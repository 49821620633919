import { LAYER_STATES } from "./constants"

const layerProcessing = (layer) => {
    return [LAYER_STATES.UPLOADING, LAYER_STATES.PENDING].includes(layer.latest_upload?.status)
}

const layerErrored = (layer) => {
    return [LAYER_STATES.ERROR].includes(layer.latest_upload?.status)
}


export {
    layerProcessing,
    layerErrored
}