import { memo } from 'react';
import Modal, { ModalHeader } from '../utility/Modal';
import { FiInfo } from 'react-icons/fi';
import useModal from '../../hooks/useModal';

function InfoModal({
  button,
  title = 'Info',
  message = 'Add your message block here',
  doneButtonTitle = 'Got it',
}) {
  const { open: modalOpen, toggle: toggleModal } = useModal();

  return (
    <div>
      <a className="cursor-pointer" onClick={toggleModal}>
        {button || (
          <div className="font-medium text-sm text-secondary hover:text-gray-800 flex py-1 px-3">
            <FiInfo size={18} />
          </div>
        )}
      </a>
      <Modal isOpen={modalOpen} onClose={toggleModal}>
        <ModalHeader title={title} onClose={toggleModal} />
        <div className="px-2 pt-2">
          <div className="p-5">{message}</div>

          <div className="modal-footer">
            <button
              className="modal-close-btn"
              type="button"
              onClick={toggleModal}
            >
              {doneButtonTitle}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default memo(InfoModal);
