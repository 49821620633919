import Modal, { ModalHeader } from '../utility/Modal';
import { TagContainer } from '../shared/Tag';

const TagConfirmDeleteModal = ({ tag, open, onDestroy, onClose }) => {
  if (!tag) return null;
  return (
    <>
      <Modal isOpen={open} onClose={onClose} modalClass={'rounded-lg'}>
        <ModalHeader title={<span>Delete Tag </span>} onClose={onClose} />

        <div className="text-center text-base items-center justify-between px-5 py-5 bg-gray-50">
          <p className="mb-1 font-bold text-gray-900">
            Are you sure you want to delete this tag?
          </p>
          <TagContainer
            color={tag.color}
            className={'ml-2 mt-3 mb-4'}
            size={'lg'}
            id={`delete-modal-${tag.id}`}
          >
            {tag.name}
          </TagContainer>
          <p className="text-red-500 italic text-sm">
            The tag will be removed from all projects and pins.
          </p>
        </div>

        <div className="modal-footer">
          <button
            className="modal-remove-btn"
            type="button"
            onClick={onDestroy}
            data-testid="test-delete-btn"
          >
            Delete
          </button>
          <button className="modal-close-btn" type="button" onClick={onClose}>
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};

export default TagConfirmDeleteModal;
