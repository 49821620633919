import { Formik } from 'formik';
import { memo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import documents from '../../api/documents';
import useAuth from '../../auth/useAuth';
import useApi from '../../hooks/useApi';
import useModal from '../../hooks/useModal';
import Modal, { ModalHeader } from '../utility/Modal';

function EditDocumentModal({
  onUpdate,
  folderObjectId,
  document: passedDocument = {},
}) {
  const {
    data: updatedDocument,
    error,
    loading,
    request: updateDocument,
  } = useApi(documents.updateDocument, null);
  const { project_id } = useParams();
  const { user } = useAuth();
  const {
    open: modalOpen,
    toggle: toggleModal,
    setOpen: setModalOpen,
  } = useModal();

  const [errorMessage, setErrorMessage] = useState(null);

  const initialValues = {
    name: passedDocument?.name,
    description: passedDocument?.description,
  };

  const _toggleModal = (e) => {
    e.preventDefault();
    toggleModal();
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .required('Required')
      .min(4, 'Must be at least 4 characters')
      .max(128, 'Cannot be more than 128 charaters')
      .typeError('Required'),
    description: Yup.string()
      .nullable()
      .max(500, 'Description cannot be longer than 500 characters'),
  });

  const submitDocument = async (data) => {
    setErrorMessage(null);
    await updateDocument(
      project_id,
      folderObjectId,
      passedDocument.objectId,
      data
    );
  };

  useEffect(() => {
    if (updatedDocument) {
      toggleModal();
      onUpdate();
    }
  }, [updatedDocument]);

  useEffect(() => {
    if (error) {
      setErrorMessage('Error updating document, please try again.');
    }
  }, [error]);

  return (
    <>
      <a
        className="focus:outline-none font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3"
        rel="noreferrer"
        href="#"
        onClick={_toggleModal}
      >
        <span>Edit</span>
      </a>

      <Modal isOpen={modalOpen} onClose={toggleModal} modalClass={'rounded-lg'}>
        <ModalHeader title={'Edit Document'} onClose={toggleModal} />

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={submitDocument}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => {
            return (
              <div className="px-2 pt-2 text-left text-gray-900 text-base">
                <div className="grid grid-cols-1 relative">
                  <form>
                    <div className="p-5">
                      <div className="font-semibold px-2 mb-2">
                        Name <span className="text-red-500">*</span>
                      </div>
                      <div className="overflow-hidden mb-8">
                        <div className="bg-white border-b border-gray-200 flex items-center">
                          <input
                            type="text"
                            placeholder="New folder"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            className="px-2 bg-gray-50 placeholder-gray-500 relative text-sm border-0 w-full border-0 outline-none focus:outline-none"
                          />
                        </div>
                        <div className="ml-3 text-red-500">
                          {errors.name && touched.name && errors.name}
                        </div>
                      </div>

                      <div className="font-semibold px-2 mb-2">Description</div>
                      <div className="overflow-hidden mb-8">
                        <div className="bg-white border-b border-gray-200 flex items-center">
                          <input
                            type="text"
                            placeholder="Description"
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                            className="px-2 bg-gray-50 placeholder-gray-500 relative text-sm border-0 w-full border-0 outline-none focus:outline-none"
                          />
                        </div>
                        <div className="ml-3 text-red-500">
                          {errors.description &&
                            touched.description &&
                            errors.description}
                        </div>
                      </div>
                      <button
                        className="modal-save-btn"
                        type="submit"
                        onClick={handleSubmit}
                        disabled={loading || errors?.description}
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            );
          }}
        </Formik>

        <div className="modal-footer">
          {errorMessage ? (
            <div className="text-red-500 text-sm">{errorMessage}</div>
          ) : null}
          <button
            className="modal-close-btn"
            type="button"
            disabled={loading}
            onClick={() => {
              toggleModal();
            }}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
}

export default memo(EditDocumentModal);
