import React from 'react';
export default function PrivateSwitch({ onToggle, isPrivate }) {
    return <div
        onClick={onToggle}
        className="form-switch secondary focus:outline-none"
    >
        <input
            type="checkbox"
            id="switch"
            className="sr-only"
            checked={isPrivate}
        />
        <label className="bg-gray-400" htmlFor="switch">
            <span
                className="bg-white shadow-sm"
                aria-hidden="true"
            ></span>
            <span className="sr-only">Switch label</span>
        </label>
    </div>
}