import React, { useState, useContext } from 'react';
import { FiX } from 'react-icons/fi';
import BulkDownloadProjectPhotosModal from '../project/BulkDownloadProjectPhotosModal';
const DownloadContext = React.createContext();
export { DownloadContext };
export function DownloadContextProvider({ children }) {
  const [downloads, setDownloads] = useState([]);
  const value = {
    downloads,
    setDownloads,
  };
  return (
    <DownloadContext.Provider value={value}>
      {children}
    </DownloadContext.Provider>
  );
}

export function useDownloads() {
  return useContext(DownloadContext);
}

export default function DownloadBar({}) {
  const { downloads, setDownloads } = useDownloads();
  const clearDownloads = () => setDownloads([]);

  if (!downloads.length) return null;

  return (
    <div
      className="bg-tertiary w-3/4 h-14 ml-55 fixed bottom-5 mx-auto inset-x-0 rounded-lg shadow-2xl flex justify-between items-center pl-6 pr-3"
      style={{ zIndex: 999 }}
    >
      <div className="text-sm font-bold text-white">
        {downloads.length} File{downloads.length > 1 ? 's' : ''} selected
      </div>
      <div className="text-base font-bold text-white flex">
        <BulkDownloadProjectPhotosModal
          afterDownload={clearDownloads}
          downloadableUrls={downloads.map(
            ({ name, files, objectId, assetable_type }) => {
              return {
                url: files.original,
                filename: name || `${assetable_type.toUpperCase()}-${objectId}`,
              };
            }
          )}
        />
        <div className="border-l border-gray-50 border-opacity-25 mx-3"></div>
        <button
          href="#"
          className="cursor-pointer hover:bg-gray-800 rounded-full p-3"
          onClick={clearDownloads}
        >
          <FiX className="text-white" />
        </button>
      </div>
    </div>
  );
}
