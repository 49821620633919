import { useState } from 'react';
import Button from '../shared/Button';
import foldersApi from '../../api/folders';
import { AddExtraProps } from '../../utility/addExtraProps';
export default function DownloadFolderButton({
  button,
  color = 'tertiary',
  className = 'mr-2',
  folder,
  share_id,
}) {
  const [downloading, setDownloading] = useState(false);
  const [downloaded, setDownloaded] = useState(false);

  const download = async () => {
    setDownloading(true);
    try {
      const { data: blob } = await foldersApi.downloadFolder(folder.objectId, {
        share_id,
      });

      // Create a blob URL for the fetched content
      const blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');

      a.href = blobUrl;
      a.download = `${folder.name}.zip`;
      a.click();

      // Clean up
      window.URL.revokeObjectURL(blobUrl);

      setDownloading(false);
      setDownloaded(true);
    } catch (error) {
      console.error('Error downloading the ZIP file:', error);
      alert('Error downloading the ZIP file.');
      setDownloading(false);
    }
  };

  if (button) {
    return AddExtraProps(button, {
      onClick: download,
      saving: downloading,
      saved: downloaded,
    });
  }

  return (
    <Button
      color={color}
      onClick={download}
      saving={downloading}
      saved={downloaded}
      savedText={'Downloaded'}
      text="Download Folder"
      className={className}
    />
  );
}
