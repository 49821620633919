import React, { useState, useEffect, useRef } from 'react';
import { BiCheckCircle } from 'react-icons/bi';
import entriesApi from '../../api/entries';
import useApi from '../../hooks/useApi';
import Loader from '../utility/Loader';

export default function EntryTextareaInput({
  entry: passedEntry,
  editing = true,
  useBottomBorder,
}) {
  const {
    data: savedEntry,
    error,
    loading: saving,
    request: saveEntry,
  } = useApi(entriesApi.updateEntry, null);
  const [entry, setEntry] = useState(passedEntry);
  const [saved, setSaved] = useState(false);
  const updateTimer = useRef(null);
  const savedTimer = useRef(null);

  const updateEntry = ({ target: { value } }) => {
    if (updateTimer.current) clearTimeout(updateTimer.current);

    updateTimer.current = setTimeout(() => {
      submitEntry(value);
    }, 1000);
  };

  const submitEntry = (value) => saveEntry(entry.objectId, { value });

  useEffect(() => {
    savedEntry && setEntry({ ...savedEntry });
  }, [savedEntry]);

  useEffect(() => {
    savedEntry && setSaved(true);
  }, [savedEntry]);

  useEffect(() => {
    if (saved) {
      savedTimer.current = setTimeout(() => {
        setSaved(false);
      }, 2000);
    }
  }, [saved]);

  return (
    <>
      <div
        className={`p-2 font-bold border-l ${useBottomBorder && 'border-b'
          } border-r relative overflow-hidden`}
      >
        {entry.name}
        {saving && (
          <div className="absolute top-0 right-2 h-full flex items-center">
            <Loader color="text-gray-700" />
          </div>
        )}
        {saved && (
          <div className="absolute top-0 right-2 h-full flex items-center">
            <BiCheckCircle
              size={16}
              className="bg-green-500 text-white rounded-full overflow-hidden"
            />
          </div>
        )}
      </div>
      <textarea
        onChange={updateEntry}
        disabled={!editing}
        className={`p-2 leading-4 border-gray-200 border-t-0 border-r-0 border-l-0 text-xxs ${entry.class_name
          } ${useBottomBorder ? 'border-b' : 'border-b-0'} ${editing && 'hover:bg-blue-50'
          } focus:border-gray-200 focus:outline-none focus:ring-0 whitespace-pre-line`}
        defaultValue={entry.value}
      />
    </>
  );
}
