import { useEffect } from 'react';
import Card from '../../utils/Card';
import { FaCheckCircle, FaClipboard } from 'react-icons/fa';
import { List } from '../../utils/Layout';
import { H3 } from '../../utils/Typography';
import Icon from '../../utils/Icon';
import Button from '../shared/Button';
import { PinType } from '../../data/models';
import { FiArchive } from 'react-icons/fi';
import usePinType from './hooks/usePinType';
import { ConfirmationModal } from '../utility/Modal';
import useModal from '../../hooks/useModal';
export default function PinTypeCard({ pinType, afterArchived, onEdit }: {
    pinType: PinType;
    onEdit: (pinType: PinType) => void;
    afterArchived?: (pinType: PinType) => void;
}) {
    const { open, setOpen } = useModal();
    const {
        unarchivePinType,
        archiving,
        archived,
        unarchived,
        archivePinType
    } = usePinType(pinType.id, { load: false });

    useEffect(() => {
        if (!archived && !unarchived) return;
        setOpen(false);
        afterArchived?.(pinType);
    }, [archived, unarchived]);

    return <>
        <Card.Card >
            <Card.Content>
                <Card.Body>
                    <Card.Header>
                        <H3 mr="mr-3">{pinType.name}</H3> <Icon name={pinType.icon.name} color={pinType.pin_color} />
                    </Card.Header>
                    <List.List>
                        <List.Item>
                            <FaCheckCircle size={16} className="mr-2" /> <div>{pinType.statuses.length} statuses</div>
                        </List.Item>
                        <List.Item>
                            <FaClipboard size={16} className="mr-2" /> <div>{pinType.fieldsets.length} custom field{pinType.fieldsets.length > 1 ? 's' : ''}</div>
                        </List.Item>
                    </List.List>
                </Card.Body>
                <Card.Footer>
                    {afterArchived && !pinType.archived_at && <Button
                        text="Archive"
                        onClick={() => setOpen(true)}
                        color='light'
                        iconAligned='left'
                        icon={<FiArchive className='mr-1' />}
                        className="flex-1 mr-1"
                        data-testid={`archivePinType${pinType.name}`}
                    />}
                    {pinType.archived_at && <Button
                        text="Activate"
                        onClick={unarchivePinType}
                        color='tertiary'
                        iconAligned='left'
                        className="flex-1 mr-1"
                        data-testid={`activatePinType${pinType.name}`}
                    />}
                    <Button
                        text="Edit"
                        onClick={() => onEdit(pinType)}
                        color='secondary'
                        className="flex-1"
                        data-testid={`editPinType${pinType.name}`}
                    />
                </Card.Footer>
            </Card.Content>
        </Card.Card>
        <ConfirmationModal confirming={archiving} confirmed={archived} isOpen={open} onClose={() => setOpen(false)} title="Are you sure?" onConfirm={archivePinType} body={
            <>
                <p className="mb-1">This will archive the {pinType.name} and <span className="font-medium">remove it as an available type on all new pins.</span></p>
                <p className="font-medium text-tertiary text-sm mb-1">Currently used on {pinType.pins_count} pins</p>
                <p className="italic text-secondary text-xs">Pins assigned this type will remain unaffected.</p>
            </>} />
    </>
}