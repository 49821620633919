import MoveDocumentTreeItem from './MoveDocumentTreeItem';

const MoveDocumentTree = ({
  folderSections,
  parentId,
  level,
  onSelectFolder,
  selectedFolderObjectId,
}) => {
  const items = folderSections
    .filter((item) => item.parent === parentId)
    .sort((a, b) => (a.name > b.name ? 1 : -1));

  return (
    <>
      {items?.length > 0 &&
        items.map((item) => (
          <MoveDocumentTreeItem
            key={item.id}
            item={item}
            level={level}
            onSelectFolder={onSelectFolder}
            selected={selectedFolderObjectId === item.id}
          >
            <MoveDocumentTree
              folderSections={folderSections}
              parentId={item.id}
              level={level + 1}
              onSelectFolder={onSelectFolder}
              selectedFolderObjectId={selectedFolderObjectId}
            />
          </MoveDocumentTreeItem>
        ))}
    </>
  );
};

export default MoveDocumentTree;
