import client from './client';
const endpoint = '/assets';

const getAssets = (parent = '', parentId = '', params = {}) =>
  client.get(
    `${parent}${parent.length ? 's/' : '/'}${parentId}${endpoint}`,
    params
  );
const getAsset = (id, params = {}) => client.get(`${endpoint}/${id}`, params);
const addAsset = (asset) => client.post(`${endpoint}`, { asset });
const updateAsset = (assetId, asset) =>
  client.put(`${endpoint}/${assetId}`, { asset });
const deleteAsset = (assetId) => client.delete(`${endpoint}/${assetId}`);
const getSignature = (blob = {}) => client.post(`${endpoint}/signed`, { blob });

export default {
  getAssets,
  getAsset,
  addAsset,
  updateAsset,
  deleteAsset,
  getSignature,
};
