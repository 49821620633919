import client from './client';
const endpoint = '/construction_drawings';

const getConstructionDrawings = (projectId, params = {}) =>
  client.get(`/projects/${projectId}${endpoint}`, params);
const getConstructionDrawing = (id, params = {}) =>
  client.get(`${endpoint}/${id}`, params);
const addConstructionDrawing = (projectId, construction_drawing) =>
  client.post(`/projects/${projectId}${endpoint}`, { construction_drawing });
const updateConstructionDrawing = (
  constructionDrawingId,
  construction_drawing
) =>
  client.put(`${endpoint}/${constructionDrawingId}`, { construction_drawing });
const deleteConstructionDrawing = (constructionDrawingId) =>
  client.delete(`${endpoint}/${constructionDrawingId}`);
const bulkDeleteConstructionDrawings = (ids) =>
  client.delete(`${endpoint}/destroy`, { ids });

export default {
  getConstructionDrawings,
  getConstructionDrawing,
  addConstructionDrawing,
  updateConstructionDrawing,
  deleteConstructionDrawing,
  bulkDeleteConstructionDrawings,
};
