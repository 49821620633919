import client from './client';
const endpoint = '/tags';

const getTags = (workspaceId, params = {}) =>
  client.get(`/workspaces/${workspaceId}${endpoint}`, params);
const addTagsToProject = (projectId, tags) =>
  client.post(`/projects/${projectId}${endpoint}`, { tags });
const updateTagOnProject = (projectId, tagId, tag) =>
  client.put(`/projects/${projectId}${endpoint}/${tagId}`, { tag });
const addTagsToWorkspace = (workspaceId, tags) =>
  client.post(`/workspaces/${workspaceId}${endpoint}`, { tags });
const updateTagOnWorkspace = (workspaceId, tagId, tag) =>
  client.put(`/workspaces/${workspaceId}${endpoint}/${tagId}`, { tag });
const destroyTagOnWorkspace = (workspaceId, tagId) =>
  client.delete(`/workspaces/${workspaceId}${endpoint}/${tagId}`);
const removeTagsFromProject = (projectId, tags) =>
  client.delete(`/projects/${projectId}${endpoint}`, { tags });
const destroyTagOnProject = (projectId, tagId) =>
  client.delete(`/projects/${projectId}${endpoint}/${tagId}`);
const addTagsToPin = (pinId, tags) =>
  client.post(`/pins/${pinId}${endpoint}`, { tags });
const removeTagsFromPin = (pinId, tags) =>
  client.delete(`/pins/${pinId}${endpoint}`, { tags });
const destroyTagOnPin = (pinId, tagId) =>
  client.delete(`/pins/${pinId}${endpoint}/${tagId}`);
const updateTagOnPin = (pinId, tagId, tag) =>
  client.put(`/pins/${pinId}${endpoint}/${tagId}`, { tag });

export default {
  getTags,
  addTagsToPin,
  addTagsToProject,
  addTagsToWorkspace,
  destroyTagOnPin,
  destroyTagOnProject,
  destroyTagOnWorkspace,
  removeTagsFromPin,
  removeTagsFromProject,
  updateTagOnPin,
  updateTagOnProject,
  updateTagOnWorkspace,
};
