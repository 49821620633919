import React, { useState } from 'react';
const WorkspaceContext = React.createContext();
export default WorkspaceContext;
function WorkspaceContextProvider({ children }) {
  const [workspace, setWorkspace] = useState({});
  const [permissions, setPermissions] = useState({});
  const [loading, setLoading] = useState(false);

  const value = {
    workspace,
    setWorkspace,
    permissions,
    setPermissions,
    loading,
    setLoading,
  };

  return (
    <WorkspaceContext.Provider value={value}>
      {children}
    </WorkspaceContext.Provider>
  );
}

export { WorkspaceContextProvider };
