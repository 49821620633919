import { useEffect, useState } from 'react';
import EntryDateActivity from '../entries/EntryDateActivity';
export default function EntryGroupDateActivity({
  entryGroup,
  editing = true,
  entries: passedEntries,
  onLoaded,
}) {
  const [entries, setEntries] = useState(passedEntries);

  const addBlankEntry = () => {
    setEntries([...entries, {}]);
  };

  useEffect(() => {
    onLoaded && onLoaded();
  }, []);

  return (
    <div className="text-xxs leading-tight">
      <div className="border-b border-gray-500 grid grid-cols-8 font-bold bg-gray-300">
        <div className="p-2">Date</div>
        <div className="p-2 col-span-7">Activity</div>
      </div>

      {entries.map((entry) => (
        <EntryDateActivity
          editing={editing}
          entry={entry}
          entryGroup={entryGroup}
        />
      ))}

      <div
        className="print:hidden border border-t-0 flex items-center justify-center rounded-b-lg shadow-sm"
        onClick={addBlankEntry}
      >
        <div className="p-2 w-full text-center hover:bg-blue-50 cursor-pointer">
          + Add Entry
        </div>
      </div>
    </div>
  );
}
