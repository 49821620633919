import reportsApi from "../../../api/reports"
import { ReportType } from "../../../data/models"

export default class NewReportService {
    projectObjectId: string;
    reportType: ReportType;
    inspectionDate: Date | undefined;
    reportData: object;
    pinFilterData: object;

    constructor({
        projectObjectId,
        reportType,
        inspectionDate,
    }: {
        projectObjectId: string
        reportType: ReportType
        inspectionDate?: Date
    }) {
        this.projectObjectId = projectObjectId;
        this.reportType = reportType
        this.inspectionDate = inspectionDate
        this.reportData = {}
        this.pinFilterData = {}
    }

    async getDefaultReport() {
        const response = await reportsApi.newReport(this.projectObjectId, {
            inspection_date: this.inspectionDate || null,
            report_type_id: this.reportType.id,
        })

        const defaultReport = response.data

        this.reportData = {
            ...defaultReport,
            name: defaultReport.name,
        };
    }

    async generateReport() {

        await this.getDefaultReport();

        this.reportData = {
            ...this.reportData,
            metadata: {},
            report_type_id: this.reportType.id,
            pin_filter: this.pinFilterData,
        };

        const response = await reportsApi.addReport(this.projectObjectId, this.reportData)
        return response.data;
    }
}