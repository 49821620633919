import React, { memo, useEffect, useState } from 'react';
import useApi from '../../hooks/useApi';
import mapLayersApi from '../../api/map_layers';
import { useParams } from 'react-router-dom';
import Loader from '../utility/Loader';

function MapLayersSelect({
  testIdPrefix = '',
  disabled = false,
  placeHolder = 'Select Map Layer attributes to Copy',
  marginRight = 'mr-3',
  width = 'w-72',
  selectedId,
  onSelect,
}) {
  const {
    data: { records, pagy },
    error,
    loading,
    request: loadLayers,
  } = useApi(mapLayersApi.getProjectMapLayers, { records: [], pagy: {} }, true);
  const { project_id } = useParams();
  const [mapLayers, setMapLayers] = useState([]);
  const [selectedLayer, setSelectedLayer] = useState();

  const setLayerSelectedLayerFromId = (selectedId) => {
    setSelectedLayer(mapLayers.find(({ id }) => id == selectedId));
  };

  useEffect(() => {
    loadLayers(project_id, { items: 20 });
  }, []);

  useEffect(() => {
    records.length > 0 && setMapLayers([...mapLayers, ...records]);
  }, [records]);

  useEffect(() => {
    onSelect && onSelect(selectedLayer);
  }, [selectedLayer]);

  if (loading) return <Loader color={'text-gray-800 mr-4'} />;

  if (mapLayers.length === 0) return <span />;

  return (
    <>
      <div
        className={`bg-white border-b border-gray-200 overflow-hidden ${marginRight} ${
          disabled && 'opacity-70'
        }`}
      >
        <div className="flex items-center">
          <select
            disabled={disabled}
            type="text"
            data-testid={`${testIdPrefix}mapLayerCopySelect`}
            className={`px-2 placeholder-gray-400 relative text-sm border-0 ${width} outline-none focus:outline-none`}
            value={selectedId}
            onChange={({ target: { value } }) =>
              setLayerSelectedLayerFromId(value)
            }
          >
            <option>{placeHolder}</option>
            {mapLayers
              .filter(({ file_type }) => file_type === 'PDF')
              .map(({ id, objectId, name }) => (
                <option
                  data-testid={`${testIdPrefix}mapLayerCopy${name}`}
                  key={`map_layer_option_${objectId}`}
                  value={id}
                >
                  {name}
                </option>
              ))}
          </select>
        </div>
      </div>
    </>
  );
}
export default memo(MapLayersSelect);
