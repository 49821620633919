import React from 'react';
export default function Progress({ current, total }) {
  const currentProgress = () => (current / total) * 100;
  return (
    <div className="relative pt-1">
      <div className="overflow-hidden h-2 mb-2 text-xs flex rounded bg-green-200">
        <div
          style={{ width: `${currentProgress()}%` }}
          className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500"
        ></div>
      </div>
    </div>
  );
}
