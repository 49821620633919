import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Loader from '../utility/Loader';
import useApi from '../../hooks/useApi';
import reportTypesApi from '../../api/report_types.js';
export default function ReportTypeRadioButtons({ reportType, onChosen }) {
  const {
    data: { records },
    loading,
    request: getWorkspaceReportTypes,
  } = useApi(reportTypesApi.getWorkspaceReportTypes, { records: [], pagy: {} }, true);
  const { workspace_id } = useParams();

  useEffect(() => {
    getWorkspaceReportTypes(workspace_id);
  }, []);

  return (
    <div className="flex flex-wrap text-sm items-center">
      {loading && <Loader margin={'mx-2'} color="text-gray-800" />}
      {records.map((report_type) => {
        let isChosen = reportType && reportType.id === report_type.id;
        return (
          <a
            key={`report_type_${report_type.id}`}
            onClick={() => onChosen?.(report_type)}
            className={`cursor-pointer mr-1 mb-1 px-2 py-1 border rounded-md ${isChosen
              ? 'shadow bg-blue-50 text-secondary border-secondary'
              : 'bg-white'
              } hover:shadow`}
          >
            {report_type.name}
          </a>
        );
      })}
    </div>
  );
}
