import { useEffect, useState } from 'react';
import Loader from '../utility/Loader';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalSuccessView } from '../utility/Modal';
import useAuth from '../../auth/useAuth';
import ErrorView from '../utility/ErrorView';
import { Workspace } from '../../data/models';
import WorkspaceForm from './WorkspaceForm';
import useWorkspace from './hooks/useWorkspace';

export default function EditWorkspaceModal({
  open,
  onClose,
  workspace: passedWorkspace,
  afterUpdate,
}: {
  open: boolean,
  onClose: () => void,
  workspace: Workspace,
  afterUpdate: (workspace: any) => void,
}) {
  const { permissions } = useAuth();
  const [workspace, setWorkspace] = useState<Workspace | any>(passedWorkspace);
  const { updateError, updated, updating, updatedWorkspace, saveWorkspace } = useWorkspace(workspace.objectId, {
    load: false,
  });

  const updateAttributes = (key: string, value: any) => {
    workspace[key] = value;
    setWorkspace({ ...workspace });
  };

  const _saveWorkspace = () => {
    saveWorkspace(workspace);
  };

  useEffect(() => {
    if (!updated) return;
    onClose?.();
    afterUpdate?.(updatedWorkspace)
  }, [updated, updatedWorkspace]);

  return (
    <Modal
      isOpen={open}
      dialogScrolls={true}
      modalScrolls={false}
      maxHeight={'max-h-auto'}
      dialogClass={'py-10'}
      aligned={'items-start'}
      onClose={onClose}
    >
      <ModalHeader title={'Update Workspace'} onClose={onClose} />
      {updated ? (
        <div className="px-2 pt-2">
          <ModalSuccessView text={'Workspace successfully updated'} />
        </div>
      ) : (
        <>
          <ModalBody>
            {updateError && (
              <ErrorView error={updateError} />
            )}
            {open && <WorkspaceForm workspace={workspace} onUpdate={updateAttributes} permissions={permissions} />}
          </ModalBody>

          <ModalFooter>
            <button
              className="modal-save-btn mr-2"
              type="button"
              disabled={updating}
              data-testid={`savedWorkspaceButton${workspace.name}`}
              onClick={_saveWorkspace}
            >
              Save {updating && <Loader />}
            </button>
            <button
              className="modal-close-btn"
              type="button"
              data-testid={`cancelEditWorkspaceButton${workspace.name}`}
              disabled={updating}
              onClick={onClose}
            >
              Cancel
            </button>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
}
