import React, { memo } from 'react';
import ReportPageDuplicateButton from './ReportPageDuplicateButton.js';
import ReportPageHideAllButton from './ReportPageHideAllButton.js';
import ReportPageHideButton from './ReportPageHideButton.js';

function ReportPageOptions({
  reportPageId,
  hideAll = true,
  duplicate = false,
}) {
  return (
    <div className="transition ease-in duration-100 justify-self-end md:absolute mb-2 md:right-2 md:top-2 shadow-lg align-self-end inline-flex md:flex items-center bg-gray-700 justify-end rounded-lg group-hover:opacity-100 md:opacity-0">
      <ReportPageHideButton reportPageId={reportPageId} />
      {hideAll && <ReportPageHideAllButton reportPageId={reportPageId} />}
      {duplicate && <ReportPageDuplicateButton reportPageId={reportPageId} />}
    </div>
  );
}

export default memo(ReportPageOptions);
