import client from './client';
const endpoint = '/activity';

const getActivity = (activityId) => client.get(`${endpoint}/${activityId}`);
const getProjectActivity = (projectId, params = {}) =>
  client.get(`/projects/${projectId}${endpoint}`, params);
const getUserActivity = (userId, params = {}) =>
  client.get(`/users/${userId}${endpoint}`, params);
const getWorkspaceActivity = (workspaceId, params = {}) =>
  client.get(`/workspaces/${workspaceId}${endpoint}`, params);

export default {
  getActivity,
  getProjectActivity,
  getUserActivity,
  getWorkspaceActivity,
};
