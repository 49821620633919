import Tag from './Tag';
import useFeatures from '../../hooks/useFeatures';
import TagsDropdown from './TagsDropdown';

export default function Tags({
  tags = [],
  allowCreate = true,
  on = 'project',
  containerClassName = 'absolute top-2 right-2',
  onAdd,
  onClearTags,
  onDestroy,
  onRemove,
  onUpdate,
  maxListHeight,
  taggableType,
  tagSize: size = 'sm',
  tagClassName = 'mr-1',
  triggerTestId,
  maxTags: passedMaxTags = 0,
  keyPrefix = '',
  canAdd = false,
}) {
  const maxTags = passedMaxTags || 10000;
  const { features } = useFeatures();

  if (!features?.rollout?.[`${on}_tags`]) return null;

  return (
    <div className={containerClassName}>
      {canAdd && (
        <TagsDropdown
          allowCreate={allowCreate}
          tags={tags}
          onAdd={onAdd}
          onClearTags={onClearTags}
          onDestroy={onDestroy}
          onRemove={onRemove}
          onUpdate={onUpdate}
          maxListHeight={maxListHeight}
          taggableType={taggableType}
          triggerTestId={triggerTestId}
        />
      )}
      {tags.slice(0, maxTags).map((tag) => (
        <Tag
          key={`${keyPrefix}Tag${tag.id}`}
          tag={tag}
          size={size}
          className={tagClassName}
          onDestroy={onDestroy}
          onRemove={onRemove}
          onUpdate={onUpdate}
        />
      ))}
      {tags.length > maxTags && (
        <Tag
          key={`${keyPrefix}TagMore`}
          tag={{
            name: `+${tags.length - maxTags}`,
            color: '#808080',
          }}
          size={size}
          className={tagClassName}
        />
      )}
    </div>
  );
}
