import { useEffect, useRef, useState } from 'react';
import { IoMdRefresh } from 'react-icons/io';
import { toast } from 'react-toastify';

function parseHTMLForVersion(htmlString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  const metaTag = doc.querySelector('meta[name="version"]');
  if (metaTag) {
    const version = metaTag.getAttribute('content');
    return version;
  }

  return null;
}

export default function useListenForNewVersion(enabled = true) {
  const versionCheck = useRef(null);
  const [toastShown, setToastShown] = useState(false);
  const [documentVisible, setDocumentVisible] = useState(true);

  const showUpdateToast = () => {
    setToastShown(true);
    toast.info(
      <div className="font-bold text-secondary items-center flex flex-col py-2">
        <p className="mb-3 text-gray-900 text-center">
          A new version of <br /> Site Marker is available.
        </p>
        <a
          className="cursor-pointer px-2 py-1 border rounded-md bg-secondary hover:shadow text-white items-center flex w-48 justify-center"
          onClick={() => (window.location.href = window.location.href)}
        >
          Update Now <IoMdRefresh className="ml-3" />
        </a>
      </div>,
      {
        position: 'bottom-left',
        className: 'w-72 ml-5 mb-5',
        autoClose: false,
        icon: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  };

  useEffect(() => {
    if (!enabled || !documentVisible || toastShown) return;
    versionCheck.current = setInterval(() => {
      fetch(window.location.origin, { cache: 'no-store' })
        .then((res) => res.text())
        .then((html) => {
          const version = parseHTMLForVersion(html);
          if (version !== process.env.REACT_APP_SOURCE_VERSION) {
            showUpdateToast();
          }
        });
    }, 30000);

    return () => clearInterval(versionCheck.current);
  }, [documentVisible, enabled, toastShown]);

  useEffect(() => {
    function handleVisibilityChange() {
      setDocumentVisible(document.visibilityState === 'visible');
    }

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () =>
      document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, []);

  return;
}
