import placeholder from '../../images/placeholders/banner-white.png';
import PinViewModel from '../../models/PinViewModel';
import Loader from '../utility/Loader';
import PinsFilterDropdown from './PinsFilterDropdown';
import SearchForm from '../actions/SearchForm';
import PinCategory from './PinCategory';
import PinLastUpdatedAt from './PinLastUpdatedAt';
import { Pin, PinFilterWhereParams } from '../../data/models';

function PinsList({
  pins,
  loading,
  onSearch,
  onFilter,
  currentFilter,
  onSelect,
}: {
  pins: any[],
  loading: boolean,
  onSearch: (q: string) => void,
  onFilter: (newFilter: PinFilterWhereParams) => void,
  currentFilter: undefined | PinFilterWhereParams,
  onSelect: (pinModel: PinViewModel) => void,
}) {
  const noPinsMessage = () => {
    if (currentFilter === null) return 'No pins have been added to project';
    return (
      <span>
        No pins found matching filters{' '}
        {currentFilter?.days ? (
          <span>
            that have been updated in the{' '}
            <span className="italic font-medium">
              {' '}
              last {currentFilter.days} day(s)
            </span>
          </span>
        ) : (
          ''
        )}
      </span>
    );
  };
  return (
    <>
      <div className="grid grid-cols-5 gap-1 pb-3 px-5 bg-gray-50 shadow">
        <div className="col-span-4">
          <SearchForm
            placeholder={'Search Pins...'}
            className="bg-white w-full"
            onSearched={onSearch}
            text={undefined}
          />
        </div>
        <div className="col-span-1 flex justify-center items-center">
          <PinsFilterDropdown
            onFilter={onFilter}
            currentFilter={currentFilter}
          />
        </div>
      </div>
      <div className="text-black text-xs h-full px-5 py-2 overflow-y-auto">
        {loading && (
          <div className="flex py-4 justify-center">
            <Loader color="black" />
          </div>
        )}
        <div data-testid="pin_list">
          {pins.map((pin) => <PinListItem pin={pin} onSelect={onSelect} />)}
        </div>
        {!loading && pins.length === 0 && (
          <div className="flex justify-center">
            <p className="py-3 font-semibold text-center">{noPinsMessage()}</p>
          </div>
        )}
      </div>
    </>
  );
}

const PinListItem = ({ pin, onSelect }: {
  pin: Pin,
  onSelect: (pinModel: PinViewModel) => void,
}) => {
  const pinModel = new PinViewModel(pin);
  return (
    <a
      className="cursor-pointer"
      key={`pin_list_pin_${pin.objectId}`}
      onClick={() => onSelect(pinModel)}
    >
      <div
        className="bg-white rounded-sm border border-gray-200 overflow-hidden mb-2"
        data-testid={`pinListPin${pin.identifier}`}
      >
        <div className="flex flex-col h-full">
          <div className="grid grid-cols-4">
            <div className="col-span-1 flex justify-center items-center">
              <img
                loading='lazy'
                className={'bg-gray-100 object-cover w-full h-20'}
                src={pin.assets_count === 0 ? placeholder : pin.hero_photo.medium}
              />
            </div>
            <div className="col-span-3 p-2">
              <div className="flex flex-row items-center">
                <div className="font-bold mr-2">{pinModel.identifier}</div>
                <PinLastUpdatedAt pin={pinModel} />
              </div>
              <div className="flex flex-row items-center flex-wrap mt-2">
                <div className="bg-blue-100 rounded-sm text-xxs px-1 mr-2">
                  {pinModel.pin_type?.name}
                </div>
                <div
                  className="bg-gray-200 text-white rounded-sm text-xxs px-1 mr-2 capitalize"
                  style={{
                    backgroundColor: pinModel.getStatusColor?.(),
                  }}
                >
                  {pinModel.getStatus()}
                </div>
                <div className="space-y-1">
                  {pinModel
                    .categoryFields()
                    .map(({ field_option: { color, name } }: { field_option: { color: string; name: string } }, index: number) => {
                      return (
                        <PinCategory
                          key={`report_pin_${pinModel.objectId ?? ''}_category_${index}`}
                          size="sm"
                          name={name}
                          color={color}
                        />
                      );
                    })}
                </div>
              </div>
              {pinModel.latestNote() && (
                <div className="text-xxs mt-2 py-0.5 px-1 bg-gray-100 rounded truncate">
                  {pinModel.latestNote()}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </a>
  );
}

export default PinsList;
