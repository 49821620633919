import { ContextMenu, MenuItem } from 'react-contextmenu';
import { FiCheckCircle, FiCircle, FiDownload, FiMapPin } from 'react-icons/fi';
export default function ContextMenus({
  id = 'default',
  onDrop,
  onSelect,
  onDownload,
  onZoomToPin,
  onHide,
  hideOnLeave = true,
}) {
  return (
    <>
      <ContextMenu
        id={`pin_menu_${id}`}
        className="bg-white rounded border w-32 absolute z-30"
        onHide={onHide}
        hideOnLeave={hideOnLeave}
      >
        {onDrop && (
          <MenuItem
            onClick={onDrop}
            className={
              'cursor-pointer items-center group px-2 hover:bg-gray-400 hover:bg-opacity-10 text-dark font-semibold text-xs py-1 border-b flex'
            }
          >
            <span data-testid="context-select" className="flex items-center">
              <FiMapPin className="mr-1 text-gray-700 group-hover:text-gray-900" />{' '}
              Drop Pin
            </span>
          </MenuItem>
        )}
        {onSelect && (
          <MenuItem
            onClick={onSelect}
            className={
              'cursor-pointer items-center group px-2 hover:bg-gray-400 hover:bg-opacity-10 text-dark font-semibold text-xs py-1 border-b flex'
            }
          >
            <span data-testid="context-select" className="flex items-center">
              <FiCheckCircle className="mr-1 text-gray-700 group-hover:text-gray-900" />{' '}
              Select
            </span>
          </MenuItem>
        )}
        {onDownload && (
          <MenuItem
            onClick={onDownload}
            className={
              'cursor-pointer items-center group px-2 hover:bg-gray-400 hover:bg-opacity-10 text-dark font-semibold text-xs py-1 border-b flex'
            }
          >
            <span data-testid="context-download" className="flex items-center">
              <FiDownload className="mr-1 text-gray-700 group-hover:text-gray-900" />{' '}
              Download
            </span>
          </MenuItem>
        )}
        <MenuItem divider />
        {onZoomToPin && (
          <MenuItem
            onClick={onZoomToPin}
            className={
              'cursor-pointer items-center group px-2 hover:bg-gray-400 hover:bg-opacity-10 text-dark font-semibold text-xs py-1 border-b flex'
            }
          >
            <span
              data-testid="context-zoom-to-pin"
              className="flex items-center"
            >
              <FiMapPin className="mr-1 text-gray-700 group-hover:text-gray-900" />{' '}
              Zoom to Pin
            </span>
          </MenuItem>
        )}
      </ContextMenu>
      <ContextMenu
        id={`pin_menu_${id}_selected`}
        className="bg-white rounded border w-32 absolute z-30"
        onHide={onHide}
        hideOnLeave={hideOnLeave}
      >
        {onSelect && (
          <MenuItem
            onClick={onSelect}
            className={
              'cursor-pointer items-center group px-2 hover:bg-gray-400 hover:bg-opacity-10 text-dark font-semibold text-xs py-1 border-b flex'
            }
          >
            <FiCircle className="mr-1 text-gray-700 group-hover:text-gray-900" />{' '}
            Unselect
          </MenuItem>
        )}
        {onDownload && (
          <MenuItem
            onClick={onDownload}
            className={
              'cursor-pointer items-center group px-2 hover:bg-gray-400 hover:bg-opacity-10 text-dark font-semibold text-xs py-1 border-b flex'
            }
          >
            <FiDownload className="mr-1 text-gray-700 group-hover:text-gray-900" />{' '}
            Download
          </MenuItem>
        )}
        <MenuItem divider />
        {onZoomToPin && (
          <MenuItem
            onClick={onZoomToPin}
            className={
              'cursor-pointer items-center group px-2 hover:bg-gray-400 hover:bg-opacity-10 text-dark font-semibold text-xs py-1 border-b flex'
            }
          >
            <FiMapPin className="mr-1 text-gray-700 group-hover:text-gray-900" />{' '}
            Zoom to Pin
          </MenuItem>
        )}
      </ContextMenu>
      <ContextMenu id="asset_menu" className="bg-white rounded border w-32">
        {onSelect && (
          <MenuItem
            onClick={onSelect}
            className={
              'cursor-pointer items-center group px-2 hover:bg-gray-400 hover:bg-opacity-10 text-dark font-semibold text-xs py-1 border-b flex'
            }
          >
            <FiCheckCircle className="mr-1 text-gray-700 group-hover:text-gray-900" />{' '}
            Select
          </MenuItem>
        )}
        {onDownload && (
          <MenuItem
            onClick={onDownload}
            className={
              'cursor-pointer items-center group px-2 hover:bg-gray-400 hover:bg-opacity-10 text-dark font-semibold text-xs py-1 border-b flex'
            }
          >
            <FiDownload className="mr-1 text-gray-700 group-hover:text-gray-900" />{' '}
            Download
          </MenuItem>
        )}
        <MenuItem divider />
      </ContextMenu>
    </>
  );
}
