import { useState } from 'react';
import Form from '../../utils/Form';
import Button from '../shared/Button';
import { FieldOption } from '../../data/models';
export default function FieldOptionEditForm({
    error,
    unarchived,
    option,
    onArchive,
    onClose,
    onSave,
    onUnarchive,
    onUpdate,
    saving,
    saved,
}: {
    error?: any;
    unarchived?: boolean;
    option: FieldOption;
    onArchive?: (e?: any) => void;
    onClose: (e?: any) => void;
    onSave: (e?: any) => void;
    onUnarchive?: (e?: any) => void;
    onUpdate: (key: string, value: any) => void;
    saving?: boolean;
    saved?: boolean;
}) {

    const [showColorPicker, setShowColorPicker] = useState(false);

    const _setShowColorPicker = (e: any) => {
        setShowColorPicker(!showColorPicker);
        e.preventDefault();
        e.stopPropagation();
        return false;
    }

    return <>
        <Form.Group mb={0} overflow='visible'>
            <Form.ColorBullet onClick={_setShowColorPicker} absolute color={option.color || '#000'} />
            {showColorPicker && <Form.ColorPicker
                color={option.color || ''}
                onDone={() => setShowColorPicker(false)}
                onChange={(values: any) => onUpdate('color', values.hex)}
            />}
            <Form.Input
                error={error?.includes('name') && error?.text()}
                pl="pl-7"
                value={option.name}
                type="text"
                autoFocus
                placeholder="Name"
                onChange={({ target: { value } }) => onUpdate('name', value)}
                data-testid="inputFieldOptionName"
            />
        </Form.Group>
        <Form.Label size="xs" mb={0}>Value</Form.Label>
        <Form.Input
            error={error?.includes('value') && error?.text()}
            value={option.value || undefined}
            type="text"
            disabled
            placeholder="Name"
            onChange={({ target: { value } }) => onUpdate('value', value)}
            data-testid="inputFieldOptionValue"
        />
        <Form.Label size="xs" mb={1}>Description</Form.Label>
        <Form.TextArea mb={3} data-testid="inputFieldOptionDescription" value={option.description} placeholder="Description" onChange={({ target: { value } }) => onUpdate('description', value)} />
        <Form.Group className="flex justify-between" mb={3}>
            <Button text="Cancel" onClick={onClose} color="light" />
            <div>
                {onUnarchive && <Button text="Activate" data-testid="buttonFieldOptionActivate" saved={unarchived} savedText='Activated!' onClick={onUnarchive} color="tertiary" className='mr-1' />}
                {onArchive && <Button text="Archive" data-testid="buttonFieldOptionArchive" onClick={onArchive} color="secondary" className='mr-1' />}
                <Button text="Save" data-testid="buttonFieldOptionSave" saved={saved} saving={saving} savedText='Saved' onClick={onSave} color="success" />
            </div>
        </Form.Group></>
}