import { memo, useEffect, useRef, useState } from 'react';
import { FiX } from 'react-icons/fi';
import Loader from '../utility/Loader';
import useApi from '../../hooks/useApi';
import mapLayersApi from '../../api/map_layers';
import { Redirect, useParams } from 'react-router-dom';
import Modal, { ModalSuccessView } from '../utility/Modal';
import ErrorView from '../utility/ErrorView';

function DeleteMapLayerButton() {
  const {
    data: deleted_layer,
    error,
    loading: deleting,
    request: deleteMapLayer,
  } = useApi(mapLayersApi.deleteMapLayer, null);
  const { project_id, workspace_id, map_layer_id } = useParams();
  const [deleted, setDeleted] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const deletedTimer = useRef();
  const [modalOpen, setModalOpen] = useState(false);

  const removeMapLayer = () => deleteMapLayer(map_layer_id);

  const closeModal = () => {
    setModalOpen(false);

    if (deletedTimer.current) {
      clearTimeout(deletedTimer.current);
      setRedirect(true);
    }
  };

  useEffect(() => {
    deleted_layer && setDeleted(true);
  }, [deleted_layer]);

  useEffect(() => {
    if (deleted)
      deletedTimer.current = setTimeout(() => setRedirect(true), 3000);
  }, [deleted]);

  if (redirect)
    return <Redirect to={`/${workspace_id}/projects/${project_id}/maps`} />;

  return (
    <>
      <a
        className="disabled:opacity-70 text-xs flex items-center cursor-pointer mr-2 px-4 py-2 hover:opacity-80 font-bold border rounded-md bg-red-500 text-white hover:shadow disabled:opacity-70"
        disabled={deleting}
        onClick={() => setModalOpen(!modalOpen)}
      >
        {deleting && <Loader margin="mr-1" />} Delete
      </a>
      <Modal isOpen={modalOpen} onClose={closeModal}>
        <div className="flex items-center justify-between px-5 py-3 bg-primary">
          <p className="text-lg font-bold text-white">Confirm Layer Deletion</p>
          <button
            className="focus:outline-none"
            disabled={deleting}
            onClick={closeModal}
          >
            <FiX
              size={28}
              className="text-white hover:opacity-60 cursor-pointer"
            />
          </button>
        </div>
        {error && <ErrorView error={error} />}
        <div className="px-2 pt-2">
          <div className="p-5">
            {!deleted && (
              <>
                <div className="mb-4">
                  <div className="font-semibold">Are you sure?</div>
                </div>
                <div>
                  This will completely delete the layer from the project.
                </div>
              </>
            )}
            {deleted && (
              <ModalSuccessView text={'Layer successfully deleted'} />
            )}
          </div>

          {!deleted && (
            <div className="modal-footer">
              <button
                className="modal-remove-btn"
                type="button"
                disabled={deleting}
                onClick={removeMapLayer}
              >
                Delete Layer {deleting && <Loader />}
              </button>
              <button
                className="modal-close-btn"
                type="button"
                disabled={deleting}
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}

export default memo(DeleteMapLayerButton);
