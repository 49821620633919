import moment from 'moment-timezone';

const prefix = 'cache';
const expiryInMinutes = 5;

const store = (key, value, expires = 5) => {
  try {
    const item = {
      value,
      timestamp: Date.now(),
      expires,
    };
    window.localStorage.setItem(`${prefix}${key}`, JSON.stringify(item));
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const remove = (key) => {
  try {
    window.localStorage.removeItem(`${prefix}${key}`);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const isExpired = (item) => {
  if (item.expires !== undefined && !item.expires) return false;

  const now = moment(Date.now());
  const storedTime = moment(item.timestamp);
  const expires = item.expires || expiryInMinutes;
  return now.diff(storedTime, 'minutes') > expires;
};

const get = (key) => {
  try {
    const value = window.localStorage.getItem(`${prefix}${key}`);
    const item = JSON.parse(value);

    if (!item) return null;

    if (isExpired(item)) {
      // Command Query Separation (CQS)
      window.localStorage.removeItem(`${prefix}${key}`);
      return null;
    }

    return item.value;
  } catch (error) {
    console.log(error);
  }
};

const exists = (key) => {
  try {
    const value = window.localStorage.get(`${prefix}${key}`);
    const item = JSON.parse(value);

    if (!item) return false;

    return true;
  } catch (error) {
    return false;
  }
};

export default {
  store,
  remove,
  get,
  exists,
};
