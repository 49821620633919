import React from 'react';
import moment from 'moment-timezone';

export default function PinLastUpdatedAt({ pin }) {
  return (
    <div className="text-xxxs">
      Last updated {moment(pin.updated_at).format('MMM D, YYYY')}
    </div>
  );
}
