import PermissionsViewModel from './PermissionsViewModel';

export default class ConstructionDrawingViewModel {
  constructor(object) {
    Object.assign(this, object);
    this.permissions = new PermissionsViewModel({
      permissions: object.permissions,
    });
  }
}
