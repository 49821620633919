import React, { useMemo, useState } from 'react';
import useQuery from '../../hooks/useQuery';
const ReportContext = React.createContext();
export default ReportContext;
function ReportContextProvider({ children }) {
  const query = useQuery();
  const [editing, setEditing] = useState(false);
  const [pinUpdated, setPinUpdated] = useState(false);
  const [deletedPins, setDeletedPins] = useState([]);
  const [softDeletedPins, setSoftDeletedPins] = useState([]);
  const [hiddenPages, setHiddenPages] = useState([]);
  const [softHiddenPages, setSoftHiddenPages] = useState([]);
  const [newPagesAdded, setNewPagesAdded] = useState(false);
  const [entryGroupsLoaded, setEntryGroupsLoaded] = useState([]);
  const [pageBreakIndexes, setPageBreakIndexes] = useState([]);
  const [mapsConverted, setMapsConverted] = useState([]);
  const [pages, setPages] = useState({
    from: query.get('from') || 1,
    to: query.get('to') || 5,
    items: query.get('items') || 5,
  });

  const value = useMemo(() => {
    return {
      editing,
      setEditing,
      pages,
      setPages,
      pageBreakIndexes,
      setPageBreakIndexes,
      mapsConverted,
      setMapsConverted,
      entryGroupsLoaded,
      setEntryGroupsLoaded,
      pinUpdated,
      setPinUpdated,
      softDeletedPins,
      setSoftDeletedPins,
      deletedPins,
      setDeletedPins,
      softHiddenPages,
      setSoftHiddenPages,
      hiddenPages,
      setHiddenPages,
      newPagesAdded,
      setNewPagesAdded,
    };
  }, [
    editing,
    setEditing,
    pages,
    setPages,
    pageBreakIndexes,
    setPageBreakIndexes,
    mapsConverted,
    setMapsConverted,
    entryGroupsLoaded,
    setEntryGroupsLoaded,
    pinUpdated,
    setPinUpdated,
    softDeletedPins,
    setSoftDeletedPins,
    deletedPins,
    setDeletedPins,
    softHiddenPages,
    setSoftHiddenPages,
    hiddenPages,
    setHiddenPages,
    newPagesAdded,
    setNewPagesAdded,
  ]);

  return (
    <ReportContext.Provider value={value}>{children}</ReportContext.Provider>
  );
}

export { ReportContextProvider };
