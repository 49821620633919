import { useEffect, useRef, useState } from 'react';
import { FiX } from 'react-icons/fi';
import useApi from '../../hooks/useApi';
import activityApi from '../../api/activity';
import Loader from './Loader';
function ActivityView({
  activityId,
  dismissable = false,
  extraClass = '',
  className = '',
  preface = <span className="font-bold">Activity</span>,
  ...rest
}) {
  const {
    data: activity,
    loading,
    request: getActivity,
  } = useApi(activityApi.getActivity, null);
  const [dismissed, setDismissed] = useState(false);
  const dismissTimer = useRef();
  const dismissAlert = () => {
    if (dismissTimer.current) clearTimeout(dismissTimer.current);
    if (dismissed) return;
    setDismissed(true);
  };

  useEffect(() => {
    getActivity(activityId);
  }, []);

  if (!activity) return null;

  return dismissed ? (
    <span />
  ) : (
    <div
      data-testid="activityView"
      className={
        className
          ? `${className} ${dismissable && 'cursor-pointer hover:opacity-90'}`
          : `${extraClass} bg-blue-500 text-white px-3 py-2 rounded-0 text-lg text-center font-semibold w-full z-50 ${
              dismissable && 'cursor-pointer hover:opacity-90'
            }`
      }
      onClick={dismissable && dismissAlert}
      {...rest}
    >
      {loading || !activity ? (
        <Loader />
      ) : (
        <span>
          {activity.owner.name}{' '}
          <span className="font-normal">{activity.text}</span>{' '}
          {activity.parameters.description}
        </span>
      )}
      {dismissable && (
        <button
          href="#"
          className="w-full cursor-pointer hover:opacity-70 rounded-full p-3 absolute right-0 top-0"
          onClick={dismissAlert}
        >
          <FiX className="text-white" />
        </button>
      )}
    </div>
  );
}

export default ActivityView;
