import React, { useEffect, useRef, useState } from 'react';
import { GrCheckbox, GrCheckboxSelected } from 'react-icons/gr';
import { BiCheckCircle } from 'react-icons/bi';
import entriesApi from '../../api/entries';
import useApi from '../../hooks/useApi';
import Loader from '../utility/Loader';

export default function EntryWeather({ entry: passedEntry, editing = true }) {
  const {
    data: savedEntry,
    error,
    loading: saving,
    request: saveEntry,
  } = useApi(entriesApi.updateEntry, null);
  const [entry, setEntry] = useState(passedEntry);
  const [saved, setSaved] = useState(false);
  const savedTimer = useRef(null);
  const updateTimer = useRef(null);

  const updateEntry = ({ target: { value, name } }) => {
    if (updateTimer.current) clearTimeout(updateTimer.current);

    updateTimer.current = setTimeout(() => {
      submitEntry(name, value);
    }, 1000);
  };

  const submitEntry = (name, value) =>
    saveEntry(entry.objectId, { [name]: value });

  useEffect(() => {
    savedEntry && setEntry({ ...savedEntry });
  }, [savedEntry]);

  useEffect(() => {
    savedEntry && setSaved(true);
  }, [savedEntry]);

  useEffect(() => {
    if (saved) {
      savedTimer.current = setTimeout(() => {
        setSaved(false);
      }, 2000);
    }
  }, [saved]);

  return (
    <>
      <input
        type="text"
        name="description"
        onChange={updateEntry}
        disabled={!editing}
        className={`${
          editing && 'hover:bg-blue-50'
        } text-center text-xxs py-1 border-gray-200 border-t-0 border-l-0 border-r border-b focus:border-gray-200 focus:outline-none focus:ring-0`}
        defaultValue={entry.description || '-'}
      />
      <input
        type="text"
        name="value"
        onChange={updateEntry}
        disabled={!editing}
        className={`${
          editing && 'hover:bg-blue-50'
        } text-center text-xxs py-1 border-gray-200 border-t-0 border-l-0 border-r border-b focus:border-gray-200 focus:outline-none focus:ring-0`}
        defaultValue={entry.value || '-'}
      />
      {saving && (
        <div className="absolute top-6 right-2 flex items-center">
          <Loader color="text-gray-700" />
        </div>
      )}
      {saved && (
        <div className="absolute top-6 right-2 flex items-center">
          <BiCheckCircle
            size={16}
            className="bg-green-500 text-white rounded-full overflow-hidden"
          />
        </div>
      )}
    </>
  );
}
