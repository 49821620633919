import {
  AiOutlineFileExcel,
  AiOutlineFileGif,
  AiOutlineFileImage,
  AiOutlineFileJpg,
  AiOutlineFilePdf,
  AiOutlineFileUnknown,
  AiOutlineFileWord,
  AiOutlineVideoCamera,
} from 'react-icons/ai';

const fileIcons = (type) => {
  if (type.includes('pdf')) return AiOutlineFilePdf;
  if (
    type.includes('msword') ||
    type.includes('doc') ||
    type.includes('ms-doc') ||
    type.includes('vnd.openxmlformats-officedocument.wordprocessingml.document')
  )
    return AiOutlineFileWord;
  if (
    type.includes('excel') ||
    type.includes('vnd.ms-excel') ||
    type.includes('x-excel') ||
    type.includes('x-msexcel') ||
    type.includes('vnd.openxmlformats-officedocument.spreadsheetml.sheet')
  )
    return AiOutlineFileExcel;
  if (type.includes('gif')) return AiOutlineFileGif;
  if (type.includes('jpeg')) return AiOutlineFileJpg;
  if (type.includes('image')) return AiOutlineFileImage;
  if (type.includes('video')) return AiOutlineVideoCamera;
  if (type.includes('tiff')) return AiOutlineFileImage;
  return AiOutlineFileUnknown;
};

export default function FileIcon({ file, ...rest }) {
  const IconComponent = fileIcons(file.type);
  return <IconComponent {...rest} />;
}
