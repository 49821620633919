import { Link } from 'react-router-dom';

const EmailConfirmationScreen = () => {
  return (
    <div className="text-center">
      <h2 className="text-2xl font-bold text-gray-800 mb-4">Check Your Email</h2>
      <p className="text-gray-600 mb-4">
        We've sent a confirmation link to your email address. Please check your inbox and click the link to verify your account.
      </p>
      <p className="text-blue-800 mb-6 text-sm">
        <span className="font-bold">Check your spam folder</span> if you don't see the email in your inbox.
      </p>
    </div>
  );
};

export default EmailConfirmationScreen;