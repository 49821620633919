import { LogLevel } from "@azure/msal-common";
import { environment } from "../config";

const msalConfig = {
    auth: {
        clientId: environment.azureClientId,
        knownAuthorities: [],
        redirectUri: `${window.location.origin}/auth/microsoft-callback`,
        postLogoutRedirectUri: `${window.location.origin}/sign_out`,
        navigateToLoginRequestUrl: true,
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (
                level: LogLevel,
                message: string,
                containsPii: boolean
            ): void => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            },
            piiLoggingEnabled: false,
        },
        windowHashTimeout: 60000,
        iframeHashTimeout: 6000,
        loadFrameTimeout: 0,
    },
};

export default msalConfig;