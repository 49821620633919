import { useEffect } from 'react';
import Card from '../../utils/Card';
import { FaClipboard, FaMap } from 'react-icons/fa';
import { List } from '../../utils/Layout';
import { H3 } from '../../utils/Typography';
import Button from '../shared/Button';
import { ReportType } from '../../data/models';
import { FiArchive, FiMapPin, FiRepeat } from 'react-icons/fi';
import { ConfirmationModal } from '../utility/Modal';
import useModal from '../../hooks/useModal';
import useReportType from './hooks/useReportType';
import { useParams } from 'react-router-dom';
import { Dot } from '../shared/Badge';
import Loader from '../utility/Loader';
import { BiCheckCircle } from 'react-icons/bi';
export default function ReportTypeCard({
    reportType,
    afterArchived,
    afterUpdated,
    afterDuplication,
    onEdit
}: {
    reportType: ReportType;
    onEdit?: (reportType: ReportType) => void;
    afterArchived?: (reportType: ReportType) => void;
    afterUpdated?: (reportType: ReportType) => void;
    afterDuplication?: (reportType: ReportType) => void;
}) {
    const { open, setOpen } = useModal();
    const { open: openDuplication, setOpen: setOpenDuplication } = useModal();
    const { open: openStatusUpdate, setOpen: setOpenStatusUpdate } = useModal();
    const { workspaceId } = useParams<{ workspaceId: string }>();


    const {
        updatedReportType,
        updateReportTypeDebounced,
        updated,
        updating,
        unarchiveReportType,
        archiving,
        archived,
        unarchived,
        archiveReportType,
        duplicateToWorkspace,
        duplicating,
        duplicated
    } = useReportType(reportType.id, { load: false });

    useEffect(() => {
        if (!archived && !unarchived) return;
        setOpen(false);
        afterArchived?.(reportType);
    }, [archived, unarchived]);

    useEffect(() => {
        if (!duplicated) return;
        setOpenDuplication(false);
        afterDuplication?.(reportType);
    }, [duplicated]);

    useEffect(() => {
        if (!duplicated) return;
        setOpenDuplication(false);
        afterDuplication?.(reportType);
    }, [duplicated]);

    useEffect(() => {
        if (!updated) return;
        setOpenStatusUpdate(false);
        afterUpdated?.(reportType);
    }, [updated]);

    const cornerComponent = () => {
        if (updating) return <Loader color="text-gray-700" />;
        if (updated) return <BiCheckCircle
            size={16}
            className="bg-green-500 text-white rounded-full overflow-hidden"
        />;
        return <Dot style={reportType.status === 'active' ? 'green' : 'gray'} title={reportType.status} />;
    }

    return <>
        <Card.Card >
            <Card.Content>
                <Card.Body>
                    <Card.Header>
                        <div className="absolute top-0 right-0">
                            {cornerComponent()}
                        </div>
                        <H3 mr="mr-3"><input defaultValue={reportType.name} className={`bg-transparent focus:outline-none`} disabled={!afterUpdated} onChange={({
                            target: { value }
                        }) => updateReportTypeDebounced({ name: value })} /></H3>
                    </Card.Header>
                    <List.List>
                        {reportType.has_map_layer && <List.Item>
                            <FaMap size={16} className="mr-2" /> <div>Includes map view</div>
                        </List.Item>}
                        {reportType.includes_pins && <List.Item>
                            <FiMapPin size={16} className="mr-2" /> <div>Includes pins</div>
                        </List.Item>}
                        {reportType.includes_site_notes && <List.Item>
                            <FaClipboard size={16} className="mr-2" /> <div>Includes site note</div>
                        </List.Item>}
                    </List.List>
                </Card.Body>
                <Card.Footer>
                    <div className="flex flex-col w-full">
                        {afterArchived && !reportType.archived_at && <Button
                            text="Archive"
                            onClick={() => setOpen(true)}
                            color='light'
                            iconAligned='left'
                            icon={<FiArchive className='mr-1' />}
                            className="flex-1 mb-1"
                            data-testid={`archiveReportType${reportType.name}`}
                        />}
                        {afterDuplication && <Button
                            text="Duplicate"
                            onClick={() => setOpenDuplication(true)}
                            color='light'
                            iconAligned='left'
                            icon={<FiRepeat className='mr-1' />}
                            className="flex-1 mb-1"
                            data-testid={`duplicateReportType${reportType.name}`}
                        />}
                        {reportType.archived_at && <Button
                            text="Unarchive"
                            onClick={unarchiveReportType}
                            color='tertiary'
                            iconAligned='left'
                            className="flex-1 mb-1"
                            data-testid={`unarchiveReportType${reportType.name}`}
                        />}
                        {!!afterUpdated && reportType.status === 'draft' && <Button
                            text={<><span className="mr-2">Set active</span> <Dot style={'green'} /></>}
                            onClick={() => setOpenStatusUpdate(true)}
                            color='light'
                            iconAligned='left'
                            className="flex-1 mb-1"
                            data-testid={`activateReportType${reportType.name}`}
                        />}
                        {!!afterUpdated && reportType.status === 'active' && <Button
                            text={<><span className="mr-2">Set draft</span> <Dot style={'gray'} /></>}
                            onClick={() => setOpenStatusUpdate(true)}
                            color='light'
                            iconAligned='left'
                            className="flex-1 mb-1"
                            data-testid={`activateReportType${reportType.name}`}
                        />}
                        {onEdit && <Button
                            text="Edit"
                            onClick={() => onEdit(reportType)}
                            color='secondary'
                            className="flex-1"
                            data-testid={`editReportType${reportType.name}`}
                        />}
                    </div>
                </Card.Footer>
            </Card.Content>
        </Card.Card>
        <ConfirmationModal
            buttonTestId={`buttonConfirmArchiveReportType${reportType.name}`}
            confirming={archiving}
            confirmed={archived}
            isOpen={open}
            onClose={() => setOpen(false)}
            title="Are you sure?"
            onConfirm={archiveReportType}
            body={
                <>
                    <p className="mb-1">This will archive the {reportType.name} and <span className="font-medium">remove it as an available type on all new reports.</span></p>
                    <p className="font-medium text-tertiary text-sm mb-1">Currently used on {reportType.reports_count} reports</p>
                    <p className="italic text-secondary text-xs">Reports usign this type will remain unaffected.</p>
                </>} />
        <ConfirmationModal
            buttonTestId={`buttonConfirmDuplicationReportType${reportType.name}`}
            confirming={duplicating}
            confirmed={duplicated}
            isOpen={openDuplication}
            onClose={() => setOpenDuplication(false)}
            title="Are you sure?"
            onConfirm={() => duplicateToWorkspace(workspaceId)}
            body={
                <>
                    <p className="mb-1">This will duplicate the {reportType.name} and <span className="font-medium">set it to draft.</span></p>
                    <p className="font-medium text-tertiary text-sm mb-1">New report type will become available to workspace when set to active.</p>
                </>} />
        <ConfirmationModal
            buttonTestId={`buttonConfirmStatusUpdateReportType${reportType.name}`}
            confirming={updating}
            confirmed={updated}
            isOpen={openStatusUpdate}
            onClose={() => setOpenStatusUpdate(false)}
            title="Are you sure?"
            onConfirm={() => updateReportTypeDebounced({ status: reportType.status === 'draft' ? 'active' : 'draft' })}
            body={
                <>
                    <p className="mb-1">This update status of {reportType.name} to <span className="font-medium">{reportType.status === 'draft' ? 'active' : 'draft'}.</span></p>
                    <p className="font-medium text-tertiary text-sm mb-1">{reportType.status === 'draft' ? 'Report type will become available to workspace.' : 'Report type will become unavailable to workspace.'}</p>
                </>} />
    </>
}