import React, { useState, useEffect, memo, useLayoutEffect } from 'react';
import { FaShareAlt } from 'react-icons/fa';
import Modal, { ModalFooter, ModalBody, ModalHeader } from '../utility/Modal';
import CopyButton from '../utility/CopyButton';
import useApi from '../../hooks/useApi';
import accessCodesApi from '../../api/access_codes';
import Loader from '../utility/Loader';
import { AddExtraProps } from '../../utility/addExtraProps';

function AccessCodeModal({
  button,
  workspace,
  modalOpen: passedModalOpen,
  onClose,
}) {
  const {
    data: { records: access_codes, pagy },
    error,
    loading,
    request: getAccessCodes,
  } = useApi(accessCodesApi.getAccessCodes, { records: [], pagy: {} });

  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    onClose?.();
    setModalOpen(!modalOpen);
  };

  const { origin } = window.location;

  const accessCodeSearchParams = () => {
    let params = { where: { active: true } };
    if (workspace) params.workspace_id = workspace.id;
    return params;
  };

  useLayoutEffect(() => {
    setModalOpen(passedModalOpen);
  }, [passedModalOpen]);

  useEffect(() => {
    modalOpen && getAccessCodes(accessCodeSearchParams());
  }, [modalOpen]);

  return (
    <>
      {button ? (
        AddExtraProps(button, { onClick: toggleModal })
      ) : (
        <a
          className="flex -ml-1 cursor-pointer btn text-gray-100 flex bg-secondary hover:text-gray-50 hover:opacity-90"
          onClick={toggleModal}
        >
          <FaShareAlt className={'opacity-70'} />
          <span className="hidden xs:block ml-2">Share</span>
        </a>
      )}
      <Modal isOpen={modalOpen} onClose={toggleModal}>
        <ModalHeader title={'Invite Link'} onClose={toggleModal} />
        <ModalBody>
          {loading && (
            <div className="flex justify-center py-10">
              <Loader color="black" />
            </div>
          )}
          {!loading && access_codes[0] && (
            <>
              <p className="font-medium pb-3 border-b mb-3">
                Share link or access code below to members you want <br />
                to join{' '}
                {workspace && (
                  <>
                    <span className="text-secondary font-semibold">
                      {workspace.name}
                    </span>{' '}
                    on{' '}
                  </>
                )}
                Sitemarker.
              </p>
              <div className="py-3">
                <p className="font-black">Link</p>
                <div className="flex items-center">
                  <a
                    className="text-secondary underline"
                    rel="noreferrer"
                    href={`${origin}/onboarding/sign_up?code=${access_codes[0].code}`}
                    target="_blank"
                  >
                    https://sitemarker.com/onboarding/sign_up?code=
                    {access_codes[0].code}
                  </a>
                  <CopyButton
                    text={`${origin}/onboarding/sign_up?code=${access_codes[0].code}`}
                  />
                </div>
              </div>
              <div className="py-3">
                <p className="font-black">Access Code</p>
                <div className="flex items-center mt-2">
                  <p className="text-xl text-primary font-bold">
                    {access_codes[0].code}
                  </p>
                  <CopyButton text={access_codes[0].code} />
                </div>
              </div>
            </>
          )}
          {!loading && access_codes.length == 0 && (
            <div className="py-5 px-3">
              <p className="font-bold text-center text-xl mb-2">Uh oh!</p>
              <p className="font-medium text-center text-lg">
                You don't currently have acces to invite members to join
                Sitemarker.
              </p>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <button
            className="modal-close-btn hidden sm:block"
            type="button"
            onClick={toggleModal}
          >
            Done
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default memo(AccessCodeModal);
