import { useEffect } from 'react';
import useApi from '../../../hooks/useApi';
import workspacesApi from '../../../api/workspaces';
import useSaved from '../../../hooks/useSaved';
import { Workspace } from '../../../data/models';
export default function useWorkspace(workspaceId: string,
    { load = true }: { load?: boolean } = {}
) {
    const {
        data: workspace,
        error,
        loading,
        request: getWorkspace,
    } = useApi(workspacesApi.getWorkspace, null, load);

    const {
        data: updatedWorkspace,
        error: updateError,
        loading: updating,
        request: saveWorkspace,
    } = useApi(workspacesApi.updateWorkspace, null, false);

    const { saved: updated } = useSaved(updatedWorkspace?.updated_at);

    const _saveWorkspace = (workspace: Workspace) => {
        saveWorkspace(workspaceId, workspace);
    }

    const reloadWorkspace = () => {
        getWorkspace(workspaceId);
    }

    useEffect(() => {
        if (!load) return;
        reloadWorkspace();
    }, [load])

    return {
        workspace,
        error,
        loading,
        getWorkspace,
        updatedWorkspace,
        updateError,
        updating,
        updated,
        saveWorkspace: _saveWorkspace,
        reloadWorkspace,
    }
}