import React, { useState, useEffect } from 'react';
export default function TagInput({ tag, onUpdate }) {
  const [tagName, setTagName] = useState(tag?.name);

  const onBlur = () => {
    onUpdate?.({ ...tag, name: tagName });
  };

  const onKeyDown = async ({ key, target: { value: name } }) => {
    if (key === 'Enter') onUpdate?.({ ...tag, name });
  };

  return (
    <input
      className="w-full"
      autoFocus
      value={tagName}
      onChange={({ target: { value } }) => setTagName(value)}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
    />
  );
}
