import { useRef } from "react";
import Transition from "../../utils/Transition"

export default function Dropdown({
    align = "right",
    margin = "mt-1",
    padding = "py-1.5",
    open,
    children
}: {
    align?: "left" | "right",
    margin?: string,
    padding?: string,
    open: boolean,
    children: React.ReactNode
}) {
    const dropdown = useRef(null);

    return <Transition
        className={`${align === 'right' ? 'origin-top-right' : 'origin-top-left'} z-10 absolute top-full sm:mr-0 min-w-80 bg-white border border-gray-200 ${padding} rounded shadow-lg overflow-hidden ${margin}`}
        show={open}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0" appear={undefined}    >
        <div ref={dropdown}>
            {children}
        </div>
    </Transition>


}