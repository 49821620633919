import React, { memo } from 'react';
import { useParams } from 'react-router-dom';
import EntryPin from '../entries/EntryPin';

function EntryGroupPins({ report, entries, editing }) {
  const { report_id } = useParams();

  return (
    <>
      {entries.map((entry, index) => {
        let { value, description } = entry;
        return (
          <EntryPin
            key={`report_${report.objectId}_entry_pin_${value}`}
            entry={entry}
            report={report}
            pinId={value}
            index={description}
            editing={editing}
            includeBreak={!(index === value.length - 1)}
          />
        );
      })}
    </>
  );
}

export default memo(EntryGroupPins);
