import users from '../data/users';
import sign from 'jwt-encode';

const invited = ({ email }) => {
  let user = users.find((user) => user.email == email);
  return { ok: true, data: { invited: user !== undefined, accepted: true } };
};

const login = ({ user: { email, password } }) => {
  let user = users.find((user) => user.email == email);

  if (!user) return { ok: false, data: { error: 'Email does not exist' } };
  if (user.password !== password)
    return { ok: false, data: { error: 'Password is incorrect' } };

  const jwt = sign(user, 'supersecretsecret12345');

  return { ok: true, headers: { authorization: `Bearer ${jwt}` }, data: {} };
};

export { invited, login };
