import { ReactNode, useEffect, useRef } from 'react';
import { FiCheckCircle, FiX } from 'react-icons/fi';
import Transition from '../../utils/Transition';
import randomString from '../../utility/randomString';
import useSidebarCollapsed from '../../hooks/useSidebarCollapsed';
import Button from '../shared/Button';

type Position = 'absolute' | 'fixed' | 'relative' | 'static' | 'sticky';

function ModalBody({ className = "px-7 pt-7 pb-5", position, children }: {
  className?: string,
  position?: Position,
  children: ReactNode
}) {
  return (
    <div className={`${className} ${position}`}>
      {children}
    </div>
  );
}


function ModalSuccessView({ text }: {
  text: string
}) {
  return (
    <div className="py-12 text-base text-center font-semibold text-gray-900">
      <FiCheckCircle className="mx-auto mb-4 text-sm-success" size={80} />
      <div>{text}</div>
    </div>
  );
}

interface ModalColors {
  [key: string]: string;
}

const MODAL_COLORS: ModalColors = {
  default: 'bg-white  text-gray-800 border-gray-200',
  danger: 'bg-red-500 text-white border-red-200',
};

function ModalHeader({
  title,
  onClose,
  closeBtnClass = 'focus:outline-none',
  className = 'flex items-center justify-between px-5 py-3 border-b border-gray-200 bg-white',
  closeBtnTestId = 'button-modal-close',
  color = 'default',
  extraClass = '',
  sticky = false,
  ...rest
}: Partial<{
  title: ReactNode;
  onClose: () => void;
  closeBtnClass: string;
  color: string;
  className: string;
  closeBtnTestId: string;
  extraClass: string;
  sticky?: boolean;
}>) {
  return (
    <div
      className={`text-left flex items-center justify-between px-5 py-3 border-b ${MODAL_COLORS[color]} ${sticky ? 'sticky top-0 z-20' : ''} ${extraClass}`}
      {...rest}
    >
      <p className="text-lg font-bold w-full">{title}</p>
      <button
        className={closeBtnClass}
        onClick={onClose}
        data-testid={closeBtnTestId}
      >
        <FiX size={28} className="hover:opacity-60 cursor-pointer" />
      </button>
    </div>
  );
}

function ModalFooter({
  children,
  extraClass = '',
  justify = 'justify-end',
  fixed = false,
  sticky = false,
}: {
  children: ReactNode,
  extraClass?: string,
  justify?: string,
  fixed?: boolean
  sticky?: boolean
}) {

  const { collapsed } = useSidebarCollapsed();

  return (
    <div
      className={`flex items-center ${justify} ${extraClass} px-6 py-4 border-t border-gray-200 bg-white 
      ${fixed && `fixed bottom-0 right-0 left-0 ${collapsed ? 'lg:left-16' : 'lg:left-52'}`}
      ${sticky && `sticky bottom-0 right-0 left-0 ${collapsed ? 'lg:left-16' : 'lg:left-52'}`}
      `}
    >
      {children}
    </div>
  );
}

function ConfirmationModal({
  isOpen,
  confirmed,
  confirming,
  onConfirm,
  onClose,
  title,
  body,
  buttonText = "Confirm",
  buttonTestId = "buttonConfirmConfirmation",
  cancelButtonTestId = "buttonCancelConfirmation",
}: {
  isOpen: boolean,
  confirming?: boolean,
  confirmed?: boolean,
  onConfirm: (e?: any) => void,
  onClose: () => void,
  title: React.ReactNode,
  body: React.ReactNode,
  buttonText?: string,
  buttonTestId?: string
  cancelButtonTestId?: string
}) {
  return <Modal id="danger-modal" isOpen={isOpen} onClose={onClose} maxWidth="max-w-xl relative">
    <div className="p-5 space-x-4">
      {/* Content */}
      <div>
        {/* Modal header */}
        <div className="mb-2">
          <div className="text-lg font-semibold text-gray-800">{title}</div>
        </div>
        {/* Modal content */}
        <div className="text-sm mb-10">
          {body}
        </div>
        {/* Modal footer */}
        <div className="flex flex-wrap justify-end space-x-2">
          <Button color='gray' data-testid={cancelButtonTestId} text='Cancel' onClick={(e) => { e.stopPropagation(); onClose(); }} />
          <Button color='tertiary' data-testid={buttonTestId} saving={confirming} saved={confirmed} savedText='Confirmed!' text={buttonText} onClick={onConfirm} />
        </div>
      </div>
    </div>
  </Modal>
}


export {
  ConfirmationModal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  ModalSuccessView,
};

export default function Modal({
  children,
  maxWidth = 'max-w-2xl',
  maxHeight = 'max-h-full',
  overflow = '',
  bgColor = 'bg-gray-50',
  dialogClass = '',
  dialogPadding = 'px-0 sm:px-6',
  modalClass = 'rounded-lg',
  dialogScrolls = false,
  modalScrolls = true,
  aligned = 'items-center',
  isOpen = false, // TODO: feels like this should probably default to true
  hideBackdrop = false,
  side,
  onClose,
}: any) {
  const modalContent = useRef(null);
  const backdropId = randomString();

  // close on click outside
  useEffect(() => {
    const clickHandler = (event: MouseEvent): void => {
      const { target } = event;
      if (
        target instanceof Element &&
        (target.getAttribute('aria-modal') === 'true' ||
          target.getAttribute('aria-hidden') === 'true') &&
        target.getAttribute('modal-id') === backdropId
      )
        onClose?.();
    };
    document.addEventListener('mousedown', clickHandler);
    return () => document.removeEventListener('mousedown', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (isOpen && keyCode === 27) {
        onClose?.();
      }
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <>
      {/* Modal backdrop */}
      {!hideBackdrop && (
        // TODO: correct our usage of the Transition component
        // @ts-ignore
        <Transition
          className={`fixed inset-0 bg-gray-900 bg-opacity-30 z-60 transition-opacity`}
          show={isOpen}
          enter="transition ease-out duration-200"
          enterStart="opacity-0"
          enterEnd="opacity-100"
          leave="transition ease-out duration-100"
          leaveStart="opacity-100"
          leaveEnd="opacity-0"
          aria-hidden="true"
          modal-id={backdropId}
        />
      )}
      {/* Modal dialog */}
      {/* TODO: correct our usage of the Transition component */}
      {/* @ts-ignore */}
      <Transition
        className={`fixed ${side ? 'top-0 right-0 h-full' : 'inset-0'
          } z-60 overflow-${dialogScrolls ? 'auto' : 'hidden'
          } flex ${aligned} justify-center transform ${dialogPadding} ${dialogClass}`}
        role="dialog"
        aria-modal="true"
        show={isOpen}
        enter="transition ease-in-out duration-200"
        enterStart={`opacity-0 ${side ? 'translate-x-4' : 'translate-y-4'}`}
        enterEnd={`opacity-100 ${side ? 'translate-x-0' : 'translate-y-0'}`}
        leave="transition ease-in-out duration-200"
        leaveStart={`opacity-100 ${side ? 'translate-x-0' : 'translate-y-0'}`}
        leaveEnd={`opacity-0 ${side ? 'translate-x-4' : 'translate-y-4'}`}
        modal-id={backdropId}
      >
        {/* Modal Content */}
        <div
          className={`${bgColor} ${modalScrolls ? 'overflow-auto' : overflow
            } ${maxWidth} w-full ${maxHeight} shadow-lg ${modalClass}`}
          ref={modalContent}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            return false;
          }}
        >
          {children}
        </div>
      </Transition>
    </>
  );
}
