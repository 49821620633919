import client from './client';

const endpoint = '/feature_announcements';

const getFeatureAnnouncements = () => client.get(`${endpoint}`);
const getFeatureAnnouncement = (id) => client.get(`${endpoint}/${id}`);
const createFeatureAnnouncement = (feature_announcement) => client.post(`${endpoint}`, { feature_announcement });
const updateFeatureAnnouncement = (id, feature_announcement) => client.put(`${endpoint}/${id}`, { feature_announcement });
const destroyFeatureAnnouncement = (id) => client.delete(`${endpoint}/${id}`);

export default {
    getFeatureAnnouncements,
    getFeatureAnnouncement,
    createFeatureAnnouncement,
    updateFeatureAnnouncement,
    destroyFeatureAnnouncement,
};
