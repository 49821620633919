import React from 'react';
import useModal from '../../hooks/useModal';
import MapContainer from '../maps/MapContainer';
import CreateReportModal from './CreateReportModal';
export default function CreateReportButton({ afterAdd }) {
  const { open, toggle } = useModal();
  return (
    <>
      <a className="cursor-pointer" onClick={toggle}>
        <div className="btn rounded hover:text-gray-50 hover:opacity-90 text-white bg-secondary">
          <svg
            className="w-4 h-4 fill-current opacity-50 flex-shrink-0"
            viewBox="0 0 16 16"
          >
            <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
          </svg>
          <span className="text-xs xs:text-sm ml-2">Create Report</span>
        </div>
      </a>
      <MapContainer loadPins={open}>
        <CreateReportModal open={open} afterAdd={afterAdd} onClose={toggle} />
      </MapContainer>
    </>
  );
}
