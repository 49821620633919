import foldersApi from '../../../api/folders';
import useApi from '../../../hooks/useApi';
export default function useFolders() {
    // Archiving
    const {
        data: archivedFolderData,
        loading: archiveFolderLoading,
        error: archiveFolderError,
        request: archiveFolder,
    } = useApi(foldersApi.archiveFolder, null);
    // Archiving
    const {
        data: unarchivedFolderData,
        loading: unarchiveFolderLoading,
        error: unarchiveFolderError,
        request: unarchiveFolder,
    } = useApi(foldersApi.unarchiveFolder, null);

    return {
        archivedFolderData,
        archiveFolderLoading,
        archiveFolderError,
        archiveFolder,
        unarchivedFolderData,
        unarchiveFolderLoading,
        unarchiveFolderError,
        unarchiveFolder,
    }
}