import React, { memo, useEffect } from 'react';
import MeetingQuickviewModal from '../meetings/MeetingQuickviewModal';
import NewMeetingModal from '../meetings/NewMeetingModal';
import meetingsApi from '../../api/meetings';
import useApi from '../../hooks/useApi';
import MeetingsModal from '../meetings/MeetingsModal';
import Loader from '../utility/Loader';
import { useParams } from 'react-router-dom';

function ProjectMeetingsCard() {
  const {
    data: { records: meetings, pagy },
    error,
    loading,
    request: getMeetings,
  } = useApi(meetingsApi.getMeetings, { records: [], pagy: {} }, true);
  const { project_id } = useParams();
  const loadLatestMeetings = () => getMeetings(project_id, { items: 4 });

  useEffect(() => {
    loadLatestMeetings();
  }, []);

  return (
    // change xl col span to indicate horizontal card size 1-12
    <div className="bg-white rounded-sm border border-gray-200 overflow-hidden text-black mb-6 shadow-xl rounded-lg">
      <div className="flex flex-col h-full">
        <div className="card-header">
          <p className="text-sm font-bold">
            {loading ? '...' : 'Site Meetings (' + pagy.count + ')'}
          </p>
          <MeetingsModal />
        </div>
        {loading && (
          <div className="flex justify-center py-5">
            <Loader color="black" />
          </div>
        )}
        {!loading && (
          <div className="grid grid-cols-5 gap-4 p-5 h-36 text-center text-xxs">
            {meetings.map((meeting) => (
              <MeetingQuickviewModal
                key={`project_meetings_card_meeting_${meeting.objectId}`}
                meeting={meeting}
              />
            ))}
            <NewMeetingModal afterAdd={loadLatestMeetings} />
          </div>
        )}
      </div>
    </div>
  );
}

export default memo(ProjectMeetingsCard);
