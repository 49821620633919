import React, { useState, useEffect } from 'react';
import { BiXCircle } from 'react-icons/bi';
import { FiX } from 'react-icons/fi';
import Loader from '../utility/Loader';
import Modal from '../utility/Modal';
export default function EntryDateActivityRemoveButton({
  showModal,
  onConfirm,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [confirming, setConfirming] = useState(false);

  return (
    <>
      <div
        className="absolute opacity-0 group-hover:opacity-100 right-0 top-0 h-full w-10 flex justify-center items-center"
        onClick={() => (showModal ? setModalOpen(true) : onConfirm())}
      >
        <BiXCircle
          size={20}
          className="hover:opacity-80 cursor-pointer rounded-full overflow-hidden bg-black text-white"
        />
      </div>
      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
        <div className="flex items-center justify-between px-5 py-3 bg-primary">
          <p className="text-lg font-bold text-white">Remove entry?</p>
          <button
            className="focus:outline-none"
            onClick={() => setModalOpen(false)}
          >
            <FiX
              size={28}
              className="text-white hover:opacity-60 cursor-pointer"
            />
          </button>
        </div>
        <div className="px-2 pt-2">
          <div className="p-5">
            <div className="mb-4">
              <div className="font-semibold text-base text-center mb-3">
                Are you sure you want to remove this entry?
              </div>
              <div className="text-center text-sm">
                * If confirmed entry will not be auto-included in any subsequent
                reports
              </div>
            </div>
            <div></div>
          </div>
          <div className="modal-footer">
            <button
              className="modal-remove-btn"
              type="button"
              disabled={confirming}
              onClick={() => {
                setConfirming(true);
                onConfirm && onConfirm();
              }}
            >
              Remove Entry {confirming && <Loader />}
            </button>
            <button
              className="modal-close-btn"
              type="button"
              disabled={confirming}
              onClick={() => setModalOpen(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
