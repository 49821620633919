import { useEffect } from 'react';
import useApi from './useApi';
import projectContactsApi from '../api/project_contacts';
import { ProjectContact } from '../data/models';

const useProjectContacts = (projectId: string | undefined, {
  load = true,
}: {
  load?: boolean
} = {}): {
  contacts: ProjectContact[],
  loading: boolean,
  reloadContacts: () => void
} => {
  const {
    data: {
      records: contacts,
      pagy,
    },
    loading,
    request: getProjectContacts
  } = useApi(projectContactsApi.getProjectContacts, { records: [], pagy: {} }, !!projectId && load);

  const reloadContacts = () => {
    if (!projectId) return;
    getProjectContacts(projectId);
  }

  useEffect(() => {
    if (!load) return;
    reloadContacts();
  }, [load, projectId]);

  return {
    contacts,
    loading,
    reloadContacts,
  };
};

export default useProjectContacts;
