import {
  FaFileAlt,
  FaFileArchive,
  FaFilePdf,
  FaFolder,
  FaFileWord,
  FaFileExcel,
  FaFilePowerpoint,
  FaFileImage,
  FaFileVideo,
} from 'react-icons/fa';
function DocumentName({ type, name, onClick }) {
  return (
    <>
      <DocumentIcon type={type} />
      <span data-tag="allowRowEvents" className="pl-2">
        {name}
      </span>
    </>
  );
}
export default DocumentName;

export function DocumentIcon(props) {
  if (props.type === 'folder')
    return (
      <FaFolder data-test-id="folder" size={16} className="flex-shrink-0" />
    );
  if (props.type === 'pdf')
    return <FaFilePdf data-test-id="pdf" size={16} className="flex-shrink-0" />;
  if (props.type === 'zip')
    return (
      <FaFileArchive
        data-test-id="archive"
        size={16}
        className="flex-shrink-0"
      />
    );
  if (['doc', 'docx'].includes(props.type))
    return (
      <FaFileWord data-test-id="word" size={16} className="flex-shrink-0" />
    );
  if (['xls', 'xlsx', 'xlsm'].includes(props.type))
    return (
      <FaFileExcel data-test-id="excel" size={16} className="flex-shrink-0" />
    );
  if (['ppt', 'pptx'].includes(props.type))
    return (
      <FaFilePowerpoint
        data-test-id="powerpoint"
        size={16}
        className="flex-shrink-0"
      />
    );
  if (['jpeg', 'jpg', 'png'].includes(props.type))
    return (
      <FaFileImage data-test-id="image" size={16} className="flex-shrink-0" />
    );
  if (['mov', 'mp4', 'wmv', 'avi'].includes(props.type))
    return (
      <FaFileVideo data-test-id="video" size={16} className="flex-shrink-0" />
    );
  return (
    <FaFileAlt data-test-id="generic" size={16} className="flex-shrink-0" />
  );
}
