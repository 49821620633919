import client from './client';

const endpoint = '/notes';

const getNotes = (parent = '', parentId = '', params = {}) =>
  client.get(
    `${parent}${parent.length ? 's/' : '/'}${parentId}${endpoint}`,
    params
  );
const getNote = (noteId, params = {}) =>
  client.get(`${endpoint}/${noteId}`, params);
const addNote = (parent = '', parentId = '', note) =>
  client.post(`${parent}${parent.length ? 's/' : '/'}${parentId}${endpoint}`, {
    note,
  });
const updateNote = (noteId, note) =>
  client.put(
    `${endpoint}/${noteId}`,
    { note }
  );

const destroyNote = (noteId) =>
  client.delete(
    `${endpoint}/${noteId}`
  );

export default {
  getNotes,
  getNote,
  addNote,
  destroyNote,
  updateNote,
};
