import { useHistory } from 'react-router-dom';
import Button from './Button';

export default function BackButton() {
  const history = useHistory();

  // Button doesn't have type signatures yet.
  return (
    /* @ts-ignore */
    <Button
      className="hidden sm:flex px-3 mr-2"
      color="secondary"
      onClick={() => history.goBack()}
      text={'← Back'}
      size={'sm'}
    />
  );
}
