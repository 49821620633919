import client from './client';
const endpoint = '/meetings';

const getMeetings = (projectId, params = {}) =>
  client.get(`/projects/${projectId}${endpoint}`, params);
const getMeeting = (id, params = {}) => client.get(`${endpoint}/${id}`, params);
const addMeeting = (projectId, meeting) =>
  client.post(`/projects/${projectId}${endpoint}`, { meeting });
const updateMeeting = (meetingId, meeting) =>
  client.put(`${endpoint}/${meetingId}`, { meeting });

export default {
  getMeetings,
  getMeeting,
  addMeeting,
  updateMeeting,
};
