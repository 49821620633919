import React, { useEffect, useState } from 'react';
import { BiCheck } from 'react-icons/bi';
import useApi from '../../hooks/useApi';
import projectMembersApi from '../../api/project_members';
import Loader from '../utility/Loader';
import { useParams } from 'react-router-dom';

export default function AddMemberItem({ workspace_member, afterUpdate }) {
  const [added, setAdded] = useState(false);
  const { project_id } = useParams();

  const {
    data: new_member,
    error,
    loading,
    request: addProjectMember,
  } = useApi(projectMembersApi.addProjectMember, false);
  const {
    data: removed_member,
    error: removeError,
    loading: removeLoading,
    request: removeProjectMember,
  } = useApi(projectMembersApi.destroyProjectMember, false);

  const createNewProjectMember = () => {
    addProjectMember(project_id, { member_id: workspace_member.member.id });
  };

  const deleteProjectMember = () => {
    removeProjectMember(new_member.objectId);
  };

  useEffect(() => {
    setAdded(true);
  }, [new_member]);

  useEffect(() => {
    setAdded(false);
  }, [removed_member]);

  useEffect(() => {
    if ((added || removed_member) && afterUpdate) afterUpdate();
  }, [added, removed_member]);

  return (
    <div className="flex items-center justify-between p-3 border-b border-gray-200">
      <div className="flex items-center">
        <img
          className="rounded-full w-12 h-12 object-cover mr-4"
          src={
            workspace_member.member.profile_photos?.medium ||
            'https://bucketeer-d9937a0d-80e4-469a-91e2-6c86bd37d08f.s3.amazonaws.com/public/user_empty.png'
          }
        />
        <div className="text-left">
          <p className="truncate">
            {workspace_member.member.name || workspace_member.member.email}
          </p>
          {workspace_member.invitationPending?.() && (
            <p className="italic text-xs text-tertiary text-xs truncate">
              Invitation pending
            </p>
          )}
          <p className="font-bold text-xs truncate">
            {workspace_member.member.title}
          </p>
        </div>
      </div>
      {added ? (
        <a
          onClick={deleteProjectMember}
          className="flex text-white hover:opacity-80 text-sm font-semibold bg-green-500 px-3 py-1 rounded-md cursor-pointer"
        >
          Added to project{' '}
          {removeLoading ? (
            <div className="ml-3">
              <Loader />
            </div>
          ) : (
            <BiCheck size={20} className="ml-2 text-white" />
          )}
        </a>
      ) : (
        <a
          onClick={createNewProjectMember}
          className="flex text-white hover:opacity-80 text-sm font-semibold bg-tertiary-light px-3 py-1 rounded-md cursor-pointer"
        >
          Add to project{' '}
          {loading && (
            <div className="ml-3">
              <Loader />
            </div>
          )}
        </a>
      )}
    </div>
  );
}
