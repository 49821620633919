import React, { useEffect, useState } from 'react';
import { BiXCircle, BiCheckCircle } from 'react-icons/bi';
import { AiOutlineFilePdf } from 'react-icons/ai';
import useApi from '../../hooks/useApi';
import { uploadAttachment } from '../../hooks/useS3';
import constructionDrawingsApi from '../../api/construction_drawings';
import Loader from '../utility/Loader';
import { useParams } from 'react-router-dom';

export default function AddPDFItem({
  adding = true,
  constructionDrawing,
  file,
  index,
  description: passedDescription,
  onRemove,
  onSaved,
  startSaving,
}) {
  const {
    data: created_drawing,
    error,
    loading: saving,
    request: mutateConstructionDrawing,
  } = useApi(
    adding
      ? constructionDrawingsApi.addConstructionDrawing
      : constructionDrawingsApi.updateConstructionDrawing,
    null
  );
  const [signing, setSigning] = useState(false);
  const [signedId, setSignedId] = useState(null);
  const { project_id } = useParams();

  const signPDF = async () => {
    setSigning(true);
    let signed_id = await uploadAttachment(file);
    setSignedId(signed_id);
    setSigning(false);
  };

  const savePDF = async () => {
    let pdfToCreate = {
      name: file.name,
      assets_attributes: [{ file: signedId }],
    };
    mutateConstructionDrawing(
      adding ? project_id : constructionDrawing.objectId,
      pdfToCreate
    );
  };

  useEffect(() => {
    if (!startSaving || saving) return;
    signPDF();
  }, [startSaving]);

  useEffect(() => {
    if (!signedId) return;
    savePDF();
  }, [signedId]);

  useEffect(() => {
    if (!created_drawing) return;
    onSaved?.(signedId);
  }, [created_drawing]);

  return (
    <div
      className="disabled:opacity-50 group relative mr-2 mb-2"
      disabled={saving}
    >
      {!created_drawing && !saving && !signing && (
        <BiXCircle
          size={20}
          className="hover:opacity-80 cursor-pointer rounded-full overflow-hidden absolute -top-1 -right-1 bg-black text-white opacity-0 group-hover:opacity-100"
          onClick={() => onRemove && onRemove(index)}
        />
      )}
      {(saving || signing) && (
        <div className="absolute w-full flex h-full justify-center items-center">
          <Loader color={'gray-800'} margin={''} />
        </div>
      )}
      {created_drawing && (
        <div className="absolute w-full flex h-full justify-center items-center">
          <BiCheckCircle
            size={40}
            className="bg-green-500 text-white rounded-full overflow-hidden"
          />
        </div>
      )}
      <div className="h-28 w-28 border border-secondary rounded-md flex flex-col justify-between items-center ">
        <div className={'h-20 flex items-center'}>
          <AiOutlineFilePdf size={42} className={`text-secondary`} />
        </div>
        <div className="bg-gray-100 border-t px-2 py-1 rounded-b-md w-full">
          <p className="text-xxs line-clamp-2 text-center text-gray-700 font-medium">
            {file.name}
          </p>
        </div>
      </div>
    </div>
  );
}
