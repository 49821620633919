import { FiMapPin } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
export default function ZoomToPinButton({ asset: { assetable } }) {
  const { project_id, workspace_id } = useParams();
  const isPin = assetable?.notable_type == 'Pin';
  const goToPin = () =>
    window.open(
      `/${workspace_id}/projects/${project_id}/maps?pin=${assetable.notable_id}`,
      '_blank'
    );
  if (!isPin) return null;
  return (
    <div
      key={`zoom_to_pin`}
      title="View pin on map"
      className={`px-2 bg-black flex items-center h-12 w-12 rounded-full flex justify-center focus:outline-none active:bg-gray-900 disabled:opacity-50 mr-2 hover:bg-gray-900 cursor-pointer text-white`}
      onClick={goToPin}
    >
      <FiMapPin size={24} />
    </div>
  );
}
