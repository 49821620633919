import React, { memo } from 'react';
import { FiClock } from 'react-icons/fi';

import { Link } from 'react-router-dom';
import humanize from '../../utility/humanize';
import EditMenu from '../EditMenu';
import EditPermissionsModal from './EditPermissionsModal';
import WorkspaceMemberViewModel from '../../models/WorkspaceMemberViewModel';
import DeleteMemberModal from './DeleteMemberModal';
import AddMemberModal from './AddMemberModal';
import useWorkspaceContext from '../../hooks/useWorkspaceContext';

function TeamTableList({ workspaceId, workspaceMembers, afterRemove }) {
  const {
    workspace: { permissions = {} },
  } = useWorkspaceContext();

  return (
    <div>
      {workspaceMembers.map((workspace_member) => {
        const workspaceMember = new WorkspaceMemberViewModel(workspace_member);
        let { id, role_id, objectId, member, role } = workspaceMember;
        return (
          <div
            key={`team_table_list_${objectId}`}
            className={`flex items-center justify-between bg-white ${workspaceMember.invitationPending?.()
              ? 'border-blue-200'
              : 'border-gray-200'
              } border-b p-5`}
          >
            <div className="flex items-center space-x-2">
              <img
                className="rounded-full w-10 h-10 object-cover"
                src={
                  member.profile_photos?.medium ||
                  'https://bucketeer-d9937a0d-80e4-469a-91e2-6c86bd37d08f.s3.amazonaws.com/public/user_empty.png'
                }
              />
              <div className="text-sm sm:text-lg justify-center font-semibold">
                {member.name || member.email}
              </div>
              <div className="pr-4 text-sm justify-center border-r">
                {member.title}
              </div>
              <EditPermissionsModal
                button={
                  <div className="px-2 text-primary hover:opacity-80 text-sm font-semibold">
                    {humanize(role.name.replace('workspace', ''))}
                  </div>
                }
                workspaceId={workspaceId}
                workspaceMemberId={objectId}
                member={member}
                role={role}
              />
              <div className="hidden sm:flex px-2 bg-sm-lightblue text-secondary rounded-md border-l text-sm">
                {member.project_count} projects
              </div>
              {workspaceMember.invitationPending?.() && (
                <div
                  className="ml-10 cursor-pointer flex items-center justify-between px-2 flex h-6 hover:shadow-lg bg-blue-100 text-secondary border border-secondary rounded-full"
                  title="Invitation Pending..."
                >
                  <FiClock />{' '}
                  <div className="text-xs ml-1 font-semibold">
                    Invitation pending
                  </div>
                </div>
              )}
            </div>
            {permissions?.can?.('manage_members') && (
              <div className="relative">
                <EditMenu className="" action="click">
                  <li>
                    <AddMemberModal
                      key={`edit_member_modal_${objectId}`}
                      user={member}
                      workspaceMember={{ id, role_id }}
                      button={
                        <button className="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3 w-full">
                          Edit User
                        </button>
                      }
                      afterCreate={afterRemove}
                    />
                  </li>
                  <li>
                    <EditPermissionsModal
                      key={`edit_member_role_${objectId}`}
                      workspaceId={workspaceId}
                      workspaceMemberId={objectId}
                      member={member}
                      role={role}
                    />
                  </li>
                  <li className="hidden">
                    <Link
                      className="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3"
                      to="#0"
                    >
                      Add to Project
                    </Link>
                  </li>
                  <li>
                    <DeleteMemberModal
                      key={`remove_member_${objectId}`}
                      workspaceId={workspaceId}
                      workspaceMemberId={objectId}
                      member={member}
                      afterRemove={afterRemove}
                    />
                  </li>
                </EditMenu>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default memo(TeamTableList);
