import NotePhotos from '../notes/NotePhotos';
import formattedDateString from '../../utility/formattedDateString';

export default function MeetingViewBody({ meeting }) {
  return (
    <div className="px-2 pt-2 text-left text-sm">
      <div className="p-5">
        <div className="text-lg mb-2 text-secondary font-semibold truncate">
          {meeting.title}
        </div>
        <div className="text-sm mb-6">
          Recorded by <span className="underline">{meeting.creator.name}</span>{' '}
          on{' '}
          <span className="font-semibold">
            {formattedDateString(meeting.met_at)}
          </span>
        </div>
        <div className="font-semibold mb-2">Attendees</div>
        <div className="mb-4">{meeting.attendees}</div>
        <div className="font-semibold mb-2">Notes</div>

        {
          // Include this special styling here to respect the line breaks added
          // to meeting notes.
        }
        <div className="mb-4" style={{ whiteSpace: 'pre-line' }}>
          {meeting.notes[0].text}
        </div>

        <div className="font-semibold mb-2">Photos</div>
        <div className="pt-2 mb-6">
          <NotePhotos note={meeting.notes[0]} />
        </div>
      </div>
    </div>
  );
}
