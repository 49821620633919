import qrLogo from '../images/icons/icon-circle-canvas.png';
import { QRCode } from 'react-qrcode-logo';
import useQuery from '../hooks/useQuery';

function QRCodePage() {
    const query = useQuery();

    const link = query.get('link');
    let finalLink = link;
    finalLink = link?.includes?.('?') ? `${link}&utm_source=qr_code_mobile_scan` : `${link}?utm_source=qr_code_mobile_scan`;


    return <div className="flex flex-col items-center justify-center mb-4 py-5" >
        <QRCode
            value={finalLink}
            size={256}
            logoImage={qrLogo}
            logoWidth={50}
            logoHeight={50}
            logoPadding={8}
            quietZone={20}
            logoPaddingStyle="circle"
            removeQrCodeBehindLogo
        />

        <div className="flex flex-col items-center justify-center mt-10 px-8">
            <p className="font-medium text-secondary text-center">Scan this QR code and use it on site to <em>{query.get('details')}</em> </p>
        </div>
    </div>;

}

export default QRCodePage;
