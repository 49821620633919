import React, { useState, useEffect } from 'react';
import useApi from '../../hooks/useApi';
import assetsApi from '../../api/assets';
import Loader from '../utility/Loader';
import { AiOutlinePlus } from 'react-icons/ai';
import PhotosModal from '../utility/PhotosModal';
import { useParams } from 'react-router-dom';

export default function PinPhotos({ pin, onToggle, onLoad }) {
  const {
    data: { records: assets, pagy },
    error,
    loading,
    request: getAssets,
  } = useApi(assetsApi.getAssets, { records: [], pagy: {} }, true);
  const [shownPhotoIndex, setShownPhotoIndex] = useState(-1);
  const { share_id } = useParams();

  useEffect(() => {
    getAssets('pin', pin.objectId, { items: 10, share_id });
  }, [pin]);

  useEffect(() => {
    onToggle?.(shownPhotoIndex > -1);
  }, [shownPhotoIndex]);

  useEffect(() => {
    onLoad?.(assets);
  }, [assets]);

  return (
    <div className="overflow-x-auto pt-1.5 px-1.5 w-full">
      {loading ? (
        <div className="flex justify-center h-20 items-center">
          <Loader color={'gray-500'} />
        </div>
      ) : (
        <div className="inline-flex">
          <div className="hidden h-20 w-20 bg-white border cursor-pointer border-gray-200 shadow-lg mr-1.5 items-center flex justify-center hover:bg-gray-50">
            <AiOutlinePlus size={36} className={'text-gray-500'} />
          </div>
          {assets.map(({ files: { original, medium, thumbnail } }, index) => (
            <div
              className="flex h-20 w-20 mr-1.5 cursor-pointer hover:opacity-90"
              onClick={() => setShownPhotoIndex(index)}
            >
              <img className="w-full h-full object-cover" src={medium} />
            </div>
          ))}
        </div>
      )}
      <PhotosModal
        isOpen={shownPhotoIndex > -1}
        assets={assets}
        activeIndex={shownPhotoIndex}
        hideBackdrop={true}
        maxHeight={'max-h-screen'}
        onClose={() => setShownPhotoIndex(-1)}
      />
    </div>
  );
}
