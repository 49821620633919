import { memo, useEffect, useMemo, useRef, useState } from 'react';
import html2pdf from 'html2pdf.js';
import PrintableReportPage from '../partials/reports/PrintableReportPage';
import { useParams } from 'react-router-dom';
import useApi from '../hooks/useApi';
import reportPagesApi from '../api/report_pages';
import Loader from '../partials/utility/Loader';
import { BiCheckCircle } from 'react-icons/bi';
import { FaFilePdf } from 'react-icons/fa';
import { uploadSource } from '../hooks/useS3';

function ReportPage() {
  const {
    data,
    error,
    loading,
    request: getReportPage,
  } = useApi(reportPagesApi.getReportPage, null, true);
  const {
    data: updatedReportPage,
    error: reportPageUpdateError,
    loading: reportPageUpdating,
    request: updateReportPage,
  } = useApi(reportPagesApi.updateReportPage, null);

  const [updateState, setUpdateState] = useState({
    updating: false,
    text: 'Generate Report',
  });

  const [reportPage, setReportPage] = useState(null);
  const { workspace_id, project_id, report_id, report_page_id } = useParams();

  const componentRef = useRef(null);
  const generatedTimer = useRef(null);
  const [showRedirectMessage, setShowRedirectMessage] = useState(false);

  const downloadPdf = async () => {
    setUpdateState({ updating: true, text: 'Building PDF' });

    var opt = {
      margin: 0,
      filename: `${reportPage.report.name.replace(' ', '_')}_page_${reportPage.page
        }.pdf`,
      html2canvas: {
        allowTaint: false,
        imageTimeout: 0,
        useCORS: true,
        scale: 2,
        ignoreElements: function (element) {
          return element.classList.contains('print:hidden');
        },
      },
      jsPDF: {
        unit: 'in',
        format: 'letter',
        orientation: 'portrait',
      },
    };

    let pdfObj = await html2pdf()
      .set(opt)
      .from(componentRef.current)
      .toPdf()
      .get('pdf');
    let pdfBase64String = pdfObj.output('datauristring');
    let file = await uploadSource(
      pdfBase64String,
      `${reportPage.report.name.replace(' ', '_')}_page_${reportPage.page}.pdf`,
      'application/pdf'
    );

    let asset_attributes = {
      file,
    };

    if (reportPage.asset && reportPage.asset.id)
      asset_attributes.id = reportPage.asset.id;

    setUpdateState({ updating: true, text: 'Saving PDF' });
    updateReportPage(reportPage.objectId, { asset_attributes });
  };

  useEffect(() => {
    getReportPage(report_id, report_page_id);
  }, []);

  useEffect(() => {
    data && setReportPage({ ...data });
  }, [data]);

  useEffect(() => {
    updatedReportPage && setReportPage({ ...updatedReportPage });
  }, [updatedReportPage]);

  useEffect(() => {
    updateState.updating && updateState.text === 'Warming Up' && downloadPdf();
  }, [updateState]);

  useEffect(() => {
    if (updateState.text === 'Report Generated') {
      generatedTimer.current = setTimeout(() => {
        setUpdateState({ updating: false, text: 'Generate Report' });
      }, 4000);
    }
  }, [updateState]);

  useEffect(() => {
    if (updatedReportPage) {
      setShowRedirectMessage(true);
      setUpdateState({ updating: true, text: 'Report Generated' });

      const timer = setTimeout(() => {
        window.location.href = `/generate/${workspace_id}/projects/${project_id}/reports/${report_id}/report_pages/${report_page_id}/complete`;
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [updatedReportPage, workspace_id, project_id, report_id, report_page_id]);

  const generateButton = useMemo(
    () => (
      <>
        {updateState.updating && (
          <div
            id={
              updateState.text === 'Report Generated'
                ? 'report_generated'
                : 'generating_report'
            }
            className="pointer-events-none fixed flex justify-center items-center w-full h-full z-40 bg-gray-700 bg-opacity-25 backdrop-filter backdrop-blur-sm"
          >
            <div className="xs:-mt-24 -mt-20 flex items-center justify-center flex-col">
              {updateState.text === 'Report Generated' ? (
                <BiCheckCircle
                  size={80}
                  className="bg-green-500 text-white rounded-full overflow-hidden mb-3"
                />
              ) : (
                <FaFilePdf
                  size={55}
                  className={`text-secondary mb-3 animate-pulse`}
                />
              )}
              <div className="font-black text-4xl xs:text-5xl text-white my-2">
                {updateState.text}
              </div>
              {updateState.text === 'Report Generated' && (
                <div
                  className="font-semibold text-xl xs:text-2xl text-gray-800"
                  id="report_generated"
                >
                  Well done - Report Generated
                </div>
              )}
              {updateState.text !== 'Report Generated' && (
                <div className="font-semibold text-xl xs:text-2xl text-gray-800">
                  Please wait - do not reload page
                </div>
              )}
            </div>
          </div>
        )}
        <div
          className={`xs:flex w-full justify-end fixed z-40 h-20 transition-transform transform delay-4000 ${reportPage && '-translate-y-20'
            } -bottom-20 left-0`}
        >
          {reportPage && (
            <button
              id={`generate_report`}
              disabled={updateState.updating}
              className="bg-green-500 text-2xl text-white font-semibold w-full h-full focus:outline-none pointer-cursor flex justify-center items-center shadow-lg hover:bg-green-400 disabled:bg-green-400 disabled:text-gray-700"
              title="Download Report"
              onClick={() =>
                setUpdateState({ updating: true, text: 'Warming Up' })
              }
            >
              {!updateState.updating && (
                <>
                  <FaFilePdf
                    size={30}
                    className={`mr-3 ${updateState.updating && 'animate-pulse'
                      }`}
                  />{' '}
                  {updateState.text}{' '}
                </>
              )}{' '}
              {updateState.updating && (
                <>
                  {' '}
                  {updateState.text === 'Report Generated' ? (
                    <BiCheckCircle size={30} />
                  ) : (
                    <Loader />
                  )}{' '}
                </>
              )}
            </button>
          )}
        </div>
      </>
    ),
    [updateState, reportPage]
  );

  const printableReport = useMemo(
    () =>
      reportPage ? (
        <PrintableReportPage
          ref={componentRef}
          report={reportPage && reportPage.report}
          reportPage={reportPage}
        />
      ) : (
        <span />
      ),
    [reportPage]
  );

  if (updatedReportPage)
    return (
      <>
        {showRedirectMessage && (
          <div id="report_completed" className="bg-yellow-300 text-center p-4 mb-4">
            PDF created. Redirecting...
          </div>
        )}
      </>
    );

  return (
    <>
      {loading ? (
        <div className="w-full flex py-5 items-center justify-center">
          <Loader color={'text-gray-700'} margin={''} />
        </div>
      ) : (
        <>
          {generateButton}
          {printableReport}
        </>
      )}
    </>
  );
}

export default memo(ReportPage);
