import { memo, useState } from 'react';
import Modal, { ModalHeader } from '../utility/Modal';

function DescriptionModal({ description }) {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div>
      {/* Button */}
      <a className="flex justify-start mt-1" onClick={() => setModalOpen(true)}>
        <p className="transition-opacity duration-300 text-center text-secondary font-bold hover:opacity-80 cursor-pointer hover:underline">
          See More
        </p>
      </a>
      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
        <ModalHeader
          title={'Project description'}
          onClose={() => setModalOpen(false)}
        />
        <div className="px-2 pt-2">
          <div className="p-5">
            <p className="text-base">{description}</p>
          </div>

          <div className="modal-footer">
            <button
              className="modal-close-btn"
              type="button"
              onClick={() => setModalOpen(false)}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default memo(DescriptionModal);
