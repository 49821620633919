import { BiCheckCircle } from "react-icons/bi";
import Loader from "../utility/Loader";
import { Entry } from "../../data/models";

export default function EntryLabel({
    entry,
    useBottomBorder = true,
    saving = false,
    saved = false
}: {
    useBottomBorder?: boolean,
    entry: Entry,
    saving?: boolean,
    saved?: boolean,
}) {
    return <div
        className={`p-2 font-bold border-l ${useBottomBorder && 'border-b'
            } border-r relative overflow-hidden`}
    >
        {entry.name}
        {saving && (
            <div className="absolute top-0 right-2 h-full flex items-center">
                <Loader color="text-gray-700" />
            </div>
        )}
        {saved && (
            <div className="absolute top-0 right-2 h-full flex items-center">
                <BiCheckCircle
                    size={16}
                    className="bg-green-500 text-white rounded-full overflow-hidden"
                />
            </div>
        )}
    </div>
}