const makeDarkerColor = (hex, factor = 0.2) => {
  // Convert hex to RGB
  const hexToRgb = (h) => {
    let r = parseInt(h.substring(1, 3), 16);
    let g = parseInt(h.substring(3, 5), 16);
    let b = parseInt(h.substring(5, 7), 16);
    return [r, g, b];
  };

  // Convert RGB to hex
  const rgbToHex = (r, g, b) => {
    return (
      '#' +
      ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()
    );
  };

  // Calculate the relative luminance of a color
  const getLuminance = ([r, g, b]) => {
    const a = [r / 255, g / 255, b / 255].map((v) => {
      return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return 0.2126 * a[0] + 0.7152 * a[1] + 0.0722 * a[2];
  };

  const rgb = hexToRgb(hex);
  const black = [0, 0, 0];

  let shaded = rgb.map((val, i) => {
    return Math.round(val + factor * (black[i] - val));
  });

  let lum1 = getLuminance(rgb);
  let lum2 = getLuminance(shaded);
  let contrastRatio =
    (Math.max(lum1, lum2) + 0.05) / (Math.min(lum1, lum2) + 0.05);

  // If the contrast ratio is too low, adjust the color further
  while (contrastRatio < 4.5 && factor > 0) {
    factor -= 0.05;
    shaded = rgb.map((val, i) => {
      return Math.round(val + factor * (black[i] - val));
    });
    lum2 = getLuminance(shaded);
    contrastRatio =
      (Math.max(lum1, lum2) + 0.05) / (Math.min(lum1, lum2) + 0.05);
  }

  return rgbToHex(shaded[0], shaded[1], shaded[2]);
};

const makeLighterColor = (hex, factor = 0.2) => {
  // Convert hex to RGB
  const hexToRgb = (h) => {
    let r = parseInt(h.substring(1, 3), 16);
    let g = parseInt(h.substring(3, 5), 16);
    let b = parseInt(h.substring(5, 7), 16);
    return [r, g, b];
  };

  // Convert RGB to hex
  const rgbToHex = (r, g, b) => {
    return (
      '#' +
      ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()
    );
  };

  const rgb = hexToRgb(hex);
  const white = [255, 255, 255];

  // Mix the color with white
  const tinted = rgb.map((val, i) => {
    return Math.round(val + factor * (white[i] - val));
  });

  return rgbToHex(tinted[0], tinted[1], tinted[2]);
};

export { makeDarkerColor, makeLighterColor };
