import * as React from 'react';
import { BiTrash, BiXCircle } from 'react-icons/bi';
export default function AssetUpdateItem({
    asset,
    markedForRemoval,
    onCancelRemove,
    onRemove,
    onSelect,
}: {
    asset: any;
    markedForRemoval?: boolean;
    onCancelRemove?: (asset: any) => void;
    onRemove?: (asset: any) => void;
    onSelect?: (asset: any) => void;
}) {
    return <Container onClick={() => onSelect?.(asset)} group={!markedForRemoval}>
        {!markedForRemoval ?
            <RemoveButton onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onRemove?.(asset);
                return false;
            }} /> :
            <CancelRemoveButton onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onCancelRemove?.(asset);
                return false;
            }} />
        }
        <img
            className={'h-28 w-28 object-cover border border-gray-200 rounded-md'}
            data-testid="addPhotoItemThumbnail"
            src={asset.files.medium}
        />
    </Container>
}

const RemoveButton = ({ onClick }: {
    onClick: (e: any) => void
}) => <BiXCircle
        size={20}
        data-testid="addPhotoItemRemove"
        className="hover:opacity-80 cursor-pointer rounded-full overflow-hidden absolute -top-1 -right-1 bg-black text-white opacity-0 group-hover:opacity-100"
        onClick={onClick}
    />


const CancelRemoveButton = ({ onClick }: {
    onClick: (e: any) => void
}) => <div className="absolute inset-0 flex flex-col justify-center items-center backdrop-filter backdrop-blur-sm">
        <span className="group w-full flex justify-center">
            <BiTrash
                size={40}
                className="group-hover:hidden cursor-pointer rounded-full overflow-hidden bg-red-600 text-gray-50 p-2 shadow-lg"
            />
            <BiXCircle
                size={40}
                className="hidden group-hover:block cursor-pointer rounded-full overflow-hidden bg-gray-900 text-gray-50 p-2 shadow-lg"
                onClick={onClick}
            />
            <p className="group-hover:hidden text-white flex text-xs mt-2 absolute bottom-2">Deleting</p>
            <p className="hidden group-hover:block text-white flex text-xs mt-2 absolute bottom-2">Cancel deletion</p>
        </span>
    </div>
const Container = ({
    hidden,
    disabled,
    children,
    group,
    onClick,
}: {
    hidden?: boolean,
    disabled?: boolean,
    children: React.ReactNode,
    group?: boolean,
    onClick?: () => void
}) => <div
    onClick={onClick}
    className={`${disabled ? 'opacity-50 pointer-events-none' : ''} ${group ? 'group cursor-zoom-in' : ''} relative mr-2 mb-2 ${hidden ? 'hidden' : ''}`}
    data-testid="addPhotoItem"
>{children}
    </div>