import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import assetsApi from '../../api/assets';
import useApi from '../../hooks/useApi';
import ReportAsset from '../reports/ReportAsset';
import Loader from '../utility/Loader';
export default function SharedAsset({ share }) {
  const { share_id, shareable_id } = useParams();
  const {
    data: asset,
    error,
    loading,
    request: getAsset,
  } = useApi(assetsApi.getAsset, null, true);

  useEffect(() => {
    getAsset(shareable_id, { share_id });
  }, []);

  if (loading)
    return (
      <div className="flex py-5 justify-center">
        <Loader color="black" />
      </div>
    );

  return (
    <>
      {asset.assetable_type === 'Report' && (
        <ReportAsset url={asset.files.original} />
      )}
    </>
  );
}
