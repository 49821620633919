import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import pinsApi from '../../api/pins';
import useApi from '../../hooks/useApi';
import PinViewModel from '../../models/PinViewModel';
import { getTextColorFromBackground } from '../../styles/colors';
import { maxPinCount } from '../../utility/pinConfig';
import Loader from '../utility/Loader';
import FieldReportItem from './FieldReportItem';
export default function ReportPinsSummary({
  report,
  entryGroup,
  onPinsLoaded,
  onLoaded,
}) {
  const {
    data: { records: pins, pagy },
    error,
    loading,
    request: getPins,
  } = useApi(pinsApi.getPins, { records: [], pagy: {} }, true);
  const [categories, setCategories] = useState([]);
  const [modeledPins, setModeledPins] = useState([]);
  const { report_id, shareable_id, share_id } = useParams();

  const setModeledCategories = () => {
    let newCategories = [
      ...new Set([
        ...modeledPins
          .map((p) =>
            p
              .categoryFields()
              .map((f) => f.field_option.name)
              .flat()
          )
          .flat(),
      ]),
    ].sort();
    setCategories(newCategories);
  };

  useEffect(() => {
    getPins('report', report_id || shareable_id, {
      items: maxPinCount,
      full: true,
      previous_report: entryGroup.extras.status === 'corrected',
      where: { status: entryGroup.extras.status },
      share_id,
    });
  }, []);

  useEffect(() => {
    pins.length > 0 && onPinsLoaded && onPinsLoaded(pins);
  }, [pins]);

  useEffect(() => {
    !loading && onLoaded && onLoaded();
  }, [loading]);

  useEffect(() => {
    pins.length > 0 &&
      setModeledPins([...pins.map((p) => new PinViewModel(p))]);
  }, [pins]);

  useEffect(() => {
    modeledPins.length > 0 && setModeledCategories();
  }, [modeledPins]);

  return (
    <div className="px-4 py-2">
      {loading && (
        <div className="pb-2">
          <Loader color="text-gray-700" margin="" />
        </div>
      )}
      {!loading && (
        <>
          {modeledPins.length === 0 ? (
            <p className="text-gray-700 text-xs">N/A</p>
          ) : (
            <>
              {categories
                .filter(
                  (c) =>
                    modeledPins.filter(
                      (p) =>
                        p
                          .categoryFields()
                          .map((f) => f.field_option.name)
                          .flat()
                          .indexOf(c) > -1
                    ).length !== 0
                )
                .map((c) => {
                  let pinsWithCategory = modeledPins.filter(
                    (p) =>
                      p
                        .categoryFields()
                        .map((f) => f.field_option.name)
                        .flat()
                        .indexOf(c) > -1
                  );
                  let {
                    field_option: { color, name },
                  } = pinsWithCategory[0]
                    .categoryFields()
                    .find((f) => f.field_option.name === c);
                  return (
                    <span key={`report_pins_summary_category_${c}`}>
                      <div
                        className="px-2 text-xs rounded-lg shadow font-semibold mb-2 inline-block"
                        style={{
                          backgroundColor: color,
                          color: getTextColorFromBackground(color, 200),
                        }}
                      >
                        {c}
                      </div>
                      {pinsWithCategory.map((p, index) => (
                        <FieldReportItem
                          key={`field_report_item_${p.identifier}`}
                          title={p.identifier}
                          note={p.latest_notes[0].text}
                          includeBorder={index !== pinsWithCategory.length - 1}
                        />
                      ))}
                    </span>
                  );
                })}
            </>
          )}
        </>
      )}
    </div>
  );
}
