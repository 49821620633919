import { useEffect, useRef, useState } from 'react';
import Modal, { ModalHeader, ModalSuccessView } from '../utility/Modal';
import useApi from '../../hooks/useApi';
import Loader from '../utility/Loader';
import usersApi from '../../api/users';

export default function DestroyUserModal({
  email,
  open,
  onClose,
  onDestroyed,
  userObjectId,
}) {
  const {
    data,
    error,
    loading: deleting,
    request: destroyUser,
  } = useApi(usersApi.destroyUser, null);
  const [destroyed, setDestroyed] = useState(false);
  const sentTimer = useRef();

  const submitDestroy = () => destroyUser(userObjectId);

  useEffect(() => {
    if (!data) return;
    setDestroyed(true);
  }, [data]);

  useEffect(() => {
    const closeAndDestroy = () => {
      onClose?.();
      onDestroyed?.(data);
    };
    if (destroyed) sentTimer.current = setTimeout(closeAndDestroy, 3000);
  }, [destroyed]);

  return (
    <Modal isOpen={open} onClose={onClose}>
      <ModalHeader title={'Destroy user'} color="danger" onClose={onClose} />
      <div className="px-2 pt-2">
        <div className="p-5">
          {!data && (
            <>
              <div className="mb-4">
                <div className="font-semibold">Destroy user?</div>
              </div>
              <div>
                This will delete user{' '}
                <em>
                  <u>{email}</u>
                </em>{' '}
                <em className="text-red-600">
                  and any workspaces owned by that user.
                </em>
              </div>
            </>
          )}
          {data && <ModalSuccessView text={'User Successfully Destroyed!'} />}
        </div>

        {!data && (
          <div className="modal-footer">
            <button
              className="modal-remove-btn"
              type="button"
              onClick={submitDestroy}
              disabled={deleting}
            >
              Destroy {deleting && <Loader />}
            </button>
            <button
              className="modal-close-btn"
              type="button"
              onClick={onClose}
              disabled={deleting}
            >
              Cancel
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
}
