import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { FaFolderPlus } from 'react-icons/fa';

const FolderDropzone = ({ onFolderDrop, children }) => {

  const onDrop = useCallback((acceptedFiles, _, event) => {
    const items = event.dataTransfer?.items;
    if (!items) return;

    console.log({ items });
    const folderTree = buildFolderTree(acceptedFiles);
    console.log({ folderTree })
    onFolderDrop(folderTree, acceptedFiles);

    for (let i = 0; i < items.length; i++) {
      const item = items[i].webkitGetAsEntry();
      if (item && item.isDirectory) {
        console.log({ item })
        traverseFileTree(item)
          .then((files) => {
            const folderTree = buildFolderTree(files);
            console.log({ finalFolderTree: folderTree });
            onFolderDrop(folderTree, files);
          })
          .catch((error) => {
            console.error('Error processing folder:', error);
          });
        break;
      }
    }
  }, [onFolderDrop]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    multiple: true,
    noDrag: false,
    accept: undefined
  });

  return (
    <div {...getRootProps()} className="relative h-full"
      data-testid={"folderDropzoneRoot"}>
      <input {...getInputProps()} data-testid={"folderDropzoneFile"} />
      {isDragActive && (
        <div className="absolute inset-0 bg-blue-100 bg-opacity-50 flex items-center justify-center z-10">
          <div className="text-center">
            <FaFolderPlus size={48} className="mx-auto text-blue-500" />
            <p className="mt-2 text-blue-700 font-semibold">Drop files or folders here</p>
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

const buildFolderTree = (files) => {
  const tree = [];

  const findOrCreateFolder = (itemsArray, folderName) => {
    let folder = itemsArray.find(item => item.name === folderName && item.document === 'folder');
    if (!folder) {
      folder = {
        name: folderName,
        document: 'folder',
        items: []
      };
      itemsArray.push(folder);
    }
    return folder;
  };

  files.forEach(file => {
    const pathParts = !!file.path ? file.path.split('/').filter(part => part !== '') : [file.name];
    let currentLevel = tree;

    pathParts.forEach((part, index) => {
      if (index === pathParts.length - 1) {
        // It's a file, add it to the current level's items array
        currentLevel.push({
          ...file,
          name: part,
          document: 'file',
          type: file.type,
          file: file
        });
      } else {
        // It's a folder
        let folder = findOrCreateFolder(currentLevel, part);
        currentLevel = folder.items;
      }
    });
  });
  return tree;
};

const traverseFileTree = async (item, path = '') => {
  if (item.isFile) {
    const file = await new Promise((resolve) => item.file(resolve));
    file.path = path + file.name;
    return [file];
  } else if (item.isDirectory) {
    const dirReader = item.createReader();
    const entries = await new Promise((resolve) => dirReader.readEntries(resolve));
    const files = await Promise.all(
      entries.map((entry) => traverseFileTree(entry, path + item.name + '/'))
    );
    return files.flat();
  }
};

export default FolderDropzone;