import React from 'react';
import {
  FiChevronLeft,
  FiChevronsLeft,
  FiChevronRight,
  FiChevronsRight,
} from 'react-icons/fi';
import EditMenu from './EditMenu';

const PageListItem = ({ children, dataTestId, onClick }) => {
  return <li data-testid={dataTestId}>
    <span
      className="font-medium text-sm hover:bg-gray-100 cursor-pointer text-gray-600 hover:text-gray-800 flex py-1 px-3"
      onClick={onClick}
    >
      {children}
    </span>
  </li>
}

const PageButton = ({ mobileHidden, disabled = false, onClick, icon = null, children, spacing = "mr-1" }) => {
  return <span
    className={`${mobileHidden ? 'hidden sm:inline-flex' : 'inline-flex'} cursor-pointer items-center justify-center border rounded leading-5 px-2.5 py-2 bg-white border-gray-200 ${!disabled ? 'border-gray-100 text-gray-600 hover:text-gray-700 hover:bg-gray-100'
      : ' border-gray-50 text-gray-300'
      } ${spacing}`}
    onClick={onClick}
  >
    {children}
    {icon}
  </span>
}

function PaginationNumeric({
  pagy,
  showPages = true,
  showEnds = true,
  onPageChosen,
  onItemsChosen,
}) {

  return (
    <div className="flex justify-center" id="paginationNumeric">
      {showPages && (
        <div className="relative hidden sm:flex flex-shrink justify-end items-center mr-10">
          <p className="font-semibold text-sm mr-3">Display</p>
          <EditMenu
            className=""
            closeOnClick
            dataTestId="pageItemsEditButton"
            action="click"
            icon={
              <span className="inline-flex cursor-pointer items-center justify-center border border-gray-100 rounded leading-5 px-3.5 py-2 bg-white hover:bg-gray-100 border-gray-200 focus:outline-none focus:ring-0 text-gray-600 hover:text-gray-700">
                {pagy.items === pagy.count ? 'All' : pagy.items}
              </span>
            }
          >
            <PageListItem dataTestId="pageItemsButton1" onClick={() => onItemsChosen?.(1)} >
              1
            </PageListItem>
            {pagy.count > 1 && <PageListItem dataTestId="pageItemsButton5" onClick={() => onItemsChosen?.(pagy.count > 5 ? 5 : pagy.count)}>
              {pagy.count > 5 ? 5 : 'All'}
            </PageListItem>}
            {pagy.count > 5 && <PageListItem dataTestId="pageItemsButton10" onClick={() => onItemsChosen?.(pagy.count > 10 ? 10 : pagy.count)}>
              {pagy.count > 10 ? 10 : 'All'}
            </PageListItem>}
            {pagy.count > 10 && <PageListItem dataTestId="pageItemsButtonAll" onClick={() => onItemsChosen?.(pagy.count)}>
              All
            </PageListItem>}
          </EditMenu>
          <p className="font-semibold text-sm ml-3">
            page{pagy.items > 1 && 's'}
          </p>
        </div>
      )}
      <nav className="flex" role="navigation" aria-label="Navigation">
        {showEnds && (
          <PageButton
            mobileHidden
            disabled={pagy.page === 1}
            onClick={() => onPageChosen?.(1)}
            icon={<FiChevronsLeft size={20} />}>
            <span className="sr-only">Previous</span>
            <wbr />
          </PageButton>
        )}
        <PageButton
          disabled={!pagy.prev}
          onClick={() => onPageChosen?.(pagy.prev)}
          icon={<FiChevronLeft size={20} />}
          spacing="mr-2"
        >
          <span className="sr-only">Previous</span>
          <wbr />
        </PageButton>
        <ul className="inline-flex text-sm font-medium -space-x-px shadow-sm">
          {!pagy.next && pagy.prev - 1 > 0 && (
            <li >
              <span
                className="inline-flex cursor-pointer items-center justify-center border border-gray-50 rounded-l leading-5 px-3.5 py-2 bg-white hover:bg-gray-100 border-gray-200 focus:outline-none focus:ring-0 text-gray-600 hover:text-gray-700"
                onClick={() => onPageChosen?.(pagy.prev - 1)}
              >
                {pagy.prev - 1}
              </span>
            </li>
          )}
          {pagy.prev && (
            <li id="buttonPagePrevious">
              <span
                className={`inline-flex cursor-pointer items-center justify-center border border-gray-50 ${!pagy.next && pagy.prev - 1 > 0 ? '' : 'rounded-l'
                  } leading-5 px-3.5 py-2 bg-white hover:bg-gray-100 border-gray-200 focus:outline-none focus:ring-0 text-gray-600 hover:bg-gray-100 hover:text-gray-700`}
                onClick={() => onPageChosen?.(pagy.prev)}
              >
                {pagy.prev}
              </span>
            </li>
          )}
          <li>
            <span className="inline-flex cursor-pointer items-center justify-center border border-transparent leading-5 px-3.5 py-2 bg-white border-gray-200 focus:outline-none focus:ring-0 text-secondary">
              {pagy.page}
            </span>
          </li>
          {pagy.next && (
            <li id="buttonPageNext">
              <span
                className={`inline-flex cursor-pointer items-center justify-center border border-transparent ${!pagy.prev && pagy.next + 1 < pagy.last ? '' : 'rounded-r'
                  } leading-5 px-3.5 py-2 bg-white hover:bg-gray-100 border-gray-200 focus:outline-none focus:ring-0 text-gray-600 hover:text-gray-700`}
                onClick={() => onPageChosen?.(pagy.next)}
              >
                {pagy.next}
              </span>
            </li>
          )}
          {!pagy.prev && pagy.next + 1 < pagy.last && (
            <li>
              <span
                className="inline-flex cursor-pointer items-center justify-center border border-transparent rounded-r leading-5 px-3.5 py-2 bg-white hover:bg-gray-100 border-gray-200 focus:outline-none focus:ring-0 text-gray-600 hover:text-gray-700"
                onClick={() => onPageChosen?.(pagy.next + 1)}
              >
                {pagy.next + 1}
              </span>
            </li>
          )}
        </ul>
        <PageButton
          disabled={!pagy.next}
          onClick={() => onPageChosen?.(pagy.next)}
          icon={<FiChevronRight size={20} />}
          spacing="ml-2"
        >
          <span className="sr-only">Previous</span>
          <wbr />
        </PageButton>
        {showEnds && <PageButton
          disabled={pagy.last === pagy.page}
          onClick={() => onPageChosen?.(pagy.last)}
          icon={<FiChevronsRight size={20} />}
          mobileHidden
          spacing="ml-1"
        >
          <span className="sr-only">Next</span>
          <wbr />
        </PageButton>}
      </nav>
    </div>
  );
}

export default PaginationNumeric;
