import React, { useEffect, useRef, useState } from 'react';
import { BiCheckCircle } from 'react-icons/bi';
import entriesApi from '../../api/entries';
import useApi from '../../hooks/useApi';
import Loader from '../utility/Loader';
import EntryDateActivityRemoveButton from './EntryDateActivityRemoveButton';
export default function EntryDateActivity({
  entryGroup,
  editing = true,
  entry: passedEntry,
  isNew: passedIsNewEntry = false,
  onAdd,
}) {
  const {
    data: createdEntry,
    error: createError,
    loading: creating,
    request: addEntry,
  } = useApi(entriesApi.addEntryToEntryGroup, null);
  const {
    data: savedEntry,
    error: updateError,
    loading: saving,
    request: saveEntry,
  } = useApi(entriesApi.updateEntry, null);
  const {
    data: deletedEntry,
    error: deleteError,
    loading: deleting,
    request: deleteEntry,
  } = useApi(entriesApi.removeEntry, null);

  const [isNew, setIsNew] = useState(passedIsNewEntry);
  const [entry, setEntry] = useState(passedEntry);
  const [saved, setSaved] = useState(false);
  const nameTimer = useRef(null);
  const valueTimer = useRef(null);
  const savedTimer = useRef(null);

  const startNewEntry = () => {
    setEntry({});
    setIsNew(false);
  };

  const removeEntry = () => {
    if (entry.objectId) {
      deleteEntry(entry.objectId);
    } else {
      setEntry(null);
    }
  };

  const updateName = ({ target: { value } }) => {
    if (nameTimer.current) clearTimeout(nameTimer.current);

    nameTimer.current = setTimeout(() => {
      submitEntry('name', value);
    }, 1000);
  };

  const updateValue = ({ target: { value } }) => {
    if (valueTimer.current) clearTimeout(valueTimer.current);

    valueTimer.current = setTimeout(() => {
      submitEntry('value', value);
    }, 1000);
  };

  const submitEntry = (key, value) =>
    entry.objectId
      ? saveEntry(entry.objectId, { [key]: value })
      : addEntry(entryGroup.objectId, { [key]: value });

  useEffect(() => {
    createdEntry && onAdd && onAdd();
  }, [createdEntry]);

  useEffect(() => {
    createdEntry && setEntry({ ...createdEntry });
  }, [createdEntry]);

  useEffect(() => {
    savedEntry && setEntry({ ...savedEntry });
  }, [savedEntry]);

  useEffect(() => {
    (savedEntry || createdEntry) && setSaved(true);
  }, [savedEntry, createdEntry]);

  useEffect(() => {
    if (saved) {
      savedTimer.current = setTimeout(() => {
        setSaved(false);
      }, 2000);
    }
  }, [saved]);

  if (deletedEntry || entry === null) return <span />;

  if (isNew)
    return (
      <div
        className="border border-t-0 flex items-center justify-center rounded-b-lg shadow-sm"
        onClick={startNewEntry}
      >
        <div className="p-2 w-full text-center hover:bg-blue-50 cursor-pointer">
          + Add Entry
        </div>
      </div>
    );

  return (
    <div className="border border-t-0 grid grid-cols-9 group relative">
      <input
        type={'date'}
        disabled={!editing}
        className={`p-2 leading-4 col-span-2 border-t-0 border-b-0 border-l-0 border-r text-xxs border-gray-200 ${
          entry.class_name
        } focus:border-gray-200 focus:outline-none focus:ring-0 ${
          editing && 'hover:bg-blue-50'
        }`}
        onChange={updateName}
        placeholder={'Date'}
        defaultValue={entry.name}
      />
      <input
        type={'text'}
        disabled={!editing}
        className={`p-2 leading-4 col-span-7 border-0 text-xxs border-gray-200 ${
          entry.class_name
        } focus:border-gray-200 focus:outline-none focus:ring-0 ${
          editing && 'hover:bg-blue-50'
        }`}
        onChange={updateValue}
        placeholder={'Activity description'}
        defaultValue={entry.value}
      />
      {!saving && !creating && (
        <EntryDateActivityRemoveButton
          showModal={entry && entry.objectId}
          onConfirm={removeEntry}
        />
      )}
      {(saving || creating) && (
        <div className="absolute right-0 top-0 h-full w-10 flex justify-center items-center">
          <Loader color="text-gray-700" margin="" />
        </div>
      )}
      {saved && (
        <div className="absolute right-0 top-0 h-full w-10 flex justify-center items-center">
          <BiCheckCircle
            size={20}
            className="bg-green-500 text-white rounded-full overflow-hidden"
          />
        </div>
      )}
    </div>
  );
}
