import React from 'react';
import { BiCheck } from 'react-icons/bi';

export default function AddPinDropperItem({
  chosen,
  project_member,
  onChosen,
}) {
  return (
    <div className="flex items-center justify-between p-3 border-b border-gray-200">
      <div className="flex items-center">
        <img
          className="rounded-full w-12 h-12 object-cover mr-4"
          src={
            project_member.member.profile_photos?.medium ||
            'https://bucketeer-d9937a0d-80e4-469a-91e2-6c86bd37d08f.s3.amazonaws.com/public/user_empty.png'
          }
        />
        <div className="text-left">
          <p className="truncate">{project_member.member.name}</p>
          <p className="font-bold text-xs truncate">
            {project_member.member.title}
          </p>
        </div>
      </div>
      {chosen ? (
        <a
          onClick={() => onChosen && onChosen(project_member.member, chosen)}
          className="flex text-white hover:opacity-80 text-sm font-semibold bg-green-500 px-3 py-1 rounded-md cursor-pointer"
        >
          Added <BiCheck size={20} className="ml-2 text-white" />
        </a>
      ) : (
        <a
          onClick={() => onChosen && onChosen(project_member.member, chosen)}
          className="flex text-white hover:opacity-80 text-sm font-semibold bg-secondary px-3 py-1 rounded-md cursor-pointer"
        >
          Add
        </a>
      )}
    </div>
  );
}
