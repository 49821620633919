import { useState, useEffect } from 'react';
import { Datum } from '../../../data/models';
import cache from '../../../utility/cache';
export default function useCachedDatums(cacheKeyPrefix: string, {
    enabled = true
}: {
    enabled?: boolean
} = {
    }): {
        datums: Datum[];
        storeDatum: (datum: Datum) => void;
    } {

    const [datums, setDatums] = useState<Datum[]>([])

    const storeDatum = (datum: Datum) => {
        const filteredDatums = datums.filter(({ id }) => id !== datum.id)
        const newDatums = [datum, ...filteredDatums.slice(0, 2)]
        cache.store(`${cacheKeyPrefix}-datum-search`, newDatums, 0)
        setDatums([...newDatums])
    }

    useEffect(() => {
        if (!enabled) return;
        const cachedDatums = cache.get(`${cacheKeyPrefix}-datum-search`);
        setDatums(cachedDatums || [])
    }, [enabled, cacheKeyPrefix])

    return {
        datums,
        storeDatum,
    }

}