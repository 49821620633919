import client from './client';
const endpoint = '/folders';

const getFolders = (parentId = '', params = {}) =>
  client.get(`projects/${parentId}${endpoint}`, params);

function getProjectFolder(parentId, folderId) {
  return client.get(`projects/${parentId}/folders/${folderId}`);
}

const getFolder = (id, params = {}) => client.get(`${endpoint}/${id}`, params);
const downloadFolder = (id, params = {}) =>
  client.get(`${endpoint}/${id}/download`, params, { responseType: 'blob' });
const addFolder = (projectId, folder) =>
  client.post(`/projects/${projectId}/folders`, folder);
const updateFolder = (parentId, folderId, folder) =>
  client.put(`projects/${parentId}/folders/${folderId}`, { folder });
const deleteFolder = (projectId, folderId) =>
  client.delete(`projects/${projectId}/folders/${folderId}`);
const bulkDeleteFolders = (ids) =>
  client.delete(`/folders/destroy`, { ids });
const archiveFolder = (projectId, folderId) =>
  client.put(`projects/${projectId}/folders/${folderId}/archive`);
const unarchiveFolder = (projectId, folderId) =>
  client.put(`projects/${projectId}/folders/${folderId}/unarchive`);

export default {
  getFolders,
  getFolder,
  getProjectFolder,
  addFolder,
  updateFolder,
  downloadFolder,
  deleteFolder,
  bulkDeleteFolders,
  archiveFolder,
  unarchiveFolder,
};
