import { useState, useEffect } from 'react';
import Modal, { ModalHeader } from '../utility/Modal';
import useApi from '../../hooks/useApi';
import assetsApi from '../../api/assets';
import Loader from '../utility/Loader';
import Button from '../shared/Button';
import PhotosModal from '../utility/PhotosModal';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import formattedDateString from '../../utility/formattedDateString';

const AssetVersion = ({
  asset: {
    name,
    version,
    creator,
    created_at,
    files: { medium },
  },
  onClick,
}) => (
  <div className="pb-3 bg-white rounded-lg mb-3 shadow border">
    <div className="bg-gray-100 text-gray-900 py-1 px-3 font-semibold border">
      Version {version}
    </div>
    <div className="px-4 py-3 flex">
      <div className="px-4 flex-1 group">
        <img
          onClick={onClick}
          className="object-cover group-hover:opacity-80 h-36"
          loading="lazy"
          src={medium}
        />
      </div>
      <div className="px-4 flex-1 flex flex-col justify-between">
        <div className="flex flex-col">
          <p className="text-sm mb-2 font-semibold">{name}</p>
          <Button text="View" className="mb-3" onClick={onClick} />
        </div>
        <div className="flex">
          <img
            className="object-cover rounded-full h-8 w-8 mr-2"
            src={
              creator.profile_photos?.medium ||
              'https://bucketeer-d9937a0d-80e4-469a-91e2-6c86bd37d08f.s3.amazonaws.com/public/user_empty.png'
            }
          />
          <div>
            <div className="text-xs">
              Added By <span className="font-medium">{creator.name}</span>
            </div>
            <div className="text-xs font-medium">
              {formattedDateString(created_at, 'LLL')}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default function VersionsConstructionDrawingModal({
  constructionDrawing,
  open,
  onClose,
  onDelete,
}) {
  const {
    data: { records, pagy },
    loading,
    request: getAssets,
  } = useApi(assetsApi.getAssets, {
    records: [],
    pagy: {},
  });

  const [versions, setVersions] = useState([]);
  const [searchParams, setSearchParams] = useState({
    q: '',
    page: 1,
    items: 20,
    order: 'version desc',
  });

  const loadMore = () =>
    setSearchParams({ ...searchParams, page: searchParams.page + 1 });
  const hasNextPage = () => !!pagy.next;

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: hasNextPage(),
    onLoadMore: loadMore,
    rootMargin: '0px 0px 400px 0px',
  });

  const [shownAssetIndex, setShownAssetIndex] = useState(-1);

  useEffect(() => {
    if (!open) return;
    getAssets(
      'construction_drawing',
      constructionDrawing.objectId,
      searchParams
    );
  }, [open, searchParams]);

  useEffect(() => {
    if (open) return;
    setSearchParams({
      q: '',
      page: 1,
      items: 20,
      order: 'version desc',
    });
    setVersions([]);
  }, [open]);

  useEffect(() => {
    setVersions((oldVersions) => [...oldVersions, ...records]);
  }, [records]);

  return (
    <>
      <Modal
        isOpen={open}
        onClose={onClose}
        maxHeight="h-full"
        dialogPadding="px-0"
        modalClass="rounded-0"
        side
      >
        <ModalHeader
          closeBtnClass="hidden"
          extraClass="sticky top-0"
          title={
            <div className="mt-2">
              {constructionDrawing.name}{' '}
              <span className="mx-1 font-light">|</span>{' '}
              <span className="font-light">Versions</span>{' '}
            </div>
          }
          onClose={onClose}
        />
        <div className="flex flex-col h-full p-2">
          {versions.map((version, index) => (
            <AssetVersion
              asset={version}
              onClick={() => setShownAssetIndex(index)}
            />
          ))}

          {(loading || hasNextPage()) && (
            <div className="flex justify-center py-10" ref={sentryRef}>
              <Loader color="black" />
            </div>
          )}
        </div>
      </Modal>
      <PhotosModal
        isOpen={shownAssetIndex > -1}
        assets={records}
        isPDF={true}
        activeIndex={shownAssetIndex}
        hideBackdrop={true}
        maxHeight={'max-h-screen'}
        onClose={() => setShownAssetIndex(-1)}
        onDelete={onDelete}
        allowDelete={false}
      />
    </>
  );
}
