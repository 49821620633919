import useApi from './useApi';
import projectContactsApi from '../api/project_contacts';

const useUpdateContact = () => {
  const { data, request } = useApi(projectContactsApi.updateContact, null);

  const updateContact = (projectId: string | undefined, contactId: number, contactData: any) => {
    if (projectId) {
      return new Promise<void>((resolve, reject) => {
        request(projectId, contactId, contactData)
          .then(() => resolve())
          .catch((error) => reject(error));
      });
    }
  };

  return {
    updatedContact: data,
    updateContact,
  };
};

export default useUpdateContact;
