import { Entry } from "../../data/models";

export default function EntryTitle({
    entry,
}: {
    entry: Entry,
}) {
    return <div
        className={`p-2 font-black border-l border-t border-b relative overflow-hidden col-span-2 bg-gray-50 text-center`}
    >
        {entry.name}
    </div>
}