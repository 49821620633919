import client from './client';
const endpoint = '/entry_groups';

const getEntryGroup = (entryGroupId, params = {}) =>
  client.get(`${endpoint}/${entryGroupId}`, params);
const updateEntryGroup = (entryGroupId, entry_group) =>
  client.put(`${endpoint}/${entryGroupId}`, { entry_group });
const resetEntryGroup = (entryGroupId) =>
  client.put(`${endpoint}/${entryGroupId}/reset`);
const getReportEntryGroups = (reportId, params = {}) =>
  client.get(`/reports/${reportId}${endpoint}`, params);
const updateReportEntryGroup = (reportId, entryGroupId, entry_group = {}) =>
  client.put(`/reports/${reportId}${endpoint}/${entryGroupId}`, {
    entry_group,
  });

export default {
  getEntryGroup,
  updateEntryGroup,
  getReportEntryGroups,
  updateReportEntryGroup,
  resetEntryGroup,
};
