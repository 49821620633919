import client from './client';

const endpoint = '/feature_flags';

const getFeatureFlags = () => client.get(`${endpoint}`);
const updateFeatureFlag = (id, feature_flag) => client.put(`${endpoint}/${id}`, { feature_flag });
const destroyFeatureFlag = (id) => client.delete(`${endpoint}/${id}`);

export default {
  getFeatureFlags,
  destroyFeatureFlag,
  updateFeatureFlag
};
