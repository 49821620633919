import { useEffect } from 'react';
import useApi from '../../../hooks/useApi';
import assetsApi from '../../../api/assets';
import { Note } from '../../../data/models';
export default function useNoteAssets(note: Note | null, {
    enabled = false,
    share_id,
}: {
    enabled?: boolean;
    share_id?: string;
}) {
    const {
        data: { records: assets, pagy },
        error,
        loading,
        request: getAssets,
    } = useApi(assetsApi.getAssets, { records: [], pagy: {} }, enabled);

    useEffect(() => {
        if (!enabled || !note) return;
        getAssets('note', note.objectId, { items: 100, share_id });
    }, [enabled, note]);

    return {
        assets,
        pagy,
        loading,
        error,
        getAssets,
    }
}