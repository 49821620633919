import ReportPageEntryGroups from '../report_page_entry_groups/ReportPageEntryGroups';
function ReportPages({ report, pages }) {
  return (
    <>
      {pages.map((page, i) => {
        return <ReportPageEntryGroups
          key={`report_${report.objectId}_page_${page.objectId}_${page.updated_at}_${i}`}
          report={report}
          reportPage={page}
        />
      })}
    </>
  );
}

export default ReportPages;
