import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { FiDownload, FiPrinter } from 'react-icons/fi';
import html2pdf from 'html2pdf.js';
import PrintableReport from '../partials/reports/PrintableReport';
import { Redirect, useParams } from 'react-router-dom';
import useApi from '../hooks/useApi';
import reportsApi from '../api/reports';
import Loader from '../partials/utility/Loader';
import ReportContext from '../partials/reports/context';
import { BiCheckCircle, BiEdit, BiShare, BiShareAlt } from 'react-icons/bi';
import { FaFilePdf } from 'react-icons/fa';
import useQuery from '../hooks/useQuery';
import sizeInMB from '../utility/sizeInMB';

export default function GenerateReport() {
  const {
    data,
    error,
    loading,
    request: getReport,
  } = useApi(reportsApi.getReport, null, true);
  const {
    data: updatedReport,
    error: reportUpdateError,
    loading: reportUpdating,
    request: updateReport,
  } = useApi(reportsApi.updateReport, null);

  const query = useQuery();
  const [updateState, setUpdateState] = useState({
    updating: false,
    text: 'Generate Report',
  });

  const [editing, setEditing] = useState(false);
  const [report, setReport] = useState(null);
  const { workspace_id, project_id, report_id } = useParams();

  const componentRef = useRef(null);
  const generatedTimer = useRef(null);
  const pageBreakTimer = useRef(null);

  const [entryGroupsLoaded, setEntryGroupsLoaded] = useState([]);
  const [pageBreakIndexes, setPageBreakIndexes] = useState([]);
  const [pages, setPages] = useState({
    from: query.get('from'),
    to: query.get('to'),
  });
  const [mapsConverted, setMapsConverted] = useState([]);

  const downloadPdf = async () => {
    setUpdateState({ updating: true, text: 'Building PDF' });

    var opt = {
      margin: 0,
      filename: `${report.name.replace(' ', '_')}.pdf`,
      html2canvas: {
        allowTaint: true,
        imageTimeout: 0,
        useCORS: true,
        scale: 2,
        ignoreElements: function (element) {
          return element.classList.contains('print:hidden');
        },
      },
      jsPDF: {
        unit: 'in',
        format: 'letter',
        orientation: 'portrait',
      },
    };

    let pdfObj = await html2pdf()
      .set(opt)
      .from(componentRef.current)
      .toPdf()
      .get('pdf'); //.then(addHeaderAndFooter)
    let pdfBase64String = pdfObj.output('datauristring');

    // let pdfFileSize = (pdfBase64String.length * (3/4)) - (pdfBase64String.substr(pdfBase64String.length - 2,2).includes('==') ? 2 : 1)
    // return alert(`pdfFileSize ${sizeInMB(pdfFileSize)}MB`)

    let asset_attributes = {
      file: {
        data: pdfObj.output('datauristring'),
        filename: `${report.name.replace(' ', '_')}.pdf`,
      },
    };

    if (report.asset && report.asset.id) asset_attributes.id = report.asset.id;

    setUpdateState({ updating: true, text: 'Saving PDF' });
    updateReport(report.objectId, { asset_attributes });
  };

  useEffect(() => {
    getReport(report_id);
  }, []);

  useEffect(() => {
    data && setReport({ ...data });
  }, [data]);

  useEffect(() => {
    if (
      report &&
      entryGroupsLoaded.length === report.entry_groups.length &&
      entryGroupsLoaded.every((v) => v.loaded === true)
    ) {
      //pageBreakTimer.current = setTimeout(calculatePageBreakIndexes,5000)
    }
    return () => pageBreakTimer.current && clearTimeout(pageBreakTimer.current);
  }, [entryGroupsLoaded]);

  useEffect(() => {
    updatedReport && setReport({ ...updatedReport });
  }, [updatedReport]);

  useEffect(() => {
    updatedReport &&
      setUpdateState({ updating: true, text: 'Report Generated' });
  }, [updatedReport]);

  useEffect(() => {
    updateState.updating && updateState.text === 'Warming Up' && downloadPdf();
  }, [updateState]);

  useEffect(() => {
    updateState.text === 'Converting Maps' &&
      (mapsConverted.length === 0 ||
        mapsConverted.every(({ converted }) => converted)) &&
      downloadPdf();
  }, [mapsConverted]);

  useEffect(() => {
    if (updateState.text === 'Report Generated') {
      generatedTimer.current = setTimeout(() => {
        setEditing(false);
        setMapsConverted([]);
        setUpdateState({ updating: false, text: 'Generate Report' });
      }, 4000);
    }
  }, [updateState]);

  useEffect(() => {
    report && setPageBreakIndexes(report.page_break_indexes);
  }, [report]);

  const generateButton = useMemo(
    () => (
      <>
        {updateState.updating && (
          <div
            id={
              updateState.text === 'Report Generated'
                ? 'report_generated'
                : 'generating_report'
            }
            className="pointer-events-none fixed flex justify-center items-center w-full h-full z-40 bg-gray-700 bg-opacity-25 backdrop-filter backdrop-blur-sm"
          >
            <div className="xs:-mt-24 lg:-ml-52 -mt-20 flex items-center justify-center flex-col">
              {updateState.text === 'Report Generated' ? (
                <BiCheckCircle
                  size={80}
                  className="bg-green-500 text-white rounded-full overflow-hidden mb-3"
                />
              ) : (
                <FaFilePdf
                  size={55}
                  className={`text-secondary mb-3 animate-pulse`}
                />
              )}
              <div className="font-black text-4xl xs:text-5xl text-white my-2">
                {updateState.text}
              </div>
              <div className="font-semibold text-xl xs:text-2xl text-gray-800">
                {updateState.text === 'Report Generated'
                  ? 'Well done - Redirecting to pdf...'
                  : 'Please wait - do not reload page'}
              </div>
            </div>
          </div>
        )}
        <div
          className={`xs:flex lg:ml-52 w-full justify-end fixed z-40 h-20 transition-transform transform delay-4000 ${
            report && '-translate-y-20'
          } -bottom-20 left-0`}
        >
          {report && entryGroupsLoaded.every((v) => v.loaded === true) && (
            <button
              id={'generate_report'}
              disabled={updateState.updating}
              className="bg-green-500 text-2xl text-white font-semibold w-full h-full focus:outline-none pointer-cursor flex justify-center items-center shadow-lg hover:bg-green-400 lg:mr-52 disabled:bg-green-400 disabled:text-gray-700"
              title="Download Report"
              onClick={() =>
                setUpdateState({ updating: true, text: 'Warming Up' })
              }
            >
              {!updateState.updating && (
                <>
                  <FaFilePdf
                    size={30}
                    className={`mr-3 ${
                      updateState.updating && 'animate-pulse'
                    }`}
                  />{' '}
                  {updateState.text}{' '}
                </>
              )}{' '}
              {updateState.updating && (
                <>
                  {' '}
                  {updateState.text === 'Report Generated' ? (
                    <BiCheckCircle size={30} />
                  ) : (
                    <Loader />
                  )}{' '}
                </>
              )}
            </button>
          )}
        </div>
      </>
    ),
    [updateState, report, entryGroupsLoaded]
  );

  const printableReport = useMemo(
    () => <PrintableReport ref={componentRef} report={report} />,
    [report]
  );

  const value = useMemo(() => {
    return {
      pages,
      setPages,
      pageBreakIndexes,
      setPageBreakIndexes,
      mapsConverted,
      setMapsConverted,
      entryGroupsLoaded,
      setEntryGroupsLoaded,
    };
  }, [
    pages,
    setPages,
    pageBreakIndexes,
    setPageBreakIndexes,
    mapsConverted,
    setMapsConverted,
    entryGroupsLoaded,
    setEntryGroupsLoaded,
  ]);

  if (updatedReport) {
    window.location.href = `/${workspace_id}/projects/${project_id}/reports/${report_id}`;
    return null;
  }

  return (
    <ReportContext.Provider value={value}>
      {loading ? (
        <div className="w-full flex py-5 items-center justify-center">
          <Loader color={'text-gray-700'} margin={''} />
        </div>
      ) : (
        <>
          {generateButton}
          {printableReport}
        </>
      )}
    </ReportContext.Provider>
  );
}
