import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import Modal, { ModalSuccessView, ModalHeader } from '../utility/Modal';
import AddPhotoItem from '../project/AddPhotoItem';
import colors from '../../styles/colors';
import moment from 'moment-timezone';
import { GoPlus } from 'react-icons/go';
import toBase64 from '../../utility/toBase64';
import useApi from '../../hooks/useApi';
import meetingsApi from '../../api/meetings';
import Loader from '../utility/Loader';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BiNote } from 'react-icons/bi';
import { HiOutlinePhotograph } from 'react-icons/hi';
import ErrorView from '../utility/ErrorView';
import useModal from '../../hooks/useModal';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';

export default function NewMeetingModal({ afterAdd }) {
  const { project_id } = useParams();
  const {
    data: newMeeting,
    error,
    loading,
    request: addMeeting,
  } = useApi(meetingsApi.addMeeting, null);
  const {
    open: modalOpen,
    setOpen: setModalOpen,
    toggle: toggleModal,
  } = useModal();
  const savedTimer = useRef(null);
  const [meeting, setMeeting] = useState({
    met_at: new Date(),
    notes_attributes: [{}],
  });
  const [saving, setSaving] = useState(false);
  const [files, setFiles] = useState([]);
  const [savedPhotos, setSavedPhotos] = useState([]);
  const [saved, setSaved] = useState(false);

  const saveMeeting = () => {
    if (savedPhotos.length)
      meeting.notes_attributes[0].assets_attributes = savedPhotos.map((id) => {
        return { file: id };
      });
    addMeeting(project_id, meeting);
  };

  const updateMeeting = (key, value) => {
    meeting[key] = value;
    setMeeting({ ...meeting });
  };

  const removeImageAtIndex = (index) => {
    setFiles([...files.filter((f, i) => i !== index)]);
  };

  const updateNote = (value) => {
    meeting.notes_attributes = [
      { ...meeting.notes_attributes[0], text: value },
    ];
    setMeeting({ ...meeting });
  };

  const updateNoteAssets = async (files) => {
    let assets_attributes = [];

    for (const file of files) {
      let base64File = await toBase64(file);
      assets_attributes.push({
        name: file.name,
        file: { data: base64File, filename: file.name },
      });
    }

    meeting.notes_attributes = [
      { ...meeting.notes_attributes[0], assets_attributes: assets_attributes },
    ];

    setMeeting({ ...meeting });
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFiles([...files, ...acceptedFiles]);
    },
    [files]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/jpeg, image/png',
    onDrop,
  });

  const savePhotos = (signedId) => {
    setSavedPhotos([...savedPhotos, signedId]);
  };

  const resetModal = () => {
    setMeeting({
      met_at: moment().format('YYYY-MM-DD[T]HH:mm'),
      notes_attributes: [{}],
    });
  };

  useEffect(() => {
    if (
      (savedPhotos.length > 0 && savedPhotos.length === files.length) ||
      (saving && files.length === 0)
    )
      saveMeeting();
  }, [savedPhotos, saving]);

  useEffect(() => {
    newMeeting && setSaved(true);
  }, [newMeeting]);

  useEffect(() => {
    if (saved) {
      resetModal();
      savedTimer.current = setTimeout(() => {
        setModalOpen(false);
        afterAdd && afterAdd();
        setTimeout(() => {
          setSaved(false);
        }, 500);
      }, 3500);
    }
  }, [saved]);

  const CustomMetAtInput = forwardRef(({ value, onClick }, ref) => (
    <input
      type="text"
      value={value}
      onClick={onClick}
      onChange={() => { }}
      ref={ref}
      className="px-2 bg-gray-50 placeholder-gray-500 relative text-sm border-0 w-full border-0 outline-none focus:outline-none"
    />
  ));

  return (
    <>
      {/* Button */}
      <a
        className="card-add-btn"
        data-testid="button-new-meeting"
        onClick={() => setModalOpen(!modalOpen)}
      >
        <div className="w-full h-24 flex flex-col justify-center text-xs">
          <GoPlus size={16} color={colors.secondary} className="mb-1 mx-auto" />
          <p className="font-bold text-secondary">
            ADD
            <br />
            MEETING
          </p>
        </div>
      </a>
      <Modal
        isOpen={modalOpen}
        dialogScrolls={true}
        modalScrolls={false}
        maxHeight={'max-h-auto'}
        dialogClass={'py-10'}
        modalClass="rounded-lg overflow-hidden"
        aligned={'items-start'}
        onClose={toggleModal}
      >
        <ModalHeader
          title={'Record New Meeting'}
          onClose={toggleModal}
          closeBtnTestId="button-close-new-meeting-modal"
        />
        {saved ? (
          <ModalSuccessView text="New meeting successfully added to project" />
        ) : (
          <div className={`px-2 pt-2 text-left ${loading && 'opacity-50'}`}>
            {error && <ErrorView error={error} />}
            <div className="p-5 text-base">
              <div className="text-lg px-2 mb-6 hidden">
                Meeting recorded by <u>Landon Messal</u>
              </div>
              <div className="font-semibold px-2 mb-2">Date</div>
              <div className="bg-gray-50 border-b border-gray-200 overflow-hidden mb-8">
                <div className="">
                  <DatePicker
                    selected={meeting.met_at}
                    onChange={(date) => updateMeeting('met_at', date)}
                    timeInputLabel="Time:"
                    dateFormat="MM/dd/yyyy h:mm aa"
                    showTimeInput
                    maxDate={new Date()}
                    customInput={<CustomMetAtInput />}
                  />
                </div>
              </div>
              <div className="font-semibold px-2 mb-2">Title</div>
              <div className="bg-white border-b border-gray-200 overflow-hidden mb-8">
                <div className="flex items-center">
                  <input
                    type="text"
                    data-testid="input-meeting-title"
                    onChange={({ target: { value } }) =>
                      updateMeeting('title', value)
                    }
                    placeholder="Ex: Drainage Plan Meeting"
                    className="px-2 bg-gray-50 placeholder-gray-500 relative text-sm border-0 w-full border-0 outline-none focus:outline-none"
                  />
                </div>
              </div>
              <div className="font-semibold px-2 mb-2">Attendees</div>
              <div className="bg-white border-b border-gray-200 overflow-hidden mb-8">
                <div className="flex items-center">
                  <input
                    type="text"
                    data-testid="input-meeting-attendees"
                    onChange={({ target: { value } }) =>
                      updateMeeting('attendees', value)
                    }
                    placeholder="Ex: Lisa, John, Bill"
                    className="px-2 bg-gray-50 placeholder-gray-500 relative text-sm border-0 w-full border-0 outline-none focus:outline-none"
                  />
                </div>
              </div>
              <div className="font-semibold px-2 mb-2">Notes</div>
              <div className="flex p-2 mb-8 border border-gray-200 rounded-md shadow bg-white">
                <BiNote size={16} className="mt-2 text-gray-600" />
                <textarea
                  type="text"
                  data-testid="input-meeting-notes"
                  onChange={({ target: { value } }) => updateNote(value)}
                  placeholder="What was discussed?"
                  className="focus:ring-0 px-2 placeholder-gray-500 relative text-xs border-0 w-full border-0 outline-none focus:outline-none"
                  rows={12}
                />
              </div>
              <div className="font-semibold px-2 mb-2">Photos</div>
              <div className="px-2 pt-2">
                {files.length > 0 && (
                  <>
                    <div className="pb-2 flex flex-wrap">
                      {files.map((file, index) => {
                        return (
                          <AddPhotoItem
                            key={`project_${project_id}_new_meeting_temp_image_${file.path}`}
                            file={file}
                            startSaving={saving}
                            index={index}
                            onSaved={savePhotos}
                            passback={true}
                            onRemove={removeImageAtIndex}
                          />
                        );
                      })}
                    </div>
                  </>
                )}
                <div
                  className="cursor-pointer ring-0 text-sm"
                  {...getRootProps()}
                >
                  <div
                    className={`disabled:opacity-50 p-5 h-32 flex flex-col active:ring-0 items-center justify-center ${!isDragActive
                      ? 'border-2 border-gray-300'
                      : 'border-3 border-secondary bg-blue-100'
                      } border-dashed hover:opacity-80 rounded-md text-center`}
                  >
                    <input {...getInputProps()} />
                    <HiOutlinePhotograph
                      size={32}
                      className={`mb-1 mx-auto ${!isDragActive ? 'text-tertiary-light' : 'text-secondary'
                        }`}
                    />
                    <div
                      className={`font-semibold ${!isDragActive ? 'text-tertiary' : 'text-secondary'
                        }`}
                    >
                      Click to upload photos
                      <br />
                      or drag and drop
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                className="modal-save-btn"
                type="button"
                data-testid="button-save-meeting"
                disabled={loading || saving || !meeting.title}
                onClick={() => setSaving(true)}
              >
                Record Meeting {(loading || saving) && <Loader />}
              </button>
              <button
                className="modal-close-btn"
                type="button"
                disabled={loading || saving}
                onClick={toggleModal}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
}
