import client from './client';
const endpoint = '/reports';

const getReports = (projectId, params = {}) =>
  client.get(`/projects/${projectId}${endpoint}`, params);
const getWorkspaceReports = (workspaceId, params = {}) =>
  client.get(`/workspaces/${workspaceId}${endpoint}`, params);
const getReport = (id, params = {}) => client.get(`${endpoint}/${id}`, params);
const getReportWeather = (id, params = {}) =>
  client.get(`${endpoint}/${id}/weather`, params);
const newReport = (projectId, report) =>
  client.post(`/projects/${projectId}${endpoint}/new`, { report });
const addReport = (projectId, report) =>
  client.post(`/projects/${projectId}${endpoint}`, { report });
const updateReport = (id, report) =>
  client.put(`${endpoint}/${id}`, { report });
const deleteReport = (id) => client.delete(`${endpoint}/${id}`);
const getPinReports = (pinId, params = {}) =>
  client.get(`/pins/${pinId}${endpoint}`, params);

export default {
  getReports,
  getWorkspaceReports,
  getReport,
  getReportWeather,
  getPinReports,
  newReport,
  addReport,
  updateReport,
  deleteReport,
};
