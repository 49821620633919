import { useEffect } from 'react';
import useApi from '../../hooks/useApi';
import Loader from '../utility/Loader';
import constructionDrawingsApi from '../../api/construction_drawings';
import { Redirect, useParams } from 'react-router-dom';

export default function SharedConstuctionDrawing() {
  const {
    data: constructionDrawing,
    loading,
    error,
    request: getConstructionDrawing,
  } = useApi(constructionDrawingsApi.getConstructionDrawing, null, true);

  const { share_id, shareable_id } = useParams();

  useEffect(() => {
    getConstructionDrawing(shareable_id, { share_id });
  }, []);

  if (loading)
    return (
      <div className="w-full flex py-5 items-center justify-center">
        <Loader color={'text-gray-700'} margin={''} />
      </div>
    );

  if (error && error.text() === "Couldn't find ConstructionDrawing")
    return (
      <Redirect
        to={{
          pathname: `/`,
          state: { error: error.text() },
        }}
      />
    );

  return (
    <div className="flex items-center justify-center overflow-auto flex-col h-full" data-testid="sharedCdContainer">
      <iframe
        className="w-full h-full"
        title={constructionDrawing?.name}
        src={constructionDrawing?.files?.original}
      ></iframe>
    </div>
  );
}
