import logo from '../images/logos/logo-white-icon-orange.svg';
import Button from '../partials/shared/Button';
export default function ErrorBoundaryView() {
    return (
        <div className="w-screen h-screen flex justify-center items-center inset-0 bg-secondary fixed">
            <div className="text-center px-5">
                <img src={logo} className="mb-12 w-3/4 mx-auto" />
                <p className="text-4xl text-white font-bold mb-4">Oh geez...</p>
                <p className="text-2xl text-white font-semibold mb-2">An unexpected error occured.</p>
                <p className="text-2xl text-gray-200 font-medium italic">Try reloading the app.</p>
                <Button text='Reload App' className="mt-8 text-lg px-5 py-3" color='dark' onClick={() => window.location.reload()} />
            </div>
        </div>
    );
}