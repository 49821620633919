import { memo, useEffect, useState } from 'react';
import { FiX } from 'react-icons/fi';
import useModal from '../../hooks/useModal';
import useApi from '../../hooks/useApi';
import Modal, { ModalHeader } from '../utility/Modal';
import pinsApi from '../../api/pins';
import Loader from '../utility/Loader';

const ConfirmDeletePinModal = ({ pin, onDeletePin }) => {
  const {
    open: modalOpen,
    toggle: toggleModal,
    setOpen: setModalOpen,
  } = useModal();

  const { data, loading, error, request } = useApi(pinsApi.deletePin, null);

  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (!error) return;
    setErrorMessage(
      error?.text?.() ?? 'Error deleting pin, please try again.'
    );
  }, [error]);

  useEffect(() => {
    if (!data) return;
    toggleModal();
    onDeletePin();
  }, [data]);

  return (
    <>
      <button
        onClick={() => toggleModal()}
        className="rounded-lg mt-2 focus:outline-none hover:bg-gray-50 text-gray-500 border border-2 border-gray-200 font-bold px-2 py-1 flex items-center text-sm"
      >
        <FiX size={18} className={'text-gray-500 mr-2'} /> Delete Pin
      </button>
      <Modal isOpen={modalOpen} onClose={toggleModal} modalClass={'rounded-lg'}>
        <ModalHeader
          title={`Delete Pin: ${pin.pin_type.name}`}
          onClose={toggleModal}
        />

        <div className="text-left text-base flex items-center justify-between px-5 py-3 bg-white">
          {`Are you sure you want to delete this pin?`}
        </div>

        <div className="modal-footer">
          {errorMessage ? (
            <div className="text-red-500 text-sm bg-white px-4">
              {errorMessage}
            </div>
          ) : null}
          <button
            className="modal-remove-btn"
            type="button"
            disabled={loading}
            onClick={async () => {
              setErrorMessage(null);
              await request(pin.objectId);
            }}
            data-testid="test-delete-btn"
          >
            Delete
            {loading && <Loader />}
          </button>
          <button
            className="modal-close-btn"
            type="button"
            disabled={loading}
            onClick={() => {
              toggleModal();
            }}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};

export default memo(ConfirmDeletePinModal);
