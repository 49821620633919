import { useEffect } from 'react';
import * as turf from '@turf/turf';
import { defaultOpacity } from '../../../utility/mapLayerConfig';
import { createLoaderElement } from '../../utility/Loader';
import mapboxgl from 'mapbox-gl';
import { KML_FILE_TYPES } from '../hooks/useFileMapLayerData';

const SHOW_LOADER_FILE_TYPES = ['PDF', 'JPG', 'PNG', 'JPEG'];

const layerId = (layer) => {
  if (KML_FILE_TYPES.includes(layer.file_type)) return `${layer.objectId}-lines`;
  if (!layer.latest_upload || layer.latest_upload.status !== 'COMPLETE')
    return `${layer.objectId}-image`;
  return layer.objectId;
};

function getRotation({ layer }) {
  const rot = layer.rotation;
  const coordinates = [
    [layer.bounding_box.west, layer.bounding_box.north],
    [layer.bounding_box.east, layer.bounding_box.north],
    [layer.bounding_box.east, layer.bounding_box.south],
    [layer.bounding_box.west, layer.bounding_box.south],
  ];

  let rotated = turf.transformRotate(
    turf.bboxPolygon([
      coordinates[0][0],
      coordinates[0][1],
      coordinates[2][0],
      coordinates[2][1],
    ]),
    rot
  );

  return turf.getCoords(rotated)[0].slice(0, 4);
}

function initKMLLayer({ map, layer }) {
  if (!map.getSource(layer.objectId)) {
    map.addSource(layer.objectId, {
      type: 'geojson',
      data: layer.asset?.files?.original,
    });
  }
  map.addLayer({
    id: `${layer.objectId}-lines`,
    type: 'line',
    source: layer.objectId,
    paint: {
      'line-color': [
        'coalesce',
        ['get', 'line-color'],
        ['get', 'stroke'], // backwards compat
      ],
      'line-width': [
        'coalesce',
        ['get', 'line-width'],
        ['get', 'width'], // backwards compat
        1.75,
      ],
    },
    filter: ['==', '$type', 'LineString'],
  });
  map.addLayer({
    id: `${layer.objectId}-polygons`,
    type: 'fill',
    source: layer.objectId,
    paint: {
      'fill-color': [
        'coalesce',
        ['get', 'fill-color'],
        ['get', 'stroke'], // backwards compat
      ],
      // default to 0.5 opacity
      'fill-opacity': ['coalesce', ['get', 'fill-opacity'], defaultOpacity],
    },
    filter: ['==', '$type', 'Polygon'],
  });
  map.addLayer({
    id: `${layer.objectId}-points`,
    type: 'circle',
    source: layer.objectId,
    paint: {
      'circle-radius': 2,
      'circle-color': ['get', 'stroke'],
    },
    filter: ['==', '$type', 'Point'],
  });
}

function initTemporaryImageLayer({ map, layer, coordinates }) {

  if (!map.getSource(layerId(layer))) {
    map.addSource(layerId(layer), {
      type: 'image',
      url: layer.asset?.files?.large,
      coordinates,
    });
  }

  map.addLayer({
    id: layerId(layer),
    type: 'raster',
    source: layerId(layer),
    paint: {
      'raster-fade-duration': 0,
      'raster-opacity': parseFloat(layer.opacity || defaultOpacity),
    },
  });

  if (!map.getSource(`${layerId(layer)}-loader`) && SHOW_LOADER_FILE_TYPES.includes(layer.file_type)) {
    map.addSource(`${layerId(layer)}-loader`, {
      type: 'image',
      url: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdj+P///38ACfsD/QVDRcoAAAAASUVORK5CYII=',
      coordinates,
    });
  }

  if (SHOW_LOADER_FILE_TYPES.includes(layer.file_type)) {
    map.addLayer({
      id: `${layerId(layer)}-loader`,
      type: 'raster',
      source: `${layerId(layer)}-loader`,
      paint: {
        'raster-fade-duration': 0,
        'raster-opacity': parseFloat(layer.opacity || defaultOpacity),
      },
    });
  }


  const features = turf.points([
    [layer.bounding_box.west, layer.bounding_box.north],
    [layer.bounding_box.east, layer.bounding_box.south],
  ]);

  const center = turf.center(features);
  const marker = new mapboxgl.Marker({
    color: "#FFFFFF",
    element: createLoaderElement({ color: "black" }),
    id: `${layerId(layer)}-loader-spinner`,
  }).setLngLat(center.geometry.coordinates)
    .addTo(map);

  return marker
}

function initTilesetLayer({ map, layer }) {
  map.addLayer({
    id: layer.objectId,
    source: {
      type: 'raster',
      url: 'mapbox://' + layer.latest_upload.payload.tileset,
    },
    'source-layer': layer.latest_upload.payload.tileset,
    type: 'raster',
    paint: {
      'raster-fade-duration': 0,
      'raster-opacity': parseFloat(layer.opacity || defaultOpacity),
    },
  });
}


export default function MapBoxMapLayer({ layer, map }) {

  let loaders = [];

  const resetLayers = () => {
    if (map.getLayer(layerId(layer))) map.removeLayer(layerId(layer));
    if (map.getLayer(`${layerId(layer)}-loader`)) map.removeLayer(`${layerId(layer)}-loader`);
    if (map.getLayer(`${layerId(layer)}-loader-spinner`)) map.removeLayer(`${layerId(layer)}-loader-spinner`);
    if (map.getLayer(`${layer.objectId}-polygons`)) map.removeLayer(`${layer.objectId}-polygons`);
    if (map.getLayer(`${layer.objectId}-points`)) map.removeLayer(`${layer.objectId}-points`);
    if (map.getSource(`${layerId(layer)}-loader`)) map.removeSource(`${layerId(layer)}-loader`);
    if (map.getSource(layerId(layer))) map.removeSource(layerId(layer));
    loaders.map((l) => l?.remove?.());
  }

  const initializeLayer = () => {
    resetLayers();
    if (KML_FILE_TYPES.includes(layer.file_type)) return initKMLLayer({ map, layer });
    else if (!layer.latest_upload || layer.latest_upload.status !== 'COMPLETE') {
      loaders.push(initTemporaryImageLayer({ map, layer, coordinates: getRotation({ layer }) }));
    }
    else initTilesetLayer({ map, layer });
  }

  useEffect(() => {
    initializeLayer();
    return resetLayers;
  }, [])

  useEffect(() => {
    if (KML_FILE_TYPES.includes(layer.file_type) || !map?.getLayer(layerId(layer))) return;

    map.setPaintProperty(
      layerId(layer),
      'raster-opacity',
      parseFloat(layer.opacity || defaultOpacity)
    );

    if (map?.getLayer(`${layerId(layer)}-loader`)) map.setPaintProperty(
      `${layerId(layer)}-loader`,
      'raster-opacity',
      parseFloat(layer.opacity || defaultOpacity)
    );

  }, [layer.opacity, map])

  useEffect(() => {

    const onStyleDataUpdate = (mapDataEvent) => {
      if (mapDataEvent?.style?._order?.indexOf(layerId(layer)) === -1) initializeLayer();
    }

    const onMapDataLoaded = (mapDataEvent) => {
      if (layerId(layer) !== mapDataEvent.sourceId) return;
      if (map.getLayer(`${layerId(layer)}-loader`) && mapDataEvent.isSourceLoaded) {
        map.removeLayer(`${layerId(layer)}-loader`);
        loaders.map((l) => l?.remove?.());
        loaders = []
      }
    }

    map.on('styledata', onStyleDataUpdate);
    map.on('sourcedata', onMapDataLoaded);

    return () => {
      map.off('styledata', onStyleDataUpdate);
      map.off('sourcedata', onMapDataLoaded);
    }

  }, [])

  return <div
    className="hidden"
    data-testid={`mapLayer${layer.name}`}
    data-mapLayer={JSON.stringify(layer)}
  />
}
