import useModal from '../../hooks/useModal';
import Modal, { ModalHeader } from '../utility/Modal';
import MeetingViewBody from './MeetingViewBody';

export default function ViewMeetingDetailsModal({ meeting }) {
  const { open: modalOpen, toggle: toggleModal } = useModal();

  return (
    <div>
      <a className="cursor-pointer" onClick={toggleModal}>
        <div className="text-secondary font-semibold text-sm">View Details</div>
      </a>
      <Modal isOpen={modalOpen} onClose={toggleModal}>
        <ModalHeader title={'Meeting Details'} onClose={toggleModal} />
        <MeetingViewBody meeting={meeting} />
        <div className="modal-footer">
          <button
            className="modal-close-btn"
            type="button"
            onClick={toggleModal}
          >
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
}
