import { memo, useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import useApi from '../../hooks/useApi';
import documentsApi from '../../api/documents';
import Loader from '../utility/Loader';
import Modal, { ModalHeader, ModalSuccessView } from '../utility/Modal';
import AddPhotoItem from '../project/AddPhotoItem';
import { useParams } from 'react-router-dom';
import FileIcon from '../shared/FileIcon';
import { AiOutlineCaretDown, AiOutlineFileAdd } from 'react-icons/ai';

const ReadyToUploadBody = ({ isDragActive }) => (
  <>
    <AiOutlineFileAdd
      size={60}
      className={`mb-1 mx-auto ${!isDragActive ? 'text-tertiary-light' : 'text-secondary'
        }`}
    />
    <div
      className={`font-semibold ${!isDragActive ? 'text-tertiary' : 'text-secondary'
        }`}
    >
      Click to upload file
      <br />
      or drag and drop
    </div>
  </>
);

const VersionUploadBody = ({ file, oldName, newName }) => (
  <>
    <div className={`border-2 border-gray-900 bg-gray-50 pb-1 rounded-lg`}>
      <div
        className={`font-semibold text-xs text-gray-800 py-1 bg-gray-200 rounded-t-lg`}
      >
        Old
      </div>
      <div className={`py-2 px-3`}>
        <FileIcon
          file={file}
          size={30}
          className={`mb-1 mx-auto text-gray-800`}
        />
        <div className={`font-semibold text-sm text-gray-800`}>{oldName}</div>
      </div>
    </div>
    <div className={`py-2`}>
      <AiOutlineCaretDown size={20} className={`mb-1 mx-auto text-secondary`} />
    </div>
    <div className={`border-2 border-blue-900 pb-1 rounded-lg`}>
      <div
        className={`font-semibold text-xs text-blue-800 py-1 bg-blue-200 rounded-t-lg`}
      >
        New
      </div>
      <div className={`py-2 px-3`}>
        <FileIcon
          file={file}
          size={30}
          className={`mb-1 mx-auto text-secondary`}
        />
        <div className={`font-semibold text-sm text-secondary`}>{newName}</div>
      </div>
    </div>
  </>
);

function AddDocumentVersionModal({
  open,
  currentFolder,
  document,
  onCreate,
  onClose,
}) {
  const { data: updatedDocument, request: updateDocument } = useApi(
    documentsApi.updateDocument,
    null
  );

  const [file, setFile] = useState();
  const [saving, setSaving] = useState(false);
  const [signedFile, setSignedFile] = useState();
  const { project_id } = useParams();

  const _onClose = () => {
    setTimeout(() => {
      onClose?.();
    }, 1000);
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFile(acceptedFiles[0]);
    },
    [file]
  );

  const addSignedFile = (signature, file) => {
    setSignedFile({ signature, file });
  };

  useEffect(async () => {
    if (!signedFile) return;

    const updateDocumentVersion = async () => {
      let newFileData = {
        name: signedFile.file.name,
        description: 'test',
        version: '1',
        assets_attributes: [
          {
            file: signedFile.signature,
          },
        ],
      };

      await updateDocument(
        project_id,
        currentFolder.objectId,
        document.objectId,
        newFileData
      );
    };

    await updateDocumentVersion();
    setSaving(false);

    _onClose?.();
    setTimeout(() => onCreate?.(), 1500);
  }, [file, signedFile]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const removeFile = () => setFile();

  return (
    <>
      <Modal isOpen={open} onClose={onClose} modalClass={'rounded-lg'}>
        <ModalHeader title={'Upload New File'} onClose={onClose} />

        {updatedDocument ? (
          <div className="p-5">
            <ModalSuccessView
              text={'New Version of Document successfully uploaded'}
            />
          </div>
        ) : (
          <>
            <div
              className={`flex flex-wrap items-start ${!!file ? 'py-4 px-5' : ''
                }`}
            >
              {file && (
                <div className="hidden">
                  <AddPhotoItem
                    key={`project_${project_id}_temp_image_${file.path}`}
                    file={file}
                    hide={true}
                    startSaving={saving}
                    index={0}
                    onSaved={addSignedFile}
                    passback={true}
                    description={null}
                    onRemove={removeFile}
                  />
                </div>
              )}
            </div>

            <div
              className="cursor-pointer outline-none ring-0 text-base w-full px-5 pb-4 pt-2"
              {...getRootProps()}
              data-testid="dropzone-photo-file"
            >
              <div
                disabled={saving !== false}
                className={`disabled:opacity-50 p-5 h-72 flex flex-col active:ring-0 items-center justify-center ${!isDragActive
                  ? 'border-2 border-gray-300'
                  : 'border-3 border-secondary bg-blue-100'
                  } border-dashed hover:opacity-80 rounded-md text-center`}
              >
                <input {...getInputProps()} data-testid="input-photo-file" />
                {file && saving && (
                  <div className="absolute w-full flex h-full justify-center items-center">
                    <Loader color={'gray-800'} margin={''} />
                  </div>
                )}
                {file ? (
                  <VersionUploadBody
                    file={file}
                    oldName={document.name}
                    newName={file.name}
                  />
                ) : (
                  <ReadyToUploadBody isDragActive={isDragActive} />
                )}
              </div>
            </div>
          </>
        )}

        <div className="modal-footer">
          <button
            className="modal-save-btn"
            type="button"
            disabled={!file || saving !== false}
            onClick={() => setSaving(true)}
          >
            Save
            {!!saving && <Loader className="ml-3" />}
          </button>
          <button
            className="modal-close-btn"
            type="button"
            disabled={false}
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
}

export default memo(AddDocumentVersionModal);
