import React, { useEffect } from 'react';
import useApi from '../../hooks/useApi';
import imagesApi from '../../api/images';
import moment from 'moment-timezone';
import Loader from './Loader';
import formattedDateString from '../../utility/formattedDateString';
export default function PhotoModalImage({ image_id }) {
  const {
    data: image,
    error,
    loading,
    request: loadImage,
  } = useApi(imagesApi.getImage, null, true);

  useEffect(() => {
    loadImage(image_id);
  }, []);

  return loading ? (
    <div className="flex justify-center">
      <Loader margin="m-0" />
    </div>
  ) : (
    <div className="flex flex-grow border-b border-gray-900 mb-4 pb-3">
      <img
        className="object-cover rounded-full h-12 w-12"
        src={
          image.creator.profile_photos?.medium ||
          'https://bucketeer-d9937a0d-80e4-469a-91e2-6c86bd37d08f.s3.amazonaws.com/public/user_empty.png'
        }
      />
      <div className="pl-3">
        <p className="font-bold text-white">{image.creator.name}</p>
        <p className="font-bold text-gray-100 text-sm flex items-center mb-1">
          Added Image
        </p>
        <p className="text-gray-100 text-sm">{image.description}</p>
        <div className="flex justify-between mt-2">
          <p className="text-gray-100 text-xs">
            {formattedDateString(image.created_at, 'LLL')}
          </p>
        </div>
      </div>
    </div>
  );
}
