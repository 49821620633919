import { IoIosClose } from 'react-icons/io';
import {
  makeDarkerColor,
  makeLighterColor,
} from '../../utility/colorAdjustments';
import { useState } from 'react';
import TagInput from '../tags/TagInput';
import TagEditMenu from '../tags/TagEditMenu';

const tagSizes = {
  sm: 'text-xxs inline-flex font-medium rounded-full text-center px-1.5 py-1 whitespace-nowrap',
  md: 'text-xs inline-flex font-medium rounded-full text-center px-2.5 py-1 whitespace-nowrap',
  lg: 'text-sm inline-flex font-medium rounded-full text-center px-2.5 py-1 whitespace-nowrap',
};

export const TagContainer = ({
  id,
  color: tagColor,
  size,
  className,
  children,
}) => {
  const backgroundColor = makeLighterColor(tagColor, 0.55);
  const color = makeDarkerColor(tagColor, 0.7);

  return (
    <div
      className={`${tagSizes[size]} group relative pointer-cursor ${className}`}
      style={{ backgroundColor, color }}
      data-tag-id={id}
    >
      {children}
    </div>
  );
};

export default function Tag({
  tag,
  className,
  size = 'md',
  onDestroy,
  onRemove,
  onUpdate,
}) {
  const [tagColor, setTagColor] = useState(tag?.color || '#fefefe');
  const backgroundColor = makeLighterColor(tagColor, 0.55);
  const color = makeDarkerColor(tagColor, 0.7);
  const [renaming, setRenaming] = useState();

  const saveTag = (newTag) => {
    onUpdate?.(newTag);
    setRenaming(false);
  };

  return (
    <>
      <TagContainer
        className={className}
        color={tagColor}
        size={size}
        id={tag.id}
      >
        {renaming ? <TagInput tag={tag} onUpdate={saveTag} /> : tag.name}
        <div className="absolute right-0 top-0 flex h-full cursor-pointer">
          {!renaming && (onUpdate || onDestroy) && (
            <TagEditMenu
              tag={tag}
              color={tagColor}
              onUpdate={onUpdate}
              onDestroy={onDestroy}
              setTagColor={setTagColor}
              onRenaming={() => setRenaming(true)}
            />
          )}

          {onRemove && (
            <div
              className="hidden group-hover:flex h-full w-5 text-opacity-50 hover:text-opacity-100 items-center justify-center rounded-r-full"
              style={{ color, backgroundColor }}
              onClick={() => onRemove(tag)}
            >
              <IoIosClose size={24} />
            </div>
          )}
        </div>
      </TagContainer>
    </>
  );
}
