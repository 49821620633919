import { useState } from 'react';
import Loader from '../utility/Loader';
import PhotosModal from '../utility/PhotosModal';
import { useParams } from 'react-router-dom';
import useAssets from '../assets/hooks/useAssets';

const NOTE_PHOTO_SIZES = {
  sm: 'h-8 w-8',
  md: 'h-16 w-16',
  lg: 'h-24 w-24',
  xl: 'h-42 w-42',
};
export default function NotePhotos({ note, size = 'md', forReport = false }) {
  const { share_id } = useParams();
  const {
    assets,
    loading,
  } = useAssets(note.objectId, 'note', { share_id });
  const [shownPhotoIndex, setShownPhotoIndex] = useState(-1);

  return (
    <div className="pt-1.5 mt-2 pb-2 px-1.5 w-full" data-testid="siteNotePhotos">
      {loading ? (
        <div className="w-full flex justify-center h-20 items-center">
          <Loader color={'gray-500'} />
        </div>
      ) : (
        <div className="flex flex-wrap">
          {assets.map(
            ({ objectId, files }, index) => {
              return <div
                key={`notes_photos_asset_${objectId}`}
                className={`flex ${NOTE_PHOTO_SIZES[size]} mr-1.5 mb-1.5 cursor-pointer hover:opacity-90`}
              >
                <img
                  className="w-full h-full object-cover"
                  src={files.medium}
                  onClick={() => setShownPhotoIndex(index)}
                />
              </div>
            }
          )}
          {!loading && assets.length === 0 && (
            <p className="italic">No photos attached</p>
          )}
        </div>
      )}
      <PhotosModal
        isOpen={shownPhotoIndex > -1}
        assets={assets}
        activeIndex={shownPhotoIndex}
        hideBackdrop={true}
        maxHeight={'max-h-screen'}
        onClose={() => setShownPhotoIndex(-1)}
      />
    </div>
  );
}
