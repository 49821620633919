import { useState } from 'react';
import { SketchPicker } from 'react-color';
import Button from '../shared/Button';
import ClickBackdrop, { clickBackdropZindex } from '../utility/ClickBackdrop';
export default function TagColor({
  tag,
  onDropdown,
  onUpdate,
  onColorChange,
  style = {},
}) {
  const [tagColor, setTagColor] = useState(tag?.color || '#fefefe');

  const onChange = ({ hex }) => {
    onColorChange?.(hex);
    setTagColor(hex);
  };

  const saveColor = () => {
    onUpdate?.({ ...tag, color: tagColor });
  };

  return (
    <>
      <ClickBackdrop testId="tagColorBackdrop" onClick={saveColor} />
      <div
        className={`origin-top-right ${clickBackdropZindex} absolute top-full ${onDropdown ? 'left-56' : 'left-0'
          } min-w-44 bg-white border border-gray-200 rounded shadow-lg overflow-hidden mt-1`}
        style={style}
      >
        <SketchPicker color={tagColor} onChange={onChange} />
        <Button
          text="Save"
          className="w-full"
          rounded="rounded-b"
          onClick={saveColor}
        />
      </div>
    </>
  );
}
