import { useState, useEffect, useRef } from 'react';
export default function useSaved(saved: boolean | string, { afterSaved, delay = 2000 }: { afterSaved?: any, delay?: number } = {}) {
    const delayTimer = useRef<NodeJS.Timeout | null>(null);
    const [finalSaved, setFinalSaved] = useState(false);

    useEffect(() => {
        if (!saved) return;

        if (delayTimer.current) clearTimeout(delayTimer.current);

        setFinalSaved(true)

        delayTimer.current = setTimeout(() => {
            afterSaved?.();
            setFinalSaved(false);
        }, delay);

    }, [saved])

    return { saved: finalSaved }
}