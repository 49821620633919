import { useState, useRef, useEffect } from 'react';
import Transition from '../utils/Transition.js';
import { AddExtraProps } from '../utility/addExtraProps.js';
import ClickBackdrop, { clickBackdropZindex } from './utility/ClickBackdrop';

function EditMenu({
  action = 'hover',
  align = 'right',
  button,
  dataTestId = 'editMenuButton',
  clickBackdrop = false,
  closeOnClick = false,
  icon = (
    <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32">
      <circle cx="16" cy="16" r="2" />
      <circle cx="10" cy="16" r="2" />
      <circle cx="22" cy="16" r="2" />
    </svg>
  ),
  menuPadding = 'py-1.5',
  onOpen,
  children,
  ...rest
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropDownCloseTimer = useRef(null);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    if (action === 'click') {
      const clickHandler = ({ target }) => {
        if (
          !dropdownOpen ||
          (dropdown.current && dropdown.current.contains(target)) ||
          (trigger.current && trigger.current.contains(target))
        )
          return;
        setDropdownOpen(false);
      };
      document.addEventListener('click', clickHandler);
      return () => document.removeEventListener('click', clickHandler);
    }
  });

  // // close if the esc key is pressed
  useEffect(() => {
    if (action === 'click') {
      const keyHandler = ({ keyCode }) => {
        if (!dropdownOpen || keyCode !== 27) return;
        setDropdownOpen(false);
      };
      document.addEventListener('keydown', keyHandler);
      return () => document.removeEventListener('keydown', keyHandler);
    }
  });

  useEffect(() => {
    if (!dropdownOpen) return;
    onOpen?.();
  }, [dropdownOpen]);

  return (
    <>
      {clickBackdrop && dropdownOpen && (
        <ClickBackdrop
          testId="editMenuBackdrop"
          onClick={() => setDropdownOpen(false)}
        />
      )}
      <div {...rest}>
        {button ? (
          AddExtraProps(button, {
            'aria-haspopup': 'true',
            'aria-expanded': dropdownOpen,
            'data-testid': dataTestId,
            onClick: (e) => setDropdownOpen(!dropdownOpen),
            onMouseEnter: () => action === 'hover' && setDropdownOpen(true),
            onMouseLeave: () => {
              if (action === 'hover')
                dropDownCloseTimer.current = setTimeout(
                  () => setDropdownOpen(false),
                  300
                );
            },
            ref: trigger,
          })
        ) : (
          <button
            data-testid={dataTestId}
            ref={trigger}
            className={`text-gray-400 hover:text-gray-500 flex items-center focus:outline-none rounded-full ${dropdownOpen && 'bg-gray-100 text-gray-500'
              }`}
            aria-haspopup="true"
            onClick={() => setDropdownOpen(!dropdownOpen)}
            onMouseEnter={() => action === 'hover' && setDropdownOpen(true)}
            onMouseLeave={() => {
              if (action === 'hover')
                dropDownCloseTimer.current = setTimeout(
                  () => setDropdownOpen(false),
                  300
                );
            }}
            aria-expanded={dropdownOpen}
          >
            <span className="sr-only">Menu</span>
            {icon}
          </button>
        )}
        <Transition
          show={dropdownOpen}
          tag="div"
          className={`origin-top-right ${clickBackdrop ? clickBackdropZindex : 'z-10'} absolute top-full ${align === 'right' ? 'right-0' : 'left-0'
            } min-w-44 bg-white border border-gray-200 ${menuPadding} rounded shadow-lg overflow-hidden mt-1`}
          enter="transition ease-out duration-200 transform"
          enterStart="opacity-0 -translate-y-2"
          enterEnd="opacity-100 translate-y-0"
          leave="transition ease-out duration-200"
          leaveStart="opacity-100"
          leaveEnd="opacity-0"
          onMouseEnter={() =>
            dropDownCloseTimer.current &&
            clearTimeout(dropDownCloseTimer.current)
          }
          onMouseLeave={() => action === 'hover' && setDropdownOpen(false)}
        >
          <ul
            ref={dropdown}
            onClick={() => action === 'click' && closeOnClick && setDropdownOpen(false)}
            onFocus={() => action === 'hover' && setDropdownOpen(true)}
            onBlur={() => action === 'hover' && setDropdownOpen(false)}
          >
            {children}
          </ul>
        </Transition>
      </div>
    </>
  );
}

export default EditMenu;
