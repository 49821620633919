import { useEffect } from 'react';
import useApi from '../../../hooks/useApi';
import sharesApi from '../../../api/shares';
export default function useShares(params: any = {}, {
    load = true
}: {
    load?: boolean
} = {
    }) {
    const {
        data: {
            records: shares,
            pagy,
        },
        error,
        loading,
        request: getShares,
    } = useApi(sharesApi.getShares, {
        records: [],
        pagy: null
    }, load);

    useEffect(() => {
        if (!load) return;
        getShares(params);
    }, [load, JSON.stringify(params)]);

    return {
        shares,
        error,
        loading,
        pagy,
    }
}