import React, { memo, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import Loader from '../utility/Loader';
import SearchForm from '../actions/SearchForm';
import useApi from '../../hooks/useApi';
import mapLayersApi from '../../api/map_layers';
import { Link, useParams } from 'react-router-dom';
import { GoPlus } from 'react-icons/go';
import ArchiveMapLayerButton from './ArchiveMapLayerButton';
import { maxMapLayerCount } from '../../utility/mapLayerConfig';
const notImageableFileTypes = ['KML', 'KMZ'];

function LayersList({ onSearch }) {
  const {
    data: { records: layers, pagy },
    error,
    loading,
    request: loadLayers,
  } = useApi(mapLayersApi.getProjectMapLayers, { records: [], pagy: {} }, true);
  const { workspace_id, project_id } = useParams();
  const [searchParams, setSearchParams] = useState({
    page: 1,
    items: maxMapLayerCount,
  });

  const loadMapLayers = () => {
    loadLayers(project_id, searchParams);
  };

  useEffect(loadMapLayers, [searchParams]);

  return (
    <>
      <div className="grid grid-cols-5 gap-1 pb-3 px-5 bg-gray-50 shadow">
        <div className="col-span-4">
          <SearchForm
            placeholder={'Search Map Layers...'}
            className="bg-white w-full"
            onSearched={onSearch}
          />
        </div>
        <div className="col-span-1 rounded-sm border border-gray-200 flex justify-center items-center bg-white">
          {/* <PinsFilterDropdown onFilter={onFilter} currentFilter={currentFilter} /> */}
          {/* <AddLayerButton project={project} /> */}
          <Link
            className="cursor-pointer w-full h-full flex items-center justify-center"
            to={`/${workspace_id}/projects/${project_id}/map_layers/new`}
          >
            <GoPlus size={16} className={'text-secondary'} />
          </Link>
        </div>
      </div>
      <div className="bg-white shadow border text-xs flex items-center justify-end px-3 py-2">
        <p className={`mr-2 text-gray-700 font-medium`}>Archived</p>
        <div className="form-switch secondary focus:outline-none">
          <input
            type="checkbox"
            id="switch"
            className="sr-only"
            onChange={() =>
              searchParams.archived
                ? setSearchParams({ ...searchParams, archived: null })
                : setSearchParams({ ...searchParams, archived: true })
            }
            checked={searchParams.archived}
          />
          <label className="bg-gray-400" htmlFor="switch">
            <span className="bg-white shadow-sm" aria-hidden="true"></span>
            <span className="sr-only">Switch label</span>
          </label>
        </div>
      </div>
      <div className="text-black text-xs h-full px-5 py-2 overflow-y-auto">
        {loading && (
          <div className="flex py-4 justify-center">
            <Loader color="black" />
          </div>
        )}
        <div>
          {layers.map((layer) => {
            return (
              <Link
                key={`layer_list_layer_${layer.objectId}`}
                className="cursor-pointer relative"
                to={`/${workspace_id}/projects/${project_id}/map_layers/${layer.objectId}`}
              >
                <div className="bg-white rounded-sm border border-gray-200 overflow-hidden hover:shadow mb-2">
                  <div className="flex flex-col h-full">
                    <div className="grid grid-cols-4">
                      <div className="col-span-1 flex justify-center items-center ">
                        {notImageableFileTypes.includes(layer.file_type) ? (
                          <img
                            src={`/icons/${layer.file_type.toLowerCase()}_icon.png`}
                            className="object-contain h-8"
                          />
                        ) : (
                          <img
                            src={layer.asset.files.medium}
                            className="object-cover h-8"
                          />
                        )}
                      </div>
                      <div className="col-span-3 p-2">
                        <div className="flex flex-row items-center">
                          <div className="font-bold mr-2">{layer.name}</div>
                        </div>
                        <div className="text-xxxs">
                          Last updated{' '}
                          {moment(layer.updated_at).format('MMM D, YYYY')}
                        </div>
                      </div>
                      <ArchiveMapLayerButton
                        mapLayer={layer}
                        archived={layer.archived_at !== null}
                        onSaved={loadMapLayers}
                      />
                    </div>
                  </div>
                </div>
              </Link>
            );
          })}
          {!loading && layers.length === 0 && (
            <div className="flex justify-center">
              <p className="py-3 font-semibold">
                No {searchParams.archived && 'archived'} map layers exist on
                project.
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default LayersList;
