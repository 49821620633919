import { useEffect } from 'react';
import notesApi from '../../../api/notes';
import useApi from '../../../hooks/useApi';
import useSaved from '../../../hooks/useSaved';

export default function useNote(noteId: string | null, {
    load = true
}: {
    load?: boolean
} = {}) {
    const {
        data: note,
        error,
        loading,
        request: loadNote,
    } = useApi(notesApi.getNote, null, load);

    const {
        data: updatedNote,
        error: updateError,
        loading: updating,
        request: updateNote,
    } = useApi(notesApi.updateNote, null);

    const {
        data: deletedNote,
        error: deleteError,
        loading: deleting,
        request: deleteNote,
    } = useApi(notesApi.destroyNote, null);

    const _loadNote = () => {
        if (noteId) loadNote(noteId);
    }

    const _updateNote = (note: any) => {
        if (noteId) updateNote(noteId, note);
    }

    const _deleteNote = () => {
        if (noteId) deleteNote(noteId);
    }

    const { saved: updated } = useSaved(updatedNote?.updated_at);
    const { saved: deleted } = useSaved(deletedNote);

    useEffect(() => {
        if (!load) return;
        _loadNote();
    }, [noteId, load]);

    return {
        note,
        error,
        loading,
        loadNote: _loadNote,
        updatedNote,
        updateError,
        updating,
        updated,
        updateNote: _updateNote,
        latestNote: updatedNote || note,
        deletedNote,
        deleteError,
        deleted,
        deleting,
        deleteNote: _deleteNote,
    };
}