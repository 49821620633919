import fieldOptionsApi from '../../../api/field_options';
import useApi from '../../../hooks/useApi';
export default function useFieldOptionCreate(fieldId: number | null) {
    const {
        data,
        error,
        loading: creating,
        request: createFieldOption,
    } = useApi(fieldOptionsApi.createFieldOption, null);

    const _createFieldOption = (fieldOption: any) => {
        createFieldOption(fieldId, fieldOption);
    }

    return {
        data,
        error,
        creating,
        createFieldOption: _createFieldOption,
    }
}