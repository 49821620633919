const users = [
  {
    id: 1,
    objectId: '123456',
    email: 'admin@sitemarker.com',
    password: 'testing123',
    name: 'Admin Name',
    phone: '(555)555-5555',
    company: 'SW',
    title: 'Project Administrator',
    walkthrough_seen: false,
  },
];

export default users;
