import { useEffect, useMemo, useRef, useState } from 'react';
import useApi from '../../../hooks/useApi';
import reportsApi from '../../../api/reports';
import ReportViewModel from '../../../models/ReportViewModel';
import useActionCable, { REPORTS_CHANNEL } from '../../../hooks/useActionCable';
import usePolling from '../../../hooks/usePolling';
import reportPagesApi from '../../../api/report_pages';
import useReportContext from '../useReportContext';
import useSaved from '../../../hooks/useSaved';

export default function useReport(reportId: string, {
    load = true,
    listenForUpdates = false,
    useViewModel = true,
    share_id,
    reportPageParams = {}
}: {
    load?: boolean
    listenForUpdates?: boolean
    useViewModel?: boolean
    share_id?: string
    reportPageParams?: any
} = {}) {

    const {
        data: report,
        error,
        loading,
        request: loadReport,
    } = useApi(reportsApi.getReport, null, load);

    const {
        data: updatedReport,
        error: updateError,
        loading: updating,
        request: updateReport,
    } = useApi(reportsApi.updateReport, null);

    const {
        data: { records: reportPages, pagy },
        error: reportPagesError,
        loading: loadingPages,
        request: getReportPages,
    } = useApi(reportPagesApi.getReportPages, { records: [], pagy: {} });

    const {
        newPagesAdded,
        setNewPagesAdded
    } = useReportContext();

    const [reportPageFirstLoad, setReportPageFirstLoad] = useState(true);
    const updateTimer = useRef<NodeJS.Timeout | null>(null);

    const _loadReport = () => {
        loadReport(reportId, { share_id });
    }

    const _updateReport = (report: any) => {
        updateReport(reportId, report);
    }

    const _updateReportDebounced = (report: any) => {
        if (updateTimer.current) clearTimeout(updateTimer.current);

        updateTimer.current = setTimeout(() => {
            _updateReport(report);
        }, 1000);
    };

    useEffect(() => {
        if (!load) return;
        _loadReport();
    }, [load, share_id]);


    const latestReport = useMemo(() => {
        if (!report) return null;

        if (updatedReport && new Date(updatedReport.updated_at) > new Date(report.updated_at)) {
            return useViewModel ? new ReportViewModel(updatedReport) : updatedReport;
        }

        return useViewModel ? new ReportViewModel(report) : report;

    }, [report?.objectId, report?.updated_at, updatedReport?.updated_at, updatedReport?.objectId])

    const shouldPoll = useMemo(() => latestReport?.pdf_status === 'building_pages', [latestReport?.pdf_status])

    const _loadReportPages = () => {
        getReportPages(reportId, { ...reportPageParams, share_id });
    }

    useEffect(() => {
        if (shouldPoll || ['queue_creation', 'creating'].includes(latestReport?.pdf_status)) return;
        _loadReportPages();
    }, [latestReport?.updated_at, latestReport?.pdf_status, share_id, shouldPoll]);

    useEffect(() => {
        if (shouldPoll) return;
        _loadReportPages();
        if (newPagesAdded) setNewPagesAdded(false);
    }, [reportPageParams.page, reportPageParams.items, share_id, shouldPoll, newPagesAdded]);

    const { receivedMessages, rejectedOrDisconnected } = useActionCable(
        REPORTS_CHANNEL,
        {
            objectId: reportId,
        },
        !!report && listenForUpdates
    );

    useEffect(() => {
        if (!reportPages.length) return;
        setReportPageFirstLoad(false);
    }, [reportPages.length])

    useEffect(() => {
        _loadReport();
    }, [receivedMessages.length]);

    usePolling(_loadReport, {
        enabled: listenForUpdates && shouldPoll && rejectedOrDisconnected,
    })

    const { saved: updated } = useSaved(updatedReport?.updated_at);

    return {
        report: useViewModel && report ? new ReportViewModel(report) : report,
        latestReport,
        error,
        loading,
        loadReport: _loadReport,
        updatedReport: useViewModel && updatedReport ? new ReportViewModel(updatedReport) : updatedReport,
        updateError,
        updating,
        updated,
        updateReport: _updateReport,
        updateReportDebounced: _updateReportDebounced,
        reportPages,
        reportPagesError,
        loadingPages: loadingPages && reportPageFirstLoad,
        reloadingPages: loadingPages && !reportPageFirstLoad,
        pagy,
        loadReportPages: _loadReportPages,
    };
}