const Container = ({ children }: {
  children: React.ReactNode
}) => {
  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl self-start min-h-full">
      {children}
    </div>
  );
}

const PageHeader = ({ children }: {
  children: React.ReactNode
}) => (
  <div className="sm:flex sm:justify-between sm:items-center mb-4">
    {children}
  </div>
);
const PageTitle = ({ children }: {
  children: React.ReactNode
}) => (
  <div className="mb-4 sm:mb-0">
    <h1 className="text-2xl md:text-3xl text-gray-800 font-poppins font-semibold capitalize">
      {children}
    </h1>
  </div>
);

const Page = {
  Page: Container,
  Header: PageHeader,
  Title: PageTitle
}

export default Page;
