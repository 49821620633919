import { useEffect } from 'react';
export default function usePolling(callback: () => void, {
    interval = 3000,
    enabled = true
}: {
    interval?: number,
    enabled?: boolean
} = {}) {
    useEffect(() => {
        if (!enabled) return;
        const id = setInterval(callback, interval);
        return () => clearInterval(id);
    }, [callback, interval, enabled]);
}