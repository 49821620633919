import React from 'react';
export default function Button({ children, style = "save", ...rest }) {
    return (
        <button
            className={style == 'save' ? `modal-save-btn` : `modal-close-btn`}
            {...rest}
        >
            {children}
        </button>
    );
}