import React from 'react';
import WorkspaceMemberViewModel from '../../models/WorkspaceMemberViewModel';
export default function AddUserItem({ workspace_member, onAdd }) {
  const addMember = () => {
    onAdd?.(workspace_member.member);
  };

  return (
    <div
      className="flex items-center justify-between p-3 border-b border-gray-200 cursor-pointer hover:opacity-70"
      onClick={addMember}
    >
      <div className="flex items-center">
        <img
          className="rounded-full w-12 h-12 object-cover mr-4"
          src={
            workspace_member.member.profile_photos?.medium ||
            'https://bucketeer-d9937a0d-80e4-469a-91e2-6c86bd37d08f.s3.amazonaws.com/public/user_empty.png'
          }
        />
        <div className="text-left">
          <p className="truncate">
            {workspace_member.member.name || workspace_member.member.email}
          </p>
          {new WorkspaceMemberViewModel(
            workspace_member
          ).invitationPending?.() && (
            <p className="italic text-xs text-tertiary text-xs truncate">
              Invitation pending
            </p>
          )}
          <p className="font-bold text-xs truncate">
            {workspace_member.member.title}
          </p>
        </div>
      </div>
    </div>
  );
}
