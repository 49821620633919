export default class PermissionsViewModel {
  constructor(object) {
    Object.assign(this, object);
  }

  can = (permission) =>
    this.permissions?.can?.find?.((obj) => obj[permission] !== undefined)?.[
      permission
    ];
  cannot = (permission) =>
    this.permissions?.can?.find?.((obj) => obj[permission] !== undefined)?.[
      permission
    ];
}
