import { useEffect } from 'react';
import { Datum, Project } from '../../../data/models';
import useApi from '../../../hooks/useApi';
import datumsApi from '../../../api/datums';
type ProjectDatumConfg = {
    datums: Datum[],
    error: any,
    loading: boolean,
}
export default function useProjectDatums(project: Project | null, {
    enabled = true
}: {
    enabled?: boolean
} = {
    }): ProjectDatumConfg {
    const {
        data: { records: datums },
        error,
        loading,
        request: getDatums,
    } = useApi(datumsApi.getDatams, { records: [], pagy: {} }, true);

    useEffect(() => {
        if (!project || !enabled) return;
        getDatums({ project_id: project.objectId, items: 100, page: 1 });
    }, [enabled, project])

    return {
        datums,
        error,
        loading,
    }
}