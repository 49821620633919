import { useEffect } from 'react';
import { Map } from 'mapbox-gl';

type MapEventType = 'render' | 'load' | 'idle' | 'sourcedataloading' | 'styledataloading' | 'movestart' | 'sourcedata' | 'styledata' | 'data' | 'dataloading';

const useMapEventsForLoadingState = (
  {
    map,
    enable,
    mapContainer
  }: { map: Map | null, enable: boolean, mapContainer: HTMLDivElement | null }
) => {
  useEffect(() => {
    if (!map || !enable || !mapContainer) return;

    const loadedEvents: MapEventType[] = ['render', 'load', 'idle', 'data', 'sourcedata', 'styledata'];
    const startEvents: MapEventType[] = ['movestart', 'dataloading', 'sourcedataloading', 'styledataloading'];

    const onMapLoaded = () => {
      if (!map.areTilesLoaded()) return;
      mapContainer.classList.add('mapboxgl-loaded');
    };

    const onMapStart = () => {
      mapContainer.classList.remove('mapboxgl-loaded');
    };

    loadedEvents.forEach(event => map.on(event, onMapLoaded));
    startEvents.forEach(event => map.on(event, onMapStart));

    if (map.areTilesLoaded()) mapContainer.classList.add('mapboxgl-loaded');

    return () => {
      loadedEvents.forEach(event => map.off(event, onMapLoaded));
      startEvents.forEach(event => map.off(event, onMapStart));
    };
  }, [enable, map, mapContainer]);
};

export default useMapEventsForLoadingState;
