import { memo, useEffect, useRef, useState } from 'react';
import { FiX } from 'react-icons/fi';
import Loader from '../utility/Loader';
import useApi from '../../hooks/useApi';
import authApi from '../../api/auth';
import { Redirect } from 'react-router-dom';
import Modal, { ModalSuccessView } from '../utility/Modal';
import ErrorView from '../utility/ErrorView';

function DeleteUserButton() {
  const {
    data: deleted_user,
    error,
    loading: deleting,
    request: deleteSelf,
  } = useApi(authApi.remove, null);
  const [redirect, setRedirect] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const deletedTimer = useRef();
  const [modalOpen, setModalOpen] = useState(false);

  const closeModal = () => {
    setModalOpen(false);

    if (deletedTimer.current) {
      clearTimeout(deletedTimer.current);
      setRedirect(true);
    }
  };

  useEffect(() => {
    deleted_user && setDeleted(true);
  }, [deleted_user]);

  useEffect(() => {
    if (deleted)
      deletedTimer.current = setTimeout(() => setRedirect(true), 3000);
  }, [deleted]);

  if (redirect) return <Redirect to={`/`} />;

  return (
    <>
      <a
        className="disabled:opacity-70 text-xs items-center btn-sm border border-gray-200 cursor-pointer mr-2 px-4 py-2 hover:opacity-80 font-bold border rounded-md text-red-500 bg-white hover:shadow disabled:opacity-70"
        disabled={deleting}
        onClick={() => setModalOpen(!modalOpen)}
      >
        {deleting && <Loader margin="mr-1" color="text-red-500" />} Delete
        Account
      </a>
      <Modal isOpen={modalOpen} onClose={closeModal}>
        <div className="flex items-center justify-between px-5 py-3 bg-primary">
          <p className="text-lg font-bold text-white">
            Confirm Account Deletion
          </p>
          <button
            className="focus:outline-none"
            disabled={deleting}
            onClick={closeModal}
          >
            <FiX
              size={28}
              className="text-white hover:opacity-60 cursor-pointer"
            />
          </button>
        </div>
        {error && <ErrorView error={error} />}
        <div className="px-2 pt-2">
          <div className="p-5">
            {!deleted && (
              <>
                <div className="mb-4">
                  <div className="font-semibold text-gray-900">
                    Are you sure you want to delete your account?
                  </div>
                </div>
                <p className="text-gray-800 font-medium">
                  All data owned and associated with your account will be{' '}
                  <span className="font-bold">removed.</span>
                </p>
                <p className="italic text-red-600">
                  Including all workspaces pins and reports created.
                </p>
              </>
            )}
            {deleted && (
              <ModalSuccessView text={'Account successfully deleted'} />
            )}
          </div>

          {!deleted && (
            <div className="modal-footer">
              <button
                className="modal-remove-btn"
                type="button"
                disabled={deleting}
                onClick={deleteSelf}
              >
                Delete Account {deleting && <Loader />}
              </button>
              <button
                className="modal-close-btn"
                type="button"
                disabled={deleting}
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}

export default memo(DeleteUserButton);
