import { BiCheckCircle } from 'react-icons/bi';
import Loader from '../utility/Loader';
import Tooltip from './Tooltip';

export default function Button({
  color = 'tertiary',
  link = false,
  className = '',
  disabled = false,
  cursor = 'cursor-pointer',
  hover = 'hover:opacity-80',
  rounded = 'rounded-md',
  text = 'Save',
  spacing = '',
  savedText,
  icon,
  iconAligned = 'right',
  size,
  onClick,
  onMouseOver,
  onMouseOut,
  saving,
  saved,
  tooltip,
  hasSavedState = true,
  ...rest
}: {
  color?: string,
  link?: boolean,
  className?: string,
  disabled?: boolean,
  cursor?: string,
  hover?: string,
  rounded?: string,
  text?: string | React.ReactNode,
  spacing?: string,
  savedText?: string,
  icon?: React.ReactNode,
  iconAligned?: string,
  size?: string,
  onClick?: (e?: any) => void,
  onMouseOver?: (e?: any) => void,
  onMouseOut?: (e?: any) => void,
  saving?: boolean,
  saved?: boolean,
  tooltip?: string,
  hasSavedState?: boolean
}) {
  const bgColor = () => {
    if (hasSavedState && saved) return 'bg-green-500';
    if (color === 'danger') return 'bg-red-600';
    if (color === 'dark') return 'bg-gray-900';
    if (color === 'gray') return 'bg-gray-100';
    if (color === 'link') return 'bg-transparent hover:underline';
    if (color === 'tertiary') return 'bg-tertiary';
    if (color === 'secondary') return 'bg-secondary';
    if (color === 'success') return 'bg-green-500';
    if (color === 'disabled') return 'bg-gray-300';
    if (color === 'light')
      return 'bg-white border-sm-lightblue shadow-sm font-semibold';
    return 'bg-white';
  };

  const textColor = () => {
    if (hasSavedState && saved) {
      if (color === 'link') return 'text-green-500';
      return 'ml-1 text-white';
    }
    if (color === 'gray') return 'text-gray';
    if (['tertiary', 'secondary', 'success', 'disabled', 'dark', 'danger'].includes(color))
      return 'text-white';
    if (color === 'light' || color === 'link') return 'text-secondary';
    return 'bg-tertiary';
  };

  const currentText = () => {
    if (hasSavedState && saved && savedText) return savedText;
    return text;
  };

  const finalIcon = () =>
    tooltip ? <Tooltip icon={icon}>{tooltip}</Tooltip> : icon;

  const finalClassName = `btn${size ? '-' + size : ''
    } ${bgColor()} ${cursor} ${hover} ${textColor()} ${spacing || ''} ${className || ''
    } ${rounded} focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed`;

  const finalDisabled = saving || saved || disabled;
  const body = (
    <>
      {!!icon && iconAligned === 'left' && finalIcon()}
      {currentText()}
      {!!icon && iconAligned === 'right' && finalIcon()}
      {saved && hasSavedState && (
        <BiCheckCircle size={20} className={textColor()} />
      )}
      {saving && <Loader color={textColor()} />}
    </>
  );

  if (link)
    return (
      <a
        className={finalClassName}
        onClick={onClick}
        onMouseOut={onMouseOut}
        onMouseOver={onMouseOver}
        {...rest}
      >
        {body}
      </a>
    );

  return (
    <button
      className={finalClassName}
      disabled={finalDisabled}
      onClick={onClick}
      onMouseOut={onMouseOut}
      onMouseOver={onMouseOver}
      {...rest}
    >
      {body}
    </button>
  );
}
