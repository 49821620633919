import { useContext } from 'react';
import ReportContext from './context';

export default function useReportContext() {
  const {
    deletedPins,
    setDeletedPins,
    softDeletedPins,
    setSoftDeletedPins,
    editing,
    setEditing,
    pinUpdated,
    setPinUpdated,
    pages,
    setPages,
    pageBreakIndexes,
    setPageBreakIndexes,
    mapsConverted,
    setMapsConverted,
    entryGroupsLoaded,
    setEntryGroupsLoaded,
    hiddenPages,
    setHiddenPages,
    softHiddenPages,
    setSoftHiddenPages,
    newPagesAdded,
    setNewPagesAdded,
  } = useContext(ReportContext);

  return {
    deletedPins,
    setDeletedPins,
    softDeletedPins,
    setSoftDeletedPins,
    editing,
    setEditing,
    pinUpdated,
    setPinUpdated,
    hiddenPages,
    setHiddenPages,
    softHiddenPages,
    setSoftHiddenPages,
    pages,
    setPages,
    pageBreakIndexes,
    setPageBreakIndexes,
    mapsConverted,
    setMapsConverted,
    entryGroupsLoaded,
    setEntryGroupsLoaded,
    newPagesAdded,
    setNewPagesAdded,
  };
}
