import { memo } from 'react';
import EntryPinCategory from '../entries/EntryPinCategory';
import EntryPinSummary from '../entries/EntryPinSummary';
import EntryPinTags from '../entries/EntryPinTags';

const entryPinSummaryComponents = {
  EntryPinCategory,
  EntryPinSummary,
  EntryPinTags,
};

function EntryGroupPinSummary({ report, entries, editing }) {
  return (
    <div className="px-4 py-2">
      {entries.map((entry) => {
        const EntryPinComponent =
          entryPinSummaryComponents[entry.component_name];
        return (
          <EntryPinComponent
            key={`entry_pin_summary_component_${entry.objectId}`}
            report={report}
            editing={editing}
            entry={entry}
          />
        );
      })}
      {entries.length === 0 && <p className="text-gray-700 text-xs">N/A</p>}
    </div>
  );
}

export default memo(EntryGroupPinSummary);
