import { useEffect, useLayoutEffect, useState } from 'react';
import useApi from '../../hooks/useApi';
import projectMembersApi from '../../api/project_members';
import Modal, { ModalHeader } from '../utility/Modal';
import Loader from '../utility/Loader';
import RemoveMemberItem from '../project/RemoveMemberItem';
import DelayedSearchInput, { SearchContainer } from '../utility/DelayedSearchInput';
import useModal from '../../hooks/useModal';
import { useParams } from 'react-router-dom';
import ProjectMemberViewModel from '../../models/ProjectMemberViewModel';

export default function MembersModal({ open, afterUpdate }) {
  const { project_id } = useParams();
  const {
    open: modalOpen,
    setOpen: setModalOpen,
    toggle: toggleModal,
  } = useModal();
  const [page, setPage] = useState(1);
  const [q, setQ] = useState('');
  const [members, setMembers] = useState([]);
  const {
    data: { records, pagy },
    error,
    loading,
    request: loadProjectMembers,
  } = useApi(
    projectMembersApi.getProjectMembers,
    { records: [], pagy: {} },
    true
  );

  useEffect(() => {
    if (modalOpen === false) {
      setMembers([]);
      setPage(1);
    }
  }, [modalOpen]);

  useEffect(() => {
    modalOpen && loadProjectMembers(project_id, { items: 10, page, q });
  }, [modalOpen, page, q]);

  useEffect(() => {
    records.length &&
      setMembers([
        ...members.map((member) => new ProjectMemberViewModel(member)),
        ...records.map((member) => new ProjectMemberViewModel(member)),
      ]);
  }, [records]);

  useLayoutEffect(() => {
    setModalOpen(open);
  }, [open]);

  return (
    <div>
      {/* Button */}
      <button
        className="card-header-btn"
        type="button"
        onClick={() => setModalOpen(!modalOpen)}
      >
        View All
      </button>
      <Modal isOpen={modalOpen} onClose={toggleModal}>
        <ModalHeader
          title={pagy.count !== undefined ? 'Project Members (' + pagy.count + ')' : '...'}
          onClose={toggleModal}
        />
        <SearchContainer>
          <DelayedSearchInput
            placeholder="Search Team..."
            onSearched={(text) => {
              setQ(text);
              setPage(1);
              setMembers([]);
            }}
          />
        </SearchContainer>
        {(!loading || members.length > 0) && (
          <>
            {members.length === 0 && (
              <p className="empty-data-txt text-center">
                No workspace members have been added to this project
              </p>
            )}
            <div className="max-h-72 overflow-y-scroll">
              {members.map((project_member) => {
                return (
                  <RemoveMemberItem
                    key={`project_${project_id}_project_member_${project_member.objectId}`}
                    project_member={project_member}
                    afterUpdate={afterUpdate}
                  />
                );
              })}
              {!loading &&
                pagy.count !== undefined &&
                pagy.count !== members.length && (
                  <div
                    className="flex justify-center p-3 border-b border-gray-200"
                    onClick={() => setPage(page + 1)}
                  >
                    <a className="text-black hover:opacity-80 text-sm font-semibold bg-gray-100 px-4 py-2 shadow cursor-pointer">
                      Load More
                    </a>
                  </div>
                )}
            </div>
          </>
        )}

        {loading && (
          <div className="flex justify-center py-5">
            <Loader color="black" />
          </div>
        )}
        <div className="modal-footer">
          <button
            className="modal-close-btn"
            type="button"
            onClick={toggleModal}
          >
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
}
