import Loader from '../utility/Loader';

export const userIsCreator = (user, report) => user?.id === report.creator.id;
export const reportIsBuilding = (report) =>
  report.pdf_status === 'building_pages';
export const reportIsDraft = (report) => report.pdf_status === 'in_review';
export const reportIsPublishing = (report) =>
  ['queue_creation', 'creating'].indexOf(report.pdf_status) > -1;
export const reportIsPublished = (report) => 'created' === report.pdf_status;
export const reportPDFIsCreating = (report) => 'creating' === report.pdf_status;
export const reportErrored = (report) => 'error_creating' === report.pdf_status;

export default function ReportStatus({ report }) {
  return (
    <>
      {reportIsBuilding(report) && (
        <div className="flex justify-center text-sm font-semibold bg-gray-100 text-gray-800 w-28 text-center px-2 py-1 rounded-md">
          Building <Loader color="text-gray-800" />
        </div>
      )}
      {reportIsDraft(report) && (
        <div className="text-sm font-semibold bg-gray-100 text-gray-800 w-28 text-center px-2 py-1 rounded-md">
          Draft
        </div>
      )}
      {reportIsPublished(report) && (
        <div className="text-sm font-semibold bg-green-400 text-white w-28 text-center px-2 py-1 rounded-md">
          Published
        </div>
      )}
      {reportIsPublishing(report) && (
        <div className="flex justify-center text-sm font-semibold bg-gray-200 text-gray-800 w-28 text-center px-2 py-1 rounded-md">
          Publishing <Loader color="text-gray-800" />
        </div>
      )}
      {reportErrored(report) && (
        <div className="text-sm font-semibold bg-red-400 text-white w-36 text-center px-2 py-1 rounded-md">
          Error Publishing
        </div>
      )}
    </>
  );
}
