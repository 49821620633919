//ft 20925646.33
//mi 3958.8
//m 6378137

const earthsRadiusByMetric = {
  miles: 3963.190592,
  feet: 20925646.33,
  meter: 6378137,
};

const calculateFeetPerPixel = ({ zoom, lat }) => {
  let metersPerPixel =
    (156543.03392 * Math.cos((lat * Math.PI) / 180)) / Math.pow(2, zoom);
  return 3.28084 * metersPerPixel;
};

const getNorthSouthEastWestFromCoords = (coord1, coord2) => {
  const latitude1 = coord1.latitude || coord1.lat;
  const longitude1 = coord1.longitude || coord1.lng;
  const latitude2 = coord2.latitude || coord2.lat;
  const longitude2 = coord2.longitude || coord2.lng;

  let north, south, east, west;

  if (latitude1 > latitude2) {
    north = latitude1;
    south = latitude2;
  } else {
    north = latitude2;
    south = latitude1;
  }

  if (longitude1 > longitude2) {
    east = longitude1;
    west = longitude2;
  } else {
    east = longitude2;
    west = longitude1;
  }

  return { north, south, east, west };
};

const getScaledDimensions = ({
  width,
  height,
  dimensions: { width: pdfWidth },
  ftPerInch,
  zoom,
  lat,
}) => {
  let pixelsPerInchOnImage = width / pdfWidth;
  let imageWidthInFt = (width / pixelsPerInchOnImage) * ftPerInch;
  let imageHeightInFt = (height / pixelsPerInchOnImage) * ftPerInch;
  let feetPerPixel = calculateFeetPerPixel({ zoom, lat });
  let newImageWidthInPx = imageWidthInFt / feetPerPixel;
  let newImageHeightInPx = imageHeightInFt / feetPerPixel;
  return { width: newImageWidthInPx, height: newImageHeightInPx };
};

export { getNorthSouthEastWestFromCoords, getScaledDimensions };
