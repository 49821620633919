import { useEffect } from "react";
import pinTypesApi from "../../../api/pin_types";
import useApi from "../../../hooks/useApi";
import { useParams } from "react-router-dom";

type PinTypesResponse = {
    pinTypes: any[];
    loading: boolean;
    error: any;
}

export default function useProjectPinTypes(projectId?: string): PinTypesResponse {

    const { project_id } = useParams<{ project_id: string }>();

    const {
        data: pinTypes,
        request: getPinTypes,
        loading,
        error
    } = useApi(pinTypesApi.getProjectPinTypes, []);

    useEffect(() => {
        getPinTypes(projectId || project_id);
    }, [project_id, projectId]);

    return {
        loading,
        error,
        pinTypes
    }

}