import projectContactsApi from '../api/project_contacts';
import useApi from './useApi';

const useRemoveContact = () => {
  const { data: removedContact, error, loading, request } = useApi(projectContactsApi.removeContact, null);

  const removeContact = async (projectId: string, contactId: number) => {
    await request(projectId, contactId);
  };

  return { removedContact, removeLoading: loading, removeError: error, removeContact };
};

export default useRemoveContact;
