import { useEffect, useState } from 'react';

export default function useMapLock({ map, locked }) {

    useEffect(() => {
        if (!map) return;

        if (locked) {
            map.boxZoom.disable();
            map.scrollZoom.disable();
            map.dragPan.disable();
            map.dragRotate.disable();
            map.keyboard.disable();
            map.doubleClickZoom.disable();
            map.touchZoomRotate.disable();
        } else {
            map.boxZoom.enable();
            map.scrollZoom.enable();
            map.dragPan.enable();
            map.keyboard.enable();
            map.doubleClickZoom.enable();
            map.touchZoomRotate.enable();
            map.dragRotate.enable();
        }
    }, [map, locked]);
}