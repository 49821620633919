import React, { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import LineChart from '../../charts/LineChart01';
import EditMenu from '../EditMenu';
import useApi from '../../hooks/useApi';
import statsApi from '../../api/stats';

// Import utilities
import { tailwindConfig, hexToRGB } from '../../utils/Utils';
import Loader from '../utility/Loader';

function DashboardCard({ title, count, stats, hasOptions = false }) {
  const {
    data,
    error,
    loading,
    request: getAppStats,
  } = useApi(statsApi.app, {}, true);

  useEffect(() => {
    getAppStats(stats);
  }, []);

  const chartData = useCallback(() => {
    return {
      labels: Object.keys(data),
      datasets: [
        {
          data: Object.values(data),
          fill: true,
          backgroundColor: `rgba(${hexToRGB(
            tailwindConfig().theme.colors.blue[500]
          )}, 0.08)`,
          borderColor: tailwindConfig().theme.colors.indigo[500],
          borderWidth: 2,
          tension: 0,
          pointRadius: 0,
          pointHoverRadius: 3,
          pointBackgroundColor: tailwindConfig().theme.colors.indigo[500],
          clip: 20,
        },
      ],
    };
  }, [data]);

  const percentageChange = (data) => {
    if (!data) return null;
    let dataValues = Object.values(data);
    if (dataValues.length <= 1)
      return (
        <div className="text-sm font-semibold text-white px-1.5 bg-gray-500 rounded-full">
          -
        </div>
      );
    let percentage = parseInt(
      100 *
        (dataValues[dataValues.length - 1] / dataValues[dataValues.length - 2])
    );
    if (!percentage)
      return (
        <div className="text-sm font-semibold text-white px-1.5 bg-gray-500 rounded-full">
          -
        </div>
      );
    if (percentage > 0)
      return (
        <div className="text-sm font-semibold text-white px-1.5 bg-green-500 rounded-full">
          +{percentage}%
        </div>
      );
    return (
      <div className="text-sm font-semibold text-white px-1.5 bg-red-500 rounded-full">
        -{percentage}%
      </div>
    );
  };

  if (loading) return <Loader />;

  console.log({ data });

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-sm border border-gray-200">
      <div className="px-5 pt-5">
        {hasOptions && (
          <header className="flex justify-between items-start mb-2">
            {/* Icon */}
            {/* Menu button */}
            <EditMenu className="relative inline-flex">
              <li>
                <Link
                  className="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3"
                  to="#0"
                >
                  Option 1
                </Link>
              </li>
              <li>
                <Link
                  className="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3"
                  to="#0"
                >
                  Option 2
                </Link>
              </li>
              <li>
                <Link
                  className="font-medium text-sm text-red-500 hover:text-red-600 flex py-1 px-3"
                  to="#0"
                >
                  Remove
                </Link>
              </li>
            </EditMenu>
          </header>
        )}
        <h2 className="text-lg font-semibold text-gray-800 mb-2">{title}</h2>
        <div className="flex justify-between">
          <div>
            <div className="text-xs font-semibold text-gray-400 uppercase mb-1">
              Total
            </div>
            <div className="flex items-start">
              <div className="text-3xl font-bold text-gray-800 mr-2">
                {Object.values(data).reduce((a, b) => a + b, 0)}
              </div>
            </div>
          </div>
          <div>
            <div className="text-xs font-semibold text-gray-400 uppercase mb-1">
              Today
            </div>
            <div className="flex items-start">
              <div className="text-3xl font-bold text-gray-800 mr-2">
                {Object.values(data)[Object.values(data).length - 1]}
              </div>
              {percentageChange(data)}
            </div>
          </div>
        </div>
      </div>
      {/* Chart built with Chart.js 3 */}
      <div className="flex-grow">
        {/* Change the height attribute to adjust the chart height */}
        <LineChart data={chartData()} width={389} height={128} />
      </div>
    </div>
  );
}

export default DashboardCard;
