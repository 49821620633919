import { useState } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import Loader from '../utility/Loader';
import useFeatures from '../../hooks/useFeatures';

export default function ReportAsset({ url }) {
  const { features: { rollout: { reports_iframe } = {} } = {} } = useFeatures();

  const [loading, setLoading] = useState(true);

  if (reports_iframe)
    return (
      <>
        <iframe
          src={url}
          className={'h-screen w-full'}
          onLoad={() => setLoading(false)}
        />
        {loading && (
          <div className="absolute inset-0 justify-center items-center flex">
            <Loader margin={''} />
          </div>
        )}
      </>
    );

  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js">
      <div className="h-full overflow-hidden overflow-y-scroll">
        <Viewer fileUrl={url} plugins={[defaultLayoutPluginInstance]} />
      </div>
    </Worker>
  );
}
