import { useCallback } from 'react';
import Loader from '../utility/Loader';
export default function PinStatusRadioButtons({
  pinTypes = [],
  loading,
  chosen,
  onChosen,
}) {
  const uniqueStatuses = useCallback(() => {
    const uniqueValues = [];

    const unique = pinTypes
      .map(({ statuses }) => statuses)
      .flat()
      .filter((element) => {
        const isDuplicate = uniqueValues.includes(element.value);

        if (!isDuplicate) {
          uniqueValues.push(element.value);
          return true;
        }

        return false;
      });

    return unique;
  }, [pinTypes]);

  return (
    <div className="flex flex-wrap text-xs items-center">
      {loading && <Loader margin={'mx-2'} color="text-gray-800" />}
      {!loading && (
        <a
          onClick={() => onChosen && onChosen(null, chosen.length === 0)}
          key={`pin_status_any`}
          className={`cursor-pointer mr-2 px-2 py-1 border rounded-md ${
            chosen.length === 0
              ? 'shadow bg-blue-50 text-secondary border-secondary'
              : 'bg-white'
          } hover:shadow`}
        >
          Any
        </a>
      )}
      {uniqueStatuses().map((status) => {
        let chosenStatus = chosen.find((value) => status.value === value);
        return (
          <a
            onClick={() =>
              onChosen && onChosen(status, chosenStatus !== undefined)
            }
            key={`pin_status_${status.value}`}
            className={`cursor-pointer mr-2 px-2 py-1 border rounded-md ${
              chosenStatus
                ? 'shadow bg-blue-50 text-secondary border-secondary'
                : 'bg-white'
            } hover:shadow`}
          >
            {status.name}
          </a>
        );
      })}
    </div>
  );
}
