import { useEffect } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { HiOutlinePhotograph } from 'react-icons/hi';

//TODO: Add conditional icon and body based on accept file/types
const BodyAcceptText = ({ accept }: { accept: string }) => <>
    Click to upload photos
    <br />
    or drag and drop
</>

const DropzoneIcon = ({ accept, isDragActive }: { accept: string, isDragActive: boolean }) => <HiOutlinePhotograph
    size={60}
    className={`mb-1 mx-auto ${!isDragActive ? 'text-tertiary-light' : 'text-secondary'
        }`}
/>

const DropzoneBody = ({ accept, isDragActive }: { accept: string, isDragActive: boolean }) => <div
    className={`font-semibold ${!isDragActive ? 'text-tertiary' : 'text-secondary'
        }`}
>
    <BodyAcceptText accept={accept} />
</div>

export default function AssetDropzone({
    accept,
    bodyClass = 'p-5 flex flex-col active:ring-0 items-center justify-center relative',
    maxWidthClass = '',
    heightClass = 'h-72',
    testId = "dropzoneFile",
    dropzoneTestId = "dropzoneRoot",
    disabled,
    noClick = false,
    onDrop,
    onFilesRejected,
    children
}: {
    accept: string,
    noClick?: boolean,
    bodyClass?: string,
    maxWidthClass?: string,
    heightClass?: string,
    disabled?: boolean,
    onDrop: (files: File[]) => void,
    onFilesRejected?: (fileRejections: FileRejection[]) => void,
    testId?: string,
    dropzoneTestId?: string,
    children?: any
}) {

    const { fileRejections, getRootProps, getInputProps, isDragActive } = useDropzone({
        accept,
        onDrop,
        noClick,
    });

    useEffect(() => {
        if (!fileRejections.length) return;
        onFilesRejected?.(fileRejections);
    }, [fileRejections])

    return <div
        className="cursor-pointer ring-0 text-base"
        {...getRootProps()}
        data-testid={dropzoneTestId}
    >
        <div
            className={`${disabled ? 'opacity-50 cursor-not-allowed' : ''} ${!isDragActive
                ? 'border-2 border-gray-300'
                : 'border-3 border-secondary bg-blue-100'
                } ${bodyClass} ${heightClass} ${maxWidthClass} border-dashed ${noClick ? '' : 'hover:opacity-80'} rounded-md text-center`}
        >
            <input {...getInputProps()} data-testid={testId} />
            {children || <>
                <DropzoneIcon accept={accept} isDragActive={isDragActive} />
                <DropzoneBody accept={accept} isDragActive={isDragActive} /></>}
        </div>
    </div >
}