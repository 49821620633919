import { useEffect, useRef, useState } from 'react';
import { FiTrash2 } from 'react-icons/fi';
import Modal, { ModalSuccessView, ModalHeader } from '../utility/Modal';
import useApi from '../../hooks/useApi';
import constructionDrawingsApi from '../../api/construction_drawings';
import Loader from '../utility/Loader';

export default function BulkDeleteConstructionDrawingModal({
  open,
  constructionDrawingIds,
  afterDelete,
}) {
  const {
    data: deleted_construction_drawings,
    error,
    loading: deleting,
    request: bulkDeleteConstructionDrawings,
  } = useApi(constructionDrawingsApi.bulkDeleteConstructionDrawings, null);
  const [deleted, setDeleted] = useState(false);
  const deletedTimer = useRef();
  const [modalOpen, setModalOpen] = useState(open || false);

  const deleteConstructionDrawings = () =>
    bulkDeleteConstructionDrawings(constructionDrawingIds);

  const closeModal = () => {
    setModalOpen(false);

    if (deletedTimer.current) {
      clearTimeout(deletedTimer.current);
      afterDelete && afterDelete();
    }
  };

  useEffect(() => {
    deleted_construction_drawings && setDeleted(true);
  }, [deleted_construction_drawings]);

  useEffect(() => {
    if (deleted)
      deletedTimer.current = setTimeout(
        () => afterDelete && afterDelete(),
        3000
      );
  }, [deleted]);

  useEffect(() => {
    setModalOpen(open);
  }, [open]);

  return (
    <div className="text-gray-600 text-center font-normal">
      {/* Button */}
      <button
        href="#"
        className="cursor-pointer hover:bg-gray-800 rounded-full p-3"
        onClick={() => setModalOpen(!modalOpen)}
      >
        <FiTrash2 className="text-white" />
      </button>
      <Modal isOpen={modalOpen} onClose={closeModal}>
        <ModalHeader
          title={
            <>
              Delete <span className="mx-1 font-light">|</span>{' '}
              <span className="font-light">
                {constructionDrawingIds.length} Construction Drawings
              </span>{' '}
            </>
          }
          onClose={closeModal}
        />
        <div className="px-2 pt-2 text-base">
          <div className="p-5 text-left">
            {!deleted && (
              <>
                <div className="mb-4">
                  <div className="font-semibold">Are you sure?</div>
                </div>
                <div>
                  This will completely remove the construction drawings. It
                  cannot be undone.
                </div>
              </>
            )}
            {deleted && (
              <ModalSuccessView text={'Drawings successfully deleted'} />
            )}
          </div>

          {!deleted && (
            <div className="modal-footer">
              <button
                className="modal-remove-btn"
                type="button"
                onClick={deleteConstructionDrawings}
                disabled={deleting}
              >
                Delete Drawings {deleting && <Loader />}
              </button>
              <button
                className="modal-close-btn"
                type="button"
                onClick={closeModal}
                disabled={deleting}
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}
