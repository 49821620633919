import React, { memo } from 'react';
import { FiClock } from 'react-icons/fi';
import humanize from '../../utility/humanize';
import EditMenu from '../EditMenu';
import AddMemberModal from './AddMemberModal';
import EditPermissionsModal from './EditPermissionsModal';
import DeleteMemberModal from './DeleteMemberModal';
import useWorkspaceContext from '../../hooks/useWorkspaceContext';

function TeamTileList({ workspaceId, workspaceMembers, afterRemove }) {
  const {
    workspace: { permissions = {} },
  } = useWorkspaceContext();

  return (
    <div className="grid grid-cols-12 gap-6">
      {workspaceMembers.map((workspaceMember) => {
        let { id, role_id, objectId, accepted, member, role } = workspaceMember;
        let invitationPending =
          (member.invited_by && !member.invitation_accepted_at) || !accepted;
        return (
          <div
            key={`team_tile_list_${objectId}`}
            className={`col-span-full sm:col-span-6 xl:col-span-3 ${invitationPending
              ? 'border-blue-200 shadow'
              : 'border-gray-200 shadow-lg'
              } bg-white rounded-sm border `}
          >
            <div className="flex flex-col h-full">
              {/* Card top */}
              <div className="flex-grow p-5 relative">
                {/* Menu button */}
                {permissions?.can?.('manage_members') && (
                  <div className="">
                    <EditMenu
                      className="absolute top-0 right-0 inline-flex mt-4 mr-4"
                      action="click"
                    >
                      <li>
                        <AddMemberModal
                          key={`edit_member_modal_${objectId}`}
                          user={member}
                          afterCreate={afterRemove}
                          workspaceMember={{ id, role_id }}
                          button={
                            <button className="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3 w-full">
                              Edit User
                            </button>
                          }
                        />
                      </li>
                      <li>
                        <EditPermissionsModal
                          key={`edit_member_role_${objectId}`}
                          workspaceId={workspaceId}
                          workspaceMemberId={objectId}
                          member={member}
                          role={role}
                        />
                      </li>
                      <li className="hidden">
                        <a className="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3">
                          Add to Project
                        </a>
                      </li>
                      <li>
                        <DeleteMemberModal
                          key={`remove_member_${objectId}`}
                          workspaceId={workspaceId}
                          workspaceMemberId={objectId}
                          member={member}
                          afterRemove={afterRemove}
                        />
                      </li>
                    </EditMenu>
                  </div>
                )}
                {/* Image + name */}
                <header>
                  <div className="flex justify-center mb-2">
                    <img
                      className="rounded-full object-cover w-16 h-16 z-0"
                      src={
                        member.profile_photos?.medium ||
                        'https://bucketeer-d9937a0d-80e4-469a-91e2-6c86bd37d08f.s3.amazonaws.com/public/user_empty.png'
                      }
                    />
                  </div>
                  <h2
                    className="text-center text-gray-800 hover:text-gray-900 text-xl justify-center font-semibold truncate"
                    title={member.name || member.email}
                  >
                    {member.name || member.email}
                  </h2>

                  {invitationPending && (
                    <div
                      className="mt-5 ml-5 cursor-pointer group flex items-center justify-center hover:justify-between hover:px-1 transition-all duration-150 absolute left-0 top-0 flex h-5 w-5 hover:h-6 hover:shadow-lg hover:w-36 overflow-hidden bg-blue-100 text-secondary border border-secondary rounded-full"
                      title="Invitation Pending..."
                    >
                      <FiClock />{' '}
                      <div className="hidden group-hover:block opacity-0 group-hover:opacity-100 transition-opacity duration-100 text-xs mr-2 font-semibold">
                        Invitation pending
                      </div>
                    </div>
                  )}
                </header>
                {/* Bio */}
                <div className="text-center mt-2 text-sm">
                  <div className="font-semibold">{member.title}</div>
                  <div>{member.project_count || 0} projects</div>
                </div>
              </div>
              {/* Card footer */}
              <EditPermissionsModal
                button={
                  <div
                    className={`border-t border-gray-200 ${invitationPending
                      ? 'bg-blue-50 hover:bg-blue-100'
                      : 'bg-gray-50 hover:bg-gray-100'
                      }`}
                  >
                    <div className="block text-center text-sm font-semibold px-3 py-4">
                      <div
                        className={`flex items-center justify-center uppercase ${invitationPending ? 'text-secondary' : 'text-primary'
                          }`}
                      >
                        {humanize(role.name.replace('workspace', ''))}
                      </div>
                    </div>
                  </div>
                }
                workspaceId={workspaceId}
                workspaceMemberId={objectId}
                member={member}
                role={role}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default memo(TeamTileList);
