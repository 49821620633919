import client from './client';
const endpoint = '/map_layers';

const getProjectMapLayers = (projectId, params = {}) =>
  client.get(`/projects/${projectId}${endpoint}`, params);
const getPinMapLayers = (pinId, params = {}) =>
  client.get(`/pins/${pinId}${endpoint}`, params);
const getReportMapLayers = (reportId, params = {}) =>
  client.get(`/reports/${reportId}${endpoint}`, params);
const getMapLayer = (id, params = {}) =>
  client.get(`${endpoint}/${id}`, params);
const getMapLayers = (params = {}) => client.get(`${endpoint}`, params);
const addMapLayer = (projectId, map_layer) =>
  client.post(`/projects/${projectId}${endpoint}`, { map_layer });
const updateMapLayer = (mapLayerId, map_layer) =>
  client.put(`${endpoint}/${mapLayerId}`, { map_layer });
const archiveMapLayer = (mapLayerId) =>
  client.put(`${endpoint}/${mapLayerId}/archive`);
const unarchiveMapLayer = (mapLayerId) =>
  client.put(`${endpoint}/${mapLayerId}/unarchive`);
const deleteMapLayer = (mapLayerId) =>
  client.delete(`${endpoint}/${mapLayerId}`);

export default {
  getProjectMapLayers,
  getPinMapLayers,
  getReportMapLayers,
  getMapLayer,
  getMapLayers,
  addMapLayer,
  updateMapLayer,
  archiveMapLayer,
  unarchiveMapLayer,
  deleteMapLayer,
};
