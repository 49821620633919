import { useEffect, useMemo, useState } from "react";
const iconSize = 24;
function getRotationAngle(element: any) {
    const computedStyle = window.getComputedStyle(element);
    const matrix = computedStyle.transform;

    // Check for no rotation (identity matrix)
    if (matrix === 'none') return 0;

    // Extract rotation angle from the transform matrix
    const values = matrix.split('(')[1].split(')')[0].split(',');
    const a: number = parseFloat(values[0]);
    const b: number = parseFloat(values[1]);
    const radians = Math.atan2(b, a);

    return radians * (180 / Math.PI);
}

const CustomCursor = ({ rotation = 0, rotating = false }: {
    rotation?: number,
    rotating?: boolean
}) => {
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [additionalRotation, setAdditionalRotation] = useState(0);
    const [show, setShow] = useState(false);

    useEffect(() => {
        const moveCursor = (e: MouseEvent) => {
            const target = e.target as HTMLElement;
            const onRotateHandle = target.classList?.contains?.('rotate-handle');
            const additionalRotation = onRotateHandle && !!target.getAttribute('data-additionalRotation') ? parseInt(target.getAttribute('data-additionalRotation') as string) : null;
            setShow(onRotateHandle);
            if (additionalRotation !== null) setAdditionalRotation(additionalRotation);
            setPosition({ x: e.clientX - iconSize / 2, y: e.clientY - iconSize / 2 });
        };

        window.addEventListener('mousemove', moveCursor);

        return () => {
            window.removeEventListener('mousemove', moveCursor);
        };
    }, []);

    if (!show && !rotating) return null;

    return (
        <div
            className="cursor-rotate"
            style={{
                left: `${position.x}px`,
                top: `${position.y}px`,
                transform: `rotate(${rotation + additionalRotation}deg)`,
            }}
        ></div>
    );
};

export default function useRotationControls({
    enabled = true,
    defaultRotation = 0,
    target,
    map,
}: {
    enabled?: boolean,
    target: any,
    map: any,
    defaultRotation?: number,
}) {

    let isDragging = false;

    const [rotation, setRotation] = useState(defaultRotation);
    const [rotating, setRotating] = useState(false);

    const cursor = useMemo(() => <CustomCursor rotation={rotation} rotating={rotating} />, [rotating, rotation]);
    const handleComponents = useMemo(() => {
        return [
            <div data-testId="mapLayerRotateHandleNE" className="w-4 h-4 absolute z-10 -left-4 -top-4 rotate-handle rotate-ne cursor-none" data-additionalRotation="0" />,
            <div data-testId="mapLayerRotateHandleNW" className="w-4 h-4 absolute z-10 -right-4 -top-4 rotate-handle rotate-nw cursor-none" data-additionalRotation="90" />,
            <div data-testId="mapLayerRotateHandleSW" className="w-4 h-4 absolute z-10 -right-4 -bottom-4 rotate-handle rotate-sw cursor-none" data-additionalRotation="180" />,
            <div data-testId="mapLayerRotateHandleSE" className="w-4 h-4 absolute z-10 -left-4 -bottom-4 rotate-handle rotate-se cursor-none" data-additionalRotation="270" />,
        ]
    }, [target])

    useEffect(() => {
        if (isDragging) return;
        setRotation(defaultRotation);
    }, [defaultRotation, isDragging])

    useEffect(() => {
        if (!enabled || !target || !map) return;

        let initialAngle = 0, currentRotation = 0;

        const documentMouseUp = () => {
            setRotating(false);
            isDragging = false;
        }

        const documentMouseMouse = (e: MouseEvent) => {
            const eventTarget = e.target as HTMLElement;

            if (eventTarget.classList?.contains?.('rotate-handle')) {
                target.classList.remove('cursor-move')
                map.getCanvas().style.cursor = 'none';
                target.classList.add('cursor-none');
            } else if (!isDragging) {
                target.classList.remove('cursor-none')
                target.classList.add('cursor-move');
                map.getCanvas().style.cursor = 'grab';
            }

            if (isDragging) {
                const rect = target.getBoundingClientRect();
                const centerX = rect.left + rect.width / 2;
                const centerY = rect.top + rect.height / 2;
                const deltaX = e.clientX - centerX;
                const deltaY = e.clientY - centerY;

                const currentAngle = Math.atan2(deltaY, deltaX) * (180 / Math.PI);
                setRotation(currentRotation + currentAngle - initialAngle);
            }
        }

        const targetMouseDown = (e: MouseEvent) => {
            const eventTarget = e.target as HTMLElement;
            if (!target || !map || !eventTarget.classList.contains?.('rotate-handle')) return;

            const rect = target.getBoundingClientRect();
            const centerX = rect.left + rect.width / 2;
            const centerY = rect.top + rect.height / 2;
            const deltaX = e.clientX - centerX;
            const deltaY = e.clientY - centerY;

            initialAngle = Math.atan2(deltaY, deltaX) * (180 / Math.PI);
            currentRotation = getRotationAngle(target);
            isDragging = true;
            setRotating(true);
        }

        target.addEventListener('mousedown', targetMouseDown);
        document.addEventListener('mouseup', documentMouseUp);
        document.addEventListener('mousemove', documentMouseMouse);

        return () => {
            target.removeEventListener('mousedown', targetMouseDown);
            document.removeEventListener('mouseup', documentMouseUp);
            document.removeEventListener('mousemove', documentMouseMouse);
        }
    }, [enabled, target, map])

    return {
        rotation,
        rotating,
        handleComponents,
        cursor
    };
}