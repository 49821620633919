import pinsApi from "../../../api/pins"
import useApi from "../../../hooks/useApi"

export default function usePinCreationServices() {
    const { data: createdPins, request: bulkCreatePins, loading: bulkCreating, error: errorBulkCreating } = useApi(pinsApi.bulkAddPin, null);
    const { data: createdPin, request: createPin, loading: creating, error: errorCreating } = useApi(pinsApi.addPin, null);
    const { data: updatedPin, request: updatePin, loading: updating, error: errorUpdating } = useApi(pinsApi.updatePin, null);

    return {
        bulkCreatePins,
        bulkCreating,
        errorBulkCreating,
        createdPins,
        createPin,
        creating,
        errorCreating,
        createdPin,
        updatePin,
        updating,
        errorUpdating,
        updatedPin
    }
}