import { useState } from 'react';
import useApi from '../../hooks/useApi';
import reportsApi from '../../api/reports';
import EntryWeatherRainExisted from '../entries/EntryWeatherRainExisted';
import EntryWeatherInspectionDate from '../entries/EntryWeatherInspectionDate';
import EntryWeather from '../entries/EntryWeather';
import moment from 'moment-timezone';
export default function ReportWeather({ report, entries }) {
  const {
    data: weather,
    error,
    loading,
    request: getWeather,
  } = useApi(reportsApi.getReportWeather, null, true);
  const [formattedResults, setFormattedResults] = useState([]);

  const formatResults = () => {
    //Within one week - show all days
    //Within one month - break out to weekly
    let onlyDaysWithRain = weather.results.filter(({ value }) => value > 0);
    let results = onlyDaysWithRain.map(({ date, value }) => {
      return { range: moment(date).format('M/D'), amount: value };
    });

    while (results.length > 8) {
      let lastResult = results[results.length - 1];
      let nextToLastResult = results[results.length - 2];
      let firstDate = nextToLastResult.range.split(' - ')[0];
      let lastDate =
        lastResult.range.indexOf('-') > -1
          ? lastResult.range.split(' - ')[1]
          : lastResult.range;
      results[results.length - 2] = {
        range: `${firstDate} - ${lastDate}`,
        amount: nextToLastResult.amount + lastResult.amount,
      };
      results.pop();
    }

    setFormattedResults(results);
  };

  return (
    <div className="relative">
      <div className="border text-xxs">
        <EntryWeatherRainExisted
          entry={entries.find(
            ({ component_name }) => component_name === 'EntryWeatherRainExisted'
          )}
        />

        <div className="grid grid-cols-4 border-b text-center font-bold">
          <div className="p-2 border-r">Rain Date(s)</div>
          <div className="p-2 border-r">Amount Precipitation (in)</div>
          <div className="p-2 border-r">Rain Date(s)</div>
          <div className="p-2">Amount Precipitation (in)</div>
        </div>

        <div className="grid grid-cols-2 text-center">
          <div className="grid grid-cols-2">
            {entries
              .filter(({ component_name }) => component_name === 'EntryWeather')
              .slice(0, 4)
              .map((e) => (
                <EntryWeather entry={e} />
              ))}
          </div>

          <div className="grid grid-cols-2">
            {entries
              .filter(({ component_name }) => component_name === 'EntryWeather')
              .slice(4)
              .map((e) => (
                <EntryWeather entry={e} />
              ))}
          </div>
        </div>

        <EntryWeatherInspectionDate
          report={report}
          entry={entries.find(
            ({ component_name }) =>
              component_name === 'EntryWeatherInspectionDate'
          )}
        />
      </div>
    </div>
  );
}
