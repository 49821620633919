import { memo, useEffect, useState } from 'react';
import { FiLayers, FiChevronDown } from 'react-icons/fi';
import LayerButton from '../LayerButton';
import { Link, useParams } from 'react-router-dom';
import useQuery from '../../../hooks/useQuery';
import Loader from '../../utility/Loader';
import { defaultOpacity } from '../../../utility/mapLayerConfig';
import { FaCheckCircle, FaRegCircle } from 'react-icons/fa';

function MapBoxMapLayersButton({
  chosenLayers: passedLayers,
  maxHeight = 'max-h-80',
  layers,
  loading,
  canAddLayers = true,
  onLayersPicked,
  onLayersLoaded,
  isOpen = false,
  onOpen,
  map,
  containerClass = 'top-0 left-0 absolute z-10 m-4 rounded-md overflow-hidden print:hidden shadow-sm',
  buttonText = 'Map Layers',
  canEdit = true,
}) {
  const { project_id, workspace_id } = useParams();
  const query = useQuery();
  const [choosingLayers, setChoosingLayers] = useState(isOpen);
  const [chosenLayers, setChosenLayers] = useState(passedLayers);

  const setChosenLayerFromGetParam = () => {
    let layerFromParam = layers.find(
      ({ objectId }) => objectId === query.get('map_layer_id')
    );
    if (!layerFromParam) return;
    let layerSelected = chosenLayers.find(({ objectId }) => layerFromParam.objectId == objectId)
    if (layerSelected) return;

    layerFromParam.opacity = defaultOpacity;
    let newLayers = [...chosenLayers, { ...layerFromParam }];

    onLayersPicked?.(newLayers);
  };

  useEffect(() => {
    if (!onLayersLoaded) return;
    onLayersLoaded(layers);
  }, [layers]);

  useEffect(() => {
    setChosenLayers(passedLayers);
  }, [passedLayers]);

  useEffect(() => {
    if (isOpen === choosingLayers) return;
    setChoosingLayers(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (!onOpen) return;
    onOpen(choosingLayers);
  }, [choosingLayers]);

  useEffect(() => {
    if (!query.get('map_layer_id') || layers.length === 0) return;
    setChosenLayerFromGetParam();
  }, [query.get('map_layer_id'), layers.map(({ objectId }) => objectId).join(',')]);

  const allLayersChosen = chosenLayers.length === layers.length;

  return (
    <div className={containerClass}>
      <SelectMapLayersButton
        onClick={() => setChoosingLayers(!choosingLayers)}
        selecting={choosingLayers}
        selected={chosenLayers.length > 0}
      >
        {buttonText}
      </SelectMapLayersButton>
      {choosingLayers && (
        <SelectAllMapLayersButton
          onClick={() => {
            allLayersChosen ? onLayersPicked?.([]) : onLayersPicked?.(layers);
          }}
          selected={allLayersChosen}
        >
          All Layers
        </SelectAllMapLayersButton>
      )}
      <div className={`shadow-lg overflow-hidden overflow-y-auto ${maxHeight}`}>
        {choosingLayers && loading && (
          <div className="bg-white shadow-lg overflow-hidden flex justify-center">
            <div className="w-9 h-9 flex items-center justify-center">
              <Loader color={'text-gray-700'} margin={''} />
            </div>
          </div>
        )}
        {layers.map((layer) => {
          let layerChosen = chosenLayers.find(
            ({ objectId }) => objectId === layer.objectId
          );
          return (
            <LayerButton
              key={`layer_button_${layer.objectId}_chosen_${layerChosen}`}
              loading={false}
              layer={layerChosen || layer}
              isChosen={!!layerChosen}
              hidden={!choosingLayers}
              canEdit={canEdit}
              onZoomToLayer={() => {
                const nePosition = [layer.bounding_box.east, layer.bounding_box.north]
                const swPosition = [layer.bounding_box.west, layer.bounding_box.south]
                map.fitBounds([
                  swPosition,
                  nePosition,
                ],
                  {
                    padding: 100,
                  })
              }}
              onLayerPicked={(updatedLayer) => {
                let newLayers = layerChosen
                  ? [
                    ...chosenLayers.filter(
                      ({ objectId }) => objectId !== layer.objectId
                    ),
                  ]
                  : [...chosenLayers, { ...updatedLayer }];
                onLayersPicked?.(newLayers);
              }}
              onLayerUpdated={(updatedLayer) => {
                let newLayers = [
                  ...chosenLayers.filter(
                    ({ objectId }) => objectId !== layer.objectId
                  ),
                  { ...updatedLayer },
                ];
                onLayersPicked?.(newLayers);
              }}
            />
          );
        })}
      </div>
      {canAddLayers && choosingLayers && (
        <Link
          data-testid="addMapLayerButton"
          className={`border-t-2 justify-between cursor-pointer bg-secondary hover:opacity-90 py-2 px-4 text-white font-semibold items-center flex rounded-md-b`}
          to={`/${workspace_id}/projects/${project_id}/map_layers/new`}
        >
          + Add Map Layer
        </Link>
      )}
    </div>
  );
}

const SelectAllMapLayersButton = ({ children, selected, onClick }) => (
  <div
    className="text-sm cursor-pointer justify-between bg-white hover:opacity-90 py-2 px-3 text-secondary font-semibold items-center flex"
    onClick={onClick}
    data-testid="selectAllMapLayersButton"
  >
    {children}{' '}
    {selected ? (
      <FaCheckCircle size={16} className="ml-2" />
    ) : (
      <FaRegCircle size={16} className="ml-2" />
    )}
  </div>
);

const SelectMapLayersButton = ({ children, onClick, selecting, selected }) => (
  <div
    className={`text-sm cursor-pointer justify-between ${selecting ? 'rounded-t-md' : 'rounded-md'
      } ${selected ? 'bg-secondary' : 'bg-white'
      } hover:opacity-90 py-2 px-3 text-${selected ? 'white' : 'secondary'
      } font-semibold items-center flex`}
    onClick={onClick}
    data-testid="mapLayersButton"
  >
    {children}{' '}
    {selecting ? (
      <FiChevronDown
        size={16}
        className={`ml-2 ${selected ? 'text-white' : 'text-secondary'}`}
      />
    ) : (
      <FiLayers
        size={16}
        className={`ml-2 ${selected ? 'text-white' : 'text-secondary'}`}
      />
    )}
  </div>
);

export default memo(MapBoxMapLayersButton);
