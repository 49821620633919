// This function causes the user's browser to download the data specified as a
// file with the given filename.
//
// NOTE: no extension is added to the filename. If the file should have an
// extension, you must include it yourself.
export const downloadBlobAsFile = (filename: string, contents: Blob) => {
  const url = window.URL.createObjectURL(contents);

  // We cannot just return the <a> element as JSX here, because for some reason
  // that I was unable to debug, the click event is not triggered on left-click.
  // (Right Click -> "Save As" worked.)
  //
  // So, we create the element ephemerally and just fire the click event
  // ourselves.
  const button = document.createElement('a');
  button.setAttribute('download', filename);
  button.setAttribute('href', url);
  button.click();

  URL.revokeObjectURL(url);
};
