const formatCurrency = (value: string, formatDecimals: boolean) => {
  if (!value) return '';

  const transformedValue = formatDecimals ? parseFloat(value).toFixed(2) : value;
  const parts = transformedValue.split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

export default formatCurrency;
