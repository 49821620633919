import client from './client';
const endpoint = '/report_pages';

const getReportPages = (reportId, params) =>
  client.get(`/reports/${reportId}${endpoint}`, params);
const getReportPage = (reportId, reportPageId) =>
  client.get(`/reports/${reportId}${endpoint}/${reportPageId}`);
const bulkUpdateReportPage = (reportId, report_page, params = {}) =>
  client.put(`/reports/${reportId}${endpoint}/bulk_update`, {
    report_page,
    ...params,
  });
const duplicateReportPage = (reportId, reportPageId) =>
  client.post(`/reports/${reportId}${endpoint}/${reportPageId}/duplicate`);
const updateReportPage = (reportPageId, report_page) =>
  client.put(`${endpoint}/${reportPageId}`, { report_page });
const deleteReportPage = (reportPageId) =>
  client.delete(`${endpoint}/${reportPageId}`);
const restoreReportPage = (reportPageId) =>
  client.put(`${endpoint}/${reportPageId}/restore`);

export default {
  getReportPages,
  getReportPage,
  updateReportPage,
  bulkUpdateReportPage,
  duplicateReportPage,
  deleteReportPage,
  restoreReportPage,
};
