import { useEffect } from 'react';
import useApi from '../../hooks/useApi';
import Loader from '../utility/Loader';
import Modal, { ModalHeader } from '../utility/Modal';
import featureFlagsApi from '../../api/feature_flags';
import usersApi from '../../api/users';
import { BiCheckCircle } from 'react-icons/bi';

const FeatureFlagCheckbox = ({ featureFlag, onClick, checked }) => (
  <div className="flex items-center mb-4">
    <div className="flex-shrink-0">
      <div
        className="form-switch focus-within:shadow-outline"
        onClick={onClick}
      >
        <input
          type="checkbox"
          id="allowMatchingSignups"
          className="sr-only"
          checked={checked}
        />
        <label className="bg-gray-300" htmlFor="allowMatchingSignups">
          <span className="bg-white shadow-sm" aria-hidden="true"></span>
          <span className="sr-only"></span>
        </label>
      </div>
    </div>
    <div className="ml-3">
      <p className="text-gray-800 text-sm mb-0">{featureFlag.name}</p>
      <p className="text-gray-600 italic text-sm">
        {featureFlag.data.description}
      </p>
    </div>
  </div>
);

export default function EditFeatureFlagsModal({
  open,
  onClose,
  featureFlags,
  userObjectId,
  onSaved,
}) {
  const {
    data,
    error,
    loading,
    request: getFeatureFlags,
  } = useApi(featureFlagsApi.getFeatureFlags, []);

  const {
    data: newUser,
    error: errorUpdating,
    loading: updating,
    request: updateFeatureFlag,
  } = useApi(usersApi.updateFeatureFlag, null);

  useEffect(() => {
    if (!newUser) return;
    onSaved?.(newUser);
  }, [newUser]);

  useEffect(() => {
    if (!open) return;
    getFeatureFlags();
  }, [open]);

  return (
    <Modal
      isOpen={open}
      dialogScrolls={true}
      modalScrolls={false}
      maxHeight={'max-h-auto'}
      dialogClass={'py-10'}
      aligned={'items-start'}
      onClose={onClose}
    >
      <ModalHeader title={'Update Feature Flags'} onClose={onClose} />
      <div className="px-2 pt-2">
        <div className="p-5">
          {error && (
            <div className="mb-4">
              <div className="bg-red-100 text-red-600 px-3 py-2 rounded">
                <span className="text-sm">{error.text()}</span>
              </div>
            </div>
          )}
          {errorUpdating && (
            <div className="mb-4">
              <div className="bg-red-100 text-red-600 px-3 py-2 rounded">
                <span className="text-sm">{errorUpdating.text()}</span>
              </div>
            </div>
          )}

          <div className="font-semibold px-2 flex items-center">
            Feature Flags{' '}
            {(loading || updating) && <Loader color="text-gray-800 ml-1" />}{' '}
            {newUser && !(updating || loading) && (
              <BiCheckCircle
                size={18}
                className="bg-green-500 text-white rounded-full ml-1"
              />
            )}
          </div>
          <div className="bg-white border-b border-gray-200 overflow-hidden mb-8 p-3">
            {data
              .filter(({ percentage }) => percentage !== 100)
              .map((featureFlag) => {
                const flagOn = featureFlags.includes(featureFlag.key);
                console.log({ flagOn });
                return (
                  <FeatureFlagCheckbox
                    featureFlag={featureFlag}
                    checked={flagOn}
                    onClick={() =>
                      updateFeatureFlag(userObjectId, {
                        flag: featureFlag.key,
                        on: flagOn ? false : true,
                      })
                    }
                  />
                );
              })}
          </div>
        </div>

        <div className="modal-footer">
          <button
            className="modal-close-btn"
            type="button"
            disabled={loading || updating}
            onClick={onClose}
          >
            Done
          </button>
        </div>
      </div>
    </Modal>
  );
}
