import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import Transition from '../../utils/Transition';
import useAuth from '../../auth/useAuth';
import useApi from '../../hooks/useApi';
import apiAuth from '../../api/auth';
import Loader from '../utility/Loader';
import colors from '../../styles/colors';
import { FaExclamation } from 'react-icons/fa';

function UserMenu() {
  const { pathname } = useLocation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const {
    data: user,
    error,
    loading,
    request: getMe,
  } = useApi(apiAuth.me, null);
  const trigger = useRef(null);
  const dropdown = useRef(null);
  const { logOut } = useAuth();

  useEffect(() => {
    getMe();
  }, []);

  // // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (
        !dropdownOpen ||
        !dropdown.current ||
        dropdown.current.contains(target) ||
        !trigger.current ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  if (loading || !user)
    return <Loader color={colors.darkGray} margin={'mx-4'} />;

  return (
    <div className="relative inline-flex">
      <button
        ref={trigger}
        className="inline-flex justify-center items-center focus:outline-none group"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <img
          className="w-8 h-8 rounded-full object-cover"
          src={
            user.profile_photos?.medium ||
            'https://bucketeer-d9937a0d-80e4-469a-91e2-6c86bd37d08f.s3.amazonaws.com/public/user_empty.png'
          }
          alt="User"
        />
        <div className="flex items-center truncate">
          <span className="truncate ml-2 text-sm font-medium group-hover:text-gray-800">
            {user.name || user.email}
          </span>
          <svg
            className="w-3 h-3 flex-shrink-0 ml-1 fill-current text-gray-400"
            viewBox="0 0 12 12"
          >
            <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
          </svg>
        </div>
      </button>

      <Transition
        className="origin-top-right absolute top-full right-0 min-w-44 bg-white border border-gray-200 py-1.5 rounded shadow-lg overflow-hidden mt-1"
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div ref={dropdown}>
          <div className="pt-0.5 pb-2 px-3 mb-1 border-b border-gray-200">
            <div className="font-medium text-gray-800 hidden">
              SeamonWhiteside
            </div>
            <div className="text-xs text-gray-500 italic">{user.title}</div>
          </div>
          <ul>
            <li>
              <Link
                className="focus:outline-none font-medium text-sm text-secondary flex items-center py-1 px-3"
                to={`/${pathname.split('/')[1]}/settings`}
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                Settings
              </Link>
            </li>
            <li>
              <Link
                className="focus:outline-none font-medium text-sm text-secondary flex items-center py-1 px-3"
                to="/signin"
                onClick={() => {
                  logOut();
                  setDropdownOpen(!dropdownOpen);
                }}
              >
                Sign Out
              </Link>
            </li>
          </ul>
        </div>
      </Transition>
    </div>
  );
}

export default UserMenu;
