import jwtDecode from 'jwt-decode';
import { useEffect, useState } from 'react';

const asyncLocalStorage = {
  setItem: function (key, value) {
    return Promise.resolve()
      .then(function () {
        localStorage.setItem(key, value);
      })
      .then(() => localStorage.getItem(key));
  },
  getItem: function (key) {
    return Promise.resolve().then(function () {
      return localStorage.getItem(key);
    });
  },
  removeItem: function (key) {
    return Promise.resolve().then(function () {
      return localStorage.removeItem(key);
    });
  },
};

const storeToken = async (key, authToken) => {
  try {
    return await asyncLocalStorage.setItem(key, authToken);
  } catch (error) {
    console.log(`Error storing ${key} - ${error}`);
  }
};

const getToken = async (key) => {
  return await asyncLocalStorage.getItem(key);
};

const getUser = async () => {
  const token = await getToken('authToken');
  return token ? jwtDecode(token) : null;
};

const getWalkThroughSeen = async () => {
  const seen = await getToken('walkthroughSeen');
  return seen;
};

const removeToken = async (key) => {
  try {
    asyncLocalStorage.removeItem(key);
  } catch (error) {
    console.log('Error removing auth token', error);
  }
};

export const useStorage = () => {
  const [token, setToken] = useState(null);

  useEffect(() => {
    const loadToken = async () => {
      setToken(await getToken('authToken'));
    };

    loadToken();
  }, []);

  return { token };
};

export default {
  getUser,
  getToken,
  getWalkThroughSeen,
  storeToken,
  removeToken,
};
