import { useState } from 'react';
import Tabs, { Tab } from '../../utils/Tabs';
import Page from '../shared/Page';
import useShares from './hooks/useShares';
import Table from '../../utils/Table';
import { H2 } from '../../utils/Typography';
import { FiExternalLink } from 'react-icons/fi';
import moment from 'moment';
import { useCopyToClipboard } from 'react-use';
import { BiClipboard } from 'react-icons/bi';
import Loader, { LoaderContainer } from '../utility/Loader';
export default function SharedWithMePage() {

    const [activeTab, setActiveTab] = useState(0);

    return <Page.Page>
        <Page.Header>
            <Page.Title>Shared with me</Page.Title>
        </Page.Header>
        {/* <Tabs.Tabs>
            <Tabs.Tab active={activeTab === 0} onClick={() => setActiveTab(0)}>Reports</Tabs.Tab>
            <Tabs.Tab active={activeTab === 1} onClick={() => setActiveTab(1)}>Projects</Tabs.Tab>
            <Tabs.Tab active={activeTab === 2} onClick={() => setActiveTab(2)}>Pins</Tabs.Tab>
            <Tabs.Tab active={activeTab === 3} onClick={() => setActiveTab(3)}>Documents</Tabs.Tab>
        </Tabs.Tabs> */}
        {activeTab === 0 && <ReportsSharedWithMe />}
    </Page.Page>
}

const ReportsSharedWithMe = () => {
    const { shares, loading } = useShares({
        shared_with_me: true,
        where: {
            shareable_type: ['Report'],
        }
    });

    return <Table.Table>
        <Table.Header>
            <H2>Shares</H2>
        </Table.Header>
        <Table.Body>
            <Table.Thead>
                <Table.Th>Shared</Table.Th>
                <Table.Th>Link</Table.Th>
                <Table.Th>Created</Table.Th>
            </Table.Thead>
            {!loading && !shares.length && <tr>
                <Table.Td colSpan={3} className="text-center px-2 py-3 italic">No shares available</Table.Td>
            </tr>}
            {shares.map((share: any, index: number) => {
                return <Table.Tr>
                    <Table.Td>
                        {share.shareable_type}
                    </Table.Td>
                    <Table.Td>
                        <ShareLink index={index} share={share} />
                    </Table.Td>
                    <Table.Td>{moment(share.created_at).fromNow()}</Table.Td>
                </Table.Tr>
            })}
        </Table.Body>
        {loading && <LoaderContainer>
            <Loader color="black" />
        </LoaderContainer>}
    </Table.Table>
}

const ShareLink = ({ share, index }: {
    index: number,
    share: any
}) => {


    const [state, copyToClipboard] = useCopyToClipboard();
    const localUrl = share.link.replace(window.location.origin, '');

    return <div className="flex items-center" data-testid={`reportShare${index}`}>
        <a className="text-tertiary underline text-sm flex items-center" href={localUrl}>{share.link} <FiExternalLink className="ml-1" /></a>
        <button
            onClick={() => copyToClipboard(`${share.link}?utm_source=copied_link`)}
            className="ml-4 flex items-center text-xs rounded-md border border-gray-200 text-tertiary py-0.5 font-semibold px-3 shadow-sm hover:opacity-80 cursor-pointer focus:outline-none mr-2"
        >
            <BiClipboard className="mr-1" />{' '}
            {state.value ? 'Copied!' : 'Copy Link'}
        </button>
    </div>
}