import React from 'react';
import { FiAlignLeft, FiBell, FiBook, FiBox, FiGrid, FiMapPin, FiSettings, FiUsers } from 'react-icons/fi';
import { Link } from 'react-router-dom';
const selectedClass = 'text-secondary';
const selectedBgClass = 'bg-blue-50';
type SelectedItemType = 'general' | 'members' | 'pin_types' | 'integrations' | 'billing' | 'plans' | 'security' | 'notifications';
const Item = ({ active, iconClass, children, to }: {
  active: boolean,
  iconClass: any,
  children: React.ReactNode,
  to: string
}) => {
  const Icon = iconClass

  return <li className="mr-0.5 md:mr-0 md:mb-0.5">
    <Link
      className={`focus:outline-none flex items-center px-2.5 py-2 rounded whitespace-nowrap ${active ? selectedBgClass : 'hover:bg-gray-100'}`}
      to={to}
    >
      <Icon className={`${active ? selectedClass : 'text-gray-600 hover:text-gray-700'} mr-2`} size={20} />
      <span className={`text-sm font-medium ${active ? selectedClass : 'text-gray-600 hover:text-gray-700'}`}>
        {children}
      </span>
    </Link>
  </li>
}
function SettingsSidebar({ selected = 'general', onSelect }: {
  selected?: SelectedItemType | string | null
  onSelect?: (selected: SelectedItemType) => void
}) {
  return (
    <div className="flex flex-nowrap overflow-x-scroll no-scrollbar md:block md:overflow-auto px-3 py-6 border-b md:border-b-0 md:border-r border-gray-200 md:w-60 lg:w-64 md:space-y-3">
      {/* Group 1 */}
      <div>
        <div className="text-xs font-semibold text-gray-400 uppercase mb-3">
          Workspace settings
        </div>
        <ul className="flex flex-nowrap md:block mr-3 md:mr-0">
          <Item to={`${window.location.pathname}?active=general`} active={selected === 'general'} iconClass={FiSettings}>
            General
          </Item>
          <Item to={`${window.location.pathname}?active=members`} active={selected === 'members'} iconClass={FiUsers}>
            Members
          </Item>
          <Item to={`${window.location.pathname}?active=pin_types`} active={selected === 'pin_types'} iconClass={FiMapPin}>
            Pin & Report Types
          </Item>
          <Item to={`${window.location.pathname}?active=notifications`} active={selected === 'notifications'} iconClass={FiBell}>
            My notifications
          </Item>
          <Item to={`${window.location.pathname}?active=integrations`} active={selected === 'integrations'} iconClass={FiGrid}>
            Integrations
          </Item>
          <Item to={`${window.location.pathname}?active=plans`} active={selected === 'plans'} iconClass={FiAlignLeft}>
            Plans
          </Item>
          <Item to={`${window.location.pathname}?active=billing`} active={selected === 'billing'} iconClass={FiBook}>
            Billing & Invoices
          </Item>
        </ul>
      </div>
      {/* Group 2 */}
      <div>
        <div className="text-xs font-semibold text-gray-400 uppercase mb-3">
          Experience
        </div>
        <ul className="flex flex-nowrap md:block mr-3 md:mr-0">
          <Item to={`${window.location.pathname}?active=feedback`} active={selected === 'feedback'} iconClass={FiBox}>
            Give Feedback
          </Item>
        </ul>
      </div>
    </div >
  );
}

export default SettingsSidebar;
