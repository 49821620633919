import { useEffect, useState } from 'react';
import Modal, { ModalFooter, ModalHeader } from '../utility/Modal';
import colors from '../../styles/colors';
import { GoPlus } from 'react-icons/go';
import useApi from '../../hooks/useApi';
import workspaceMembersApi from '../../api/workspace_members';
import Loader from '../utility/Loader';
import DelayedSearchInput, { SearchContainer } from '../utility/DelayedSearchInput';
import AddMemberItem from './AddMemberItem';
import { AddExtraProps } from '../../utility/addExtraProps';
import AddContactItem from './AddContactItem';
import { FiPlus } from 'react-icons/fi';
import AddMemberModal from '../team/AddMemberModal';
import useModal from '../../hooks/useModal';
import { useParams } from 'react-router-dom';
import { validateEmail } from '../../utility/validateEmail';
import WorkspaceMemberViewModel from '../../models/WorkspaceMemberViewModel';

export default function AddMembersModal({
  title = 'Add Team Member',
  button,
  forContact = false,
  afterUpdate,
}) {
  const { project_id } = useParams();
  const { open: modalOpen, toggle: toggleModal } = useModal();
  const [searchParams, setSearchParams] = useState({
    page: 1,
    q: '',
    items: 10,
    available: !forContact,
  });
  const [members, setMembers] = useState([]);
  const {
    data: { records, pagy },
    error,
    loading,
    request: loadWorkspaceMembers,
  } = useApi(
    workspaceMembersApi.getProjectWorkspaceMembers,
    { records: [], pagy: {} },
    true
  );

  const searchIsEmail = (text) => validateEmail(text);

  useEffect(() => {
    if (modalOpen) return;
    setMembers([]);
    setSearchParams({ ...searchParams, page: 1, q: '' });
  }, [modalOpen]);

  useEffect(() => {
    if (!modalOpen) return;
    loadWorkspaceMembers(project_id, searchParams);
  }, [modalOpen, searchParams]);

  useEffect(() => {
    if (records.length === 0) return;
    let objectIds = members.map(({ objectId }) => objectId);
    setMembers([
      ...members.map((member) => new WorkspaceMemberViewModel(member)),
      ...records
        .filter(({ objectId }) => !objectIds.includes(objectId))
        .map((member) => new WorkspaceMemberViewModel(member)),
    ]);
  }, [records]);

  return (
    <>
      {button ? (
        AddExtraProps(button, { onClick: toggleModal })
      ) : (
        <a className="card-add-btn" onClick={toggleModal}>
          <div className="w-full h-24 flex flex-col justify-center text-xs">
            <GoPlus
              size={16}
              color={colors.secondary}
              className="mb-1 mx-auto"
            />
            <p className="font-bold text-secondary">
              ADD
              <br />
              MEMBER
            </p>
          </div>
        </a>
      )}
      <Modal isOpen={modalOpen} onClose={toggleModal}>
        <ModalHeader title={title} onClose={toggleModal} />
        <SearchContainer>
          <DelayedSearchInput
            key={`searching_for_contact_${forContact}_open_${modalOpen}`}
            placeholder="Search Team Members..."
            defaultValue={searchParams.q}
            onSearched={(text) => {
              setSearchParams({ ...searchParams, page: 1, q: text });
              setMembers([]);
            }}
          />
          <AddMemberModal
            key={`search_field_add_member_${project_id}`}
            user={{ name: searchParams.q }}
            button={
              <button
                className="border-l px-4 flex items-center bg-gray-100 focus:outline-none"
                title="Add New Member"
              >
                <FiPlus size={16} className="hover:opacity-60 cursor-pointer" />
              </button>
            }
            afterCreate={() => {
              setMembers([]);
              loadWorkspaceMembers(project_id, searchParams);
              toggleModal();
              afterUpdate?.();
            }}
          />
        </SearchContainer>
        {(!loading || members.length > 0) && (
          <>
            {members.length === 0 && searchParams.q.trim() === '' && (
              <>
                <p className="font-bold text-lg text-gray-600 py-10 pb-5 text-center">
                  All workspace members have been added to the project
                </p>
                <AddMemberModal
                  user={{ name: searchParams.q }}
                  key={`all_members_added_add_member_${project_id}`}
                  button={
                    <button className="focus:outline-none border border-secondary hover:opacity-80 text-sm font-semibold bg-blue-50 text-secondary px-3 py-1 shadow cursor-pointer rounded-full mb-5">
                      + Add new member
                    </button>
                  }
                  afterCreate={() => {
                    setMembers([]);
                    loadWorkspaceMembers(project_id, searchParams);
                    toggleModal();
                    afterUpdate?.();
                  }}
                />
              </>
            )}
            {members.length === 0 && searchParams.q.trim() !== '' && (
              <>
                <p className="font-bold text-lg text-gray-600 py-10 pb-5 text-center">
                  No member matching{' '}
                  <span className="italic mr-1 font-medium">
                    '{searchParams.q}'
                  </span>{' '}
                  found <br /> that can be added{' '}
                  {forContact ? 'as contact' : 'as member'}
                </p>
                {searchIsEmail(searchParams.q) && (
                  <AddMemberModal
                    user={{ email: searchParams.q }}
                    key={`empty_result_add_member_${project_id}`}
                    button={
                      <button className="focus:outline-none border border-secondary hover:opacity-80 text-sm font-semibold bg-blue-50 text-secondary px-3 py-1 shadow cursor-pointer rounded-full mb-5">
                        Invite {searchParams.q}
                      </button>
                    }
                    afterCreate={() => {
                      setMembers([]);
                      loadWorkspaceMembers(project_id, searchParams);
                      toggleModal();
                      afterUpdate?.();
                    }}
                  />
                )}
              </>
            )}
            <div className="max-h-72 overflow-y-scroll">
              {members.map((workspace_member) => {
                return forContact ? (
                  <AddContactItem
                    key={`project_${project_id}_workspace_member_${workspace_member.objectId}_add_contact`}
                    workspace_member={workspace_member}
                    afterUpdate={(project) => {
                      toggleModal();
                      afterUpdate?.(project);
                    }}
                  />
                ) : (
                  <AddMemberItem
                    key={`project_${project_id}_workspace_member_${workspace_member.objectId}`}
                    workspace_member={workspace_member}
                    afterUpdate={afterUpdate}
                  />
                );
              })}
              {!loading &&
                pagy.count !== undefined &&
                pagy.count !== members.length && (
                  <div
                    className="flex justify-center p-3 border-b border-gray-200"
                    onClick={() =>
                      setSearchParams({
                        ...searchParams,
                        page: searchParams.page + 1,
                      })
                    }
                  >
                    <a className="text-black hover:opacity-80 text-sm font-semibold bg-gray-100 px-4 py-2 shadow cursor-pointer">
                      Load More
                    </a>
                  </div>
                )}
            </div>
          </>
        )}

        {loading && (
          <div className="flex justify-center py-5">
            <Loader color="black" />
          </div>
        )}
        <ModalFooter>
          <button
            className="modal-close-btn"
            type="button"
            onClick={toggleModal}
          >
            Close
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
}
