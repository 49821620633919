import { FiDownload } from 'react-icons/fi';
export default function DownloadButton({ file, name }) {
  const downloadLink = () => file.replace('file?size=original', 'download');

  const downloadURI = () => {
    var link = document.createElement('a');
    link.href = downloadLink();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div
      key={`file_${name}_loading`}
      className={`px-2 bg-black flex items-center h-12 w-12 rounded-full flex justify-center focus:outline-none active:bg-gray-900 disabled:opacity-50 mr-2 hover:bg-gray-900 cursor-pointer text-white`}
      onClick={() => downloadURI(file)}
    >
      <FiDownload size={24} />
    </div>
  );
}
