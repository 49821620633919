import { useState, useEffect } from 'react';
import sizeInMB from '../../../utility/sizeInMB';

const SIZE_LIMITED_FILE_TYPES = ['KML', 'KMZ'];
const MALFORMED_FILE_TYPES = ['KML'];
const VALIDATABLE_FILE_TYPES = [...SIZE_LIMITED_FILE_TYPES, ...MALFORMED_FILE_TYPES];

function isKMLMalformed(fileContent: string) {
    try {
        // Parse the KML content
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(fileContent, 'text/xml');

        // Check if the root element is 'kml'
        const rootElement = xmlDoc.documentElement;
        if (rootElement.tagName.toLowerCase() !== 'kml') return true;

        return false;
    } catch (error) {
        return true;
    }
}

export default function useValidateFile({
    enabled,
    file,
    fileType,
}: {
    enabled: boolean,
    file: File,
    fileType: string
}) {
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (!enabled) return;
        if (!fileType || !VALIDATABLE_FILE_TYPES.includes(fileType)) return;


        const validateFileSize = () => {
            if (parseFloat(sizeInMB(file.size)) > 260) {
                setError(`${fileType} files must be <= 260MB`);
            }
        };

        const validateFileForm = () => {
            const reader = new FileReader();
            reader.onload = function (event) {
                const fileContent = event?.target?.result;

                if (!fileContent) {
                    setError(`${fileType} unable to validate file.`);
                    return;
                }
                // Call the validation function with the file content
                const isMalformed = isKMLMalformed(fileContent as string);

                if (isMalformed) {
                    setError(`${fileType} file is malformed.`);
                }
            };

            reader.readAsText(file);
        };

        const validateFile = () => {
            if (SIZE_LIMITED_FILE_TYPES.includes(fileType)) validateFileSize();
            if (MALFORMED_FILE_TYPES.includes(fileType)) validateFileForm();
        };

        validateFile();
    }, [enabled, fileType]);

    return {
        valid: !error,
        error
    };
}