import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import useQuery from '../hooks/useQuery';
import authApi from '../api/auth';
import Loader from '../partials/utility/Loader';
import logo from '../images/logos/logo-black.svg';
import AuthImage from '../images/bgs/login-bg.png';
import ErrorViewModel from '../models/ErrorViewModel';
import ErrorView from '../partials/utility/ErrorView';
import { Redirect } from 'react-router';

function ResetPassword() {
  const query = useQuery();
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(false);
  const reset_password_token = query.get('token');

  const updatePassword = async (values) => {
    setError(false);

    setSaving(true);

    values.reset_password_token = reset_password_token;

    let response = await authApi.updatePassword(values);

    setSaving(false);

    if (!response.ok)
      setError(new ErrorViewModel(response.data.error || response.data));
    else setSaved(true);
  };

  const formik = useFormik({
    initialValues: {
      password: '',
      password_confirmation: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(6, 'Must be 6 characters or more')
        .max(128, 'Must be 128 characters or less')
        .required('Required'),
      password_confirmation: Yup.string().oneOf(
        [Yup.ref('password'), null],
        'Passwords must match'
      ),
    }),
    onSubmit: updatePassword,
  });

  if (saved)
    return (
      <Redirect
        to={{
          pathname: '/signin',
          state: { message: 'New Password successfully set!' },
        }}
      />
    );

  return (
    <main className="bg-white">
      <div className="relative md:flex">
        {/* Content */}
        <div className="md:w-1/2">
          <div className="max-w-sm mx-auto min-h-screen flex flex-col justify-center px-4 py-8">
            <div className="w-full">
              <img src={logo} className="w-7/12 mb-12" />
              {error && <ErrorView error={error} extraClass={'mb-4'} />}
              <h1 className="text-3xl text-gray-800 font-bold mb-6">
                Reset your password
              </h1>
              {/* Form */}
              <form onSubmit={formik.handleSubmit}>
                <div className="space-y-4">
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="password"
                    >
                      New Password{' '}
                      <span className="text-red-500">
                        *{' '}
                        {formik.touched.password &&
                          formik.errors.password &&
                          formik.errors.password}
                      </span>
                    </label>
                    <input
                      id="password"
                      name="password"
                      className={`form-input w-full ${
                        formik.touched.password &&
                        formik.errors.password &&
                        'border-red-500'
                      }`}
                      {...formik.getFieldProps('password')}
                      type="password"
                    />
                  </div>
                  <div>
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="password_confirmation"
                    >
                      Repeat New Password{' '}
                      <span className="text-red-500">
                        *{' '}
                        {formik.touched.password_confirmation &&
                          formik.errors.password_confirmation &&
                          formik.errors.password_confirmation}
                      </span>
                    </label>
                    <input
                      id="password"
                      name="password_confirmation"
                      className={`form-input w-full ${
                        formik.touched.password_confirmation &&
                        formik.errors.password_confirmation &&
                        'border-red-500'
                      }`}
                      {...formik.getFieldProps('password_confirmation')}
                      type="password"
                    />
                  </div>
                </div>
                <div className="flex justify-end mt-6">
                  {!saved && (
                    <button
                      className="btn rounded bg-secondary text-white whitespace-nowrap"
                      disabled={saving}
                      type="submit"
                    >
                      Save new password
                      {saving && <Loader />}
                    </button>
                  )}
                  {saved && (
                    <button className="btn rounded bg-green-400 text-white whitespace-nowrap">
                      Saved!
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* Image */}
        <div
          className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
          aria-hidden="true"
        >
          <img
            className="object-cover object-center w-full h-full"
            src={AuthImage}
            width="760"
            height="1024"
            alt="Authentication"
          />
        </div>
      </div>
    </main>
  );
}

export default ResetPassword;
