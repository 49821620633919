import { useState, memo } from 'react';
import { FaUndo } from 'react-icons/fa';
import { BiHide } from 'react-icons/bi';
import Modal, { ModalHeader } from '../utility/Modal';
import reportPagesApi from '../../api/report_pages';
import { toast } from 'react-toastify';
import useReportContext from '../reports/useReportContext';

function ReportPageHideButton({ reportPageId }) {
  const [hidden, setHidden] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const { hiddenPages, setHiddenPages, softHiddenPages, setSoftHiddenPages } =
    useReportContext();

  const restoreRemoval = () => {
    setHidden(false);
    setSoftHiddenPages([
      ...softHiddenPages.filter((pageId) => pageId !== reportPageId),
    ]);
  };

  const unHideReportPage = async () => {
    let { data } = await reportPagesApi.updateReportPage(reportPageId, {
      hidden: false,
    });
    if (data) {
      setHiddenPages([
        ...hiddenPages.filter((pageId) => pageId !== reportPageId),
      ]);
      setHidden(false);
    }
  };

  const hideReportPage = async () => {
    let { data } = await reportPagesApi.updateReportPage(reportPageId, {
      hidden: true,
    });
    if (data) setHiddenPages([...hiddenPages, reportPageId]);
  };

  const showToast = async () => {
    setModalOpen(false);
    toast.info(
      <div>
        <div className="font-bold text-secondary flex items-center justify-between">
          <span>
            <strong>Page</strong> Hidden
          </span>
          <a
            className="cursor-pointer ml-4 px-2 py-1 border rounded-md bg-white hover:shadow flex text-secondary items-center"
            onClick={unHideReportPage}
          >
            Undo <FaUndo className="ml-3" />
          </a>
        </div>
      </div>,
      {
        position: 'bottom-left',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );

    await hideReportPage();
  };

  const closeModal = () => setModalOpen(false);

  return (
    <>
      <a
        onClick={() => setModalOpen(!modalOpen)}
        className="transition ease-in duration-150 py-2 cursor-pointer bg-transparent font-medium text-xs text-gray-200 hover:text-white flex px-2 border-0 mr-1"
      >
        <BiHide size={16} className="mr-0.5 text-blue-400" /> Hide Page
      </a>

      <Modal isOpen={modalOpen} onClose={closeModal}>
        <ModalHeader title={'Confirm Page Hiding'} onClose={closeModal} />
        <div className="px-2 pt-2">
          <div className="p-5">
            {!hidden && (
              <>
                <div className="mb-4">
                  <div className="font-semibold">Are you sure?</div>
                </div>
                <div>This will completely hide this page from the report.</div>
              </>
            )}
          </div>

          {!hidden && (
            <div className="modal-footer">
              <button
                className="modal-save-btn"
                type="button"
                onClick={showToast}
              >
                Hide Page
              </button>
              <button
                className="modal-close-btn"
                type="button"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}

export default memo(ReportPageHideButton);
