import { memo, useEffect, useState } from 'react';
import { FiDownload, FiEdit, FiLayers, FiZoomIn } from 'react-icons/fi';
import { FaFileImage, FaFilePdf, FaShapes } from 'react-icons/fa';
import { GiDeliveryDrone } from 'react-icons/gi';
import Loader from '../utility/Loader';
import useQuery from '../../hooks/useQuery';
import { Link, useHistory, useParams } from 'react-router-dom';
import { defaultOpacity } from '../../utility/mapLayerConfig';
import Badge from '../shared/Badge';
import { layerErrored, layerProcessing } from './utils/helpers';
import { DOWNLOADABLE_LAYER_TYPES, OPACITY_ENABLED_FILE_TYPES, ZOOM_TO_ENABLED_FILE_TYPES } from './hooks/useFileMapLayerData';
import Button from '../shared/Button';
import useFeatures from '../../hooks/useFeatures';

const sliderFileTypes = ['PDF', 'TIF', 'PNG', 'JPG', 'JPEG'];

const DownloadButton = ({ layer }) => {
  const onClick = (e) => {
    e.stopPropagation();
  }

  return <a
    href={layer.asset.files.download.original}
    onClick={onClick}
    title="Download layer"
    className="focus:outline-none border rounded-md px-2 py-2 ml-2 bg-white hover:shadow-md flex text-xs items-center">
    <FiDownload size={14} />
  </a>
}

const ZoomToButton = ({ onClick }) => {
  return <a
    href={"#"}
    title="Zoom to layer"
    onClick={(e) => {
      onClick();
      e.preventDefault();
      e.stopPropagation();
      return false;
    }}
    className="focus:outline-none border rounded-md px-2 py-2 ml-2 bg-white hover:shadow-md flex text-xs items-center">
    <FiZoomIn size={14} />
  </a>
}


function LayerButton({
  layer: passedLayer,
  loading: passedLoading,
  canEdit = true,
  canDownload = true,
  hidden = false,
  isChosen = false,
  onLayerPicked,
  onLayerUpdated,
  onZoomToLayer,
}) {
  const query = useQuery();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [layer, setLayer] = useState(passedLayer);
  const [showSlider, setShowSlider] = useState(
    sliderFileTypes.indexOf(passedLayer.file_type) > -1 && isChosen
  );
  const { workspace_id, project_id, share_id } = useParams();
  const finalCanDownload = canDownload && !share_id && DOWNLOADABLE_LAYER_TYPES.includes(layer.file_type);
  const { features } = useFeatures();


  useEffect(() => {
    setLoading(passedLoading);
  }, [passedLoading]);

  useEffect(() => {
    setLayer(passedLayer);
  }, [passedLayer]);

  const layerIcon = ({ layer: { file_type }, color = 'black' }) => {
    if (loading) return <Loader color={color} />;
    if (!file_type)
      return <FiLayers size={14} className={`mr-2 text-${color}`} />;
    if (file_type === 'PDF')
      return <FaFilePdf size={14} className={`mr-2 text-${color}`} />;
    if (file_type === 'TIF')
      return <GiDeliveryDrone size={14} className={`mr-2 text-${color}`} />;
    if (['PNG', 'JPG', 'JPEG'].includes(file_type))
      return <FaFileImage size={14} className={`mr-2 text-${color}`} />;
    if (file_type === 'KML')
      return (
        <img
          src={`/icons/kml_${color}_icon64.png`}
          className={`mr-2 text-${color}`}
          style={{ height: 17 }}
        />
      );
    if (file_type === 'KMZ')
      return (
        <img
          src={`/icons/kmz_${color}_icon64.png`}
          className={`mr-2 text-${color}`}
          style={{ height: 17 }}
        />
      );
    return <FaShapes size={14} className={`ml-2 text-${color}`} />;
  };

  const checkQueryParams = () => {
    if (!query.get('map_layer_id')) return;
    query.delete('map_layer_id');
    history.replace({
      search: query.toString(),
    });
  };
  const updateOpacity = ({ target: { value } }) => {
    onLayerUpdated?.({ ...layer, opacity: value });
  };

  const zoomToEnabled = !!features.rollout?.map_layer_zoom_to && ZOOM_TO_ENABLED_FILE_TYPES.includes(layer.file_type);

  return (
    <>
      <div
        className={`${hidden ? 'hidden' : ''
          } border-t-2 justify-between border-color-gray-200 cursor-pointer bg-${isChosen ? 'white' : 'gray-100'
          } hover:opacity-90 py-1 px-4 text-${isChosen ? 'secondary' : 'black'
          } font-semibold items-center flex`}
        data-testid={`mapLayerButton${layer.name}`}
        onClick={() => {
          sliderFileTypes.indexOf(layer.file_type) > -1 &&
            setShowSlider(!isChosen);
          checkQueryParams();
          setLoading(true);
          onLayerPicked && onLayerPicked({ opacity: defaultOpacity, ...layer });
        }}
      >
        {layerIcon({ layer, color: isChosen ? 'secondary' : 'black' })}
        <span className="text-left flex-grow">{layer.name}</span>
        {layerProcessing(layer) && (
          <Badge label="Processing" title="Map layer tileset processing" />
        )}
        {layerErrored(layer) && (
          <Badge
            label={null}
            style="danger"
            title={`Map layer tileset creation errored with '${layer.latest_upload?.payload?.error}'`}
          />
        )}
        {canEdit && (
          <Link
            className="border rounded-md px-2 py-2 ml-2 bg-white hover:shadow-md flex text-xs items-center"
            data-testid={`mapLayerButtonEdit${layer.name}`}
            title='Edit layer'
            to={`/${workspace_id}/projects/${project_id}/map_layers/${layer.objectId}`}
          >
            <FiEdit
              size={14}
              className={`text-${isChosen ? 'secondary' : 'black'}`}
            />
          </Link>
        )}
        {finalCanDownload && <DownloadButton layer={layer} />}
        {zoomToEnabled && <ZoomToButton onClick={onZoomToLayer} />}
      </div>
      <div
        className={`bg-white px-2 ${(!showSlider ||
          hidden) &&
          'hidden'
          }`}
      >
        <input
          id="range_slider"
          data-testid={`mapLayerButtonSlider${layer.name}`}
          className="rounded-lg overflow-hidden appearance-none bg-secondary h-3 w-full"
          type="range"
          min="0"
          max="1"
          step="0.01"
          defaultValue={layer.opacity || defaultOpacity}
          onChange={updateOpacity}
        />
      </div>
    </>
  );
}

export default memo(LayerButton);
