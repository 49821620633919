import React, { useState, useEffect, forwardRef, useRef } from 'react';
import { BiCheckCircle } from 'react-icons/bi';
import entriesApi from '../../api/entries';
import useApi from '../../hooks/useApi';
import Loader from '../utility/Loader';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import formattedDateString from '../../utility/formattedDateString';

export default function EntryDatimeInput({
  entry: passedEntry,
  editing = true,
  useBottomBorder,
}) {
  const {
    data: savedEntry,
    error,
    loading: saving,
    request: saveEntry,
  } = useApi(entriesApi.updateEntry, null);
  const [entry, setEntry] = useState(passedEntry);
  const [saved, setSaved] = useState(false);
  const savedTimer = useRef(null);
  const datePickerRef = useRef(null);

  const submitEntry = (value) => {
    entry.value = value.toISOString();
    setEntry({ ...entry });
    saveEntry(entry.objectId, { value });
  };

  const entryDate = (s) => {
    return new Date(s.toString().replace(' UTC', 'Z').replace(' ', 'T'));
  };

  useEffect(() => {
    savedEntry && setEntry({ ...savedEntry });
  }, [savedEntry]);

  useEffect(() => {
    savedEntry && setSaved(true);
  }, [savedEntry]);

  useEffect(() => {
    if (saved) {
      savedTimer.current = setTimeout(() => {
        setSaved(false);
      }, 2000);
    }
  }, [saved]);

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <input
      type="text"
      key={`entry_input_datetime_${entry.objectId}`}
      disabled={!editing}
      placeholder={'N/A'}
      value={value}
      onClick={onClick}
      onChange={() => { }}
      ref={ref}
      className={`w-full leading-4 border-gray-200 text-xxs border-0 focus:border-gray-200 ${editing && 'hover:bg-blue-50'
        } focus:outline-none focus:ring-0 overflow-hidden`}
    />
  ));

  return (
    <>
      <div
        className={`p-2 font-bold border-l ${useBottomBorder && 'border-b'
          } border-r relative overflow-hidden`}
      >
        {entry.name}
        {saving && (
          <div className="absolute top-0 right-2 h-full flex items-center">
            <Loader color="text-gray-700" />
          </div>
        )}
        {saved && (
          <div className="absolute top-0 right-2 h-full flex items-center">
            <BiCheckCircle
              size={16}
              className="bg-green-500 text-white rounded-full overflow-hidden"
            />
          </div>
        )}
      </div>
      <div
        disabled={!editing}
        key={`entry_datetime_${entry.objectId}`}
        className={`${entry.class_name} border-t-0 border-r-0 border-l-0 ${useBottomBorder ? 'border-b' : 'border-b-0'
          } overflow-hidden items-center flex`}
      >
        {!editing ? (
          <div className="w-full px-2">
            {entry.value
              ? formattedDateString(
                entryDate(entry.value),
                entry.value_type === 'datetime'
                  ? 'MM/DD/YYYY h:mm A'
                  : 'MM/DD/YYYY'
              )
              : 'N/A'}
          </div>
        ) : (
          <>
            <DatePicker
              selected={entry.value ? entryDate(entry.value) : entry.value}
              ref={datePickerRef}
              onChange={(date) => submitEntry(date)}
              wrapperClassName={'w-full'}
              timeInputLabel="Time:"
              dateFormat={
                entry.value_type === 'datetime'
                  ? 'MM/dd/yyyy h:mm aa'
                  : 'MM/dd/yyyy'
              }
              showTimeInput
              maxDate={new Date()}
              customInput={<CustomInput />}
            />
          </>
        )}
      </div>
    </>
  );
}
