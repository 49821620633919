import { useEffect, useRef, useState } from 'react';
import Modal, { ModalHeader, ModalSuccessView } from '../utility/Modal';
import useApi from '../../hooks/useApi';
import Loader from '../utility/Loader';
import usersApi from '../../api/users';

export default function SendResetPasswordModal({
  email,
  sent,
  expired,
  open,
  onClose,
  onSaved,
  userObjectId,
}) {
  const {
    data,
    error,
    loading: sending,
    request: resetUserPassword,
  } = useApi(usersApi.resetUserPassword, null);
  const [reset, setReset] = useState(false);
  const sentTimer = useRef();

  const sendEmail = () => resetUserPassword(userObjectId);

  useEffect(() => {
    if (!data) return;
    onSaved?.(data);
    setReset(true);
  }, [data]);

  useEffect(() => {
    const closeAndReset = () => {
      onClose?.();
      setReset(false);
    };
    if (reset) sentTimer.current = setTimeout(closeAndReset, 3000);
  }, [reset]);

  return (
    <Modal isOpen={open} onClose={onClose}>
      <ModalHeader title={'Reset user password'} onClose={onClose} />
      {expired && (
        <div className="px-3 py-2 font-semibold bg-yellow-200 text-yellow-800">
          The existing reset password link has expired.
        </div>
      )}
      {sent && !expired && !reset && (
        <div className="px-3 py-2 font-semibold bg-blue-200 text-blue-800">
          Reset password link has been sent
        </div>
      )}
      <div className="px-2 pt-2">
        <div className="p-5">
          {!data && (
            <>
              <div className="mb-4">
                <div className="font-semibold">Send reset password email?</div>
              </div>
              <div>
                We'll send an email to{' '}
                <em>
                  <u>{email}</u>
                </em>{' '}
                with instructions to reset their password.
              </div>
            </>
          )}
          {data && <ModalSuccessView text={'Reset Password Email Sent!'} />}
        </div>

        {!data && (
          <div className="modal-footer">
            <button
              className="modal-save-btn"
              type="button"
              onClick={sendEmail}
              disabled={sending}
            >
              Send Email {sending && <Loader />}
            </button>
            <button
              className="modal-close-btn"
              type="button"
              onClick={onClose}
              disabled={sending}
            >
              Cancel
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
}
