import React, { useEffect, useState } from 'react';
import appConfigApi from '../api/app_config';
import PermissionsViewModel from '../models/PermissionsViewModel';
import authStorage from './storage';
const AuthContext = React.createContext();
export default AuthContext;
function AuthContextProvider({ children }) {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState();
  const [permissions, setPermissions] = useState({});

  useEffect(() => {
    const restoreUser = async () => {
      const user = await authStorage.getUser();
      user ? setUser(user) : setLoading(false);
    };
    restoreUser();
  }, []);

  useEffect(() => {
    if (!user) return;
    const getPermissions = async () => {
      try {
        let { data } = await appConfigApi.getAppPermissions();
        setPermissions(new PermissionsViewModel(data));
      } catch {}
      setLoading(false);
    };

    getPermissions();
  }, [user?.objectId]);

  const value = {
    loading,
    permissions,
    user,
    setUser,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export { AuthContextProvider };
