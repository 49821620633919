import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import pinsApi from '../../api/pins';
import useApi from '../../hooks/useApi';
import useQuery from '../../hooks/useQuery';
import PinViewModel from '../../models/PinViewModel';
import Modal from '../utility/Modal';
import PinActions from './PinActions';
import PinHeader from './PinHeader';
import PinModalDetails from './PinModalDetails';
import PinModalNotes from './PinModalNotes';
import PinPhotos from './PinPhotos';
import NewPinModal from './NewPinModal';
import AddWorkLogModal from './AddWorkLogModal';

export default function PinModal({
  pin: passedPin,
  pinId,
  onClose,
  onDeletePin,
  actionable = true,
}) {
  const { share_id } = useParams();
  const { data: loadedPin, request: getPin } = useApi(pinsApi.getPin, null);
  const [pin, setPin] = useState(null);
  const [photoShown, setPhotoShown] = useState(false);
  const [editingPin, setEditingPin] = useState(false);
  const [addingWorkLog, setAddingWorkLog] = useState(false);
  const query = useQuery();
  const history = useHistory();

  const removePinParam = () => {
    const param = query.get('pin');
    if (!param) return;
    query.delete('pin');
    history.replace({
      search: query.toString(),
    });
  };

  const _onClose = () => {
    removePinParam();
    onClose?.();
  };

  useEffect(() => {
    if (!pinId) return;
    getPin(pinId, { share_id });
  }, [pinId]);

  useEffect(() => {
    if (!loadedPin) return;
    setPin(new PinViewModel(loadedPin));
  }, [loadedPin]);

  useEffect(() => {
    if (!passedPin) return;
    setPin(new PinViewModel(passedPin));
  }, [passedPin]);

  return <>
    <Modal
      isOpen={pin}
      dialogScrolls={!photoShown}
      modalScrolls={false}
      dialogClass={'pt-10 pin-dialog'}
      maxWidth={'max-w-md'}
      aligned={'items-center'}
      onClose={_onClose}
    >
      {pin && (
        <div className="bg-gray-50 pb-44" data-testid={'pin_modal'}>
          <PinHeader onEditPin={() => setEditingPin(true)} key={`pinHeader${pin.updated_at}`} pin={pin} onClose={_onClose} setPin={setPin} />
          <div className="bg-white shadow">
            <PinPhotos key={`pinPhotos${pin.updated_at}`} pin={pin} onToggle={(shown) => setPhotoShown(shown)} />
            {actionable && (
              <PinActions
                key={`pinActions${pin.updated_at}`}
                pin={pin}
                onAddWorkLog={() => setAddingWorkLog(true)}
                onDeletePin={onDeletePin}
              />
            )}
          </div>
          <PinModalNotes key={`pinNotes${pin.updated_at}`} pin={pin} afterEdit={() =>
            getPin(pinId, { share_id })} />
          <PinModalDetails key={`pinDetails${pin.updated_at}`} pin={pin} onUpdate={setPin} />
        </div>
      )}
    </Modal>
    {editingPin &&
      <NewPinModal
        key={`updatePinModal${pin?.objectId}`}
        onClose={(loadPin) => {
          if (loadPin) getPin(pin.objectId, { share_id });
          setEditingPin(false)
        }}
        existingPin={pin}
      />
    }
    {addingWorkLog &&
      <AddWorkLogModal
        key={`addWorkLogModal${pin?.objectId}`}
        onClose={(updatedPin) => {
          setAddingWorkLog(false)
          if (updatedPin) setPin(new PinViewModel(updatedPin));
        }}
        pin={pin}
      />
    }
  </>;
}
