import { memo, useEffect, useState } from 'react';
import { ContextMenuTrigger } from 'react-contextmenu';
import useApi from '../../hooks/useApi';
import assetsApi from '../../api/assets';
import AddPhotoModal from './AddPhotoModal';
import ProjectPhotosModal from './ProjectPhotosModal';
import PhotosModal from '../utility/PhotosModal';
import Loader from '../utility/Loader';
import { useHistory, useParams } from 'react-router-dom';
import { useDownloads } from '../utility/DownloadBar';
import ContextMenus from '../utility/ContextMenus';

const contextDownload = (e, { uri }) => {
  let link = document.createElement('a');
  link.href = uri.replace('file?size=original', 'download');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

function ProjectPhotosCard() {
  const { workspace_id, project_id } = useParams();
  const [shownPhotoIndex, setShownPhotoIndex] = useState(-1);
  const {
    data: { records, pagy },
    error,
    loading,
    request: loadAssets,
  } = useApi(assetsApi.getAssets, { records: [], pagy: {} }, true);
  const { setDownloads } = useDownloads();
  const loadFirstPage = () => loadAssets('project', project_id, { items: 9 });
  const history = useHistory();

  const goToPin = (e, { pinId }) => {
    history.push(`/${workspace_id}/projects/${project_id}/maps?pin=${pinId}`)
  };

  const selectPhoto = (e, data) => {
    setDownloads((d) => [...d, data.asset]);
  };

  useEffect(() => {
    loadFirstPage();
  }, []);

  return <>
    <div className="bg-white rounded-sm border border-gray-200 overflow-hidden text-black shadow-xl rounded-lg">
      <div className="flex flex-col h-full">
        <div className="card-header">
          <p className="text-sm font-bold">
            {pagy.count !== undefined ? 'Photos (' + pagy.count + ')' : '...'}
          </p>
          <ProjectPhotosModal allowSelectAll={true} />
        </div>
        {loading && (
          <div className="flex justify-center py-5">
            <Loader color="black" />
          </div>
        )}
        {!loading && (
          <div className="p-5 grid grid-cols-5 gap-3">
            <AddPhotoModal afterAdd={loadFirstPage} />
            {records.slice(0, 9).map((asset, index) => {
              const isPin = asset.assetable?.notable_type == 'Pin';
              return (
                <ContextMenuTrigger
                  id={isPin ? `pin_menu_projectPhotosInline${project_id}` : 'asset_menu_default'}
                  collect={() => {
                    return {
                      asset,
                      uri: asset.files.original,
                      pinId: isPin ? asset.assetable.notable_id : null,
                    };
                  }}
                >
                  <div
                    key={`project_photos_card_photo_${asset.objectId}`}
                    data-testid={`projectPhotosCardPhoto${asset.objectId}`}
                    className="bg-cover bg-center h-24 hover:opacity-80 bg-gray-900 cursor-pointer"
                    onClick={() => setShownPhotoIndex(index)}
                    style={{ backgroundImage: `url(${asset.files.medium})` }}
                  />
                </ContextMenuTrigger>
              );
            })}
          </div>
        )}
      </div>
      <ContextMenus id={`projectPhotosInline${project_id}`} onDownload={contextDownload} onZoomToPin={goToPin} hideOnLeave={false} />
    </div>

    <PhotosModal
      isOpen={shownPhotoIndex > -1}
      defaultFullScreen={true}
      assets={records}
      activeIndex={shownPhotoIndex}
      hideBackdrop={true}
      maxHeight={'max-h-screen'}
      onClose={() => setShownPhotoIndex(-1)}
      onDelete={loadFirstPage}
    />
  </>;
}

export default memo(ProjectPhotosCard);
