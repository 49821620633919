import React, { useState, useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ErrorViewModel from '../../models/ErrorViewModel';
import ErrorView from '../utility/ErrorView';
import Loader from '../utility/Loader';
import workspacesApi from '../../api/workspaces';
import workspaceMembersApi from '../../api/workspace_members';
import Button from '../shared/Button';

export default function WorkspaceForm({
  user,
  showBack = true,
  onBack,
  onComplete,
}) {
  const getDefaultWorkspaceName = () => {
    let defaultWorkspaceName = 'My Workspace';
    if (user?.name) {
      defaultWorkspaceName = `${user.name}'s Workspace`;
    }
    if (user?.custom_domain) {
      defaultWorkspaceName = user.custom_domain
        .split('.')[0]
        .trim()
        .toLowerCase()
        .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
    }
    return defaultWorkspaceName;
  };

  const [newWorkspace, setNewWorkspace] = useState({
    name: getDefaultWorkspaceName(),
  });
  const [autoInviteWorkspace, setAutoInviteWorkspace] = useState(null);
  const [usingAutoInvite, setUsingAutoInvite] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(false);
  const savedTimer = useRef(null);

  const setDefaultWorkspaceName = () => {
    setNewWorkspace({ name: getDefaultWorkspaceName() });
  };

  const getWorkspaceWithAutoInvites = async (domain) => {
    let response = await workspacesApi.getWorkspaces({
      where: {
        custom_domain: domain,
        allow_matching_custom_domain_signups: true,
      },
    });
    if (response.ok && response.data.records.length) {
      setUsingAutoInvite(true);
      setAutoInviteWorkspace(response.data.records[0]);
    } else setDefaultWorkspaceName();
    setLoading(false);
  };

  const joinWorkspace = async () => {
    setSaving(true);
    setError(false);
    let response = await workspaceMembersApi.addWorkspaceMember(
      autoInviteWorkspace.objectId,
      { member_id: user.id }
    );
    if (!response.ok && !response.data?.member_id?.[0].includes('already been taken')) {
      let { data } = response;
      setError(new ErrorViewModel(data));
    } else setSaved(true);
    setSaving(false);
  };

  const saveWorkspace = async (values) => {
    setError(false);
    setSaving(true);
    let response = await workspacesApi.addWorkspace(values);
    setSaving(false);
    if (!response.ok)
      return setError(new ErrorViewModel(response.data.error || response.data));
    else setSaved(true);
  };

  const formik = useFormik({
    initialValues: newWorkspace,
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
    }),
    onSubmit: saveWorkspace,
  });

  useEffect(() => {
    if (!user) return;
    if (user.custom_domain) getWorkspaceWithAutoInvites(user.custom_domain);
    else {
      setDefaultWorkspaceName();
      setLoading(false);
    }
  }, [user?.name]);

  useEffect(() => {
    if (saved) {
      savedTimer.current = setTimeout(() => {
        onComplete?.();
      }, 2500);
    }
  }, [saved]);

  if (loading)
    return (
      <div className="max-w-md mx-auto flex items-center justify-center">
        <Loader color="text-black" />
      </div>
    );

  return (
    <div className="max-w-md mx-auto">
      {error && (
        <div className="text-center">
          <ErrorView error={error} extraClass={'mb-4'} />
        </div>
      )}
      {autoInviteWorkspace && usingAutoInvite ? (
        <div className="text-center">
          <h1 className="text-3xl text-gray-800 font-bold mb-4">
            Ready to join your team?
          </h1>
          <p className="mb-6">
            Your team members{' '}
            <span className="font-bold">@{user?.custom_domain}</span> have
            already created the workspace{' '}
            <span className="font-bold">{autoInviteWorkspace.name}</span>.
          </p>
          <div className="flex flex-col justify-center">
            <Button
              text="Join team"
              savedText="Joined"
              saving={saving}
              saved={saved}
              onClick={joinWorkspace}
            />
            <button
              className="btn rounded bg-white hover:opacity-80 text-tertiary shadow-none mt-2"
              disabled={saving || saved}
              onPress={() => setUsingAutoInvite(false)}
              type="submit"
            >
              No thanks
            </button>
          </div>
        </div>
      ) : (
        <>
          <h1 className="text-3xl text-gray-800 font-bold mb-6">
            What's the name of your team or company?
          </h1>
          <form onSubmit={formik.handleSubmit}>
            <div className="space-y-4 mb-8">
              {/* Company Name */}
              <div>
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="name"
                >
                  Workspace Name <span className="text-red-500">*</span>
                </label>
                <input
                  id="name"
                  name="name"
                  className="form-input w-full"
                  data-testid="input-name"
                  placeholder="Team name (eg Sitemarker Inc)"
                  type="text"
                  {...formik.getFieldProps('name')}
                />
              </div>
            </div>
            <div className="flex items-center justify-between">
              <button
                className={`text-sm underline hover:no-underline ${autoInviteWorkspace && !usingAutoInvite ? '' : 'hidden'
                  }`}
                onPress={() => setUsingAutoInvite(true)}
              >
                &lt;- Back
              </button>
              <Button
                saved={saved}
                saving={saving}
                text="Next"
                savedText="Saved"
              />
            </div>
          </form>
        </>
      )}
    </div>
  );
}
