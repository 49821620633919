import * as React from 'react';
const defaultClass = "focus:outline-none form-input focus:border-gray-300";
export default function Input({ className, suffix, ...rest }: { className?: string, suffix?: string, rest: React.InputHTMLAttributes<HTMLInputElement> }) {
    return <div className="relative">
        <input
            className={[defaultClass, className].join(' ')}
            {...rest}
        />
        {suffix && <div className="absolute inset-0 left-auto flex items-center pointer-events-none">
            <span className="text-sm text-gray-400 font-medium px-3">{suffix}</span>
        </div>}
    </div>
}