import { memo, useEffect, useState } from 'react';
import Modal, { ModalHeader } from '../utility/Modal';
import useApi from '../../hooks/useApi';
import assetsApi from '../../api/assets';
import Loader from '../utility/Loader';
import PhotosModal from '../utility/PhotosModal';
import ReactDatePicker from 'react-datepicker';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import {
  FiCheckCircle,
  FiCircle,
  FiEye,
  FiMaximize,
  FiMinimize,
  FiX,
} from 'react-icons/fi';
import BulkDownloadProjectPhotosModal from './BulkDownloadProjectPhotosModal';
import { BiSearch } from 'react-icons/bi';
import colors from '../../styles/colors';
import ContextMenus from '../utility/ContextMenus';
import { ContextMenuTrigger } from 'react-contextmenu';
import useSelectControl from '../../hooks/useSelectControl';

const DEFAULT_PHOTO_COUNT = 30;

function ProjectPhotosModal({ allowSelectAll = false }) {
  const { project_id, workspace_id } = useParams();
  const [modalOpen, setModal] = useState(false);
  const [page, setPage] = useState(0);
  const [modalExpanded, setModalExpanded] = useState('max-w-2xl');
  const [assets, setAssets] = useState([]);
  const [shownPhotoIndex, setShownPhotoIndex] = useState(-1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const {
    data: { records, pagy },
    error,
    loading,
    request: loadAssets,
  } = useApi(assetsApi.getAssets, { records: [], pagy: {} }, true);
  const {
    data: selectAllData,
    error: selectAllError,
    loading: selectAllLoading,
    request: loadAllAssetsForDate,
  } = useApi(assetsApi.getAssets, { records: [], pagy: {} }, true);
  const [searchParams, setSearchParams] = useState({
    where: { start_date: null, end_date: null },
  });
  const [formattedImages, setFormattedImages] = useState({});
  const [selectAllChosenDate, setSelectAllChosenDate] = useState();
  const history = useHistory();
  const loadMore = () => {
    let newPage = page + 1;
    setPage(newPage);
    if (searchParams.where.start_date) {
      return loadAssets('project', project_id, {
        items: DEFAULT_PHOTO_COUNT,
        page: newPage,
        where: searchParams.where,
      });
    }

    loadAssets('project', project_id, {
      items: DEFAULT_PHOTO_COUNT,
      page: newPage,
    });
  };

  // If select all is chosen and infinite scroll still hasnt retrieved all assets, load all assets to properly select them.
  const loadAllSelectAll = (date) => {
    if (!pagy?.count) return;
    let pages = pagy.last;
    setSelectAllChosenDate(date);
    loadAllAssetsForDate('project', project_id, {
      items: pagy.count,
      page: pages,
      where: {
        start_date: moment(date).format('DD-MM-YYYY'),
        end_date: moment(date).format('DD-MM-YYYY 23:59:59'),
      },
    });
  };

  const hasNextPage = () => !!pagy.next;

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: hasNextPage(),
    onLoadMore: loadMore,
    disabled: !!error,
    rootMargin: '0px 0px 400px 0px',
  });

  const toggleModal = () => setModal(!modalOpen);

  const {
    clearSelections,
    mouseEnterAndLeaveControl,
    highlightIndexes,
    selectedIndexes: selectedPhotoIndexes,
    setSelectedIndexes: setSelectedPhotoIndexes,
    toggleIndex,
    selectControl,
  } = useSelectControl({ enabled: modalOpen });

  const contextNavigate = (e, { pinId }) => {
    history.push(`/${workspace_id}/projects/${project_id}/maps?pin=${pinId}`)
  };

  const contextSelect = (e, { asset: { originalIndex } }) => {
    toggleIndex(originalIndex);
  };

  const contextDownload = (e, { uri }) => {
    let link = document.createElement('a');
    link.href = uri.replace('file?size=original', 'download');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return false;
  };

  const handleDateChange = (value) => {
    setStartDate(value[0]);
    setEndDate(value[1]);
    setPage(0);
    setAssets([]);
  };

  const handleFormattingImages = (assetData) => {
    return assetData.reduce((group, asset, index) => {
      const date = asset.created_at;
      const formattedDate = moment(date).format('ddd, MMM DD, YYYY');
      group[formattedDate] = group[formattedDate] ?? [];

      if (group[formattedDate].find((x) => x.objectId === asset.objectId)) {
        return group;
      } else {
        asset.originalIndex = index;
        group[formattedDate].push(asset);
        return group;
      }
    }, {});
  };

  useEffect(() => {
    if (modalOpen) return loadMore();

    setStartDate(null);
    setEndDate(null);
    setAssets([]);
    setPage(0);
    clearSelections();
  }, [modalOpen, searchParams]);

  useEffect(() => {
    clearSelections();

    if (startDate && endDate)
      setSearchParams({
        ...searchParams,
        where: {
          ...searchParams.where,
          start_date: moment(startDate).format('DD-MM-YYYY'),
          end_date: moment(endDate).format('DD-MM-YYYY 23:59:59'),
        },
      });
    else
      setSearchParams({
        ...searchParams,
        where: { ...searchParams.where, start_date: null, end_date: null },
      });
  }, [startDate, endDate]);

  useEffect(() => {
    if (!records.length) return;
    setAssets([...assets, ...records]);
  }, [records]);

  useEffect(() => {
    if (assets.length === 0) return;
    const images = handleFormattingImages(assets);
    setFormattedImages(images);
  }, [assets]);

  useEffect(() => {
    if (!selectAllData?.records?.length) return;
    setAssets([...assets, ...selectAllData.records]);
  }, [selectAllData]);

  useEffect(() => {
    if (formattedImages.length === 0) return;
    if (selectAllChosenDate) {
      setSelectedPhotoIndexes([
        ...selectedPhotoIndexes,
        ...formattedImages[selectAllChosenDate]
          .map((fmi) => {
            return fmi.originalIndex;
          })
          .filter((i) => !selectedPhotoIndexes.includes(i)),
      ]);
      setSelectAllChosenDate(null);
    }
  }, [formattedImages]);

  return (
    <>
      <button className="card-header-btn" type="button" onClick={toggleModal}>
        View All
      </button>
      <Modal
        modalScrolls={false}
        isOpen={modalOpen}
        onClose={toggleModal}
        maxHeight="h-full relative"
        maxWidth={modalExpanded}
      >
        <ModalHeader
          title={
            pagy.count !== undefined ? (
              <div className="flex w-full items-center justify-between">
                <span>Assets ({pagy.count}) </span>
                <button
                  className={`pr-4 mr-4 border-r flex items-center flex justify-center focus:outline-none active:text-blue-900 disabled:text-gray-500 hover:text-blue-800 cursor-pointer text-black`}
                  title="Toggle Fullscreen"
                  onClick={() =>
                    setModalExpanded(
                      modalExpanded !== 'max-w-2xl' ? 'max-w-2xl' : 'max-w-full'
                    )
                  }
                >
                  {modalExpanded !== 'max-w-2xl' ? (
                    <FiMinimize data-testid="minimize-photos" />
                  ) : (
                    <FiMaximize data-testid="maximize-photos" />
                  )}
                </button>{' '}
              </div>
            ) : (
              <span>...</span>
            )
          }
          onClose={toggleModal}
        />
        <div className="bg-white border-b border-gray-200 p-4 relative z-30">
          <div
            className="flex items-center px-2 flex-1 bg-gray-100"
            data-testid="date-picker"
          >
            <BiSearch size={16} color={colors.darkGray} />
            <ReactDatePicker
              wrapperClassName="w-full"
              className="bg-gray-100 focus:ring-0 px-2 placeholder-gray-500 relative text-xs border-0 w-full border-0 outline-none focus:outline-none"
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={handleDateChange}
              placeholderText={'Search By Date'}
              isClearable={true}
            />
            <div></div>
          </div>
        </div>
        <div className="px-3 pb-48 overflow-y-scroll h-full">
          {(!loading || assets.length > 0) && (
            <div>
              {Object.keys(formattedImages).map((date) => {
                let splitDate = date.split(',');
                let day = splitDate[0];
                let dateYear = `${splitDate[1]}, ${splitDate[2]}`;
                const selectActive = selectedPhotoIndexes.length > 0;
                const allSelected =
                  selectedPhotoIndexes.filter((aindex) =>
                    formattedImages[date]
                      .map((fmi) => fmi.originalIndex)
                      .includes(aindex)
                  ).length === formattedImages[date].length;
                return (
                  <div className="mb-3 mt-1" key={`formatted-image-${date}`}>
                    <div className="font-medium px-2 mb-1 py-1.5 bg-white border-b shadow mb-1 text-secondary z-20 sticky top-0 flex">
                      {allowSelectAll &&
                        (allSelected ? (
                          <FiCheckCircle
                            size={20}
                            className={'cursor-pointer mt-0.5 mr-2'}
                            onClick={() => {
                              setSelectedPhotoIndexes(
                                selectedPhotoIndexes.filter(
                                  (index) =>
                                    !formattedImages[date]
                                      .map((fmi) => fmi.originalIndex)
                                      .includes(index)
                                )
                              );
                            }}
                          />
                        ) : (
                          <FiCircle
                            size={20}
                            className={'cursor-pointer mt-0.5 mr-2'}
                            onClick={() => {
                              if (assets.length < pagy.count) {
                                return loadAllSelectAll(date);
                              }
                              setSelectedPhotoIndexes([
                                ...selectedPhotoIndexes,
                                ...formattedImages[date]
                                  .map((fmi) => {
                                    return fmi.originalIndex;
                                  })
                                  .filter(
                                    (i) => !selectedPhotoIndexes.includes(i)
                                  ),
                              ]);
                            }}
                          />
                        ))}
                      <div>
                        <span className="font-black">{day}</span> {dateYear}
                      </div>
                    </div>
                    <div
                      className={`grid ${modalExpanded !== 'max-w-2xl'
                        ? 'grid-cols-7'
                        : 'grid-cols-5'
                        } gap-3 mb-2`}
                    >
                      {formattedImages[date].map((asset) => {
                        const isPin = asset.assetable?.notable_type == 'Pin';
                        const selected = selectedPhotoIndexes.includes(
                          asset.originalIndex
                        );
                        const highlighted = highlightIndexes.includes(
                          asset.originalIndex
                        );
                        return (
                          <ContextMenuTrigger
                            id={
                              isPin
                                ? `pin_menu_projectsModal${project_id}${selected ? '_selected' : ''
                                }`
                                : 'asset_menu_projectsModal'
                            }
                            collect={() => {
                              return {
                                asset,
                                uri: asset.files.original,
                                pinId: isPin
                                  ? asset.assetable.notable_id
                                  : null,
                              };
                            }}
                          >
                            <div
                              className="group relative h-32 cursor-pointer"
                              onClick={(event) =>
                                selectControl(event, {
                                  targetIndex: asset.originalIndex,
                                  onBypass: (i) => setShownPhotoIndex(i),
                                })
                              }
                              onMouseEnter={(event) =>
                                mouseEnterAndLeaveControl(
                                  event,
                                  asset.originalIndex
                                )
                              }
                              onMouseLeave={(event) =>
                                mouseEnterAndLeaveControl(
                                  event,
                                  asset.originalIndex
                                )
                              }
                            >
                              <div
                                className={`h-32 w-full hover:opacity-80 bg-center bg-no-repeat cursor-pointer bg-cover bg-gray-900 ${selected ? 'border-4 border-secondary' : ''
                                  }`}
                                style={{
                                  backgroundImage: `url(${asset.files.medium})`,
                                }}
                              />
                              <div
                                data-testid={`${asset.objectId}Overlay`}
                                className={`w-full h-32 top-0 left-0 absolute ${highlighted ? 'bg-secondary' : 'bg-black'
                                  } bg-opacity-20 p-2 ${selected || highlighted || selectActive
                                    ? ''
                                    : 'opacity-0'
                                  } group-hover:opacity-100`}
                              >
                                <button
                                  className="focus:outline-none"
                                  onClick={(event) => {
                                    selectControl(event, {
                                      targetIndex: asset.originalIndex,
                                      forceSelect: true,
                                    });
                                  }}
                                  data-testid={asset.objectId}
                                >
                                  {selected || highlighted ? (
                                    <FiCheckCircle
                                      size={20}
                                      className={`text-gray-50 ${selected ? 'opacity-100' : 'opacity-60'
                                        } hover:opacity-90 cursor-pointer`}
                                    />
                                  ) : (
                                    <FiCircle
                                      size={20}
                                      className={
                                        'text-gray-50 opacity-60 hover:opacity-90 cursor-pointer'
                                      }
                                    />
                                  )}
                                </button>
                              </div>
                              {(selected || selectActive) && (
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setShownPhotoIndex(asset.originalIndex);
                                  }}
                                  data-testid={`viewPhotoIcon${asset.objectId}`}
                                  className={`focus:outline-none bottom-0 right-0 absolute bg-opacity-20 p-2 group-hover:opacity-100`}
                                >
                                  <FiEye
                                    size={20}
                                    className={
                                      'text-gray-50 opacity-60 hover:opacity-90 cursor-pointer'
                                    }
                                  />
                                </button>
                              )}
                            </div>
                          </ContextMenuTrigger>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {(loading || hasNextPage()) && (
            <div className="flex justify-center py-5" ref={sentryRef}>
              <Loader color="black" />
            </div>
          )}
          {!loading && pagy.count === 0 && (
            <div className="flex justify-center">
              <p className="empty-data-txt">
                No photos have been added to project
              </p>
            </div>
          )}
        </div>
        <div className="modal-footer absolute bottom-0 w-full">
          <button
            className="modal-close-btn"
            type="button"
            onClick={toggleModal}
          >
            Close
          </button>
        </div>
        <ContextMenus
          onSelect={contextSelect}
          onDownload={contextDownload}
          onZoomToPin={contextNavigate}
          hideOnLeave={false}
          id={`projectsModal${project_id}`}
        />
      </Modal>
      <PhotosModal
        isOpen={shownPhotoIndex > -1}
        defaultFullScreen={true}
        assets={assets}
        activeIndex={shownPhotoIndex}
        onSelected={({ index, selected }) =>
          selected
            ? setSelectedPhotoIndexes(
              selectedPhotoIndexes.filter((i) => i !== index)
            )
            : setSelectedPhotoIndexes([...selectedPhotoIndexes, index])
        }
        selectedPhotoIndexes={selectedPhotoIndexes}
        hideBackdrop={true}
        maxHeight={'max-h-screen'}
        onClose={() => setShownPhotoIndex(-1)}
      />
      {selectedPhotoIndexes.length > 0 && modalOpen && (
        <>
          <div
            className="bg-tertiary w-3/4 h-14 fixed bottom-5 rounded-lg shadow-2xl flex justify-between items-center pl-6 pr-3"
            style={{ zIndex: 999 }}
          >
            <div className="text-sm font-bold text-white">
              {selectedPhotoIndexes.length} Photo
              {selectedPhotoIndexes.length > 1 ? 's' : ''} selected
            </div>
            <div className="text-base font-bold text-white flex">
              <BulkDownloadProjectPhotosModal
                afterDownload={() => {
                  setSelectedPhotoIndexes([]);
                }}
                downloadableUrls={assets
                  .filter((i, index) => selectedPhotoIndexes.includes(index))
                  .map(({ name, files, objectId, assetable_type }) => {
                    return {
                      url: files.original,
                      filename:
                        name || `${assetable_type.toUpperCase()}-${objectId}`,
                    };
                  })}
              />
              <div className="border-l border-gray-50 border-opacity-25 mx-3"></div>
              <button
                href="#"
                className="cursor-pointer hover:bg-gray-800 rounded-full p-3"
                onClick={() => setSelectedPhotoIndexes([])}
              >
                <FiX className="text-white" />
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default memo(ProjectPhotosModal);
