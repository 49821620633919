import { useEffect } from 'react';
import useApi from '../../../hooks/useApi';
import productsApi from '../../../api/products';
export default function useProducts(enabled: boolean = true) {
    const { data: { records: products, pagy }, loading, request: loadProducts } = useApi(productsApi.getProducts, { records: [], pagy: {} }, true);

    useEffect(() => {
        if (!enabled) return;
        loadProducts();
    }, [enabled])

    return {
        products,
        pagy,
        loading,
        loadProducts,
    }
}