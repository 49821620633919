import { useEffect, useState } from 'react';
import documentsApi from '../../api/documents';
import foldersApi from '../../api/folders';
import useApi from '../../hooks/useApi';
import useModal from '../../hooks/useModal';
import Modal, { ModalHeader } from '../utility/Modal';

const ConfirmDeleteDocumentModal = ({
  title,
  rowData,
  folderObjectId,
  projectObjectId,
  onDelete,
}) => {
  const { open: modalOpen, toggle: toggleModal } = useModal();

  const {
    data: deletedFolder,
    error,
    loading,
    request: deleteFolder,
  } = useApi(foldersApi.deleteFolder, null);

  const {
    data: deletedDocument,
    error: documentError,
    loading: documentLoading,
    request: deleteDocument,
  } = useApi(documentsApi.deleteDocument, null);

  const [errorMessage, setErrorMessage] = useState(null);

  const _toggleModal = (e) => {
    e.preventDefault();
    toggleModal();
    return false;
  };

  useEffect(() => {
    if ((deletedFolder || deletedDocument) && !error && !documentError) {
      toggleModal();
      onDelete();
    }
  }, [deletedFolder, deletedDocument]);

  useEffect(() => {
    if (error || documentError) {
      setErrorMessage(
        `Error deleting ${rowData.type}: ${rowData.name}, please try again.`
      );
    }
  }, [error, documentError]);

  const deleteEntityByType = async () => {
    setErrorMessage(null);

    switch (rowData.type) {
      case 'folder':
        await deleteFolder(projectObjectId, rowData.objectId);
        break;
      default:
        await deleteDocument(projectObjectId, folderObjectId, rowData.objectId);
        break;
    }
  };

  return (
    <>
      <a
        className="focus:outline-none font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3"
        rel="noreferrer"
        href="#"
        onClick={_toggleModal}
      >
        <span>Delete</span>
      </a>
      <Modal isOpen={modalOpen} onClose={toggleModal} modalClass={'rounded-lg'}>
        <ModalHeader title={title} onClose={toggleModal} />

        <div className="text-left text-base flex items-center justify-between px-5 py-3 bg-white">
          {`Are you sure you want to delete ${rowData.type}: ${rowData.name}?`}
        </div>

        <div className="modal-footer">
          {errorMessage ? (
            <div className="text-red-500 text-sm bg-white px-4">
              {errorMessage}
            </div>
          ) : null}
          <button
            className="modal-remove-btn"
            type="button"
            disabled={loading || documentLoading}
            onClick={async () => {
              await deleteEntityByType();
            }}
            data-testid="test-delete-btn"
          >
            Delete
          </button>
          <button
            className="modal-close-btn"
            type="button"
            disabled={loading || documentLoading}
            onClick={() => {
              toggleModal();
            }}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ConfirmDeleteDocumentModal;
