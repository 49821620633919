import { useState, useEffect, useRef } from 'react';
import { BiArchiveIn, BiArchiveOut } from 'react-icons/bi';
import mapLayersApi from '../../api/map_layers';
import useModal from '../../hooks/useModal';
import { AddExtraProps } from '../../utility/addExtraProps';
import Loader from '../utility/Loader';
import Modal, { ModalHeader, ModalSuccessView } from '../utility/Modal';
export default function ArchiveMapLayerButton({ button, mapLayer, onSaved }) {
  const {
    open: modalOpen,
    setOpen: setModalOpen,
    toggle: toggleOpen,
  } = useModal();
  const [saving, setSaving] = useState(false);
  const [archived, setArchived] = useState(mapLayer.archived_at !== null);
  const [saved, setSaved] = useState(false);
  const savedTimer = useRef(null);

  const archiveMapLayer = async () => {
    setSaving(true);
    await mapLayersApi.archiveMapLayer(mapLayer.objectId);
    setSaving(false);
    setSaved(true);
  };

  const unarchiveMapLayer = async () => {
    setSaving(true);
    await mapLayersApi.unarchiveMapLayer(mapLayer.objectId);
    setSaving(false);
    setSaved(true);
  };

  useEffect(() => {
    setArchived(mapLayer.archived_at !== null);
  }, [mapLayer.archived_at]);

  useEffect(() => {
    if (saved) {
      savedTimer.current = setTimeout(() => {
        setSaved(false);
        setModalOpen(false);
        onSaved?.();
      }, 2000);
    }
  }, [saved]);

  const buttonContents = () => {
    if (saving) return <Loader color="gray-900" size={'4'} />;
    return !archived ? <BiArchiveIn /> : <BiArchiveOut />;
  };

  const onButtonClick = (e) => {
    toggleOpen();
    e.preventDefault();
    e.stopPropagation();
    return false;
  };

  return (
    <>
      {button ? (
        AddExtraProps(button, { onClick: onButtonClick })
      ) : (
        <button
          data-testid="archive-map-layer-button"
          className="card-header-btn absolute top-2 right-2 py-2 px-2 hover:shadow hover:opacity-100"
          title={`${archived ? 'Una' : 'A'}rchive Map Layer`}
          type="button"
          onClick={onButtonClick}
        >
          {buttonContents()}
        </button>
      )}
      <Modal isOpen={modalOpen} onClose={toggleOpen}>
        <ModalHeader
          title={`${archived ? 'Una' : 'A'}rchive Map layer?`}
          onClose={toggleOpen}
        />
        {saved ? (
          <div className="p-5">
            <ModalSuccessView
              text={`Map Layer successfully ${archived ? 'unarchived' : 'archived'
                }.`}
            />
          </div>
        ) : (
          <div className="px-2 pt-2 text-base">
            <div className="p-5">
              <p></p>
              <div className="mb-4 font-semibold">
                Are you sure you want to {archived ? 'unarchive' : 'archive'} '
                {mapLayer?.name}' map layer?
              </div>
              <div>
                {archived
                  ? 'This will allow map layer to be used on project.'
                  : 'This will completely hide map layer from use on project.'}
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="modal-save-btn"
                type="button"
                onClick={archived ? unarchiveMapLayer : archiveMapLayer}
              >
                {archived ? 'Unarchive' : 'Archive'}
                {saving && <Loader className="ml-3" />}
              </button>
              <button
                className="modal-close-btn"
                type="button"
                disabled={saving !== false}
                onClick={() => {
                  setModalOpen(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
}
