import { useEffect } from 'react';
import useApi from '../../../hooks/useApi';
import pinTypesApi from '../../../api/pin_types';
import { PinType } from '../../../data/models';
import useSaved from '../../../hooks/useSaved';
export default function usePinType(pinTypeId: number | null, {
    load = true }: {
        load?: boolean
    } = {}): {
        latestPinType: PinType | null;
        pinType: PinType | null;
        error: any;
        loading: boolean;
        loadPinType: () => void;
        updatedPinType: PinType | null;
        errorUpdating: any;
        updating: boolean;
        updated: boolean;
        updatePinType: (pinType: any) => void;
        archivedPinType: PinType | null;
        errorArchiving: any;
        archiving: boolean;
        archived: boolean;
        archivePinType: () => void;
        unarchivedPinType: PinType | null;
        errorUnarchiving: any;
        unarchiving: boolean;
        unarchived: boolean;
        unarchivePinType: () => void;
    } {
    const {
        data: pinType,
        error,
        loading,
        request: loadPinType,
    } = useApi(pinTypesApi.getPinType, null, load);

    const {
        data: updatedPinType,
        error: errorUpdating,
        loading: updating,
        request: updatePinType,
    } = useApi(pinTypesApi.updatePinType, null);

    const {
        data: archivedPinType,
        error: errorArchiving,
        loading: archiving,
        request: archivePinType,
    } = useApi(pinTypesApi.archivePinType, null);

    const {
        data: unarchivedPinType,
        error: errorUnarchiving,
        loading: unarchiving,
        request: unarchivePinType,
    } = useApi(pinTypesApi.unarchivePinType, null);

    const _updatePinType = (pinType: any) => {
        if (pinTypeId) updatePinType(pinTypeId, pinType);
    }

    const _archivePinType = () => {
        if (pinTypeId) archivePinType(pinTypeId);
    }

    const _unarchivePinType = () => {
        if (pinTypeId) unarchivePinType(pinTypeId);
    }

    const _loadPinType = () => {
        if (pinTypeId) loadPinType(pinTypeId);
    }


    const { saved: updated } = useSaved(updatedPinType?.updated_at);
    const { saved: archived } = useSaved(archivedPinType?.updated_at);
    const { saved: unarchived } = useSaved(unarchivedPinType?.updated_at);

    useEffect(() => {
        if (!load) return;
        _loadPinType();
    }, [load, pinTypeId]);

    return {
        latestPinType: updatedPinType || pinType,
        pinType,
        error,
        loading,
        loadPinType: _loadPinType,
        updatedPinType,
        errorUpdating,
        updating,
        updated,
        updatePinType: _updatePinType,
        archivedPinType,
        archived,
        errorArchiving,
        archiving,
        archivePinType: _archivePinType,
        unarchivedPinType,
        errorUnarchiving,
        unarchiving,
        unarchived,
        unarchivePinType: _unarchivePinType,
    }
}