import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import pinsApi from '../../api/pins';
import useApi from '../../hooks/useApi';
import PinViewModel from '../../models/PinViewModel';
import { getTextColorFromBackground } from '../../styles/colors';
import { maxPinCount } from '../../utility/pinConfig';
import Loader from '../utility/Loader';
import FieldReportItem from './FieldReportItem';
export default function ReportTreePinsSummary({
  report,
  entryGroup,
  onPinsLoaded,
  onLoaded,
}) {
  const {
    data: { records: pins, pagy },
    error,
    loading,
    request: getPins,
  } = useApi(pinsApi.getPins, { records: [], pagy: {} }, true);
  const { report_id, shareable_id, share_id } = useParams();

  useEffect(() => {
    getPins('report', report_id || shareable_id, {
      items: maxPinCount,
      full: true,
      where: { status: null },
      share_id,
    });
  }, []);

  useEffect(() => {
    pins.length > 0 && onPinsLoaded && onPinsLoaded(pins);
  }, [pins]);

  useEffect(() => {
    !loading && onLoaded && onLoaded();
  }, [loading]);

  return (
    <div className="px-4 py-2">
      {loading && (
        <div className="pb-2">
          <Loader color="text-gray-700" margin="" />
        </div>
      )}
      {!loading && (
        <>
          {' '}
          {pins.map((p, index) => (
            <FieldReportItem
              key={`field_report_item_${p.identifier}`}
              title={p.identifier}
              note={p.latest_notes[0].text}
              includeBorder={index !== pins.length - 1}
            />
          ))}
        </>
      )}
    </div>
  );
}
