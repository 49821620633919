import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Modal, { ModalHeader, ModalSuccessView, ModalBody, ModalFooter } from '../utility/Modal';
import colors from '../../styles/colors';
import { GoPlus } from 'react-icons/go';
import { BiNote } from 'react-icons/bi';
import { HiOutlinePhotograph } from 'react-icons/hi';
import { useDropzone } from 'react-dropzone';
import Loader from '../utility/Loader';
import AddPhotoItem from './AddPhotoItem';
import notesApi from '../../api/notes';
import useApi from '../../hooks/useApi';
import { useParams } from 'react-router';
import useModal from '../../hooks/useModal';

function AddPhotoModal({ afterAdd }) {
  const {
    data: newNote,
    error: noteError,
    loading: adding,
    request: addNote,
  } = useApi(notesApi.addNote, null);

  const { project_id } = useParams();
  const {
    open: modalOpen,
    toggle: toggleModal,
    setOpen: setModalOpen,
  } = useModal();
  const [saveAsSiteNote, setSaveAsSiteNote] = useState(false);
  const [saving, setSaving] = useState(false);
  const [savedPhotos, setSavedPhotos] = useState([]);
  const [saved, setSaved] = useState(false);
  const savedTimer = useRef(null);
  const [description, setDescription] = useState(null);
  const [files, setFiles] = useState([]);

  const addNewNote = async () => {
    await addNote('project', project_id, {
      text: description,
      assets_attributes: savedPhotos.map((id) => {
        return { file: id };
      }),
    });
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFiles([...files, ...acceptedFiles]);
    },
    [files]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/jpeg, image/png',
    onDrop,
  });

  const updateDescription = (text) => {
    setDescription(text);
  };

  const removeImageAtIndex = (index) => {
    setFiles([...files.filter((f, i) => i !== index)]);
  };

  const savePhotos = (signedId) => {
    setSavedPhotos((prevPhotos) => [...prevPhotos, signedId]);
  };

  const resetModal = () => {
    setFiles([]);
    setSaving(false);
    setDescription(null);
  };

  useEffect(() => {
    if (
      savedPhotos.length > 0 &&
      savedPhotos.length === files.length &&
      saveAsSiteNote
    )
      addNewNote();
  }, [savedPhotos]);

  useEffect(() => {
    if (
      savedPhotos.length > 0 &&
      savedPhotos.length === files.length &&
      (!saveAsSiteNote || newNote)
    )
      savedTimer.current = setTimeout(() => setSaved(true), 2000);
  }, [savedPhotos, newNote]);

  useEffect(() => {
    if (saved) {
      resetModal();
      savedTimer.current = setTimeout(() => {
        setModalOpen(false);
        afterAdd?.();
        setTimeout(() => {
          setSaved(false);
        }, 500);
      }, 3500);
    }
  }, [saved]);

  const photoFiles = useMemo(() => <>
    {files.map((file, index) => {
      return (
        <AddPhotoItem
          key={`project_${project_id}_temp_image_${file.path}`}
          file={file}
          startSaving={saving}
          index={index}
          onSaved={savePhotos}
          passback={saveAsSiteNote}
          description={description}
          onRemove={removeImageAtIndex}
        />
      );
    })}
  </>, [saving, files.length])

  return (
    <>
      {/* Button */}
      <AddPhotoButton
        onClick={toggleModal}
      />
      <Modal isOpen={modalOpen} onClose={toggleModal} modalClass={'rounded-lg'}>
        <ModalHeader
          title={'Add Project Photo'}
          onClose={toggleModal}
          closeBtnTestId="button-close-add-photo-modal"
        />
        {saved ? (
          <ModalSuccessView
            text={
              saveAsSiteNote
                ? 'Site Note successfully added to project'
                : 'Photos successfully added to project'
            }
          />
        ) : (
          <ModalBody>
            {files.length > 0 && (
              <>
                <PhotosContainer>
                  {photoFiles}
                </PhotosContainer>
                <PhotoNoteSection
                  onChange={updateDescription}
                  placeholder={`Add a note about ${files.length === 1 ? 'this photo' : 'these photos'
                    }...`}
                  onSaveSiteNote={() => {
                    !(description === null || description.length === 0) &&
                      setSaveAsSiteNote(!saveAsSiteNote);
                  }}
                  saveAsSiteNote={saveAsSiteNote}
                  saveAsSiteNoteDisabled={description === null || description.length === 0}
                />
              </>
            )}
            <AssetDropzone
              disabled={saving !== false}
              getInputProps={getInputProps}
              getRootProps={getRootProps}
              dragActive={isDragActive}
            />
          </ModalBody>
        )}

        {!saved && <ModalFooter>
          <button
            className="modal-save-btn"
            type="button"
            data-testid="button-save-photo"
            disabled={files.length === 0 || saving !== false}
            onClick={() => setSaving(true)}
          >
            Save
            {saving !== false && <Loader className="ml-3" />}
          </button>
          <button
            className="modal-close-btn"
            type="button"
            disabled={saving !== false}
            onClick={() => {
              toggleModal();
              resetModal();
            }}
          >
            Cancel
          </button>
        </ModalFooter>}
      </Modal >
    </>
  );
}

const AddPhotoButton = ({ onClick }) => <a
  className="card-add-btn"
  onClick={onClick}
  data-testid="button-add-photo"
>
  <div className="w-full h-24 flex flex-col justify-center">
    <GoPlus size={16} color={colors.secondary} className="mb-1 mx-auto" />
    <p className="font-bold text-secondary text-center text-xs">
      ADD
      <br />
      PHOTO
    </p>
  </div>
</a>
const PhotosContainer = ({ children }) => <div className="pb-2 flex flex-wrap">{children}</div>
const AssetDropzone = ({ disabled, getInputProps, getRootProps, dragActive }) => <div
  className="cursor-pointer ring-0 text-base"
  {...getRootProps()}
  data-testid="dropzone-photo-file"
>
  <div
    disabled={disabled}
    className={`disabled:opacity-50 p-5 h-72 flex flex-col active:ring-0 items-center justify-center ${!dragActive
      ? 'border-2 border-gray-300'
      : 'border-3 border-secondary bg-blue-100'
      } border-dashed hover:opacity-80 rounded-md text-center`}
  >
    <input {...getInputProps()} data-testid="input-photo-file" />
    <HiOutlinePhotograph
      size={60}
      className={`mb-1 mx-auto ${!dragActive ? 'text-tertiary-light' : 'text-secondary'
        }`}
    />
    <div
      className={`font-semibold ${!dragActive ? 'text-tertiary' : 'text-secondary'
        }`}
    >
      Click to upload photos
      <br />
      or drag and drop
    </div>
  </div>
</div>

const PhotoNoteSection = ({ onChange, placeholder, saveAsSiteNote, saveAsSiteNoteDisabled, onSaveSiteNote }) => <div className="p-2 mb-4 border border-gray-200 rounded-md shadow bg-white">
  <div className="flex">
    <BiNote size={16} className="mt-2 text-gray-600" />
    <textarea
      type="text"
      data-testid="input-photo-note"
      autoFocus
      onChange={({ target: { value } }) =>
        onChange?.(value)
      }
      placeholder={placeholder}
      className="focus:ring-0 px-2 placeholder-gray-500 relative text-xs border-0 w-full border-0 outline-none focus:outline-none bg-white"
    />
  </div>
  <div
    className="flex items-center justify-end pt-3 cursor-pointer bg-white"
    onClick={onSaveSiteNote}
  >
    <p
      className={`mr-2 text-gray-500 font-medium text-sm ${saveAsSiteNoteDisabled ? 'opacity-50' : ''}`}
    >
      Save as site note
    </p>
    <div
      className={`form-switch focus-within:shadow-outline ${saveAsSiteNoteDisabled ? 'opacity-50' : ''}`}
    >
      <input
        type="checkbox"
        data-testid="checkbox-photo-as-site-note"
        id="sitenote"
        className="sr-only"
        checked={saveAsSiteNote}
      />
      <label className="bg-gray-300" htmlFor="sitenote">
        <span
          className="bg-white shadow-sm"
          aria-hidden="true"
        ></span>
        <span className="sr-only">Save as site note</span>
      </label>
    </div>
  </div>
</div>

export default memo(AddPhotoModal);
