import { useState, useRef, useEffect, useCallback } from 'react';
import Transition from '../../utils/Transition.js';
import { AiOutlineTags } from 'react-icons/ai';
import Tags from './Tags';
import tagsApi from '../../api/tags.js';
import { useParams } from 'react-router-dom';
import useApi from '../../hooks/useApi';
import Fuse from 'fuse.js';
import TagEditMenu from '../tags/TagEditMenu.js';
import TagInput from '../tags/TagInput.js';
import { BsThreeDots } from 'react-icons/bs';
import ClickBackdrop, { clickBackdropZindex } from '../utility/ClickBackdrop';
import TagColor from '../tags/TagColor.js';
import TagConfirmDeleteModal from '../tags/TagConfirmDeleteModal.js';

const fuseOptions = {
  includeScore: true,
  keys: ['name'],
};

const TagListItem = ({
  renaming,
  tag,
  onClick,
  onOptionsClick,
  onDestroy,
  onUpdate,
}) => {
  const saveTag = (newTag) => {
    onUpdate?.(newTag);
  };

  return (
    <li
      className="py-1 px-3 border-b cursor-pointer hover:bg-gray-50"
      key={tag.id}
      data-tag-id={tag.id}
    >
      <div className="flex items-center justify-between" data-tag-id={tag.id}>
        <span
          className="text-sm font-medium hover:opacity-70 w-full"
          onClick={() => onClick(tag)}
          data-tag-id={tag.id}
          style={{ color: tag?.color }}
        >
          {renaming ? <TagInput tag={tag} onUpdate={saveTag} /> : tag.name}
        </span>
        {!renaming && (onUpdate || onDestroy) && (
          <div className="relative">
            <div
              className={
                'flex focus:outline-none h-full px-1 text-opacity-50 hover:text-opacity-100 items-center justify-center rounded-full'
              }
              style={{ color: tag?.color }}
              onClick={(e) => onOptionsClick({ e, tag })}
            >
              <BsThreeDots size={18} />
            </div>
          </div>
        )}
      </div>
    </li>
  );
};
const NewTagListItem = ({ name, onClick }) => {
  return (
    <div
      className="py-1 px-3 border cursor-pointer hover:bg-blue-50 text-xs text-center text-gray-900 font-semibold shadow"
      key={'addNewTag'}
      onClick={() => onClick(name)}
    >
      Add new tag "{name}"
    </div>
  );
};

function TagsDropdown({
  align,
  tags,
  allowCreate = true,
  optionMenuOffset = 10,
  onAdd,
  onClearTags,
  onDestroy,
  onRemove,
  onUpdate,
  maxListHeight = 'max-h-60',
  taggableType,
  triggerTestId = 'tagsDropdownTrigger',
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);
  const [activeOptionTarget, setActiveOptionTarget] = useState();

  const trigger = useRef(null);
  const dropdown = useRef(null);
  const dropdownContainerRef = useRef(null);
  const input = useRef(null);
  const { workspace_id } = useParams();
  const {
    data: { records: workspaceTags },
    request: getTags,
  } = useApi(tagsApi.getTags, {
    records: [],
    pagy: {},
  });

  const fuse = useCallback(
    () => new Fuse(workspaceTags, fuseOptions),
    [workspaceTags.map(({ id }) => id).join('')]
  );

  const tagIds = useCallback(() => {
    return tags.map((tag) => tag.id);
  }, [tags]);

  const filteredWorkspaceTags = filteredResults?.filter(
    ({ id }) => !tagIds().includes(id)
  );

  const _onDestroy = async (tag) => {
    await onDestroy?.(tag);
    getTags(workspace_id);
  };

  const onDestroyTag = async (tag) => {
    await tagsApi.destroyTagOnWorkspace(workspace_id, tag.id);
    setActiveOptionTarget();
    getTags(workspace_id);
  };

  const onUpdateTag = async (tag) => {
    await tagsApi.updateTagOnWorkspace(workspace_id, tag.id, tag);
    setFilteredResults((f) => [...f.map((t) => (t.id === tag.id ? tag : t))]);
    setActiveOptionTarget();
    getTags(workspace_id);
  };

  const addNewTag = async (name) => {
    const {
      data: { records: tags_with_colors },
    } = await tagsApi.addTagsToWorkspace(workspace_id, name);
    onAdd?.(
      tags_with_colors.find(
        ({ name: newTagName }) => newTagName === name.toLowerCase()
      )
    );
    setTimeout(() => {
      setSearch('');
      if (input.current) input.current.value = '';
    }, 1);
    getTags(workspace_id);
  };

  const onKeyDown = async (e) => {
    const key = e.key || e.keyCode;
    const { value: name } = e.target;

    if ((key === 'Enter' || key === ',' || key === 13) && !!name) {
      await addNewTag(name);
    }
  };

  const onSearch = ({ target: { value } }) => {
    setSearch(value);
    setFilteredResults(
      fuse()
        .search(value)
        .map(({ item }) => item)
    );
  };

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {
    setFilteredResults(workspaceTags);
  }, [workspaceTags.map(({ id }) => id).join('')]);

  useEffect(() => {
    if (!dropdownOpen) return;
    getTags(workspace_id, { taggable_type: taggableType });
  }, [dropdownOpen]);

  const offsetTop = () => {
    if (!dropdownContainerRef.current) return 0;
    return (
      activeOptionTarget.e.clientY -
      dropdownContainerRef.current.getBoundingClientRect().top -
      optionMenuOffset
    );
  };

  const showCreateButton =
    !!search && !workspaceTags.find(({ name }) => name === search) && allowCreate;

  return (
    <>
      {dropdownOpen && (
        <ClickBackdrop
          testId="tagsDropdownBackdrop"
          onClick={() => {
            setDropdownOpen(false);
          }}
        />
      )}

      <div className="relative inline-flex" ref={dropdownContainerRef}>
        <button
          data-testid={triggerTestId}
          ref={trigger}
          className="text-xs inline-flex font-medium rounded-full text-center p-1 border border-dashed border-gray-300 bg-white mr-1"
          title="Edit Tags"
          aria-haspopup="true"
          onClick={() => setDropdownOpen(!dropdownOpen)}
          aria-expanded={dropdownOpen}
        >
          <AiOutlineTags />
        </button>
        <Transition
          show={dropdownOpen}
          tag="div"
          className={`origin-top-right ${clickBackdropZindex} absolute top-full w-64 bg-white border border-gray-200 rounded shadow-lg ${align === 'right' ? 'right-0' : 'left-0'
            }`}
          enter="transition ease-out duration-200 transform"
          enterStart="opacity-0 -translate-y-2"
          enterEnd="opacity-100 translate-y-0"
          leave="transition ease-out duration-200"
          leaveStart="opacity-100"
          leaveEnd="opacity-0"
        >
          <div ref={dropdown}>
            {!!tags.length && (
              <Tags
                tags={tags}
                tagSize="sm"
                containerClassName="bg-blue-50 pb-1 pt-2 border-b px-2 mt-0 flex flex-wrap items-center max-w-64 h-auto"
                tagClassName="mr-1 mb-1"
                onDestroy={_onDestroy}
                onRemove={onRemove}
                onUpdate={onUpdate}
              />
            )}
            <input
              type="text"
              data-testId="tagsDropdownSearchInput"
              className="form-input w-full border-0 bg-gray-50 rounded-0 outline-none focus:outline-none focus:ring-0 focus:border-gray-300"
              placeholder={
                allowCreate ? 'Search or Create New tags' : 'Search tags'
              }
              onChange={onSearch}
              onKeyDown={onKeyDown}
              ref={input}
            />
            {showCreateButton && (
              <NewTagListItem name={search} onClick={addNewTag} />
            )}
            {!!filteredWorkspaceTags.length && (
              <ul
                className={`${maxListHeight} ${activeOptionTarget ? 'overflow-y-hidden' : 'overflow-y-auto'
                  }`}
              >
                {filteredWorkspaceTags.map((workspaceTag) => {
                  return (
                    <TagListItem
                      key={`workspace-tag-${workspaceTag.id}`}
                      tag={
                        activeOptionTarget?.tag?.id === workspaceTag.id
                          ? activeOptionTarget.tag
                          : workspaceTag
                      }
                      renaming={
                        activeOptionTarget?.tag?.id === workspaceTag.id &&
                        activeOptionTarget.optionSetting === 'rename'
                      }
                      onClick={onAdd}
                      onOptionsClick={setActiveOptionTarget}
                      onDestroy={onDestroy && onDestroyTag}
                      onUpdate={onUpdate && onUpdateTag}
                    />
                  );
                })}
              </ul>
            )}
            {activeOptionTarget && !activeOptionTarget.optionSetting && (
              <>
                <ClickBackdrop
                  testId="tagsOptionsDropdownBackdrop"
                  onClick={() => {
                    setActiveOptionTarget();
                  }}
                />
                <TagEditMenu
                  tag={activeOptionTarget.tag}
                  color={activeOptionTarget.tag?.color}
                  onUpdate={onUpdate}
                  onDestroy={_onDestroy}
                  onOptionChosen={(optionSetting) =>
                    setActiveOptionTarget((a) => ({ ...a, optionSetting }))
                  }
                  onDropdown
                  style={{ top: `${offsetTop()}px` }}
                />
              </>
            )}
            {activeOptionTarget?.optionSetting === 'color' && (
              <TagColor
                tag={activeOptionTarget.tag}
                onUpdate={() => onUpdateTag(activeOptionTarget.tag)}
                onDropdown
                onColorChange={(color) =>
                  setActiveOptionTarget(({ e, tag, optionSetting }) => ({
                    e,
                    tag: { ...tag, color },
                    optionSetting,
                  }))
                }
                style={{ top: `${offsetTop()}px` }}
              />
            )}
            <TagConfirmDeleteModal
              tag={activeOptionTarget?.tag}
              open={activeOptionTarget?.optionSetting === 'delete'}
              onDestroy={() => onDestroyTag(activeOptionTarget?.tag)}
              onClose={() => setActiveOptionTarget()}
            />
            {filteredWorkspaceTags.length === 0 && allowCreate && (
              <div className="text-xxs text-gray-500 text-center py-2 bg-gray-50">
                Type Enter or comma to add tag.
              </div>
            )}

            {onClearTags && (
              <div className="py-2 px-3 border-t border-gray-200 bg-gray-50">
                <ul className="flex items-center justify-between">
                  <li>
                    <button
                      className="btn-xs bg-white border-gray-200 hover:border-gray-300 text-gray-500 hover:text-gray-600"
                      onClick={onClearTags}
                    >
                      Clear
                    </button>
                  </li>
                  <li>
                    <button
                      className="btn-xs bg-secondary hover:bg-tertiary text-white"
                      onClick={() => {
                        setDropdownOpen(false);
                      }}
                      onBlur={() => setDropdownOpen(false)}
                    >
                      Done
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </Transition>
      </div>
    </>
  );
}

export default TagsDropdown;
