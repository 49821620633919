import { useParams } from 'react-router-dom';
import DeleteProjectModal from './DeleteProjectModal';
import useProject from './hooks/useProject';
import Loader from '../utility/Loader';
import { BiCheckCircle } from 'react-icons/bi';
import { PinNoteSettings } from '../workspaces/WorkspaceForm';
import useFeatures from '../../hooks/useFeatures';
import { useEffect } from 'react';
import useModal from '../../hooks/useModal';
import Button from '../shared/Button';
import Page from '../shared/Page';
import EmptyState from '../utility/EmptyState';
import { ConfirmationModal } from '../utility/Modal';
import useProjectContext from '../../hooks/useProjectContext';
import { Project } from '../../data/models';
export default function ProjectSettings() {
  const {
    features,
  } = useFeatures();

  const { project, setProject } = useProjectContext();

  if (!project) return <div className="flex py-5 justify-center">
    <Loader color="black" />
  </div>;

  return (
    <Page.Page>
      {features?.rollout?.project_pin_settings && <ProjectPinSettings project={project} setProject={setProject} />}
      {features?.rollout?.max_project_limit && <ProjectActiveSetting project={project} setProject={setProject} />}
      <ProjectDelete />
    </Page.Page>
  );
}

const ProjectDelete = () => <div className="bg-white border-b border-gray-200 p-5">
  <div className="font-semibold text-xl">Remove Project</div>
  <div className="pt-3 pb-5 text-sm">
    Delete this project permanently along with all its associated data.
    This action cannot be undone.
  </div>
  <DeleteProjectModal />
</div>

const ProjectActiveSetting = ({ project, setProject }: {
  project: Project
  setProject?: (project: Project) => void
}) => {

  const { project_id } = useParams<{ project_id: string }>();
  const { open, setOpen } = useModal();
  const {
    updateProject,
    updating,
    updated,
    updatedProject,
  } = useProject(project_id, {
    load: false
  });

  useEffect(() => {
    if (!updated || !updatedProject) return;
    setTimeout(() => {
      setOpen(false)
      setProject?.(updatedProject);
      window.location.reload();
    }, 1000);
  }, [updated, updatedProject])

  if (!project) return <div className="flex py-5 justify-center">
    <Loader color="black" />
  </div>;

  return <>
    <div className="bg-white border-b border-gray-200 p-5 mb-4">
      <div className="font-semibold text-xl">Project Status</div>
      {project?.active ? <div className="pt-3 pb-5 text-sm">
        Inactivate project to prevent further data entry.
        This will allow you to create more projects.
      </div> : <div className="pt-3 pb-5 text-sm">
        Activate project to allow data entry.
      </div>}
      <Button data-testid="projectActiveToggle" onClick={() => setOpen(true)} color={project?.active ? 'secondary' : 'light'} text={!project?.active ? "Activate Project" : "Deactivate Project"} />
    </div>
    <ConfirmationModal
      isOpen={open}
      onClose={() => setOpen(false)}
      buttonTestId='settingsProjectActivate'
      title=""
      body={
        project?.active ? <EmptyState
          heading={<>Deactivate <span className="bg-blue-100 py-1 px-2 rounded mr-1">{project?.identifier} {project?.name}</span>?</>}
          subHeading={<div className="pt-2">
            <p className="font-medium">This will block any further additions to the project.</p>
            <p className="text-secondary font-semibold">You will still be allowed to download project data.</p>
          </div>}
          hasButton={false}
        /> : <EmptyState
          heading={<>Activate <span className="bg-blue-100 py-1 px-2 rounded mr-1">{project?.identifier} {project?.name}</span>?</>}
          subHeading={<div className="pt-2">
            <p className="font-medium">This will unblock additions to the project.</p>
          </div>}
          hasButton={false}
        />}
      onConfirm={() => updateProject({ active: !project?.active })}
      confirming={updating}
      confirmed={updated}
      buttonText={project?.active ? "Deactivate Project" : "Activate Project"}
    />
  </>
}

const ProjectPinSettings = ({ project, setProject }: {
  project: Project
  setProject?: (project: Project) => void
}) => {

  const { project_id } = useParams<{ project_id: string }>();

  const {
    loading,
    updateProject,
    updatedProject,
    updating,
    updated
  } = useProject(project_id, {
    load: false
  });

  const onUpdate = (field: string, value: any) => {
    updateProject({
      [field]: value,
    });
  }

  useEffect(() => {
    if (!updatedProject) return;
    setProject?.(updatedProject);
  }, [updatedProject])

  return <div className="bg-white border-b border-gray-200 p-5">
    <div className="font-semibold text-xl flex items-center">Pin Settings
      {updating && <Loader color="black" size="4" />}
      {(updated && !updating) && <BiCheckCircle
        size={16}
        className="ml-2 bg-green-500 text-white rounded-full overflow-hidden"
      />}
    </div>
    <div className="pt-3 pb-5 text-sm">
      Update pin requirements for this project.
    </div>

    {(loading || !project) ? <Loader color='bg-black' /> :
      <PinNoteSettings
        testIdPrefix={'project'}
        testIdSuffix={project?.objectId}
        pinsSetting={project?.settings?.pins}
        onUpdate={(newSetting) => onUpdate(
          'settings',
          {
            ...project.settings,
            pins: newSetting
          }
        )}
      />}
  </div >;
}
