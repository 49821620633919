import client from './client';
const endpoint = '/access_codes';

const getAccessCodes = (params = {}) => client.get(`${endpoint}`, params);
const getAccessCode = (id, params = {}) =>
  client.get(`${endpoint}/${id}`, params);
const addAccessCode = (access_code) =>
  client.post(`${endpoint}`, { access_code });
const updateAccessCode = (id, access_code) =>
  client.put(`${endpoint}/${id}`, { access_code });

export default {
  getAccessCodes,
  getAccessCode,
  addAccessCode,
  updateAccessCode,
};
