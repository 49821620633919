import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import EntryViolation from '../entries/EntryViolation';
import pinsApi from '../../api/pins';
import useApi from '../../hooks/useApi';
import { maxPinCount } from '../../utility/pinConfig';

const entryGroupViolationsComponents = {
  EntryViolation: EntryViolation,
};

export default function EntryGroupViolations({
  editing = true,
  entries,
  onLoaded,
}) {
  const {
    data: { records: pins, pagy },
    error,
    loading,
    request: getPins,
  } = useApi(pinsApi.getPins, { records: [], pagy: {} }, true);
  const { report_id, share_id, shareable_id } = useParams();

  useEffect(() => {
    getPins('report', report_id || shareable_id, {
      items: maxPinCount,
      share_id,
    });
  }, []);

  useEffect(() => {
    onLoaded && onLoaded();
  }, []);

  return (
    <div className="text-xxs leading-tight">
      <div className="border-b border-gray-500 grid grid-cols-12 font-bold text-center bg-gray-300 py-2 leading-tight">
        <div>Ref</div>
        <div className="col-span-7">Activity</div>
        <div>YES</div>
        <div>NO</div>
        <div>N/A</div>
        <div>Action Item #</div>
      </div>
      {entries.map((entry, index) => {
        const EntryViolationComponent =
          entryGroupViolationsComponents[entry.component_name];
        return (
          <EntryViolationComponent
            editing={editing}
            entry={entry}
            pins={pins}
          />
        );
      })}
    </div>
  );
}
