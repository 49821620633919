import { Entry } from '../../data/models';
import EntryInput from './EntryInput';
import EntryLabel from './EntryLabel';
import useEntry from './hooks/useEntry';

export default function EntryTextInput({
  entry: passedEntry,
  editing = true,
  useBottomBorder,
}: {
  entry: Entry;
  editing?: boolean;
  useBottomBorder?: boolean;
}) {

  const { saveEntryDebounced, saved, savedEntry, saving } = useEntry(passedEntry.objectId, {
    load: false,
  })

  const updateEntry = (e: React.ChangeEvent<HTMLInputElement>) => {
    saveEntryDebounced({ value: e.target.value });
  };

  return (
    <>
      <EntryLabel
        entry={savedEntry || passedEntry}
        useBottomBorder={useBottomBorder}
        saved={saved}
        saving={saving}
      />
      <EntryInput
        entry={savedEntry || passedEntry}
        onChange={updateEntry}
        disabled={!editing}
        useBottomBorder={useBottomBorder}
      />
    </>
  );
}
