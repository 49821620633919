import { useState, useEffect, useRef } from 'react';
import authApi from '../../api/auth';
import useAuth from '../../auth/useAuth';
import useQuery from '../../hooks/useQuery';
import ErrorViewModel from '../../models/ErrorViewModel';
import ErrorView from '../utility/ErrorView';
import Loader from '../utility/Loader';
import { ModalSuccessView } from '../utility/Modal';
export default function ConfirmationForm({ onConfirmed }) {
  const { user, logIn } = useAuth();
  const query = useQuery();
  const confirmation_token = query.get('token');
  const email = query.get('email');
  const [confirming, setConfirming] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [error, setError] = useState(false);

  const confirmEmail = async () => {
    setConfirming(true);

    let response = await authApi.confirmEmail(confirmation_token);

    setConfirming(false);

    if (!response.ok) {
      let { data } = response;
      return setError(new ErrorViewModel(data));
    }

    let user = await logIn(response);
    setConfirmed(true);
    setTimeout(() => onConfirmed?.(user), 2500);
  };

  useEffect(() => {
    confirmation_token && (!confirmed || confirming) && confirmEmail();
  }, [confirmation_token]);

  const [resending, setResending] = useState(false);
  const [resent, setResent] = useState(false);
  const resentTimer = useRef(null);

  const resendConfirmation = async () => {
    setResending(true);
    await authApi.resendConfirmation(email);
    setResent(true);
    setResending(false);
  };

  useEffect(() => {
    if (resent) {
      resentTimer.current = setTimeout(() => {
        setResent(false);
      }, 4000);
    }
  }, [resent]);

  const resendEmailText = () => {
    if (resending) return 'Sending...';
    if (resent) return 'Sent!';
    return 'Resend email';
  };

  return (
    <div className="max-w-md mx-auto text-center">
      {error ? (
        <div className="text-center">
          <h1 className="text-3xl text-gray-800 font-bold mb-4">Uh oh...</h1>
          <ErrorView error={error} extraClass={'mb-4'} />
        </div>
      ) : (
        <>
          {!confirmed && (
            <h1 className="text-3xl text-gray-800 font-bold mb-4 flex items-center justify-center">
              {confirming ? (
                <>
                  <span>Confirming... </span>
                  <span>
                    <Loader color="black" />
                  </span>
                </>
              ) : (
                'Confirm your email'
              )}
            </h1>
          )}
          {confirmed ? (
            <ModalSuccessView text={'Email successfully confirmed'} />
          ) : (
            <>
              <h1 className="text-xl text-gray-600 mb-2">
                We sent an email to{' '}
                <strong className="text-tertiary">
                  {email || 'your email'}
                </strong>
              </h1>
              <p className="text-black mb-6">
                Open and click through to <em>confirm your account</em>.
              </p>
              <div className="text-sm">
                Nothing in your inbox?{' '}
                <button
                  className="font-medium text-tertiary hover:text-secondary cursor-pointer"
                  data-testid="action-resend-confirmation"
                  onClick={resendConfirmation}
                  disabled={resending}
                >
                  {resendEmailText()}
                </button>
              </div>
              <div className="text-xs italic font-medium text-tertiary">
                And remember to check your spam folder
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}
