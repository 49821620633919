import { useCallback, useEffect, useState } from 'react';
import Modal, { ConfirmationModal, ModalBody, ModalFooter, ModalHeader, ModalSuccessView } from '../utility/Modal';
import ErrorView from '../utility/ErrorView';
import ErrorViewModel from '../../models/ErrorViewModel';
import Loader from '../utility/Loader';
import AddPhotoItem from '../project/AddPhotoItem';
import { useParams } from 'react-router';
import useNote from './hooks/useNote';
import Form from '../../utils/Form';
import AssetUpdateItem from '../assets/AssetUpdateItem';
import timings from '../../styles/timings';
import PhotosModal from '../utility/PhotosModal';
import MapFileToAsset from '../assets/utils/MapFileToAsset';
import { Flex } from '../../utils/Layout';
import { FiTrash2 } from 'react-icons/fi';
import useAuth from '../../auth/useAuth';
import Badge from '../shared/Badge';

function NoteEditModal({
    noteId,
    onClose,
    afterEdit,
    afterDelete
}: {
    noteId: string | null,
    onClose: () => void,
    afterEdit?: (n: any) => void
    afterDelete?: () => void
}) {
    const {
        note,
        deleted,
        deleting,
        deleteNote,
        updateNote,
        updateError,
        updatedNote,
        updated,
        updating
    } = useNote(noteId)

    const { project_id } = useParams<{ project_id: string }>();
    const { user } = useAuth();
    const [deletingNote, setDeletingNote] = useState(false);
    const [uploadingPhotos, setUploadingPhotos] = useState(false);
    const [savedPhotos, setSavedPhotos] = useState<string[]>([]);
    const [shownPhotoIndex, setShownPhotoIndex] = useState(-1);
    const [removedAssets, setRemovedAssets] = useState<number[]>([]);
    const [description, setDescription] = useState<string | undefined>();
    const [files, setFiles] = useState<any[]>([]);

    const submitNote = async (photos: string[]) => {
        await updateNote({
            text: description,
            assets_attributes: [
                ...photos.map((file) => {
                    return { file };
                }),
                ...removedAssets.map((id) => {
                    return { id, _destroy: true };
                })
            ],
        });
    };

    const filesWithAssets = [...files.map(MapFileToAsset), ...note?.assets || []];

    const onDrop = useCallback(
        (acceptedFiles: any) => {
            setFiles([...files, ...acceptedFiles]);
        },
        [files]
    );

    const updateDescription = (text: string) => {
        setDescription(text);
    };

    const removeImageAtIndex = (index: number) => {
        setFiles([...files.filter((f, i) => i !== index)]);
    };

    const savePhotos = (signedId: string) => {
        const newSavedPhotos = [...savedPhotos, signedId];
        setSavedPhotos([...savedPhotos, signedId]);
        if (newSavedPhotos.length === files.length) submitNote(newSavedPhotos);
    };

    const closeModal = () => {
        setFiles([]);
        setSavedPhotos([]);
        setRemovedAssets([]);
        setUploadingPhotos(false);
        onClose?.();
        if (updatedNote) afterEdit?.(updatedNote);
    }

    useEffect(() => {
        setDescription(note?.text);
    }, [note?.objectId, note?.updated_at])

    useEffect(() => {
        if (!updated && !deleted) return;
        if (deleted) afterDelete?.();

        setTimeout(() => {
            setDeletingNote(false);
            closeModal();
        }, timings.normal);
    }, [updated, deleted]);

    return <>
        <Modal isOpen={!!noteId} onClose={closeModal}>
            <ModalHeader
                title="Update Note"
                onClose={closeModal}
                closeBtnTestId="buttonCloseNoteEditModal"
                sticky
            />
            {updateError && <ErrorView error={new ErrorViewModel(updateError)} />}
            {updated ? (
                <ModalSuccessView text={'Note successfully updated'} />
            ) : (
                <>
                    <ModalBody>
                        <>
                            <Form.Group>
                                <Form.TextArea
                                    mb={0}
                                    placeholder="Add a note ..."
                                    value={description}
                                    rows={4}
                                    onChange={({ target: { value } }) =>
                                        updateDescription(value)
                                    }
                                    data-testid="inputEditSiteNote"
                                />
                            </Form.Group>
                        </>
                        <div className="pb-2 flex flex-wrap">
                            {files.map((file, index) => {
                                return (
                                    <AddPhotoItem
                                        key={`project_${project_id}_temp_site_note_image_${file.path}`}
                                        file={file}
                                        startSaving={uploadingPhotos}
                                        index={index}
                                        onSaved={savePhotos}
                                        passback={true}
                                        description={description}
                                        onSelect={() => setShownPhotoIndex(index)}
                                        onRemove={removeImageAtIndex}
                                        locationRequired={undefined}
                                        onLocated={undefined} />
                                );
                            })}
                            {note?.assets?.map?.((asset: any, index: number) => {
                                const markedForRemoval = removedAssets.includes(asset.id);
                                return <AssetUpdateItem
                                    key={`asset-${asset.id}-${index}-${markedForRemoval}`}
                                    markedForRemoval={markedForRemoval}
                                    asset={asset}
                                    onSelect={() => setShownPhotoIndex(files.length + index)}
                                    onCancelRemove={() => setRemovedAssets((as) => [...as.filter((id) => id !== asset.id)])}
                                    onRemove={() => setRemovedAssets((as) => ([...as, asset.id]))}
                                />
                            })}
                        </div>
                        <Form.FileDropzone accept="image/jpeg, image/png" onDrop={onDrop} />
                    </ModalBody>

                    <ModalFooter sticky justify='justify-between'>
                        {(user?.id === note?.notetaker?.id) && <button
                            className="modal-remove-btn"
                            type="button"
                            data-testid="buttonDeleteSiteNote"
                            disabled={deleting || updating || uploadingPhotos}
                            onClick={() => setDeletingNote(true)}
                        >
                            <FiTrash2 className='mr-2' /> Delete {deleting && <Loader className="ml-3" />}
                        </button>}
                        <Flex>
                            <button
                                className="modal-save-btn"
                                type="button"
                                data-testid="buttonSaveSiteNote"
                                disabled={deleting || (description === note?.text && !removedAssets.length && !files.length) || updating || uploadingPhotos}
                                onClick={() => !!files.length ? setUploadingPhotos(true) : submitNote([])}
                            >
                                Save
                                {(updating || uploadingPhotos) && <Loader className="ml-3" />}
                            </button>
                            <button
                                className="modal-close-btn"
                                type="button"
                                disabled={deleting || updating || uploadingPhotos}
                                onClick={closeModal}
                            >
                                Cancel
                            </button>
                        </Flex>
                    </ModalFooter>
                </>
            )}
        </Modal>
        <PhotosModal
            isOpen={shownPhotoIndex > -1}
            assets={filesWithAssets}
            activeIndex={shownPhotoIndex}
            hideBackdrop
            onClose={() => setShownPhotoIndex(-1)}
        />
        <ConfirmationModal
            confirmed={deleted}
            confirming={deleting}
            isOpen={deletingNote}
            title="Confirm delete"
            body={<>
                <div className="flex absolute right-5 top-6"><Badge dot={false} padding='py-0.5 px-2 shadow-md' label={note?.reported_on ? `In use on report(s)` : 'Not currently attached to any reports'} style={note?.reported_on ? 'danger' : 'info'} title={note?.reported_on ? `In use on report(s)` : 'Not attached to any reports'} /></div>
                <p className="mb-1 mt-4">This will remove note from <span className="font-semibold">any reports it's attached on.</span></p>
            </>}
            onClose={() => setDeletingNote(false)}
            onConfirm={deleteNote}
        />
    </>;
}

export default NoteEditModal;
