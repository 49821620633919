import { memo } from 'react';
import useModal from '../../hooks/useModal';
import Modal, { ModalHeader } from '../utility/Modal';

const ArchiveModal = ({ onConfirmArchive, row, errorMessage }) => {
  const {
    open: modalOpen,
    toggle: toggleModal,
    setOpen: setModalOpen,
  } = useModal();

  return (
    <>
      <a
        className="focus:outline-none font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3"
        rel="noreferrer"
        onClick={(e) => toggleModal()}
      >
        Archive
      </a>
      <Modal isOpen={modalOpen} onClose={toggleModal} modalClass={'rounded-lg'}>
        <ModalHeader title={`Archive ${row.name}`} onClose={toggleModal} />

        {errorMessage && errorMessage.errors && (
          <div className="text-sm font-semibold bg-red-500 text-white w-full text-center px-2 py-1 rounded-md">
            {errorMessage.errors[0]}
          </div>
        )}

        <div className="text-left text-base px-5 py-3 bg-white">
          <span className="mr-1">{`Are you sure you want to archive ${row.name}?`}</span>
          <b>{`This ${row.type !== 'folder' ? 'document' : row.type
            } will be deleted in 90 days.`}</b>
        </div>

        <div className="modal-footer">
          <button
            className="modal-save-btn"
            type="button"
            onClick={() => onConfirmArchive()}
            data-testid="test-archive-btn"
          >
            Archive
          </button>
          <button
            className="modal-close-btn"
            type="button"
            onClick={() => {
              toggleModal();
            }}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};

export default memo(ArchiveModal);
