import { useEffect } from 'react';
import fieldOptionsApi from '../../../api/field_options';
import useApi from '../../../hooks/useApi';
export default function useFieldOptions(fieldId: number | null) {
    const {
        data: fieldOptions,
        error,
        loading,
        request: getFieldFieldOptions,
    } = useApi(fieldOptionsApi.getFieldFieldOptions, [], true);

    const reloadFieldOptions = () => {
        if (!fieldId) return;
        getFieldFieldOptions(fieldId, { items: 100 });
    }

    useEffect(() => {
        if (!fieldId) return;
        getFieldFieldOptions(fieldId, { items: 100 });
    }, [fieldId])

    return {
        fieldOptions,
        error,
        loading,
        getFieldFieldOptions,
        reloadFieldOptions,
    }
}