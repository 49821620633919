import * as React from 'react';
import { Link } from 'react-router-dom';
export default function AddButton({
    onClick,
    children = 'Add',
    to,
    testId,
}: {
    children: React.ReactNode
    onClick?: () => void
    to?: string
    testId?: string
}) {
    return <WrapperComponent
        to={to}
    >
        <div
            className="btn rounded cursor-pointer text-white hover:text-gray-50 hover:opacity-90 bg-secondary"
            data-testid={testId}
            onClick={onClick}
        >
            <svg
                className="w-4 h-4 fill-current opacity-50 flex-shrink-0"
                viewBox="0 0 16 16"
            >
                <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
            </svg>
            <span className="hidden xs:block ml-2">{children}</span>
        </div>
    </WrapperComponent>
}

const WrapperComponent = ({ children, to }: {
    to?: string
    children: any
    onClick?: () => void
}) => {
    if (to) {
        return <Link to={to}>{children}</Link>
    }

    return <>
        {children}
    </>
}
