import { useState, useEffect } from 'react';
import Modal, { ModalHeader } from '../utility/Modal';
import { useParams } from 'react-router-dom';
import Loader from '../utility/Loader';
import projectMembersApi from '../../api/project_members';
import DelayedSearchInput, { SearchContainer } from '../utility/DelayedSearchInput';
import useApi from '../../hooks/useApi';
import AddPinDropperItem from './AddPinDropperItem';
import ErrorViewModel from '../../models/ErrorViewModel';
import useModal from '../../hooks/useModal';
import ErrorView from '../utility/ErrorView';

const AvatarSizes = {
    "7": "w-7 h-7",
    "5": "w-5 h-5",
    "3": "w-3 h-3",
}
export default function PinDroppersModal({
    open,
    onClose,
    chosenIds = [],
    onChosen,
}: {
    open: boolean,
    onClose: () => void,
    chosenIds: number[],
    onChosen: (member: { id: number }, chosen: boolean) => void
}) {
    const {
        data: { records: members, pagy },
        error: errorMembers,
        loading,
        request: loadProjectMembers,
    } = useApi(
        projectMembersApi.getProjectMembers,
        { records: [], pagy: {} },
        true
    );
    const [error, setError] = useState(null);
    const [searchParams, setSearchParams] = useState({
        page: 1,
        q: '',
        items: 20,
        has_updated: true,
    });
    const { project_id } = useParams<{ project_id: string }>();

    useEffect(() => {
        loadProjectMembers(project_id, searchParams);
    }, [searchParams]);

    return <Modal isOpen={open} onClose={onClose}>
        <ModalHeader title={'Update Pin Droppers'} onClose={onClose} />
        {error && (
            <ErrorView error={error} />
        )}
        {pagy?.count > 3 && <SearchContainer>
            <DelayedSearchInput
                key={`searching_for_pin_droppers_${project_id}_open_${open}`}
                placeholder="Search Team Members..."
                defaultValue={searchParams.q}
                onSearched={(text: string) => {
                    setSearchParams({ ...searchParams, page: 1, q: text });
                }}
            />
        </SearchContainer>}
        {(!loading || members.length > 0) && (
            <>
                {members.length === 0 && searchParams.q.trim() === '' && (
                    <p className="empty-data-txt text-center">
                        All projects members being used for report
                    </p>
                )}
                {members.length === 0 && searchParams.q.trim() !== '' && (
                    <p className="empty-data-txt text-center">
                        No member matching{' '}
                        <span className="italic mr-1 font-medium">
                            '{searchParams.q}'
                        </span>{' '}
                        found <br /> that can be added as pin dropper
                    </p>
                )}
                <div className="max-h-72 overflow-y-scroll">
                    {members.map((project_member: any) => {
                        const chosen = chosenIds.find(
                            (id) => id === project_member.member.id
                        );
                        return (
                            <AddPinDropperItem
                                key={`project_${project_id}_project_member_${project_member.objectId}_pin_dropper`}
                                project_member={project_member}
                                chosen={chosen}
                                onChosen={onChosen}
                            />
                        );
                    })}
                    {!loading &&
                        pagy.count !== undefined &&
                        pagy.count !== members.length && (
                            <div
                                className="flex justify-center p-3 border-b border-gray-200"
                                onClick={() =>
                                    setSearchParams({
                                        ...searchParams,
                                        page: searchParams.page + 1,
                                    })
                                }
                            >
                                <a className="text-black hover:opacity-80 text-sm font-semibold bg-gray-100 px-4 py-2 shadow cursor-pointer">
                                    Load More
                                </a>
                            </div>
                        )}
                </div>
            </>
        )}

        {loading && (
            <div className="flex justify-center py-5">
                <Loader color="black" />
            </div>
        )}
        <div className="modal-footer">
            <button
                className="modal-close-btn"
                type="button"
                onClick={onClose}
            >
                done
            </button>
        </div>
    </Modal>;
}
