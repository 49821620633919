import React, { useCallback, useState, useEffect, useRef } from 'react';
import useApi from '../../hooks/useApi';
import apiAuth from '../../api/auth';
import Loader from '../utility/Loader';
import ErrorView from '../utility/ErrorView';
import Button from '../shared/Button';
import toBase64 from '../../utility/toBase64';
import { useDropzone } from 'react-dropzone';

export default function ProfilePictureForm({ onComplete }) {
  const [user, setUser] = useState(null);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const savedTimer = useRef(null);
  const [file, setFile] = useState(null);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFile(acceptedFiles[0]);
      console.log('setFiles');
    },
    [file]
  );

  const { getRootProps, getInputProps, isDragActive, rootRef } = useDropzone({
    onDrop,
    accept: 'image/*',
    maxFiles: 1,
  });
  const { data, loading, request: getMe } = useApi(apiAuth.me, null);
  const { error: updateUserError, request: updateMe } = useApi(
    apiAuth.update,
    null
  );

  useEffect(() => {
    getMe();
  }, []);

  useEffect(() => {
    data && setUser(data);
  }, [data]);

  useEffect(() => {
    if (saved) {
      savedTimer.current = setTimeout(() => {
        onComplete?.();
      }, 1000);
    }
  }, [saved]);

  const savePhoto = async () => {
    setSaving(!saving);

    let base64File = await toBase64(file);
    await updateMe({
      profile_photo: { filename: file.name, data: base64File },
    });

    setSaving(!saving);
    setSaved(true);
  };

  return (
    <div className="max-w-md mx-auto text-center">
      {updateUserError && (
        <ErrorView error={updateUserError} extraClass={'mb-4'} />
      )}
      <section>
        <h2 className="text-xl text-gray-800 font-bold mb-5">
          Let's get your profile photo updated
        </h2>
        <div className="flex items-center focus:outline-none">
          <div
            className={`mr-4 w-32 h-32 rounded-full overflow-hidden cursor-pointer hover:opacity-80 focus:outline-none ${
              !isDragActive
                ? ''
                : 'border-3 border-secondary bg-blue-100 opacity-50 bg-tertiary'
            }`}
            {...getRootProps()}
            title="Click or Drag and Drop to change photo"
          >
            <img
              className="w-full h-full object-cover mr-4"
              src={
                file ? URL.createObjectURL(file) : user?.profile_photos.medium
              }
              alt="User upload"
            />
            <input {...getInputProps()} />
          </div>
          {file ? (
            <>
              <Button
                saved={saved}
                saving={saving}
                text="Save Photo"
                savedText="Saved"
                onClick={savePhoto}
              />
              <button
                className="btn rounded bg-gray-100 shadow-sm text-gray-900 focus:outline-none hover:opacity-80 ml-2"
                disabled={saving}
                onClick={() => setFile(null)}
              >
                Cancel
              </button>
            </>
          ) : (
            <button
              className="btn rounded border-gray-200 text-secondary shadow-sm focus:outline-none hover:opacity-80 ml-4"
              onClick={() => rootRef.current.click()}
            >
              Change
            </button>
          )}
        </div>
      </section>

      <div className="flex place-content-end">
        {loading && !user && <Loader color="black" />}
        {!loading && user && (
          <button
            className="btn rounded bg-tertiary hover:opacity-80 text-white ml-auto"
            onClick={() => onComplete?.()}
          >
            Skip
          </button>
        )}
      </div>
    </div>
  );
}
