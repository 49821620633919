import { useEffect, useRef } from 'react';
import useApi from '../../../hooks/useApi';
import entriesApi from '../../../api/entries';
import useSaved from '../../../hooks/useSaved';
export default function useEntry(entryId: string | null, {
    load = true
}: {
    load?: boolean
} = {}) {
    const {
        data: entry,
        error,
        loading,
        request: loadEntry,
    } = useApi(entriesApi.getEntry, null, load);

    const {
        data: savedEntry,
        error: saveError,
        loading: saving,
        request: saveEntry,
    } = useApi(entriesApi.updateEntry, null);

    const updateTimer = useRef<NodeJS.Timeout | null>(null);
    
    const _saveEntry = (entry: any) => {

        if (entryId) saveEntry(entryId, entry);
    }

    const _saveEntryDebounced = (entry: any, delay: number = 1000) => {

        if (!entryId) return;
        if (updateTimer.current) clearTimeout(updateTimer.current);

        updateTimer.current = setTimeout(() => {
            saveEntry(entryId, entry);
        }, delay);
    }

    const _loadEntry = () => {
        if (entryId) loadEntry(entryId);
    }

    const { saved } = useSaved(savedEntry?.updated_at);

    useEffect(() => {
        if (!load || !entryId) return;
        _loadEntry();
    }, [load, entryId]);

    return {
        saveEntryDebounced: _saveEntryDebounced,
        entry,
        error,
        loading,
        loadEntry: _loadEntry,
        savedEntry,
        saveError,
        saving,
        saved,
        saveEntry: _saveEntry,
    }
}
