import { useState } from 'react';
import authApi from '../../api/auth';
import useModal from '../../hooks/useModal';
import ErrorViewModel from '../../models/ErrorViewModel';
import ErrorView from '../utility/ErrorView';
import Loader from '../utility/Loader';
import Modal, { ModalHeader, ModalSuccessView, ModalBody, ModalFooter } from '../utility/Modal';

export default function ForgotPasswordModal({ email: passedEmail = '' }) {
  const [email, setEmail] = useState(passedEmail);
  const { open: modalOpen, toggle: toggleModal } = useModal();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [sendingLink, setSendingLink] = useState(false);

  const sendLink = async () => {
    setSendingLink(true);

    const response = await authApi.resetPassword(email);

    setSendingLink(false);

    if (response.ok) {
      setSuccess(true);
    } else {
      setError(new ErrorViewModel(response.data));
    }
  };

  return (
    <div>
      {/* Button */}
      <a
        className="cursor-pointer mr-1 text-sm underline hover:no-underline bg-transparent border-0"
        onClick={toggleModal}
      >
        Forgot Password?
      </a>
      <Modal isOpen={modalOpen} onClose={toggleModal}>
        <ModalHeader title={'Forgot Password'} onClose={toggleModal} />
        {error && (
          <ErrorView error={error} data-testid="info-forgot-pwd-error" />
        )}
        <ModalBody>
          {!success && (
            <>
              <div
                className="mb-4 font-semibold"
                data-testid="info-forgot-password-header"
              >
                Send Reset Link
              </div>
              <div>
                Please type your email address below to receive a password reset
                link.
              </div>
              <div className="border-b border-gray-200 overflow-hidden mt-5">
                <div className="flex items-center">
                  <input
                    type="text"
                    autoFocus={modalOpen}
                    data-testid="action-forgot-pwd-email"
                    placeholder="name@example.com"
                    value={email}
                    onChange={({ target: { value } }) => setEmail(value)}
                    className="px-2 placeholder-gray-500 bg-transparent relative text-sm border-0 w-full border-0 outline-none focus:outline-none"
                  />
                </div>
              </div>
            </>
          )}
          {success && (
            <ModalSuccessView
              text={`Reset link successfully sent to ${email}`}
            />
          )}
        </ModalBody>

        {!success && (
          <ModalFooter>
            <button
              className="modal-save-btn"
              type="button"
              onClick={sendLink}
              data-testid="action-forgot-pwd-send"
            >
              Send Link {sendingLink && <Loader />}
            </button>
            <button
              className="modal-close-btn"
              type="button"
              data-testid="action-forgot-pwd-cancel"
              onClick={toggleModal}
            >
              Cancel
            </button>
          </ModalFooter>
        )}
      </Modal>
    </div>
  );
}
