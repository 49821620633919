import { useEffect } from 'react';
import useApi from '../../../hooks/useApi';
import pinTypesApi from '../../../api/pin_types';
import { SearchParams } from '../../../data/models';
export default function useWorkspacePinTypes(workspaceId: string, {
    params = {}
}: {
    params?: SearchParams
} = {
    }) {
    const {
        data: pinTypes,
        error,
        loading,
        request: loadPinTypes,
    } = useApi(pinTypesApi.getPinTypes, [], true);

    const reloadPinTypes = () => loadPinTypes(workspaceId, params);

    useEffect(() => {
        loadPinTypes(workspaceId, params);
    }, [JSON.stringify(params), workspaceId]);

    return {
        pinTypes,
        error,
        loading,
        loadPinTypes,
        reloadPinTypes,
    }
}