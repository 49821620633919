import fieldOptionsApi from '../../../api/field_options';
import useApi from '../../../hooks/useApi';
import useSaved from '../../../hooks/useSaved';
export default function useFieldOption(fieldOptionId: number | null | undefined) {
    const {
        data,
        error,
        loading: updating,
        request: saveFieldOption,
    } = useApi(fieldOptionsApi.updateFieldOption, null);

    const {
        data: archivedFieldOption,
        error: archivingError,
        loading: archiving,
        request: archiveFieldOption,
    } = useApi(fieldOptionsApi.archiveFieldOption, null);

    const {
        data: unarchivedFieldOption,
        error: unarchivingError,
        loading: unarchiving,
        request: unarchiveFieldOption,
    } = useApi(fieldOptionsApi.unarchiveFieldOption, null);

    const _saveFieldOption = (fieldOption: any) => {
        saveFieldOption(fieldOptionId, fieldOption);
    }

    const _archiveFieldOption = () => {
        archiveFieldOption(fieldOptionId);
    }

    const _unarchiveFieldOption = () => {
        unarchiveFieldOption(fieldOptionId);
    }


    const { saved: updated } = useSaved(data?.updated_at);
    const { saved: archived } = useSaved(archivedFieldOption?.updated_at);
    const { saved: unarchived } = useSaved(unarchivedFieldOption?.updated_at);

    return {
        data,
        error,
        updating,
        updated,
        saveFieldOption: _saveFieldOption,
        archivedFieldOption,
        archivingError,
        archiving,
        archived,
        archiveFieldOption: _archiveFieldOption,
        unarchivedFieldOption,
        unarchivingError,
        unarchiving,
        unarchived,
        unarchiveFieldOption: _unarchiveFieldOption,
    }
}