import { memo, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { HiOutlinePhotograph } from 'react-icons/hi';
import { FiPlus } from 'react-icons/fi';
import useModal from '../../../hooks/useModal';
import AddPhotoItem from '../../project/AddPhotoItem';
import Modal, { ModalHeader } from '../../utility/Modal';

const ImageInput = ({ onImagesAdded, disabled }) => {
  const [files, setFiles] = useState([]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFiles([...files, ...acceptedFiles]);
    },
    [files]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*',
  });

  const {
    open: modalOpen,
    toggle: toggleModal,
    setOpen: setModalOpen,
  } = useModal();

  return (
    <>
      <div className={`flex flex-wrap items-start`}>
        {files.map((file, i) => {
          return (
            <AddPhotoItem
              key={`${file.path}`}
              file={file}
              startSaving={false}
              index={i}
              onSaved={() => console.log('saved')}
              passback={(x) => console.log('passback', x)}
              description={null}
              onRemove={(index) => {
                files.splice(index, 1);
                setFiles([...files]);
              }}
            />
          );
        })}
      </div>
      <button
        disabled={disabled}
        data-testid="test-modal-button"
        onClick={() => toggleModal()}
        className={`btn rounded hover:text-gray-50 ${disabled ? '' : 'hover:opacity-90'
          } ${disabled ? 'cursor-default' : 'cursor-pointer'
          } text-white bg-secondary ${disabled ? 'opacity-80' : ''}`}
      >
        <FiPlus size={18} className={'text-white-500 mr-2'} /> Add Photos
      </button>
      <Modal isOpen={modalOpen} onClose={toggleModal} modalClass={'rounded-lg'}>
        <ModalHeader title={`Add Photos`} onClose={toggleModal} />

        <div
          className={`flex flex-wrap items-start ${files?.length > 0 ? 'pt-4 px-5' : ''
            }`}
        >
          {files.map((file, i) => {
            return (
              <AddPhotoItem
                key={`${file.path}`}
                file={file}
                startSaving={false}
                index={i}
                onSaved={() => console.log('saved')}
                passback={(x) => console.log('passback', x)}
                description={null}
                onRemove={(index) => {
                  files.splice(index, 1);
                  setFiles([...files]);
                }}
              />
            );
          })}
        </div>

        <div
          className="cursor-pointer outline-none ring-0 text-base w-full px-5 py-4"
          {...getRootProps()}
          data-testid="dropzone-photo-file"
        >
          <div
            className={`disabled:opacity-50 p-5 h-72 flex flex-col active:ring-0 items-center justify-center ${!isDragActive
              ? 'border-2 border-gray-300'
              : 'border-3 border-secondary bg-blue-100'
              } border-dashed hover:opacity-80 rounded-md text-center`}
          >
            <input {...getInputProps()} data-testid="input-photo-file" />
            <HiOutlinePhotograph
              size={60}
              className={`mb-1 mx-auto ${!isDragActive ? 'text-tertiary-light' : 'text-secondary'
                }`}
            />
            <div
              className={`font-semibold ${!isDragActive ? 'text-tertiary' : 'text-secondary'
                }`}
            >
              Click to upload images
              <br />
              or drag and drop
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <button
            className="modal-save-btn"
            type="button"
            onClick={() => {
              onImagesAdded(files);
              toggleModal();
            }}
            data-testid="test-btn"
          >
            Add Photos
          </button>
          <button
            className="modal-close-btn"
            type="button"
            onClick={() => {
              toggleModal();
            }}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};

export default memo(ImageInput);
