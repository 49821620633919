import { useEffect, useState } from 'react';
import { FiMoreHorizontal } from 'react-icons/fi';
import EditMenu from '../EditMenu';
import constructionDrawingsApi from '../../api/construction_drawings';
import useApi from '../../hooks/useApi';
import Loader from '../utility/Loader';
import DeleteConstructionDrawingModal from './DeleteConstructionDrawingModal';
import UploadVersionConstructionDrawingModal from './UploadVersionConstructionDrawingModal';
import ShareModal from '../shares/ShareModal';
import useProjectContext from '../../hooks/useProjectContext';
import VersionsConstructionDrawingModal from './VersionsConstructionDrawingModal';
import ConstructionDrawingViewModel from '../../models/ConstructionDrawingViewModel';
import formattedDateString from '../../utility/formattedDateString';
import { checkPermission } from '../shared/utils';

const ConstructionDrawing = ({
  constructionDrawing: passedConstructionDrawing,
  onClick,
  onSelect,
  afterDelete,
  selected,
  viewMode = 'grid',
}) => {
  const {
    data,
    loading: reloading,
    request: getConstructionDrawing,
  } = useApi(constructionDrawingsApi.getConstructionDrawing);
  const { project } = useProjectContext();
  const [loading, setLoading] = useState(true);
  const [constructionDrawing, setConstructionDrawing] = useState(
    passedConstructionDrawing
  );
  const [versionModalOpen, setVersionModalOpen] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [versionsModalOpen, setVersionsModalOpen] = useState(false);
  const {
    objectId,
    name,
    files: { original, medium },
    created_at,
  } = constructionDrawing;

  const reloadConstructionDrawing = () => {
    getConstructionDrawing(objectId);
  };

  useEffect(() => {
    if (!data) return;
    setLoading(true);
    setConstructionDrawing(new ConstructionDrawingViewModel(data));
  }, [data]);

  useEffect(() => {
    if (!passedConstructionDrawing) return;
    setConstructionDrawing(passedConstructionDrawing);
  }, [passedConstructionDrawing]);

  return (
    <>
      {viewMode == 'list' && (
        <div
          key={`construction_drawing_list_view_${objectId}`}
          className="cursor-pointer"
        >
          <div className="bg-white px-5 py-2 flex justify-between border-b border-gray-200">
            <div className="flex items-center space-x-4">
              <input
                key={`construction_drawing_checkbox_${objectId}`}
                onChange={onSelect}
                checked={selected}
                className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-secondary checked:border-secondary focus:outline-none transition duration-200 my-1 align-top bg-no-repeat bg-center bg-contain cursor-pointer"
                type="checkbox"
                data-testid="cd_chk"
              />
              <div
                className="w-10 h-10"
                onClick={onClick}
                style={{
                  backgroundImage: `url(${medium})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              ></div>
              <div
                className="text-sm sm:text-md font-semibold z-10"
                onClick={onClick}
              >
                {name}
              </div>
              <div className="text-xs sm:text-md ">
                Uploaded on{' '}
                {formattedDateString(created_at, 'MMM D, YYYY [at] h:mm a')}
              </div>
            </div>
            <div className="relative">
              <EditMenu className="">
                <li>
                  <a
                    className="focus:outline-none cursor-pointer font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3"
                    rel="noreferrer"
                    href={original}
                    target="_blank"
                  >
                    View
                  </a>
                </li>
                <li>
                  <a
                    className="focus:outline-none cursor-pointer font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3"
                    download={name}
                    href={original}
                  >
                    Download
                  </a>
                </li>
                <li>
                  {checkPermission({ permissions: project.permissions, permission: 'create_public_link' }) && (
                    <a
                      className="cursor-pointer"
                      data-testid="shareReportButton"
                      onClick={() => setShareModalOpen(!shareModalOpen)}
                    >
                      <div className="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3">
                        Share
                      </div>
                    </a>
                  )}
                </li>
                {constructionDrawing.versions && (
                  <li>
                    <a
                      className="focus:outline-none cursor-pointer font-medium text-sm flex py-1 px-3"
                      type="button"
                      onClick={() => setVersionsModalOpen(!versionsModalOpen)}
                    >
                      Version History
                    </a>
                  </li>
                )}
                <li>
                  <a
                    className="focus:outline-none cursor-pointer font-medium text-sm text-blue-900 hover:text-blue-800 flex py-1 px-3"
                    type="button"
                    onClick={() => setVersionModalOpen(!versionModalOpen)}
                  >
                    Upload New Version
                  </a>
                </li>
                <li>
                  <DeleteConstructionDrawingModal
                    afterDelete={afterDelete}
                    constructionDrawing={{ objectId, name }}
                  />
                </li>
              </EditMenu>
            </div>
          </div>

          <UploadVersionConstructionDrawingModal
            afterAdd={reloadConstructionDrawing}
            constructionDrawing={{ objectId, name }}
            open={versionModalOpen}
            onClose={() => setVersionModalOpen(false)}
          />

          <ShareModal
            open={shareModalOpen}
            onClose={() => setShareModalOpen(false)}
            data={constructionDrawing}
            shareType="ConstructionDrawing"
            shareableApi={constructionDrawingsApi.updateConstructionDrawing}
          />

          <VersionsConstructionDrawingModal
            constructionDrawing={constructionDrawing}
            open={versionsModalOpen}
            onClose={() => setVersionsModalOpen(false)}
          />
        </div>
      )}
      {viewMode == 'grid' && (
        <div
          key={`construction_drawing_grid_view_${objectId}`}
          className="cursor-pointer"
          rel="noreferrer"
        >
          <div className="bg-white">
            <div className="text-xs text-center p-2 h-11 group flex justify-between items-center">
              <input
                key={`construction_drawing_checkbox_${objectId}`}
                onChange={onSelect}
                checked={selected}
                className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-secondary checked:border-secondary focus:outline-none transition duration-200 my-1 align-top bg-no-repeat bg-center bg-contain cursor-pointer"
                type="checkbox"
                data-testid="cd_chk"
              />
              <div className="hidden h-6 w-12 text-xl flex rounded justify-center items-center hover:bg-gray-100">
                <FiMoreHorizontal />
              </div>
              <div className="relative">
                <EditMenu className="">
                  <li>
                    <a
                      className="focus:outline-none cursor-pointer font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3"
                      rel="noreferrer"
                      href={original}
                      target="_blank"
                    >
                      View
                    </a>
                  </li>
                  <li>
                    <a
                      className="focus:outline-none cursor-pointer font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3"
                      download={name}
                      href={original}
                    >
                      Download
                    </a>
                  </li>
                  <li>
                    {checkPermission({ permissions: project.permissions, permission: 'create_public_link' }) && (
                      <a
                        className="cursor-pointer"
                        data-testid="shareReportButton"
                        onClick={() => setShareModalOpen(!shareModalOpen)}
                      >
                        <div className="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3">
                          Share
                        </div>
                      </a>
                    )}
                  </li>
                  {constructionDrawing.versions && (
                    <li>
                      <a
                        className="focus:outline-none cursor-pointer font-medium text-sm flex py-1 px-3"
                        type="button"
                        onClick={() => setVersionsModalOpen(!versionsModalOpen)}
                      >
                        Version History
                      </a>
                    </li>
                  )}
                  <li>
                    <a
                      className="focus:outline-none cursor-pointer font-medium text-sm text-blue-900 hover:text-blue-800 flex py-1 px-3"
                      type="button"
                      onClick={() => setVersionModalOpen(!versionModalOpen)}
                    >
                      Upload New Version
                    </a>
                  </li>
                  <li>
                    <DeleteConstructionDrawingModal
                      afterDelete={afterDelete}
                      constructionDrawing={{ objectId, name }}
                    />
                  </li>
                </EditMenu>
              </div>
            </div>
            <div
              className="relative group h-40 overflow-hidden"
              onClick={onClick}
            >
              {(loading || reloading) && (
                <div className="absolute inset-0 flex justify-center items-center">
                  <Loader color="black" />
                </div>
              )}
              <img
                onLoad={() => setLoading(false)}
                className="object-cover group-hover:opacity-80"
                loading="lazy"
                src={medium}
              />
              <div className="absolute top-0 left-0 opacity-0 w-full h-full bg-secondary transition-opacity duration-300 group-hover:opacity-60 flex items-center">
                <div className="text-white w-full text-center font-black">
                  VIEW
                </div>
              </div>
            </div>
            <div className="text-xs text-center p-3 h-14">{name}</div>
          </div>

          <UploadVersionConstructionDrawingModal
            afterAdd={reloadConstructionDrawing}
            constructionDrawing={{ objectId, name }}
            open={versionModalOpen}
            onClose={() => setVersionModalOpen(false)}
          />

          <ShareModal
            open={shareModalOpen}
            onClose={() => setShareModalOpen(false)}
            data={constructionDrawing}
            shareType="ConstructionDrawing"
            shareableApi={constructionDrawingsApi.updateConstructionDrawing}
          />

          <VersionsConstructionDrawingModal
            constructionDrawing={constructionDrawing}
            open={versionsModalOpen}
            onClose={() => setVersionsModalOpen(false)}
          />
        </div>
      )}
    </>
  );
};

export default ConstructionDrawing;
