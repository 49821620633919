import * as React from 'react';
import { Entry } from '../../data/models';
export default function EntryInput({ entry, disabled, useBottomBorder, onChange }: {
    entry: Entry
    disabled?: boolean
    useBottomBorder?: boolean
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}) {
    return <input
        type={entry.value_type === 'number' ? 'number' : 'text'}
        data-testid={`entryTextInput_${entry.name
            .replace(' ', '_')
            .toLowerCase()}`}
        onChange={onChange}
        disabled={disabled}
        className={`text-xxs leading-4 border-gray-200 p-2 border-t-0 border-r-0 border-l-0 ${entry.class_name
            } ${useBottomBorder ? 'border-b' : 'border-b-0'} ${!disabled && 'hover:bg-blue-50'
            } focus:border-gray-200 focus:outline-none focus:ring-0`}
        defaultValue={entry.value}
    />
}