import { useEffect, useState, useRef } from 'react';
import useApi from '../../hooks/useApi';
import Loader from '../utility/Loader';
import Modal, { ModalHeader, ModalSuccessView, ModalBody, ModalFooter } from '../utility/Modal';
import ErrorView from '../utility/ErrorView';
import Form from '../../utils/Form';
import featureFlagsApi from '../../api/feature_flags';
export default function EditFeatureFlagModal({
    newFlag = false,
    open,
    featureFlag: passedFeatureFlag,
    afterCreate,
    onClose,
}) {
    const [saved, setSaved] = useState(false);
    const [featureFlag, setFeatureFlag] = useState(passedFeatureFlag);
    const savedTimer = useRef(null);
    const {
        data: updatedFeatureFlag,
        error,
        loading: saving,
        request: submitFeatureFlag,
    } = useApi(featureFlagsApi.updateFeatureFlag, null);

    const saveFeatureFlag = () => {
        submitFeatureFlag(featureFlag.key, featureFlag);
    };

    const updateFeatureFlag = (key, value) => {
        setFeatureFlag({ ...featureFlag, [key]: value });
    };

    useEffect(() => {
        setFeatureFlag(passedFeatureFlag);
    }, [passedFeatureFlag])

    useEffect(() => {
        if (!updatedFeatureFlag) return;
        setSaved(true);
    }, [updatedFeatureFlag]);

    useEffect(() => {
        if (saved) {
            savedTimer.current = setTimeout(() => {
                setFeatureFlag(null);
                onClose?.();
                afterCreate?.(updatedFeatureFlag);
                setTimeout(() => {
                    setSaved(false);
                }, 500);
            }, 2500);
        }
    }, [saved]);

    return (
        <Modal
            isOpen={open}
            dialogScrolls={true}
            modalScrolls={false}
            maxHeight={'max-h-auto'}
            dialogClass={'py-10'}
            modalClass={'overflow-hidden rounded-lg'}
            aligned={'items-start'}
            onClose={onClose}
        >
            <ModalHeader
                title={newFlag ? 'New Feature Flag' : 'Edit Feature Flag'}
                onClose={onClose}
            />
            {saved ? (
                <ModalSuccessView text={newFlag ? 'Feature flag successfully created' : 'Feature flag successfully updated'} />
            ) : (<>
                <ModalBody>
                    {error && (
                        <ErrorView error={error} />
                    )}

                    <Form.Label>Key</Form.Label>
                    <Form.InputGroup>
                        <Form.Input
                            type="text"
                            data-testid="inputFeatureFlagKey"
                            disabled={!newFlag}
                            placeholder="new_feature_flag"
                            value={featureFlag?.key}
                            onChange={({ target: { value } }) =>
                                updateFeatureFlag('key', value)
                            }
                        />
                    </Form.InputGroup>

                    <Form.Label>Description</Form.Label>
                    <Form.InputGroup>
                        <Form.Input
                            type="text"
                            data-testid="inputFeatureFlagDescription"
                            placeholder="Quick internal description on what this feature flag enables."
                            value={featureFlag?.data?.description}
                            onChange={({ target: { value: description } }) =>
                                updateFeatureFlag('data', {
                                    description
                                })
                            }
                        />
                    </Form.InputGroup>

                    <Form.Label>Percentage</Form.Label>
                    <Form.InputGroup>
                        <Form.Input
                            type="number"
                            placeholder="100"
                            data-testid="inputFeatureFlagPercentage"
                            value={featureFlag?.percentage}
                            min={0}
                            max={100}
                            onChange={({ target: { value } }) =>
                                updateFeatureFlag('percentage', value)
                            }
                        />
                    </Form.InputGroup>
                    <Form.Label>Users</Form.Label>
                    <Form.InputGroup>
                        <Form.Input
                            type="text"
                            disabled={true}
                            value={featureFlag?.users}
                            onChange={({ target: { value } }) =>
                                updateFeatureFlag('users', value)
                            }
                        />
                    </Form.InputGroup>
                </ModalBody>


                <ModalFooter>
                    <button
                        className="modal-save-btn mr-2"
                        type="button"
                        data-testid="saveFeatureFlag"
                        disabled={saving}
                        onClick={saveFeatureFlag}
                    >
                        Save {saving && <Loader />}
                    </button>
                    <button
                        className="modal-close-btn"
                        type="button"
                        data-testid="cancelFeatureFlag"
                        disabled={saving}
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                </ModalFooter></>
            )
            }
        </Modal >
    );
}
