import { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import Progress from '../../utils/Progress';
import { Project } from '../../data/models';
import formatCurrency from '../../utility/formatCurrency';
import { checkPermission } from '../shared/utils';

export function ContractDetails({
  project,
  showProgressBar,
  setProject,
  inputContainerClassNames = {
    startDate: 'flex-1 px-2  border-l-0 border-t-0 border-b border-r border-gray-200',
    endDate: 'flex-1 pl-4 border-gray-200 border-l-0 border-t-0 border-b border-r border-gray-200',
    amount: 'flex-1 px-2  border-l-0 border-t-0 border-b border-r-0 border-gray-200',
  },
  inputClassNames = {
    startDate: 'placeholder-gray-400 focus:ring-0 bg-transparent text-sm w-full border-0 focus:outline-none',
    endDate: 'placeholder-gray-400 focus:ring-0 bg-transparent text-sm w-full border-0 focus:outline-none',
    amount: 'placeholder-gray-400 focus:ring-0 bg-transparent text-sm w-full border-0 focus:outline-none px-2',
    dollar: 'flex items-center text-gray-500'
  }
}: {
  inputContainerClassNames?: {
    startDate?: string;
    endDate?: string;
    amount?: string;
  };
  inputClassNames?: {
    startDate?: string;
    endDate?: string;
    amount?: string;
    dollar?: string;
  };
  project: Project;
  showProgressBar?: boolean;
  setProject: (project: Project) => void;
}) {
  const [startDate, setStartDate] = useState<Date | null>(
    project.contract_start ? new Date(project.contract_start as string) : null
  );

  const [endDate, setEndDate] = useState<Date | null>(
    project.contract_end ? new Date(project.contract_end as string) : null
  );

  useEffect(() => {
    setStartDate(project.contract_start ? new Date(project.contract_start as string) : null);
  }, [project.contract_start]);

  useEffect(() => {
    setEndDate(project.contract_end ? new Date(project.contract_end as string) : null);
  }, [project.contract_end]);

  const initialContractAmount = project.contract_amount
    ? parseFloat(project.contract_amount.toString())
    : 0;

  const formattedInitialAmount = isNaN(initialContractAmount) || initialContractAmount === 0
    ? ''
    : initialContractAmount.toFixed(2);

  const [contractAmount, setContractAmount] = useState<string>(
    formatCurrency(formattedInitialAmount, true)
  );

  const handleContractAmountUpdate = (e: any) => {
    let rawValue = e?.target?.value?.replace(/,/g, '');
    const formattedValue = formatCurrency(rawValue, false);
    setContractAmount(formattedValue);
    updateProject('contract_amount', parseFloat(rawValue).toFixed(2));
  };

  const updateProject = (key: string, value: unknown): void => {
    const updatedProject = { ...project, [key]: value };

    if (key === 'contract_start' || key === 'contract_end') {
      const start = updatedProject.contract_start ? new Date(updatedProject.contract_start as string) : null;
      const end = updatedProject.contract_end ? new Date(updatedProject.contract_end as string) : null;

      setStartDate(start);
      setEndDate(end);
    }

    setProject(updatedProject);
  };

  const completion_percentage = (typeof project.completion_percentage === 'number' && project.completion_percentage >= 0) ? project.completion_percentage : 0;

  return (
    <div className="pb-2 mb-2">
      <div className="flex items-center mb-8">
        <div className={inputContainerClassNames?.startDate}>
          <div className="font-semibold mb-2">Contract Start Date</div>
          <DatePicker
            selected={startDate}
            onChange={(date: Date) => updateProject('contract_start', date)}
            dateFormat="MM/dd/yyyy"
            placeholderText='mm/dd/yyyy'
            wrapperClassName="w-full"
            className={inputClassNames?.startDate}
          />
        </div>
        <div className={inputContainerClassNames?.endDate}>
          <div className="font-semibold mb-2">Contract End Date</div>
          <DatePicker
            selected={endDate}
            onChange={(date: Date) => updateProject('contract_end', date)}
            placeholderText='mm/dd/yyyy'
            dateFormat="MM/dd/yyyy"
            wrapperClassName="w-full"
            className={inputClassNames?.endDate}
          />
        </div>
        {checkPermission({ permissions: project.permissions, permission: 'read_contract_amount' }) && (
          <div className={inputContainerClassNames?.amount}>
            <div className="font-semibold mb-2">Contract Amount</div>
            <div className={inputClassNames?.dollar}>
              <span className="text-gray-500 px-2 bg-gray-50">$</span>
              <input
                type="text"
                value={contractAmount || ''}
                onChange={handleContractAmountUpdate}
                className={inputClassNames?.amount}
                placeholder="Enter amount"
              />
            </div>
          </div>
        )}
      </div>
      {showProgressBar && (
        <div className="mb-8">
          <div className="font-semibold mb-2 px-2">
            Percentage of Completion {completion_percentage}%
          </div>
          <Progress current={project.completion_percentage || 0} total={100} />
        </div>
      )}
    </div>
  );
}
