import { useEffect, useState } from 'react';
import { ArchiveTabState, Field, FieldOption, PinType } from '../../data/models';
import Form, { DropdownOption } from '../../utils/Form';
import FieldOptionEditModal from '../field_options/FieldOptionEditModal';
import Button from '../shared/Button';
import useFieldOptionCreate from '../field_options/hooks/useFieldOptionCreate';
import useSaved from '../../hooks/useSaved';
import Accordion from '../../utils/Accordion';
import { FiClipboard, FiEdit } from 'react-icons/fi';
import { Flex, Row } from '../../utils/Layout';
import FieldOptionEditForm from '../field_options/FieldOptionEditForm';
import timings from '../../styles/timings';
import ErrorView from '../utility/ErrorView';
import { ConfirmationModal } from '../utility/Modal';
import Tabs, { Tab } from '../../utils/Tabs';
import useFieldOptions from '../field_options/hooks/useFieldOptions';
import Badge from '../shared/Badge';
import Draggable from 'react-draggable';
import useField from '../fields/hooks/useField';
import useFieldOption from '../field_options/hooks/useFieldOption';
import useHandleDraggableList, { DragPositionIndicator } from '../shared/hooks/useHandleDraggableList';
import Loader from '../utility/Loader';
import { BiCheckCircle } from 'react-icons/bi';
export default function FieldsetsForm({ pinType,
    afterUpdate,
}: {
    pinType: PinType | null,
    afterUpdate?: () => void;
}) {

    const [selectedFieldOption, setSelectedFieldOption] = useState<FieldOption | null>(null);
    const [fieldSelections, setFieldSelections] = useState<{ [key: string]: number[] }>({});

    const onFieldSelect = ({ field, selected }: {
        field: Field,
        selected: number
    }) => {
        const fieldOption = field.field_options.find(({ id }) => id === selected)
        if (fieldOption) setSelectedFieldOption(fieldOption)
    }
    const onFieldDeselect = ({ field, selected }: {
        field: Field,
        selected: number
    }) => {
        const newSelected = fieldSelections[field.id] || [];
        setFieldSelections(() => ({
            ...fieldSelections,
            [field.id]: [...newSelected.filter((id) => id !== selected)]
        }))
    }

    if (!pinType) return null;

    return <>
        {pinType.fieldsets.map((fieldset) => {
            return <Accordion body={<Row>
                <FiClipboard className='mr-1' /> {fieldset.name} <span className="ml-1 font-light text-xs flex items-center">({fieldset.fields[0].field_options.length} options)</span> </Row>}>
                {
                    fieldset.fields.map((field) => {
                        const FormComponent = fieldTypes[field.component_name];
                        const currentSelected = fieldSelections[field.id] || [];
                        return <FormComponent
                            afterUpdate={afterUpdate}
                            field={field}
                            onSelect={(selected) => {
                                if (currentSelected.includes(selected)) {
                                    onFieldDeselect({ field, selected })
                                } else {
                                    onFieldSelect({ field, selected })
                                }
                            }}
                            selected={currentSelected}
                        />
                    })
                }
            </Accordion>
        })}
        <FieldOptionEditModal afterUpdate={afterUpdate} fieldOption={selectedFieldOption} onClose={() => setSelectedFieldOption(null)} />
    </>
}

const FIELD_TAG_HEIGHT = 36;
const FieldTags = ({ field, onSelect }: {
    field: Field,
    afterUpdate?: () => void,
    onSelect: (selected: number) => void,
    selected: number | number[]
}) => {

    const { fieldOptions, reloadFieldOptions } = useFieldOptions(field.id);
    const [multiSelectEnabled, setMultiSelectEnabled] = useState(field.selection_type === 'multi');
    const { updateField, loading, updating, updated, field: latestField } = useField(field.id);

    const [filteredFieldOptions, setFilteredFieldOptions] = useState<FieldOption[]>([]);
    const [activeTab, setActiveTab] = useState<ArchiveTabState>('active');
    const addNewCategoryData: DropdownOption = {
        id: -1,
        name: `+ Add ${field.name}`,
        addNewOption: true
    }

    useEffect(() => {
        setFilteredFieldOptions(fieldOptions.filter((fo: FieldOption) => activeTab === 'active' ? !fo.archived_at : !!fo.archived_at));
    }, [JSON.stringify(fieldOptions), activeTab])


    const {
        draggedIndex,
        potentialNewIndex,
        onItemDrag,
        onItemDragStart,
        onItemDragStop
    } = useHandleDraggableList({
        itemHeight: FIELD_TAG_HEIGHT,
        items: filteredFieldOptions,
        onItemsReordered: (items: any[]) => {
            const itemsWithUpdatedOrder = items.map((item, index) => ({ ...item, order: index }));
            setFilteredFieldOptions(itemsWithUpdatedOrder);
            updateField({ field_options_attributes: itemsWithUpdatedOrder.map(({ id, order }) => ({ id, order })) });
        }
    })

    if (loading || !latestField) return <Flex justify='justify-center'><Loader color='black' /></Flex>

    return <>
        <FieldOptionNewButton afterUpdate={reloadFieldOptions} field={field} option={addNewCategoryData} />
        <Form.Group mb={4}>
            <Flex justify='justify-between'>
                <Flex>
                    <Form.Switch
                        data-testid={`fieldSelectionType${latestField.name}`}
                        size='sm'
                        checked={multiSelectEnabled}
                        htmlFor={`fieldSelectionType${latestField.name}`}
                        onClick={() => {
                            const selection_type = multiSelectEnabled ? 'single' : 'multi';
                            updateField({ selection_type })
                            setMultiSelectEnabled(!multiSelectEnabled)
                        }}
                    />
                    <Form.SwitchLabel mr={2}>
                        Allow multiple selections
                    </Form.SwitchLabel>
                </Flex>
                <div className="flex items-center">
                    {updating && <Loader color="black" size="4" />}
                    {updated && <BiCheckCircle
                        size={16}
                        className="bg-green-500 text-white rounded-full overflow-hidden"
                    />}
                </div>
            </Flex>
        </Form.Group>
        <Tabs.Tabs mb={4}>
            <Tabs.Tab testId={`tabFieldTagsActive${latestField.name}`} active={activeTab === 'active'} onClick={() => setActiveTab('active')}>Active</Tabs.Tab>
            <Tabs.Tab testId={`tabFieldTagsArchived${latestField.name}`} active={activeTab === 'archived'} onClick={() => setActiveTab('archived')}>Archived ({fieldOptions.filter((fo: FieldOption) => !!fo.archived_at).length})</Tabs.Tab>
        </Tabs.Tabs>
        <div className="border border-gray-150 mb-2 relative" data-testid={`fieldOptionList${latestField.name}`}>
            {filteredFieldOptions.map((option: FieldOption, index: number) => {
                return <>
                    {potentialNewIndex === index && draggedIndex >= index && <DragPositionIndicator />}
                    <FieldListOption
                        key={`${option.id}-${option.updated_at}-${index}`}
                        dragging={draggedIndex === index}
                        afterUpdate={reloadFieldOptions}
                        field={field}
                        index={index}
                        onDrag={onItemDrag(index)}
                        onDragStart={onItemDragStart(index)}
                        onDragStop={onItemDragStop}
                        firstOption={index === 0}
                        lastOption={index === filteredFieldOptions.length - 1}
                        option={option}
                        onClick={() => onSelect(option.id)}
                    />
                    {potentialNewIndex === index && draggedIndex < index && <DragPositionIndicator />}
                </>
            })}
            {filteredFieldOptions.length === 0 && <div className="p-2 text-center text-sm text-gray-800">No {activeTab} {latestField.name}(s) found</div>}
        </div>
    </>
}



const defaultFieldOption = { name: '', description: '', color: '#000', value: '', order: 0 }
const FieldOptionNewButton = ({
    afterUpdate,
    field,
    option,
}: {
    afterUpdate?: () => void,
    field: Field,
    option: DropdownOption,
}) => {

    const [adding, setAdding] = useState(false);
    const [newOption, setNewOption] = useState(defaultFieldOption);
    const { data: createdOption, createFieldOption, creating, error } = useFieldOptionCreate(field.id);

    const updateFieldOption = (key: string, value: any) => {
        const newValues = {
            [key]: value
        }
        if (key === 'name') newValues['value'] = value.toLowerCase().replace(/ /g, '_');
        setNewOption((no) => ({ ...no, ...newValues }))
    }

    const { saved } = useSaved(createdOption?.updated_at);

    const _setAdding = (e: any) => {
        setAdding(!adding);
    }

    const _onCreate = (e: any) => {
        createFieldOption(newOption);
    }

    useEffect(() => {
        if (!saved) return;
        setAdding(false);
        setNewOption(defaultFieldOption);
        afterUpdate?.();
    }, [saved])

    return <div className="border rounded mb-4">
        <button
            key={'addNewOption'}
            className={`flex justify-center py-1 text-sm shadow items-center w-full hover:bg-gray-50 px-3 cursor-pointer`}
            onClick={_setAdding}
        >
            <span className={"font-semibold"}>{option.name}</span>
        </button >
        {adding && <div className="px-2">
            <FieldOptionEditForm
                error={error}
                option={newOption as FieldOption}
                onClose={_setAdding}
                onSave={_onCreate}
                onUpdate={updateFieldOption}
                saved={saved}
                saving={creating}
            />
        </div>}
    </div>
}

const FieldListOption = ({ afterUpdate, onDragStart, onDragStop, onDrag, field, index, option }: {
    afterUpdate?: () => void,
    onDragStart?: () => void,
    onDragStop?: () => void,
    onDrag?: (e: any, d: any) => void,
    dragging?: boolean,
    field: Field,
    option: FieldOption,
    index: number,
    lastOption: boolean,
    firstOption: boolean,
    onClick: () => void,
}) => {

    const [editing, setEditing] = useState(false);
    const [draggingDisabled, setDraggingDisabled] = useState(false);
    const [confirmingArchive, setConfirmingArchive] = useState(false);
    const [confirmingUpdate, setConfirmingUpdate] = useState(false);
    const [newOption, setNewOption] = useState<any>({ name: '', description: '', color: '' });
    const {
        data: updatedFieldOption,
        error,
        updating,
        updated,
        saveFieldOption,
        archiveFieldOption,
        unarchiveFieldOption,
        archiving,
        archived,
        unarchived,
    } = useFieldOption(option.id);

    const updateFieldOption = (key: string, value: any) => {
        const newValues = {
            [key]: value
        }
        if (key === 'name') newValues['value'] = value.toLowerCase().replace(/ /g, '_');
        setNewOption((no: any) => ({ ...no, ...newValues }))
    }

    const _saveFieldOption = (e: any) => {
        saveFieldOption(newOption);
    }

    const _setEditing = (e: any) => {
        if (editing) setNewOption(option);
        else setDraggingDisabled(false);
        setEditing(!editing);
        e.preventDefault();
        e.stopPropagation();
        return false;
    }

    useEffect(() => {
        if (!option) return;
        setNewOption(option);
    }, [option])

    useEffect(() => {
        if (!updatedFieldOption) return;
        setNewOption(updatedFieldOption);
    }, [updatedFieldOption])

    useEffect(() => {
        if (!updated && !archived && !unarchived) return;
        setTimeout(() => {
            setEditing(false);
            setConfirmingUpdate(false);
            setConfirmingArchive(false);
            afterUpdate?.();
        }, timings.normal)
    }, [archived, updated, unarchived])

    return <>
        {!editing ? <Draggable
            disabled={draggingDisabled}
            position={{ x: 0, y: 0 }}
            onStart={onDragStart}
            onStop={onDragStop}
            onDrag={onDrag}
            defaultClassNameDragging='z-50 absolute w-full opacity-50 bg-blue-100'
        >
            <button
                key={newOption.id}
                data-testid={`fieldOption${newOption.value}`}
                className={`cursor-move flex justify-between py-1.5 text-sm ${index !== 0 ? 'border-t' : ''} items-center w-full hover:bg-gray-50 px-3 cursor-pointer`}
            >
                <Form.ColorBullet color={newOption.color || '#000'} />
                <span className={"font-medium"}>{newOption.name}</span>
                <Button
                    text=""
                    size='sm'
                    data-testid={`buttonEditFieldOption${newOption.value}`}
                    onMouseOver={() => setDraggingDisabled(true)}
                    onMouseOut={() => setDraggingDisabled(false)}
                    onClick={_setEditing}
                    icon={<FiEdit />}
                    color='light' />
            </button >
        </Draggable> : <div className="px-2 border">
            {error && <ErrorView error={error} />}
            <FieldOptionEditForm
                option={newOption as FieldOption}
                onClose={_setEditing}
                unarchived={unarchived}
                onArchive={!option.archived_at ? () => setConfirmingArchive(true) : undefined}
                onUnarchive={option.archived_at ? () => unarchiveFieldOption() : undefined}
                onSave={() => setConfirmingUpdate(true)}
                onUpdate={updateFieldOption}
            />
        </div>}
        <ConfirmationModal
            confirmed={archived}
            confirming={archiving}
            isOpen={confirmingArchive}
            title="Confirm archiving"
            body={<>
                <div className="flex absolute right-5 top-6"><Badge dot={false} padding='py-0.5 px-2 shadow-md' label={!!option.field_values_count ? `In use on ${option.field_values_count} pins` : 'Not attached to any pins'} style={!!option.field_values_count ? 'danger' : 'info'} title={`Attached to ${option.field_values_count} pins`} /></div>
                <p className="mb-1 mt-4">This will archive the {field.name} and <span className="font-semibold">remove it as an option on all new pins.</span></p>
                <p className="text-secondary">Pins with existing field option will remain unaffected.</p>
            </>}
            onClose={() => setConfirmingArchive(false)}
            onConfirm={archiveFieldOption}
        />
        <ConfirmationModal
            confirmed={updated}
            confirming={updating}
            isOpen={confirmingUpdate}
            title="Confirm update"
            body={<>
                <div className="flex absolute right-5 top-6"><Badge dot={false} padding='py-0.5 px-2 shadow-md' label={!!option.field_values_count ? `In use on ${option.field_values_count} pins` : 'Not attached to any pins'} style={!!option.field_values_count ? 'danger' : 'info'} title={`Attached to ${option.field_values_count} pins`} /></div>
                <p className="mb-1 mt-4">This will update the {field.name} and <span className="font-semibold">all pins it is attached too.</span></p>
                <p className="text-secondary">Any reports with pins including {field.name} will remain unaffected.</p>
            </>}
            onClose={() => setConfirmingUpdate(false)}
            onConfirm={_saveFieldOption}
        />
    </>
}

const FieldNumeric = ({ field, onSelect, selected }: {
    field: Field,
    onSelect: (selected: number) => void,
    selected: number | number[]
}) => {
    return <p>FieldNumeric</p>
}

const FieldDropdownOption = ({ option, isSelected, onClick }: {
    option: DropdownOption | FieldOption,
    onClick: () => void,
    isSelected: boolean,
}) => {

    return <button
        key={option.id}
        className={`flex justify-between py-1.5 border-t items-center w-full hover:bg-gray-50 px-3 cursor-pointer ${isSelected && 'text-secondary'}`}
        onClick={onClick}
    >
        <Form.ColorBullet color={option.color || '#00000'} />
        <span className={"font-medium"}>{option.name}</span>
        <Form.DropdownCheck selected={isSelected} />
    </button >
}

export {
    FieldDropdownOption
}

const FieldBoxes = ({ field, onSelect, selected }: {
    field: Field,
    onSelect: (selected: number) => void,
    selected: number | number[]
}) => {
    return <p>FieldBoxes</p>
}

const FieldPicker = ({ field, onSelect, selected }: {
    field: Field,
    onSelect: (selected: number) => void,
    selected: number | number[]
}) => {
    return <p>FieldPicker</p>
}

const FieldSwitch = ({ field, onSelect, selected }: {
    field: Field,
    onSelect: (selected: number) => void,
    selected: number | number[]
}) => {
    return <p>FieldSwitch</p>
}


const fieldTypes = {
    FieldNumeric,
    FieldTags,
    FieldBoxes,
    FieldPicker,
    FieldSwitch,
}