import React from 'react';
import { FiPlus } from 'react-icons/fi';
import ConfirmDeletePinModal from './ConfirmDeletePinModal';
export default function PinActions({ pin, onDeletePin, onAddWorkLog }) {
  return (
    <div className="px-4 pb-4 flex flex-wrap">
      <button
        className="rounded-lg mt-2 focus:outline-none hover:bg-gray-50 text-gray-500 border border-2 border-gray-200 font-bold px-2 py-1 mr-2 flex items-center text-sm"
        onClick={onAddWorkLog}
        data-testid="addWorkLogButton"
      >
        <FiPlus size={18} className={'text-gray-500 mr-2'} /> Add to Worklog
      </button>

      <ConfirmDeletePinModal
        pin={pin}
        onDeletePin={() => onDeletePin(pin.objectId)}
      />
    </div>
  );
}
