import { memo } from 'react';
import { getTextColorFromBackground } from '../../styles/colors';

function EntryPinCategory({
  entry: {
    class_name,
    value,
    styles: { color },
  },
}) {
  const finalColor = color || '#000000';

  return (
    <div className="text-xs pb-2">
      <div
        className={class_name}
        style={{
          backgroundColor: finalColor,
          color: getTextColorFromBackground(finalColor, 200),
        }}
      >
        {value}
      </div>
    </div>
  );
}

export default memo(EntryPinCategory);
