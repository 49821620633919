import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import * as msal from '@azure/msal-browser';
import useAuth from '../auth/useAuth';
import authApi from '../api/auth';
import Loader from '../partials/utility/Loader';

const MicrosoftAuthCallback = () => {
  const history = useHistory();
  const { instance } = useMsal();
  const { logIn } = useAuth();

  useEffect(() => {
    const handleCallback = async () => {

      const handleMicrosoftLogin = async (result: msal.AuthenticationResult) => {
        try {
          const response = await authApi.omniauth('microsoft', result.accessToken);
          await logIn(response);
        } catch (error) {
          console.error('Error during Microsoft login:', error);
        }
      };

      try {
        const result = await instance.handleRedirectPromise();
        console.log({ result })
        if (result) {
          await handleMicrosoftLogin(result);
        }
      } catch (error) {
        console.error('Error handling Microsoft redirect:', error);
      }
    };

    handleCallback();
  }, [instance, history, logIn]);



  return (
    <div className="flex justify-center items-center h-screen">
      <Loader color="black" />
    </div>
  );
};

export default MicrosoftAuthCallback;