import logo from '../images/logos/logo-white-icon-orange.svg';
import Button from '../partials/shared/Button';
import useWorkspaceContext from '../hooks/useWorkspaceContext';
import useWorkspaces from '../partials/workspaces/hooks/useWorkspaces';
import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { environment } from '../config';
export default function ConferenceFullPageBlock() {

    const { workspace } = useWorkspaceContext();
    const { workspaces } = useWorkspaces({
        load: !!workspace?.conference
    });
    const [goToCreateWorkspace, setGoToCreateWorkspace] = useState(false);

    if (!workspace?.conference || workspace.permissions?.can?.('read_on_web') || window.location.pathname.includes('how-to')) return null;
    const currentDate = new Date();
    const targetDate = new Date('2024-09-11');

    const isPastSept11_2024 = currentDate > targetDate;


    if (goToCreateWorkspace) return <Redirect to="/onboarding/workspace" />;

    return <>
        <div className="w-screen h-screen flex justify-center items-center inset-0 bg-secondary fixed z-70">
            <div className="text-center px-5">
                <img src={logo} className="mb-12 w-3/4 mx-auto" />
                <p className="text-4xl text-white font-bold mb-4">Hey there!</p>
                <p className="text-2xl text-white font-semibold mb-2">Looks like {isPastSept11_2024 ? 'you attended ' : 'you\'re attending '} <span className="font-black">{workspace.name}</span></p>
                {workspaces.length === 1 ?
                    <p className="text-2xl text-gray-200 font-medium italic">Ready to create your own workspace?</p>
                    : <p className="text-2xl text-gray-200 font-medium italic">Ready to join another workspace?</p>}
                <Button text={workspaces.length > 1 ? 'Join Workspace' : 'Create Workspace'} className="mt-8 text-lg px-5 py-3" color='success' onClick={() => setGoToCreateWorkspace(true)} />
            </div>
            <a
                href={environment.deepLink}
                className="flex flex-col fixed sm:hidden bottom-0 w-full h-20 bg-white text-secondary shadow-lg text-lg font-bold justify-center flex items-center text-center z-70"
            >

                <div>Open App</div>
                <span className="text-xs font-light text-gray-800">Still wanting to drop pins and win a driver?</span>

            </a>
        </div>
    </>;
}