import { useCallback, useEffect, useRef, useState } from 'react';
import Modal, { ModalHeader, ModalSuccessView } from '../utility/Modal';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { useDropzone } from 'react-dropzone';
import Loader from '../utility/Loader';
import AddPDFItem from './AddPDFItem';
import useModal from '../../hooks/useModal';
import { useParams } from 'react-router-dom';
import timings from '../../styles/timings';

export default function AddConstructionDrawingModal({ afterAdd }) {
  const { project_id } = useParams();
  const {
    open: modalOpen,
    setOpen: setModalOpen,
    toggle: toggleOpen,
  } = useModal();
  const [savedPDFs, setSavedPDFs] = useState([]);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const savedTimer = useRef(null);
  const [files, setFiles] = useState([]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFiles([...files, ...acceptedFiles]);
    },
    [files]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'application/pdf',
    onDrop,
  });

  const removeImageAtIndex = (index) => {
    setFiles([...files.filter((f, i) => i !== index)]);
  };

  const savePDFs = (signed_id) => {
    setSavedPDFs([...savedPDFs, signed_id]);
  };

  const resetModal = () => {
    setFiles([]);
    setSaving(false);
  };

  useEffect(() => {
    if (savedPDFs.length === 0) return;
    if (savedPDFs.length !== files.length) return;
    savedTimer.current = setTimeout(() => setSaved(true), timings.normal);
  }, [savedPDFs]);

  useEffect(() => {
    if (saved) {
      resetModal();
      savedTimer.current = setTimeout(() => {
        setModalOpen(false);
        afterAdd?.();
        setTimeout(() => {
          setSaved(false);
          setSavedPDFs([]);
        }, 500);
      }, timings.normal);
    }
  }, [saved]);

  return (
    <div>
      {/* Button */}
      <a className="cursor-pointer" onClick={toggleOpen}>
        <div className="btn rounded hover:text-gray-50 hover:opacity-90 text-white bg-secondary">
          <svg
            className="w-4 h-4 fill-current opacity-50 flex-shrink-0"
            viewBox="0 0 16 16"
          >
            <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
          </svg>
          <span className="hidden xs:block ml-2">Add CD</span>
        </div>
      </a>
      <Modal isOpen={modalOpen} onClose={toggleOpen}>
        <ModalHeader
          title={'Add New Construction Drawing'}
          onClose={toggleOpen}
        />
        {saved ? (
          <div className="p-5">
            <ModalSuccessView text={'PDFs successfully added to project'} />
          </div>
        ) : (
          <div className="px-2 pt-2">
            <div className="p-5">
              {files.length > 0 && (
                <>
                  <div className="pb-2 flex flex-wrap">
                    {files.map((file, index) => {
                      return (
                        <AddPDFItem
                          key={`project_${project_id}_temp_pdf_${file.path}`}
                          file={file}
                          startSaving={saving}
                          index={index}
                          onSaved={savePDFs}
                          onRemove={removeImageAtIndex}
                        />
                      );
                    })}
                  </div>
                </>
              )}
              <div
                className="cursor-pointer ring-0 text-base flex-1"
                {...getRootProps()}
              >
                <div
                  disabled={saving !== false}
                  className={`disabled:opacity-50 p-5 h-72 flex flex-col active:ring-0 items-center justify-center ${!isDragActive
                    ? 'border-2 border-gray-300'
                    : 'border-3 border-secondary bg-blue-100'
                    } border-dashed hover:opacity-80 rounded-md text-center`}
                >
                  <input {...getInputProps()} />
                  <AiOutlineFilePdf
                    size={60}
                    className={`mb-1 mx-auto ${!isDragActive ? 'text-tertiary-light' : 'text-secondary'
                      }`}
                  />
                  <div
                    className={`font-semibold ${!isDragActive ? 'text-tertiary' : 'text-secondary'
                      }`}
                  >
                    Click to upload file
                    <br />
                    or drag and drop
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                className="modal-save-btn"
                type="button"
                disabled={files.length === 0 || saving !== false}
                onClick={() => setSaving(true)}
              >
                Save
                {saving !== false && <Loader className="ml-3" />}
              </button>
              <button
                className="modal-close-btn"
                type="button"
                disabled={saving !== false}
                onClick={() => {
                  setModalOpen(false);
                  resetModal();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}
