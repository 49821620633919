export default class ErrorViewModel extends Object {
  [key: string]: any;

  constructor(object: Object) {
    super();
    Object.assign(this, object);
  }

  text() {
    let firstErrorKey: string = Object.keys(this)[0];
    return `${this[firstErrorKey]}`;
  }

  includes(key: string) {
    return this[key] || false;
  }
}
