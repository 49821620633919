import Moment from 'react-moment';
import moment from 'moment-timezone';

export default function configureTimezone() {
  let searchParams = new URLSearchParams(window.location.search);
  let passedTimezone = searchParams.get('tz');
  let guessedTimezone = null;

  if (passedTimezone) {
    moment.tz.setDefault(passedTimezone);
  } else {
    Moment.globalLocal = true;
    guessedTimezone = moment.tz.guess();
  }

  return passedTimezone || guessedTimezone;
}
