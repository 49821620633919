import { useEffect } from 'react';
import useApi from '../../../hooks/useApi';
import featureFlagsApi from '../../../api/feature_flags';
import { FeatureFlag } from '../../../data/models';
export default function useFeatureFlags(enabled: boolean = true): {
    featureFlags: FeatureFlag[];
    loading: boolean;
    loadFeatureFlags: () => void;
} {
    const {
        data: featureFlags,
        error,
        loading,
        request: loadFeatureFlags,
    } = useApi(featureFlagsApi.getFeatureFlags, []);

    useEffect(() => {
        if (!enabled) return;
        loadFeatureFlags();
    }, [enabled])

    return {
        featureFlags,
        loading,
        loadFeatureFlags,
    }
}