import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import mapLayersApi from '../../../api/map_layers';
import useApi from '../../../hooks/useApi';
import { maxMapLayerCount } from '../../../utility/mapLayerConfig';
import MapBoxMapLayer from './MapBoxMapLayer';
import MapBoxMapLayersButton from './MapBoxMapLayersButton';
import useActionCable, {
  PROJECT_MAP_LAYERS_CHANNEL,
} from '../../../hooks/useActionCable';
export default function MapBoxMapLayers({
  map,
  maxHeight,
  canAddLayers = true,
  canEditLayers = true,
  canToggleLayers = true,
  onLayersChosen,
  defaultLayers = [],
}) {
  const { project_id, share_id, shareable_id } = useParams(); //We're making an assumption that shareable_id will only be for reports - which is true for now
  const {
    data: { records: layers },
    loading,
    request: loadLayers,
  } = useApi(
    shareable_id
      ? mapLayersApi.getReportMapLayers
      : mapLayersApi.getProjectMapLayers,
    { records: [], pagy: {} },
    true
  );

  const [chosenLayers, setChosenLayers] = useState([]);
  const [allLayers, setAllLayers] = useState([]);

  const { receivedMessages } = useActionCable(
    PROJECT_MAP_LAYERS_CHANNEL,
    {
      objectId: project_id,
    },
    !!project_id
  );

  useEffect(() => {
    loadLayers(shareable_id || project_id, {
      items: maxMapLayerCount,
      share_id,
    });
  }, [receivedMessages.length]);

  useEffect(() => {
    setChosenLayers(defaultLayers);
  }, [defaultLayers.map(({ objectId }) => objectId).join(',')]);

  useEffect(() => {
    setAllLayers(layers);
  }, [layers]);

  function setLayersPicked(layers) {
    setChosenLayers(layers);
    onLayersChosen?.(layers);
    setAllLayers((existingLayers) => [
      ...existingLayers.map((layer) => {
        const foundLayer = layers.find((l) => l.objectId === layer.objectId);
        return foundLayer ? foundLayer : layer;
      }),
    ]);
  }

  return (
    <>
      {(canAddLayers || canToggleLayers || canEditLayers) && (
        <MapBoxMapLayersButton
          key={`choose_layer_project_${shareable_id || project_id}`}
          layers={allLayers}
          chosenLayers={chosenLayers}
          loading={loading}
          onLayersPicked={setLayersPicked}
          map={map}
          maxHeight={maxHeight}
          canAddLayers={canAddLayers}
          canEdit={canEditLayers}
        />
      )}
      {map &&
        chosenLayers.map((layer) => (
          <MapBoxMapLayer
            key={`mapBoxMapLayer${layer.objectId}`}
            layer={layer}
            map={map}
          />
        ))}
    </>
  );
}
