import React from 'react';
const Table = ({ children }: {
    children: React.ReactNode
}) => {
    return <div className="bg-white shadow-lg rounded-sm border border-gray-200 relative mb-20">
        {children}
    </div>
}

const Header = ({ children }: {
    children: React.ReactNode
}) => <header className="px-5 py-4 justify-between items-center flex">
        {children}
    </header>

const Body = ({ children }: {
    children: React.ReactNode
}) => <div className="overflow-x-auto">
        <table className="table-auto w-full">
            {children}
        </table>
    </div>

const Thead = ({ children }: {
    children: React.ReactNode
}) => <thead className="text-xs font-semibold uppercase text-gray-500 bg-gray-50 border-t border-b border-gray-200">
        {children}
    </thead>

const Th = ({ children }: {
    children: React.ReactNode
}) => <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="font-semibold text-left">
            {children}
        </div>
    </th>

const Tbody = ({ children }: {
    children: React.ReactNode
}) => <tbody className="text-sm divide-y divide-gray-200">
        {children}
    </tbody>

const Tr = ({ children, className = "", ...rest }: {
    children: React.ReactNode,
    className?: string,
    rest?: React.HTMLAttributes<HTMLTableRowElement>,
}) => <tr className={className} {...rest}>
        {children}
    </tr>

const Td = ({ children, colSpan, className = "px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px", ...rest }: {
    children: React.ReactNode,
    colSpan?: number
    className?: string,
    rest?: React.HTMLAttributes<HTMLTableColElement>,
}) => <td colSpan={colSpan} className={className} {...rest}>
        {children}
    </td>


export default {
    Table,
    Header,
    Body,
    Thead,
    Th,
    Tbody,
    Td,
    Tr
}