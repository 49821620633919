import React, { useState, useRef, useEffect } from 'react';
import { BiEnvelope } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import joinsApi from '../../api/joins';
import useApi from '../../hooks/useApi';
import Transition from '../../utils/Transition';
import JoinNotification from '../joins/JoinNotification';
import Loader from '../utility/Loader';

function Notifications() {
  const {
    data: joins,
    error,
    loading,
    request: getJoins,
  } = useApi(joinsApi.getJoins);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  useEffect(() => {
    getJoins();
  }, []);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <div className="relative inline-flex ml-3">
      <button
        ref={trigger}
        data-testid={'notifications-button'}
        className={`w-8 h-8 flex focus:ring-0 items-center justify-center bg-gray-100 hover:bg-gray-200 transition duration-150 rounded-full ${
          dropdownOpen && 'bg-gray-200'
        }`}
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <span className="sr-only">Notifications</span>
        <BiEnvelope size={18} className="text-gray" />
        {/* <svg className="w-4 h-4" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path className="fill-current text-gray-500" d="M6.5 0C2.91 0 0 2.462 0 5.5c0 1.075.37 2.074 1 2.922V12l2.699-1.542A7.454 7.454 0 006.5 11c3.59 0 6.5-2.462 6.5-5.5S10.09 0 6.5 0z" />
          <path className="fill-current text-gray-400" d="M16 9.5c0-.987-.429-1.897-1.147-2.639C14.124 10.348 10.66 13 6.5 13c-.103 0-.202-.018-.305-.021C7.231 13.617 8.556 14 10 14c.449 0 .886-.04 1.307-.11L15 16v-4h-.012C15.627 11.285 16 10.425 16 9.5z" />
        </svg> */}
        {joins.length > 0 && (
          <div className="absolute top-0 right-0 w-2.5 h-2.5 bg-red-500 border-2 border-white rounded-full"></div>
        )}
      </button>

      <Transition
        className="origin-top-right z-10 absolute top-full right-0 -mr-48 sm:mr-0 min-w-80 bg-white border border-gray-200 py-1.5 rounded shadow-lg overflow-hidden mt-1"
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div ref={dropdown}>
          {!loading && (
            <div
              className={`text-xs font-semibold text-gray-400 uppercase pt-1.5 pb-2 px-4 ${
                joins.length === 0 && 'text-center'
              }`}
            >
              {joins.length === 0 && 'No'} Notifications
            </div>
          )}
          {loading && (
            <div className="w-full flex justify-center py-2">
              <Loader color="text-black" />
            </div>
          )}
          {!loading && (
            <ul data-testid="notifications-list">
              {joins.map((join) => (
                <JoinNotification
                  key={`join_${join.objectId}`}
                  onDecline={getJoins}
                  join={join}
                />
              ))}
            </ul>
          )}
        </div>
      </Transition>
    </div>
  );
}

export default Notifications;
