import client from './client';
const endpoint = '/report_pins';

const getReportPin = (reportId, reportPinId, params = {}) =>
  client.get(`/reports/${reportId}${endpoint}/${reportPinId}`, params);
const updateReportPin = (reportId, pinId, report_pin) =>
  client.put(`/reports/${reportId}${endpoint}/${pinId}`, { report_pin });
const deleteReportPin = (reportId, pinId) =>
  client.delete(`/reports/${reportId}${endpoint}/${pinId}`);
const restoreReportPin = (reportId, pinId) =>
  client.put(`/reports/${reportId}${endpoint}/${pinId}/restore`);

export default {
  getReportPin,
  updateReportPin,
  deleteReportPin,
  restoreReportPin,
};
