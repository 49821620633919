import { useEffect, useState, useRef } from 'react';
import { Link, Redirect } from 'react-router-dom';
import useModal from '../../hooks/useModal';
import Modal, { ModalHeader, ModalSuccessView, ModalBody, ModalFooter } from '../utility/Modal';
import moment from 'moment-timezone';
import workspaceMembersApi from '../../api/workspace_members';
import projectMembersApi from '../../api/project_members';
import Loader from '../utility/Loader';
export default function JoinNotification({ join, onDecline }) {
  const { open: modalOpen, setOpen: setModalOpen } = useModal();
  const [accepted, setAccepted] = useState();
  const [declined, setDeclined] = useState();
  const [accepting, setAccepting] = useState(false);
  const [declining, setDeclining] = useState(false);

  const savedTimer = useRef(null);

  let joinType = join.workspace ? 'workspace' : 'project';
  let joinText = `You've been invited to join a ${joinType}`;

  const acceptInvitation = async () => {
    setAccepting(true);
    if (joinType === 'workspace')
      await workspaceMembersApi.acceptWorkspaceMembership(join.objectId);
    else projectMembersApi.acceptProjectMembership(join.objectId);
    setAccepted(true);
    setAccepting(false);
  };

  const declineInvitation = async () => {
    setDeclining(true);
    if (joinType === 'workspace')
      await workspaceMembersApi.declineWorkspaceMembership(join.objectId);
    else await projectMembersApi.declineProjectMembership(join.objectId);
    setDeclined(true);
    setDeclining(false);
  };

  useEffect(() => {
    if (declined) {
      savedTimer.current = setTimeout(() => {
        setModalOpen(false);
        onDecline?.();
      }, 2000);
    }
  }, [declined]);

  if (accepted && join[joinType]) {
    return (
      <Redirect
        to={{
          pathname:
            joinType === 'workspace'
              ? `/${join[joinType].objectId}/projects`
              : `/${join[joinType].workspace.objectId}/projects/${join[joinType].objectId}/details`,
          state: { success: `Successfully joined ${join[joinType].name}` },
        }}
      />
    );
  }

  return (
    <>
      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
        <ModalHeader
          title={`Join ${joinType}`}
          onClose={() => setModalOpen(false)}
        />
        <ModalBody>
          {!declined && (
            <div className="text-center pb-3">
              <p className="font-black text-gray-900 text-2xl mb-2">
                Ready to join {join[joinType].name}?
              </p>
              <p>
                You were invited to join {joinType}{' '}
                <span className="font-semibold">{join[joinType].name}</span> by{' '}
                <span className="font-semibold">{join.creator?.name}.</span>
              </p>
            </div>
          )}
          {declined && (
            <ModalSuccessView
              text={`Successfully ${accepted ? 'joined workspace' : 'declined workspace invitation'
                }`}
            />
          )}
        </ModalBody>

        {!declined && (
          <ModalFooter>
            <button
              className="modal-save-btn"
              type="button"
              data-testid="accept-button"
              disabled={accepting || declining}
              onClick={acceptInvitation}
            >
              Accept
              {accepting && <Loader />}
            </button>
            <button
              className="modal-remove-btn"
              type="button"
              disabled={accepting || declining}
              onClick={declineInvitation}
            >
              Decline
              {declining && <Loader />}
            </button>
          </ModalFooter>
        )}
      </Modal>
      <li className="join border-b border-gray-200 last:border-0">
        <Link
          className="block py-2 px-4 hover:bg-gray-50 focus:outline-none focus:ring-0"
          to="#0"
          onClick={() => setModalOpen(true)}
        >
          <span className="block text-sm mb-2">
            👋 <span className="font-medium text-gray-800">{joinText}</span>{' '}
            {join.creator.name} invited you to join their {joinType}{' '}
            <span className="font-semibold">{join[joinType].name}.</span>
          </span>
          <span className="block text-xs font-medium text-gray-400">
            {moment(join.created_at).fromNow()}
          </span>
        </Link>
      </li>
    </>
  );
}
