/* eslint-disable no-bitwise */
/* eslint-disable eqeqeq */
/* eslint-disable no-mixed-operators */
/* eslint-disable import/prefer-default-export */
const uuidv4 = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0;
    const v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

export default uuidv4;
