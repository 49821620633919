import { Formik } from 'formik';
import { memo, useEffect, useState } from 'react';
import { FaFolderPlus } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import foldersApi from '../../api/folders';
import useAuth from '../../auth/useAuth';
import useApi from '../../hooks/useApi';
import useModal from '../../hooks/useModal';
import Modal, { ModalHeader } from '../utility/Modal';

function AddFolderModal({
  currentFolder,
  onCreate,
  folder: passedFolder = {},
}) {
  const {
    data: created_folder,
    error,
    loading: saving,
    request: addFolder,
  } = useApi(foldersApi.addFolder, null);
  const {
    data: updated_folder,
    error: update_error,
    loading: updating,
    request: updateFolder,
  } = useApi(foldersApi.updateFolder, null);
  const [folder, setFolder] = useState({});
  const [title, setTitle] = useState('Create New Folder');
  const { project_id } = useParams();
  const { user } = useAuth();
  const {
    open: modalOpen,
    toggle: toggleModal,
    setOpen: setModalOpen,
  } = useModal();
  const isCreateMode = !passedFolder.id;

  function submitFolder(data) {
    if (isCreateMode) {
      addFolder(project_id, {
        ...data,
        folder_id: currentFolder.id,
      });
    } else {
      updateFolder(project_id, passedFolder.objectId, data);
    }
  }

  const initialValues = {
    name: passedFolder?.name,
    description: passedFolder?.description,
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .required('Required')
      .min(4, 'Must be at least 4 characters')
      .max(128, 'Cannot be more than 128 charaters')
      .typeError('Required'),
    description: Yup.string()
      .nullable()
      .max(500, 'Description cannot be longer than 500 characters'),
  });

  useEffect(() => {
    if (!isCreateMode) {
      setTitle('Edit Folder');
    }
  }, []);

  useEffect(() => {
    if (created_folder) {
      toggleModal();
      onCreate(created_folder.objectId);
    }
  }, [created_folder]);

  useEffect(() => {
    if (updated_folder) {
      onCreate(updated_folder.objectId);
      toggleModal();
    }
  }, [updated_folder]);

  return (
    <>
      {isCreateMode ? (
        <div
          className="btn rounded hover:text-gray-50 hover:opacity-90 text-white bg-secondary cursor-pointer mr-3"
          onClick={toggleModal}
        >
          <FaFolderPlus size={24} /> <span className="ml-2">New folder</span>
        </div>
      ) : (
        <button
          className="focus:outline-none font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3"
          rel="noreferrer"
          href="#"
          onClick={toggleModal}
        >
          <span>Edit</span>
        </button>
      )}
      <Modal isOpen={modalOpen} onClose={toggleModal} modalClass={'rounded-lg'}>
        <ModalHeader title={title} onClose={toggleModal} />

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={submitFolder}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
          }) => {
            return (
              <div className="px-2 pt-2 text-left text-gray-900 text-base">
                <div className="grid grid-cols-1 relative">
                  <form>
                    <div className="p-5">
                      <div className="font-semibold px-2 mb-2">
                        Name <span className="text-red-500">*</span>
                      </div>
                      <div className="overflow-hidden mb-8">
                        <div className="bg-white border-b border-gray-200 flex items-center">
                          <input
                            type="text"
                            placeholder="New folder"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            className="px-2 bg-gray-50 placeholder-gray-500 relative text-sm border-0 w-full border-0 outline-none focus:outline-none"
                          />
                        </div>
                        <div className="ml-3 text-red-500">
                          {errors.name && touched.name && errors.name}
                        </div>
                      </div>

                      <div className="font-semibold px-2 mb-2">Description</div>
                      <div className="overflow-hidden mb-8">
                        <div className="bg-white border-b border-gray-200 flex items-center">
                          <input
                            type="text"
                            placeholder="Description"
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                            className="px-2 bg-gray-50 placeholder-gray-500 relative text-sm border-0 w-full border-0 outline-none focus:outline-none"
                          />
                        </div>
                        <div className="ml-3 text-red-500">
                          {errors.description &&
                            touched.description &&
                            errors.description}
                        </div>
                      </div>
                      <button
                        className="modal-save-btn"
                        type="submit"
                        onClick={handleSubmit}
                        disabled={updating || saving || errors?.description}
                      >
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            );
          }}
        </Formik>

        <div className="modal-footer">
          <button
            className="modal-close-btn"
            type="button"
            disabled={saving !== false}
            onClick={() => {
              toggleModal();
            }}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
}

export default memo(AddFolderModal);
