import { useCallback, useEffect, useMemo, useState } from 'react';
import { FaFileImport } from 'react-icons/fa';
import useModal from '../../hooks/useModal';
import colors from '../../styles/colors';
import Loader from '../utility/Loader';
import Modal, { ModalHeader, ModalSuccessView, ModalBody, ModalFooter } from '../utility/Modal';
import useFeatures from '../../hooks/useFeatures';
import AssetDropzone from '../assets/AssetDropzone';
import AssetUploadContainer from '../assets/AssetUploadContainer';
import AddPhotoItem, { EmptyPhotoItem, UploadCompleteIcon } from '../project/AddPhotoItem';
import MapBoxMap from '../maps/mapbox/MapBoxMap';
import useProjectPinTypes from './hooks/useProjectPinTypes';
import usePinCreationServices from './hooks/usePinCreationServices';
import ErrorView from '../utility/ErrorView';
import useSaved from '../../hooks/useSaved';
import Badge from '../shared/Badge';
import Progress from '../../utils/Progress';

const Title = () => <div className="flex" data-testid="importPinsTitle">Import Pins from photos
    <span className="ml-1"><Badge label="Beta" animated style={'green'} title="This feature is currently in Beta - don't expect perfection!" /></span>
</div>
function PinImportButton({
    containerClass = 'absolute m-4 rounded-md overflow-hidden right-5',
    buttonClass = 'text-sm cursor-pointer justify-between rounded-md bg-white hover:opacity-90 py-2 px-3 text-secondary font-semibold items-center flex',
    buttonText = 'Import Pins',
    afterImport,
    project,
}: {
    loadingPins?: boolean;
    containerClass?: string;
    buttonClass?: string;
    buttonText?: string;
    project: any,
    afterImport?: () => void;
}) {

    const { open, toggle } = useModal();
    const [savePhotos, setSavePhotos] = useState(false);
    const [pinsCreated, setPinsCreated] = useState(false);
    const [tempPins, setTempPins] = useState<any[]>([]);
    const [signedPhotos, setSignedPhotos] = useState<{ signedId: string, index: number }[]>([]);
    const { pinTypes, loading } = useProjectPinTypes(project.objectId);
    const { createdPins, errorBulkCreating, bulkCreatePins: createPins, bulkCreating: creating } = usePinCreationServices();

    const [files, setFiles] = useState<File[]>([]);
    const [locations, setLocations] = useState<any[]>([]);

    const resetModal = () => {
        setPinsCreated(false);
        setLocations([]);
        setSignedPhotos([]);
        setFiles([]);
    }

    const _onToggle = () => {
        toggle();
        resetModal();
        if (pinsCreated) afterImport?.();
    }
    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            setFiles((oldFiles) => [...oldFiles, ...acceptedFiles]);
        },
        [files]
    );

    const removeImageAtIndex = (index: number) => {
        setFiles((oldFiles) => [...oldFiles.filter((_, i) => i !== index)]);
        setLocations((oldLocations) => [...oldLocations.filter(({ index: i }) => i !== index)]);
    };

    const { features } = useFeatures();
    const allPhotosUploaded = useMemo(() => {
        return !!signedPhotos.length && signedPhotos.length === locations.length
    }, [signedPhotos.length, locations.length])

    const photoFiles = useMemo(() => {
        return <>
            {files.map((file, index) => {
                return (
                    <AddPhotoItem
                        key={`project_${project.objectId}_temp_image_${file.name}`}
                        file={file}
                        startSaving={savePhotos}
                        hidden={index > 8}
                        index={index}
                        locationRequired
                        onLocated={(index: number, gpsData: any) => setLocations((otherLocations) => [...otherLocations.filter(({ index: i }) => i !== index), { index, gpsData }])}
                        onSaved={(signedId: string, _: any, index: number) => setSignedPhotos((otherPhotos) => [...otherPhotos.filter(({ index: i }) => i !== index), { index, signedId }])}
                        passback={true}
                        description={'description'}
                        onRemove={removeImageAtIndex}
                        onSelect={undefined}
                    />
                );
            })}
            {files.length > 9 && <EmptyPhotoItem>
                <>
                    + {files.length - 9} More
                    {savePhotos && !allPhotosUploaded && <Loader color={colors.secondary} className='absolute' />}
                    {allPhotosUploaded && <UploadCompleteIcon />}
                </>
            </EmptyPhotoItem>}
        </>
    }, [files.length, savePhotos, allPhotosUploaded])

    const importPins = () => {
        setSavePhotos(true);
    }

    const pinsFromLocations = () => {

        const defaultPinType = pinTypes.find(({ name }) => ['General Pin', 'General'].includes(name));
        const defaultField = defaultPinType?.fieldsets?.[0]?.fields?.[0];
        const defaultFieldOption = defaultField?.field_options?.find?.(({ name }: { name: string }) => ['Other', 'Misc'].includes(name));

        return locations.map(({ index, gpsData }) => {
            return {
                index,
                color: defaultFieldOption?.color,
                coordinate_attributes: {
                    lat: gpsData.latitude,
                    lng: gpsData.longitude
                },
                coordinate: {
                    lat: gpsData.latitude,
                    lng: gpsData.longitude
                },
                pin_type: defaultPinType,
                pin_type_id: defaultPinType?.id,
                status: 'observation',
                field_values_attributes: [{
                    field_option_id: defaultFieldOption?.id,
                    field_id: defaultField?.id,
                    value: defaultFieldOption?.value
                }],
                notes_attributes: [
                    {
                        assets_attributes: [
                            {
                                file: signedPhotos.find(({ index: i }) => i === index)?.signedId
                            }
                        ]
                    }
                ]
            }
        }).sort((a, b) => a.index - b.index);
    }

    useEffect(() => {
        if (!locations.length) return;
        setTempPins(pinsFromLocations());
    }, [locations.length])

    useEffect(() => {
        if (!allPhotosUploaded) return;
        createPins(project.objectId, pinsFromLocations());
        setSavePhotos(false);
    }, [project.objectId, allPhotosUploaded])

    const { saved } = useSaved(pinsCreated, {
        afterSaved: () => {
            _onToggle();
        }
    });

    useEffect(() => {
        if (!createdPins) return;
        setPinsCreated(true);
    }, [createdPins])

    const importing = (creating || savePhotos);

    if (!features?.rollout?.import_pins_from_photos) return null;

    return (
        <>
            <div className={containerClass} onClick={() => {
                if (!loading) toggle()
            }}>
                <div
                    title={'Import Pins into your project'}
                    className={buttonClass}
                    data-testid="importPinsButton"
                >
                    <FaFileImport size={16} color={colors.secondary} className="mr-2" />{' '}
                    {buttonText}
                    {loading && <Loader color={colors.secondary} />}
                </div>
            </div>
            <Modal maxHeight="h-auto" dialogPadding="pt-20" modalScrolls={false} dialogScrolls isOpen={open} onClose={_onToggle}>
                <ModalHeader title={<Title />} onClose={_onToggle} />
                {errorBulkCreating && <ErrorView error={errorBulkCreating} />}
                <ModalBody>
                    {saved ? <div className="p-5">
                        <ModalSuccessView text={'Pins successfully imported to project'} />
                    </div> : <>
                        <AssetUploadContainer>
                            {photoFiles}
                        </AssetUploadContainer>

                        {importing && <>
                            <Progress current={signedPhotos.length} total={locations.length} />
                            <div className="flex justify-center items-center px-4 mb-3">
                                <p className="text-xs flex text-center">
                                    {signedPhotos.length} / <span className="font-medium ml-1">{locations.length} Photos uploaded</span>
                                </p>
                                {!allPhotosUploaded && <Loader color="black" size="3" />}
                            </div>
                        </>}
                        {!!files.length && <p className="px-3 py-1 text-xs text-blue-800 font-semibold bg-blue-50 rounded mb-2 text-center">All imported pins will be General pin types with Misc category</p>}
                        {!locations.length && <AssetDropzone accept={'image/jpeg, image/png'} disabled={false} onDrop={onDrop} />}

                        {!!locations.length && <AssetDropzone noClick bodyClass='h-80 relative bg-gray-100 rounded-md overflow-hidden' accept={'image/jpeg, image/png'} disabled={false} onDrop={onDrop} >
                            <MapBoxMap
                                children={undefined}
                                project={project}
                                pins={tempPins}
                                fitToPins={true}
                                passedSelectedPin={undefined}
                                mapState={undefined}
                                onSelectPin={() => { }}
                                onMapClick={undefined}
                                onBoundsUpdated={undefined}
                                onPitchUpdated={undefined}
                                onBearingUpdated={undefined}
                                onLayersChosen={undefined}
                                locked={undefined}
                                defaultBounds={undefined}
                                mapTypeToggle={false}
                            />
                        </AssetDropzone>}
                        {!!files.length && <p className="px-3 py-1 text-xs text-gray-800 font-semibold bg-gray-100 rounded-b mb-2 text-center">Drop photos on map to upload more</p>}

                    </>}
                </ModalBody>
                <ModalFooter extraClass='sticky bottom-0 z-10'>
                    {!pinsCreated && <button data-testId="submitImportPins" disabled={importing || !locations.length} className="modal-save-btn" type="button" onClick={importPins}>
                        Import {importing && <Loader color={colors.secondary} />}
                    </button>}

                    <button disabled={importing} className="modal-close-btn" type="button" onClick={_onToggle}>
                        Close
                    </button>
                </ModalFooter>
            </Modal >
        </>
    );
}

export default PinImportButton;
