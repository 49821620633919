import { useEffect, useRef, useState } from 'react';
import Modal, { ModalHeader, ModalSuccessView } from '../utility/Modal';
import useApi from '../../hooks/useApi';
import Loader from '../utility/Loader';
import usersApi from '../../api/users';

export default function ConfirmEmailModal({
  email,
  open,
  onClose,
  onSaved,
  userObjectId,
}) {
  const {
    data,
    error,
    loading: confirming,
    request: confirmUserEmail,
  } = useApi(usersApi.confirmUserEmail, null);
  const [confirmed, setConfirmed] = useState(false);
  const confirmedTimer = useRef();

  const confirmEmail = () => confirmUserEmail(userObjectId);

  useEffect(() => {
    if (!data) return;
    onSaved?.(data);
    setConfirmed(true);
  }, [data]);

  useEffect(() => {
    const closeAndReset = () => {
      onClose?.();
      setConfirmed(false);
    };
    if (confirmed) confirmedTimer.current = setTimeout(closeAndReset, 3000);
  }, [confirmed]);

  return (
    <Modal isOpen={open} onClose={onClose}>
      <ModalHeader title={'Confirm user email'} onClose={onClose} />
      <div className="px-2 pt-2">
        <div className="p-5">
          {!data && (
            <>
              <div className="mb-4">
                <div className="font-semibold">Confirm user email?</div>
              </div>
              <div>
                We'll set user{' '}
                <em>
                  <u>{email}</u>
                </em>{' '}
                to confirmed.
              </div>
            </>
          )}
          {data && <ModalSuccessView text={'User email confirmed!'} />}
        </div>

        {!data && (
          <div className="modal-footer">
            <button
              className="modal-save-btn"
              type="button"
              onClick={confirmEmail}
              disabled={confirming}
            >
              Confirm {confirming && <Loader />}
            </button>
            <button
              className="modal-close-btn"
              type="button"
              onClick={onClose}
              disabled={confirming}
            >
              Cancel
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
}
