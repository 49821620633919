import React, { useCallback, useEffect, useRef, useState } from 'react';
import useApi from '../../hooks/useApi';
import authApi from '../../api/auth';
import Loader from '../utility/Loader';
import { BiCheckCircle } from 'react-icons/bi';
import formatPhoneNumber from '../../utility/formatPhoneNumber';
import { useDropzone } from 'react-dropzone';
import ErrorView from '../utility/ErrorView';
import toBase64 from '../../utility/toBase64';
import DeleteUserButton from './DeleteUserButton';

function SettingsContent() {
  const [updatingField, setUpdatingField] = useState(null);
  const [user, setUser] = useState(null);
  const [updatingValue, setUpdatingValue] = useState(null);
  const [emailSync, setEmailSync] = useState(false);
  const [textSync, setTextSync] = useState(false);
  const [pushSync, setPushSync] = useState(false);
  const [saved, setSaved] = useState(false);
  const savedTimer = useRef(null);

  const [file, setFile] = useState(null);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFile(acceptedFiles[0]);
    },
    [file]
  );

  const { getRootProps, getInputProps, isDragActive, rootRef } = useDropzone({
    onDrop,
  });
  const { data, error, loading, request: getMe } = useApi(authApi.me, null);
  const {
    data: updatedUser,
    error: updateUserError,
    loading: updatingUser,
    request: updateMe,
  } = useApi(authApi.update, null);

  const savePhoto = async () => {
    let base64File = await toBase64(file);
    updateMe({ profile_photo: { filename: file.name, data: base64File } });
  };

  const saveUserField = () => {
    if (updatingField === 'password') updateMe({ ...updatingValue });
    else updateMe({ [updatingField]: updatingValue });
  };
  const fieldUpdate = ({ target: { value, name } }) => {
    if (updatingField === 'phone') value = formatPhoneNumber(value);
    setUpdatingValue(value);
  };

  const setUpdating = (field) => {
    setUpdatingField(field);
    setUpdatingValue(user[field]);
  };

  const clearUpdating = () => {
    setUpdatingField(null);
    setUpdatingValue(null);
    setFile(null);
  };

  useEffect(() => {
    getMe();
  }, []);

  useEffect(() => {
    data && setUser(data);
  }, [data]);

  useEffect(() => {
    if (updatedUser) {
      setSaved(true);
      setUser({ ...updatedUser });
      clearUpdating();
    }
  }, [updatedUser]);

  useEffect(() => {
    if (saved) {
      savedTimer.current = setTimeout(() => {
        setSaved(false);
      }, 2000);
    }
  }, [saved]);

  if (loading || !user)
    return (
      <div className="flex-grow flex p-10 justify-center">
        <Loader color="black" margin={'m-0'} />
      </div>
    );

  return (
    <div className="bg-white shadow-lg rounded-sm border border-gray-200 mb-8">
      <div className="flex flex-col md:flex-row md:-mr-px">
        <div className="flex-grow">
          {/* Panel body */}
          <div className="p-10 relative">
            {updateUserError && (
              <ErrorView error={updateUserError} extraClass={'mb-4'} />
            )}
            <div className="absolute right-5 top-5">
              {updatingUser && <Loader color="black" />}
              {saved && (
                <BiCheckCircle
                  size={24}
                  className="bg-green-500 text-white rounded-full overflow-hidden"
                />
              )}
            </div>
            <div className="grid grid-cols-2">
              <div className="col-span-full lg:col-span-1 space-y-6 lg:border-r-2">
                <section>
                  <h2 className="text-xl text-gray-800 font-bold mb-5">
                    Profile Photo
                  </h2>
                  <div className="flex items-center focus:outline-none">
                    <div
                      className={`w-32 h-32 rounded-full overflow-hidden cursor-pointer hover:opacity-80 focus:outline-none ${
                        !isDragActive
                          ? ''
                          : 'border-3 border-secondary bg-blue-100 opacity-50 bg-tertiary'
                      }`}
                      {...getRootProps()}
                      title="Click or Drag and Drop to change photo"
                    >
                      <img
                        className="w-full h-full object-cover"
                        src={
                          file
                            ? URL.createObjectURL(file)
                            : user.profile_photos.original
                        }
                        alt="User upload"
                      />
                      <input {...getInputProps()} />
                    </div>
                    {file ? (
                      <>
                        <button
                          className="btn rounded-sm bg-tertiary shadow-sm text-white focus:outline-none hover:opacity-80 ml-4"
                          onClick={savePhoto}
                          disabled={updatingUser}
                        >
                          Save Photo
                        </button>
                        <button
                          className="btn rounded-sm bg-gray-100 shadow-sm text-gray-900 focus:outline-none hover:opacity-80 ml-2"
                          disabled={updatingUser}
                          onClick={() => setFile(null)}
                        >
                          Cancel
                        </button>
                      </>
                    ) : (
                      <button
                        className="btn rounded-sm border-gray-200 text-secondary shadow-sm focus:outline-none hover:opacity-80 ml-4"
                        onClick={() => rootRef.current.click()}
                      >
                        Change
                      </button>
                    )}
                  </div>
                </section>
                <section>
                  <h2 className="text-xl text-gray-800 font-bold mb-1">
                    Title
                  </h2>
                  {updatingField === 'title' ? (
                    <input
                      className="text-sm focus:outline-none focus:ring-0 focus:border-secondary focus:border-4 border border-gray-200 py-2 px-4 rounded-md"
                      autoFocus
                      placeholder="Title"
                      defaultValue={user.title}
                      value={updatingValue}
                      onChange={fieldUpdate}
                    />
                  ) : (
                    <div className="text-sm">{user.title || 'None'}</div>
                  )}
                  <div className="mt-5">
                    {updatingField === 'title' ? (
                      <>
                        <button
                          className="btn rounded bg-tertiary shadow-sm text-white focus:outline-none hover:opacity-80"
                          onClick={saveUserField}
                        >
                          Save Title
                        </button>
                        <button
                          className="btn rounded bg-gray-100 shadow-sm text-gray-900 focus:outline-none hover:opacity-80 ml-2"
                          onClick={clearUpdating}
                        >
                          Cancel
                        </button>
                      </>
                    ) : (
                      <button
                        className="btn rounded border-gray-200 shadow-sm text-secondary focus:outline-none hover:opacity-80"
                        onClick={() => setUpdating('title')}
                      >
                        {user.title ? 'Update' : '+ Add'} Title
                      </button>
                    )}
                  </div>
                </section>
                <section>
                  <h2 className="text-xl text-gray-800 font-bold mb-1">
                    Email
                  </h2>
                  {updatingField === 'email' ? (
                    <input
                      className="text-sm focus:outline-none focus:ring-0 focus:border-secondary focus:border-4 border border-gray-200 py-2 px-4 rounded-md"
                      autoFocus
                      defaultValue={user.email}
                      onChange={fieldUpdate}
                    />
                  ) : (
                    <div className="text-sm">{user.email}</div>
                  )}
                  <div className="mt-5">
                    {updatingField === 'email' ? (
                      <>
                        <button
                          className="btn rounded bg-tertiary shadow-sm text-white focus:outline-none hover:opacity-80"
                          onClick={() => setUpdatingField(null)}
                        >
                          Save Email
                        </button>
                        <button
                          className="btn rounded bg-gray-100 shadow-sm text-gray-900 focus:outline-none hover:opacity-80 ml-2"
                          onClick={clearUpdating}
                        >
                          Cancel
                        </button>
                      </>
                    ) : (
                      <button
                        className="hidden btn border-gray-200 shadow-sm text-secondary focus:outline-none hover:opacity-80"
                        onClick={() => setUpdating('email')}
                      >
                        Update Email
                      </button>
                    )}
                  </div>
                </section>
                <section>
                  <h2 className="text-xl text-gray-800 font-bold mb-1">
                    Password
                  </h2>
                  {updatingField === 'password' ? (
                    <>
                      <p className="text-xs text-secondary mb-1 font-semibold">
                        Current Password
                      </p>
                      <input
                        type="password"
                        className="text-sm focus:outline-none focus:ring-0 focus:border-secondary focus:border-4 border border-gray-200 py-2 px-4 rounded-md mb-2"
                        onChange={({ target: { value } }) =>
                          setUpdatingValue((existing) => ({
                            ...existing,
                            current_password: value,
                          }))
                        }
                        value={updatingValue?.current_password}
                        autoFocus
                        placeholder="Current Password"
                      />
                      <p className="text-xs text-secondary mb-1 font-semibold">
                        New Password
                      </p>
                      <input
                        type="password"
                        className="text-sm focus:outline-none focus:ring-0 focus:border-secondary focus:border-4 border border-gray-200 py-2 px-4 rounded-md mb-2"
                        onChange={({ target: { value } }) =>
                          setUpdatingValue((existing) => ({
                            ...existing,
                            password: value,
                          }))
                        }
                        value={updatingValue?.password}
                        placeholder="New Password"
                      />
                      <p className="text-xs text-secondary mb-1 font-semibold">
                        Confirm New Password
                      </p>
                      <input
                        type="password"
                        className="text-sm focus:outline-none focus:ring-0 focus:border-secondary focus:border-4 border border-gray-200 py-2 px-4 rounded-md mb-2"
                        onChange={({ target: { value } }) =>
                          setUpdatingValue((existing) => ({
                            ...existing,
                            password_confirmation: value,
                          }))
                        }
                        value={updatingValue?.password_confirmation}
                        placeholder="Confirm Password"
                      />
                    </>
                  ) : (
                    <div className="text-sm inline-block align-baseline">
                      *********
                    </div>
                  )}
                  <div className="mt-5">
                    {updatingField === 'password' ? (
                      <>
                        <button
                          className="btn rounded bg-tertiary shadow-sm text-white focus:outline-none hover:opacity-80"
                          onClick={saveUserField}
                        >
                          Save Password
                        </button>
                        <button
                          className="btn rounded bg-gray-100 shadow-sm text-gray-900 focus:outline-none hover:opacity-80 ml-2"
                          onClick={clearUpdating}
                        >
                          Cancel
                        </button>
                      </>
                    ) : (
                      <button
                        className="btn rounded border-gray-200 shadow-sm text-secondary focus:outline-none hover:opacity-80"
                        onClick={() => setUpdatingField('password')}
                      >
                        Update Password
                      </button>
                    )}
                  </div>
                </section>
              </div>
              <div className="col-span-full pt-6 lg:pt-0 lg:col-span-1 space-y-6 lg:pl-10">
                <section>
                  <h2 className="text-xl text-gray-800 font-bold mb-1">Name</h2>
                  {updatingField === 'name' ? (
                    <input
                      className="text-sm focus:outline-none focus:ring-0 focus:border-secondary focus:border-4 border border-gray-200 py-2 px-4 rounded-md"
                      autoFocus
                      placeholder="Name"
                      defaultValue={user.name}
                      value={updatingValue}
                      onChange={fieldUpdate}
                    />
                  ) : (
                    <div className="text-sm">{user.name || 'None'}</div>
                  )}
                  <div className="mt-5">
                    {updatingField === 'name' ? (
                      <>
                        <button
                          className="btn rounded bg-tertiary shadow-sm text-white focus:outline-none hover:opacity-80"
                          onClick={saveUserField}
                        >
                          Save Name
                        </button>
                        <button
                          className="btn rounded bg-gray-100 shadow-sm text-gray-900 focus:outline-none hover:opacity-80 ml-2"
                          onClick={clearUpdating}
                        >
                          Cancel
                        </button>
                      </>
                    ) : (
                      <button
                        className="btn rounded border-gray-200 shadow-sm text-secondary focus:outline-none hover:opacity-80"
                        onClick={() => setUpdating('name')}
                      >
                        {user.name ? 'Update' : '+ Add'} Name
                      </button>
                    )}
                  </div>
                </section>
                <section>
                  <h2 className="text-xl text-gray-800 font-bold mb-1">
                    Phone
                  </h2>
                  {updatingField === 'phone' ? (
                    <input
                      className="text-sm focus:outline-none focus:ring-0 focus:border-secondary focus:border-4 border border-gray-200 py-2 px-4 rounded-md"
                      autoFocus
                      placeholder="(555) 555-5555"
                      defaultValue={user.phone}
                      value={updatingValue}
                      onChange={fieldUpdate}
                    />
                  ) : (
                    <div className="text-sm">{user.phone || 'None'}</div>
                  )}
                  <div className="mt-5">
                    {updatingField === 'phone' ? (
                      <>
                        <button
                          className="btn rounded bg-tertiary shadow-sm text-white focus:outline-none hover:opacity-80"
                          onClick={saveUserField}
                        >
                          Save Phone
                        </button>
                        <button
                          className="btn rounded bg-gray-100 shadow-sm text-gray-900 focus:outline-none hover:opacity-80 ml-2"
                          onClick={clearUpdating}
                        >
                          Cancel
                        </button>
                      </>
                    ) : (
                      <button
                        className="btn rounded border-gray-200 shadow-sm text-secondary focus:outline-none hover:opacity-80"
                        onClick={() => setUpdating('phone')}
                      >
                        {user.phone ? 'Update' : '+ Add'} Phone Number
                      </button>
                    )}
                  </div>
                </section>
                <section>
                  <h2 className="text-xl text-gray-800 font-bold mb-1">
                    Qualifications
                  </h2>
                  {updatingField === 'qualifications' ? (
                    <input
                      className="text-sm focus:outline-none focus:ring-0 focus:border-secondary focus:border-4 border border-gray-200 py-2 px-4 rounded-md"
                      autoFocus
                      placeholder="CEPSCI"
                      defaultValue={user.qualifications}
                      value={updatingValue}
                      onChange={fieldUpdate}
                    />
                  ) : (
                    <div className="text-sm">
                      {user.qualifications || 'None'}
                    </div>
                  )}
                  <div className="mt-5">
                    {updatingField === 'qualifications' ? (
                      <>
                        <button
                          className="btn rounded bg-tertiary shadow-sm text-white focus:outline-none hover:opacity-80"
                          onClick={saveUserField}
                        >
                          Save Qualifications
                        </button>
                        <button
                          className="btn rounded bg-gray-100 shadow-sm text-gray-900 focus:outline-none hover:opacity-80 ml-2"
                          onClick={clearUpdating}
                        >
                          Cancel
                        </button>
                      </>
                    ) : (
                      <button
                        className="btn rounded border-gray-200 shadow-sm text-secondary focus:outline-none hover:opacity-80"
                        onClick={() => setUpdating('qualifications')}
                      >
                        {user.phone ? 'Update' : '+ Add'} Qualifications
                      </button>
                    )}
                  </div>
                </section>
                <section className="hidden">
                  <h2 className="text-xl text-gray-800 font-bold mb-1">
                    Notification Preferences
                  </h2>
                  <div className="text-sm">
                    Receive notifications on project updates via:
                  </div>
                  <div className="flex items-center mt-5">
                    <div className="text-sm text-gray-400 italic mr-3">
                      {emailSync ? 'On' : 'Off'}
                    </div>
                    <div className="form-switch focus-within:shadow-outline">
                      <input
                        type="checkbox"
                        id="emailtoggle"
                        className="sr-only"
                        onChange={() => setEmailSync(!emailSync)}
                      />
                      <label className="bg-gray-300" htmlFor="emailtoggle">
                        <span
                          className="bg-white shadow-sm"
                          aria-hidden="true"
                        ></span>
                        <span className="sr-only">Enable notifications</span>
                      </label>
                    </div>
                    <p className="ml-3 font-semibold">Email</p>
                  </div>
                  <div className="flex items-center mt-5">
                    <div className="text-sm text-gray-400 italic mr-3">
                      {textSync ? 'On' : 'Off'}
                    </div>
                    <div className="form-switch focus-within:shadow-outline">
                      <input
                        type="checkbox"
                        id="texttoggle"
                        className="sr-only"
                        onChange={() => setTextSync(!textSync)}
                      />
                      <label className="bg-gray-300" htmlFor="texttoggle">
                        <span
                          className="bg-white shadow-sm"
                          aria-hidden="true"
                        ></span>
                        <span className="sr-only">Enable notifications</span>
                      </label>
                    </div>
                    <p className="ml-3 font-semibold">Text Message</p>
                  </div>
                  <div className="flex items-center mt-5">
                    <div className="text-sm text-gray-400 italic mr-3">
                      {pushSync ? 'On' : 'Off'}
                    </div>
                    <div className="form-switch focus-within:shadow-outline">
                      <input
                        type="checkbox"
                        id="pushtoggle"
                        className="sr-only"
                        onChange={() => setPushSync(!pushSync)}
                      />
                      <label className="bg-gray-300" htmlFor="pushtoggle">
                        <span
                          className="bg-white shadow-sm"
                          aria-hidden="true"
                        ></span>
                        <span className="sr-only">Enable notifications</span>
                      </label>
                    </div>
                    <p className="ml-3 font-semibold">In-App Push</p>
                  </div>
                </section>
              </div>
            </div>
          </div>
          <div className="p-10 relative">
            <h1 className="text-xl font-bold text-red-600 mb-2">
              Delete Account
            </h1>
            <p className="text-red-800 mb-3 text-xs">
              All data associated with your account will be deleted from <br />
              SiteMarker and any external services SiteMarker uses.
            </p>
            <DeleteUserButton />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SettingsContent;
