import React, { useState, useEffect } from 'react';
import { useCallback } from 'react';
import { BiCube } from 'react-icons/bi';
import { FiGlobe, FiMap } from 'react-icons/fi';
import Button from '../../shared/Button';
import { mapboxStyleUrls } from '../../../data/models';

const removeBuildingLayersFromMap = (map) => {
  if (map.getLayer('add-3d-buildings')) map.removeLayer('add-3d-buildings');
};

const removeTerrainFromMap = (map) => {
  const demSource = map.getSource('mapbox-dem');
  if (!demSource) return;
  map.setTerrain();
  map.removeSource('mapbox-dem');
};

const setPitchOnMap = (map, pitch) => {
  const camera = map.getFreeCameraOptions();

  // Update camera pitch and bearing
  camera.setPitchBearing(pitch, map.getBearing());
  // Apply changes
  map.setFreeCameraOptions(camera);
};

const addTerrainToMap = (map) => {
  map.addSource('mapbox-dem', {
    type: 'raster-dem',
    url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
    tileSize: 512,
    maxzoom: 14,
  });
  // add the DEM source as a terrain layer with exaggerated height
  map.setTerrain({ source: 'mapbox-dem', exaggeration: 1.5 });
};

const addBuildingLayersToMap = (map) => {
  const layers = map.getStyle().layers;
  const labelLayerId = layers.find(
    (layer) => layer.type === 'symbol' && layer.layout['text-field']
  ).id;

  // The 'building' layer in the Mapbox Streets
  // vector tileset contains building height data
  // from OpenStreetMap.
  map.addLayer(
    {
      id: 'add-3d-buildings',
      source: 'composite',
      'source-layer': 'building',
      filter: ['==', 'extrude', 'true'],
      type: 'fill-extrusion',
      minzoom: 15,
      paint: {
        'fill-extrusion-color': '#aaa',

        // Use an 'interpolate' expression to
        // add a smooth transition effect to
        // the buildings as the user zooms in.
        'fill-extrusion-height': [
          'interpolate',
          ['linear'],
          ['zoom'],
          15,
          0,
          15.05,
          ['get', 'height'],
        ],
        'fill-extrusion-base': [
          'interpolate',
          ['linear'],
          ['zoom'],
          15,
          0,
          15.05,
          ['get', 'min_height'],
        ],
        'fill-extrusion-opacity': 0.75,
      },
    },
    labelLayerId
  );
};

export default function MapBoxMapTypeToggle({ map }) {
  const [mapType, setMapType] = useState('satellite');

  const handleMapLoad = useCallback(() => {
    addTerrainToMap(map);
    addBuildingLayersToMap(map);
  }, [map]);

  const toggleMapStyle = useCallback(
    (newMapStyle) => {
      if (mapType === newMapStyle) return;
      if (newMapStyle !== '3D') {
        map.off('style.load', handleMapLoad);
        removeTerrainFromMap(map);
        removeBuildingLayersFromMap(map);
      }
      if (newMapStyle === 'default')
        map.setStyle(mapboxStyleUrls.streets);
      if (newMapStyle === 'satellite')
        map.setStyle(mapboxStyleUrls['satellite-streets']);
      if (newMapStyle === '3D') {
        if (mapType === 'satellite') handleMapLoad();
        else {
          map.on('style.load', handleMapLoad);
          map.setStyle(mapboxStyleUrls['satellite-streets']);
        }
      }
      setMapType(newMapStyle);
    },
    [map, mapType]
  );

  if (!map) return null;

  return (
    <div
      className={
        'bottom-0 left-0 absolute z-20 m-4 rounded-md overflow-hidden print:hidden bg-white rounded-lg shadow border p-3'
      }
    >
      <Button
        text="Street"
        color={mapType === 'default' ? 'secondary' : 'light'}
        spacing="mr-2"
        icon={<FiMap className="ml-2" />}
        onClick={() => toggleMapStyle('default')}
      />
      <Button
        text="Satellite"
        color={mapType === 'satellite' ? 'secondary' : 'light'}
        spacing="mr-2"
        icon={<FiGlobe className="ml-2" />}
        onClick={() => toggleMapStyle('satellite')}
      />
      <Button
        text="3D"
        color={mapType === '3D' ? 'secondary' : 'light'}
        spacing="mr-2"
        icon={<BiCube className="ml-2" />}
        onClick={() => toggleMapStyle('3D')}
      />
    </div>
  );
}
