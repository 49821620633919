import documentsApi from '../../../api/documents';
import useApi from '../../../hooks/useApi';
export default function useDocuments() {

    const {
        data: documentFile,
        loading: documentFileLoading,
        error: documentFileError,
        request: getDocumentFile,
    } = useApi(documentsApi.getDocumentFile, null);

    const {
        data: archivedDocumentData,
        loading: archiveDocumentLoading,
        error: archiveDocumentError,
        request: archiveDocument,
    } = useApi(documentsApi.archiveDocument, null);

    const {
        data: unarchivedDocumentData,
        loading: unarchiveDocumentLoading,
        error: unarchiveDocumentError,
        request: unarchiveDocument,
    } = useApi(documentsApi.unarchiveDocument, null);

    return {
        documentFile,
        documentFileLoading,
        documentFileError,
        getDocumentFile,
        archivedDocumentData,
        archiveDocumentLoading,
        archiveDocumentError,
        archiveDocument,
        unarchivedDocumentData,
        unarchiveDocumentLoading,
        unarchiveDocumentError,
        unarchiveDocument,
    }
}