import { useRef, useState } from 'react';
import { MdClose } from 'react-icons/md';
import Input from '../../shared/Input';
import uuidv4 from '../../../utility/uuidv4';
import Title from '../modal/Title';

export default function FieldNumeric({
    chosen = [],
    dataTestId,
    fieldId,
    onChosen,
    onRemove,
    title,
    required,
}) {
    const [inputValue, setInputValue] = useState();

    const addNumber = () => {
        if (!inputValue) return;

        onChosen({
            value: inputValue,
            objectId: uuidv4(),
            name: inputValue,
            field_id: fieldId
        }, {
            allows_multiple: true
        })

        setTimeout(() => setInputValue(''), 0)
    };

    return (
        <div>
            <div className="mb-6">
                <Title required={required}>{title}</Title>
                <div className="flex">
                    <Input data-testid={dataTestId} value={inputValue} className="w-20" placeholder="Size" suffix='"' onChange={({ target: { value } }) => setInputValue(value)} />
                    <button
                        className="bg-secondary rounded-md font-bold flex px-4 py-1 ml-2 text-white items-center cursor-pointer hover:opacity-80 shadow-sm"
                        type="button"
                        data-testid="addNumberButton"
                        onClick={addNumber}
                    >
                        Add
                    </button>
                </div>
                <div className="flex flex-row mt-2 flex-wrap">
                    {chosen.map((item) => {
                        return (
                            <button className="bg-secondary rounded-md font-bold flex px-2 py-1 mr-2 mt-2 text-white items-center cursor-pointer hover:opacity-80 shadow-sm"

                                onClick={() => onRemove(item)}
                            >
                                {item.value} "
                                <MdClose
                                    size={16}
                                    className="ml-2 mx-auto text-white font-bold"
                                />
                            </button>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
