import { memo, useEffect, useState } from 'react';
import Modal, { ModalHeader } from '../utility/Modal';
import useApi from '../../hooks/useApi';
import rolesApi from '../../api/roles';
import Loader from '../utility/Loader';
import workspaceMembersApi from '../../api/workspace_members';
import { BiCheckCircle } from 'react-icons/bi';
import useModal from '../../hooks/useModal';
import ErrorView from '../utility/ErrorView';

function EditPermissionsModal({
  workspaceId,
  workspaceMemberId,
  member,
  role: passedRole,
  button,
}) {
  const {
    open: modalOpen,
    toggle: toggleModal,
    setOpen: setModalOpen,
  } = useModal();
  const [workspaceMember, setWorkspaceMember] = useState({
    role_id: passedRole.id,
  });
  const [role, setRole] = useState(passedRole);
  const {
    data: roles,
    error,
    loading,
    request: getRoles,
  } = useApi(rolesApi.getWorkspaceRoles, []);
  const {
    data: updatedWorkspaceMember,
    error: updateError,
    loading: workspaceMemberLoading,
    request: updateWorkspaceMember,
  } = useApi(workspaceMembersApi.updateWorkspaceMember, null);
  const saveWorkspaceMember = () =>
    updateWorkspaceMember(workspaceId, workspaceMemberId, workspaceMember);

  useEffect(() => {
    modalOpen && getRoles(workspaceId, { member_id: workspaceMemberId });
  }, [modalOpen]);

  useEffect(() => {
    updatedWorkspaceMember && setRole(updatedWorkspaceMember.role);
  }, [updatedWorkspaceMember]);

  useEffect(() => {
    updatedWorkspaceMember && setModalOpen(false);
  }, [updatedWorkspaceMember]);

  const activeError = () => error || updateError;

  return (
    <div>
      {/* Button */}
      <a className="cursor-pointer" onClick={toggleModal}>
        {button || (
          <div className="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3">
            Edit Permissions
          </div>
        )}
      </a>
      <Modal isOpen={modalOpen} onClose={toggleModal}>
        <ModalHeader
          title={
            !loading && roles.length > 0 ? 'Edit Permissions' : 'Member Info'
          }
          onClose={toggleModal}
        />
        <div className="px-2 pt-2">
          {activeError() && (
            <ErrorView
              dismissable={true}
              error={activeError()}
              className={
                'bg-red-500 text-white px-3 py-2 rounded-0 text-lg text-center font-bold'
              }
              preface={''}
            />
          )}
          <div className="p-5">
            <div className="mb-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <img
                    className="rounded-full border-2 border-white float-left mr-2 w-16 h-16 object-cover"
                    src={
                      member.profile_photos?.medium ||
                      'https://bucketeer-d9937a0d-80e4-469a-91e2-6c86bd37d08f.s3.amazonaws.com/public/user_empty.png'
                    }
                  />
                  <div className="leading-snug">
                    <div className="font-semibold">
                      {member.name || member.email}
                    </div>
                    <div>{member.title}</div>
                    {member.phone && (
                      <a
                        className="text-sm font-medium text-tertiary hover:underline"
                        href={`tel:${member.phone.replace(/\D/g, '')}`}
                      >
                        {member.phone}
                      </a>
                    )}
                  </div>
                </div>
                {workspaceMemberLoading && (
                  <Loader margin={''} color="gray-800" />
                )}
                {updatedWorkspaceMember && (
                  <BiCheckCircle
                    size={36}
                    className="bg-green-500 text-white rounded-full overflow-hidden"
                  />
                )}
              </div>
            </div>
            {loading && (
              <div className="flex justify-center items-center h-36">
                <Loader margin={''} color="gray-800" />
              </div>
            )}
            {!loading &&
              roles
                .filter((r) => r.name.indexOf('workspace') > -1)
                .map(({ id, name, description }) => (
                  <div
                    className="p-2 mt-1 rounded-md text-sm"
                    key={`edit_permissions_role_${id}`}
                  >
                    <a
                      className="cursor-pointer group"
                      onClick={() =>
                        setWorkspaceMember({ ...workspaceMember, role_id: id })
                      }
                    >
                      <div className="flex items-center">
                        <div
                          className={`rounded-full w-4 h-4 border mr-2 ${id === workspaceMember.role_id
                            ? 'bg-secondary'
                            : 'group-hover:bg-blue-50'
                            }`}
                        />
                        <div className="font-semibold uppercase group-hover:text-secondary">
                          {name.replace('workspace_', '')}
                        </div>
                      </div>
                      <div className="leading-snug pl-6 group-hover:text-secondary">
                        {description}
                      </div>
                    </a>
                  </div>
                ))}
          </div>

          <div className="modal-footer">
            {!loading && roles.length > 0 && (
              <button
                className="modal-save-btn"
                disabled={
                  workspaceMember.role_id === role.id || workspaceMemberLoading
                }
                type="button"
                onClick={saveWorkspaceMember}
              >
                Update {workspaceMemberLoading && <Loader />}
              </button>
            )}
            <button
              className="modal-close-btn"
              type="button"
              onClick={toggleModal}
            >
              Done
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default memo(EditPermissionsModal);
