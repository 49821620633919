import Modal, { ModalHeader } from '../utility/Modal';
import moment from 'moment-timezone';
import MeetingViewBody from './MeetingViewBody';
import useModal from '../../hooks/useModal';

export default function MeetingQuickviewModal({ meeting }) {
  const { open: modalOpen, toggle: toggleModal } = useModal();

  let metAt = moment(meeting.created_at);

  return (
    <>
      <a
        className="cursor-pointer transition duration-150 transform hover:-translate-y-1"
        onClick={toggleModal}
      >
        <div className="rounded-md border-2 flex flex-col border-sm-lightblue overflow-hidden">
          <p className="uppercase text-md bg-sm-lightblue py-2 text-secondary font-semibold">
            {metAt.format('MMM')}
          </p>
          <p className="text-lg py-2 text-secondary flex flex-grow items-center justify-center">
            {metAt.format('D')}
          </p>
        </div>
        <p className="mt-2 truncate">{meeting.title}</p>
        <p className="font-bold text-xxxs truncate">{meeting.creator.name}</p>
      </a>

      <Modal isOpen={modalOpen} onClose={toggleModal}>
        <ModalHeader title={'Meeting Details'} onClose={toggleModal} />
        <MeetingViewBody meeting={meeting} />
        <div className="modal-footer">
          <button
            className="modal-close-btn"
            type="button"
            onClick={toggleModal}
          >
            Close
          </button>
        </div>
      </Modal>
    </>
  );
}
