import { useState, useEffect, useRef } from 'react';
export default function useDelayedValue(value, delay = 1000) {
    const delayTimer = useRef(false);
    const [finalValue, setFinalValue] = useState(value);

    useEffect(() => {
        if (!value) return;

        if (delayTimer.current) clearTimeout(delayTimer.current);

        delayTimer.current = setTimeout(() => {
            setFinalValue(value);
        }, delay);

    }, [value])

    return { value: finalValue }
}