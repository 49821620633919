import { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import authApi from '../api/auth';
import useAuth from '../auth/useAuth';
import useQuery from '../hooks/useQuery';

import OnboardingImage from '../images/bgs/login-bg.png';
import ConfirmationForm from '../partials/onboarding/ConfirmationForm';
import JoinWorkspaceForm from '../partials/onboarding/JoinWorkspaceForm';
import SignupForm from '../partials/onboarding/SignupForm';
import WorkspaceForm from '../partials/onboarding/WorkspaceForm';
import WorkspaceShareForm from '../partials/onboarding/WorkspaceShareForm';
import ProfilePictureForm from '../partials/onboarding/ProfilePictureForm';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import Loader from '../partials/utility/Loader';
import { User } from '../data/models';

import { useMsal } from "@azure/msal-react";
import { AuthenticationResult } from "@azure/msal-browser";
import useFeatures from '../hooks/useFeatures';

function Onboarding() {
  const { step } = useParams<{ step: string }>();
  const history = useHistory();
  const { user, setUser, logIn } = useAuth();
  const [signingUp, setSigningUp] = useState(false);
  const query = useQuery();
  const code = query.get('code');
  const { features } = useFeatures();
  const googleLoginEnabled = features?.rollout?.login_with_google;
  const microsoftLoginEnabled = features?.rollout?.login_with_microsoft;
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const { instance } = useMsal();

  const reloadUser = async () => {
    let response = await authApi.me();
    if (response.ok) setUser({ ...response.data });
  };

  const reloadUserThenGoTo = async (goTo: string) => {
    await reloadUser();
    history.push(goTo);
  };

  const handleStepAfterConfirmation = (user: User) => {
    if (user?.default_workspace && user?.default_workspace.conference) {
      history.push('download-app?source=conference_qr');
    } else {
      history.push(user.default_workspace ? `share` : 'workspace');
    }
  };

  const handleOmniAuth = async (provider: string, credential: string) => {
    setSigningUp(true);

    try {
      const response = await authApi.omniauth(provider, credential, code ? { user_access_codes_attributes: [{ code }] } : {});
      const loggedInUser = await logIn(response);
      handleStepAfterConfirmation(loggedInUser as User);
    } catch (error: any) {
      alert(error.errorMessage);
    } finally {
      setSigningUp(false);
    }
  };

  const handleGoogleLogin = ({ credential }: CredentialResponse) => {
    if (credential) {
      handleOmniAuth('google', credential);
    }
  };

  useEffect(() => {
    const handleRedirectResult = async () => {
      try {
        const result = await instance.handleRedirectPromise();
        if (result) {
          setIsAuthenticating(true);
          handleMicrosoftAuthResult(result);
        }
      } catch (error) {
        setIsAuthenticating(false);
        alert('An error occurred during Microsoft login. Please try again.');
      }
    };

    handleRedirectResult();
  }, [instance]);

  const handleMicrosoftAuthResult = (result: AuthenticationResult) => {
    const idToken = result.idToken;
    if (idToken) {
      handleOmniAuth('microsoft', idToken);
    } else {
      alert('Unable to complete Microsoft login. Please try again.');
    }
  };

  const handleMicrosoftLoginClick = async () => {
    setIsAuthenticating(true);
    try {
      if (window.innerWidth > 768) {
        const result = await instance.loginPopup();
        handleMicrosoftAuthResult(result);
      } else {
        await instance.loginRedirect();
      }
    } catch (error) {
      alert('An error occurred during Microsoft login. Please try again.');
    } finally {
      setIsAuthenticating(false);
    }
  };

  return (
    <main className="bg-white">
      <div className="relative flex">
        {/* Content */}
        <div className="w-full md:w-1/2">
          <div className="min-h-screen h-full flex flex-col after:flex-1">
            {code && step === 'sign_up' && (
              <div className="w-full bg-green-400 text-white text-center text-lg font-semibold p-3">
                You've been invited to join Site Marker
              </div>
            )}
            <div>
              {/* Header */}
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" to="/">
                  <svg
                    width="54"
                    height="33"
                    viewBox="0 0 54 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.2324 10.4287L26.7621 20.3574L43.3054 10.4287L36.8946 6.58489L30.0762 10.6732V2.48302L26.7621 0.5L23.4616 2.48302V10.6732L16.6433 6.57131L10.2324 10.4287Z"
                      fill="#F49F15"
                    />
                    <path
                      d="M53.4244 16.5L47.0135 12.6426L26.7622 24.7988L26.6264 24.7037L6.52454 12.6562L0.100098 16.5L26.7622 32.5L53.4244 16.5Z"
                      fill="#16697A"
                    />
                  </svg>
                </Link>
                {step === 'sign_up' && (
                  <div className="text-sm">
                    Have an account?{' '}
                    <Link
                      className="font-medium text-tertiary hover:text-secondary"
                      to="/signin"
                    >
                      Sign In
                    </Link>
                  </div>
                )}
              </div>

              {/* Progress bar */}
              {!code && (
                <div className="px-4 pt-12 pb-8">
                  <div className="max-w-md mx-auto w-full">
                    <div className="relative">
                      <div
                        className="absolute left-0 top-1/2 -mt-px w-full h-0.5 bg-gray-200"
                        aria-hidden="true"
                      ></div>
                      <ul className="relative flex justify-between w-full">
                        {user?.default_workspace && user?.default_workspace.conference ? (
                          <>
                            <li>
                              <div className="flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold bg-tertiary text-white">
                                1
                              </div>
                            </li>
                            <li>
                              <div
                                className={`flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold ${step === 'confirm' ? 'bg-tertiary text-white' : 'bg-gray-100 text-gray-500'
                                  }`}
                              >
                                2
                              </div>
                            </li>
                            <li>
                              <div
                                className={`flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold ${step === 'share' ? 'bg-tertiary text-white' : 'bg-gray-100 text-gray-500'
                                  }`}
                              >
                                3
                              </div>
                            </li>
                          </>
                        ) : (
                          <>
                            <li>
                              <div
                                className={`flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold bg-tertiary text-white`}
                              >
                                1
                              </div>
                            </li>
                            <li>
                              <div
                                className={`flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold ${!['sign_up'].includes(step)
                                  ? 'bg-tertiary text-white'
                                  : 'bg-gray-100 text-gray-500'
                                  }`}
                              >
                                2
                              </div>
                            </li>
                            <li>
                              <div
                                className={`flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold ${!['confirm', 'sign_up'].includes(step)
                                  ? 'bg-tertiary text-white'
                                  : 'bg-gray-100 text-gray-500'
                                  } `}
                              >
                                3
                              </div>
                            </li>
                            <li>
                              <div
                                className={`flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold ${![
                                  'join_workspace',
                                  'workspace',
                                  'confirm',
                                  'sign_up',
                                ].includes(step)
                                  ? 'bg-tertiary text-white'
                                  : 'bg-gray-100 text-gray-500'
                                  }`}
                              >
                                4
                              </div>
                            </li>
                            <li>
                              <div
                                className={`flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold ${![
                                  'profile_picture',
                                  'join_workspace',
                                  'workspace',
                                  'confirm',
                                  'sign_up',
                                ].includes(step)
                                  ? 'bg-tertiary text-white'
                                  : 'bg-gray-100 text-gray-500'
                                  }`}
                              >
                                5
                              </div>
                            </li>
                          </>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="px-4 py-8 mt-10">
              {step === 'sign_up' && (
                <div className="max-w-md mx-auto relative">
                  <h1 className="text-3xl text-center text-gray-800 font-bold mb-6">
                    Let's get started
                  </h1>
                  <div className="flex flex-col sm:flex-row sm:justify-between justify-center sm:space-x-4 mb-6">
                    {googleLoginEnabled && (
                      <div className="w-full sm:w-1/2 flex justify-center mb-4 sm:mb-0">
                        <GoogleLogin
                          onSuccess={handleGoogleLogin}
                          text="signup_with"
                        />
                      </div>
                    )}
                    {microsoftLoginEnabled && (
                      <div className="w-full sm:w-1/2 flex justify-center" data-testid="signUpWithMicrosoftContainer">
                        <button
                          onClick={handleMicrosoftLoginClick}
                          disabled={isAuthenticating}
                          className="border border-gray-300 rounded-md px-4 py-2 flex items-center justify-center text-sm font-medium"
                        >
                          <svg className="w-4 h-4 mr-2" viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg">
                            <path fill="#f35325" d="M1 1h10v10H1z" />
                            <path fill="#81bc06" d="M12 1h10v10H12z" />
                            <path fill="#05a6f0" d="M1 12h10v10H1z" />
                            <path fill="#ffba08" d="M12 12h10v10H12z" />
                          </svg>
                          {isAuthenticating ? 'Loading...' : 'Sign up with Microsoft'}
                        </button>
                      </div>
                    )}
                  </div>
                  {(googleLoginEnabled || microsoftLoginEnabled) && <p className="text-center font-bold mt-6 border-t border-gray-200 py-4">Or</p>}
                  <SignupForm
                    code={code}
                    onComplete={(user: User) =>
                      history.push(
                        user.confirmed
                          ? 'workspace'
                          : 'confirm?email=' + user.email
                      )
                    }
                  />
                  {signingUp && <div className="absolute inset-0 bg-white bg-opacity-50 flex items-center justify-center"><Loader color='black' /></div>}
                </div>
              )}
              {step === 'confirm' && (
                <ConfirmationForm onConfirmed={handleStepAfterConfirmation} />
              )}
              {step === 'join_workspace' && <JoinWorkspaceForm user={user} />}
              {step === 'workspace' && (
                <WorkspaceForm
                  user={user}
                  onBack={() => history.goBack()}
                  onComplete={() => reloadUserThenGoTo('profile_picture')}
                />
              )}
              {step === 'profile_picture' && (
                <ProfilePictureForm
                  onComplete={() => reloadUserThenGoTo('share')}
                />
              )}
              {step === 'share' && (
                <WorkspaceShareForm
                  user={user}
                  onBack={() => history.goBack()}
                  onComplete={() =>
                    history.push(`/${user.default_workspace.objectId}/projects`)
                  }
                />
              )}
            </div>
          </div>
        </div>
        <div
          className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
          aria-hidden="true"
        >
          <img
            className="object-cover object-center w-full h-full"
            src={OnboardingImage}
            width="760"
            height="1024"
            alt="Onboarding"
          />
        </div>
      </div>
    </main>
  );
}

export default Onboarding;
