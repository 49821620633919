import { useState, useEffect, useRef } from 'react';
import { GrCheckbox, GrCheckboxSelected } from 'react-icons/gr';
import { BiCheckCircle } from 'react-icons/bi';
import entriesApi from '../../api/entries';
import useApi from '../../hooks/useApi';
import Loader from '../utility/Loader';

const LEFT_ATTRIBUTE_NAMES = ["swppp", "noi", "dhec_cover_letter"];
const BOTTOM_ATTRIBUTE_NAMES = ["dhec_cover_letter"];

export default function EntryCheckboxes({
  entry: passedEntry,
  editing = true,
  useBottomBorder,
  extraLabelClass = '',
}) {
  const {
    data: savedEntry,
    error,
    loading: saving,
    request: saveEntry,
  } = useApi(entriesApi.updateEntry, null);
  const [entry, setEntry] = useState(passedEntry);
  const [saved, setSaved] = useState(false);
  const updateTimer = useRef(null);
  const savedTimer = useRef(null);

  const updateEntry = ({ target: { value } }) => {
    if (updateTimer.current) clearTimeout(updateTimer.current);

    updateTimer.current = setTimeout(() => {
      submitEntry(value);
    }, 1000);
  };

  const submitEntry = (value) => {
    entry.value = value;
    setEntry({ ...entry });
    saveEntry(entry.objectId, { value });
  };

  useEffect(() => {
    savedEntry && setEntry({ ...savedEntry });
  }, [savedEntry]);

  useEffect(() => {
    savedEntry && setSaved(true);
  }, [savedEntry]);

  useEffect(() => {
    if (saved) {
      savedTimer.current = setTimeout(() => {
        setSaved(false);
      }, 2000);
    }
  }, [saved]);

  return (
    <>
      <div
        className={`p-2 font-bold ${LEFT_ATTRIBUTE_NAMES.includes(entry.attribute_name) ? '' : 'border-l'} ${extraLabelClass} ${useBottomBorder && !BOTTOM_ATTRIBUTE_NAMES.includes(entry.attribute_name) && 'border-b'
          } border-r relative`}
      >
        {entry.name}
        {saving && (
          <div className="absolute top-0 right-2 h-full flex items-center">
            <Loader color="text-gray-700" />
          </div>
        )}
        {saved && (
          <div className="absolute top-0 right-2 h-full flex items-center">
            <BiCheckCircle
              size={16}
              className="bg-green-500 text-white rounded-full overflow-hidden"
            />
          </div>
        )}
      </div>
      <div
        className={`py-2 ${entry.class_name} ${useBottomBorder && !BOTTOM_ATTRIBUTE_NAMES.includes(entry.attribute_name) && 'border-b'} ${editing && entry.requires_confirmation && 'bg-yellow-50'
          } flex items-center`}
      >
        {entry.value_options.map(({ label, value, allows_manual }, index) => {
          return (
            <>
              <div className="pl-4 pr-1">
                {value.toString() === entry.value ||
                  (allows_manual &&
                    entry.value &&
                    entry.value_options.findIndex(
                      ({ value }) => value === entry.value
                    ) === -1) ? (
                  <GrCheckboxSelected
                    size={14}
                    className={`${editing && 'hover:bg-blue-100 cursor-pointer'
                      }`}
                  />
                ) : (
                  <GrCheckbox
                    size={14}
                    onClick={() => editing && submitEntry(value.toString())}
                    className={`${editing && 'hover:bg-blue-100 cursor-pointer'
                      }`}
                  />
                )}
              </div>
              <div>{label}</div>
              {entry.value &&
                allows_manual &&
                entry.value_options
                  .filter(
                    ({ value: not_this_value }) => not_this_value !== value
                  )
                  .findIndex(({ value }) => value === entry.value) === -1 && (
                  <input
                    type={'text'}
                    onChange={updateEntry}
                    disabled={!editing}
                    placeholder={`Add ${label.toLowerCase()} ${entry.name.toLowerCase()}`}
                    className={`text-xxs leading-4 border-gray-200 p-1 border ${editing && 'hover:bg-blue-50'
                      } focus:border-gray-200 focus:outline-none focus:ring-0 w-full mx-4`}
                    defaultValue={entry.value !== value ? entry.value : null}
                  />
                )}
            </>
          );
        })}
      </div>
    </>
  );
}
