import { Pin } from '../data/models';
import colors from '../styles/colors';
import PermissionsViewModel from './PermissionsViewModel';

export default class PinViewModel {
  constructor(object) {
    Object.assign(this, object);
    this.json = object;
    this.permissions = new PermissionsViewModel({
      permissions: object.permissions,
    });
    this.identifier = object.identifier;
    this.pin_type = object.pin_type;
    this.objectId = object.objectId;
  }

  updateableFields = () =>
    this.field_values?.filter((fv) => fv.field?.updateable) || [];
  validStatusField = () =>
    this.field_values?.find((fv) => fv.field?.updateable);
  hasCategoryFields = () => !!this.categoryFields().length;
  categoryFields = () =>
    this.field_values?.filter((fv) => fv.field?.name === 'category') || [];
  speciesField = () =>
    this.field_values?.find((fv) => fv.field?.name === 'species');
  gradeField = () =>
    this.field_values?.find((fv) => fv.field?.name === 'grade');
  ratingField = () =>
    this.field_values?.find((fv) => fv.field?.name === 'rating');
  trunkFields = () =>
    this.field_values?.filter((fv) => fv.field?.name === 'trunk') || [];
  violationFields = () =>
    this.field_values?.filter((fv) => fv.field?.name === 'violation_type') ||
    [];

  validStatusField = () => this.active_status;

  getStatus() {
    return this.active_status?.name || 'N/A';
  }

  getStatusColor() {
    return this.active_status?.color || colors.darkGray;
  }

  getStatusColorFromValue(value) {
    const found_option = this.statuses?.find?.((fo) => fo.value === value);
    return found_option?.color || '#000';
  }

  getStatusFieldValue() {
    return this.field_values?.find((fv) => fv.field?.statuses_pin);
  }

  hasStatusField() {
    return this.active_status || false;
  }

  latestPhoto() {
    return this.photoed_note?.processed_photos?.[0] || null;
  }

  latestNote() {
    return this.metadata?.latest_note || this.latest_notes?.[0]?.text || null;
  }

  hasPhoto() {
    return this.photoed_note;
  }

  photos() {
    return this.latest_note?.map((n) => n.processed_photos).flat();
  }

  canEditPinDrop() {
    return this.permissions.can('manage') && !this.reported_on; // this.twentyFourHoursAgo().isBefore(moment(this.created_at))
  }

}
