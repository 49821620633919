import ExifReader from 'exifreader';

const GPS_EXIF_TAGS = [
    'GPSLatitude',
    'GPSLongitude',
    'GPSLatitudeRef',
    'GPSLongitudeRef',
    'GPSAltitude',
    'GPSAltitudeRef',
    'GPSImgDirection',
    'GPSImgDirectionRef',
];

const gpsValuesPresent = (exifData) => {
    return GPS_EXIF_TAGS.every((tag) => exifData?.[tag] !== undefined);
};

const getGpsValues = (exifData) => {

    const convertedValues = convertToWGS84(exifData);

    return {
        ...convertedValues
    };
};

const convertToWGS84 = (exifData) => {

    const latitude = exifData.GPSLatitude.description;
    const longitude = exifData.GPSLongitude.description;
    const latitudeRef = exifData.GPSLatitudeRef.description;
    const longitudeRef = exifData.GPSLongitudeRef.description;
    const altitude = exifData.GPSAltitude.description;
    const direction = exifData.GPSImgDirection.description;
    let latitudeConverted = latitude, longitudeConverted = longitude,
        altitudeConverted = altitude, directionConverted = direction;

    if (latitudeRef.includes('South') || latitudeRef === 'S') {
        latitudeConverted = -latitude;
    }

    if (longitudeRef.includes('West') || longitudeRef === 'W') {
        longitudeConverted = -longitude;
    }

    altitudeConverted = parseFloat(altitude);
    directionConverted = parseFloat(direction);

    return {
        direction: directionConverted,
        altitude: altitudeConverted,
        latitude: latitudeConverted,
        longitude: longitudeConverted,
    };
};

const getFileExif = async (file) => {
    return await ExifReader.load(file);
};

const isExifSupportedFile = (file) => {
    const supportedFileTypes = ['image/jpeg', 'image/png', 'audio/wav', 'image/tiff', 'image/webp'];
    return supportedFileTypes.includes(file.type);
};

export {
    gpsValuesPresent,
    getGpsValues,
    getFileExif,
    isExifSupportedFile,
}