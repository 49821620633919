import { useEffect } from 'react';
import useApi from '../../../hooks/useApi';
import downloadsApi from '../../../api/downloads';
import useSaved from '../../../hooks/useSaved';
import useActionCable, { DOWNLOADS_CHANNEL } from '../../../hooks/useActionCable';

export default function useProjectDownloads(projectId: string, {
    load = true,
    listenForUpdates = true
}: {
    load?: boolean
    listenForUpdates?: boolean
} = {}) {
    const {
        data: {
            records: downloads,
            pagy,
        },
        error,
        loading,
        request: loadDownloads,
    } = useApi(downloadsApi.getProjectDownloads, {
        records: [],
        pagy: null
    }, load);

    const {
        data: createdDownload,
        error: createError,
        loading: creating,
        request: createDownload,
    } = useApi(downloadsApi.createProjectDownload, null);

    const {
        data: deletedDownload,
        error: deleteError,
        loading: deleting,
        request: deleteDownload,
    } = useApi(downloadsApi.deleteDownload, null);

    const _createDownload = (params: any) => {
        createDownload(projectId, params);
    }

    const _loadDownloads = () => {
        loadDownloads(projectId, { items: 100 });
    }

    const { receivedMessages } = useActionCable(
        DOWNLOADS_CHANNEL,
        {
            parent_objectId: projectId,
            parent: 'project',
        },
        listenForUpdates
    );

    const { saved: created } = useSaved(createdDownload?.updated_at);
    const { saved: deleted } = useSaved(deletedDownload);


    useEffect(() => {
        if (!load) return;
        _loadDownloads();
    }, [receivedMessages])

    return {
        loadDownloads: _loadDownloads,
        downloads,
        pagy,
        loading,
        error,
        createDownload: _createDownload,
        creating,
        createError,
        createdDownload,
        created,
        deleteDownload,
        deleting,
        deleted,
        deletedDownload,
        deleteError
    }
}