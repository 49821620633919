import PermissionsViewModel from './PermissionsViewModel';

export default class ReportViewModel {
  constructor(object) {
    Object.assign(this, object);
    this.permissions = new PermissionsViewModel({
      permissions: object.permissions,
    });
  }
}
