import React, { useState, useEffect } from 'react';
import workspacesApi from '../../api/workspaces';
import workspaceMembersApi from '../../api/workspace_members';
import ErrorViewModel from '../../models/ErrorViewModel';
import ErrorView from '../utility/ErrorView';
import Loader from '../utility/Loader';
export default function JoinWorkspaceForm({ user }) {
  const [autoInviteWorkspace, setAutoInviteWorkspace] = useState(null);
  const [loading, setLoading] = useState(false);
  const [joining, setJoining] = useState(false);
  const [joined, setJoined] = useState(false);
  const [error, setError] = useState(false);

  const joinWorkspace = async () => {
    setJoining(true);
    setError(false);
    let response = await workspaceMembersApi.addWorkspaceMember(
      autoInviteWorkspace.objectId,
      { member_id: user.id }
    );
    if (!response.ok) {
      let { data } = response;
      setError(new ErrorViewModel(data));
    } else setJoined(true);
    setJoining(false);
  };

  const getWorkspaceWithAutoInvites = async (domain) => {
    let response = await workspacesApi.getWorkspaces({
      where: {
        custom_domain: domain,
        allow_matching_custom_domain_signups: true,
      },
    });
    if (response.ok && response.data.records.length)
      setAutoInviteWorkspace(response.data.records[0]);
    setLoading(false);
  };

  useEffect(() => {
    if (!user) return;
    if (user.custom_domain) getWorkspaceWithAutoInvites(user.custom_domain);
  }, [user?.name]);

  return (
    <div className="max-w-md mx-auto text-center">
      {error ? (
        <div className="text-center">
          <h1 className="text-3xl text-gray-800 font-bold mb-4">Uh oh...</h1>
          <ErrorView error={error} extraClass={'mb-4'} />
        </div>
      ) : (
        <>
          <h1 className="text-3xl text-gray-800 font-bold mb-4 flex items-center justify-center">
            {joining ? (
              <>
                <span>Joining... </span>
                <span>
                  <Loader color="black" />
                </span>
              </>
            ) : (
              'Ready to join your team?'
            )}
          </h1>
          {/* {confirmed ? <ModalSuccessView text={"Email successfully confirmed"} /> : <>
  <h1 className="text-xl text-gray-600 mb-2">We sent an email to <strong className="text-tertiary">{email || 'your email'}</strong></h1>
  <p className="text-black mb-6">Open and click thru to <em>confirm your account</em>.</p>
  <div className="text-sm">
    Nothing in your inbox? <button className="font-medium text-tertiary hover:text-secondary cursor-pointer" onClick={resendConfirmation} disabled={resending}>{resendEmailText()}</button>
  </div>
  </>} */}
        </>
      )}
    </div>
  );
}
