import { useEffect } from 'react';
import useApi from '../../../hooks/useApi';
import reportTypesApi from '../../../api/report_types';
import { SearchParams } from '../../../data/models';
export default function useReportTypes({
    params = {}
}: {
    params?: SearchParams
} = {
    }) {
    const {
        data: { records: reportTypes },
        error,
        loading,
        request: loadReportTypes,
    } = useApi(reportTypesApi.getReportTypes, { records: [], pagy: {} }, true);

    const reloadReportTypes = () => loadReportTypes(params);

    useEffect(() => {
        loadReportTypes(params);
    }, [JSON.stringify(params)]);

    return {
        reportTypes,
        error,
        loading,
        loadReportTypes,
        reloadReportTypes,
    }
}