export default {
  smOrange: '#D57A35',
  primary: '#D57A35',
  smBlue: '#10678B',
  secondary: '#10678B',
  smGreen: '#57652B',
  smSuccess: '#9FD53D',
  success: '#9FD53D',
  smSuccessDisabled: '#c8e37d',
  smDanger: '#D72C4E',
  smLightBG: '#EFF6EE',
  treeGreen: '#9ed531',
  black: '#000',
  white: '#fff',
  lightGray: '#eee',
  darkGray: '#808080',
  backgroundGray: '#F9F8F8',
  mediumBlue: '#BDD4DC',
  lightBlue: '#DFE9ED',
};

const hexToRgb = (hex) => {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    }
    : {
      r: 0,
      g: 0,
      b: 0,
    };
};

const getTextColorFromBackground = (hex, threshold = 125) => {
  // Randomly update colours
  let rgb = hexToRgb(hex);
  let brightness = Math.round(
    (parseInt(rgb.r) * 299 + parseInt(rgb.g) * 587 + parseInt(rgb.b) * 114) /
    1000
  );
  return brightness > threshold ? 'black' : 'white';
};

export { getTextColorFromBackground };
