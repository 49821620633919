import { memo, useEffect, useState } from 'react';
import ProjectNotesModal from './ProjectNotesModal';
import notesApi from '../../api/notes';
import useApi from '../../hooks/useApi';
import Loader from '../utility/Loader';
import NoteCreateModal, { NoteAddButton } from '../notes/NoteCreateModal';
import { useParams } from 'react-router-dom';
import EditableProjectSiteNote from './EditableProjectSiteNote';
import { OverflowTypes } from '../pins/PinFilterCard';
import NoteEditModal from '../notes/NoteEditModal';
import { Note } from '../../data/models';

const ProjectNotesHeader = ({ loading, notesCount }: {
  loading: boolean;
  notesCount: number;
}) => <div className="card-header">
    <p className="text-sm font-bold">
      {loading ? '...' : 'Site Notes (' + notesCount + ')'}
    </p>
    <ProjectNotesModal />
  </div>

const ProjectNotes = ({ loading, notes, overflow = 'y-auto', showPhotos, onShowPhotosClicked, onEditButtonClicked }: {
  loading: boolean;
  notes: Note[];
  overflow?: 'y-auto' | 'y-hidden' | 'y-scroll';
  showPhotos: boolean;
  onShowPhotosClicked: () => void;
  onEditButtonClicked?: (note: Note) => void;
}) => {
  return <>
    {loading && (
      <div className="flex justify-center py-5">
        <Loader color="black" />
      </div>
    )}
    {!loading && (
      <div
        className={`px-4 pb-2 space-y-2 max-h-60 text-xs ${!!notes.length && overflow ? OverflowTypes[overflow] : ''}`}
      >
        {notes.map((note) => (
          <EditableProjectSiteNote
            key={`project_site_note_${note.objectId}`}
            note={note}
            showPhotos={showPhotos}
            onEditButtonClicked={onEditButtonClicked ? () => onEditButtonClicked?.(note) : undefined}
            onShowPhotosClicked={onShowPhotosClicked}
          />
        ))}
        {notes.length === 0 && (
          <p className="text-lg text-center text-gray-800 py-3 font-medium">
            No notes on project
          </p>
        )}
      </div>
    )}
  </>
}

export {
  ProjectNotes
}

function ProjectNotesCard() {
  const { project_id } = useParams<{ project_id: string }>();
  const [showPhotos, setShowPhotos] = useState<boolean>(false);
  const {
    data: { records: notes, pagy },
    loading,
    request: getNotes,
  } = useApi(notesApi.getNotes, { records: [], pagy: {} }, true);

  const [editingNote, setEditingNote] = useState<Note | null>(null);
  const [creatingNote, setCreatingNote] = useState<boolean>(false);
  const toggleShowPhotos = () => setShowPhotos(!showPhotos);

  const loadFirstNotes = () => {
    getNotes('project', project_id, { items: 5 });
  };

  useEffect(() => {
    loadFirstNotes();
  }, []);

  return (<>
    <div className="bg-white rounded-sm border border-gray-200 overflow-hidden text-black shadow-xl mb-6 rounded-lg">
      <div className="flex flex-col h-full">
        <ProjectNotesHeader loading={loading} notesCount={pagy.count} />
        <div className="flex justify-end px-5 py-2 shadow-sm mb-3">
          <NoteAddButton onClick={() => setCreatingNote(true)} />
        </div>
        <ProjectNotes
          loading={loading}
          notes={notes}
          showPhotos={showPhotos}
          onShowPhotosClicked={toggleShowPhotos}
          onEditButtonClicked={setEditingNote}
        />
      </div>
    </div>

    <NoteCreateModal
      open={creatingNote}
      onClose={() => setCreatingNote(false)}
      afterAdd={loadFirstNotes}
    />
    <NoteEditModal
      noteId={editingNote?.objectId || null}
      onClose={() => setEditingNote(null)}
      afterEdit={loadFirstNotes}
      afterDelete={loadFirstNotes}
    />
  </>
  );
}

export default memo(ProjectNotesCard);
