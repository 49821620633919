function WorkspaceHowTo() {

    return <div className="flex flex-col items-center justify-center mb-4 py-5" >
        <iframe loading="lazy" src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAGQSN8hgZ4&#x2F;3Md2-G0LM0Zp9coenJJFaw&#x2F;watch?embed" allow="fullscreen"
            allowFullScreen
            style={
                {
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%"
                }
            } />
    </div >;

}

export default WorkspaceHowTo;
