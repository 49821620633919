import { useEffect, createContext, useContext, useState } from 'react';
import cache from '../utility/cache';
const SidebarContext = createContext();
function SidebarContextProvider({ children }) {
    const [collapsed, setCollapsed] = useState(cache.get("sidebarCollapsed") === true);
    return (
        <SidebarContext.Provider value={[collapsed, setCollapsed]}>
            {children}
        </SidebarContext.Provider>
    );
}
export {
    SidebarContextProvider
};

export default function useSidebarCollapsed() {
    const [collapsed, setCollapsed] = useContext(SidebarContext);

    useEffect(() => {
        const sidebarCollapsed = cache.get("sidebarCollapsed");
        if (sidebarCollapsed !== undefined) setCollapsed(collapsed);
    }, [])

    useEffect(() => {
        cache.store("sidebarCollapsed", collapsed);
    }, [collapsed]);

    return {
        collapsed,
        setCollapsed
    };
}