import React from 'react';

export default function FieldReportItem({ title, note, includeBorder = true }) {
  return (
    <div className={`grid grid-cols-10 mb-1 text-xs pb-1`}>
      <div className="font-semibold text-center">{title}</div>
      <div className="col-span-9 leading-tight">{note}</div>
    </div>
  );
}
