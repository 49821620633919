import { useEffect, useRef } from 'react';
import { ReportType } from '../../../data/models';
import reportTypesApi from '../../../api/report_types';
import useSaved from '../../../hooks/useSaved';
import useApi from '../../../hooks/useApi';
export default function useReportType(reportTypeId: number | null, {
    load = true }: {
        load?: boolean
    } = {}): {
        reportType: ReportType | null;
        error: any;
        loading: boolean;
        loadPinType: () => void;
        updatedReportType: ReportType | null;
        errorUpdating: any;
        updating: boolean;
        updated: boolean;
        updateReportTypeDebounced: (data: any, delay?: number) => void;
        updateReportType: (data: any) => void;
        archivedReportType: ReportType | null;
        errorArchiving: any;
        archiving: boolean;
        archived: boolean;
        archiveReportType: () => void;
        unarchivedReportType: ReportType | null;
        errorUnarchiving: any;
        unarchiving: boolean;
        unarchived: boolean;
        unarchiveReportType: () => void;
        duplicatedReportType: ReportType | null;
        errorDuplicating: any;
        duplicating: boolean;
        duplicated: boolean;
        duplicateToWorkspace: (workspace_id: string) => void;
    } {

    const {
        data: reportType,
        error,
        loading,
        request: loadReportType,
    } = useApi(reportTypesApi.getReportType, null, load);

    const {
        data: updatedReportType,
        error: errorUpdating,
        loading: updating,
        request: updateReportType,
    } = useApi(reportTypesApi.updateReportType, null);

    const {
        data: archivedReportType,
        error: errorArchiving,
        loading: archiving,
        request: archiveReportType,
    } = useApi(reportTypesApi.archiveReportType, null);

    const {
        data: unarchivedReportType,
        error: errorUnarchiving,
        loading: unarchiving,
        request: unarchiveReportType,
    } = useApi(reportTypesApi.unarchiveReportType, null);

    const {
        data: duplicatedReportType,
        error: errorDuplicating,
        loading: duplicating,
        request: duplicateToWorkspace,
    } = useApi(reportTypesApi.duplicateReportTypeToWorkspace, null);

    const updateTimer = useRef<NodeJS.Timeout | null>(null);


    const _updateReportType = (data: any) => {
        if (reportTypeId) updateReportType(reportTypeId, data);
    }

    const _updateReportTypeDebounced = (data: any, delay: number = 1000) => {

        if (!reportTypeId) return;
        if (updateTimer.current) clearTimeout(updateTimer.current);

        updateTimer.current = setTimeout(() => {
            _updateReportType(data);
        }, delay);
    }

    const _archiveReportType = () => {
        if (reportTypeId) archiveReportType(reportTypeId);
    }

    const _unarchiveReportType = () => {
        if (reportTypeId) unarchiveReportType(reportTypeId);
    }

    const _duplicateToWorkspace = (workspace_id: string) => {
        if (reportTypeId) duplicateToWorkspace(reportTypeId, workspace_id);
    }

    const _loadReportType = () => {
        if (reportTypeId) loadReportType(reportTypeId);
    }


    const { saved: updated } = useSaved(updatedReportType?.updated_at);
    const { saved: archived } = useSaved(archivedReportType?.updated_at);
    const { saved: unarchived } = useSaved(unarchivedReportType?.updated_at);
    const { saved: duplicated } = useSaved(duplicatedReportType?.updated_at);

    useEffect(() => {
        if (!load) return;
        _loadReportType();
    }, [load, reportTypeId]);

    return {
        reportType,
        error,
        loading,
        loadPinType: _loadReportType,
        updatedReportType,
        errorUpdating,
        updating,
        updated,
        updateReportType: _updateReportType,
        updateReportTypeDebounced: _updateReportTypeDebounced,
        archivedReportType,
        archived,
        errorArchiving,
        archiving,
        archiveReportType: _archiveReportType,
        unarchivedReportType,
        errorUnarchiving,
        unarchiving,
        unarchived,
        unarchiveReportType: _unarchiveReportType,
        duplicatedReportType,
        errorDuplicating,
        duplicating,
        duplicated,
        duplicateToWorkspace: _duplicateToWorkspace,
    }
}