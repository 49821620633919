import { useEffect, useState } from 'react';
import useApi from '../../hooks/useApi';
import featureFlagsApi from '../../api/feature_flags';
import Table from '../../utils/Table';
import Button from '../shared/Button';
import EditFeatureFlagModal from './EditFeatureFlagModal';
import { ConfirmationModal } from '../utility/Modal';
import useSaved from '../../hooks/useSaved';
import { FiTrash2 } from 'react-icons/fi';
import AddButton from '../shared/AddButton';

const AddFeatureFlagButton = ({ onClick }: { onClick: any }) => <AddButton
    testId="actionCreateFeatureFlag"
    onClick={onClick}
>
    Add Feature Flag
</AddButton>

type FeatureFlag = {
    name?: string;
    key?: string;
    description?: string;
    percentage?: number;
    users?: string[];
}

export default function FeatureFlags() {
    const {
        data,
        request: getFeatureFlags,
    } = useApi(featureFlagsApi.getFeatureFlags, []);

    const {
        data: deletedFlag,
        loading: deletingFlag,
        request: deleteFeatureFlag,
    } = useApi(featureFlagsApi.destroyFeatureFlag, []);

    const [updatingFeatureFlag, setUpdatingFeatureFlag] = useState<FeatureFlag | null>(null);
    const [deletingFeatureFlag, setDeleteingFeatureFlag] = useState<FeatureFlag | null>(null);
    const newFlag = !updatingFeatureFlag?.key;

    const { saved: deleted } = useSaved(deletedFlag);

    useEffect(() => {
        if (!deleted) return;
        setDeleteingFeatureFlag(null);
        getFeatureFlags();
    }, [deleted])

    useEffect(() => {
        getFeatureFlags();
    }, [])

    return <div className="pb-5">
        <EditFeatureFlagModal newFlag={newFlag} featureFlag={updatingFeatureFlag} open={!!updatingFeatureFlag} onClose={() => setUpdatingFeatureFlag(null)} afterCreate={getFeatureFlags} />
        <Table.Table>
            <Table.Header>
                <h2 className="font-semibold text-gray-800">Feature Flags</h2>
                <AddFeatureFlagButton onClick={() => {
                    setUpdatingFeatureFlag({})
                }} />
            </Table.Header>
            <Table.Body>
                <Table.Thead>
                    <Table.Th>Name</Table.Th>
                    <Table.Th>Key</Table.Th>
                    <Table.Th>Description</Table.Th>
                    <Table.Th>Percentage</Table.Th>
                    <Table.Th>Users</Table.Th>
                    <Table.Th>Actions</Table.Th>
                </Table.Thead>
                <Table.Tbody>
                    {data?.map((flag: any) => {
                        return <tr>
                            <Table.Td>{flag.name}</Table.Td>
                            <Table.Td data-testid={`featureFlagKey${flag.key}`}>{flag.key}</Table.Td>
                            <Table.Td data-testid={`featureFlagDescription${flag.key}`} className="max-w-xs">{flag.data?.description || 'N/A'}</Table.Td>
                            <Table.Td data-testid={`featureFlagPercentage${flag.key}`}>{flag.percentage}</Table.Td>
                            <Table.Td>{flag.users.length}</Table.Td>
                            <Table.Td>
                                <Button
                                    text="Edit"
                                    data-testid={`featureFlagEditButton${flag.key}`}
                                    color='light'
                                    onClick={() => setUpdatingFeatureFlag(flag)}
                                />
                                <Button
                                    text="Delete"
                                    className='ml-2'
                                    data-testid={`featureFlagDeleteButton${flag.key}`}
                                    color='danger'
                                    iconAligned='left'
                                    onClick={() => setDeleteingFeatureFlag(flag)}
                                />
                            </Table.Td>
                        </tr>
                    })}
                </Table.Tbody>
            </Table.Body>
        </Table.Table>
        <ConfirmationModal
            confirming={deletingFlag}
            confirmed={deleted}
            isOpen={!!deletingFeatureFlag}
            onClose={() => setDeleteingFeatureFlag(null)}
            onConfirm={() => {
                deleteFeatureFlag(deletingFeatureFlag?.key);
            }}
            title="Delete Feature Flag"
            body={deletingFeatureFlag ? `Are you sure you want to delete the feature flag ${deletingFeatureFlag.name}?` : ''}
        />
    </div>;
}