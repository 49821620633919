import { useEffect, useRef, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import Modal, { ModalHeader, ModalSuccessView } from '../utility/Modal';
import useApi from '../../hooks/useApi';
import reportsApi from '../../api/reports';
import Loader from '../utility/Loader';

export default function DeleteReportModal({
  report,
  onReport = false,
  onDeleted,
}) {
  const {
    data: deleted_report,
    error,
    loading: deleting,
    request: deleteReport,
  } = useApi(reportsApi.deleteReport, null);
  const { project_id, workspace_id, report_id } = useParams();
  const [deleted, setDeleted] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const deletedTimer = useRef();
  const [modalOpen, setModalOpen] = useState(false);

  const removeReport = () => deleteReport(report?.objectId || report_id);

  const closeModal = () => {
    setModalOpen(false);

    if (deletedTimer.current) {
      clearTimeout(deletedTimer.current);
      setRedirect(true);
    }
  };

  useEffect(() => {
    deleted_report && setDeleted(true);
  }, [deleted_report]);

  useEffect(() => {
    if (deleted)
      deletedTimer.current = setTimeout(() => setRedirect(true), 3000);
  }, [deleted]);

  if (redirect) {
    if (onDeleted) onDeleted();
    else
      return (
        <Redirect to={`/${workspace_id}/projects/${project_id}/reports`} />
      );
  }

  return (
    <div>
      {/* Button */}
      {onReport ? (
        <button
          onClick={() => setModalOpen(!modalOpen)}
          className="mr-2 hidden sm:flex items-center content-center rounded-md text-white bg-red-500 py-1 font-semibold px-3 shadow-sm hover:opacity-80 cursor-pointer focus:outline-none"
        >
          {' '}
          Delete{' '}
        </button>
      ) : (
        <a
          className="bg-transparent cursor-pointer font-medium text-sm text-red-500 hover:text-red-600 flex py-1 px-3"
          onClick={() => setModalOpen(!modalOpen)}
        >
          Delete
        </a>
      )}

      <Modal isOpen={modalOpen} onClose={closeModal}>
        <ModalHeader title={'Confirm Report Removal'} onClose={closeModal} />
        <div className="px-2 pt-2">
          <div className="p-5">
            {!deleted && (
              <>
                <div className="mb-4">
                  <div className="font-semibold">Are you sure?</div>
                </div>
                <div>
                  This will completely delete the report and all of its data. It
                  cannot be undone.
                </div>
              </>
            )}
            {deleted && (
              <ModalSuccessView text={'Report successfully deleted'} />
            )}
          </div>

          {!deleted && (
            <div className="modal-footer">
              <button
                className="modal-remove-btn"
                type="button"
                onClick={removeReport}
                disabled={deleting}
              >
                Delete Report {deleting && <Loader />}
              </button>
              <button
                className="modal-close-btn"
                type="button"
                onClick={closeModal}
                disabled={deleting}
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}
