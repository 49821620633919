import { useEffect, useState } from "react";
import SearchForm from "../actions/SearchForm";
import Dropdown from "../utility/Dropdown";
import { Link } from "react-router-dom";
import useApi from "../../hooks/useApi";
import projectsApi from "../../api/projects";
import Loader from "../utility/Loader";
import useWorkspaceContext from "../../hooks/useWorkspaceContext";
import cache from "../../utility/cache";

const ProjectItem = ({ project, index, onClick }) => <Link to={`/${project.workspace.objectId}/projects/${project.objectId}/details`} onClick={onClick}
    key={'projectSearchResult' + project.objectId}
>
    <div className={`px-3 py-2 ${index > 0 ? 'border-t' : ''} hover:opacity-80 cursor-pointer`} data-testid={`projectSearchResult${project.identifier}${project.name}`}>
        <p className="font-light font-oswald uppercase"><span className="font-medium">{project.identifier}</span> {project.name}</p>
        <p className="text-sm text-tertiary">{project.map.location.locality}, {project.map.location.region}</p>
    </div>
</Link>

const SearchLoader = () => <div className="flex justify-center py-2"><Loader color="gray-900" /></div>
const SearchHeader = ({ children }) => <div className={`bg-white px-2 border-b py-1 text-secondary text-xs uppercase font-semibold`}>
    {children}
</div>
const SearchFooter = ({ children }) => <div className="text-center px-2 py-1 border-t bg-gray-50 text-gray-500 text-xs font-medium">
    {children}
</div>

const SearchEmptyItem = ({ children }) => <div className="px-3 py-2 text-sm font-medium text-center">{children}</div>

export {
    SearchLoader,
    SearchHeader,
    SearchFooter,
    SearchEmptyItem
}
const sanitizedProject = (project) => {
    return {
        objectId: project.objectId,
        identifier: project.identifier,
        name: project.name,
        map: {
            location: {
                locality: project.map.location.locality,
                region: project.map.location.region
            }
        },
        workspace: {
            objectId: project.workspace.objectId
        }
    }
}
export default function ProjectSearch() {

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [recentProjects, setRecentProjects] = useState([]);
    const { workspace } = useWorkspaceContext();

    const {
        data: { records: projects },
        loading,
        request: loadProjects,
    } = useApi(projectsApi.getWorkspaceProjects, { records: [], pagy: {} }, false);

    useEffect(() => {
        if (!searchText) return;
        loadProjects(workspace.objectId, { q: searchText, page: 1, items: 10 });
    }, [searchText])

    useEffect(() => {
        const cachedProjects = cache.get(`workspace-${workspace.objectId}-project-search`);
        setRecentProjects(cachedProjects || [])
    }, [workspace.objectId])

    const onProjectClick = (project, cacheProject = true) => {
        if (!cacheProject) return;

        setTimeout(() => {
            setSearchText('')
            const filteredRecentProjects = recentProjects.filter(({ objectId }) => objectId !== project.objectId)
            const newRecentProjects = [sanitizedProject(project), ...filteredRecentProjects.slice(0, 2)]
            cache.store(`workspace-${workspace.objectId}-project-search`, newRecentProjects, false)
            setRecentProjects([...newRecentProjects])
        }, 150)
    }

    if (!workspace) return null;

    const openDropdown = dropdownOpen && (!!searchText || !!recentProjects.length);
    const showRecentProjects = !loading && !!recentProjects.length && !searchText;
    const showSearchedProjects = !loading && !!searchText;
    const showEmpty = !loading && projects.length === 0 && !!searchText;

    return <div className="relative mx-4 lg:mx-0 w-full" data-testid="headerProjectSearchContainer">
        <SearchForm
            placeholder="Search projects by address or name..."
            className={"w-full xl:w-1/2"}
            text={searchText}
            onSearched={setSearchText}
            onFocus={() => setDropdownOpen(true)}
            onBlur={() => setTimeout(() => setDropdownOpen(false), 1)}
            autoComplete="off"
            data-testid="headerProjectSearchInput"
        />
        <Dropdown padding={'pt-1.5'} open={openDropdown} align="left" >
            {loading && <SearchLoader />}
            {showRecentProjects && <>
                <SearchHeader>
                    Recently Searched
                </SearchHeader>
                {recentProjects.map((project, index) => <ProjectItem index={index} project={project} onClick={() => onProjectClick(project, false)}
                    key={'projectRecentSearchResult' + project.objectId}
                />)}
                <SearchFooter>
                    Search to see more projects...
                </SearchFooter>
            </>}
            {showSearchedProjects && projects.map((project, index) => <ProjectItem index={index} project={project} onClick={() => onProjectClick(project)}
                key={'projectSearchResult' + project.objectId}
            />)}
            {showEmpty && <SearchEmptyItem>No projects found searching `{searchText}`</SearchEmptyItem>}
        </Dropdown>
    </div>;
}