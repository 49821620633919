import Loader from '../utility/Loader';

export const downloadIsPending = (download: any) =>
    ['pending', 'processing'].includes(download.status);
export const downloadIsProcessed = (download: any) => 'processed' === download.status;
export const downloadFailed = (download: any) => 'failed' === download.status;

export default function DownloadStatus({ download }: {
    download: any
}) {
    return (
        <>
            {downloadIsPending(download) && (
                <div className="flex justify-center text-xs font-semibold bg-gray-100 text-gray-800 w-28 text-center px-1 py-0.5 rounded-md">
                    Processing <Loader color="text-gray-800" size='4' />
                </div>
            )}
            {downloadIsProcessed(download) && (
                <div className="text-xs font-semibold bg-green-400 text-white w-28 text-center px-1 py-0.5 rounded-md">
                    Processed
                </div>
            )}
            {downloadFailed(download) && (
                <div className="text-xs font-semibold bg-red-400 text-white w-36 text-center px-1 py-0.5 rounded-md">
                    Error Publishing
                </div>
            )}
        </>
    );
}
