import { uploadAttachment } from '../hooks/useS3';
import { KML_FILE_TYPES } from '../partials/map_layers/hooks/useFileMapLayerData';
export default class MapLayerAttributeMappingService {
  constructor({
    asset,
    boundingBox,
    bounds,
    file,
    fileType,
    fileUrl,
    originalDimensions,
    name,
    rotation,
    scale,
  }) {
    this.asset = asset;
    this.bounds = bounds;
    this.boundingBox = boundingBox;
    this.name = name;
    this.originalDimensions = originalDimensions;
    this.scale = scale;
    this.file = file;
    this.fileType = fileType;
    this.fileUrl = fileUrl;
    this.rotation = rotation;
  }

  async attributes() {
    return {
      asset_attributes: await this.assetAttributes(),
      name: this.name,
      scale: this.scale,
      file_type: this.fileType,
      bounding_box_attributes: this.boundingBox,
      bounds_attributes: this.boundsAttributes,
      rotation: this.rotation,
    };
  }

  get boundsAttributes() {
    const northEastBound = this.bounds?.find((b) => b.direction === 'ne');
    const southWestBound = this.bounds?.find((b) => b.direction === 'sw');
    return this.boundingBox
      ? [
        {
          id: southWestBound?.id,
          direction: 'sw',
          lat: this.boundingBox.south,
          lng: this.boundingBox.west,
        },
        {
          id: northEastBound?.id,
          direction: 'ne',
          lat: this.boundingBox.north,
          lng: this.boundingBox.east,
        },
      ]
      : [];
  }

  async assetAttributes() {
    if (KML_FILE_TYPES.includes(this.fileType)) return this.kmlAssetAttributes();
    return await this.fileAssetAttributes();
  }

  kmlAssetAttributes() {
    if (!this.fileUrl) return null;

    const data = `data:application/json;base64,${Buffer.from(
      JSON.stringify(this.fileUrl)
    ).toString('base64')}`;

    return {
      id: this.asset?.id,
      info: {},
      file: {
        filename: this.name,
        data,
      },
    };
  }

  async fileAssetAttributes() {
    if (!this.file) return null;

    return {
      id: this.asset?.id,
      info: this.originalDimensions,
      file: await uploadAttachment(this.file),
    };
  }
}
