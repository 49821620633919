import * as React from 'react';
import { useState, useEffect } from 'react';
import { Datum } from '../../data/models';
import Modal, { ModalBody, ModalHeader } from '../utility/Modal';
import { datumName } from './hooks/useDatums';
import BaseMap from '../maps/mapbox/BaseMap';
export default function DatumPreviewModal({ datum, onClose }: { datum: Datum | null, onClose: () => void }) {
    const bounds = () => {
        if (!datum) return null;
        return [[datum.data.json.bbox.east_longitude, datum.data.json.bbox.north_latitude], [datum.data.json.bbox.west_longitude, datum.data.json.bbox.south_latitude]];
    }
    return <Modal isOpen={!!datum} onClose={onClose} modalClass="overflow-visible">
        <ModalHeader title={datum ? datumName(datum) : 'Loading Datum...'} onClose={onClose} />
        <ModalBody>
            <BaseMap bounds={bounds()} />
        </ModalBody>
    </Modal>
}