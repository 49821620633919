import client from './client';
const endpoint = '/report_page_entry_groups';

const getReportPageEntryGroups = (reportPageId, params = {}) =>
  client.get(`/report_pages/${reportPageId}${endpoint}`, params);
const deleteReportPageEntryGroups = (reportPageId, reportPageEntryGroupId) =>
  client.delete(
    `/report_pages/${reportPageId}${endpoint}/${reportPageEntryGroupId}`
  );

export default {
  getReportPageEntryGroups,
  deleteReportPageEntryGroups,
};
