import { memo, useEffect } from 'react';
import logo from '../images/logos/logo-black.svg';
import useApi from '../hooks/useApi';
import sharesApi from '../api/shares';
import { Link, Redirect, useParams } from 'react-router-dom';
import Loader from '../partials/utility/Loader';
import SharedReport from '../partials/shares/SharedReport';
import SharedAsset from '../partials/shares/SharedAsset';
import SharedDocument from '../partials/shares/SharedDocument';
import SharedFolder from '../partials/shares/SharedFolder';
import SharedConstructionDrawing from '../partials/shares/SharedConstructionDrawing';
import useAuth from '../auth/useAuth';
import { useSegmentTrack } from 'react-segment-analytics';
import { useQueryParams } from '../hooks/useQuery';

const shareableComponents = {
  SharedAsset,
  SharedReport,
  SharedDocument,
  SharedFolder,
  SharedConstructionDrawing,
};

function Share() {
  const {
    data: share,
    error,
    loading,
    request: getShare,
  } = useApi(sharesApi.getShare, null, true);
  const { share_id } = useParams();
  const { user } = useAuth();
  const track = useSegmentTrack();
  const params = useQueryParams();

  useEffect(() => {
    getShare(share_id);
  }, []);

  useEffect(() => {
    if (!share) return;

    const shareParams = {
      share_id: share.objectId,
      shareable_type: share.shareable_type,
      shareable_id: share.shareable_id,
      link: window.location.href,
      ...params,
    }

    track('Share Viewed', shareParams);

  }, [share, JSON.stringify(params)]);

  if (loading)
    return (
      <div className="flex py-5 justify-center" data-testid="loader-share">
        <Loader color="black" />
      </div>
    );

  if (share?.archived_at || !share)
    return (
      <Redirect
        to={{
          pathname: user
            ? `/${user.default_workspace?.objectId}/projects`
            : '/signin',
          state: {
            info: 'Sorry looks like that link is not valid',
            dismissable: user ? true : false,
          },
        }}
      />
    );

  const SharedComponent = shareableComponents[`Shared${share?.shareable_type}`];

  return (
    <div
      className={`flex ${(share?.shareable_type !== 'Document' ||
        share?.document?.file_details?.type?.includes('pdf')) &&
        'h-screen'
        } overflow-hidden`}
    >
      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-hidden overflow-x-hidden">
        {/*  Site header */}
        <header className="bg-white z-30 shadow-sm border-b">
          <div className="py-2 px-4 sm:px-6 lg:px-16">
            <div className="flex items-center w-full justify-between">
              <div>
                <img src={logo} className={'h-4 sm:h-5'} />
              </div>
              <div className="text-lg text-center font-light font-oswald uppercase">
                {share.report?.project?.name}
              </div>
              <div className="flex items-center justify-end ml-1">
                {/* <div className="hidden h-4 w-4 rounded-full bg-green-700 mr-4" style={{backgroundColor:"#A0D43E"}}/>
                        <ShareMenu /> */}
                {!user ? (
                  <>
                    <Link
                      className="btn rounded-sm border-gray-200 hover:border-gray-300 text-secondary mr-2"
                      to="/onboarding/sign_up"
                    >
                      Sign Up for Free
                    </Link>
                    <Link
                      className="btn rounded-sm border-0 hover:border-gray-300 text-secondary shadow-none"
                      to="/signin"
                    >
                      Sign In
                    </Link>
                  </>
                ) : (
                  <Link
                    className="btn rounded-sm border-0 hover:text-blue-900 text-secondary shadow-none"
                    to="/"
                  >
                    Dashboard
                  </Link>
                )}
              </div>
            </div>
          </div>
          <div className="py-4 px-4 sm:px-6 lg:px-16 bg-secondary w-full text-white text-sm md:text-md hidden">
            <div className="flex justify-between">
              <div>
                {share?.shared_by && (
                  <>
                    {share?.shared_by.name} ({share?.shared_by.email})
                  </>
                )}
              </div>
            </div>
          </div>
        </header>
        {SharedComponent && <SharedComponent share={share} />}
      </div>
    </div>
  );
}

export default memo(Share);
