import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SearchForm from '../partials/actions/SearchForm';

import TeamTileList from '../partials/team/TeamTileList';
import TeamTableList from '../partials/team/TeamTableList';

import { IoMdList } from 'react-icons/io';
import { FiGrid } from 'react-icons/fi';
import {
  FaSortAlphaDown,
  FaSortAlphaUp,
  FaRegCalendarAlt,
} from 'react-icons/fa';
import useApi from '../hooks/useApi';
import workspaceMembersApi from '../api/workspace_members';
import workspacesApi from '../api/workspaces';
import Loader from '../partials/utility/Loader';
import AddMemberModal from '../partials/team/AddMemberModal';
import CreateWorkspaceMenu from '../partials/workspaces/CreateWorkspaceMenu';
import AccessCodeModal from '../partials/access_codes/AccessCodeModal';
import EmptyState from '../partials/utility/EmptyState';
import useWorkspaceContext from '../hooks/useWorkspaceContext';

function TeamTabs() {
  let { workspace_id, path } = useParams();
  const {
    workspace: { permissions = {} },
  } = useWorkspaceContext();
  const [gridView, setGridView] = useState(true);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [searchParams, setSearchParams] = useState({
    page: 1,
    items: 12,
    q: '',
    order: 'updated_at desc',
  });
  const [members, setMembers] = useState([]);
  const {
    data: workspace,
    error: workspaceError,
    loading: loadingWorkspace,
    request: getWorkspace,
  } = useApi(workspacesApi.getWorkspace, null);
  const {
    data: { records, pagy },
    error,
    loading,
    request: loadWorkspaceMembers,
  } = useApi(
    workspaceMembersApi.getWorkspaceMembers,
    { records: [], pagy: {} },
    true
  );

  useEffect(() => {
    setMembers([]);
  }, [workspace_id]);

  useEffect(() => {
    getWorkspace(workspace_id);
  }, [workspace_id]);

  useEffect(() => {
    loadWorkspaceMembers(workspace_id, searchParams);
  }, [workspace_id, searchParams]);

  useEffect(() => {
    records.length && setMembers([...members, ...records]);
  }, [records]);

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl self-start">
      {/* Page header */}
      <div className="sm:flex sm:justify-between sm:items-center mb-8">
        {/* Left: Title */}
        <div className="mb-4 sm:mb-0 flex justify-between items-center relative min-w-56">
          <h1 className="text-2xl md:text-3xl text-gray-800 font-poppins uppercase font-semibold mr-4">
            {loadingWorkspace || !workspace ? (
              <Loader color="black" />
            ) : (
              <span>
                {workspace.name}{' '}
                <span className="text-gray-500 hidden">Team</span>{' '}
              </span>
            )}
          </h1>
          {workspace && (
            <CreateWorkspaceMenu
              workspace={workspace}
              openClasses={'transform rotate-180'}
            />
          )}
        </div>

        {/* Right: Actions */}
        <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
          <a
            className="flex items-center cursor-pointer mr-1"
            onClick={() => {
              setMembers([]);
              setSearchParams({ ...searchParams, order: 'updated_at desc' });
            }}
          >
            <FaRegCalendarAlt
              size={18}
              className={
                searchParams.order === 'updated_at desc'
                  ? 'text-primary'
                  : 'text-gray-700'
              }
            />
          </a>
          <a
            className="flex items-center cursor-pointer mr-1"
            onClick={() => {
              setMembers([]);
              setSearchParams({ ...searchParams, order: 'users.name asc' });
            }}
          >
            <FaSortAlphaDown
              size={18}
              className={
                searchParams.order === 'users.name asc'
                  ? 'text-primary'
                  : 'text-gray-700'
              }
            />
          </a>
          <a
            className="flex items-center cursor-pointer pr-3 border-r border-gray-300 border-r-2 mr-1"
            onClick={() => {
              setMembers([]);
              setSearchParams({ ...searchParams, order: 'users.name desc' });
            }}
          >
            <FaSortAlphaUp
              size={18}
              className={
                searchParams.order === 'users.name desc'
                  ? 'text-primary'
                  : 'text-gray-700'
              }
            />
          </a>
          <a
            className="flex items-center cursor-pointer mr-1"
            onClick={() => setGridView(true)}
          >
            <FiGrid
              size={18}
              className={gridView ? 'text-primary' : 'text-gray-700'}
            />
          </a>
          <a
            className="flex items-center cursor-pointer mr-3"
            onClick={() => setGridView(false)}
          >
            <IoMdList
              size={20}
              className={!gridView ? 'text-primary' : 'text-gray-700'}
            />
          </a>
          {/* Search form */}
          <SearchForm
            placeholder="Search Members..."
            onSearched={(text) => {
              setMembers([]);
              setSearchParams({ q: text, page: 1, items: 12 });
            }}
          />
          {/* Add member button */}
          {workspace && permissions?.can?.('manage_members') && (
            <AddMemberModal
              workspace={workspace}
              afterCreate={() => {
                setMembers([]);
                setSearchParams({ q: '', page: 1, items: 12 });
              }}
            />
          )}
          {permissions?.can?.('invite_members') && (
            <AccessCodeModal
              workspace={workspace}
              modalOpen={shareModalOpen}
              onClose={() => setShareModalOpen(false)}
            />
          )}
        </div>
      </div>

      {/* Cards */}
      {gridView && (
        <TeamTileList
          workspaceId={workspace_id}
          workspaceMembers={members}
          afterRemove={() => {
            setMembers([]);
            setSearchParams({ q: '', page: 1, items: 12 });
          }}
        />
      )}
      {!gridView && (
        <TeamTableList
          workspaceId={workspace_id}
          workspaceMembers={members}
          afterRemove={() => {
            setMembers([]);
            setSearchParams({ q: '', page: 1, items: 12 });
          }}
        />
      )}
      {loading && (
        <div className="flex py-4 justify-center">
          <Loader color="black" />
        </div>
      )}
      {!loading &&
        searchParams.q.trim().length === 0 &&
        members.length === 0 && (
          <div className="flex justify-center">
            <EmptyState
              heading={'Invite your team to join'}
              subHeading={
                'Share workspace link or access code to your team members'
              }
              buttonText={'Share Workspace'}
              icon={'user'}
              onClick={() => setShareModalOpen(true)}
            />
          </div>
        )}
      {!loading &&
        pagy.count !== undefined &&
        pagy.count !== members.length && (
          <div
            className="flex justify-center p-3 border-b border-gray-200"
            onClick={() =>
              setSearchParams({ ...searchParams, page: searchParams.page + 1 })
            }
          >
            <div className="text-black hover:opacity-80 text-sm font-semibold bg-white px-4 mt-1 py-2 shadow cursor-pointer">
              Load More
            </div>
          </div>
        )}

      {/* Pagination */}
      {/* <div className="mt-8">
              <PaginationNumeric />
            </div> */}
    </div>
  );
}

export default TeamTabs;
