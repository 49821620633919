import { useEffect } from 'react';
import fieldsApi from '../../../api/fields';
import useApi from '../../../hooks/useApi';
import useSaved from '../../../hooks/useSaved';

export default function useField(fieldId: number | null, {
    load = true
}: {
    load?: boolean;
} = {}) {
    const {
        data: field,
        error,
        loading,
        request: getField,
    } = useApi(fieldsApi.getField, null, load);

    const {
        data: updatedField,
        error: updateError,
        loading: updating,
        request: updateField,
    } = useApi(fieldsApi.updateField, null, false);

    const _updateField = (data: any) => {
        if (!fieldId) return;
        updateField(fieldId, data);
    }

    const reloadField = () => {
        if (!fieldId) return;
        getField(fieldId);
    }

    useEffect(() => {
        if (!fieldId) return;
        getField(fieldId);
    }, [fieldId])

    const { saved: updated } = useSaved(updatedField?.updated_at)

    return {
        latestField: updatedField || field,
        field,
        error,
        loading,
        getField,
        reloadField,
        updatedField,
        updateError,
        updating,
        updated,
        updateField: _updateField,
    }
}