import { memo, useState } from 'react';
import { EditProjectModal, Step } from './EditProjectModal';
import DescriptionModal from './DescriptionModal';
import MoreProjectDetailsModal from './MoreProjectDetailsModal';
import ProjectContactsModal from '../contacts/ProjectContactsModal';
import useProjectContext from '../../hooks/useProjectContext';
import useProjectContacts from '../../hooks/useProjectContacts';
import { Project, ProjectContact } from '../../data/models';
import Button from '../shared/Button';
import useFeature from '../feature_flags/hooks/useFeature';

import { checkPermission } from '../shared/utils';
import Loader from '../utility/Loader';

function ProjectDetailsCard() {
  const { project, loading, setProject } = useProjectContext();
  const [showEditModal, setShowEditModal] = useState(false);
  const [editModalStep, setEditModalStep] = useState<Step>('name');

  const { contacts: projectContacts, loading: contactsLoading, reloadContacts } = useProjectContacts(project?.objectId);

  const handleContactChange = () => {
    reloadContacts();
  };

  const hasContractDetails = (project: Project): boolean => {
    return (
      (project.contract_amount !== undefined && project.contract_amount > 0) ||
      (project.contract_length !== undefined &&
        project.contract_length > 0 &&
        project.contract_start !== undefined &&
        project.contract_end !== undefined)
    );
  };

  const descriptionOverflowed = (): boolean =>
    !!project?.description && project.description.length > 118;

  const noReportingFieldsFilled = (): boolean =>
    !project?.npdes_permit_no && !project?.ms4_operator && !project?.inspection_frequency;

  const setProjectAndHideModal = (newProject: any): void => {
    setProject?.({ ...newProject });
    setShowEditModal(false);
  };

  const setStepAndShowModal = (step: Step): void => {
    setEditModalStep(step);
    setShowEditModal(true);
  };

  const buttonText = project?.primary_contact && projectContacts.length === 0
    ? 'Edit'
    : projectContacts.length > 0
      ? 'View All'
      : 'View / Edit Contacts';

  const contactsModalButtonClassName = (hasContacts: boolean): string => {
    return `focus:outline-none bg-white border-2 border-sm-lightblue shadow-sm text-secondary rounded-md font-semibold cursor-pointer ${hasContacts ? 'px-2 py-1 group-hover:opacity-100 transition-opacity duration-300 opacity-0 text-xs' : 'px-5 py-2 group-hover:opacity-80 my-3 text-xs'
      }`;
  };

  const {
    announcementAvailable,
    announcementModal,
    enabled: qrCodeSharingEnabled,
    forceShowAnnouncement,
    beta,
  } = useFeature('qrcode_sharing');

  if (loading || !project) return <ProjectCard.Card>
    <ProjectCard.Header>
      <ProjectCard.HeaderTitle>Info</ProjectCard.HeaderTitle>
    </ProjectCard.Header>
    <div className="flex py-5 justify-center">
      <Loader color="black" />
    </div>
  </ProjectCard.Card>


  return (
    <>
      <ProjectCard.Card>
        <ProjectCard.Header>
          <ProjectCard.HeaderTitle>Info</ProjectCard.HeaderTitle>
          {checkPermission({ permissions: project.permissions, permission: 'update' }) && <EditButton
            onClick={() => setStepAndShowModal('name')}
          />}
        </ProjectCard.Header>
        <div className="grid grid-cols-12">
          <div
            className="col-span-6 bg-cover bg-center group relative"
            key={`projectMap${project.objectId}`}
            style={{ backgroundImage: `url(${project.map?.images?.large})` }}
          >
            {checkPermission({ permissions: project.permissions, permission: 'update' }) && <button
              className="transition-opacity duration-300 focus:outline-none absolute top-0 right-0 text-xs mt-1 mr-1 group-hover:opacity-100 bg-white px-2 py-1 border-2 border-sm-lightblue shadow-sm text-secondary rounded-md font-semibold hover:opacity-80 opacity-0 cursor-pointer"
              onClick={() => setStepAndShowModal('map')}
            >
              Edit
            </button>}
          </div>
          <div className="col-span-6 p-5 text-xs">
            <div className="group relative">
              <h2 className="text-md md:text-lg font-oswald uppercase font-medium">
                {project.name}
              </h2>
              <p>{project.map.location.street_address}</p>
              <p>
                {project.map.location.locality}, {project.map.location.region} {project.map.location.postal_code}
              </p>
              {checkPermission({ permissions: project.permissions, permission: 'update' }) && <button
                className={`transition-opacity duration-300 text-xs focus:outline-none bg-white border-2 border-sm-lightblue shadow-sm text-secondary rounded-md font-semibold hover:opacity-80 cursor-pointer ${!!project.description
                  ? 'px-2 py-1 group-hover:opacity-100 opacity-0 -mt-2 absolute top-0 right-0'
                  : 'px-5 py-2 '
                  }`}
                onClick={() => {
                  setStepAndShowModal('name');
                }}
              >
                {project.description ? 'Edit' : '+ Add Description'}
              </button>}
            </div>
            <div className="group">
              <div className={`mt-5 flex ${project.primary_contact || projectContacts.length > 0 ? 'items-center justify-between space-x-2' : 'items-start flex-col'}`}>
                <p className="font-bold">Contacts:</p>
                <ProjectContactsModal
                  title="Manage Project Contacts"
                  primaryContact={project.primary_contact}
                  setProjectAndHideModal={setProjectAndHideModal}
                  handleContactChange={handleContactChange}
                  button={
                    <Button
                      size="xs"
                      color="light"
                      text={buttonText}
                      data-testid="openContactsModal"
                      className={contactsModalButtonClassName(!!project.primary_contact || projectContacts.length > 0)} />
                  }
                />
              </div>
              {(project.primary_contact || projectContacts.length > 0) && (
                <div className="flex flex-wrap flex-col">
                  {project.primary_contact && (
                    <div key={project.primary_contact.objectId} className="flex">
                      <p className="text-xs font-semibold">
                        {project.primary_contact.name}<span className="text-gray-600 font-semibold ml-1">- Primary Contact</span>
                      </p>
                    </div>
                  )}
                  {projectContacts.map((contact: ProjectContact) => (
                    <div key={contact.id} className="flex">
                      <p className="text-xs font-semibold">
                        {contact.contact_name}{contact.role && (<span className="text-gray-600 font-semibold ml-1">- {contact.role}</span>)}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="group relative">
              <p className="font-bold mt-5 mb-2">Description:</p>
              {project.description && (
                <div
                  className={`${descriptionOverflowed() && ''
                    } transition-all duration-300 group `}
                >
                  <p className="line-clamp-3">{project.description}</p>
                  {descriptionOverflowed() && (
                    <DescriptionModal description={project.description} />
                  )}
                </div>
              )}
              {checkPermission({ permissions: project.permissions, permission: 'update' }) && <button
                className={`transition-opacity duration-300 text-xs focus:outline-none bg-white border-2 border-sm-lightblue shadow-sm text-secondary rounded-md font-semibold hover:opacity-80 cursor-pointer ${!!project.description
                  ? 'px-2 py-1 group-hover:opacity-100 opacity-0 -mt-2 absolute top-0 right-0'
                  : 'px-5 py-2 '
                  }`}
                onClick={() => {
                  setStepAndShowModal('name');
                }}
              >
                {project.description ? 'Edit' : '+ Add Description'}
              </button>}
            </div>
            <MoreProjectDetailsModal
              length={project.contract_length}
              amount={project.contract_amount}
              start_date={project.contract_start}
              end_date={project.contract_end}
              completion_percentage={project.completion_percentage}
              npdes_permit_no={project.npdes_permit_no}
              ms4_operator={project.ms4_operator}
              inspection_frequency={project.inspection_frequency}
              inspection_frequency_other={project.inspection_frequency_other}
              noReportingFieldsFilled={noReportingFieldsFilled}
              setStepAndShowModal={setStepAndShowModal}
              hasContractDetails={hasContractDetails(project as Project)}
              permissions={project.permissions}
            />
          </div>
        </div>
      </ProjectCard.Card>
      {showEditModal && (
        <EditProjectModal
          key={`project_${project.objectId}_edit_modal`}
          step={editModalStep}
          setStep={setEditModalStep}
          project={project}
          closeWithoutSaving={() => setShowEditModal(false)}
          afterSave={setProjectAndHideModal}
          hasContractDetails={hasContractDetails(project as Project)}
        />
      )}
      {announcementAvailable && announcementModal}
    </>
  );
}

const ProjectCardCard = ({ children }: {
  children: React.ReactNode
}) => <div className="bg-white rounded-sm border border-gray-200 overflow-hidden text-black shadow-xl mb-6 rounded-lg">
    <div className="flex flex-col h-full">
      {children}
    </div>
  </div>

const ProjectCardHeader = ({ children }: {
  children: React.ReactNode
}) => <div className="card-header">
    {children}
  </div>

const ProjectCardHeaderTitle = ({ children }: {
  children: React.ReactNode
}) => <p className="text-sm font-bold">
    {children}
  </p>

const ProjectCard = {
  Card: ProjectCardCard,
  Header: ProjectCardHeader,
  HeaderTitle: ProjectCardHeaderTitle
}

const EditButton = ({ onClick }: {
  onClick: () => void
}) => <div className="flex items-center">
    <button
      className="card-header-btn"
      type="button"
      onClick={onClick}
    >
      Edit
    </button>
  </div>

export default memo(ProjectDetailsCard);
