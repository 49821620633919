import Loader from '../utility/Loader';
import PinTypesViewModel from '../../models/PinTypesViewModel';
export default function PinTypeFilterableFields({
  pinTypes = [],
  chosenPinTypes = [],
  loading,
  fieldValues = [],
  onChosen,
}) {
  const filterableFields = new PinTypesViewModel(
    pinTypes.filter(({ id }) => chosenPinTypes.includes(id))
  ).filterableFields();

  return (
    <div className="text-xs">
      {loading && <Loader margin={'mx-2'} color="text-gray-800" />}
      {!loading && !filterableFields.length && <em>N/A</em>}
      {filterableFields.map(({ name: setName, fields }, index) => (
        <div
          className="bg-gray-50 p-2 rounded-lg border"
          key={`filterableField${setName}${index}`}
        >
          <div className="text-gray-500 italic mt-1 mb-2">{setName}</div>
          <div className="flex flex-wrap text-xs items-center">
            <a
              onClick={() => onChosen?.([])}
              key={`pin_status_any`}
              className={`cursor-pointer mr-2 px-2 py-1 mb-2 border rounded-md ${
                fieldValues.length === 0
                  ? 'bg-blue-50 text-secondary border-secondary shadow'
                  : 'bg-white'
              } hover:shadow`}
            >
              Any
            </a>
            {fields.map(({ name, value }) => {
              const fieldAdded = fieldValues.find((v) => value === v);
              return (
                <a
                  key={`pin_filter_dropdown_filter_fields_field_${value}`}
                  className={`cursor-pointer mr-2 px-2 py-1 mb-2 border rounded-md ${
                    fieldAdded
                      ? 'bg-blue-50 text-secondary border-secondary shadow'
                      : 'bg-white'
                  } hover:shadow`}
                  onClick={() =>
                    fieldAdded
                      ? onChosen([...fieldValues.filter((v) => v !== value)])
                      : onChosen([...fieldValues, value])
                  }
                >
                  {name}
                </a>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
}
