import { useEffect, useRef, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import Modal, { ModalHeader, ModalSuccessView, } from '../utility/Modal';
import useApi from '../../hooks/useApi';
import projectsApi from '../../api/projects';
import Loader from '../utility/Loader';

export default function DeleteProjectModal() {
  const {
    data: deleted_project,
    error,
    loading: deleting,
    request: deleteProject,
  } = useApi(projectsApi.deleteProject, null);
  const { project_id, workspace_id } = useParams();
  const [deleted, setDeleted] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const deletedTimer = useRef();
  const [modalOpen, setModalOpen] = useState(false);

  const removeProject = () => deleteProject(project_id);

  const closeModal = () => {
    setModalOpen(false);

    if (deletedTimer.current) {
      clearTimeout(deletedTimer.current);
      setRedirect(true);
    }
  };

  useEffect(() => {
    deleted_project && setDeleted(true);
  }, [deleted_project]);

  useEffect(() => {
    if (deleted)
      deletedTimer.current = setTimeout(() => setRedirect(true), 3000);
  }, [deleted]);

  if (redirect) return <Redirect to={`/${workspace_id}/projects?pcd=true`} />;

  return (
    <div>
      {/* Button */}
      <a
        className="cursor-pointer bg-white  hover:text-white hover:bg-red-600 rounded-md text-red-600 font-bold uppercase px-6 py-2 text-sm border border-red-600"
        type="button"
        onClick={() => setModalOpen(!modalOpen)}
      >
        Delete Project
      </a>
      <Modal isOpen={modalOpen} onClose={closeModal}>
        <ModalHeader title={'Confirm Project Removal'} onClose={closeModal} />
        <div className="px-2 pt-2">
          <div className="p-5">
            {!deleted && (
              <>
                <div className="mb-4">
                  <div className="font-semibold">Are you sure?</div>
                </div>
                <div>
                  This will completely delete the project and all of its data.
                  It cannot be undone.
                </div>
              </>
            )}
            {deleted && (
              <ModalSuccessView text={'Project successfully deleted'} />
            )}
          </div>

          {!deleted && (
            <div className="modal-footer">
              <button
                className="modal-remove-btn"
                type="button"
                onClick={removeProject}
                disabled={deleting}
              >
                Delete Project {deleting && <Loader />}
              </button>
              <button
                className="modal-close-btn"
                type="button"
                onClick={closeModal}
                disabled={deleting}
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}
