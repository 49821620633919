import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { FiDownload, FiLock, FiMove, FiUnlock } from 'react-icons/fi';
import { HiSwitchHorizontal } from 'react-icons/hi';
import { Link, Redirect, useParams, Prompt } from 'react-router-dom';
import mapLayersApi from '../../api/map_layers';
import useApi from '../../hooks/useApi';
import ErrorView from '../utility/ErrorView';
import Loader from '../utility/Loader';
import { getScaledDimensions } from '../../utility/imageScaling';
import AddAssetButton from './AddAssetButton';
import MapBoxMapLayers from './mapbox/MapBoxMapLayers';
import MapLayersSelect from './MapLayersSelect';
import MapLayerAttributeMappingService from '../../services/MapLayerAttributeMappingService';
import DeleteMapLayerButton from './DeleteMapLayerButton';
import ArchiveMapLayerButton from './ArchiveMapLayerButton';
import MapBoxMapLayer from './mapbox/MapBoxMapLayer';
import useRotationControls from './hooks/useRotationControls';
import { mapboxStyleUrls, MapLayerAlignmentMode } from '../../data/models';
import useTwoPointAlignment from './hooks/useTwoPointAlignment';
import Button from '../shared/Button';
import { FaBullseye } from 'react-icons/fa';
import useFeature from '../feature_flags/hooks/useFeature';
import { Dot } from '../shared/Badge';
import { DOWNLOADABLE_LAYER_TYPES, GEO_JSON_FILE_TYPES } from './hooks/useFileMapLayerData';

const UNIT_ABBREVIATIONS = {
  inch: 'in',
  feet: 'ft',
};


const DownloadButton = ({ layer }) => {

  if (!DOWNLOADABLE_LAYER_TYPES.includes(layer?.file_type) || !layer?.asset?.files?.download?.original) return null;

  const onClick = (e) => {
    e.stopPropagation();
  }

  return <a
    href={layer.asset.files.download.original}
    onClick={onClick}
    title="Download File"
    className="focus:outline-none cursor-pointer disabled:opacity-70 text-xxs flex items-center cursor-pointer hover:opacity-80 font-bold px-3 justify-center rounded-md-r bg-white text-gray-700 disabled:opacity-70">
    <FiDownload size={14} />
  </a>
}


export const FormPrompt = ({ hasUnsavedChanges, disclaimer = 'You have unsaved changes, are you sure you want to leave?' }) => {

  useEffect(() => {
    const onBeforeUnload = (e) => {
      if (hasUnsavedChanges && !document.activeElement?.href?.includes?.('download')) {
        e.preventDefault();
        e.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  const onLocationChange = useCallback((location) => {
    if (location.search.includes('via=cancel')) {
      return true;
    }
    return disclaimer;
  }, [hasUnsavedChanges]);

  return <Prompt when={hasUnsavedChanges} message={onLocationChange} />;
};

const NOT_SCALABLE_FILE_TYPES = ['KML', 'KMZ'];
const NOT_PREVIEWABLE_FILE_TYPES = ['TIF', 'TIFF', 'GEOTIFF', 'GEOTIF', 'FBX'];

const MapLayerEditToolbar = ({
  pointAlignmentConfig,
  alignmentMode,
  boundingBox,
  fileType,
  layerToCopy,
  locked,
  originalDimensions,
  rotation,
  scale,
  setAlignmentMode,
  setLocked,
  setLayerToCopy,
  setBoundingBox,
  setOriginalDimensions,
  setRotation,
  setScale,
  onChanged,
}) => {

  return <>
    <div
      className={`hidden mr-5 pr-5 border-r ${NOT_SCALABLE_FILE_TYPES.includes(fileType) &&
        'opacity-60 hover:cursor-not-allowed'
        }`}
    >
      <div className="font-semibold">Copy Layer</div>
      <MapLayersSelect
        selectedId={layerToCopy?.id}
        testIdPrefix={'inline'}
        placeHolder={'Select...'}
        marginRight={''}
        width={'text-xs w-24'}
        onSelect={(layer) => {
          if (!layer) return;
          setLayerToCopy(layer);
          onChanged?.();
          if (layer.bounding_box)
            setBoundingBox((b) => ({ ...b, ...layer.bounding_box }));
          if (layer.scale) setScale(layer.scale);
          if (layer.rotation) setRotation(layer.rotation);
          if (layer.asset.processed_dimensions)
            setOriginalDimensions(layer.asset.processed_dimensions);
        }}
      />
    </div>
    {pointAlignmentConfig.enabled && <div
      className={`mr-5 pr-5 border-r ${NOT_SCALABLE_FILE_TYPES.includes(fileType) &&
        'opacity-60 hover:cursor-not-allowed'
        }`}
    >
      <div className="font-semibold mb-1 flex justify-between">
        Align
        {pointAlignmentConfig.announcementAvailable && <>
          {pointAlignmentConfig.beta ? <Dot onClick={pointAlignmentConfig.forceShowAnnouncement} style="green" animated title="New map layer point alignment currently in beta!" /> : <Dot onClick={pointAlignmentConfig.forceShowAnnouncement} style="warning" animated title="New map layer point alignment tool now available!" />}
        </>}
      </div>
      <div className="flex items-center">
        <Button
          text={alignmentMode === MapLayerAlignmentMode.TwoPoint ? "Done" : "Point"}
          color={alignmentMode === MapLayerAlignmentMode.TwoPoint ? "secondary" : "light"}
          data-testid="pointAlignButton"
          className={"focus:outline-none"}
          size={'xs'}
          icon={<FaBullseye className="ml-2" />}
          onClick={() => setAlignmentMode(alignmentMode === MapLayerAlignmentMode.TwoPoint ? MapLayerAlignmentMode.Standard : MapLayerAlignmentMode.TwoPoint)}
        />
      </div>
    </div>}
    <div
      className={`mr-5 pr-5 border-r ${NOT_SCALABLE_FILE_TYPES.includes(fileType) &&
        'opacity-60 hover:cursor-not-allowed'
        }`}
    >
      <div className="font-semibold">Scale</div>
      <div className="flex items-center">
        <div className="bg-white border-b border-gray-300 overflow-hidden w-16">
          <input
            type="number"
            data-testid="scaleToInput"
            value={scale.to}
            onChange={({ target: { value: to } }) => {
              onChanged?.();
              setScale((s) => ({ ...s, to }));
            }}
            placeholder="0"
            className="disabled:opacity-70 disabled:cursor-not-allowed placeholder-gray-400 text-sm border-0 w-full focus:outline-none font-semibold text-center"
          />
        </div>
        <div className="text-secondary">
          {UNIT_ABBREVIATIONS[scale.to_unit]}
        </div>
        <div className="mx-3 text-2xl font-black">=</div>
        <div className="bg-white border-b border-gray-300 overflow-hidden w-16">
          <input
            type="number"
            data-testid="scaleFromInput"
            disabled={NOT_SCALABLE_FILE_TYPES.includes(fileType)}
            value={scale.from}
            onChange={({ target: { value: from } }) => {
              onChanged?.();
              setScale((s) => ({ ...s, from }));
            }}
            placeholder="0"
            className="disabled:opacity-70 disabled:cursor-not-allowed px-2 placeholder-gray-400 text-sm border-0 w-full focus:outline-none font-semibold text-center"
          />
        </div>
        <div className="text-secondary">
          {UNIT_ABBREVIATIONS[scale.from_unit]}
        </div>
      </div>
    </div>
    <div
      className={`mr-5 pr-5 border-r ${NOT_SCALABLE_FILE_TYPES.includes(fileType) &&
        'opacity-60 hover:cursor-not-allowed'
        }`}
    >
      <div className="font-semibold">Dimensions</div>
      <div className="flex items-center">
        <div className="bg-white border-b border-gray-300 overflow-hidden w-20 text-xs">
          <input
            type="number"
            disabled={true}
            value={originalDimensions?.width}
            onChange={({ target: { value: width } }) => {
              onChanged?.();
              setOriginalDimensions((o) => ({ ...o, width }));
            }}
            placeholder="0"
            className="disabled:opacity-70 disabled:cursor-not-allowed placeholder-gray-400 text-sm border-0 w-full focus:outline-none font-semibold text-center"
          />
        </div>
        <div className="text-secondary">w</div>
        <div className="mx-3 text-2xl font-black">
          <div
            className="p-2 transform rotate-180 rounded-full border shadow-sm cursor-pointer"
            data-testid="dimensionSwitchButton"
            onClick={() => {
              onChanged?.();
              setOriginalDimensions((o) => ({
                width: o.height,
                height: o.width,
              }));
            }}
          >
            <HiSwitchHorizontal size={14} />
          </div>
        </div>
        <div className="bg-white border-b border-gray-300 overflow-hidden w-20 text-xs">
          <input
            type="number"
            disabled={true}
            value={originalDimensions?.height}
            onChange={({ target: { value: height } }) => {
              onChanged?.();
              setOriginalDimensions((o) => ({ ...o, height }));
            }}
            placeholder="0"
            className="disabled:opacity-70 disabled:cursor-not-allowed px-2 placeholder-gray-400 relative text-sm border-0 w-full focus:outline-none font-semibold text-center"
          />
        </div>
        <div className="text-secondary">h</div>
      </div>
    </div>
    <div
      className={`mr-5 pr-5 border-r ${NOT_SCALABLE_FILE_TYPES.includes(fileType) &&
        'opacity-60 hover:cursor-not-allowed'
        }`}
    >
      <div className="font-semibold">Rotation</div>
      <div className="flex">
        <div className="bg-white border-b border-gray-300 overflow-hidden w-24">
          <div className="flex items-center">
            <input
              disabled={NOT_SCALABLE_FILE_TYPES.includes(fileType)}
              type="number"
              data-testid="rotationInput"
              step="0.25"
              placeholder="0"
              value={rotation}
              onChange={({ target: { value } }) => {
                onChanged?.();
                setRotation(value);
              }}
              className="disabled:opacity-70 disabled:cursor-not-allowed px-2 placeholder-gray-400 relative text-sm border-0 w-full border-0 outline-none focus:outline-none text-secondary font-semibold text-center"
            />
          </div>
        </div>
        <div className="text-xl text-secondary">°</div>
      </div>
    </div>
    <div
      className={`mr-5 pr-5 flex flex-col ${NOT_SCALABLE_FILE_TYPES.includes(fileType) &&
        'opacity-60 hover:cursor-not-allowed'
        }`}
    >
      <div className="font-semibold mb-1 flex items-center">
        <div>Position</div>
        <div className="text-xs text-gray-600 flex items-center ml-3">
          <FiMove className="mr-2" /> Drag the new layer to position on map
        </div>
      </div>
      <div className="flex items-center">
        <button
          onClick={() => setLocked(!locked)}
          disabled={NOT_SCALABLE_FILE_TYPES.includes(fileType)}
          title={`${locked ? 'Un-' : ''}Lock Map Layer Position`}
          data-testid="lockMapLayerPosition"
          className={`${locked ? 'bg-secondary text-white' : 'bg-white text-black'
            } disabled:opacity-70 disabled:cursor-not-allowed focus:outline-none mr-3 border border-gray-300 font-bold rounded-full w-8 h-8 flex items-center justify-center shadow-sm hover:shadow-xl text-xs cursor-pointer`}
        >
          {locked ? (
            <FiUnlock className={'text-white'} />
          ) : (
            <FiLock className={'text-black'} />
          )}
          <span className="hidden">
            {locked ? 'Un-lock layer' : 'Lock layer'}
          </span>
        </button>
        <div className="flex bg-gray-50 border rounded-md text-xs">
          <div
            className="flex items-center border-b border-gray-100"
            title={
              boundingBox &&
              `SW ${boundingBox.south}, ${boundingBox.west} | NE ${boundingBox.north}, ${boundingBox.east}`
            }
          >
            <div className="px-3 w-10 bg-gray-200 font-bold cursor-pointer">
              SW
            </div>
            <div className="px-2 text-secondary font-medium">
              {boundingBox?.south?.toFixed?.(2) || 'N/A'} ,{' '}
              {boundingBox?.west?.toFixed?.(2) || 'N/A'}
            </div>
            <div className="px-3 w-10 bg-gray-200 font-bold cursor-pointer">
              NE
            </div>
            <div className="px-2 text-secondary font-medium">
              {boundingBox?.north?.toFixed?.(2) || 'N/A'} ,{' '}
              {boundingBox?.east?.toFixed?.(2) || 'N/A'}
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
};

const NameInput = ({ value, onChange }) => (
  <input
    type="text"
    placeholder="Layer Name"
    data-testid="mapLayerNameInput"
    value={value}
    onChange={({ target: { value } }) => onChange?.(value)}
    className="px-2 bg-gray-200 flex-grow placeholder-gray-400 relative text-sm border-0 w-full bg-transparent focus:outline-none text-gray-800 font-semibold"
  />
);

const CancelButton = ({ to, disabled }) => (
  <Link
    to={to}
    className="disabled:opacity-70 text-xs justify-center flex items-center cursor-pointer mr-2 px-4 py-2 hover:opacity-80 font-bold border rounded-md bg-white text-gray-900 hover:shadow disabled:opacity-70"
    disabled={disabled}
  >
    Cancel
  </Link>
);

const SaveMapLayerButton = ({ onClick, saving }) => (
  <a
    className="disabled:opacity-70 text-xs justify-center flex items-center cursor-pointer mr-2 px-4 py-2 hover:opacity-80 font-bold border rounded-md bg-green-500 text-white hover:shadow disabled:opacity-70"
    disabled={saving}
    onClick={onClick}
  >
    {saving && <Loader margin="mr-1" />} {saving ? 'Saving' : 'Save Map Layer'}
  </a>
);

const MAP_POSITION_EVENTS = [
  'rotate',
  'drag',
  'resize',
  'move',
  'wheel',
  'zoom',
  'boxzoomend',
]

export {
  MAP_POSITION_EVENTS,
}

function addRotations(rotation1, rotation2) {
  // Add the two rotations
  let result = parseFloat(rotation1) + parseFloat(rotation2);

  // Normalize the result within the range of -360 to 360 degrees
  if (result > 360 || result < -360) {
    result = ((result % 360) + 360) % 360;
    if (result > 180) {
      result -= 360;
    }
  }

  return result;
}

export {
  addRotations,
}

const DraggableMapLayer = ({
  alignmentMode,
  disabled,
  opacity,
  originalDimensions,
  boundingBox,
  onBoundingBoxChange,
  rotation: defaultRotation,
  onRotate,
  onScale,
  imageUrl,
  scale,
  map,
  mapContainer,
}) => {

  const [firstLoad, setFirstLoad] = useState(true);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [points, setPoints] = useState([]);
  const [dimensions, setDimensions] = useState(originalDimensions);
  const mapLayerRef = useRef(null);
  const mapLayerBoundsRef = useRef(null);


  const { cursor, rotation, rotating, handleComponents } = useRotationControls({
    enabled: alignmentMode === MapLayerAlignmentMode.Standard,
    map,
    defaultRotation: parseFloat(defaultRotation),
    target: mapLayerRef.current,
  })

  const {
    canvas,
    pointComponents,
    position: alignedPosition,
    rotation: alignedRotation,
    scale: alignedScale,
  } = useTwoPointAlignment({
    setPoints,
    points,
    enabled: alignmentMode === MapLayerAlignmentMode.TwoPoint,
    map,
    mapContainer,
    mapLayer: mapLayerRef.current,
    boundingBox,
    rotation: defaultRotation,
  });

  useEffect(() => {
    if (alignmentMode !== MapLayerAlignmentMode.TwoPoint || !alignedPosition || !alignedScale || !alignedRotation) return;
    onRotate?.(addRotations(rotation, alignedRotation));
    setPosition(alignedPosition);
    updateBoundingBox(null, alignedPosition);
    onScale((s) => ({ ...s, from: s.from * alignedScale }));
  }, [alignmentMode, JSON.stringify(alignedPosition), alignedScale])

  const updatePosition = (_, { x, y }) => {
    const xDiff = x - position.x;
    const yDiff = y - position.y;
    setPosition({ x, y });
    if (!!points.length) setPoints(points.map((pointProps) => {
      if (!pointProps.layer) return pointProps;
      return { ...pointProps, x: pointProps.x + xDiff, y: pointProps.y + yDiff };
    }));
  };

  const updateBoundingBox = (_, { x, y }) => {
    const northWestCoordinate = map.unproject([x - dimensions.width / 2, y - dimensions.height / 2]);
    const southEastCoordinate = map.unproject([
      x + dimensions.width / 2,
      y + dimensions.height / 2,
    ]);

    onBoundingBoxChange?.({
      north: northWestCoordinate.lat,
      west: northWestCoordinate.lng,
      south: southEastCoordinate.lat,
      east: southEastCoordinate.lng,
    });
  };

  useEffect(() => {
    if (rotation === defaultRotation) return;
    onRotate?.(rotation);
  }, [rotation])

  useEffect(() => {
    if (!firstLoad || !boundingBox) return;

    const mapFitLayerBounds = () => {
      map.fitBounds(
        [
          [boundingBox.west, boundingBox.south],
          [boundingBox.east, boundingBox.north],
        ],
        {
          padding: 100,
        }
      );

      setFirstLoad(false);
    };

    mapFitLayerBounds();
  }, [firstLoad, boundingBox]);

  useEffect(() => {
    if (!scale?.to || ["0", 0].includes(scale?.to)) return;

    const setScaledPosition = () => {
      const { lat, lng } = map.getCenter();

      const centerLatLng = boundingBox
        ? [
          boundingBox?.west - (boundingBox?.west - boundingBox?.east) / 2,
          boundingBox?.north - (boundingBox?.north - boundingBox?.south) / 2,
        ]
        : [lng, lat];
      const centerPoint = map.project(centerLatLng);

      const { width, height } = getScaledDimensions({
        width: originalDimensions.width,
        height: originalDimensions.height,
        dimensions: {
          width: originalDimensions.width / 36,
          height: originalDimensions.height / 36,
        },
        ftPerInch: Number(scale.from) / Number(scale.to),
        zoom: map.getZoom(),
        lat: centerLatLng[1],
      });

      const x = centerPoint.x - width / 2;
      const y = centerPoint.y - height / 2;

      const northWestCoordinate = map.unproject([x, y]);
      const southEastCoordinate = map.unproject([x + width, y + height]);

      setPosition(centerPoint);

      setDimensions({
        width,
        height,
      });

      onBoundingBoxChange?.({
        north: northWestCoordinate.lat,
        west: northWestCoordinate.lng,
        south: southEastCoordinate.lat,
        east: southEastCoordinate.lng,
      });
    };

    setScaledPosition();
  }, [scale, originalDimensions]);

  useEffect(() => {
    if (!map || !boundingBox) return;

    const updatePositionToNewMapBounds = () => {
      const northWestPoint = map.project([boundingBox.west, boundingBox.north]);
      const northEastPoint = map.project([boundingBox.east, boundingBox.north]);
      const southWestPoint = map.project([boundingBox.west, boundingBox.south]);

      const width = Math.abs(southWestPoint.x - northEastPoint.x);
      const height = Math.abs(northEastPoint.y - southWestPoint.y);
      const centerPoint = {
        x: southWestPoint.x + width / 2,
        y: northEastPoint.y + height / 2,
      }

      setPosition(centerPoint);
      setDimensions({ width, height });
    };

    MAP_POSITION_EVENTS.forEach((event) => {
      map.on(event, updatePositionToNewMapBounds);
    });

    return () => {
      MAP_POSITION_EVENTS.forEach((event) => {
        map.off(event, updatePositionToNewMapBounds);
      });
    };
  }, [map, JSON.stringify(boundingBox), JSON.stringify(points)]);

  useEffect(() => {
    if (!mapLayerBoundsRef.current || !map) return;

    const onWheel = (wheelEvent) => {
      wheelEvent.cancelable && wheelEvent.preventDefault?.();
      wheelEvent.stopPropagation?.();
      wheelEvent.stopImmediatePropagation?.();
      map._canvas.dispatchEvent(
        new wheelEvent.constructor(
          wheelEvent.type,
          wheelEvent
        )
      );

      return false;
    }

    mapLayerBoundsRef.current.addEventListener('wheel', onWheel)

    return () => mapLayerBoundsRef.current?.removeEventListener?.('wheel', onWheel)

  }, [map, mapLayerBoundsRef.current])

  return (<>
    {cursor}
    <Draggable
      disabled={disabled || rotating}
      onDrag={updatePosition}
      onStop={updateBoundingBox}
      position={position}
      // if we wanted position to represent the center use positionOffset
      positionOffset={{ x: -dimensions.width / 2, y: -dimensions.height / 2 }}
    >
      <div
        className={`absolute ${disabled ? 'pointer-events-none' : ''}`}
        data-testid="mapLayerMapNew"
        data-mapLayer-boundingBox={JSON.stringify(boundingBox)}
        data-mapLayer-width={originalDimensions.width}
        data-mapLayer-height={originalDimensions.height}
        ref={mapLayerBoundsRef}
        style={{
          width: `${dimensions.width}px`,
          height: `${dimensions.height}px`,
          opacity: `${opacity}%`,
          touchAction: 'none',
        }}
      >
        <div className={`w-full h-full select-none rounded-lg cursor-move ring-4`}
          ref={mapLayerRef}
          style={{
            transform: `rotate(${rotation}deg)`,
          }}
        >
          {handleComponents}
          <img
            className="w-full h-full pointer-events-none"
            data-testid={`mapLayerDraggableLayer${imageLoaded ? 'Loaded' : 'Loading'
              }`}
            onLoad={() => setImageLoaded(true)}
            src={imageUrl}
          />
        </div>
        {!imageLoaded && (
          <div
            style={{
              transform: `rotate(${rotation}deg)`,
            }}
            className="bg-white bg-opacity-50 absolute inset-0 flex items-center justify-center"
          >
            <Loader color="black" />
          </div>
        )}
      </div>
    </Draggable>
    {pointComponents}
    {canvas}
  </>);
};

const OpacitySlider = ({ opacity, setOpacity }) => (
  <div
    className={`font-bold bg-secondary bg-opacity-80 backdrop-filter backdrop-blur-md rounded-md px-3 py-1 flex flex-col items-center shadow-md cursor-pointer`}
  >
    <input
      type="range"
      min={1}
      max={100}
      className="w-40"
      defaultValue={opacity}
      onChange={({ target: { value } }) => setOpacity(value)}
    />
    <div className="flex w-full justify-between text-xs">
      <span className="text-gray-50">Opacity</span>
      <span className="text-white">{opacity}%</span>
    </div>
  </div>
);

const NotScalableOverlay = ({ fileType, newMapLayer }) => (
  <div className="z-30 backdrop-filter rounded-lg backdrop-blur-sm w-full h-full absolute bg-blue-600 bg-opacity-40 text-3xl flex items-center justify-center text-white text-center">
    <div className="flex flex-col">
      <span className="font-semibold text-5xl mb-4">
        {fileType} files can not be previewed
        {newMapLayer && ' before upload'}.
      </span>{' '}
      <em className="text-blue-300">
        Please make sure the file is correct before{' '}
        {!newMapLayer ? 'updating' : 'uploading'}.
      </em>
    </div>
  </div>
);

const removeGeoJsonFromMap = ({ map }) => {
  if (map.getSource('kml-layer')) {
    map.removeLayer('kml-lines');
    map.removeLayer('kml-polygons');
    map.removeLayer('kml-points');
    map.removeSource('kml-layer');
  }
};

const addGeojsonToMap = ({ map, file }) => {
  removeGeoJsonFromMap({ map });
  map.addSource('kml-layer', {
    type: 'geojson',
    data: file,
  });

  map.addLayer({
    id: 'kml-lines',
    type: 'line',
    source: 'kml-layer',
    paint: {
      'line-color': [
        'coalesce',
        ['get', 'line-color'],
        ['get', 'stroke'], // backwards compat
      ],
      'line-width': [
        'coalesce',
        ['get', 'line-width'],
        ['get', 'width'], // backwards compat
        1.75,
      ],
    },
    filter: ['==', '$type', 'LineString'],
  });

  map.addLayer({
    id: 'kml-polygons',
    type: 'fill',
    source: 'kml-layer',
    paint: {
      'fill-color': [
        'coalesce',
        ['get', 'fill-color'],
        ['get', 'stroke'], // backwards compat
      ],
      'fill-opacity': ['get', 'fill-opacity'],
    },
    filter: ['==', '$type', 'Polygon'],
  });

  map.addLayer({
    id: 'kml-points',
    type: 'circle',
    source: 'kml-layer',
    paint: {
      'circle-radius': 2,
      'circle-color': ['get', 'stroke'],
    },
    filter: ['==', '$type', 'Point'],
  });
};

const useInitializeMapboxMap = ({
  bounds,
  enabled = true,
  mapContainerRef,
  mapRef,
}) => {
  useEffect(() => {
    if (mapRef.current || !enabled) return;
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: mapboxStyleUrls['satellite-streets'],
      bounds,
      pitchWithRotate: false,
      dragRotate: false,
      touchZoomRotate: false,
    });

    mapRef.current.on('load', () => {
      const nav = new mapboxgl.NavigationControl({
        visualizePitch: true,
      });
      mapRef.current.addControl(nav, 'bottom-right');
    });
  }, [enabled, mapRef.current]);
};

const MapboxMapLayerNewRefactor = ({ project }) => {
  const { workspace_id, project_id, map_layer_id } = useParams();

  const mapContainer = useRef(null);
  const map = useRef(null);

  const [name, setName] = useState('');
  const [originalDimensions, setOriginalDimensions] = useState();
  const [scale, setScale] = useState({
    from: 40,
    from_unit: 'feet',
    to: 1,
    to_unit: 'inch',
    system: 'imperial',
  });
  const [rotation, setRotation] = useState(0);
  const [boundingBox, setBoundingBox] = useState();
  const [alignmentMode, setAlignmentMode] = useState(MapLayerAlignmentMode.Standard);
  const [file, setFile] = useState();
  const [fileType, setFileType] = useState();
  const [uploadedFileUrl, setUploadedFileUrl] = useState();
  const [layerToCopy, setLayerToCopy] = useState();
  const [locked, setLocked] = useState(false);
  const [opacity, setOpacity] = useState(75);

  const pointAlignmentConfig = useFeature('point_map_layer_align');

  const {
    data: mapLayer,
    error,
    loading: saving,
    request: addMapLayer,
  } = useApi(mapLayersApi.addMapLayer, null);

  const [uploading, setUploading] = useState(false);

  useInitializeMapboxMap({
    bounds: [
      [project.bounding_box.west, project.bounding_box.south],
      [project.bounding_box.east, project.bounding_box.north],
    ],
    mapContainerRef: mapContainer,
    mapRef: map,
  });

  useEffect(() => {
    //For whatever reason the math goes sideqays on alignment at zoom > 20
    //TODO - figure out why
    if (!map.current) return;
    map.current.setMaxZoom(alignmentMode === MapLayerAlignmentMode.TwoPoint ? 20 : 24);
  }, [alignmentMode, map.current])

  const saveMapLayer = async () => {
    if (saving || uploading) return;

    setUploading(true);

    const service = new MapLayerAttributeMappingService({
      name,
      scale,
      file,
      fileType,
      fileUrl: uploadedFileUrl,
      boundingBox,
      originalDimensions,
      rotation,
    });

    addMapLayer(project_id, await service.attributes());
    setUploading(false);
  };

  const addAssetButton = useMemo(() => {
    return <AddAssetButton
      modalOpen={map_layer_id === 'new'}
      onCopyLayerChosen={setLayerToCopy}
      onAssetAdded={({
        bounding_box,
        scale,
        rotation,
        name,
        file,
        file_type,
        asset: {
          files: { large },
          processed_dimensions,
        },
      }) => {
        if (bounding_box) setBoundingBox(bounding_box);
        if (scale) setScale(scale);
        if (rotation) setRotation(rotation);

        setName(name);
        setFile(file);
        setFileType(file_type);
        setUploadedFileUrl(large);
        pointAlignmentConfig.showAnnouncement();
        if (GEO_JSON_FILE_TYPES.includes(file_type)) {
          addGeojsonToMap({
            map: map.current,
            file: large,
          });
        } else {
          removeGeoJsonFromMap({ map: map.current });
          setOriginalDimensions(processed_dimensions);
        }
      }}
    />
  }, [map_layer_id])

  if (mapLayer)
    return (
      <Redirect
        to={`/${workspace_id}/projects/${project_id}/maps?map_layer_id=${mapLayer.objectId}`}
      />
    );

  return (
    <>
      {pointAlignmentConfig.announcementAvailable && pointAlignmentConfig.announcementModal}
      <FormPrompt hasUnsavedChanges={!!uploadedFileUrl && !mapLayer} />
      {error && <ErrorView error={error} extraClass={'rounded-none'} />}
      <div className="px-5 py-2 bg-gray-50 mb-4 sm:mb-0 border-b grid grid-cols-8 gap-4">
        <div className="col-span-5 flex items-center overflow-hidden bg-white shadow-sm border pr-2">
          <NameInput value={name} onChange={setName} />
          {addAssetButton}
        </div>
        <div className="flex col-span-3 justify-end">
          <SaveMapLayerButton
            onClick={saveMapLayer}
            saving={saving || uploading}
          />
          <CancelButton
            to={`/${workspace_id}/projects/${project_id}/maps?via=cancel`}
            disabled={saving}
          />
        </div>
      </div>
      <div className="flex py-2 px-5 w-full bg-white text-sm">
        <MapLayerEditToolbar
          pointAlignmentConfig={pointAlignmentConfig}
          alignmentMode={alignmentMode}
          setAlignmentMode={setAlignmentMode}
          boundingBox={boundingBox}
          fileType={fileType}
          layerToCopy={layerToCopy}
          locked={locked}
          originalDimensions={originalDimensions}
          rotation={rotation}
          scale={scale}
          setLocked={setLocked}
          setLayerToCopy={setLayerToCopy}
          setBoundingBox={setBoundingBox}
          setOriginalDimensions={setOriginalDimensions}
          setRotation={setRotation}
          setScale={setScale}
        />
      </div>
      <div className="h-full w-full bg-gray-100 relative overflow-hidden">
        {NOT_PREVIEWABLE_FILE_TYPES.includes(fileType) && (
          <NotScalableOverlay fileType={fileType} newMapLayer />
        )}
        <MapBoxMapLayers
          key={'mapBoxMapLayersNew'}
          map={map.current}
          canAddLayers={true}
          canEditLayers={false}
        />
        <div className="absolute top-4 right-5 z-30 flex items-start">
          {map.current?.getProjection?.() && (
            <OpacitySlider opacity={opacity} setOpacity={setOpacity} />
          )}
        </div>
        <div
          ref={mapContainer}
          className="w-full h-full"
          data-testid="mapLayerContainer"
        >
          {uploadedFileUrl && originalDimensions && !GEO_JSON_FILE_TYPES.includes(fileType) && (
            <DraggableMapLayer
              boundingBox={boundingBox}
              alignmentMode={alignmentMode}
              disabled={locked || alignmentMode === MapLayerAlignmentMode.TwoPoint}
              map={map.current}
              mapContainer={mapContainer.current}
              imageUrl={uploadedFileUrl}
              opacity={opacity}
              rotation={rotation}
              onRotate={setRotation}
              onScale={setScale}
              scale={scale}
              originalDimensions={originalDimensions}
              onBoundingBoxChange={(newBoundingBox) =>
                setBoundingBox((b) => ({ ...b, ...newBoundingBox }))
              }
            />
          )}
        </div>
      </div>
    </>
  );
};

export const MapboxMapLayerEdit = () => {
  const { workspace_id, project_id, map_layer_id } = useParams();

  const mapContainer = useRef(null);
  const map = useRef(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [dirty, setDirty] = useState(false);

  const [name, setName] = useState('');
  const [originalDimensions, setOriginalDimensions] = useState();
  const [scale, setScale] = useState({
    from: 40,
    from_unit: 'feet',
    to: 1,
    to_unit: 'inch',
    system: 'imperial',
  });
  const [rotation, setRotation] = useState(0);
  const [boundingBox, setBoundingBox] = useState();
  const [file, setFile] = useState();
  const [fileType, setFileType] = useState();
  const [uploadedFileUrl, setUploadedFileUrl] = useState();
  const [uploading, setUploading] = useState(false);
  const [alignmentMode, setAlignmentMode] = useState(MapLayerAlignmentMode.Standard);
  const [layerToCopy, setLayerToCopy] = useState();
  const [locked, setLocked] = useState(false);
  const [opacity, setOpacity] = useState(75);

  const pointAlignmentConfig = useFeature('point_map_layer_align');

  const {
    data: loadedMapLayer,
    loading,
    request: getMapLayer,
  } = useApi(mapLayersApi.getMapLayer, null, true);

  const {
    data: mapLayer,
    error,
    loading: updating,
    request: updateMapLayer,
  } = useApi(mapLayersApi.updateMapLayer, null);

  useInitializeMapboxMap({
    bounds: [
      [loadedMapLayer?.bounding_box?.west, loadedMapLayer?.bounding_box?.south],
      [loadedMapLayer?.bounding_box?.east, loadedMapLayer?.bounding_box?.north],
    ],
    enabled: !!loadedMapLayer,
    mapContainerRef: mapContainer,
    mapRef: map,
  });

  useEffect(() => {
    //For whatever reason the math goes sideqays on alignment at zoom > 20
    //TODO - figure out why
    if (!map.current) return;
    map.current.setMaxZoom(alignmentMode === MapLayerAlignmentMode.TwoPoint ? 20 : 24);
  }, [alignmentMode, map.current])

  useEffect(() => {
    if (!loadedMapLayer || !map.current) return;

    const {
      bounding_box,
      scale,
      rotation,
      name,
      file,
      file_type,
      asset: {
        files: { large },
        processed_dimensions,
      },
    } = loadedMapLayer;

    if (bounding_box) setBoundingBox(bounding_box);
    if (scale) setScale(scale);
    if (rotation) setRotation(rotation);

    setName(name);
    setFile(file);
    setFileType(file_type);
    setUploadedFileUrl(large);
    setOriginalDimensions(processed_dimensions);
    pointAlignmentConfig.showAnnouncement();

    map.current.on('load', () => setMapLoaded(true));
  }, [loadedMapLayer, map.current]);

  useEffect(() => {
    if (map_layer_id === 'new') return;
    getMapLayer(map_layer_id);
  }, [map_layer_id]);

  const saveMapLayer = async () => {
    if (updating || uploading) return;

    setUploading(true);

    const service = new MapLayerAttributeMappingService({
      asset: loadedMapLayer?.asset,
      bounds: loadedMapLayer?.bounds || [], //TODO: bounds is deprecated, remove it
      boundingBox,
      file,
      fileType,
      fileUrl: uploadedFileUrl,
      name,
      originalDimensions,
      rotation,
      scale,
    });

    updateMapLayer(map_layer_id, await service.attributes());
    setUploading(false);
  };

  if (mapLayer)
    return (
      <Redirect
        to={`/${workspace_id}/projects/${project_id}/maps?map_layer_id=${mapLayer.objectId}`}
      />
    );

  return (
    <>
      {pointAlignmentConfig.announcementAvailable && pointAlignmentConfig.announcementModal}
      <FormPrompt hasUnsavedChanges={dirty && !mapLayer} />
      {loading && (
        <div className="backdrop-filter backdrop-blur-sm flex items-center z-40 bg-black bg-opacity-30 justify-center absolute inset-0">
          <Loader color="white" />
        </div>
      )}
      {error && <ErrorView error={error} extraClass={'rounded-none'} />}
      <div className="px-5 py-2 bg-gray-50 mb-4 sm:mb-0 border-b grid grid-cols-8 gap-4">
        <div className="col-span-5 flex items-center overflow-hidden bg-white shadow-sm border">
          <NameInput value={name} onChange={setName} />
          <AddAssetButton
            modalOpen={map_layer_id === 'new'}
            onCopyLayerChosen={setLayerToCopy}
            onAssetAdded={({
              bounding_box,
              scale,
              rotation,
              name,
              file,
              file_type,
              asset: {
                files: { large },
                processed_dimensions,
              },
            }) => {
              if (bounding_box)
                setBoundingBox((b) => ({ ...b, ...bounding_box }));
              if (scale) setScale(scale);
              if (rotation) setRotation(rotation);

              setName(name);
              setFile(file);
              setFileType(file_type);
              setUploadedFileUrl(large);
              setDirty(true);
              if (GEO_JSON_FILE_TYPES.includes(file_type))
                addGeojsonToMap({
                  map: map.current,
                  file: large,
                });
              else {
                removeGeoJsonFromMap({ map: map.current });
                setOriginalDimensions(processed_dimensions);
              }
            }}
          />
          <DownloadButton layer={loadedMapLayer} />
        </div>
        <div className="flex col-span-3 justify-end">
          <SaveMapLayerButton
            onClick={saveMapLayer}
            saving={updating || uploading}
          />
          <DeleteMapLayerButton />
          {loadedMapLayer && (
            <ArchiveMapLayerButton
              mapLayer={loadedMapLayer}
              button={
                <a className="disabled:opacity-70 text-xs flex items-center cursor-pointer mr-2 px-4 py-2 hover:opacity-80 font-bold border rounded-md bg-tertiary text-white hover:shadow disabled:opacity-70">
                  {loadedMapLayer?.archived_at ? 'Unarchive' : 'Archive'}
                </a>
              }
              onSaved={() => getMapLayer(map_layer_id)}
            />
          )}
          <CancelButton
            to={`/${workspace_id}/projects/${project_id}/maps?via=cancel`}
            disabled={updating}
          />
        </div>
      </div>
      <div className="flex py-2 px-5 w-full bg-white text-sm">
        <MapLayerEditToolbar
          pointAlignmentConfig={pointAlignmentConfig}
          alignmentMode={alignmentMode}
          boundingBox={boundingBox}
          fileType={fileType}
          layerToCopy={layerToCopy}
          locked={locked}
          originalDimensions={originalDimensions}
          rotation={rotation}
          scale={scale}
          setLocked={setLocked}
          setLayerToCopy={setLayerToCopy}
          setBoundingBox={setBoundingBox}
          setOriginalDimensions={setOriginalDimensions}
          setAlignmentMode={setAlignmentMode}
          setRotation={setRotation}
          setScale={setScale}
        />
      </div>
      <div className="h-full w-full bg-gray-100 relative overflow-hidden">
        {NOT_PREVIEWABLE_FILE_TYPES.includes(fileType) && (
          <NotScalableOverlay fileType={fileType} />
        )}
        <MapBoxMapLayers
          key={'mapBoxMapLayersNew'}
          map={map.current}
          canAddLayers={true}
          canEditLayers={false}
        />
        <div className="absolute top-4 right-5 z-30 flex items-start">
          {map.current?.getProjection?.() && (
            <OpacitySlider opacity={opacity} setOpacity={setOpacity} />
          )}
        </div>
        <div
          ref={mapContainer}
          data-testid="mapLayerContainer"
          className="w-full h-full"
        >
          {uploadedFileUrl && originalDimensions && !GEO_JSON_FILE_TYPES.includes(fileType) && !NOT_PREVIEWABLE_FILE_TYPES.includes(fileType) && (
            <DraggableMapLayer
              alignmentMode={alignmentMode}
              disabled={locked || alignmentMode === MapLayerAlignmentMode.TwoPoint}
              boundingBox={boundingBox}
              map={map.current}
              mapContainer={mapContainer.current}
              imageUrl={uploadedFileUrl}
              onRotate={setRotation}
              opacity={opacity}
              rotation={rotation}
              scale={scale}
              onScale={setScale}
              originalDimensions={originalDimensions}
              onBoundingBoxChange={(newBoundingBox) => {
                setDirty(true);
                setBoundingBox((b) => ({ ...b, ...newBoundingBox }));
              }}
            />
          )}
          {!map.current?.getSource?.('kml-layer') &&
            mapLoaded &&
            loadedMapLayer &&
            GEO_JSON_FILE_TYPES.includes(fileType) && (
              <MapBoxMapLayer
                key={`mapBoxMapLayer${loadedMapLayer.objectId}`}
                layer={loadedMapLayer}
                map={map.current}
              />
            )}
        </div>
      </div>
    </>
  );
};

export default MapboxMapLayerNewRefactor;
