// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import { useEffect, useState } from 'react';

export default function useMapControls({
    map,
    locked,
    userLocation = true,
    showNavigationControls
}: {
    map: mapboxgl.Map | null;
    locked: boolean;
    userLocation: boolean;
    showNavigationControls: boolean;
}) {

    const [navigation] = useState(
        new mapboxgl.NavigationControl({ visualizePitch: true })
    );

    const [userLocationControl] = useState(
        new mapboxgl.GeolocateControl({
            fitBoundsOptions: { maxZoom: 19 },
            positionOptions: { enableHighAccuracy: true },
            showAccuracyCircle: true,
            showUserLocation: true,
            showUserHeading: true,
            trackUserLocation: true,
        })
    );

    const [scaleBar] = useState(
        new mapboxgl.ScaleControl({
            unit: 'imperial',
            maxWidth: 100,
        })
    );

    useEffect(() => {
        if (!map) return;

        if (locked) {
            if (showNavigationControls) {
                map.removeControl(navigation);
                map.removeControl(scaleBar);
            }
        } else {
            if (showNavigationControls) {
                map.addControl(scaleBar, 'bottom-right');
                map.addControl(navigation, 'bottom-right');
            }
        }
    }, [map, locked]);

    useEffect(() => {
        if (!map || !userLocation) return;
        map.addControl(userLocationControl, 'bottom-right');
    }, [map, userLocation])
}