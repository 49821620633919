import { forwardRef } from 'react';
import ReportPages from '../report_pages/ReportPages';
import ReportHeader from './ReportHeader';

const PrintableReport = forwardRef(({ report, pages }, ref) => {
  return (
    <div
      ref={ref}
      id="report-parent"
      className="h-full overflow-hidden overflow-y-scroll pb-20 sm:pb-0"
      style={{ margin: '0' }}
    >
      {/*  Site header */}
      <ReportHeader report={report} />

      <div className="print:mb-0 w-full max-w-3xl mx-auto text-black">
        <ReportPages report={report} pages={pages} />
      </div>
    </div>
  );
});

export default PrintableReport;
