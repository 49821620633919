import { useState, useEffect, useCallback } from 'react';
export default function useSelectControl({ enabled = true } = {}) {
  const [lastSelectedIndex, setLastSelectedIndex] = useState(-1);
  const [lastHighlighedIndex, setLastHighlightedIndex] = useState(-1);
  const [highlightIndexes, setHighlightIndexes] = useState([]);
  const [shiftDown, setShiftDown] = useState(false);
  const [selectedIndexes, setSelectedIndexes] = useState([]);

  const clearSelections = () => {
    setSelectedIndexes([]);
    setLastSelectedIndex(-1);
    setLastHighlightedIndex(-1);
  };

  const selectControl = (
    event,
    {
      targetIndex,
      forceSelect = false,
      forceSelectOnShift = false,
      forceSelectOnCtrl = false,
      onBypass,
    } = {}
  ) => {
    event.preventDefault();
    event.stopPropagation();

    const selecting = !!selectedIndexes.length;
    const selected = selectedIndexes.includes(targetIndex);
    const finalForceSelect =
      (event.shiftKey && forceSelectOnShift) ||
      (event.ctrlKey && forceSelectOnCtrl) ||
      forceSelect;

    if (!selecting && !finalForceSelect) return onBypass?.(targetIndex);

    setLastSelectedIndex(selected ? -1 : targetIndex); //Set anchor if selecting

    if (event.shiftKey && lastSelectedIndex > -1) {
      //If shifting and anchor

      const max = Math.max(targetIndex, lastSelectedIndex);
      const min = Math.min(targetIndex, lastSelectedIndex);
      const total = max - min;

      const highlightedRange = Array.from(
        { length: max - min + 1 },
        (_, i) => min + i
      );
      const totalToSelect = highlightedRange.filter(
        (i) => !selectedIndexes.includes(i)
      ).length;
      const shouldSelectRange =
        totalToSelect > 0 ? totalToSelect > totalToSelect - total : false;

      if (!shouldSelectRange)
        return setSelectedIndexes(
          selectedIndexes.filter(
            (assetIndex) => !(assetIndex >= min && assetIndex <= max)
          )
        );

      setSelectedIndexes([
        ...new Set([...selectedIndexes, ...highlightedRange]),
      ]);

      return;
    }

    if (selecting || finalForceSelect)
      setSelectedIndexes(
        selected
          ? selectedIndexes.filter((i) => i !== targetIndex)
          : [...selectedIndexes, targetIndex]
      );
  };

  const mouseEnterAndLeaveControl = useCallback(
    (event, targetIndex = null) => {
      if (selectedIndexes.length < 1) return;
      setLastHighlightedIndex(event?.type === 'mouseenter' ? targetIndex : -1);
    },
    [selectedIndexes]
  );

  const toggleIndex = useCallback(
    (index) => {
      const selected = selectedIndexes.includes(index);
      setSelectedIndexes((s) =>
        selected ? s.filter((i) => i !== index) : [...s, index]
      );
      setLastSelectedIndex(selected ? -1 : index);
    },
    [selectedIndexes]
  );

  useEffect(() => {
    if (lastHighlighedIndex < 0 || lastSelectedIndex < 0 || !shiftDown) return;

    const min = Math.min(lastHighlighedIndex, lastSelectedIndex);
    const max = Math.max(lastHighlighedIndex, lastSelectedIndex);

    setHighlightIndexes(
      Array.from({ length: max - min + 1 }, (_, i) => min + i)
    );
  }, [lastHighlighedIndex, shiftDown]);

  useEffect(() => {
    if (shiftDown) return;
    setHighlightIndexes([]);
  }, [shiftDown]);

  useEffect(() => {
    const trackShift = ({ keyCode, type }) =>
      setShiftDown(keyCode === 16 && type === 'keydown');

    const removeEventListeners = () => {
      document.removeEventListener('keyup', trackShift);
      document.removeEventListener('keydown', trackShift);
    };

    if (enabled) {
      document.addEventListener('keydown', trackShift);
      document.addEventListener('keyup', trackShift);
    } else removeEventListeners();

    return removeEventListeners;
  }, [enabled]);

  return {
    clearSelections,
    highlightIndexes,
    mouseEnterAndLeaveControl,
    selectedIndexes,
    setSelectedIndexes,
    selectControl,
    toggleIndex,
  };
}
