import React from 'react';
import SettingsContent from '../partials/settings/SettingsContent';

function Settings() {
  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl self-start">
      {/* Page header */}
      <div className="mb-8">
        {/* Title */}
        <h1 className="text-2xl md:text-3xl text-gray-800 font-poppins uppercase font-semibold">
          Account Settings
        </h1>
      </div>

      {/* Content */}
      {/* <SettingsSidebar /> */}
      <SettingsContent />
    </div>
  );
}

export default Settings;
