import client from './client';
const endpoint = '/report_types';

const getWorkspaceReportTypes = (workspaceId, params = {}) =>
  client.get(`/workspaces/${workspaceId}${endpoint}`, params);
const getReportTypes = (params = {}) =>
  client.get(endpoint, params);
const getReportType = (reportTypeId) =>
  client.get(`${endpoint}/${reportTypeId}`);
const updateReportType = (reportTypeId, report_type) =>
  client.put(`${endpoint}/${reportTypeId}`, { report_type });
const archiveReportType = (reportTypeId) =>
  client.put(`${endpoint}/${reportTypeId}/archive`);
const duplicateReportTypeToWorkspace = (reportTypeId, workspace_id) =>
  client.put(`${endpoint}/${reportTypeId}/duplicate`, { workspace_id });
const unarchiveReportType = (reportTypeId) =>
  client.put(`${endpoint}/${reportTypeId}/unarchive`);


export default {
  getReportType,
  getReportTypes,
  getWorkspaceReportTypes,
  updateReportType,
  archiveReportType,
  unarchiveReportType,
  duplicateReportTypeToWorkspace,
};
