import { FaFilePdf } from 'react-icons/fa';
import Loader from '../utility/Loader.js';
import { Worker } from '@react-pdf-viewer/core';

const Body = ({ children }) => <div className="flex justify-center my-5">{children}</div>
const Container = ({ children }) => <div className="w-full h-full items-center">
  {children}
</div>


const Title = ({ children }) => <div className="my-5 font-bold text-gray-900 text-2xl w-full text-center">
  {children}
</div>

const ACCEPTED_FILE_COPY = 'PDF/KML/KMZ/JPG/PNG/GEOTIFF accepted';

const EmptyUploadBody = () => <>
  <FaFilePdf
    size={60}
    className={`mb-1 mx-auto text-tertiary-light`}
  />
  <div
    className={`font-semibold mt-2 text-tertiary`}
  >
    Drag and drop or click <br />
    to upload file.
  </div>
  <div className="text-xs text-gray-600 mt-4">
    {ACCEPTED_FILE_COPY}
  </div>
</>


const Icon = ({ extension }) => <img
  src={`/icons/${extension}_icon.png`}
  className="h-40 my-5 mx-auto"
/>

const FileNameContainer = ({ children }) => <div className="w-full bg-gray-50 border-b border-gray-200 overflow-hidden flex items-center mt-4">
  {children}
</div>

const LoadingContainer = ({ children }) => <div className="absolute inset-1 justify-center items-center flex bg-white bg-opacity-30">
  {children}
</div>

const Loading = () => <LoadingContainer>
  <Loader color={'text-gray-800'} margin={''} />
</LoadingContainer>

const FileNameInput = ({ value, onChange }) => <input
  type="text"
  value={value}
  onChange={onChange}
  placeholder="Site_Drainage_Plan.pdf"
  className="text-center rounded-sm shadow-sm px-2 bg-transparent placeholder-gray-400 relative text-sm border-0 w-full border-0 outline-none focus:outline-none"
/>

const PDFWorker = () => <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.min.js" />

const MapLayerFileUpload = {
  Body,
  Container,
  Title,
  EmptyUploadBody,
  Icon,
  FileNameContainer,
  FileNameInput,
  Loading,
  PDFWorker
}

export default MapLayerFileUpload
