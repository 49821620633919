import { useState } from 'react';
import {
  FiArrowRight,
  FiChevronDown,
  FiChevronRight,
  FiFolder,
} from 'react-icons/fi';

const MoveDocumentTreeItem = ({
  item,
  level,
  onSelectFolder,
  selected,
  children,
}) => {
  const [isClosed, setIsClosed] = useState(true);

  return (
    <div key={`section-${item.id}`}>
      <div
        className={`flex items-center p-1 my-2 rounded font-semibold text-lg hover:bg-gray-100 cursor-pointer ${
          selected && 'bg-gray-200'
        }`}
        style={{ marginLeft: level > 0 ? 20 * level : 0 }}
        onClick={() => onSelectFolder(item.id)}
      >
        <span
          className="cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            setIsClosed(!isClosed);
          }}
        >
          {!item.hasChildren ? (
            <FiArrowRight />
          ) : isClosed ? (
            <FiChevronRight />
          ) : (
            <FiChevronDown />
          )}
        </span>
        <span className="mr-2 ml-2">{<FiFolder size={20} />}</span>
        <span>{item.name}</span>
      </div>
      {!isClosed && <div>{children}</div>}
    </div>
  );
};

export default MoveDocumentTreeItem;
