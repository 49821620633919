import { forwardRef } from 'react';
import ReportPageEntryGroups from '../report_page_entry_groups/ReportPageEntryGroups';
import ReportHeader from './ReportHeader';

const PrintableReportPage = forwardRef(({ report, reportPage }, ref) => {
  return (
    <div
      ref={ref}
      id="report-parent"
      className="h-full overflow-hidden overflow-y-scroll p-0 m-0"
    >
      {/*  Site header */}
      <ReportHeader printable report={report} />
      <div className="px-6 py-5 print:mb-0 w-full max-w-4xl mx-auto text-black bg-white">
        <ReportPageEntryGroups report={report} reportPage={reportPage} />
      </div>
    </div >
  );
});

export default PrintableReportPage;
