import { useEffect, useState, useRef } from 'react';
import Loader from '../utility/Loader';
import Modal, { ModalHeader, ModalSuccessView, ModalBody, ModalFooter } from '../utility/Modal';
import ErrorView from '../utility/ErrorView';
import PreviewFeatureAnnouncementModal from './PreviewFeatureAnnouncementModal';
import useFeatureAnnouncementCreationServices from './hooks/useFeatureAnnouncementCreationServices';
import useFeatureAnnouncementForm from './hooks/useFeatureAnnouncementForm';

export default function EditFeatureAnnouncementModal({
    featureAnnouncement: passedFeatureAnnouncement,
    open,
    afterSave,
    onClose,
}) {
    const [featureAnnouncement, setFeatureAnnouncement] = useState(passedFeatureAnnouncement);

    const [previewing, setPreviewing] = useState(false);
    const [saved, setSaved] = useState(false);
    const savedTimer = useRef(null);

    const {
        updateError,
        updating,
        updatedFeatureAnnouncement,
        updateFeatureAnnouncement: update
    } = useFeatureAnnouncementCreationServices();

    const saveFeatureAnnoucement = () => {
        update(featureAnnouncement.id, featureAnnouncement);
    };

    const updateFeatureAnnouncement = (key, value) => {
        setFeatureAnnouncement({ ...featureAnnouncement, [key]: value });
    };

    const form = useFeatureAnnouncementForm(featureAnnouncement, {
        onUpdate: updateFeatureAnnouncement,
    })

    const announcementsFilled = () => featureAnnouncement && ['title', 'subtitle', 'description', 'expiration_date', 'loom_link', 'faq_link', 'feature_flag'].map((key) => featureAnnouncement[key]).every(Boolean);

    useEffect(() => {
        if (!passedFeatureAnnouncement) return;
        setFeatureAnnouncement(passedFeatureAnnouncement);
    }, [passedFeatureAnnouncement])

    useEffect(() => {
        if (!updatedFeatureAnnouncement) return;
        setSaved(true);
    }, [updatedFeatureAnnouncement]);

    useEffect(() => {
        if (saved) {
            setFeatureAnnouncement({});
            savedTimer.current = setTimeout(() => {
                onClose?.();
                afterSave?.(updatedFeatureAnnouncement);
                setTimeout(() => {
                    setSaved(false);
                }, 500);
            }, 2500);
        }
    }, [saved]);

    return <>
        <Modal
            isOpen={open}
            dialogScrolls={true}
            modalScrolls={false}
            maxHeight={'max-h-auto'}
            dialogClass={'py-10'}
            modalClass={'overflow-hidden rounded-lg'}
            aligned={'items-start'}
            onClose={onClose}
        >
            <ModalHeader
                title={'Update Feature Announcement'}
                onClose={onClose}
            />
            {saved ? (
                <ModalSuccessView text={'New feature announcement successfully updated'} />
            ) : (<>
                <ModalBody>
                    {updateError && (
                        <ErrorView error={updateError} />
                    )}
                    {form}
                </ModalBody>


                <ModalFooter>
                    <button
                        className="modal-success-btn"
                        type="button"
                        disabled={updating || !announcementsFilled(featureAnnouncement)}
                        onClick={() => setPreviewing(true)}
                    >
                        Preview
                    </button>
                    <button
                        className="modal-save-btn mr-2"
                        type="button"
                        data-testid="editFeatureAnnouncementButton"
                        disabled={updating}
                        onClick={saveFeatureAnnoucement}
                    >
                        Update {updating && <Loader />}
                    </button>
                    <button
                        className="modal-close-btn mr-2"
                        type="button"
                        disabled={updating}
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                </ModalFooter></>
            )
            }
        </Modal >

        <PreviewFeatureAnnouncementModal
            featureAnnouncement={featureAnnouncement}
            open={previewing}
            onClose={() => setPreviewing(false)}
        />
    </>
        ;
}
