// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import { useEffect, useRef } from 'react';
import { mapboxStyleUrls } from '../../../data/models';
export default function BaseMap({ bounds }: {
    bounds: number[][] | null
}) {
    const mapContainer = useRef<HTMLDivElement>(null);
    const map = useRef(null);

    useEffect(() => {
        if (!mapContainer.current) return;
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: mapboxStyleUrls['satellite-streets'],
            bounds,
            preserveDrawingBuffer: true,
        });
    }, [mapContainer.current, bounds]);

    return <div
        ref={mapContainer}
        id="mapContainer"
        data-testid={'baseMap'}
        className="w-full h-72"
    />
}