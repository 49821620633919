const MapFileToAsset = (file: any) => {
    return {
        name: file.name,
        files: {
            large: URL.createObjectURL(file),
            medium: URL.createObjectURL(file),
            original: URL.createObjectURL(file),
            thumbnail: URL.createObjectURL(file),
        }
    };
}

export default MapFileToAsset;