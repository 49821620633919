import { useState } from 'react';
export default function useUserLocation() {
  const [locating, setLocating] = useState(false);
  const [location, setLocation] = useState(null);
  const [error, setError] = useState(null);

  const getUserLocation = () => {
    if (navigator.geolocation) {
      setLocating(true);
      setError(null);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            accuracy: position.coords.accuracy,
            heading: position.coords.heading,
          });
          setLocating(false);
        },
        (error) => {
          setError(error.message);
          setLocating(false);
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
        }
      );
    } else {
      setError('Geolocation is not supported by this browser.');
    }
  };

  return { error, locating, location, getUserLocation };
}
