import Report from '../../pages/Report';
import { ReportContextProvider } from './context';

export default function ReportContainer({ mobile = false }) {
  return (
    <ReportContextProvider>
      <Report mobile={mobile} />
    </ReportContextProvider>
  );
}
