import useApi from '../../../hooks/useApi';
import notesApi from '../../../api/notes';
import useSaved from '../../../hooks/useSaved';
export default function useNoteCreate() {
    const {
        data: note,
        error,
        loading: creating,
        request: createNote,
    } = useApi(notesApi.addNote, null);

    const { saved: created } = useSaved(note?.updated_at);

    return {
        note,
        created,
        error,
        creating,
        createNote,
    }
}