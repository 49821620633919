import Loader from '../partials/utility/Loader';

const ReportPageCountDisplay = ({ shownCreatedPagesCount, totalPageCount, loaderColor = "black", loaderSize = "3" }) => (
  <div className="flex justify-center items-center">
    <p className="text-xs flex text-center">
      {shownCreatedPagesCount} / <span className="font-medium ml-1">{totalPageCount} Pages created</span>
    </p>
    <Loader color={loaderColor} size={loaderSize} />
  </div>
);

export default ReportPageCountDisplay;
