import React, { useState, useEffect, useRef } from 'react';
import SignaturePad from 'signature_pad';
import { uploadSource } from '../../hooks/useS3';
import useApi from '../../hooks/useApi';
import entriesApi from '../../api/entries';
import Loader from '../utility/Loader';
import { BiCheckCircle } from 'react-icons/bi';
import { useParams } from 'react-router-dom';
export default function EntrySignature({ editing, entry: passedEntry }) {
  const {
    data: retrievedEntry,
    error: retrvalError,
    loading,
    request: getEntry,
  } = useApi(entriesApi.getEntry, passedEntry, true);
  const {
    data: savedEntry,
    error,
    loading: saving,
    request: saveEntry,
  } = useApi(entriesApi.updateEntry, null);
  const { share_id } = useParams();
  const [entry, setEntry] = useState(passedEntry);
  const [data, setData] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [hasSaved, setHasSaved] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [saved, setSaved] = useState(false);
  const savedTimer = useRef(null);
  const [isEmpty, setIsEmpty] = useState(true);
  const signatureCanvas = useRef();
  const signaturePad = useRef();

  const toggleUpdating = () => {
    updating && updateSignatureData(entry);
    updating && setHasSaved(false);
    setUpdating(!updating);
  };

  const emptySignature = () => {
    signaturePad.current.clear();
    setIsEmpty(true);
  };

  const saveSignature = async () => {
    setUploading(true);
    let file = await uploadSource(
      signaturePad.current.toDataURL(),
      `entry_signature_${entry.objectId}`,
      'image/png'
    );
    saveEntry(entry.objectId, { asset_attributes: { file } });
  };

  const updateSignatureData = (e) => {
    e &&
      e.asset &&
      signaturePad.current &&
      signaturePad.current.fromDataURL(e.asset.files.original);
    setIsEmpty(signaturePad.current.isEmpty());
  };

  useEffect(() => {
    const resizeCanvas = () => {
      if (!signaturePad.current) return;
      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      signatureCanvas.current.width =
        signatureCanvas.current.offsetWidth * ratio;
      signatureCanvas.current.height =
        signatureCanvas.current.offsetHeight * ratio;
      signatureCanvas.current.getContext('2d').scale(ratio, ratio);
      signaturePad.current.clear(); // otherwise isEmpty() might return incorrect value
    };

    const beginStroke = () => {
      setIsEmpty(signaturePad.current.isEmpty());
    };

    const endStroke = () => {
      setIsEmpty(signaturePad.current.isEmpty());
    };

    if (signatureCanvas.current) {
      signaturePad.current = new SignaturePad(signatureCanvas.current);
      signaturePad.current.addEventListener('beginStroke', beginStroke);
      signaturePad.current.addEventListener('endStroke', endStroke);
      resizeCanvas();
    }

    window.addEventListener('resize', resizeCanvas);

    return () => window.removeEventListener('resize', resizeCanvas);
  }, [signatureCanvas]);

  useEffect(() => {
    getEntry(passedEntry.objectId, { share_id });
  }, []);

  useEffect(() => {
    if (retrievedEntry) {
      setEntry({ ...retrievedEntry });
      updateSignatureData(retrievedEntry);
    }
  }, [retrievedEntry]);

  useEffect(() => {
    if (savedEntry) {
      setEntry({ ...savedEntry });
    }
  }, [savedEntry]);

  useEffect(() => {
    if (savedEntry) {
      setSaved(true);
      setUploading(false);
    }
  }, [savedEntry]);

  useEffect(() => {
    if (saved) {
      setHasSaved(true);
      savedTimer.current = setTimeout(() => {
        setSaved(false);
      }, 2000);
    }
  }, [saved]);

  return (
    <>
      <canvas
        ref={signatureCanvas}
        className={`w-full ${
          (!updating || !editing || uploading) && 'pointer-events-none'
        } absolute h-28`}
      />
      {editing ? (
        <>
          {!updating && (
            <div
              className="print:hidden w-full absolute h-28 bg-blue-50 bg-opacity-70 border border-dashed flex items-center justify-center"
              onClick={toggleUpdating}
            >
              <div
                className={`cursor-pointer bg-secondary hover:opacity-90 py-1 px-5 text-white font-semibold items-center flex rounded-full`}
                onClick={toggleUpdating}
              >
                {entry.asset ? 'Update Signature' : 'Add Signature'}
              </div>
            </div>
          )}
          {updating && (
            <div
              disabled={loading || uploading || saving || isEmpty}
              className={`absolute right-0 cursor-pointer ${
                (loading || uploading || saving || isEmpty) && 'opacity-50'
              } bg-blue-50 hover:opacity-90 py-1 px-5 text-secondary font-semibold items-center flex rounded-full mr-2 border`}
              onClick={emptySignature}
            >
              Clear
            </div>
          )}
          <div className="px-4 py-2 border-b-2 border-gray-800 h-28 flex justify-content-end">
            <p class="text-gray-700 text-sm flex items-end">X</p>
          </div>
          <div className="p-2 font-bold">{entry.name}</div>
          {updating && (
            <div className="w-full flex justify-end text-base print:hidden">
              <div
                disabled={loading || uploading || saving}
                className={`cursor-pointer ${
                  (loading || uploading || saving) && 'opacity-50'
                } ${
                  hasSaved
                    ? 'bg-green-500 text-white'
                    : 'bg-blue-50 text-secondary'
                } hover:opacity-90 py-1 px-5 font-semibold items-center flex rounded-full mr-2 border`}
                onClick={toggleUpdating}
              >
                {hasSaved ? 'Done' : 'Cancel'}
              </div>
              <div
                disabled={loading || uploading || saving || isEmpty}
                className={`cursor-pointer ${
                  (loading || uploading || saving || isEmpty) && 'opacity-50'
                } bg-secondary hover:opacity-90 py-1 px-5 text-white font-semibold items-center flex rounded-full`}
                onClick={saveSignature}
              >
                Save
                {(loading || uploading || saving) && (
                  <div className="flex items-center">
                    <Loader color="text-gray-700" />
                  </div>
                )}
                {saved && (
                  <div className="flex items-center">
                    <BiCheckCircle
                      size={20}
                      className="ml-1 text-white rounded-full overflow-hidden"
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <div className="px-4 py-2 border-b-2 border-gray-800 h-28 flex justify-content-end">
            <p class="text-gray-700 text-sm flex items-end">X</p>
          </div>
          <div className="p-2 font-bold">{entry.name}</div>
        </>
      )}
    </>
  );
}
