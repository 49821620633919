import { useState, useEffect } from 'react';
import WorkspacesTableItem from './WorkspacesTableItem';
import { Workspace } from '../../data/models';
import { FiArrowDown, FiArrowUp } from 'react-icons/fi';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';

function WorkspacesTable({
  afterUpdate,
  selectedItems,
  order,
  onOrderUpdate,
  workspaces,
  totalCount,
}: {
  afterUpdate: any;
  selectedItems: any;
  workspaces: any;
  totalCount: number;
  onOrderUpdate: (newOrder: string) => void;
  order: string;
}) {
  const [selectAll, setSelectAll] = useState(false);
  const [isCheck, setIsCheck] = useState<number[]>([]);
  const [list, setList] = useState([]);

  useEffect(() => {
    setList(workspaces);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setIsCheck(list.map((li: { id: number }) => li.id));
    if (selectAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e: any) => {
    const { id, checked } = e.target;
    setSelectAll(false);
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  useEffect(() => {
    selectedItems(isCheck);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheck]);

  return (
    <div className="bg-white shadow-lg rounded-sm border border-gray-200 relative">
      <header className="px-5 py-4">
        <h2 className="font-semibold text-gray-800">
          All Workspaces{' '}
          <span className="text-gray-400 font-medium">{totalCount}</span>
        </h2>
      </header>
      <div>
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-gray-500 bg-gray-50 border-t border-b border-gray-200">
              <tr>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px hidden">
                  <div className="flex items-center">
                    <label className="inline-flex">
                      <span className="sr-only">Select all</span>
                      <input
                        className="form-checkbox"
                        type="checkbox"
                        checked={selectAll}
                        onChange={handleSelectAll}
                      />
                    </label>
                  </div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px hidden">
                  <div className="font-semibold text-left">Paid</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Workspace</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Owner</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left flex items-center">
                    Joined
                    <SortButton
                      onSort={onOrderUpdate}
                      order={order}
                      orderKey="created_at"
                    />
                  </div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Address</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold flex items-center">
                    Projects
                    <SortButton
                      onSort={onOrderUpdate}
                      order={order}
                      orderKey="projects_count"
                    />
                  </div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left flex items-center">
                    Members
                    <SortButton
                      onSort={onOrderUpdate}
                      order={order}
                      orderKey="workspace_members_count"
                    />
                  </div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold flex items-center">
                    Pins
                    <SortButton
                      onSort={onOrderUpdate}
                      order={order}
                      orderKey="pins_count"
                    />
                  </div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left flex items-center">
                    Reports
                    <SortButton
                      onSort={onOrderUpdate}
                      order={order}
                      orderKey="reports_count"
                    />
                  </div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Features</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <span className="sr-only">Menu</span>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-gray-200">
              {workspaces.map((workspace: Workspace) => {
                return (
                  <WorkspacesTableItem
                    key={`${workspace.objectId}${order}`}
                    id={workspace.objectId}
                    activeSubscription={workspace.active_subscription}
                    workspace={workspace}
                    image={workspace.icon_url}
                    name={workspace.name}
                    createdAt={workspace.created_at}
                    email={workspace.owner?.name}
                    address={workspace.address}
                    phone={workspace.phone}
                    projectsCount={workspace.projects_count || 0}
                    workspaceMembersCount={workspace.workspace_members_count || 0}
                    reportsCount={workspace.reports_count || 0}
                    pinsCount={workspace.pins_count || 0}
                    features={workspace.feature_flags}
                    fav={false}
                    handleClick={handleClick}
                    isChecked={isCheck.includes(workspace.id)}
                    afterUpdate={afterUpdate}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

const SortButton = ({
  onSort,
  defaultOrder = 'desc',
  order,
  orderKey
}: {
  onSort: (newOrder: string) => void,
  defaultOrder?: 'asc' | 'desc',
  order: string,
  orderKey: string
}) => {
  const orderKeyInOrder = order.includes(orderKey);
  const desc = order.includes('desc');
  const onClick = () => {
    if (!orderKeyInOrder) return onSort(`${orderKey} ${defaultOrder}`);
    else onSort(desc ? `${orderKey} asc` : `${orderKey} desc`);
  }

  return <div className="text-xs cursor-pointer" onClick={onClick}>
    <AiFillCaretUp className={`ml-1 ${(orderKeyInOrder && !desc) ? 'text-secondary' : 'text-gray-300'}`} />
    <AiFillCaretDown className={`ml-1 ${(orderKeyInOrder && desc) ? 'text-secondary' : 'text-gray-300'}`} />
  </div>
}

export default WorkspacesTable;
