import client from './client';

const getProjectContacts = (projectId, params) =>
  client.get(`/projects/${projectId}/project_contacts`, { params });

const addContact = (projectId, project_contact) =>
  client.post(`/projects/${projectId}/project_contacts`, { project_contact });

const updateContact = (projectId, contactId, contactData) =>
  client.put(`/projects/${projectId}/project_contacts/${contactId}`, { project_contact: contactData });

const removeContact = (projectId, contactId) =>
  client.delete(`/projects/${projectId}/project_contacts/${contactId}`);

export default {
  getProjectContacts,
  addContact,
  updateContact,
  removeContact
};
