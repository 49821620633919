import React from 'react';
import { Link } from 'react-router-dom';
import OnboardingImage from '../images/bgs/login-bg.png';

function Join() {
  return (
    <main className="bg-white">
      <div className="relative flex">
        {/* Content */}
        <div className="w-full md:w-1/2">
          <div className="min-h-screen h-full flex flex-col after:flex-1">
            {/* Header */}
            <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
              {/* Logo */}
              <Link className="block" to="/">
                <svg
                  width="54"
                  height="33"
                  viewBox="0 0 54 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.2324 10.4287L26.7621 20.3574L43.3054 10.4287L36.8946 6.58489L30.0762 10.6732V2.48302L26.7621 0.5L23.4616 2.48302V10.6732L16.6433 6.57131L10.2324 10.4287Z"
                    fill="#F49F15"
                  />
                  <path
                    d="M53.4244 16.5L47.0135 12.6426L26.7622 24.7988L26.6264 24.7037L6.52454 12.6562L0.100098 16.5L26.7622 32.5L53.4244 16.5Z"
                    fill="#16697A"
                  />
                </svg>
              </Link>
              <div className="text-sm">
                Have an account?{' '}
                <Link
                  className="font-medium text-tertiary hover:text-secondary"
                  to="/signin"
                >
                  Sign In
                </Link>
              </div>
            </div>

            <div className="px-4 py-8 flex flex-grow items-center">
              <div className="max-w-md mx-auto -mt-16">
                <div className="text-center">
                  <svg
                    className="inline-flex w-16 h-16 fill-current mb-6"
                    viewBox="0 0 64 64"
                  >
                    <circle className="text-green-100" cx="32" cy="32" r="32" />
                    <path
                      className="text-green-500"
                      d="m28.5 41-8-8 3-3 5 5 12-12 3 3z"
                    />
                  </svg>
                  <h1 className="text-3xl text-gray-800 font-bold mb-8">
                    You've been invited to Join
                  </h1>
                  <Link className="btn rounded bg-tertiary text-white" to="/">
                    Go To Dashboard -&gt;
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Image */}
        <div
          className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
          aria-hidden="true"
        >
          <img
            className="object-cover object-center w-full h-full"
            src={OnboardingImage}
            width="760"
            height="1024"
            alt="Onboarding"
          />
        </div>
      </div>
    </main>
  );
}

export default Join;
