import projectsShow from '../data/project';
import projects from '../data/projects';

const loadProjects = async ({ page = 1, q }) => {
  let filteredProjects = projects.slice(0, page * 10);
  if (q)
    filteredProjects = filteredProjects.filter(
      ({ name }) => ~name.toLowerCase().indexOf(q.toLowerCase())
    );
  return { ok: true, data: filteredProjects };
};

const loadProject = async (objectId) => {
  let project = projectsShow.find((p) => p.objectId === objectId);
  return { ok: true, data: project };
};

export { loadProject, loadProjects };
