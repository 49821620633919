import colors, { getTextColorFromBackground } from '../../../styles/colors';

export default function FieldStatuses({
  options,
  chosen,
  onChosen,
  onRemoved,
}) {
  return (
    <div className="flex flex-wrap">
      {options.map((option) => {
        const isSelected = chosen && chosen.id === option.id;
        const backgroundColor = isSelected
          ? (chosen && chosen.color) || colors.secondary
          : colors.lightBlue;
        const color =
          isSelected && chosen && chosen.color
            ? getTextColorFromBackground(chosen.color, 200)
            : colors.secondary;
        return (
          <button
            id={option?.id}
            key={`fieldStatus-${option?.name
              .toLowerCase()
              .replace(' ', '-')}`}
            data-testid={`fieldStatus-${option?.name
              .toLowerCase()
              .replace(' ', '-')}`}
            onClick={(e) => {
              if (isSelected && onRemoved) onRemoved(option);
              else onChosen?.(option);
            }}
            style={{
              backgroundColor,
              color,
            }}
            className={`hover:text-white h-16 px-6 text-secondary rounded-md font-bold mr-3 mb-3`}
          >
            {option.name}
          </button>
        );
      })}
    </div>
  );
}
