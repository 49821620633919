import React, { useState, useEffect } from 'react';
import { FiDownload, FiFile } from 'react-icons/fi';
import documentsApi from '../../api/documents';
import useApi from '../../hooks/useApi';
import Loader from '../utility/Loader';

export default function SharedDocument({ share }) {
  const {
    data: downloadData,
    loading: downloadLoading,
    error: downloadError,
    request: downloadRequest,
  } = useApi(documentsApi.getDocumentFile, null);

  const [error, setError] = useState(null);

  const fileType = share.document.file_details.type;
  const src = share.document.file_details.uri;
  const fileName = share.document.name;
  const projectObjectId = share.document.folder.project.objectId;
  const folderObjectId = share.document.folder.objectId;
  const documentObjectId = share.document.objectId;
  const isVideo = fileType.includes('video');
  const isPdf = fileType.includes('pdf');

  useEffect(() => {
    if (!downloadData) return;
    const url = URL.createObjectURL(downloadData);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }, [downloadData]);

  const renderElementByShareType = () => {
    if (isVideo && !error) {
      return (
        <video controls className="w-full">
          <source src={src} onError={(e) => setError(true)} />
        </video>
      );
    } else if (isPdf && !error) {
      return (
        <iframe className="w-full h-full" title={fileName} src={src}></iframe>
      );
    } else if (!error) {
      return <img className="p-4" src={src} onError={(e) => setError(true)} />;
    } else {
      return (
        <div className="flex flex-col items-center justify-center bg-gray-200 rounded p-4">
          <FiFile size={80} />
          <div className="mt-4 text-center font-semibold">
            Unable to display a preview for this file type: {fileType}
          </div>
        </div>
      );
    }
  };

  const downloadImage = async () => {
    await downloadRequest(projectObjectId, folderObjectId, documentObjectId);
  };

  return (
    <div className="flex items-center justify-center overflow-auto flex-col h-full">
      <div
        container
        className={`border-2 border-sm-lightblue shadow-lg rounded ${
          isPdf && 'w-[90%] h-[90%] flex flex-col'
        }`}
      >
        <div header className="card-header font-bold">
          {fileName}

          <button
            onClick={(e) => downloadImage()}
            className="modal-save-btn color-white"
            disabled={downloadLoading}
          >
            <FiDownload size={14}></FiDownload>
          </button>
        </div>
        <div
          body
          className={isPdf && 'flex items-center justify-center h-full'}
        >
          {renderElementByShareType()}
        </div>
        <div footer className="border border-gray-200 px-4 py-2">
          {share.document.description}
        </div>
      </div>
    </div>
  );
}
