import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import useApi from '../../../hooks/useApi';
import pinTypesApi from '../../../api/pin_types';
import FieldStatuses from '../pin_form_fields/FieldStatuses';
import Title from '../modal/Title';
import FieldContainer from '../pin_form_fields/FieldContainer';
import PrivateSwitch from '../pin_form_fields/PrivateSwitch';

export default function usePinFields({ pin }) {

    const { data: pinTypes, request: loadPinTypes } = useApi(
        pinTypesApi.getProjectPinTypes,
        []
    );

    const [status, setStatus] = useState(pin.active_status);
    const [isPrivate, setIsPrivate] = useState(pin.private);

    const { project_id } = useParams();

    useEffect(() => {
        loadPinTypes(project_id);
    }, []);

    const pinPinType = pinTypes.find((pt) => pt.id === pin.pin_type_id);
    const privatePinComponent = <FieldContainer className="flex-column items-center mb-8">
        <Title>
            Private
        </Title>
        <PrivateSwitch
            key={`pinFieldPrivate${pin.objectId}`}
            onToggle={() => setIsPrivate(!isPrivate)} isPrivate={isPrivate}
        />
    </FieldContainer>
    const fieldComponents = pinPinType?.statuses?.length > 0 ? [
        <FieldContainer key={`pinFieldStatus${pin.objectId}`}>
            <Title>Status</Title>
            <FieldStatuses
                options={pinPinType.statuses}
                onChosen={setStatus}
                onRemoved={() => setStatus(null)}
                chosen={status}
            />
        </FieldContainer>,
        privatePinComponent] : [privatePinComponent];

    return {
        fieldComponents,
        status,
        isPrivate,
    }
}