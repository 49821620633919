import { useEffect, useState } from 'react';
import Modal, { ModalHeader } from '../utility/Modal';
import ViewMeetingDetailsModal from './ViewMeetingDetailsModal';
import moment from 'moment-timezone';
import meetingsApi from '../../api/meetings';
import useApi from '../../hooks/useApi';
import Loader from '../utility/Loader';
import DelayedSearchInput, { SearchContainer } from '../utility/DelayedSearchInput';
import useModal from '../../hooks/useModal';
import { useParams } from 'react-router-dom';

export default function MeetingsModal() {
  const { project_id } = useParams();
  const { open: modalOpen, toggle: toggleModal } = useModal();
  const [searchParams, setSearchParams] = useState({
    page: 1,
    items: 10,
    q: '',
  });
  const [meetings, setMeetings] = useState([]);
  const {
    data: { records, pagy },
    error,
    loading,
    request: getMeetings,
  } = useApi(meetingsApi.getMeetings, { records: [], pagy: {} }, true);

  const resetModal = () => {
    setMeetings([]);
    setSearchParams({ page: 1, items: 10, q: '' });
  };

  useEffect(() => {
    modalOpen && getMeetings(project_id, searchParams);
  }, [modalOpen, searchParams]);

  useEffect(() => {
    !modalOpen && resetModal();
  }, [modalOpen]);

  useEffect(() => {
    records.length && setMeetings([...meetings, ...records]);
  }, [records]);

  return (
    <div>
      {/* Button */}
      <button className="card-header-btn" type="button" onClick={toggleModal}>
        View All
      </button>
      <Modal isOpen={modalOpen} onClose={toggleModal}>
        <ModalHeader
          title={loading ? '...' : 'Site Meetings (' + pagy.count + ')'}
          onClose={toggleModal}
        />
        <SearchContainer>
          <DelayedSearchInput
            placeholder="Search Meetings..."
            onSearched={(text) => {
              setMeetings([]);
              setSearchParams({ q: text, page: 1, items: 10 });
            }}
          />
        </SearchContainer>
        <div className="max-h-72 overflow-y-scroll pt-2">
          {meetings.map((meeting) => {
            let metAt = moment(meeting.created_at);

            return (
              <div className="flex items-center justify-between p-3 border-b border-gray-200">
                <div className="flex items-center">
                  <div className="rounded-md border-2 border-sm-lightblue overflow-hidden mr-4 w-12 text-center">
                    <p className="uppercase text-xxs bg-sm-lightblue py-2 text-secondary font-semibold">
                      {metAt.format('MMM')}
                    </p>
                    <p className="text-xs py-2 font-semibold text-secondary">
                      {metAt.format('D')}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm truncate">{meeting.title}</p>
                    <p className="font-bold text-xs truncate mb-1">
                      {meeting.creator.name}
                    </p>
                    <p className="text-xxs truncate">
                      Attendees: {meeting.attendees}
                    </p>
                  </div>
                </div>
                <ViewMeetingDetailsModal meeting={meeting} />
              </div>
            );
          })}
          {!loading && searchParams.q === '' && pagy.count === 0 && (
            <div className="flex justify-center">
              <p className="empty-data-txt">
                No meetings have been recorded on project
              </p>
            </div>
          )}
          {!loading &&
            pagy.count !== undefined &&
            pagy.count !== meetings.length && (
              <div className="flex justify-center p-3 mt-3">
                <a
                  className="text-black hover:opacity-80 text-sm font-semibold bg-gray-100 px-4 py-2 shadow cursor-pointer"
                  onClick={() =>
                    setSearchParams({
                      ...searchParams,
                      page: searchParams.page + 1,
                    })
                  }
                >
                  Load More
                </a>
              </div>
            )}
          {loading && (
            <div className="flex justify-center py-10">
              <Loader color="black" />
            </div>
          )}
        </div>

        <div className="modal-footer">
          <button
            className="modal-close-btn"
            type="button"
            onClick={toggleModal}
          >
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
}
