import React, { useState, useEffect } from 'react';
import { FiPlus, FiX } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import useApi from '../../hooks/useApi';
import workspaceMembersApi from '../../api/workspace_members';
import AddMemberModal from '../team/AddMemberModal.js';
import DelayedSearchInput from '../utility/DelayedSearchInput';
import AddUserItem from './AddUserItem';

export default function ShareReport({
  report,
  onSharedWithUpdate,
  showResultsOnSearch = true,
}) {
  const {
    data: { records, pagy },
    error,
    loading,
    request: loadWorkspaceMembers,
  } = useApi(
    workspaceMembersApi.getProjectWorkspaceMembers,
    { records: [], pagy: {} },
    true
  );

  const { project_id } = useParams();

  const [searchParams, setSearchParams] = useState({
    page: 1,
    q: '',
    items: 10,
  });
  const [members, setMembers] = useState([]);
  const [sharedWith, setSharedWith] = useState([]);

  const membersNotCurrentlyShared = () =>
    members.filter(
      ({ member: { objectId } }) =>
        !sharedWith.map(({ objectId }) => objectId).includes(objectId)
    );
  const addToSharedWith = (member) => setSharedWith([...sharedWith, member]);
  const removeSharedWith = (index) =>
    setSharedWith([...sharedWith.filter((m, i) => i !== index)]);

  useEffect(() => {
    loadWorkspaceMembers(project_id, searchParams);
  }, [searchParams]);

  useEffect(() => {
    if (!records.length) return;
    setMembers([...members, ...records]);
  }, [records]);

  useEffect(() => {
    if (!onSharedWithUpdate) return;
    onSharedWithUpdate(sharedWith);
  }, [sharedWith]);

  return (
    <>
      <div className="flex px-2 py-1 flex-wrap">
        {sharedWith.map(({ objectId, email, name }, index) => (
          <div
            key={`share_report_modal_${report.objectId}_member_${objectId}`}
            className="bg-blue-50 text-xs font-bold py-1 px-2 text-secondary border border-blue-300 rounded-md flex items-center mr-1 mb-1 shadow-sm"
          >
            {email || name}{' '}
            <button
              className="focus:outline-none"
              onClick={() => removeSharedWith(index)}
            >
              <FiX
                size={16}
                className="ml-1 text-secondary hover:opacity-60 cursor-pointer"
              />
            </button>
          </div>
        ))}
      </div>
      <div
        className={`bg-white ${(!showResultsOnSearch ||
          (showResultsOnSearch && searchParams.q.length > 0)) &&
          ''
          } overflow-hidden p-2 flex`}
      >
        <DelayedSearchInput
          key={`searching_for_member_share_report_${report.objectId}`}
          placeholder="Share by name, email or position..."
          defaultValue={searchParams.q}
          onSearched={(text) => {
            setSearchParams({ ...searchParams, page: 1, q: text });
            setMembers([]);
          }}
        />
        <AddMemberModal
          key={`search_field_add_member_${project_id}`}
          user={{ name: searchParams.q }}
          button={
            <button
              className="px-4 flex items-center bg-gray-100 focus:outline-none"
              title="Add New Member"
            >
              <FiPlus size={16} className="hover:opacity-60 cursor-pointer" />
            </button>
          }
          afterCreate={addToSharedWith}
        />
      </div>
      {(!loading || members.length > 0) && (
        <>
          {members.length === 0 && searchParams.q.trim() === '' && (
            <>
              <p className="font-bold text-lg text-gray-600 py-10 pb-5 text-center">
                All workspace members have been added to the project
              </p>
              <div className="flex justify-center">
                <AddMemberModal
                  user={{ name: searchParams.q }}
                  key={`all_members_added_add_member_${project_id}`}
                  button={
                    <button className="focus:outline-none border border-secondary hover:opacity-80 text-sm font-semibold bg-blue-50 text-secondary px-3 py-1 shadow cursor-pointer rounded-full mb-5">
                      + Add new member
                    </button>
                  }
                  afterCreate={addToSharedWith}
                />
              </div>
            </>
          )}
          {members.length === 0 && searchParams.q.trim() !== '' && (
            <>
              <p className="font-bold text-lg text-gray-600 py-10 pb-5 text-center">
                No member matching{' '}
                <span className="italic mr-1 font-medium">
                  '{searchParams.q}'
                </span>{' '}
                found
              </p>
              <div className="flex justify-center">
                <AddMemberModal
                  user={{ name: searchParams.q }}
                  key={`empty_result_add_member_${project_id}`}
                  button={
                    <button className="focus:outline-none border border-secondary hover:opacity-80 text-sm font-semibold bg-blue-50 text-secondary px-3 py-1 shadow cursor-pointer rounded-full mb-5">
                      + Add new member
                    </button>
                  }
                  afterCreate={addToSharedWith}
                />
              </div>
            </>
          )}
          {(!showResultsOnSearch ||
            (showResultsOnSearch && searchParams.q.length > 0)) && (
              <div className="max-h-72 overflow-y-scroll">
                {membersNotCurrentlyShared().map((workspace_member) => {
                  return (
                    <AddUserItem
                      key={`report_${report.objectId}_workspace_member_${workspace_member.objectId}`}
                      workspace_member={workspace_member}
                      onAdd={addToSharedWith}
                    />
                  );
                })}
                {!loading &&
                  pagy.count !== undefined &&
                  pagy.count !== members.length && (
                    <div
                      className="flex justify-center p-3 border-b border-gray-200"
                      onClick={() =>
                        setSearchParams({
                          ...searchParams,
                          page: searchParams.page + 1,
                        })
                      }
                    >
                      <a className="text-black hover:opacity-80 text-sm font-semibold bg-gray-100 px-4 py-2 shadow cursor-pointer">
                        Load More
                      </a>
                    </div>
                  )}
              </div>
            )}
        </>
      )}
    </>
  );
}
