import { memo, useEffect } from 'react';
import reportsApi from '../../api/reports';
import useApi from '../../hooks/useApi';
import Loader from '../utility/Loader';
import PinViewModel from '../../models/PinViewModel';
import Tags from '../shared/Tags';
import tagsApi from '../../api/tags';

const PinModalDetails = ({ pin, onUpdate }) => {
  const {
    data: reports,
    error,
    loading,
    request: getReports,
  } = useApi(reportsApi.getPinReports);

  useEffect(() => {
    getReports(pin.objectId);
  }, [pin]);

  const onAddTag = async (tag) => {
    const {
      data: { records: tags_with_colors },
    } = await tagsApi.addTagsToPin(pin.objectId, tag.name);
    onUpdate(
      (pin) =>
        new PinViewModel({
          ...pin,
          tags_with_colors,
        })
    );
  };

  const onUpdateTag = async (tag) => {
    const {
      data: { records: tags_with_colors },
    } = await tagsApi.updateTagOnPin(pin.objectId, tag.id, tag);
    onUpdate(
      (pin) =>
        new PinViewModel({
          ...pin,
          tags_with_colors,
        })
    );
  };

  const onDestroyTag = async (tag) => {
    const {
      data: { records: tags_with_colors },
    } = await tagsApi.destroyTagOnPin(pin.objectId, tag.id);

    onUpdate(
      (pin) =>
        new PinViewModel({
          ...pin,
          tags_with_colors,
        })
    );
  };

  const onRemoveTag = async (tag) => {
    const {
      data: { records: tags_with_colors },
    } = await tagsApi.removeTagsFromPin(pin.objectId, tag.name);

    onUpdate(
      (pin) =>
        new PinViewModel({
          ...pin,
          tags_with_colors,
        })
    );
  };

  return (
    <>
      <div className="border-b pb-4 mb-4" data-testId="pinDetails">
        <div className="px-4">
          <p className="font-bold mb-3">Details</p>
          <div className="flex flex-wrap items-center justify-between text-xs">
            <div className="w-1/2 mb-3">
              <p className="font-semibold">ID</p>
              <p>{pin.identifier}</p>
            </div>
            <div className="w-1/2 mb-3">
              <p className="font-semibold uppercase">Status</p>
              <p className="capitalize">{pin.status?.replace(/_/g, ' ')}</p>
            </div>
            {pin.field_values
              .filter((field_value) => field_value.field?.name !== 'status')
              .map((field_value) => (
                <div
                  className="w-1/2 mb-3"
                  key={`field_value_${field_value.objectId}`}
                >
                  <p className="font-semibold uppercase">
                    {field_value.field?.name?.replace(/_/g, ' ')}
                  </p>
                  {field_value.field_option ? <div className="flex items-center">
                    {!!field_value.field_option.color && <div className="w-3 h-3 rounded-full mr-1" style={{ backgroundColor: field_value.field_option.color }} />}{field_value.field_option.name}
                  </div> :
                    <p className="capitalize">
                      {field_value.value
                        ? field_value.value.replace(/_/g, ' ')
                        : 'N/A'}
                    </p>}
                </div>
              ))}
          </div>
        </div>
      </div >
      <div className="pb-4 text-xs">
        <div className="px-4">
          <p className="font-bold uppercase">Tags</p>
          <Tags
            onAdd={onAddTag}
            onDestroy={onDestroyTag}
            onRemove={onRemoveTag}
            onUpdate={onUpdateTag}
            tagSize="md"
            on={'pin'}
            tags={pin.tags_with_colors}
            containerClassName={'mt-2'}
            tagClassName={'mr-1 mb-1'}
            canAdd={true}
          />
        </div>
      </div>
      <div className="pb-4 text-xs">
        <div className="px-4">
          <p className="font-bold uppercase">Reports</p>
          <p>
            {loading ? (
              <Loader />
            ) : (
              `Included in (${reports?.pagy?.count ?? 0}) reports`
            )}
          </p>
        </div>
      </div>
    </>
  );
};

export default memo(PinModalDetails);
