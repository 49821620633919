import client from './client';
const endpoint = '/workspace_members';

const getProjectWorkspaceMembers = (projectId, params = {}) =>
  client.get(`/projects/${projectId}${endpoint}`, params);
const getWorkspaceMembers = (workspaceId, params = {}) =>
  client.get(`/workspaces/${workspaceId}${endpoint}`, params);
const getWorkspaceMember = (id, params = {}) =>
  client.get(`${endpoint}/${id}`, params);
const addWorkspaceMember = (workspaceId, workspace_member) =>
  client.post(`/workspaces/${workspaceId}${endpoint}`, { workspace_member });
const addProjectWorkspaceMember = (projectId, workspace_member) =>
  client.post(`/projects/${projectId}${endpoint}`, { workspace_member });
const updateWorkspaceMember = (
  workspaceId,
  workspaceMemberId,
  workspace_member
) =>
  client.put(`/workspaces/${workspaceId}${endpoint}/${workspaceMemberId}`, {
    workspace_member,
  });
const acceptWorkspaceMembership = (workspaceMemberId) =>
  client.put(`${endpoint}/${workspaceMemberId}/accept`);
const declineWorkspaceMembership = (workspaceMemberId) =>
  client.put(`${endpoint}/${workspaceMemberId}/decline`);
const removeWorkspaceMember = (workspaceId, workspaceMemberId) =>
  client.delete(`/workspaces/${workspaceId}${endpoint}/${workspaceMemberId}`);

export default {
  getProjectWorkspaceMembers,
  getWorkspaceMembers,
  getWorkspaceMember,
  addWorkspaceMember,
  addProjectWorkspaceMember,
  updateWorkspaceMember,
  acceptWorkspaceMembership,
  declineWorkspaceMembership,
  removeWorkspaceMember,
};
