const clickBackdropZindex = 'z-70'
export {
  clickBackdropZindex
}
export default function ClickBackdrop({ testId = "clickBackdrop", onClick }: {
  testId?: string,
  onClick?: (arg0: boolean) => void
}) {
  return (
    <div
      className={`fixed ${clickBackdropZindex} inset-0`}
      data-testid={testId}
      onClick={({ target }: {
        target: any
      }) => {
        if (target.dataset.testid !== testId) return;
        onClick?.(false);
      }}
    />
  );
}
