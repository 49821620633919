import featureAnnouncementsApi from '../../../api/feature_announcements';
import useApi from '../../../hooks/useApi';
export default function useFeatureAnnouncementCreationServices() {
    const {
        data: createdFeatureAnnouncement,
        error: createError,
        loading: creating,
        request: createFeatureAnnouncement,
    } = useApi(featureAnnouncementsApi.createFeatureAnnouncement, null);

    const {
        data: updatedFeatureAnnouncement,
        error: updateError,
        loading: updating,
        request: updateFeatureAnnouncement,
    } = useApi(featureAnnouncementsApi.updateFeatureAnnouncement, null);

    return {
        createdFeatureAnnouncement,
        createError,
        creating,
        createFeatureAnnouncement,
        updatedFeatureAnnouncement,
        updateError,
        updating,
        updateFeatureAnnouncement,
    };
}