import { useState } from 'react';
import * as Sentry from "@sentry/react";
import Button from '../shared/Button';
import { BsFillBugFill } from 'react-icons/bs';
import useModal from '../../hooks/useModal';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalSuccessView } from '../utility/Modal';
import Form from '../../utils/Form';
import Loader from '../utility/Loader';
import useAuth from '../../auth/useAuth';
import timings from '../../styles/timings';
import ErrorViewModel from '../../models/ErrorViewModel';
import ErrorView from '../utility/ErrorView';
import { AiFillBug } from 'react-icons/ai';
export default function Feedback() {

    const { open, setOpen } = useModal();
    const { user } = useAuth();
    const defaultFeedback = {
        name: user.name,
        email: user.email,
        message: ''
    };

    const [feedback, setFeedback] = useState(defaultFeedback);

    const [error, setError] = useState<String | null>(null);
    const [saving, setSaving] = useState(false);
    const [saved, setSaved] = useState(false);

    const submitFeedback = async () => {
        try {
            setSaving(true);
            await Sentry.sendFeedback(
                feedback,
                {
                    includeReplay: true
                },
            );
            setSaving(false);
            setSaved(true);
            setTimeout(() => {
                setOpen(false);
                setFeedback(defaultFeedback);
                setSaved(false);
            }, timings.normal)
        } catch (error) {
            setSaving(false);
            setError("An error occurred while submitting feedback. Please try again.. \n If problem persists, please contact support@sitemarker.com.");
        }
    }
    return <>
        <div className="relative hidden md:inline-flex mr-2">
            <Button
                text='Report a bug'
                color='gray'
                rounded='rounded-full'
                icon={<AiFillBug
                    className="ml-2"
                />}
                onClick={() => setOpen(true)}
            />
        </div>
        <Modal isOpen={open} title="Report a bug" onClose={() => setOpen(false)}>
            <ModalHeader title="Submit a bug report" onClose={() => setOpen(false)} />
            <ModalBody>
                {error && <ErrorView extraClass='mb-2' error={error} />}
                {saved ? <ModalSuccessView text='Feedback successfully submitted!' /> : <>
                    <Form.InputGroup mb={4}>
                        <Form.Label required>
                            Full name
                        </Form.Label>
                        <Form.Input
                            type="text"
                            value={feedback.name}
                            placeholder='Robert Paulson'
                            onChange={({ target: { value: name } }) => setFeedback({ ...feedback, name })}
                        />
                    </Form.InputGroup>
                    <Form.InputGroup mb={4}>
                        <Form.Label required>
                            Email
                        </Form.Label>
                        <Form.Input
                            type="email"
                            value={feedback.email}
                            placeholder='youremail@domain.com'
                            onChange={({ target: { value: email } }) => setFeedback({ ...feedback, email })}
                        />
                    </Form.InputGroup>
                    <Form.Group mb={4}>
                        <Form.Label required>
                            Message
                        </Form.Label>
                        <Form.TextArea
                            rows={4}
                            value={feedback.message}
                            placeholder='Tell us what happened...'
                            onChange={({ target: { value: message } }) => setFeedback({ ...feedback, message })}
                        />
                    </Form.Group>
                </>}
            </ModalBody>
            {
                !saved && <ModalFooter>
                    <button
                        className="modal-save-btn mr-2"
                        type="button"
                        data-testid="sendFeedback"
                        onClick={submitFeedback}
                        disabled={saving}
                    >
                        Submit {saving && <Loader />}
                    </button>
                    <button
                        className="modal-close-btn"
                        type="button"
                        data-testid="cancelFeedback"
                        disabled={saving}
                        onClick={() => setOpen(false)}
                    >
                        Cancel
                    </button>
                </ModalFooter>
            }
        </Modal >
    </>;
}