import useApi from './useApi';
import projectContactsApi from '../api/project_contacts';
import { ProjectContact } from '../data/models';

const useAddContact = () => {
  const { data, loading, error, request } = useApi(projectContactsApi.addContact, null);

  const addContact = (projectId: string | undefined, contactData: ProjectContact) => {
    if (projectId) {
      request(projectId, contactData);
    }
  };

  return {
    addedContact: data,
    addLoading: loading,
    creationError: error,
    addContact,
  };
};

export default useAddContact;
