import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import reportPageEntryGroupsApi from '../../api/report_page_entry_groups';
import useApi from '../../hooks/useApi';
import Loader from '../utility/Loader';
import ReportPageEntryGroup from './ReportPageEntryGroup';
import ReportPageOptions from '../report_pages/ReportPageOptions';
import useReportContext from '../reports/useReportContext';

function ReportPageEntryGroups({ report, reportPage }) {
  const {
    data: report_page_entry_groups,
    loading,
    request: getReportPageEntryGroups,
  } = useApi(reportPageEntryGroupsApi.getReportPageEntryGroups, [], true);

  const { report_page_id, share_id } = useParams();
  const reportPageId = report_page_id || reportPage.objectId;
  const {
    editing,
    deletedPins,
    softDeletedPins,
    hiddenPages,
    softHiddenPages,
  } = useReportContext();
  const isPin = () =>
    report_page_entry_groups.length === 1 &&
    report_page_entry_groups[0].info?.pin_objectId;
  const isMap = () =>
    report_page_entry_groups.length === 1 &&
    report_page_entry_groups[0].entry_group?.component_name === 'EntryGroupMap';
  const isHidable = () => isPin() || isMap();
  const isDeletedPin = () =>
    [...deletedPins, ...softDeletedPins].includes(
      report_page_entry_groups[0].info?.pin_objectId
    );
  const isHiddenPage = () =>
    [...hiddenPages, ...softHiddenPages].includes(reportPageId);
  const loadEntryGroups = () =>
    getReportPageEntryGroups(reportPageId, { share_id });

  useEffect(() => {
    loadEntryGroups();
  }, [reportPage?.updated_at]);

  useEffect(() => {
    if (!deletedPins.length || !isHidable() || isDeletedPin()) return;
    loadEntryGroups();
  }, [deletedPins]);

  if (isHidable() && (isDeletedPin() || isHiddenPage())) return <span />;

  return (
    <div
      className={
        !report_page_id && 'mb-5 px-4 py-4 bg-white shadow-sm relative group'
      }
      id={`report_page_${report_page_id || reportPage.objectId}_${reportPage.updated_at}}`}
    >
      {loading ? (
        <div className="py-5 flex justify-center items-center">
          <Loader color={'text-gray-700'} margin="" />
        </div>
      ) : (
        <>
          {report_page_entry_groups.map((rpeg, index) => (
            <ReportPageEntryGroup
              key={`entry_group_${rpeg.objectId}_${index}_${rpeg.updated_at}`}
              lastGroup={index === report_page_entry_groups.length - 1}
              report={report}
              reportPageEntryGroup={rpeg}
            />
          ))}
        </>
      )}
      {isPin() && editing && <ReportPageOptions reportPageId={reportPageId} />}
      {isMap() && editing && (
        <ReportPageOptions
          reportPageId={reportPageId}
          hideAll={false}
          duplicate={true}
        />
      )}
    </div>
  );
}

export default ReportPageEntryGroups;
