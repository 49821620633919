import { useContext } from 'react';
import WorkspaceContext from '../partials/workspaces/context';
export default function useWorkspaceContext() {
  const { workspace, setWorkspace, loading } = useContext(WorkspaceContext);

  const projectIdentifierRequired = workspace?.settings?.projects?.identifiers?.required !== false;

  return {
    projectIdentifierRequired,
    workspace,
    setWorkspace,
    loading,
  };
}
