import { useEffect, useState } from 'react';
import workspacesApi from '../../api/workspaces';
import useApi from '../../hooks/useApi';
import { AddExtraProps } from '../../utility/addExtraProps';
import Loader from '../utility/Loader';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalSuccessView } from '../utility/Modal';
import useAuth from '../../auth/useAuth';
import ErrorView from '../utility/ErrorView';
import useSaved from '../../hooks/useSaved';
import { Workspace } from '../../data/models';
import WorkspaceForm from './WorkspaceForm';

export default function CreateWorkspaceModal({
  button,
  workspace: passedWorkspace,
  afterCreate,
}: {
  button?: React.ReactNode,
  workspace?: Workspace,
  afterCreate?: (workspace: Workspace) => void
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [workspace, setWorkspace] = useState<Workspace | any>(passedWorkspace || {});
  const {
    data: newWorkspace,
    error,
    loading,
    request: addWorkspace,
  } = useApi(workspacesApi.addWorkspace, null);

  const { permissions } = useAuth();

  const createWorkspace = () => {
    addWorkspace(workspace);
  };

  const updateWorkspace = (key: string, value: any) => {
    workspace[key] = value;
    setWorkspace({ ...workspace });
  };

  useEffect(() => {
    if (!passedWorkspace) return;
    setWorkspace({ ...passedWorkspace });
  }, [passedWorkspace]);

  const { saved } = useSaved(newWorkspace, {
    afterSaved: () => {
      setWorkspace({});
      setModalOpen(false);
      afterCreate?.(newWorkspace);
    }
  });

  return (
    <>
      {button ? (
        AddExtraProps(button, {
          onClick: () => setModalOpen(!modalOpen),
          'data-testid': 'actionCreateWorkspace',
        })
      ) : (
        <CreateButton
          onClick={() => setModalOpen(true)}
        />
      )}
      <Modal
        isOpen={modalOpen}
        dialogScrolls={true}
        modalScrolls={false}
        maxHeight={'max-h-auto'}
        dialogClass={'py-10'}
        aligned={'items-start'}
        onClose={() => setModalOpen(false)}
      >
        <ModalHeader
          title={'Create Workspace'}
          onClose={() => setModalOpen(false)}
        />
        {saved ? (<ModalSuccessView text={'New workspace successfully created'} />) : (<>
          <ModalBody>
            {error && (<ErrorView error={error} />)}
            <WorkspaceForm workspace={workspace} onUpdate={updateWorkspace} permissions={permissions} />
          </ModalBody>
          <ModalFooter>
            <button
              className="modal-save-btn mr-2"
              type="button"
              data-testId="createWorkspaceButton"
              disabled={loading}
              onClick={createWorkspace}
            >
              Create {loading && <Loader />}
            </button>
            <button
              className="modal-close-btn"
              type="button"
              data-testId="cancelWorkspaceButton"
              disabled={loading}
              onClick={() => setModalOpen(false)}
            >
              Cancel
            </button>
          </ModalFooter>
        </>
        )
        }
      </Modal >
    </>
  );
}

const CreateButton = ({ onClick }: {
  onClick: () => void
}) => <a
  className="text-sm cursor-pointer bg-secondary font-medium text-white hover:text-gray-50 hover:opacity-90 flex py-3 px-5 flex items-center text-center"
  data-testid="actionCreateWorkspace"
  onClick={onClick}
>
    <svg
      className="w-4 h-4 fill-current opacity-50 flex-shrink-0 mr-4"
      viewBox="0 0 16 16"
    >
      <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
    </svg>{' '}
    Create Workspace
  </a>;
