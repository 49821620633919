import React, { useState, useEffect } from 'react';
import { BsThreeDots } from 'react-icons/bs';
import EditMenu from '../EditMenu';
import { FiDroplet, FiEdit, FiTrash } from 'react-icons/fi';
import TagConfirmDeleteModal from './TagConfirmDeleteModal';
import TagColor from './TagColor';
import {
  makeDarkerColor,
  makeLighterColor,
} from '../../utility/colorAdjustments';
import { clickBackdropZindex } from '../utility/ClickBackdrop';
export default function TagEditMenu({
  tag,
  color: tagColor,
  className = 'hidden focus:outline-none group-hover:flex h-full px-1 text-opacity-50 hover:text-opacity-100 items-center justify-center rounded-l-full',
  onDropdown = false,
  style = {},
  onUpdate,
  onDestroy,
  setTagColor,
  onRenaming,
  onOptionChosen,
}) {
  const [optionChosen, setOptionChosen] = useState();
  const backgroundColor = makeLighterColor(tagColor, 0.55);
  const color = makeDarkerColor(tagColor, 0.7);

  const saveTag = (newTag) => {
    onUpdate?.(newTag);
    setOptionChosen();
  };

  const destroyTag = () => {
    onDestroy?.(tag);
  };

  useEffect(() => {
    if (optionChosen === 'rename') onRenaming?.();
  }, [optionChosen]);

  useEffect(() => {
    onOptionChosen?.(optionChosen);
  }, [optionChosen]);

  if (onDropdown) {
    return (
      <>
        <div
          className={`origin-top-right ${clickBackdropZindex} absolute top-full top-0 left-56 min-w-44 bg-white border border-gray-200 py-1.5 rounded shadow-lg overflow-hidden mt-1`}
          style={style}
        >
          <ul>
            {onDestroy && (
              <li>
                <button
                  className="font-medium focus:outline-none text-sm text-red-500 hover:text-red-600 flex py-1 px-3 flex items-center"
                  onClick={() => setOptionChosen('delete')}
                >
                  <FiTrash className="mr-1" /> Delete
                </button>
              </li>
            )}
            {onUpdate && (
              <>
                <li>
                  <button
                    className="font-medium focus:outline-none text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3 flex items-center"
                    onClick={() => setOptionChosen('rename')}
                  >
                    <FiEdit className="mr-1" /> Rename
                  </button>
                </li>
                <li>
                  <button
                    className="font-medium focus:outline-none text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3 flex items-center"
                    onClick={() => setOptionChosen('color')}
                  >
                    <FiDroplet className="mr-1" /> Edit Color
                  </button>
                </li>
              </>
            )}
          </ul>
        </div>
      </>
    );
  }
  return (
    <>
      <EditMenu
        action="click"
        align="left"
        button={
          <div className={className} style={{ color, backgroundColor }}>
            <BsThreeDots size={18} />
          </div>
        }
        clickBackdrop
      >
        {onDestroy && (
          <li>
            <button
              className="font-medium focus:outline-none text-sm text-red-500 hover:text-red-600 flex py-1 px-3 flex items-center"
              onClick={() => setOptionChosen('delete')}
            >
              <FiTrash className="mr-1" /> Delete
            </button>
          </li>
        )}
        {onUpdate && (
          <>
            <li>
              <button
                className="font-medium focus:outline-none text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3 flex items-center"
                onClick={() => setOptionChosen('rename')}
              >
                <FiEdit className="mr-1" /> Rename
              </button>
            </li>
            <li>
              <button
                className="font-medium focus:outline-none text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3 flex items-center"
                onClick={() => setOptionChosen('color')}
              >
                <FiDroplet className="mr-1" /> Edit Color
              </button>
            </li>
          </>
        )}
      </EditMenu>
      {optionChosen === 'color' && (
        <TagColor tag={tag} onUpdate={saveTag} onColorChange={setTagColor} />
      )}
      <TagConfirmDeleteModal
        tag={tag}
        open={optionChosen === 'delete'}
        onDestroy={destroyTag}
        onClose={() => setOptionChosen()}
      />
    </>
  );
}
