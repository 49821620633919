const pin_types = [
  {
    id: 15,
    name: 'General Pin',
    created_at: '2021-04-06T15:42:05.165Z',
    updated_at: '2021-04-06T15:42:05.165Z',
    objectId: '2fac10c9',
    pin_color: null,
    identifier_prefix: 'GEN',
    icon: {
      id: 22,
      name: 'plus',
      component_name: 'FontAwesome',
      objectId: 'e6167688',
    },
    fieldsets: [
      {
        id: 11,
        name: 'Category Tag',
        allows_multiple: null,
        objectId: '0b792534',
        fields: [
          {
            id: 16,
            component_name: 'FieldTags',
            name: 'category',
            has_description: false,
            style: {},
            required: false,
            objectId: '13522ac3',
            colors_pin: true,
            identifies_pin: false,
            prefixs_pin: false,
            allows_duplicates: null,
            field_options: [
              {
                id: 113,
                objectId: 'b2cb1a39',
                name: 'Proposed excavation',
                value: 'proposed_excavation',
                default: null,
                description: 'Proposed excavation',
                color: '#FFFFFF',
                order: 0,
              },
              {
                id: 114,
                objectId: '457a396e',
                name: 'Temporary survery markings',
                value: 'temporary_survery_markings',
                default: null,
                description: 'Temporary survery markings',
                color: '#F5C2D9',
                order: 1,
              },
              {
                id: 115,
                objectId: '3408f7f8',
                name: 'Electric Power Lines',
                value: 'electric_power_lines',
                default: null,
                description:
                  'Electric power lines, cables, conduit and lighting cables',
                color: '#D02630',
                order: 2,
              },
              {
                id: 116,
                objectId: '950ba31a',
                name: 'Gas, Oil, Steam',
                value: 'gas_oil_steam',
                default: null,
                description: 'Gas, oil, steam, pertroleum or gaseous materials',
                color: '#FDF035',
                order: 3,
              },
              {
                id: 117,
                objectId: '66c328e7',
                name: 'Communication, Alarm',
                value: 'communication',
                default: null,
                description:
                  'Communication, alarm or signal lines, cables or conduit',
                color: '#F8A332',
                order: 4,
              },
              {
                id: 118,
                objectId: 'ccdb1fb6',
                name: 'Potable Water',
                value: 'potable_water',
                default: null,
                description: 'Potable Water',
                color: '#1793C7',
                order: 5,
              },
              {
                id: 119,
                objectId: 'e2117c52',
                name: 'Reclaimed water, irrigation and slurry lines',
                value: 'recliamed_water',
                default: null,
                description: 'Reclaimed water, irrigation and slurry lines',
                color: '#87428B',
                order: 6,
              },
              {
                id: 120,
                objectId: 'eac53aa6',
                name: 'Sewer and drain lines',
                value: 'sewer_and_drain_lines',
                default: null,
                description: 'Sewer and drain lines',
                color: '#3B9944',
                order: 7,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 16,
    name: 'SWPPP',
    created_at: '2021-04-06T15:42:05.399Z',
    updated_at: '2021-04-06T15:42:05.399Z',
    objectId: '0f44cc6f',
    pin_color: null,
    identifier_prefix: null,
    icon: {
      id: 20,
      name: 'circle',
      component_name: 'FontAwesome',
      objectId: 'b89e0f8f',
    },
    fieldsets: [
      {
        id: 12,
        name: 'Violation Type',
        allows_multiple: null,
        objectId: 'b5e6c7d0',
        fields: [
          {
            id: 17,
            component_name: 'FieldBoxes',
            name: 'violation_type',
            has_description: true,
            style: {},
            required: true,
            objectId: '90317682',
            colors_pin: false,
            identifies_pin: true,
            prefixs_pin: true,
            allows_duplicates: null,
            field_options: [
              {
                id: 121,
                objectId: 'ced68bef',
                name: 'A',
                value: 'a',
                default: null,
                description:
                  'Some text describing the violation going on Some text describing the violation going on',
                color: null,
                order: 0,
              },
              {
                id: 122,
                objectId: 'a1dccb1e',
                name: 'B',
                value: 'b',
                default: null,
                description:
                  'Some text describing the violation going on Some text describing the violation going on',
                color: null,
                order: 1,
              },
              {
                id: 123,
                objectId: '5ba1c07d',
                name: 'C',
                value: 'c',
                default: null,
                description:
                  'Some text describing the violation going on Some text describing the violation going on',
                color: null,
                order: 2,
              },
              {
                id: 124,
                objectId: '7d525c0a',
                name: 'D',
                value: 'd',
                default: null,
                description:
                  'Some text describing the violation going on Some text describing the violation going on',
                color: null,
                order: 3,
              },
              {
                id: 125,
                objectId: '273af399',
                name: 'E',
                value: 'e',
                default: null,
                description:
                  'Some text describing the violation going on Some text describing the violation going on',
                color: null,
                order: 4,
              },
              {
                id: 126,
                objectId: 'a611b908',
                name: 'F',
                value: 'f',
                default: null,
                description:
                  'Some text describing the violation going on Some text describing the violation going on',
                color: null,
                order: 5,
              },
              {
                id: 127,
                objectId: '0969e801',
                name: 'G',
                value: 'g',
                default: null,
                description:
                  'Some text describing the violation going on Some text describing the violation going on',
                color: null,
                order: 6,
              },
              {
                id: 128,
                objectId: 'b04e5cb3',
                name: 'H',
                value: 'h',
                default: null,
                description:
                  'Some text describing the violation going on Some text describing the violation going on',
                color: null,
                order: 7,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 17,
    name: 'Tree',
    created_at: '2021-04-06T15:42:05.543Z',
    updated_at: '2021-04-06T15:42:05.543Z',
    objectId: '6d48a8ef',
    pin_color: '#9ed531',
    identifier_prefix: 'TREE',
    icon: {
      id: 21,
      name: 'triangle',
      component_name: 'MaterialCommunityIcons',
      objectId: 'b6053ebf',
    },
    fieldsets: [
      {
        id: 13,
        name: 'Species',
        allows_multiple: null,
        objectId: 'fbb396c7',
        fields: [
          {
            id: 18,
            component_name: 'FieldPicker',
            name: 'species',
            has_description: false,
            style: {},
            required: true,
            objectId: '27a8c3db',
            colors_pin: false,
            identifies_pin: false,
            prefixs_pin: false,
            allows_duplicates: null,
            field_options: [
              {
                id: 130,
                objectId: '8e622381',
                name: 'Gum',
                value: 'gum',
                default: null,
                description: null,
                color: null,
                order: 1,
              },
              {
                id: 131,
                objectId: 'f26528d2',
                name: 'Spruce',
                value: 'spruce',
                default: null,
                description: null,
                color: null,
                order: 2,
              },
              {
                id: 132,
                objectId: '8643110f',
                name: 'Maple',
                value: 'maple',
                default: null,
                description: null,
                color: null,
                order: 3,
              },
              {
                id: 133,
                objectId: '4c6c3a36',
                name: 'Willow',
                value: 'willow',
                default: null,
                description: null,
                color: null,
                order: 4,
              },
              {
                id: 134,
                objectId: '4994b269',
                name: 'Dogwood',
                value: 'dogwood',
                default: null,
                description: null,
                color: null,
                order: 5,
              },
              {
                id: 129,
                objectId: 'fb536271',
                name: 'Whiteoak',
                value: 'whiteoak',
                default: true,
                description: null,
                color: null,
                order: 0,
              },
            ],
          },
        ],
      },
      {
        id: 14,
        name: 'Grade',
        allows_multiple: null,
        objectId: 'ab93bc1e',
        fields: [
          {
            id: 19,
            component_name: 'FieldBoxes',
            name: 'grade',
            has_description: true,
            style: {},
            required: true,
            objectId: '68f04da4',
            colors_pin: false,
            identifies_pin: false,
            prefixs_pin: false,
            allows_duplicates: null,
            field_options: [
              {
                id: 135,
                objectId: '04fa28e2',
                name: 'A',
                value: 'a',
                default: null,
                description: 'Very healthy',
                color: null,
                order: 0,
              },
              {
                id: 136,
                objectId: '3538be67',
                name: 'B',
                value: 'b',
                default: null,
                description: 'Healthy with signs of aging',
                color: null,
                order: 1,
              },
              {
                id: 137,
                objectId: '151815cc',
                name: 'C',
                value: 'c',
                default: null,
                description: 'Aging heavily, not dead',
                color: null,
                order: 2,
              },
              {
                id: 138,
                objectId: '24d789fb',
                name: 'D',
                value: 'd',
                default: null,
                description: 'Dead and rotting',
                color: null,
                order: 3,
              },
            ],
          },
        ],
      },
      {
        id: 15,
        name: 'Rating',
        allows_multiple: null,
        objectId: '195101ef',
        fields: [
          {
            id: 20,
            component_name: 'FieldBoxes',
            name: 'rating',
            has_description: false,
            style: {},
            required: true,
            objectId: 'e6fe903c',
            colors_pin: false,
            identifies_pin: false,
            prefixs_pin: false,
            allows_duplicates: null,
            field_options: [
              {
                id: 139,
                objectId: 'c228d2cf',
                name: 'Poor',
                value: 'poor',
                default: null,
                description: null,
                color: null,
                order: 0,
              },
              {
                id: 140,
                objectId: '68f0406e',
                name: 'Fair',
                value: 'fair',
                default: null,
                description: null,
                color: null,
                order: 1,
              },
              {
                id: 141,
                objectId: '48c45c48',
                name: 'Good',
                value: 'good',
                default: null,
                description: null,
                color: null,
                order: 2,
              },
            ],
          },
        ],
      },
      {
        id: 16,
        name: 'Trunk Size(s)',
        allows_multiple: null,
        objectId: '4773dacd',
        fields: [
          {
            id: 21,
            component_name: 'FieldTags',
            name: 'trunk',
            has_description: false,
            style: {},
            required: true,
            objectId: '16004c7c',
            colors_pin: false,
            identifies_pin: false,
            prefixs_pin: false,
            allows_duplicates: true,
            field_options: [
              {
                id: 142,
                objectId: 'f3724bbf',
                name: '1"',
                value: '1',
                default: null,
                description: null,
                color: null,
                order: 0,
              },
              {
                id: 143,
                objectId: '12f26a07',
                name: '2"',
                value: '2',
                default: null,
                description: null,
                color: null,
                order: 1,
              },
              {
                id: 144,
                objectId: '671476cc',
                name: '3"',
                value: '3',
                default: null,
                description: null,
                color: null,
                order: 2,
              },
              {
                id: 145,
                objectId: '0e612d18',
                name: '4"',
                value: '4',
                default: null,
                description: null,
                color: null,
                order: 3,
              },
              {
                id: 146,
                objectId: 'a0f3531f',
                name: '5"',
                value: '5',
                default: null,
                description: null,
                color: null,
                order: 4,
              },
              {
                id: 147,
                objectId: 'b1f9f51e',
                name: '6"',
                value: '6',
                default: null,
                description: null,
                color: null,
                order: 5,
              },
              {
                id: 148,
                objectId: '755990b4',
                name: '7"',
                value: '7',
                default: null,
                description: null,
                color: null,
                order: 6,
              },
              {
                id: 149,
                objectId: '6bb7aa02',
                name: '8"',
                value: '8',
                default: null,
                description: null,
                color: null,
                order: 7,
              },
              {
                id: 150,
                objectId: '26aca5b5',
                name: '9"',
                value: '9',
                default: null,
                description: null,
                color: null,
                order: 8,
              },
              {
                id: 151,
                objectId: '5903af4f',
                name: '10"',
                value: '10',
                default: null,
                description: null,
                color: null,
                order: 9,
              },
              {
                id: 152,
                objectId: '35eb56aa',
                name: '11"',
                value: '11',
                default: null,
                description: null,
                color: null,
                order: 10,
              },
              {
                id: 153,
                objectId: '89a4293b',
                name: '12"',
                value: '12',
                default: null,
                description: null,
                color: null,
                order: 11,
              },
              {
                id: 154,
                objectId: 'bff8363f',
                name: '13"',
                value: '13',
                default: null,
                description: null,
                color: null,
                order: 12,
              },
              {
                id: 155,
                objectId: '4d38d2fb',
                name: '14"',
                value: '14',
                default: null,
                description: null,
                color: null,
                order: 13,
              },
              {
                id: 156,
                objectId: '73ee00bb',
                name: '15"',
                value: '15',
                default: null,
                description: null,
                color: null,
                order: 14,
              },
              {
                id: 157,
                objectId: 'ed0afaf8',
                name: '16"',
                value: '16',
                default: null,
                description: null,
                color: null,
                order: 15,
              },
              {
                id: 158,
                objectId: '8ac8f727',
                name: '17"',
                value: '17',
                default: null,
                description: null,
                color: null,
                order: 16,
              },
              {
                id: 159,
                objectId: 'e655166f',
                name: '18"',
                value: '18',
                default: null,
                description: null,
                color: null,
                order: 17,
              },
              {
                id: 160,
                objectId: '67c7ffba',
                name: '19"',
                value: '19',
                default: null,
                description: null,
                color: null,
                order: 18,
              },
              {
                id: 161,
                objectId: 'f3b89076',
                name: '20"',
                value: '20',
                default: null,
                description: null,
                color: null,
                order: 19,
              },
              {
                id: 162,
                objectId: '1acfe7d6',
                name: '21"',
                value: '21',
                default: null,
                description: null,
                color: null,
                order: 20,
              },
              {
                id: 163,
                objectId: '57632b2a',
                name: '22"',
                value: '22',
                default: null,
                description: null,
                color: null,
                order: 21,
              },
              {
                id: 164,
                objectId: '8fecb038',
                name: '23"',
                value: '23',
                default: null,
                description: null,
                color: null,
                order: 22,
              },
              {
                id: 165,
                objectId: 'fcb824e0',
                name: '24"',
                value: '24',
                default: null,
                description: null,
                color: null,
                order: 23,
              },
              {
                id: 166,
                objectId: '32e07eb3',
                name: '25"',
                value: '25',
                default: null,
                description: null,
                color: null,
                order: 24,
              },
              {
                id: 167,
                objectId: '11b52100',
                name: '26"',
                value: '26',
                default: null,
                description: null,
                color: null,
                order: 25,
              },
              {
                id: 168,
                objectId: '81c61240',
                name: '27"',
                value: '27',
                default: null,
                description: null,
                color: null,
                order: 26,
              },
              {
                id: 169,
                objectId: 'b646d9fc',
                name: '28"',
                value: '28',
                default: null,
                description: null,
                color: null,
                order: 27,
              },
              {
                id: 170,
                objectId: '6ceeedc4',
                name: '29"',
                value: '29',
                default: null,
                description: null,
                color: null,
                order: 28,
              },
              {
                id: 171,
                objectId: 'bc006eee',
                name: '30"',
                value: '30',
                default: null,
                description: null,
                color: null,
                order: 29,
              },
              {
                id: 172,
                objectId: '48f331b5',
                name: '31"',
                value: '31',
                default: null,
                description: null,
                color: null,
                order: 30,
              },
              {
                id: 173,
                objectId: '324980a3',
                name: '32"',
                value: '32',
                default: null,
                description: null,
                color: null,
                order: 31,
              },
              {
                id: 174,
                objectId: 'e4eda335',
                name: '33"',
                value: '33',
                default: null,
                description: null,
                color: null,
                order: 32,
              },
              {
                id: 175,
                objectId: 'f40f2a04',
                name: '34"',
                value: '34',
                default: null,
                description: null,
                color: null,
                order: 33,
              },
              {
                id: 176,
                objectId: 'c2b8dcba',
                name: '35"',
                value: '35',
                default: null,
                description: null,
                color: null,
                order: 34,
              },
              {
                id: 177,
                objectId: '90ce3d40',
                name: '36"',
                value: '36',
                default: null,
                description: null,
                color: null,
                order: 35,
              },
              {
                id: 178,
                objectId: '80b76d63',
                name: '37"',
                value: '37',
                default: null,
                description: null,
                color: null,
                order: 36,
              },
              {
                id: 179,
                objectId: 'd2e6fa1b',
                name: '38"',
                value: '38',
                default: null,
                description: null,
                color: null,
                order: 37,
              },
              {
                id: 180,
                objectId: 'c9c2ed75',
                name: '39"',
                value: '39',
                default: null,
                description: null,
                color: null,
                order: 38,
              },
              {
                id: 181,
                objectId: '9e897836',
                name: '40"',
                value: '40',
                default: null,
                description: null,
                color: null,
                order: 39,
              },
              {
                id: 182,
                objectId: '3496c6d0',
                name: '41"',
                value: '41',
                default: null,
                description: null,
                color: null,
                order: 40,
              },
              {
                id: 183,
                objectId: 'c19357b0',
                name: '42"',
                value: '42',
                default: null,
                description: null,
                color: null,
                order: 41,
              },
              {
                id: 184,
                objectId: '2ecc827c',
                name: '43"',
                value: '43',
                default: null,
                description: null,
                color: null,
                order: 42,
              },
              {
                id: 185,
                objectId: '3896ac85',
                name: '44"',
                value: '44',
                default: null,
                description: null,
                color: null,
                order: 43,
              },
              {
                id: 186,
                objectId: '3d64f944',
                name: '45"',
                value: '45',
                default: null,
                description: null,
                color: null,
                order: 44,
              },
              {
                id: 187,
                objectId: '38e48829',
                name: '46"',
                value: '46',
                default: null,
                description: null,
                color: null,
                order: 45,
              },
              {
                id: 188,
                objectId: 'f4b4a3e3',
                name: '47"',
                value: '47',
                default: null,
                description: null,
                color: null,
                order: 46,
              },
              {
                id: 189,
                objectId: '8265adb3',
                name: '48"',
                value: '48',
                default: null,
                description: null,
                color: null,
                order: 47,
              },
              {
                id: 190,
                objectId: '0880a634',
                name: '49"',
                value: '49',
                default: null,
                description: null,
                color: null,
                order: 48,
              },
              {
                id: 191,
                objectId: '9fbf70d9',
                name: '50"',
                value: '50',
                default: null,
                description: null,
                color: null,
                order: 49,
              },
              {
                id: 192,
                objectId: '18c0e500',
                name: '51"',
                value: '51',
                default: null,
                description: null,
                color: null,
                order: 50,
              },
              {
                id: 193,
                objectId: '740f6404',
                name: '52"',
                value: '52',
                default: null,
                description: null,
                color: null,
                order: 51,
              },
              {
                id: 194,
                objectId: '5ab305fe',
                name: '53"',
                value: '53',
                default: null,
                description: null,
                color: null,
                order: 52,
              },
              {
                id: 195,
                objectId: '1f742297',
                name: '54"',
                value: '54',
                default: null,
                description: null,
                color: null,
                order: 53,
              },
              {
                id: 196,
                objectId: '89a2d639',
                name: '55"',
                value: '55',
                default: null,
                description: null,
                color: null,
                order: 54,
              },
              {
                id: 197,
                objectId: 'd6fd4e82',
                name: '56"',
                value: '56',
                default: null,
                description: null,
                color: null,
                order: 55,
              },
              {
                id: 198,
                objectId: '6864e692',
                name: '57"',
                value: '57',
                default: null,
                description: null,
                color: null,
                order: 56,
              },
              {
                id: 199,
                objectId: 'f0b45a34',
                name: '58"',
                value: '58',
                default: null,
                description: null,
                color: null,
                order: 57,
              },
              {
                id: 200,
                objectId: 'f83df545',
                name: '59"',
                value: '59',
                default: null,
                description: null,
                color: null,
                order: 58,
              },
              {
                id: 201,
                objectId: 'e568aee3',
                name: '60"',
                value: '60',
                default: null,
                description: null,
                color: null,
                order: 59,
              },
              {
                id: 202,
                objectId: '37b546e0',
                name: '61"',
                value: '61',
                default: null,
                description: null,
                color: null,
                order: 60,
              },
              {
                id: 203,
                objectId: 'e9de5f1f',
                name: '62"',
                value: '62',
                default: null,
                description: null,
                color: null,
                order: 61,
              },
              {
                id: 204,
                objectId: 'd1132c90',
                name: '63"',
                value: '63',
                default: null,
                description: null,
                color: null,
                order: 62,
              },
              {
                id: 205,
                objectId: '7e697097',
                name: '64"',
                value: '64',
                default: null,
                description: null,
                color: null,
                order: 63,
              },
              {
                id: 206,
                objectId: 'b244bae6',
                name: '65"',
                value: '65',
                default: null,
                description: null,
                color: null,
                order: 64,
              },
              {
                id: 207,
                objectId: '20cfeef6',
                name: '66"',
                value: '66',
                default: null,
                description: null,
                color: null,
                order: 65,
              },
              {
                id: 208,
                objectId: 'da55a296',
                name: '67"',
                value: '67',
                default: null,
                description: null,
                color: null,
                order: 66,
              },
              {
                id: 209,
                objectId: '0f14cdda',
                name: '68"',
                value: '68',
                default: null,
                description: null,
                color: null,
                order: 67,
              },
              {
                id: 210,
                objectId: '7a6e036c',
                name: '69"',
                value: '69',
                default: null,
                description: null,
                color: null,
                order: 68,
              },
              {
                id: 211,
                objectId: 'b5c33ef9',
                name: '70"',
                value: '70',
                default: null,
                description: null,
                color: null,
                order: 69,
              },
              {
                id: 212,
                objectId: '2a7893ea',
                name: '71"',
                value: '71',
                default: null,
                description: null,
                color: null,
                order: 70,
              },
              {
                id: 213,
                objectId: 'dd472461',
                name: '72"',
                value: '72',
                default: null,
                description: null,
                color: null,
                order: 71,
              },
              {
                id: 214,
                objectId: '95b50095',
                name: '73"',
                value: '73',
                default: null,
                description: null,
                color: null,
                order: 72,
              },
              {
                id: 215,
                objectId: 'd2bd5e93',
                name: '74"',
                value: '74',
                default: null,
                description: null,
                color: null,
                order: 73,
              },
              {
                id: 216,
                objectId: '19e37eac',
                name: '75"',
                value: '75',
                default: null,
                description: null,
                color: null,
                order: 74,
              },
              {
                id: 217,
                objectId: '5100dccf',
                name: '76"',
                value: '76',
                default: null,
                description: null,
                color: null,
                order: 75,
              },
              {
                id: 218,
                objectId: '280a228f',
                name: '77"',
                value: '77',
                default: null,
                description: null,
                color: null,
                order: 76,
              },
              {
                id: 219,
                objectId: 'ce81911d',
                name: '78"',
                value: '78',
                default: null,
                description: null,
                color: null,
                order: 77,
              },
              {
                id: 220,
                objectId: '463f1da7',
                name: '79"',
                value: '79',
                default: null,
                description: null,
                color: null,
                order: 78,
              },
              {
                id: 221,
                objectId: '9a21b775',
                name: '80"',
                value: '80',
                default: null,
                description: null,
                color: null,
                order: 79,
              },
              {
                id: 222,
                objectId: 'a431e657',
                name: '81"',
                value: '81',
                default: null,
                description: null,
                color: null,
                order: 80,
              },
              {
                id: 223,
                objectId: 'b389406c',
                name: '82"',
                value: '82',
                default: null,
                description: null,
                color: null,
                order: 81,
              },
              {
                id: 224,
                objectId: '1515fa9f',
                name: '83"',
                value: '83',
                default: null,
                description: null,
                color: null,
                order: 82,
              },
              {
                id: 225,
                objectId: '87f66ec1',
                name: '84"',
                value: '84',
                default: null,
                description: null,
                color: null,
                order: 83,
              },
              {
                id: 226,
                objectId: '2f89a279',
                name: '85"',
                value: '85',
                default: null,
                description: null,
                color: null,
                order: 84,
              },
              {
                id: 227,
                objectId: 'fd22905d',
                name: '86"',
                value: '86',
                default: null,
                description: null,
                color: null,
                order: 85,
              },
              {
                id: 228,
                objectId: '1012fe66',
                name: '87"',
                value: '87',
                default: null,
                description: null,
                color: null,
                order: 86,
              },
              {
                id: 229,
                objectId: '526d856e',
                name: '88"',
                value: '88',
                default: null,
                description: null,
                color: null,
                order: 87,
              },
              {
                id: 230,
                objectId: '0f47afcc',
                name: '89"',
                value: '89',
                default: null,
                description: null,
                color: null,
                order: 88,
              },
              {
                id: 231,
                objectId: 'e7fc665d',
                name: '90"',
                value: '90',
                default: null,
                description: null,
                color: null,
                order: 89,
              },
              {
                id: 232,
                objectId: 'dd8e0dbc',
                name: '91"',
                value: '91',
                default: null,
                description: null,
                color: null,
                order: 90,
              },
              {
                id: 233,
                objectId: '5551b70b',
                name: '92"',
                value: '92',
                default: null,
                description: null,
                color: null,
                order: 91,
              },
              {
                id: 234,
                objectId: '7cfe1fa3',
                name: '93"',
                value: '93',
                default: null,
                description: null,
                color: null,
                order: 92,
              },
              {
                id: 235,
                objectId: '141c8973',
                name: '94"',
                value: '94',
                default: null,
                description: null,
                color: null,
                order: 93,
              },
              {
                id: 236,
                objectId: '9cfd6d32',
                name: '95"',
                value: '95',
                default: null,
                description: null,
                color: null,
                order: 94,
              },
              {
                id: 237,
                objectId: '716fedd9',
                name: '96"',
                value: '96',
                default: null,
                description: null,
                color: null,
                order: 95,
              },
              {
                id: 238,
                objectId: '5644c9bf',
                name: '97"',
                value: '97',
                default: null,
                description: null,
                color: null,
                order: 96,
              },
              {
                id: 239,
                objectId: 'f42bae2b',
                name: '98"',
                value: '98',
                default: null,
                description: null,
                color: null,
                order: 97,
              },
              {
                id: 240,
                objectId: '76ce360c',
                name: '99"',
                value: '99',
                default: null,
                description: null,
                color: null,
                order: 98,
              },
              {
                id: 241,
                objectId: '13632a1f',
                name: '100"',
                value: '100',
                default: null,
                description: null,
                color: null,
                order: 99,
              },
            ],
          },
        ],
      },
      {
        id: 17,
        name: 'Irregular Canopy',
        allows_multiple: null,
        objectId: 'cb0cbbd6',
        fields: [
          {
            id: 24,
            component_name: 'FieldSwitch',
            name: 'irregular_canopy',
            has_description: false,
            style: {},
            required: false,
            objectId: '563b32ae',
            colors_pin: false,
            identifies_pin: false,
            prefixs_pin: false,
            allows_duplicates: null,
            field_options: [],
          },
        ],
      },
      {
        id: 18,
        name: 'Direction',
        allows_multiple: null,
        objectId: 'da8796a6',
        fields: [
          {
            id: 25,
            component_name: 'FieldPicker',
            name: 'direction',
            has_description: false,
            style: {},
            required: false,
            objectId: 'a5879b2c',
            colors_pin: false,
            identifies_pin: false,
            prefixs_pin: false,
            allows_duplicates: null,
            field_options: [
              {
                id: 243,
                objectId: 'fd4d9f80',
                name: 'East',
                value: 'east',
                default: null,
                description: null,
                color: null,
                order: 1,
              },
              {
                id: 244,
                objectId: '491cbf32',
                name: 'South',
                value: 'south',
                default: null,
                description: null,
                color: null,
                order: 2,
              },
              {
                id: 245,
                objectId: '96868fb3',
                name: 'West',
                value: 'west',
                default: null,
                description: null,
                color: null,
                order: 3,
              },
              {
                id: 242,
                objectId: 'd5d1c002',
                name: 'North',
                value: 'north',
                default: true,
                description: null,
                color: null,
                order: 0,
              },
            ],
          },
        ],
      },
    ],
  },
];
export default pin_types;
