import { useState, memo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Modal, { ModalHeader, ModalSuccessView } from '../utility/Modal';
import { BiDuplicate } from 'react-icons/bi';
import reportPagesApi from '../../api/report_pages';
import useReportContext from '../reports/useReportContext';
import useApi from '../../hooks/useApi';
import useSaved from '../../hooks/useSaved';
import timings from '../../styles/timings';

function ReportPageDuplicateButton({ reportPageId }) {
  const { report_id } = useParams();
  const [modalOpen, setModalOpen] = useState(false);
  const { setNewPagesAdded } = useReportContext();

  const {
    data,
    request: duplicatePage
  } = useApi(reportPagesApi.duplicateReportPage, null);

  const { saved: duplicated } = useSaved(data?.updated_at)

  useEffect(() => {
    if (!data?.objectId) return;
    setNewPagesAdded(true);
  }, [data?.objectId])

  useEffect(() => {
    if (!data?.objectId || !duplicated) return;
    setTimeout(() => setModalOpen(false), 2000);
  }, [data?.objectId, duplicated])

  const closeModal = () => setModalOpen(false);

  return (
    <>
      <a
        onClick={() => setModalOpen(!modalOpen)}
        className="transition ease-in duration-150 py-2 bg-transparent cursor-pointer border-l border-gray-500 pl-3 font-medium text-xs text-gray-200 hover:text-white flex px-2 border-b-0 border-r-0"
      >
        <BiDuplicate size={16} className="mr-0.5 text-green-400" /> Duplicate
        Map Page
      </a>

      <Modal isOpen={modalOpen} onClose={closeModal}>
        <ModalHeader
          title={'Confirm Page Duplication'}
          onClose={() => setModalOpen(false)}
        />
        <div className="px-2 pt-2">
          <div className="p-5">
            {!duplicated && (
              <>
                <div className="mb-4">
                  <div className="font-semibold">Are you sure?</div>
                </div>
                <div>This will add a duplicate page below this page</div>
              </>
            )}
            {duplicated && (
              <ModalSuccessView text={'Page successfully duplicated'} />
            )}
          </div>

          {!duplicated && (
            <div className="modal-footer">
              <button
                className="modal-success-btn"
                type="button"
                onClick={() => duplicatePage(
                  report_id,
                  reportPageId
                )}
              >
                Duplicate Page
              </button>
              <button
                className="modal-close-btn"
                type="button"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}

export default memo(ReportPageDuplicateButton);
