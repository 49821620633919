import React from 'react';
import MembersCard from '../members/MembersCard';
import ProjectDetailsCard from './ProjectDetailsCard';
import ProjectMeetingsCard from './ProjectMeetingsCard';
import ProjectNotesCard from './ProjectNotesCard';
import ProjectPhotosCard from './ProjectPhotosCard';

export default function ProjectDetails() {
  return (
    <div className="grid grid-cols-2 gap-6 px-4 sm:px-6 lg:px-8 py-8 max-w-9xl self-start w-full">
      <div className="col-span-full xl:col-span-1">
        <ProjectDetailsCard />
        <ProjectPhotosCard />
      </div>
      <div className="col-span-full xl:col-span-1">
        <MembersCard />
        <ProjectNotesCard />
        <ProjectMeetingsCard />
      </div>
    </div>
  );
}
