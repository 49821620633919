import React from 'react';
import useClipboard from 'react-use-clipboard';
export default function CopyButton({ text }) {
  const [isCopied, setCopied] = useClipboard(text, {
    successDuration: 3000,
  });
  return (
    <button
      onClick={setCopied}
      className={`ml-3 btn focus:outline-none ${
        isCopied ? 'bg-blue-50' : 'bg-white'
      } border-sm-lightblue shadow-sm text-secondary rounded-md font-semibold hover:opacity-80 cursor-pointer`}
    >
      {isCopied ? 'Copied!' : 'Copy'}
    </button>
  );
}
