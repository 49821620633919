import { memo } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import Tags from '../shared/Tags';
import { Dot } from '../shared/Badge';
import useFeatures from '../../hooks/useFeatures';

function ProjectsCard({ project, workspaceId }) {
  const {
    active,
    objectId,
    name,
    identifier,
    updated_at,
    map: {
      location: { region, locality },
      images: { large },
    },
    tags_with_colors,
    latest_members,
    total_member_count,
  } = project;

  const { features } = useFeatures();


  return (
    <Link
      to={`/${workspaceId}/projects/${objectId}/details`}
      className="transition duration-150 transform hover:-translate-y-1 col-span-full sm:col-span-6 xl:col-span-4 bg-white shadow-lg rounded-sm border border-gray-200"
    >
      <div className="flex flex-col h-full">
        <header
          style={{
            height: '240px',
            backgroundImage: `url(${large})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Tags tags={tags_with_colors} maxTags={2} keyPrefix={objectId} />
        <div className="flex-grow mt-2 pt-5 px-5">
          <div className="inline-flex text-gray-800 hover:text-gray-900 mb-1">
            <h2 className="text-xl font-light font-oswald uppercase flex items-center">
              <span className="font-medium">{identifier}</span> <span className='ml-1'>{name}</span>  {features.rollout?.max_project_limit && <span className='ml-1.5'><Dot style={active ? 'green' : 'warning'} title={active ? 'Active' : 'Inactive'} /></span>}
            </h2>
          </div>
          <div className="text-sm mb-3">{`${locality}, ${region}`} </div>
          <Link
            to={`/${workspaceId}/projects/${objectId}/details?show=teamMembers`}
            className="flex flex-shrink-0 -space-x-3 -ml-px"
          >
            {latest_members.slice(0, 3).map((member) => {
              return (
                <div
                  key={`member_${member.objectId}`}
                  className="block"
                  to={`/projects/${objectId}/members/${member.objectId}`}
                >
                  <div
                    className="rounded-full border-2 border-white box-content"
                    style={{
                      height: 28,
                      width: 28,
                      backgroundImage: `url(${member.profile_photos?.medium ||
                        'https://bucketeer-d9937a0d-80e4-469a-91e2-6c86bd37d08f.s3.amazonaws.com/public/user_empty.png'
                        })`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                    }}
                    alt={member.name}
                  />
                </div>
              );
            })}
            {total_member_count > 3 && (
              <div
                className="rounded-full border-2 border-white box-content bg-primary text-white text-xs flex items-center justify-center"
                style={{ height: 28, width: 28 }}
              >
                <p>+{total_member_count - 3}</p>
              </div>
            )}
          </Link>
        </div>

        <footer className="mt-4 pb-5 px-5">
          <div className="flex justify-between items-center">
            <div>
              <div className="text-xs text-gray-500">
                Last updated: {moment(updated_at).format('MMM D, YYYY')}
              </div>
            </div>
            <div>
              <div
                className="text-sm font-medium text-secondary"
                to={`/${workspaceId}/projects/${objectId}/details`}
              >
                View -&gt;
              </div>
            </div>
          </div>
        </footer>
      </div>
    </Link>
  );
}

export default memo(ProjectsCard);
