import React, { useState, useEffect } from 'react';
export default function useFormattedFolderData(folderData) {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!folderData) return;
    const folders = folderData.folders
      .sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      })
      .map((folder) => ({ ...folder, type: 'folder' }));
    const documents = folderData.documents
      .sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      })
      .map((document) => ({
        ...document,
        type: document.name.substring(document.name.lastIndexOf('.') + 1),
      }));

    const archivedFolders = folderData.archived_folders.map((x) => ({
      ...x,
      type: 'archived_folder',
    }));
    const archivedDocuments = folderData.archived_documents.map((x) => ({
      ...x,
      type: 'archived_document',
    }));

    const data = [
      ...folders,
      ...documents,
      ...archivedFolders,
      ...archivedDocuments,
    ];
    setData(data);
  }, [folderData]);

  return {
    data,
  };
}
