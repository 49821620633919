import React, { useEffect, useRef, useState } from 'react';
import { FaCaretDown } from 'react-icons/fa';
import { Link, Redirect, useLocation } from 'react-router-dom';
import workspacesApi from '../../api/workspaces';
import useApi from '../../hooks/useApi';
import Loader from '../utility/Loader';
import CreateWorkspaceModal from './CreateWorkspaceModal';
import Transition from '../../utils/Transition';
import DelayedSearchInput from '../utility/DelayedSearchInput';
import { maxWorkspaceCount } from '../../utility/workspaceConfig';

export default function CreateWorkspaceMenu({
  workspace,
  caretProps = { className: 'text-black', size: 28 },
  openClasses = 'bg-gray-100 text-gray-500',
  includeCreate = true,
  redirectPath: passedRedirectPath,
}) {
  const { pathname } = useLocation();
  const [chosenWorkspace, setChosenWorkspace] = useState(null);
  const {
    data: { records, pagy },
    error,
    loading,
    request: getWorkspaces,
  } = useApi(workspacesApi.getWorkspaces, { records: [], pagy: {} });

  const [searchParams, setSearchParams] = useState({
    q: '',
    items: maxWorkspaceCount,
  });
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const trigger = useRef(null);
  const dropdown = useRef(null);

  const redirectPath = () => {
    let tempPath =
      passedRedirectPath || pathname.replace(`/${workspace.objectId}`, '');
    if (tempPath.indexOf('projects') > -1) tempPath = '/projects';
    return tempPath;
  };

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (
        target.tagName === 'INPUT' ||
        !dropdownOpen ||
        (dropdown.current && dropdown.current.contains(target)) ||
        (trigger.current && trigger.current.contains(target))
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ target, keyCode }) => {
      if (!dropdownOpen || keyCode !== 27 || target.tagName === 'INPUT') return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {
    if (!dropdownOpen) return;
    getWorkspaces({ ...searchParams });
  }, [dropdownOpen, searchParams]);

  if (chosenWorkspace)
    return <Redirect to={`/${chosenWorkspace.objectId}/team`} />;

  return (
    <div className="text-left">
      <button
        ref={trigger}
        data-testid="workspaceToggleButton"
        className={`text-gray-400 hover:text-gray-500 flex items-center focus:outline-none rounded-full ${dropdownOpen && openClasses
          }`}
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <span className="sr-only">Menu</span>
        <FaCaretDown {...caretProps} />
      </button>
      <Transition
        show={dropdownOpen}
        tag="div"
        className={`origin-top-right z-50 w-full absolute top-full right-0 min-w-36 bg-white border border-gray-200 rounded shadow-lg overflow-hidden mt-1`}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        {(pagy.count > 2 || searchParams.q.length > 0) && (
          <DelayedSearchInput
            id={'workspace-search'}
            placeholder={'Search'}
            onSearched={(text) => {
              setSearchParams({ q: text });
            }}
          />
        )}
        <ul ref={dropdown}>
          {loading && (
            <li className="flex justify-center py-3">
              <Loader color={'gray-800'} margin={''} />
            </li>
          )}
          {!loading &&
            records
              .filter(({ id }) => id !== workspace.id)
              .map((w) => (
                <li
                  className="hover:bg-gray-50 border border-b border-gray-100 py-2 px-4 truncate overflow-hidden"
                  key={`workspace_${w.objectId}_menu_option`}
                >
                  <Link
                    className="cursor-pointer text-gray-600 hover:text-gray-800 flex flex-col focus:outline-none"
                    data-testid={`workspace${w.name}Option`}
                    to={`/${w.objectId}${redirectPath()}`}
                    onClick={() => setDropdownOpen(false)}
                  >
                    <span className="font-bold">{w.name}</span>
                    <span className="text-secondary font-medium text-xs">
                      {w.projects_count} Projects
                    </span>
                  </Link>
                </li>
              ))}
          {dropdownOpen && includeCreate && !loading && (
            <li className="border-t border-gray-100 bg-gray-50 hover:bg-gray-100">
              <CreateWorkspaceModal
                key={`create_workspace`}
                afterCreate={(newWorkspace) => setChosenWorkspace(newWorkspace)}
              />
            </li>
          )}
        </ul>
      </Transition>
    </div>
  );
}
