import React from 'react';
import { BiUserPlus } from 'react-icons/bi';
import { IoIosConstruct, IoMdImages } from 'react-icons/io';
export default function EmptyState({
  onClick,
  icon = 'construct',
  heading = 'This is an Empty State',
  subHeading = 'And this is the text below the empty state giving us more information',
  buttonText = 'Action',
  buttonTestId = 'action-empty-state-button',
  hasButton = true,
}: {
  onClick?: () => void,
  icon?: 'construct' | 'user' | 'images',
  heading?: React.ReactNode,
  subHeading?: React.ReactNode,
  buttonText?: React.ReactNode,
  buttonTestId?: string,
  hasButton?: boolean,
}) {
  return (
    <div className="text-center py-8 px-4" data-testid="empty-state">
      <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gradient-to-t from-gray-300 to-gray-200 mb-4">
        {icon === 'construct' && <IoIosConstruct size={28} />}
        {icon === 'user' && <BiUserPlus size={28} />}
        {icon === 'images' && <IoMdImages size={28} />}
      </div>
      <h2 className="text-2xl text-gray-800 font-bold mb-2">{heading}</h2>
      <div className="mb-6">{subHeading}</div>
      {hasButton && (
        <button
          className="btn rounded bg-secondary hover:opacity-80 text-white"
          data-testid={buttonTestId}
          onClick={onClick}
        >
          <svg
            className="w-4 h-4 fill-current opacity-50 flex-shrink-0"
            viewBox="0 0 16 16"
          >
            <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
          </svg>
          <span className="ml-2">{buttonText}</span>
        </button>
      )}
    </div>
  );
}
