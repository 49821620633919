import React, { useState, useRef, useEffect } from 'react';
import {
  AiFillBug,
  AiFillPlusCircle,
  AiFillQuestionCircle,
} from 'react-icons/ai';
import { Link } from 'react-router-dom';
import useAuth from '../../auth/useAuth';
import Transition from '../../utils/Transition';

function Help() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { user } = useAuth();

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <div className="relative inline-flex ml-3">
      <button
        ref={trigger}
        className={`w-8 h-8 flex items-center justify-center bg-gray-100 hover:bg-gray-200 transition duration-150 rounded-full ${dropdownOpen && 'bg-gray-200'
          }`}
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <span className="sr-only">Need help?</span>
        <AiFillQuestionCircle size={20} />
      </button>

      <Transition
        className="origin-top-right z-10 absolute top-full right-0 min-w-44 bg-white border border-gray-200 py-1.5 rounded shadow-lg overflow-hidden mt-1"
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          <div className="text-xs font-semibold text-gray-400 uppercase pt-1.5 pb-2 px-4">
            Need help?
          </div>
          <ul>
            <li>
              <a
                className="font-medium text-sm text-secondary hover:text-tertiary flex items-center py-1 px-3"
                href="https://knowledge.sitemarker.com/"
                target="_blank"
                onClick={() => setDropdownOpen(!dropdownOpen)} rel="noreferrer"
              >
                <svg
                  className="w-3 h-3 fill-current text-secondary flex-shrink-0 mr-2"
                  viewBox="0 0 12 12"
                >
                  <rect y="3" width="12" height="9" rx="1" />
                  <path d="M2 0h8v2H2z" />
                </svg>
                <span>FAQ</span>
              </a>
            </li>
            <li className="hidden">
              <Link
                className="font-medium text-sm text-blue-500 hover:text-blue-600 flex items-center py-1 px-3"
                to="#0"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                <svg
                  className="w-3 h-3 fill-current text-blue-300 flex-shrink-0 mr-2"
                  viewBox="0 0 12 12"
                >
                  <path d="M10.5 0h-9A1.5 1.5 0 000 1.5v9A1.5 1.5 0 001.5 12h9a1.5 1.5 0 001.5-1.5v-9A1.5 1.5 0 0010.5 0zM10 7L8.207 5.207l-3 3-1.414-1.414 3-3L5 2h5v5z" />
                </svg>
                <span>Support Site</span>
              </Link>
            </li>
            <li>
              <a
                className="font-medium text-sm text-secondary hover:text-tertiary flex items-center py-1 px-3"
                href={`https://forms.clickup.com/36026297/f/12bdxt-1927/UGE59N9THXFXEHTF1C?Email=${user?.email}&Name=${user?.name}`}
                target="_blank"
                rel="noreferrer"
              >
                <AiFillPlusCircle className="mr-1" />
                <span>Request a Feature</span>
              </a>
            </li>
            <li>
              <a
                className="hidden font-medium text-sm text-secondary hover:text-tertiary flex items-center py-1 px-3"
                href={`https://forms.clickup.com/f/12bdxt-320/DDCWIZ3SX61CX88DQZ?Email=${user?.email}&Name=${user?.name}`}
                target="_blank"
                rel="noreferrer"
              >
                <AiFillBug className="mr-1" />
                <span>Submit Bug Report</span>
              </a>
            </li>
          </ul>
        </div>
      </Transition>
    </div>
  );
}

export default Help;
