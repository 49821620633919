import React, { useState, useEffect, useRef } from 'react';
import ErrorViewModel from '../../models/ErrorViewModel';
import ErrorView from '../utility/ErrorView';
import Loader from '../utility/Loader';
import workspacesApi from '../../api/workspaces';
import AccessCodeShareBody from '../access_codes/AccessCodeShareBody';

export default function WorkspaceShareForm({ user, onBack, onComplete }) {
  const [error, setError] = useState(false);

  const updateWorkspaceAllow = async ({
    target: { checked: allow_matching_custom_domain_signups },
  }) => {
    let response = await workspacesApi.updateWorkspace(
      user.default_workspace.objectId,
      { allow_matching_custom_domain_signups }
    );
  };

  return (
    <div className="max-w-md mx-auto">
      {error && (
        <div className="text-center">
          <ErrorView error={error} extraClass={'mb-4'} />
        </div>
      )}
      <h1 className="text-3xl text-gray-800 font-bold mb-4">
        Ready for others to join the team?
      </h1>
      <div className="py-5 px-3 mb-5 bg-gray-50 rounded-md shadow-sm">
        <AccessCodeShareBody workspace={user.default_workspace} shouldLoad />
      </div>
      {user.default_workspace?.custom_domain &&
        user.default_workspace?.owner_id === user.id && (
          <div className="flex items-center justify-between space-x-6 mb-8">
            <div>
              <div className="font-medium text-gray-800 text-sm mb-1">
                Let others join you?
              </div>
              <div className="text-xs">
                Allow anyone with an{' '}
                <span className="font-bold">
                  @{user.default_workspace?.custom_domain}
                </span>{' '}
                email address to join{' '}
                <span className="font-bold text-tertiary">
                  {user.default_workspace?.name}
                </span>
                . Not ready to add team-members? No worries, you can add them
                later!
              </div>
            </div>
            <div className="flex items-center">
              <div className="form-switch focus-within:outline-blue">
                <input
                  type="checkbox"
                  id="switch"
                  data-testid="input-custom-domains-switch"
                  className="sr-only"
                  onChange={updateWorkspaceAllow}
                  defaultChecked={
                    user.default_workspace.allow_matching_custom_domain_signups
                  }
                />
                <label className="bg-gray-400" htmlFor="switch">
                  <span
                    className="bg-white shadow-sm"
                    aria-hidden="true"
                  ></span>
                  <span className="sr-only">Switch label</span>
                </label>
              </div>
            </div>
          </div>
        )}
      <div className="flex items-center justify-between">
        <button
          className="text-sm underline hover:no-underline hidden"
          onClick={onBack}
        >
          &lt;- Back
        </button>
        <button
          className="btn rounded bg-tertiary hover:opacity-80 text-white ml-auto"
          onClick={onComplete}
        >
          Next{' '}
        </button>
      </div>
    </div>
  );
}
