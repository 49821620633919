import React, { useEffect } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import useApi from '../../hooks/useApi';
import notesApi from '../../api/notes';
import moment from 'moment-timezone';
import Loader from './Loader';
import { useParams } from 'react-router-dom';
import formattedDateString from '../../utility/formattedDateString';
export default function PhotoModalNote({ note_id }) {
  const {
    data: note,
    error,
    loading,
    request: loadNote,
  } = useApi(notesApi.getNote, null, true);
  const { share_id } = useParams();

  useEffect(() => {
    loadNote(note_id, { share_id });
  }, []);

  return loading ? (
    <div className="flex justify-center">
      <Loader margin="m-0" />
    </div>
  ) : (
    <div className="flex flex-grow border-b border-gray-900 mb-4 pb-3">
      <img
        className="object-cover rounded-full h-12 w-12"
        src={
          note.notetaker.profile_photos?.medium ||
          'https://bucketeer-d9937a0d-80e4-469a-91e2-6c86bd37d08f.s3.amazonaws.com/public/user_empty.png'
        }
      />
      <div className="pl-3">
        <p className="font-bold text-white">{note.notetaker.name}</p>
        {note.notable_version && (
          <p className="font-bold text-gray-100 text-sm flex items-center mb-1">
            {note.notable_version.event === 'create' && (
              <>
                <p className="text-gray-300">Created Pin</p>
                {note.notable_version.changeset.status && (
                  <FaArrowRight size={14} className="text-white mx-2" />
                )}
              </>
            )}
            {note.notable_version.changeset.status && (
              <p className="capitalize">
                Marked{' '}
                <em>
                  {note.notable_version.changeset.status?.[1]?.replace?.('_', ' ')}
                </em>
              </p>
            )}
          </p>
        )}
        <p className="text-gray-100 text-sm whitespace-pre-line">{note.text}</p>
        <div className="flex justify-between mt-2 text-gray-100 text-xs">
          {formattedDateString(note.created_at, 'LLL')}
        </div>
      </div>
    </div>
  );
}
