import Loader from '../utility/Loader';
export default function PinCountBadge({ count, loading }: {
    count: number,
    loading: boolean
}) {
    return <div className="flex items-center justify-center bg-black absolute z-20 bottom-10 font-semibold -inset-x-0 w-32 mx-auto text-gray-50 text-center rounded-md shadow text-xs py-1">
        {loading ? (
            <span>
                <Loader />
            </span>
        ) : (
            <span>{count} pins in area</span>
        )}
    </div>
}