import { PinType, Project } from "../../../data/models";

const usePinNoteRequirements = ({ project, pinType }: {
    project: Project | null;
    pinType: PinType | null;
}) => {

    const projectNoteRequirements = project?.settings?.pins?.notes;
    const projectNoteRequiredOnCreate = projectNoteRequirements?.required?.includes?.('create');
    const projectNoteAssetRequiredOnCreate = projectNoteRequirements?.assets?.required?.includes?.('create');
    const projectNoteRequiredOnUpdate = projectNoteRequirements?.required?.includes?.('update');
    const projectNoteAssetRequiredOnUpdate = projectNoteRequirements?.assets?.required?.includes?.('update');

    const pinTypeNoteRequirements = pinType?.settings?.pins?.notes || { required: [], assets: { required: [] } };
    const pinTypeNoteRequiredOnCreate = pinTypeNoteRequirements?.required?.includes?.('create');
    const pinTypeNoteAssetRequiredOnCreate = pinTypeNoteRequirements?.assets?.required?.includes?.('create');
    const pinTypeNoteRequiredOnUpdate = pinTypeNoteRequirements?.required?.includes?.('update');
    const pinTypeNoteAssetRequiredOnUpdate = pinTypeNoteRequirements?.assets?.required?.includes?.('update');

    return {
        noteRequiredOnCreate: projectNoteRequiredOnCreate || pinTypeNoteRequiredOnCreate,
        noteAssetRequiredOnCreate: pinTypeNoteAssetRequiredOnCreate || projectNoteAssetRequiredOnCreate,
        noteRequiredOnUpdate: projectNoteRequiredOnUpdate || pinTypeNoteRequiredOnUpdate,
        noteAssetRequiredOnUpdate: pinTypeNoteAssetRequiredOnUpdate || projectNoteAssetRequiredOnUpdate,
    };
};


export default usePinNoteRequirements;
