import React, { useEffect } from 'react';
import EntryTextInput from '../entries/EntryTextInput';
import EntryTextareaInput from '../entries/EntryTextareaInput';
import EntryDatetimeInput from '../entries/EntryDatetimeInput';
import EntryCheckboxes from '../entries/EntryCheckboxes';

const entryGroupCheckboxesComponents = {
  EntryTextInput: EntryTextInput,
  EntryTextareaInput: EntryTextareaInput,
  EntryDatetimeInput: EntryDatetimeInput,
  EntryCheckboxes: EntryCheckboxes,
};

export default function EntryGroupCheckboxes({
  entries,
  editing = true,
  onLoaded,
}) {
  useEffect(() => {
    onLoaded && onLoaded();
  }, []);

  return (
    <div className="border grid grid-cols-6 text-xxs">
      {entries.map((entry, index) => {
        const EntryCheckboxesComponent =
          entryGroupCheckboxesComponents[entry.component_name];
        return (
          <EntryCheckboxesComponent
            entry={entry}
            editing={editing}
            extraLabelClass={'col-span-3 md:col-span-2'}
            useBottomBorder={index !== entries.length - 1}
          />
        );
      })}
    </div>
  );
}
