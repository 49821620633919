import client from './client';
const endpoint = '/workspaces';

const getWorkspaces = (params = {}) => client.get(`${endpoint}`, params);
const getWorkspace = (id, params = {}) =>
  client.get(`${endpoint}/${id}`, params);
const getWorkspacePermissions = (id) =>
  client.get(`${endpoint}/${id}/permissions`);
const addWorkspace = (workspace) => client.post(`${endpoint}`, { workspace });
const updateWorkspace = (workspaceId, workspace) =>
  client.put(`${endpoint}/${workspaceId}`, { workspace });

export default {
  getWorkspaces,
  getWorkspace,
  getWorkspacePermissions,
  addWorkspace,
  updateWorkspace,
};
