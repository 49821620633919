import client from './client';

const endpoint = '/field_options';

const getFieldOptions = () => client.get(`${endpoint}`);
const getFieldFieldOptions = (fieldId, params = {}) => client.get(`/fields/${fieldId}${endpoint}`, params);
const getFieldOption = (id) => client.get(`${endpoint}/${id}`);
const createFieldOption = (fieldId, field_option) => client.post(`/fields/${fieldId}${endpoint}`, { field_option });
const updateFieldOption = (id, field_option) => client.put(`${endpoint}/${id}`, { field_option });
const archiveFieldOption = (id) => client.put(`${endpoint}/${id}/archive`);
const unarchiveFieldOption = (id) => client.put(`${endpoint}/${id}/unarchive`);
const destroyFieldOption = (id) => client.delete(`${endpoint}/${id}`);

export default {
    archiveFieldOption,
    getFieldOptions,
    getFieldFieldOptions,
    getFieldOption,
    createFieldOption,
    updateFieldOption,
    unarchiveFieldOption,
    destroyFieldOption,
};
