import client from './client';
const endpoint = '/images';

const getImages = (projectId, params = {}) =>
  client.get(`/projects/${projectId}${endpoint}`, params);
const getImage = (id, params = {}) => client.get(`${endpoint}/${id}`, params);
const addImage = (projectId, image) =>
  client.post(`/projects/${projectId}${endpoint}`, { image });
const updateImage = (imageId, image) =>
  client.put(`${endpoint}/${imageId}`, { image });
const deleteImage = (imageId) => client.delete(`${endpoint}/${imageId}`);

export default {
  getImages,
  getImage,
  addImage,
  updateImage,
  deleteImage,
};
