import { useState, useEffect, useRef } from 'react';
import { MAP_POSITION_EVENTS } from '../MapboxMapLayerNewRefactor';

const showPoints = false;
const rotationUnit = 'degrees';
const getDistance = (p1: PointConfig | Point, p2: PointConfig | Point) => Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
const getAngle = (p1: PointConfig | Point, p2: PointConfig | Point) => Math.atan2(p2.y - p1.y, p2.x - p1.x);
const getMidpoint = (p1: PointConfig | Point, p2: PointConfig | Point) => ({ x: (p1.x + p2.x) / 2, y: (p1.y + p2.y) / 2 });

type PointConfig = {
    x: number,
    y: number,
    lat: number,
    lng: number,
    className: string,
    size: number,
    layer: boolean
}

type Point = {
    x: number,
    y: number,
}

type Bounds = {
    top: number,
    left: number,
    right: number,
    bottom: number,
    width: number,
    height: number
}

type BoundingBox = {
    north: number,
    south: number,
    east: number,
    west: number
}

function addPoints({ points, color = "bg-red-600", container }: {
    points: PointConfig[],
    color?: string,
    container: HTMLElement
}) {
    for (const point of points) {
        const pointDiv = document.createElement('div');
        pointDiv.className = `${color} rounded-full absolute z-20 test-point`;
        pointDiv.style.left = `${point.x - 5}px`;
        pointDiv.style.top = `${point.y - 5}px`;
        pointDiv.style.height = '10px';
        pointDiv.style.width = '10px';
        container.appendChild(pointDiv);
    };
}

function rotatePoint({ center: { x: cx, y: cy }, point, rotation: angle }: {
    center: Point,
    point: PointConfig,
    rotation: number
}) {

    const rad = angle * (Math.PI / 180);

    // Translate point to the rectangle's coordinate system
    const translatedX = point.x - cx;
    const translatedY = point.y - cy;

    // Rotate the point around the origin (0,0) by the negative of the angle
    const rotatedX = translatedX * Math.cos(-rad) - translatedY * Math.sin(-rad);
    const rotatedY = translatedX * Math.sin(-rad) + translatedY * Math.cos(-rad);

    // Translate the point back
    const finalX = rotatedX + cx;
    const finalY = rotatedY + cy;

    // Translate point back
    return { ...point, x: finalX, y: finalY };
}

function isOnPointLayer({ point: { x, y }, layerBounds: { top, left, bottom, right }, rotation: angle = 0 }: {
    point: Point,
    layerBounds: Bounds,
    rotation: number
}) {
    // Calculate the center of the rectangle
    const cx = (left + right) / 2;
    const cy = (top + bottom) / 2;

    // Convert angle to radians
    const rad = angle * (Math.PI / 180);

    // Translate point to the rectangle's coordinate system
    const translatedX = x - cx;
    const translatedY = y - cy;

    // Rotate the point around the origin (0,0) by the negative of the angle
    const rotatedX = translatedX * Math.cos(-rad) - translatedY * Math.sin(-rad);
    const rotatedY = translatedX * Math.sin(-rad) + translatedY * Math.cos(-rad);

    // Translate the point back
    const finalX = rotatedX + cx;
    const finalY = rotatedY + cy;

    // Check if the point is within the non-rotated bounds
    return finalX >= left && finalX <= right && finalY >= top && finalY <= bottom;
}

function scaleLine(scaleFactor: number, point1: PointConfig, point2: PointConfig, center: Point) {
    // Calculate the direction vectors from the center to the points
    const direction1 = {
        x: point1.x - center.x,
        y: point1.y - center.y
    };

    const direction2 = {
        x: point2.x - center.x,
        y: point2.y - center.y
    };

    // Scale the direction vectors
    const scaledDirection1 = {
        x: direction1.x * scaleFactor,
        y: direction1.y * scaleFactor
    };

    const scaledDirection2 = {
        x: direction2.x * scaleFactor,
        y: direction2.y * scaleFactor
    };

    // Calculate the new points based on the scaled directions
    const newPoint1 = {
        ...point1,
        x: center.x + scaledDirection1.x,
        y: center.y + scaledDirection1.y
    };

    const newPoint2 = {
        ...point2,
        x: center.x + scaledDirection2.x,
        y: center.y + scaledDirection2.y
    };

    return [
        newPoint1,
        newPoint2
    ];
}

function setPosition({ map, layerBounds, layerPoints, mapPoints }: {
    map: any,
    layerBounds: Bounds,
    layerPoints: PointConfig[],
    mapPoints: PointConfig[]
}) {

    const centerX1 = layerBounds.left + layerBounds.width / 2;
    const centerY1 = layerBounds.top + layerBounds.height / 2;

    const layerMidPoint = getMidpoint(layerPoints[0], layerPoints[1]);
    const mapMidPoint = getMidpoint(mapPoints[0], mapPoints[1]);

    const translateX = mapMidPoint.x - layerMidPoint.x;
    const translateY = mapMidPoint.y - layerMidPoint.y;

    const repositionedPoints = layerPoints.map((point) => {
        const newX = point.x + translateX;
        const newY = point.y + translateY;
        const { lat, lng } = map.unproject([newX, newY]);

        return {
            ...point,
            lat,
            lng,
            x: newX,
            y: newY
        }
    });

    if (showPoints) addPoints({ points: repositionedPoints, color: "bg-indigo-600", container: map.getContainer() });


    const position = {
        x: centerX1 + translateX,
        y: centerY1 + translateY
    }

    return { position, repositionedPoints }

};

function setRotation({ map, layerBounds, layerPoints, mapPoints }: {
    map: any,
    layerBounds: Bounds,
    layerPoints: PointConfig[],
    mapPoints: PointConfig[]
}) {

    const center = {
        x: layerBounds.left + layerBounds.width / 2,
        y: layerBounds.top + layerBounds.height / 2
    }

    const layerAngle = getAngle(layerPoints[0], layerPoints[1]);
    const mapAngle = getAngle(mapPoints[0], mapPoints[1]);
    const rotation = rotationUnit === 'degrees' ? (mapAngle - layerAngle) * (180 / Math.PI) : (mapAngle - layerAngle);
    const rotatedPoints = layerPoints.map((point) => rotatePoint({ center, point, rotation: -rotation }));

    if (showPoints) addPoints({ points: rotatedPoints, color: "bg-blue-600", container: map.getContainer() });

    return { rotation, rotatedPoints };
}

function setScale({ map, layerBounds, layerPoints, mapPoints }: {
    map: any,
    layerBounds: Bounds,
    layerPoints: PointConfig[],
    mapPoints: PointConfig[]
}) {
    // Calculate the direction vectors from the center to the points
    const layerDistance = getDistance(layerPoints[0], layerPoints[1]);
    const mapDistance = getDistance(mapPoints[0], mapPoints[1]);

    const center = {
        x: layerBounds.left + layerBounds.width / 2,
        y: layerBounds.top + layerBounds.height / 2
    }

    const scale = mapDistance / layerDistance
    const scaledPoints = scaleLine(scale, layerPoints[0], layerPoints[1], center);

    if (showPoints) addPoints({ points: scaledPoints, container: map.getContainer() });

    return {
        scaledPoints,
        scale
    };
}

function scalePositionAndRotate({ map, layerBounds, layerPoints, mapPoints }: {
    map: any,
    layerBounds: Bounds,
    layerPoints: PointConfig[],
    mapPoints: PointConfig[]
}) {
    const { scale, scaledPoints } = setScale({ map, layerBounds, layerPoints, mapPoints });
    const { rotation, rotatedPoints } = setRotation({ map, layerBounds, layerPoints: scaledPoints, mapPoints });
    const { position, repositionedPoints: points } = setPosition({ map, layerBounds, layerPoints: rotatedPoints, mapPoints });

    return {
        position,
        scale,
        rotation,
        points
    }
}

const CanvasArrows = ({ aligned = false, anchorPoint = null, map }: {
    aligned: boolean,
    anchorPoint: PointConfig | null,
    map: any
}) => {
    const canvasRef = useRef(null);
    const [mousePos, setMousePos] = useState({ x: 0, y: 0, lat: 0, lng: 0 });
    const [canvasSize, setCanvasSize] = useState({ width: map.getCanvas().offsetWidth, height: map.getCanvas().offsetHeight });

    useEffect(() => {
        if (!canvasRef.current) return;

        const canvas: HTMLCanvasElement = canvasRef.current;
        const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');

        const drawArrow = (ctx: CanvasRenderingContext2D, fromX: number, fromY: number, toX: number, toY: number, arrowWidth: number, color: string) => {
            const layerDistance = getDistance({ x: fromX, y: fromY }, { x: toX, y: toY });
            const headLength = layerDistance < 15 ? 0 : 15; // Length of the arrowhead
            const angle = Math.atan2(toY - fromY, toX - fromX);
            // Calculate new end point with buffer
            const fromBuffer = 0, toBuffer = 10;
            const adjustedFromX = fromX + fromBuffer * Math.cos(angle);
            const adjustedFromY = fromY + fromBuffer * Math.sin(angle);
            // Calculate new end point with buffer
            const adjustedToX = toX - toBuffer * Math.cos(angle);
            const adjustedToY = toY - toBuffer * Math.sin(angle);

            ctx.beginPath();
            ctx.moveTo(adjustedFromX, adjustedFromY);
            ctx.lineTo(adjustedToX, adjustedToY);
            ctx.strokeStyle = color;
            ctx.lineWidth = arrowWidth;
            ctx.stroke();

            // Draw arrowhead
            ctx.beginPath();
            ctx.moveTo(adjustedToX, adjustedToY);
            ctx.lineTo(adjustedToX - headLength * Math.cos(angle - Math.PI / 6), adjustedToY - headLength * Math.sin(angle - Math.PI / 6));
            ctx.moveTo(adjustedToX, adjustedToY);
            ctx.lineTo(adjustedToX - headLength * Math.cos(angle + Math.PI / 6), adjustedToY - headLength * Math.sin(angle + Math.PI / 6));
            ctx.strokeStyle = color;
            ctx.lineWidth = arrowWidth + 1.5;
            ctx.stroke();
        };

        const handleMouseMove = ({ point: { x, y }, lngLat: { lat, lng } }: {
            point: Point,
            lngLat: { lat: number, lng: number }
        }) => {
            setMousePos({ x, y, lat, lng })
        };

        const handleDrag = () => {
            const { lat, lng } = mousePos;
            const { x, y } = map.project([mousePos.lng, mousePos.lat]);
            setMousePos({ x, y, lat, lng })
        };

        const updateCanvasSize = () => {
            setCanvasSize({ width: map.getCanvas().offsetWidth, height: map.getCanvas().offsetHeight });
        }

        map.on('resize', updateCanvasSize)
        map.on('drag', handleDrag)
        map.on('mousemove', handleMouseMove);

        if (ctx) ctx.clearRect(0, 0, canvas.width, canvas.height);

        if (!!anchorPoint && ctx) drawArrow(ctx, anchorPoint.x, anchorPoint.y, mousePos.x, mousePos.y, 2, '#D57A35');

        return () => {
            map.off('resize', updateCanvasSize)
            map.off('drag', handleDrag);
            map.off('mousemove', handleMouseMove);
        }

    }, [anchorPoint, canvasRef, map, mousePos]);

    const helperText = () => {
        if (aligned) return 'Click Done to finish alignment or start over';
        if (!anchorPoint) return 'Click on map or layer to add point';
        return anchorPoint.layer ? 'Click on map to add next point' : 'Click on layer to add point';
    }

    return <>
        <div className="absolute inset-x-0 justify-center flex pointer-events-none">
            <p className="text-white font-black mt-3 text-sm bg-black px-3 py-1 rounded-lg shadow">{helperText()}</p>
        </div>
        <canvas
            ref={canvasRef}
            width={canvasSize.width}
            height={canvasSize.height}
            data-testid="alignmentArrows"
            className="pointer-events-none w-full h-full absolute top-0 left-0 border-primary border-4 bg-primary bg-opacity-3"
        />
        <div className="absolute inset-x-0 justify-center flex bottom-5 pointer-events-none">
            <p className="text-black font-black text-xs mt-3 text-sm bg-gray-300 px-3 py-1 rounded-lg shadow">Click and hold to Pan map</p>
        </div>
    </>;
};

const PointAlignment = ({ aligned, point }: {
    aligned: boolean,
    point: PointConfig
}) => {
    const { x, y, className, size } = point;
    const finalClassName = !aligned ? `cursor-crosshair ${className}` : className;
    return (
        <div className={finalClassName} style={{
            top: `${y - size / 2}px`,
            left: `${x - size / 2}px`,
            width: `${size}px`,
            height: `${size}px`,
        }} />
    );
};

type PointStyleConfig = {
    size: number,
    layer: boolean,
    className: string
}

const layerDefaults: PointStyleConfig = {
    size: 12,
    layer: true,
    className: 'point-alignment border border-primary border-2 rounded-full absolute z-30 rounded-full'
}
const mapDefaults: PointStyleConfig = {
    size: 20,
    layer: false,
    className: 'point-alignment border border-primary border-3 rounded-full absolute z-30 bg-primary bg-opacity-20'
}

export default function useTwoPointAlignment({
    boundingBox,
    rotation: defaultRotation = 0,
    enabled = true,
    map,
    mapContainer,
    mapLayer,
    points,
    setPoints,
}: {
    boundingBox: BoundingBox,
    rotation: number,
    enabled: boolean,
    map: any,
    mapContainer: HTMLElement,
    mapLayer: HTMLElement,
    points: PointConfig[],
    setPoints: any
}) {

    const [{ position, rotation, scale }, setTransform] = useState<{ position: Point | null, rotation: number | null, scale: number | null }>({ position: null, rotation: null, scale: null });
    const [layerBounds, setLayerBounds] = useState<Bounds | null>(null);
    const mapPoints = points.filter((p) => !p.layer);
    const layerPoints = points.filter((p) => p.layer);

    const anchorPoint = () => {
        if (points.length === 1) return points[0];
        if (points.length === 3) return points[2];
        return null
    }

    useEffect(() => {
        if (enabled) return;
        setTransform({ position: null, rotation: null, scale: null });
        setPoints([])
    }, [enabled])

    useEffect(() => {
        if (!map || !boundingBox || !enabled) return;

        const updateLayerBounds = () => {
            const projectedNE = map.project([boundingBox.east, boundingBox.north]);
            const projectedSW = map.project([boundingBox.west, boundingBox.south]);
            const newLayerBounds = {
                top: projectedNE.y,
                left: projectedSW.x,
                right: projectedNE.x,
                bottom: projectedSW.y,
                width: Math.abs(projectedNE.x - projectedSW.x),
                height: Math.abs(projectedNE.y - projectedSW.y)
            }

            setLayerBounds(newLayerBounds)

            if (!!points.length) setPoints((ps: PointConfig[]) => ps.map((pointProps: PointConfig) => {
                const { x, y } = map.project([pointProps.lng, pointProps.lat]);
                return { ...pointProps, x, y };
            }));

        }

        updateLayerBounds();

        MAP_POSITION_EVENTS.forEach((event) => map.on(event, updateLayerBounds));

        return () => MAP_POSITION_EVENTS.forEach((event) => map.off(event, updateLayerBounds));

    }, [map, enabled, JSON.stringify(boundingBox), JSON.stringify(points)])

    useEffect(() => {
        if (!enabled || !map || !mapContainer || !mapLayer || !layerBounds) return;

        const addPoint = (e: any) => {
            const isOnLayer = isOnPointLayer({ point: e.point, layerBounds, rotation: defaultRotation });
            const isStartPoint = points.length === 0 || points.length === 2;
            const prevPoint = isStartPoint ? null : points[points.length - 1];
            const { lat, lng } = map.unproject([e.point.x, e.point.y]);

            if (layerPoints.length < 2 && isOnLayer && !prevPoint?.layer) {
                const newPoint: PointConfig = {
                    x: e.point.x,
                    y: e.point.y,
                    lat,
                    lng,
                    ...layerDefaults
                };
                setPoints((ps: PointConfig[]) => [...ps, newPoint]);
            }

            if (mapPoints.length < 2 && (!isOnLayer && (!prevPoint || prevPoint?.layer) || prevPoint?.layer)) {
                const newPoint: PointConfig = {
                    x: e.point.x,
                    y: e.point.y,
                    lat,
                    lng,
                    ...mapDefaults
                };
                setPoints((ps: PointConfig[]) => [...ps, newPoint]);
            }
        }

        const cursorUpdate = () => {
            map.getCanvas().style.cursor = !enabled || points.length === 4 ? 'pointer' : 'crosshair';
        }

        const cursorDragging = () => {
            map.getCanvas().style.cursor = 'grabbing';
        }

        map.on('dragstart', cursorDragging)
        map.on('dragend', () => cursorUpdate)

        map.on('click', addPoint);
        map.on('mousemove', cursorUpdate);

        cursorUpdate();

        return () => {
            map.off('dragstart', cursorDragging)
            map.off('dragend', () => cursorUpdate)
            map.off('click', addPoint);
            map.off('mousemove', cursorUpdate);
        }
    }, [defaultRotation, JSON.stringify(layerBounds), map, enabled, mapContainer, mapLayer, JSON.stringify(points)])

    useEffect(() => {
        if (points.length < 4 || !layerBounds || scale) return;

        const { scale: newScale, position: newPosition, rotation: newRotation, points: finalPoints } = scalePositionAndRotate({ map, layerBounds, layerPoints, mapPoints });

        setTransform({
            scale: newScale,
            position: newPosition,
            rotation: newRotation
        });

        setPoints([
            ...mapPoints,
            ...finalPoints
        ]);

    }, [scale, map, JSON.stringify(layerBounds), JSON.stringify(points)])

    return {
        position,
        rotation,
        scale,
        pointComponents: points.map((point) => <PointAlignment aligned={points.length === 4} point={point} />),
        canvas: enabled ? <CanvasArrows aligned={points.length === 4} anchorPoint={anchorPoint()} map={map} /> : null,
    };
}