import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'querystring';
import { FaCaretRight } from 'react-icons/fa';

function Breadcrumb({ ancestors = [], current, updateFolderId }) {
  const history = useHistory();
  const location = useLocation();

  function route(newFolderId) {
    history.push({
      pathname: location.pathname,
      search: qs.stringify({
        folder_id: newFolderId,
      }),
    });
    updateFolderId?.(newFolderId);
  }
  return (
    <div className="mt-4 mr-4 mb-4 text-lg inline-flex flex-wrap">
      {[...ancestors].reverse().map((ancestor, i) => (
        <div className="inline-flex items-center">
          <div
            data-testid={`test-ancestor-element-${i}`}
            className="text-gray-800 cursor-pointer hover:underline text-xl capitalize"
            onClick={() => {
              route(ancestor.objectId);
            }}
          >
            {ancestor.name.includes("Root") ? "Home" : ancestor.name}
          </div>
          <FaCaretRight size={24} className="mx-1" />
        </div>
      ))}
      <div className="text-gray-600 inline-flex justfiy-center items-center text-xl font-semibold capitalize">
        {current.includes("Root") ? "Home" : current}
      </div>
    </div>
  );
}

export default Breadcrumb;
