import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import PrintableReport from '../partials/reports/PrintableReport';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import reportsApi from '../api/reports';
import Loader from '../partials/utility/Loader';
import PinExportButtons from '../partials/reports/PinExportButtons';
import { BiShareAlt } from 'react-icons/bi';
import ShareModal from '../partials/shares/ShareModal';
import PublishReportModal from '../partials/reports/PublishReportModal';
import ReportAsset from '../partials/reports/ReportAsset';
import PaginationNumeric from '../partials/PaginationNumeric';
import DeleteReportModal from '../partials/reports/DeleteReportModal';
import useQuery from '../hooks/useQuery';
import {
  reportErrored,
  reportIsBuilding,
  reportIsDraft,
  reportIsPublished,
  reportIsPublishing,
  reportPDFIsCreating
} from '../partials/reports/ReportStatus';
import BackButton from '../partials/shared/BackButton';
import Progress from '../utils/Progress';
import ReportPageCountDisplay from './ReportPageCountDisplay';
import ReportCombinationStatus from './ReportCombinationStatus';
import useReportContext from '../partials/reports/useReportContext';
import useReport from '../partials/reports/hooks/useReport';

export default function Report({ mobile }) {

  const query = useQuery();
  const history = useHistory();

  const [viewingPDF, setViewingPDF] = useState(query.get('view') === 'pdf');
  const [deleted, setDeleted] = useState(false);
  const {
    workspace_id,
    project_id,
    report_id,
    shareable_id,
    share_id
  } = useParams();

  const {
    editing,
    setEditing,
  } = useReportContext();

  const [searchParams, setSearchParams] = useState({ page: 1, items: 5 });

  const {
    latestReport: report,
    reportPages,
    loadingPages,
    pagy,
    loading,
    error,
    updating,
    updateReport
  } = useReport(report_id || shareable_id, {
    share_id,
    reportPageParams: searchParams,
    listenForUpdates: true,
  });

  const componentRef = useRef(null);

  const publishReport = (tempSharedWith = []) => {
    updateReport({
      published: true,
      pdf_status: 'queue_creation',
      shares_attributes: tempSharedWith,
    });
  };

  useEffect(() => {
    if (!report) return;
    setViewingPDF(!report.permissions?.can?.('update') && report.asset);
  }, [report?.pdf_status]);

  useEffect(() => {
    if (!report) return;
    setEditing(reportIsDraft(report) && report.permissions?.can?.('update'));
  }, [report?.pdf_status]);

  useEffect(() => {
    query.set('view', viewingPDF ? 'pdf' : 'report');
    history.replace({
      search: query.toString(),
    });
  }, [viewingPDF]);

  const printableReport = useMemo(
    () => (<PrintableReport
      key={`${reportPages?.map?.((p) => p.updated_at).join(',')}`}
      ref={componentRef}
      report={report}
      pages={reportPages}
    />
    ),
    [report?.objectId, reportPages?.map?.((p) => p.updated_at).join(',')]
  );

  if (deleted)
    return (
      <Redirect
        to={{
          pathname: `/${workspace_id}/projects/${project_id}/reports`,
          state: { success: 'Report successfully deleted' },
        }}
      />
    );

  if (error && error.text() === "Couldn't find Report")
    return (
      <Redirect
        to={{
          pathname: `/${workspace_id}/projects/${project_id}/reports`,
          state: { error: error.text() },
        }}
      />
    );

  return (
    <>
      {loading && !report ? (
        <ReportLoader />
      ) : (
        <>
          <ReportHeaderContainer mobile={mobile}>
            {!reportIsBuilding(report) && (
              <ReportActionsContainer mobile={mobile}>
                <ReportActionsSection>
                  {!shareable_id && !mobile && <BackButton />}
                  {reportIsDraft(report) && (
                    <DraftBadge />
                  )}
                  {reportIsPublished(report) && (
                    <PublishedBadge />
                  )}
                  {reportIsPublishing(report) && (
                    <PublishingBadge />
                  )}
                  {reportErrored(report) && (
                    <ReportActionsSection>
                      <ErrorBadge />{' '}
                      <RetryPublishButton
                        onClick={() => publishReport([])}
                      />
                    </ReportActionsSection>
                  )}
                </ReportActionsSection>
                <ReportActionsSection>
                  {!mobile && reportIsPublishing(report) && !reportPDFIsCreating(report) && (
                    <>
                      <ReportPageCountDisplay
                        shownCreatedPagesCount={report.shown_created_pages_count}
                        totalPageCount={pagy.count}
                      />
                    </>
                  )}
                  {!mobile && reportPDFIsCreating(report) && (
                    <>
                      <ReportCombinationStatus
                        label="Pages created"
                        currentCount={report.shown_created_pages_count}
                        totalCount={pagy.count}
                      />
                    </>
                  )}
                </ReportActionsSection>
                <ReportActionsSection>
                  {report?.permissions?.can?.('read') &&
                    !reportIsDraft(report) &&
                    !reportIsBuilding(report) && !mobile && (
                      <ShareModal
                        key={`share_report_${report.objectId}`}
                        button={
                          <button className="flex items-center rounded-md border border-gray-200 text-secondary py-1 font-semibold px-3 shadow-sm hover:opacity-80 cursor-pointer focus:outline-none mr-2">
                            <BiShareAlt className="mr-1" /> Share
                          </button>
                        }
                        data={report}
                        shareableApi={reportsApi.updateReport}
                      />
                    )}
                  {report?.report_type.name.includes('Tree') && (
                    <PinExportButtons
                      project={report.project}
                      searchParams={{}}
                      containerClass=""
                      buttonClass="flex items-center rounded-md border border-gray-200 text-secondary py-1 font-semibold px-3 shadow-sm hover:opacity-80 cursor-pointer focus:outline-none mr-2"
                      buttonText="Export Pins"
                    />
                  )}
                  {userCanPublishOrDelete(report) && !mobile && (
                    <>
                      <DeleteReportModal
                        onReport={true}
                        report={report}
                        onDeleted={() => setDeleted(true)}
                      />
                      <PublishReportModal
                        report={report}
                        publishing={updating}
                        onPublish={publishReport}
                      />
                    </>
                  )}
                  {/* TODO allow report editing */}
                  {report.asset && (
                    <button
                      className="flex items-center border border-gray-200 rounded-md text-white bg-white text-secondary py-1 font-semibold px-3 shadow-sm hover:opacity-80 cursor-pointer focus:outline-none"
                      onClick={() => setViewingPDF(!viewingPDF)}
                    >
                      {' '}
                      View {viewingPDF ? 'Interactive Report' : 'PDF'}{' '}
                    </button>
                  )}
                </ReportActionsSection>
              </ReportActionsContainer>
            )}
            {mobile && reportIsPublishing(report) && !reportPDFIsCreating(report) && (
              <div className="w-full bg-white border-b py-2 px-3 z-40">
                <ReportPageCountDisplay
                  shownCreatedPagesCount={report.shown_created_pages_count}
                  totalPageCount={pagy.count}
                />
              </div>
            )}
            {mobile && reportPDFIsCreating(report) && (
              <div className="w-full bg-white border-b py-2 px-3 z-40">
                <ReportCombinationStatus
                  label="Pages created"
                  currentCount={report.shown_created_pages_count}
                  totalCount={pagy.count}
                />
              </div>
            )}
            {reportIsPublishing(report) && !reportPDFIsCreating(report) && (
              <div className="w-full bg-white border-b py-2 px-3 z-40">
                <Progress current={report.shown_created_pages_count} total={pagy.count} />
              </div>
            )}
            {reportPDFIsCreating(report) && (
              <div className="w-full bg-white border-b py-2 px-3 z-40">
                <Progress current={report.combined_pages_count ?? 0} total={pagy.count} />
              </div>
            )}
            {report.has_entries_requiring_confirmation && editing && (
              <EntryConfirmationCard />
            )}
            {!viewingPDF && !!pagy.last && !reportIsBuilding(report) && (
              <div className="shadow-sm w-full bg-white flex items-center justify-between border-b py-2 px-3 z-40">
                <p className="font-normal text-sm ml-3">
                  {!mobile && <span className="hidden sm:inline">Showing of{' '}</span>}
                  {pagy.last === 1 ? (
                    <span className="font-semibold">{pagy.count}</span>
                  ) : (
                    <>
                      <span className="font-semibold">{pagy.from}</span> to{' '}
                      <span className="font-semibold">{pagy.to}</span>
                    </>
                  )}{' '}
                  of <span className="font-semibold">{pagy.count}</span> pages
                </p>
                <PaginationNumeric
                  pagy={pagy}
                  showPages={!mobile}
                  showEnds={!mobile}
                  onPageChosen={(newPage) =>
                    setSearchParams({ ...searchParams, page: newPage })
                  }
                  onItemsChosen={(newItems) =>
                    setSearchParams({ ...searchParams, items: newItems })
                  }
                />
              </div>
            )}
          </ReportHeaderContainer>
          {!viewingPDF && (
            <>
              {loadingPages || report.pdf_status === 'building_pages' ? (
                <BuildingPagesLoader building={report.pdf_status === 'building_pages'} />
              ) : (
                printableReport
              )}
            </>
          )}
          {viewingPDF && <ReportAsset url={report.asset?.files?.original} />}
        </>
      )}
    </>
  );
}

const userCanPublishOrDelete = (report) =>
  reportIsDraft(report) && report?.permissions?.can?.('update');

const ReportLoader = () => <div className="w-full flex py-5 items-center justify-center">
  <Loader color={'text-gray-700'} margin={''} />
</div>

const ReportHeaderContainer = ({ mobile, children }) => {
  if (mobile) return <div className="sticky top-0 z-40">
    {children}
  </div>

  return <>
    {children}
  </>
}

const ReportActionsContainer = ({ mobile, children }) => <div className="shadow-sm bg-white flex items-center justify-between border-b py-2 px-3 text-sm">
  {children}
</div>

const ReportActionsSection = ({ children }) => <div className="flex">
  {children}
</div>

const DraftBadge = () => <div className="text-sm w-28 font-semibold bg-gray-200 sm:bg-gray-100 text-gray-800 text-center px-2 py-1 rounded-md">
  Draft
</div>

const PublishedBadge = () => <div className="text-sm font-semibold bg-green-400 text-white w-28 text-center px-2 py-1 rounded-md">
  Published
</div>

const PublishingBadge = () => <div className="text-sm font-semibold bg-gray-200 text-gray-800 w-28 flex text-center px-2 py-1 rounded-md">
  Publishing <Loader color={'text-gray-800'} />{' '}
</div>

const ErrorBadge = () => <div className="text-sm font-semibold bg-red-500 text-white w-36 text-center px-2 py-1 rounded-md">
  Error Publishing
</div>

const RetryPublishButton = ({ onClick }) => <button
  className="cursor-pointer text-secondary hover:shadow-sm font-semibold px-2 border rounded-md ml-2 focus:outline-none"
  onClick={onClick}
>
  Retry Publish
</button>

const EntryConfirmationCard = () => <div className="shadow-sm w-full bg-white flex items-center justify-center border-b py-2 px-3">
  <p className="text-center font-medium">
    Please confirm <span className="italic">and/or</span> complete{' '}
    <span className="bg-yellow-100 font-semibold">
      highlighted fields
    </span>{' '}
    below
  </p>
</div>

const BuildingPagesLoader = ({ building }) => <div className="w-full flex flex-col py-5 items-center justify-center">
  {building && (
    <p className="font-bold mb-3">Building Pages...</p>
  )}
  <Loader color={'text-gray-700'} margin={''} />
</div>
