import client from './client';
const endpoint = '/downloads';

const getProjectDownloads = (projectId: string, params = {}) =>
    client.get(`projects/${projectId}${endpoint}`, params);

const createProjectDownload = (projectId: string, params = {}) =>
    client.post(`projects/${projectId}${endpoint}`, params);

const deleteDownload = (downloadId: string) =>
    client.delete(`${endpoint}/${downloadId}`);

export default {
    getProjectDownloads,
    createProjectDownload,
    deleteDownload,
}