import { useState, useEffect } from 'react';
import { FieldOption } from '../../data/models';
import Modal, { ModalBody, ModalHeader } from '../utility/Modal';
import Form from '../../utils/Form';
import Button from '../shared/Button';
import useFieldOption from './hooks/useFieldOption';
export default function FieldOptionEditModal({
    fieldOption,
    afterUpdate,
    onClose,
}: {
    fieldOption: FieldOption | null,
    afterUpdate?: () => void;
    onClose: () => void;
}) {

    const { saveFieldOption, archiveFieldOption, archiving, archived, archivedFieldOption, updating, updated, data } = useFieldOption(fieldOption?.id);

    const [showColorPicker, setShowColorPicker] = useState(false);
    const [updatedFieldOption, setUpdatedFieldOption] = useState<FieldOption | null>(null);

    const _saveFieldOption = () => {
        if (!updatedFieldOption) return;
        saveFieldOption(updatedFieldOption);
    }

    const updateFieldOption = (key: string, value: any) => {
        if (!updatedFieldOption) return;
        setUpdatedFieldOption({ ...updatedFieldOption, [key]: value })
    }

    useEffect(() => {
        if (!fieldOption) return;
        setUpdatedFieldOption(fieldOption)
    }, [fieldOption])

    useEffect(() => {
        if (!updated && !archived) return;
        afterUpdate?.();
    }, [updated, archived])

    return <Modal isOpen={!!fieldOption} onClose={onClose} side
        maxHeight="h-full"
        maxWidth="lg:w-[352px] xl:w-[460px] 2xl:w-[calc(460px+80px)]"
        dialogPadding="px-0"
        modalClass="rounded-0"
    >
        <ModalHeader title="Edit Field Option" onClose={onClose} />
        <ModalBody>
            {updatedFieldOption && <>
                <Form.Label>Name</Form.Label>
                <Form.InputGroup>
                    <Form.Input
                        value={updatedFieldOption.name}
                        onChange={({ target: { value } }) => updateFieldOption('name', value)}
                        type="text"
                    />
                </Form.InputGroup>
                <Form.Label>Description</Form.Label>
                <Form.Group>
                    <Form.TextArea
                        value={updatedFieldOption.description}
                        onChange={({ target: { value } }) => updateFieldOption('description', value)}
                    />
                </Form.Group>
                <Form.Label>Color</Form.Label>
                <Form.InputGroup overflow='visible'>
                    <span className="border shadow-sm inset-0 right-auto absolute w-5 h-5 rounded-full mt-2" style={{
                        backgroundColor: updatedFieldOption.color || '#000',
                    }} />
                    <Form.Input
                        pl='pl-7'
                        type="text"
                        value={updatedFieldOption.color || ''}
                        onChange={({ target: { value } }) => updateFieldOption('color', value)}
                        onFocus={() => setShowColorPicker(true)}
                    />
                    {showColorPicker && <Form.ColorPicker
                        color={updatedFieldOption.color || ''}
                        onDone={() => setShowColorPicker(false)}
                        onChange={(values: any) => updateFieldOption('color', values.hex)}
                    />}
                </Form.InputGroup>
                <Form.Group className='flex justify-between'>
                    <Button color='secondary' text="Archive" savedText='Archived' saved={archived} saving={archiving} onClick={() => archiveFieldOption()} />
                    <Button color='success' text="Save" savedText='Saved' saved={updated} saving={updating} onClick={_saveFieldOption} />
                </Form.Group>
            </>}
        </ModalBody>
    </Modal>
}