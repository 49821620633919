import moment from 'moment-timezone';
import { FaFlag } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Button from '../shared/Button';
import { BsGlobe, BsPhoneFill } from 'react-icons/bs';
import {
  BiCheckCircle,
  BiCircle,
  BiLock,
  BiMailSend,
  BiTimer,
  BiTrashAlt,
} from 'react-icons/bi';
import Tooltip from '../shared/Tooltip';
import SendResetPasswordModal from './SendResetPasswordModal';
import { useState } from 'react';
import ConfirmEmailModal from './ConfirmEmailModal';
import EditFeatureFlagsModal from './EditFeatureFlagsModal';
import UserActivityModal from './UserActivityModal';
import DestroyUserModal from './DestroyUserModal';
import formattedDateString from '../../utility/formattedDateString';

const featureColors = [
  'text-primary mr-1',
  'text-secondary mr-1',
  'text-tertiary-500 mr-1',
  'text-red-500 mr-1',
];


function UsersTableItem({ user: passedUser, fav, handleClick, isChecked }) {
  const [user, setUser] = useState(passedUser);
  const [showActivity, setShowActivity] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [showConfirmEmail, setShowConfirmEmail] = useState(false);
  const [showDestroyUser, setShowDestroyUser] = useState(false);
  const [showFeatureFlags, setShowFeatureFlags] = useState(false);

  if (!user) return null;

  return (
    <>
      <tr>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px hidden">
          <div className="flex items-center">
            <label className="inline-flex">
              <span className="sr-only">Select</span>
              <input
                id={user.id}
                className="form-checkbox"
                type="checkbox"
                onChange={handleClick}
                checked={isChecked}
              />
            </label>
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px hidden">
          <div className="flex items-center relative">
            <button>
              <svg
                className={`w-4 h-4 flex-shrink-0 fill-current ${fav ? 'text-yellow-500' : 'text-gray-300'
                  }`}
                viewBox="0 0 16 16"
              >
                <path d="M8 0L6 5.934H0l4.89 3.954L2.968 16 8 12.223 13.032 16 11.11 9.888 16 5.934h-6L8 0z" />
              </svg>
            </button>
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="flex items-center">
            <UserPhoto
              user={user}
              onClick={() => setShowActivity(true)}
            />
            <UserInfo
              user={user}
            >
              <EmailConfirmationBadge
                confirmed={user.confirmed}
                onClick={() => setShowConfirmEmail(true)}
              />
              <PasswordResetBadge
                onClick={setShowResetPassword}
                sent={user.reset_password_sent_at}
                expired={user.reset_password_expired}
              />
              <span>
                <BiTrashAlt
                  title="Destroy user"
                  className="ml-1 text-red-600 cursor-pointer"
                  onClick={() => setShowDestroyUser(true)}
                />
              </span>
            </UserInfo>
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-left text-sm flex items-center">
            <span
              title={`Signed up via ${user.signed_up_on === 'mobile' ? 'Mobile' : 'Web'
                }`}
              className="mr-1"
            >
              {user.signed_up_on === 'mobile' ? <BsPhoneFill /> : <BsGlobe />}{' '}
            </span>{' '}
            {formattedDateString(user.created_at, 'LLL')}
          </div>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-left">
            {user.last_sign_in_at
              ? formattedDateString(user.last_sign_in_at, 'LLL')
              : 'N/A'}
          </div>
        </td>
        <td className="hidden px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-left">{user.signed_up_on || 'N/A'}</div>
        </td>
        <td className="hidden px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div className="text-left">{user.title}</div>
          <div className="text-left">{user.phone}</div>
        </td>
        <td className="hidden px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <Link to={`/${user.id}/projects`}>
            <div className="text-center">{user.projects_count}</div>
          </Link>
        </td>
        <td className="hidden px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <Link to={`/${user.id}/team`}>
            <div className="text-left font-medium text-light-blue-500">
              {user.workspaceMembersCount}
            </div>
          </Link>
        </td>
        <td className="hidden px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <Link to={`/${user.id}/projects`}>
            <div className="text-left font-medium text-green-500">
              {user.pinsCount}
            </div>
          </Link>
        </td>
        <td className="hidden px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <Link to={`/${user.id}/reports`}>
            <div className="text-center">{user.reportsCount}</div>
          </Link>
        </td>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <div
            className="text-center flex cursor-pointer"
            onClick={() => setShowFeatureFlags(true)}
            title={user.feature_flags}
          >
            {user.feature_flags?.slice(0, 4).map((_, index) => (
              <FaFlag className={featureColors[index]} />
            ))}
          </div>
        </td>
        <td className="px-2 hidden first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
          {/* Menu button */}
          <Button text="Edit" color="light" />
        </td>
      </tr >
      <EditFeatureFlagsModal
        open={showFeatureFlags}
        userObjectId={user.objectId}
        featureFlags={user.feature_flags}
        onSaved={setUser}
        onClose={() => setShowFeatureFlags(false)}
      />
      <SendResetPasswordModal
        userObjectId={user.objectId}
        email={user.email}
        sent={user.reset_password_sent_at}
        expired={user.reset_password_expired}
        open={showResetPassword}
        onSaved={setUser}
        onClose={() => setShowResetPassword(false)}
      />
      <DestroyUserModal
        userObjectId={user.objectId}
        email={user.email}
        open={showDestroyUser}
        onDestroyed={() => setUser(null)}
        onClose={() => setShowDestroyUser(false)}
      />
      <ConfirmEmailModal
        userObjectId={user.objectId}
        email={user.email}
        open={showConfirmEmail}
        onSaved={setUser}
        onClose={() => setShowConfirmEmail(false)}
      />
      <UserActivityModal
        user={user}
        open={showActivity}
        onClose={() => setShowActivity(false)}
      />
    </>
  );
}

const UserPhoto = ({ user, onClick = () => { } }) => <div
  className="w-10 h-10 flex-shrink-0 mr-2 sm:mr-3 cursor-pointer"
  onClick={onClick}
>
  <img
    className="rounded-full w-10 h-10 object-cover"
    src={
      user.profile_photos?.large ||
      'https://bucketeer-d9937a0d-80e4-469a-91e2-6c86bd37d08f.s3.amazonaws.com/public/user_empty.png'
    }
    alt={user.name}
  />
</div>

const UserInfo = ({ user, children = null }) => <div
  className="font-medium text-gray-800 w-72 overflow-x-hidden overflow-ellipsis"
  title={user.name}
>
  <div className="mb-0 text-sm">{user.name}</div>
  <div className="flex items-center">
    <a
      className="font-normal text-sm text-blue-800 mr-1"
      href={`mailto:${user.email}`}
    >
      {user.email}
    </a>
    {children}
  </div>
  <div className="text-xs font-normal text-green-800">
    <a href={`tel:+${user.phone}`}>{user.phone}</a>
  </div>
</div>

export {
  UserPhoto,
  UserInfo
}

const PasswordResetBadge = ({ sent, expired, onClick }) => {
  if (!sent)
    return (
      <span
        title={'No reset password email sent'}
        className="cursor-pointer"
        onClick={onClick}
      >
        <BiLock className="ml-1 text-gray-600 rounded-full" />
      </span>
    );

  return (
    <span
      title={
        expired ? 'Reset password email expired' : 'Reset password email sent'
      }
      className="cursor-pointer"
      onClick={onClick}
    >
      {expired ? (
        <BiTimer className="ml-1 text-red-600 bg-red-50 rounded-full" />
      ) : (
        <BiMailSend className="ml-1 text-blue-600 bg-blue-50 rounded-full" />
      )}
    </span>
  );
};

const EmailConfirmationBadge = ({ confirmed, onClick }) =>
  confirmed ? (
    <Tooltip
      position={'left'}
      icon={
        <BiCheckCircle className="text-green-600 bg-green-50 rounded-full" />
      }
    >
      Email confirmed
    </Tooltip>
  ) : (
    <Tooltip
      position={'left'}
      icon={
        <BiCircle
          onClick={onClick}
          className="cursor-pointer bg-gray-100 rounded-full"
        />
      }
    >
      Email <em>unconfirmed</em>
    </Tooltip>
  );

export default UsersTableItem;
