import client from './client';
const endpoint = '/pins';

const getPins = (parentType = 'project', parentId, params = {}) =>
  client.get(`/${parentType}s/${parentId}${endpoint}`, params);
const getPinFromReport = (reportId, id, params = {}) =>
  client.get(`/reports/${reportId}${endpoint}/${id}`, params);
const getPin = (id, params = {}) => client.get(`${endpoint}/${id}`, params);
const addPin = (projectId, pin) =>
  client.post(`/projects/${projectId}${endpoint}`, { pin });
const bulkAddPin = (projectId, pins) =>
  client.post(`/projects/${projectId}${endpoint}/bulk_create`, { pins });
const updatePin = (pinId, pin) => client.put(`${endpoint}/${pinId}`, { pin });
const deletePin = (pinId) => client.delete(`${endpoint}/${pinId}`);

export default {
  getPins,
  getPin,
  getPinFromReport,
  addPin,
  bulkAddPin,
  updatePin,
  deletePin,
};
