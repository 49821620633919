import React, { memo, useEffect, useRef, useState } from 'react';
import { FiX } from 'react-icons/fi';
import ErrorViewModel from '../../models/ErrorViewModel';
function ErrorView({
  error,
  dismissable = false,
  extraClass = '',
  className = '',
  preface = <span className="font-bold">Error!</span>,
  ...rest
}) {
  const [dismissed, setDismissed] = useState(false);
  const dismissTimer = useRef();
  const dismissAlert = () => {
    if (dismissTimer.current) clearTimeout(dismissTimer.current);
    if (dismissed) return;
    setDismissed(true);
  };

  useEffect(() => {
    if (!dismissable) return;
    dismissTimer.current = setTimeout(dismissAlert, 4000);
  }, [dismissable]);

  return dismissed ? (
    <span />
  ) : (
    <div
      data-testid="errorView"
      className={
        className
          ? `${className} ${dismissable && 'cursor-pointer hover:opacity-90'}`
          : `${extraClass} mx-2 bg-red-200 text-red-900 px-3 py-2 rounded text-sm z-60 ${dismissable && 'cursor-pointer hover:opacity-90'
          }`
      }
      onClick={dismissable && dismissAlert}
      {...rest}
    >
      {preface} {error instanceof ErrorViewModel ? error.text() : error}
      {dismissable && (
        <button
          href="#"
          className="absolute top-0 right-0 cursor-pointer hover:opacity-70 rounded-full p-3"
          onClick={dismissAlert}
        >
          <FiX className="text-white" />
        </button>
      )}
    </div>
  );
}

export default memo(ErrorView);
