import { useState, useLayoutEffect } from 'react';
import Modal, { ModalBody, ModalHeader } from '../utility/Modal';
import { FaExclamationCircle } from 'react-icons/fa';
import useFeatures from '../../hooks/useFeatures';
import useAnalytics from '../../hooks/useAnalytics';

const AutoOpenModal = () => {
  const [open, setOpen] = useState(false);
  const { track } = useAnalytics();

  useLayoutEffect(() => {
    track('Account Locked Modal Shown');
    setOpen(true);
  }, []);

  return (
    <Modal isOpen={open}>
      <ModalHeader title="Account Locked" />
      <ModalBody>
        <p className="flex flex-col items-center justify-center mb-4">
          <FaExclamationCircle className="text-6xl text-yellow-500" />
          <p className="text-2xl font-bold">Uh oh!</p>
        </p>
        <p className="mb-1 bg-gray-100 px-2 py-4 rounded-md shadow-sm border text-center">
          Looks like your account has been <strong>locked.</strong> <br />
          It is you? Is it us? <em>Let's have a chat.</em> <br /> <br />
          <span className="font-medium">
            Email us at{' '}
            <a
              href="mailto:landon@sitemarker.com"
              className="text-blue-600 mt-2"
              onClick={() => track('Account Locked Email Clicked')}
            >
              landon@sitemarker.com
            </a>{' '}
            <br />
            or call{' '}
            <a
              href="tel:+18439943691"
              className="text-blue-600"
              onClick={() => track('Account Locked Phone Call Clicked')}
            >
              (843) 994-3691
            </a>
          </span>
        </p>
      </ModalBody>
    </Modal>
  );
};
export default function AccountOverdueModal() {
  const { features: { rollout: { account_locked = false } = {} } = {} } =
    useFeatures();

  return account_locked ? <AutoOpenModal /> : null;
}
