import assetsApi from '../api/assets';
import SparkMD5 from 'spark-md5';

const getSignature = async (file) => {
  let data = await getInfoFromFile(file);
  let response = await assetsApi.getSignature(data);
  return response.data;
};

const readFile = (file) => {
  return new Promise((resolve, reject) => {
    var fr = new FileReader();
    fr.onload = () => {
      resolve(fr.result);
    };
    fr.onerror = reject;
    fr.readAsArrayBuffer(file);
  });
};

const getMD5HashFromFile = async (file) => {
  let buffer = await readFile(file);
  let md5 = SparkMD5.ArrayBuffer.hash(buffer),
    byte_size = file.size,
    filename = file.name
      .substring(file.name.lastIndexOf('/') + 1)
      .split('.')[0],
    content_type = file.type;
  return { md5, byte_size, filename, content_type };
};

const getInfoFromFile = async (file) => {
  const { md5, byte_size, filename, content_type } = await getMD5HashFromFile(
    file
  );
  const checksum = Buffer.from(md5, 'hex').toString('base64');
  return {
    checksum,
    byte_size,
    filename,
    content_type,
  };
};

const srcToFile = async (data, fileName, mimeType) => {
  let fetchedData = await fetch(data);
  let buffer = await fetchedData.arrayBuffer();
  let file = new File([buffer], fileName, { type: mimeType });
  return file;
};

const uploadSource = async (data, fileName, mimeType) => {
  let newFile = await srcToFile(data, fileName, mimeType);
  let signed_id = await uploadAttachment(newFile);
  return signed_id;
};

const uploadAttachment = async (file, retries = 3) => {
  const signature = await getSignature(file);
  const { url, headers } = signature.direct_upload;
  let status = 500,
    body = null;
  while (retries > 0 && status !== 200) {
    let {
      status: newStatus,
      headers: responseHeaders,
      body: newBody,
    } = await fetch(url, {
      method: 'PUT',
      body: file,
      mode: 'cors',
      headers,
    });
    status = newStatus;
    body = newBody;
    if (status === 200) return signature.signed_id;
    else retries -= 1;
  }
  throw body;
};

export {
  getSignature,
  getInfoFromFile,
  uploadAttachment,
  uploadSource,
  getMD5HashFromFile,
};
