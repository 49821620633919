import React, { useRef } from 'react';
import html2pdf from 'html2pdf.js';
// import { PDFViewer } from '@react-pdf/renderer';
// import MyDocument from '../partials/reports/MyDocument';
export default function ReportDownload() {
  const pdfRef = useRef(null);
  const worker = html2pdf();

  const downloadPdf = () => {
    let element = document.getElementById('pdf-to-download');
    console.log('element', element);
    console.log('pdfRef', pdfRef.current);
    var worker = html2pdf().from(pdfRef.current).save();
  };

  return (
    <div id="pdf-to-download" ref={pdfRef} onClick={downloadPdf}>
      Bla Bla
    </div>
  );
}
