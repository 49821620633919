import { useEffect, useRef, useState } from 'react';
import { BiCheckCircle } from 'react-icons/bi';
import { useParams } from 'react-router-dom';
import ctLogo from '../../images/logos/ct-logo.jpg';
import drgLogo from '../../images/logos/drg-logo.webp';
import logo from '../../images/logos/logo-black.svg';
import sbLogo from '../../images/logos/sb-logo.jpg';
import jwcLogo from '../../images/logos/jwc-logo.png';
import ppsLogo from '../../images/logos/pps-logo.jpg';
import choateLogo from '../../images/logos/choate-logo.png';
import seamonWhitesideLogo from '../../images/logos/sw-logo.png';
import matthewsLogo from '../../images/logos/matthews-logo.png';
import Loader from '../utility/Loader';
import useReportContext from './useReportContext';
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa';
import { NewPhoto, useRetry } from '../entries/EntryPin';
import { Report } from '../../data/models';
import useReport from './hooks/useReport';
import urlIsGenerating from '../../utility/urlIsGenerating';

const ctConfig = {
  logo: ctLogo,
  className: 'h-7',
};

const sbConfig = {
  logo: sbLogo,
  className: 'h-12',
};

const jwcConfig = {
  logo: jwcLogo,
  className: 'h-12',
};

const choateConfig = {
  logo: choateLogo,
  className: 'h-10',
};

const drgConfig = {
  logo: drgLogo,
  className: 'h-12',
};

const ppsConfig = {
  logo: ppsLogo,
  className: 'h-12',
};

const seamonWhitesideConfig = {
  logo: seamonWhitesideLogo,
  className: 'h-4',
};

const matthewsConfig = {
  logo: matthewsLogo,
  className: 'h-8',
};

export const customLogoWorkspaceIds: {
  [key: string]: {
    logo: string;
    className: string;
  };
} = Object.freeze({
  '85c73740ceaed11648b1': sbConfig,
  fd7738370d83ddd48c93: ctConfig,
  '9bef12382200c182f2d8': ctConfig,
  d91a2b86683cac79aeb9: ctConfig,
  e117b7b347d0442e64da: ctConfig,
  '944b5ed1569459687cbc': ctConfig,
  '2bf314e42d796f8ce2e5': ctConfig,
  f57e97a0be7e146dce52: ctConfig,
  '2f20b42b2897f8f19b05': ctConfig,
  '8751767599ccaa333b1a': ctConfig,
  e7bba84eed5f61c3c01f: ctConfig,
  a5b065f6771e13a4db71: ctConfig,
  '7244d3758e913f3bd6ce': ctConfig,
  a2eef1cca9b297205205: ctConfig,
  '4f1cf9559e457d244478': ctConfig,
  '79ae99772b4fa731836b': ctConfig,
  a9068254f34e1c9937e7: ctConfig,
  d70f7dc17a2f666389c9: ctConfig,
  '7524e07d7523bbbc4a47': ctConfig,
  '201f9bae479e21e64306': seamonWhitesideConfig,
  e4e5a65985dd73b62ce8: jwcConfig,
  '53bbe81f0eb6abfdaf00': choateConfig,
  ac78ede5c4a28a3c7337: drgConfig,
  'bb9cc895fa31c67f2f0e': ppsConfig,
  '3f25e173c8c11dea81ed': matthewsConfig,
});

export default function ReportHeader({
  report,
  printable = false
}: {
  report: Report,
  printable?: boolean
}) {
  const { workspace_id, share_id } = useParams<{ workspace_id: string, share_id: string }>();

  const {
    updateReportDebounced,
    updating,
    updated
  } = useReport(report.objectId, {
    share_id,
    load: false
  });

  const { editing } = useReportContext();
  const [reportLogoLoaded, setReportLogoLoaded] = useState(false);
  const isGenerating = urlIsGenerating(window);
  const customWorkspaceConfig = report.logo ? {
    logo: isGenerating ? report.logo.files.download.medium : report.logo.files.medium,
    className: report.logo.metadata.class_name,
  } : customLogoWorkspaceIds[workspace_id]

  const updateReport = (e: any) => {
    const { target: { innerText: name } } = e;
    updateReportDebounced({ name });
  };

  return <>
    {reportLogoLoaded && <span data-testid="reportHeaderLogoLoaded" className={"header-logo-loaded"} />}
    <ReportHeaderComponent
      key={`report-header-${report.objectId}`}
      projectIdentifier={report.project.identifier}
      projectName={report.project.name}
      reportName={report.name}
      imgSrc={customWorkspaceConfig ? customWorkspaceConfig.logo : logo}
      imgClassName={customWorkspaceConfig ? customWorkspaceConfig.className : 'h-5'}
      onImageLoad={() => setReportLogoLoaded(true)}
      printable={printable}
      editing={editing}
      onBlur={updateReport}
      onInput={updateReport}
      saving={updating}
      saved={updated}
    />
  </>;
}

const ReportHeaderComponent = ({
  projectIdentifier,
  projectName,
  reportName,
  printable,
  imgEditable = false,
  onImageEdit,
  imgSrc,
  imgClassName = 'h-5',
  editing,
  onImageLoad,
  onInput,
  onBlur,
  saving,
  saved,
}: {
  projectIdentifier: string;
  projectName: string;
  reportName: string;
  printable?: boolean;
  imgEditable?: boolean;
  onImageEdit?: (direction: number) => void;
  imgSrc: string;
  imgClassName?: string;
  editing: boolean;
  onImageLoad: () => void;
  onInput: (e: React.FormEvent<HTMLDivElement>) => void;
  onBlur: (e: React.FormEvent<HTMLDivElement>) => void;
  saving: boolean;
  saved: boolean;
}) => {

  const inputRef = useRef<HTMLParagraphElement | null>(null);
  const { onLoad, onError } = useRetry(onImageLoad);

  useEffect(() => {
    if (!inputRef.current || inputRef.current.textContent) return;
    inputRef.current.textContent = reportName;
  }, [inputRef, reportName]);

  return <header className={`${printable ? 'text-xs' : 'text-sm top-0 sticky w-full z-30'} relative px-4 bg-white print:border-0 border-b border-gray-200`}>
    <div className={`${printable ? 'h-12' : 'h-16'} items-center flex justify-between`}>

      {imgEditable && <div className="absolute top-1 left-1">
        <FaPlusCircle onClick={() => onImageEdit?.(1)} className={`text-gray-900 mb-1 ${imgClassName === 'h-16' ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer hover:opacity-80'}`} />
        <FaMinusCircle onClick={() => onImageEdit?.(-1)} className="cursor-pointer hover:opacity-80 text-gray-900" />
      </div>}
      <img
        src={imgSrc}
        className={imgClassName}
        onLoad={onLoad}
        onError={onError}
      />
      <div className="w-full relative text-right ml-4 truncate">
        <p className={`font-medium text-gray-900 tracking-wide font-oswald`}>
          <span className="font-semibold">{projectIdentifier}</span>{' '}
          {projectName}
        </p>
        {printable ? <p className="text-xs w-full text-right font-light text-black border border-transparent rounded-md focus:outline-none">
          {reportName}
        </p> : <p
          contentEditable={editing}
          ref={inputRef}
          onInput={onInput}
          onBlur={onBlur}
          data-testId="inputReportName"
          className={`w-full text-right text-sm font-light text-black border border-transparent p-0 rounded-md focus:outline-none ${editing && 'hover:bg-blue-50'
            } focus:border-gray-200 focus:ring-0 resize-none`}
        />}
      </div>
    </div>

    {saving && (
      <div className="absolute top-2 right-2 flex items-center">
        <Loader color="text-gray-700" />
      </div>
    )}
    {saved && (
      <div className="absolute top-2 right-2 flex items-center">
        <BiCheckCircle
          size={16}
          className="bg-green-500 text-white rounded-full overflow-hidden"
        />
      </div>
    )}
  </header>
}

export {
  ReportHeaderComponent
}
