import React, { memo, useState } from 'react';
import Header from '../partials/Header';
import Sidebar from '../partials/Sidebar';
function Layout({ children, ...rest }: {
  children: React.ReactNode
}) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className={`flex h-screen overflow-hidden`}>
      <Sidebar
        key={'app_sidebar'}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
      <div className={`relative flex flex-col flex-1`}>
        <Header
          key={'app_header'}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        />
        {children}
      </div>
    </div>
  );
}

const Flex = ({ children, justify = '' }: {
  children: React.ReactNode,
  justify?: 'justify-end' | 'justify-start' | 'justify-center' | 'justify-between' | 'justify-around' | ''
}) => <div className={`flex ${justify}`} >
    {children}
  </div >

const Row = ({ children }: {
  children: React.ReactNode
}) => <div className="flex items-center">
    {children}
  </div>

const Grid = ({ children }: {
  children: React.ReactNode
}) => <div className="grid grid-cols-12 gap-4">
    {children}
  </div>

const ListMain = ({ children }: {
  children: React.ReactNode
}) => <ul className="text-sm space-y-2 mb-5">
    {children}
  </ul>

const Item = ({ children }: {
  children: React.ReactNode
}) => <li className="flex items-center">
    {children}
  </li>

const List = {
  List: ListMain,
  Item: Item,
}

export {
  Grid,
  Flex,
  Row,
  List
}

export default memo(Layout);
