const H1 = ({
    children,
    mb = "mb-6"
}: {
    children: React.ReactNode,
    mb?: string
}) => <h1 className={`text-3xl text-gray-800 font-bold ${mb}`}>
        {children}
    </h1>

const H2 = ({ children }: {
    children: React.ReactNode
}) => {
    return <h2 className="font-semibold text-gray-800">{children}</h2>
}

const H3 = ({ children, mr = "mr-0" }: {
    children: React.ReactNode,
    mr?: string;
}) => {
    return <h3 className={`text-lg text-gray-800 font-semibold ${mr}`}>{children}</h3>
}
export {
    H1,
    H2,
    H3
}