import colors from '../styles/colors';
import _ from 'lodash';

const pinConfig = {
  swppp: {
    component: 'FontAwesome',
    props: {
      name: 'circle',
      size: 24,
      color: colors.secondary,
      anchor: { x: 0.5, y: 0.5 },
    },
  },
  tree: {
    component: 'MaterialCommunityIcons',
    props: {
      name: 'triangle',
      size: 24,
      color: colors.treeGreen,
      anchor: { x: 0.5, y: 0.6 },
    },
  },
  plus: {
    component: 'FontAwesome',
    props: {
      name: 'plus',
      size: 24,
      color: colors.smDanger,
      anchor: { x: 0.5, y: 0.5 },
    },
  },
};

const maxPinCount = 500;

// A WKT is a Well Known Text representation of a projection.
const defaultProjection = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs ';
const ncProj4 =
  '+proj=lcc +lat_1=36.16666666666666 +lat_2=34.33333333333334 +lat_0=33.75 +lon_0=-79 +x_0=609601.2192024384 +y_0=0 +ellps=GRS80 +datum=NAD83 +to_meter=0.3048006096012192 +no_defs ';
const ncWKT =
  'PROJCS["NAD83 / North Carolina (ftUS)",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137,298.257222101]],PRIMEM["Greenwich",0],UNIT["Degree",0.017453292519943295]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["standard_parallel_1",36.16666666666666],PARAMETER["standard_parallel_2",34.33333333333334],PARAMETER["latitude_of_origin",33.75],PARAMETER["central_meridian",-79],PARAMETER["false_easting",2000000],PARAMETER["false_northing",0],UNIT["Foot_US",0.30480060960121924]]';
const scProj4 =
  '+proj=lcc +lat_1=34.83333333333334 +lat_2=32.5 +lat_0=31.83333333333333 +lon_0=-81 +x_0=609600 +y_0=0 +ellps=GRS80 +datum=NAD83 +to_meter=0.3048 +no_defs ';
const scWKT =
  'PROJCS["NAD83 / South Carolina (ft)",GEOGCS["GCS_North_American_1983",DATUM["D_North_American_1983",SPHEROID["GRS_1980",6378137,298.257222101]],PRIMEM["Greenwich",0],UNIT["Degree",0.017453292519943295]],PROJECTION["Lambert_Conformal_Conic"],PARAMETER["standard_parallel_1",34.83333333333334],PARAMETER["standard_parallel_2",32.5],PARAMETER["latitude_of_origin",31.83333333333333],PARAMETER["central_meridian",-81],PARAMETER["false_easting",2000000],PARAMETER["false_northing",0],UNIT["Foot",0.3048]]';

const availableProjections = [
  { name: 'Default Project: WSG84', proj4: defaultProjection },
  {
    name: 'North Carolina: NAD83 US Foot (EPSG:2264 | NAD83)',
    proj4: ncProj4,
    wkt: ncWKT,
  },
  {
    name: 'South Carolina: NAD83 International Foot (EPSG:2273 | NAD83)',
    proj4: scProj4,
    wkt: scWKT,
  },
];

export { defaultProjection, availableProjections, maxPinCount };
export default pinConfig;
