import React, { useEffect } from 'react';
import useApi from '../../hooks/useApi';
import accessCodesApi from '../../api/access_codes';
import Loader from '../utility/Loader';
import CopyButton from '../utility/CopyButton';
export default function AccessCodeShareBody({ workspace, shouldLoad }) {
  const {
    data: { records: access_codes, pagy },
    error,
    loading,
    request: getAccessCodes,
  } = useApi(accessCodesApi.getAccessCodes, { records: [], pagy: {} });
  const { origin } = window.location;

  const accessCodeSearchParams = () => {
    let params = { where: { active: true } };
    if (workspace) params.workspace_id = workspace.id;
    return params;
  };

  useEffect(() => {
    shouldLoad && getAccessCodes(accessCodeSearchParams());
  }, [shouldLoad]);

  return (
    <>
      {loading && (
        <div className="flex justify-center py-10">
          <Loader color="black" />
        </div>
      )}
      {!loading && access_codes[0] && (
        <>
          <p className="font-medium pb-3 border-b mb-3">
            Share link or access code below to members you want <br />
            to join{' '}
            {workspace && (
              <>
                <span className="text-secondary font-semibold">
                  {workspace.name}
                </span>{' '}
                on{' '}
              </>
            )}
            Sitemarker.
          </p>
          <div className="py-3">
            <p className="font-black">Link</p>
            <div className="flex items-center">
              <a
                className="text-secondary underline text-sm"
                rel="noreferrer"
                href={`${origin}/onboarding/sign_up?code=${access_codes[0].code}`}
                target="_blank"
              >
                https://app.sitemarker.com/onboarding/sign_up?code=
                {access_codes[0].code}
              </a>
              <CopyButton
                text={`${origin}/onboarding/sign_up?code=${access_codes[0].code}`}
              />
            </div>
          </div>
          <div className="py-3">
            <p className="font-black">Access Code</p>
            <div className="flex items-center mt-2">
              <p className="text-xl text-primary font-bold">
                {access_codes[0].code}
              </p>
              <CopyButton text={access_codes[0].code} />
            </div>
          </div>
        </>
      )}
      {!loading && access_codes.length == 0 && (
        <div className="py-5 px-3">
          <p className="font-bold text-center text-xl mb-2">Uh oh!</p>
          <p className="font-medium text-center text-lg">
            You don't currently have acces to invite members to join Sitemarker.
          </p>
        </div>
      )}
    </>
  );
}
