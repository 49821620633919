import Loader from '../utility/Loader';
import { EntryPinPhoto } from './EntryPin';
import { Asset, Entry } from '../../data/models';
import urlIsGenerating from '../../utility/urlIsGenerating';

function EntryNotePhotos({
    entry,
    error,
    assets,
    loading,
    onPhotoSelected,
    onPhotoLoaded,
}: {
    entry: Entry,
    error: any,
    assets: Asset[],
    loading: boolean,
    onPhotoSelected?: (index: number) => void
    onPhotoLoaded?: (index: number) => void
}) {

    const isGenerating = urlIsGenerating(window);

    if (error) return <p className="px-2">N/A</p>
    if (loading) return <div className="flex justify-center py-2">
        <Loader color="black" />
    </div>

    return <div className="w-full" data-testid="siteNotePhotos">
        {loading ? (
            <div className="w-full flex justify-center h-20 items-center">
                <Loader color={'gray-500'} />
            </div>
        ) : (
            <div className={"grid gap-1 grid-cols-2 sm:grid-cols-3 md:grid-cols-4"}>
                {assets.map(
                    (asset, index: number) => {
                        const { objectId, files, taken_at, save_started_at } = asset;
                        const { download, large, original, created_at } = files;
                        const photoIndex = (entry.info?.start_index || 0) + index;
                        return <EntryPinPhoto photo={{
                            objectId,
                            large: isGenerating ? download.large : large,
                            original: original,
                            createdAt: taken_at || save_started_at || created_at,
                        }}
                            index={index}
                            size="full"
                            onSelect={() => onPhotoSelected?.(photoIndex)}
                            onLoaded={onPhotoLoaded}
                        />
                    }
                )}
                {!loading && assets.length === 0 && (
                    <p className="italic">No photos attached</p>
                )}
            </div>
        )}
    </div>
}

export default EntryNotePhotos;
