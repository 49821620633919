import { useEffect, useState } from 'react';
import useApi from '../hooks/useApi';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import Loader from '../partials/utility/Loader';
import SearchForm from '../partials/actions/SearchForm';
import usersApi from '../api/users';
import UsersTable from '../partials/users/UsersTable';

function Users() {
  const {
    data: { records, pagy },
    error,
    loading,
    request: getUsers,
  } = useApi(usersApi.getUsers, { records: [], pagy: {} });
  const [selectedItems, setSelectedItems] = useState([]);
  const [users, setUsers] = useState([]);
  const [searchParams, setSearchParams] = useState({ page: 1, items: 100 });

  const loadMore = () =>
    setSearchParams({ ...searchParams, page: searchParams.page + 1 });
  const hasNextPage = () => !!pagy.next;

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: hasNextPage(),
    onLoadMore: loadMore,
    disabled: !!error,
    rootMargin: '0px 0px 400px 0px',
  });

  const handleSelectedItems = (selectedItems) => {
    setSelectedItems([...selectedItems]);
  };

  useEffect(() => {
    getUsers(searchParams);
  }, [searchParams]);

  useEffect(() => {
    if (!records.length) return;
    const existingUserObjectIds = users.map(({ objectId }) => objectId);
    setUsers([
      ...users,
      ...records.filter(
        ({ objectId }) => existingUserObjectIds.includes(objectId) === false
      ),
    ]);
  }, [records]);

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      {/* Page header */}
      <div className="sm:flex sm:justify-between sm:items-center mb-8">
        {/* Left: Title */}
        <div className="mb-4 sm:mb-0">
          <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">
            Users
          </h1>
        </div>

        <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
          <SearchForm
            onSearched={(text) => {
              setUsers([]);
              setSearchParams({ q: text, page: 1, items: 20 });
            }}
          />
        </div>
      </div>

      {/* Table */}
      <UsersTable
        selectedItems={handleSelectedItems}
        users={users}
        totalCount={pagy.count}
      />
      {(loading || hasNextPage()) && (
        <div className="flex justify-center py-5" ref={sentryRef}>
          <Loader color="black" />
        </div>
      )}
    </div>
  );
}

export default Users;
