import { BsTriangleFill } from "react-icons/bs";
import { FaCircle, FaPlus } from "react-icons/fa";
import { IconNames } from "../data/models";
import colors from "../styles/colors";

const icons = {
    "plus": {
        component: FaPlus,
        color: colors.secondary
    },
    "circle": {
        component: FaCircle,
        color: colors.primary
    },
    "triangle": {
        component: BsTriangleFill,
        color: colors.smGreen
    }
};

const Icon = ({ name, color, size = 20 }: {
    name: IconNames;
    color?: string;
    size?: number;
}) => {
    const iconConfig = icons[name];
    const IconComponent = iconConfig.component;

    return (
        <IconComponent color={color || iconConfig.color} size={size} />
    );
}

export default Icon;