import { useEffect } from 'react';
import useApi from '../../../hooks/useApi';
import entriesApi from '../../../api/entries';
import { Entry } from '../../../data/models';
export default function usePageEntries(reportPageEntryGroupId: string | null, {
    load = true,
    share_id
}: {
    load?: boolean,
    share_id?: string
} = {}): {
    entries: Entry[];
    error: any;
    loading: boolean;
    loadEntries: () => void;
} {

    const {
        data: entries,
        error,
        loading,
        request: getEntries,
    } = useApi(entriesApi.getReportPageEntryGroupEntries, [], true);

    const _loadEntries = () => {
        if (reportPageEntryGroupId) getEntries(reportPageEntryGroupId, { share_id });
    }

    useEffect(() => {
        if (!load) return;
        _loadEntries();
    }, [reportPageEntryGroupId, share_id, load]);

    return {
        entries,
        error,
        loading,
        loadEntries: _loadEntries
    }

}