import { memo } from 'react';
import EntryFreeText from '../entries/EntryFreeText';

function EntryGroupFreeText({ report, entries }: {
    report: any;
    entries: any[];
}) {
    return (
        <div className="px-2 pt-2 text-xs">
            {entries.map((entry) => (
                <EntryFreeText
                    key={`report_${report.objectId}_entry_free_text_${entry.objectId}`}
                    entry={entry}
                    disabled={true}
                />
            ))}
            {entries.length === 0 && <p className="px-2">N/A</p>}
        </div>
    );
}

export default EntryGroupFreeText;