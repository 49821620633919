import { useLocation } from 'react-router-dom';
export default function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const useQueryParams = () => {
  const params = useQuery();
  let paramObj = {};
  for (var value of params.keys()) {
    paramObj[value] = params.get(value);
  }
  return paramObj;
}
