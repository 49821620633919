import { Dispatch, SetStateAction, useContext } from 'react';
import { ProjectContext } from '../partials/project/context';
import { Project } from '../data/models';
import ErrorViewModel from '../models/ErrorViewModel';

export default function useProjectContext(): {
  project: Project | null;
  setProject?: Dispatch<SetStateAction<Project | null>>;
  loadProject?: (id: string) => void;
  error?: boolean | ErrorViewModel;
  loading?: boolean;
} {
  const context = useContext(ProjectContext);
  if (!context) return { project: null };

  const { project, setProject, loadProject, error, loading } = context;

  return {
    // Wrangle the project variable a bit so we can get sigs in the callers.
    project: project || null,
    setProject,
    loadProject,
    error,
    loading,
  };
}
