import Notifications from './header/Notifications';
import Help from './header/Help';
import UserMenu from './header/UserMenu';
import ProjectSearch from './header/ProjectSearch';
import Feedback from './header/Feedback';

function Header({ sidebarOpen, setSidebarOpen }) {
  return (
    <header className="hidden sm:block sticky z-50 top-0 bg-white border-b border-gray-200">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 -mb-px">
          {/* Header: Left side */}
          <div className="flex flex-grow">
            {/* Hamburger button */}
            <button
              className="text-gray-500 hover:text-gray-600 lg:hidden"
              aria-controls="sidebar"
              aria-expanded={sidebarOpen}
              onClick={() => setSidebarOpen(!sidebarOpen)}
            >
              <span className="sr-only">Open sidebar</span>
              <svg
                className="w-6 h-6 fill-current"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="4" y="5" width="16" height="2" />
                <rect x="4" y="11" width="16" height="2" />
                <rect x="4" y="17" width="16" height="2" />
              </svg>
            </button>
            <ProjectSearch />
          </div>

          {/* Header: Right side */}
          <div className="flex items-center">
            {/* <SearchModal /> */}
            <Feedback />
            <Notifications />
            <Help />
            {/*  Divider */}
            {/* <a href="https://forms.clickup.com/f/12bdxt-320/DDCWIZ3SX61CX88DQZ" target="_blank" rel="noreferrer" className="flex items-center cursor-pointer hover:text-primary">
              <BiEnvelope size={20} />
            </a> */}
            {/* <a href="https://forms.clickup.com/f/12bdxt-320/DDCWIZ3SX61CX88DQZ" target="_blank" rel="noreferrer" className="flex items-center cursor-pointer hover:text-primary">
              <RiFeedbackLine size={20} />
              <div className="text-sm ml-1">Feedback</div>
            </a>
             */}
            <hr className="w-px h-6 bg-gray-200 mx-3" />
            <UserMenu />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
