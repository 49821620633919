import client from './client';
const endpoint = '/users';

const getUsers = (params = {}) => client.get(`${endpoint}`, params);
const resetUserPassword = (userId) =>
  client.post(`${endpoint}/${userId}/reset_password`);
const confirmUserEmail = (userId) =>
  client.post(`${endpoint}/${userId}/confirm_email`);
const updateFeatureFlag = (userId, params = {}) =>
  client.post(`${endpoint}/${userId}/feature_flag`, params);
const getWorkspaceUsers = (workspaceId) =>
  client.get(`/workspaces/${workspaceId}${endpoint}`);
const getWorkspaceUser = (workspaceId, id, params = {}) =>
  client.get(`/workspaces/${workspaceId}${endpoint}/${id}`, params);
const addWorkspaceUser = (workspaceId, user) =>
  client.post(`/workspaces/${workspaceId}${endpoint}`, { user });
const updateWorkspaceUser = (workspaceId, id, user) =>
  client.put(`/workspaces/${workspaceId}${endpoint}/${id}`, { user });
const updateUser = (id, user) => client.put(`${endpoint}/${id}`, { user });
const destroyUser = (id) => client.delete(`${endpoint}/${id}`);
const becomeUser = (token) => client.post(`${endpoint}/become`, { token });

export default {
  getWorkspaceUsers,
  getWorkspaceUser,
  addWorkspaceUser,
  updateWorkspaceUser,
  getUsers,
  updateUser,
  destroyUser,
  resetUserPassword,
  confirmUserEmail,
  updateFeatureFlag,
  becomeUser,
};
