const UPLOADING = 'UPLOADING';
const PENDING = 'PENDING';
const ERROR = 'ERROR';

const LAYER_STATES = {
    UPLOADING,
    PENDING,
    ERROR,
}

export {
    LAYER_STATES
}