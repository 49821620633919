import { useContext, useEffect, useState } from 'react';
import AuthContext from './context';
import authStorage from './storage';
import jwtDecode from 'jwt-decode';
import authApi from '../api/auth';

export default function useAuth() {
  const { loading, permissions, user, setUser } = useContext(AuthContext);
  const [updating, setUpdating] = useState(false);

  const update = async (data) => {
    setUpdating(true);
    let response = await authApi.update(data);
    if (response.ok) logIn(response);
    setUpdating(false);
  };

  const decodedUser = (response) => {
    const token = response.headers.authorization.replace('Bearer ', '');
    const user = jwtDecode(token);
    return { token, user };
  };

  const logIn = async (response) => {
    const { token, user } = decodedUser(response);
    await setToken(token);
    setUser(user);
    return user;
  };

  const logOut = async () => {
    await authStorage.removeToken('authToken');
    setUser(null);
  };

  const setToken = async (token) => {
    return await authStorage.storeToken('authToken', token);
  };

  return {
    loading,
    permissions,
    user,
    setUser,
    update,
    updating,
    decodedUser,
    setToken,
    logIn,
    logOut,
  };
}
