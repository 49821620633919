import { useEffect, useRef, useState } from 'react';
import { FiDownload } from 'react-icons/fi';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import Modal, { ModalHeader, ModalSuccessView } from '../utility/Modal';
import Loader from '../utility/Loader';
import foldersApi from '../../api/folders';

export default function BulkDownloadDocumentsModal({
    open,
    subject = 'Folders and files',
    documents,
    afterDownload,
}: {
    open: boolean;
    subject?: string;
    documents: any[];
    afterDownload?: () => void;
}) {

    const [downloading, setDownloading] = useState(false);
    const [downloaded, setDownloaded] = useState(false);
    const [zipFileName, setZipFileName] = useState('download');
    const downloadTimer = useRef<NodeJS.Timeout>();
    const [modalOpen, setModalOpen] = useState(open || false);

    const closeModal = () => {
        setModalOpen(false);

        if (downloadTimer.current) {
            clearTimeout(downloadTimer.current);
            setDownloaded(false);
            afterDownload?.();
        }
    };

    const downloadZip = async () => {
        setDownloading(true);

        const prepareDownloadConfig = async (documents: any[], parentPath?: string) => {
            const downloadConfig = [];
            for (const document of documents) {
                if (document.type === 'folder') {
                    const { data: folder } = await foldersApi.getFolder(document.objectId)
                    const folderDocuments: any = await prepareDownloadConfig(folder.documents, `${parentPath ? parentPath + '/' : ''}${folder.name}`);
                    downloadConfig.push(...folderDocuments);
                } else {
                    downloadConfig.push({
                        url: document.asset.files.original,
                        filename: `${parentPath ? parentPath + '/' : ''}${document.name}`,
                    });
                }
            }

            return downloadConfig;
        };

        const downloadConfig = await prepareDownloadConfig(documents);

        const zip = new JSZip();

        for (const { url, filename } of downloadConfig) {
            const res = await fetch(url.replace('file?size=original', 'download'));
            const blob = await res.blob();
            await zip.file(filename, blob);
        }

        zip.generateAsync({ type: 'blob' }).then(function (content) {
            FileSaver.saveAs(content, `${zipFileName}.zip`);
        });

        setDownloading(false);
        setDownloaded(true);
    };

    useEffect(() => {
        if (!downloaded) return;
        downloadTimer.current = setTimeout(() => {
            setDownloaded(false);
            setModalOpen(false);
            afterDownload?.();
        }, 3000);
    }, [downloaded]);

    useEffect(() => {
        setModalOpen(open);
    }, [open]);

    return (
        <div className="text-gray-600 text-center font-normal">
            <button
                className="cursor-pointer hover:bg-gray-800 rounded-full p-3"
                data-testid="bulkDownloadDocumentsButton"
                disabled={downloading}
                onClick={() => setModalOpen(true)}
            >
                <FiDownload className="text-white" data-testid="download-icon" />
            </button>
            <Modal isOpen={modalOpen} onClose={closeModal}>
                <ModalHeader
                    title={
                        <>
                            Download <span className="mx-1 font-light">|</span>{' '}
                            <span className="font-light">
                                {documents.length} {subject}
                            </span>{' '}
                        </>
                    }
                    onClose={closeModal}
                />
                <div className="px-2 pt-2 text-base">
                    <div className="p-5 text-left">
                        {!downloaded && (
                            <>
                                <div className="mb-4 font-semibold">Ready to download?</div>
                                <div className="mb-4">
                                    <div className="font-light mb-2 text-sm">File Name</div>
                                    <div className="bg-white flex shadow-sm border rounded-md border-gray-200 overflow-hidden">
                                        <div className="flex flex-grow items-center">
                                            <input
                                                type="text"
                                                value={zipFileName}
                                                onChange={({ target: { value } }) =>
                                                    setZipFileName(value)
                                                }
                                                className="px-2 bg-white placeholder-gray-500 relative text-sm border-0 w-full outline-none focus:outline-none focus:ring-0"
                                            />
                                        </div>
                                        <div className="flex px-5 items-center bg-white border-l">
                                            .zip
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        {downloaded && (
                            <ModalSuccessView text={'Files successfully downloaded'} />
                        )}
                    </div>

                    {!downloaded && (
                        <div className="modal-footer">
                            <button
                                className="modal-save-btn"
                                type="button"
                                onClick={downloadZip}
                                disabled={downloading}
                            >
                                Download {subject} {downloading && <Loader />}
                            </button>
                            <button
                                className="modal-close-btn"
                                type="button"
                                onClick={closeModal}
                                disabled={downloading}
                            >
                                Cancel
                            </button>
                        </div>
                    )}
                </div>
            </Modal>
        </div>
    );
}
