import client from './client';

const endpoint = '/app_config';

const getAppConfig = () => client.get(`${endpoint}`);
const getAppPermissions = () => client.get(`${endpoint}/permissions`);

export default {
  getAppConfig,
  getAppPermissions,
};
