import React, { useState, useEffect, useRef } from 'react';
import { GrCheckbox, GrCheckboxSelected } from 'react-icons/gr';
import { BiCheckCircle } from 'react-icons/bi';
import entriesApi from '../../api/entries';
import useApi from '../../hooks/useApi';
import Loader from '../utility/Loader';
import useReportContext from '../reports/useReportContext';
export default function EntryWeatherInspectionDate({ entry: passedEntry }) {
  const {
    data: savedEntry,
    error,
    loading: saving,
    request: saveEntry,
  } = useApi(entriesApi.updateEntry, null);
  const [entry, setEntry] = useState(passedEntry);
  const [saved, setSaved] = useState(false);
  const { editing } = useReportContext();
  const savedTimer = useRef(null);
  const descriptionTimer = useRef(null);

  const updateDescription = ({ target: { value } }) => {
    if (descriptionTimer.current) clearTimeout(descriptionTimer.current);
    descriptionTimer.current = setTimeout(() => {
      submitEntryDescription(value);
    }, 1000);
  };

  const submitEntryDescription = (value) => {
    entry.description = value;
    setEntry({ ...entry });
    saveEntry(entry.objectId, { description: value });
  };

  const submitEntryValue = (value) => {
    entry.value = value;
    setEntry({ ...entry });
    saveEntry(entry.objectId, { value: value });
  };

  useEffect(() => {
    savedEntry && setEntry({ ...savedEntry });
  }, [savedEntry]);

  useEffect(() => {
    savedEntry && setSaved(true);
  }, [savedEntry]);

  useEffect(() => {
    if (saved) {
      savedTimer.current = setTimeout(() => {
        setSaved(false);
      }, 2000);
    }
  }, [saved]);

  return (
    <div
      className={`py-1 ${editing && entry.requires_confirmation && 'bg-yellow-50'
        }`}
    >
      <div className="text-center relative">
        Weather at time of this inspection?
        {saving && (
          <div className="absolute top-1 right-2 h-full flex items-center">
            <Loader color="text-gray-700" />
          </div>
        )}
        {saved && (
          <div className="absolute top-1 right-2 h-full flex items-center">
            <BiCheckCircle
              size={16}
              className="bg-green-500 text-white rounded-full overflow-hidden"
            />
          </div>
        )}
      </div>
      <div className={`flex flex-wrap justify-center py-1`}>
        <div className="pr-1">
          {entry.value === 'clear' ? (
            <GrCheckboxSelected
              size={14}
              onClick={() => editing && submitEntryValue(null)}
              className={editing && `hover:bg-blue-100 cursor-pointer`}
            />
          ) : (
            <GrCheckbox
              size={14}
              onClick={() => editing && submitEntryValue('clear')}
              className={editing && 'hover:bg-blue-100 cursor-pointer'}
            />
          )}
        </div>
        <div>Clear</div>
        <div className="pl-4 pr-1">
          {entry.value === 'cloudy' ? (
            <GrCheckboxSelected
              size={14}
              onClick={() => editing && submitEntryValue(null)}
              className={editing && `hover:bg-blue-100 cursor-pointer`}
            />
          ) : (
            <GrCheckbox
              size={14}
              onClick={() => editing && submitEntryValue('cloudy')}
              className={editing && 'hover:bg-blue-100 cursor-pointer'}
            />
          )}
        </div>
        <div>Cloudy</div>
        <div className="pl-4 pr-1">
          {entry.value === 'rain' ? (
            <GrCheckboxSelected
              size={14}
              onClick={() => editing && submitEntryValue(null)}
              className={editing && `hover:bg-blue-100 cursor-pointer`}
            />
          ) : (
            <GrCheckbox
              size={14}
              onClick={() => editing && submitEntryValue('rain')}
              className={editing && 'hover:bg-blue-100 cursor-pointer'}
            />
          )}
        </div>
        <div>Rain</div>
        <div className="pl-4 pr-1">
          {entry.value === 'sleet' ? (
            <GrCheckboxSelected
              size={14}
              onClick={() => editing && submitEntryValue(null)}
              className={editing && `hover:bg-blue-100 cursor-pointer`}
            />
          ) : (
            <GrCheckbox
              size={14}
              onClick={() => editing && submitEntryValue('sleet')}
              className={editing && 'hover:bg-blue-100 cursor-pointer'}
            />
          )}
        </div>
        <div>Sleet</div>
        <div className="pl-4 pr-1">
          {entry.value === 'fog' ? (
            <GrCheckboxSelected
              size={14}
              onClick={() => editing && submitEntryValue(null)}
              className={editing && `hover:bg-blue-100 cursor-pointer`}
            />
          ) : (
            <GrCheckbox
              size={14}
              onClick={() => editing && submitEntryValue('fog')}
              className={editing && 'hover:bg-blue-100 cursor-pointer'}
            />
          )}
        </div>
        <div>Fog</div>
        <div className="pl-4 pr-1">
          {entry.value === 'snowing' ? (
            <GrCheckboxSelected
              size={14}
              onClick={() => editing && submitEntryValue(null)}
              className={editing && `hover:bg-blue-100 cursor-pointer`}
            />
          ) : (
            <GrCheckbox
              size={14}
              onClick={() => editing && submitEntryValue('snowing')}
              className={editing && 'hover:bg-blue-100 cursor-pointer'}
            />
          )}
        </div>
        <div>Snowing</div>
        <div className="pl-4 pr-1 mt-2 sm:mt-0 print:mt-0">
          {entry.value === 'high_winds' ? (
            <GrCheckboxSelected
              size={14}
              onClick={() => editing && submitEntryValue(null)}
              className={editing && `hover:bg-blue-100 cursor-pointer`}
            />
          ) : (
            <GrCheckbox
              size={14}
              onClick={() => editing && submitEntryValue('high_winds')}
              className={editing && 'hover:bg-blue-100 cursor-pointer'}
            />
          )}
        </div>
        <div className="mt-2 sm:mt-0 print:mt-0">High Winds</div>
        <div className="pl-4 pr-1 mt-2 sm:mt-0 print:mt-0">
          {entry.value === 'other' ? (
            <GrCheckboxSelected
              size={14}
              onClick={() => editing && submitEntryValue(null)}
              className={editing && `hover:bg-blue-100 cursor-pointer`}
            />
          ) : (
            <GrCheckbox
              size={14}
              onClick={() => editing && submitEntryValue('other')}
              className={editing && 'hover:bg-blue-100 cursor-pointer'}
            />
          )}
        </div>
        <div className="mt-2 sm:mt-0 print:mt-0">Other:</div>
      </div>
      <div className="text-center">
        Temperature: (°F){' '}
        <input
          type="text"
          disabled={!editing}
          className={`text-center border-gray-300 border-l-0 border-r-0 border-t-0 border-b text-xxs pt-1 pb-0 mb-1 w-10 ${editing && 'hover:bg-blue-50'
            } focus:border-gray-200 focus:outline-none focus:ring-0`}
          onChange={updateDescription}
          defaultValue={entry.description}
        />
      </div>
    </div>
  );
}
