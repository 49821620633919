import { useEffect, useState } from 'react';
import { ReportResourceContainer, ReportResourceHeader } from '../pins/PinFilterCard';
import useApi from '../../hooks/useApi';
import notesApi from '../../api/notes';
import ProjectNotesModal from '../project/ProjectNotesModal';
import EditableProjectSiteNote from '../project/EditableProjectSiteNote';
import NoteCreateModal, { NoteAddButton } from '../notes/NoteCreateModal';
import Loader from '../utility/Loader';
import useFeature from '../feature_flags/hooks/useFeature';

export const ReportSiteNoteSelection = ({ reportNote, onDismiss, onSelect }: { reportNote: any, onDismiss: any, onSelect: any }) => {

    const {
        data: note,
        loading,
        request: getNote,
    } = useApi(notesApi.getNote, null, true);

    const [showPhotos, setShowPhotos] = useState<boolean>(false);
    const [creatingNote, setCreatingNote] = useState<boolean>(false);

    const { enabled } = useFeature('report_site_note_photos');

    const loadSelectedNote = () => {
        getNote(reportNote?.note_id);
    };

    useEffect(() => {
        loadSelectedNote();
    }, [reportNote?.note_id]);


    return <><ReportResourceContainer mb={3}>
        <ReportResourceHeader>
            <span>Site Notes</span>
            <div className="flex">
                <span className="mr-1"><ProjectNotesModal buttonText="Select Note" mode="selection" onSelect={onSelect} includePhotos={enabled} /></span>
                <NoteAddButton onClick={() => setCreatingNote(true)} />
            </div>
        </ReportResourceHeader>
        {loading && <div className="flex justify-center pb-3">
            <Loader color="black" />
        </div>}
        {reportNote && note && <div className="px-4 pb-4" data-testid="selectedSiteNote">
            <EditableProjectSiteNote
                key={`note${note.objectId}`}
                note={note}
                dismissable
                onDismiss={onDismiss}
                onShowPhotosClicked={() => setShowPhotos(!showPhotos)}
                showPhotos={showPhotos}
                maxHeight="max-h-16 overflow-hidden overflow-ellipsis"
            />
        </div>}
        {!reportNote && <div>
            <p className="text-center font-medium pb-3">No site note selected.</p>
        </div>}
    </ReportResourceContainer>

        <NoteCreateModal
            open={creatingNote}
            includePhotos={enabled}
            onClose={() => setCreatingNote(false)}
            afterAdd={(newNote: any) => {
                onSelect?.({
                    note_id: newNote.id
                })
            }} />
    </>
}