import { useState, useEffect } from 'react';
import Modal, { ModalHeader } from '../utility/Modal';
import useApi from '../../hooks/useApi';
import Loader from '../utility/Loader';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import activityApi from '../../api/activity';
import moment from 'moment-timezone';
import {
  BiBuilding,
  BiClipboard,
  BiLocationPlus,
  BiMapPin,
  BiQuestionMark,
  BiUser,
} from 'react-icons/bi';
import { HiDocumentAdd } from 'react-icons/hi';
const formattedActivity = (activities) => {
  return activities.reduce((group, activity, index) => {
    const date = activity.created_at;
    const formattedDate = moment(date).format('ddd, MMM DD, YYYY');
    group[formattedDate] = group[formattedDate] ?? [];

    if (group[formattedDate].find((x) => x.id === activity.id)) {
      return group;
    } else {
      activity.originalIndex = index;
      group[formattedDate].push(activity);
      return group;
    }
  }, {});
};

const goToActivity = (activity) => {
  let link = activity.parameters.link;
  if (!activity.parameters.link.includes('?')) link += '?';
  link += `activity_id=${activity.id}`;
  window.open(link, '_blank');
};

const ActivityIcon = ({ activity }) => {
  if (activity.key.includes('pin'))
    return (
      <div className="w-9 h-9 rounded-full flex items-center justify-center flex-shrink-0 bg-yellow-500 my-2 mr-2">
        <BiLocationPlus className="text-white" size={20} />
      </div>
    );
  if (activity.key.includes('project'))
    return (
      <div className="w-9 h-9 rounded-full flex items-center justify-center flex-shrink-0 bg-indigo-500 my-2 mr-2">
        <BiMapPin className="text-white" size={20} />
      </div>
    );
  if (activity.key.includes('document'))
    return (
      <div className="w-9 h-9 rounded-full flex items-center justify-center flex-shrink-0 bg-blue-500 my-2 mr-2">
        <HiDocumentAdd className="text-white" size={20} />
      </div>
    );
  if (activity.key.includes('report'))
    return (
      <div className="w-9 h-9 rounded-full flex items-center justify-center flex-shrink-0 bg-pink-500 my-2 mr-2">
        <BiClipboard className="text-white" size={20} />
      </div>
    );
  if (activity.key.includes('workspace'))
    return (
      <div className="w-9 h-9 rounded-full flex items-center justify-center flex-shrink-0 bg-red-500 my-2 mr-2">
        <BiBuilding className="text-white" size={20} />
      </div>
    );
  if (activity.key.includes('user'))
    return (
      <div className="w-9 h-9 rounded-full flex items-center justify-center flex-shrink-0 bg-green-500 my-2 mr-2">
        <BiUser className="text-white" size={20} />
      </div>
    );
  return (
    <div className="w-9 h-9 rounded-full flex items-center justify-center flex-shrink-0 bg-yellow-500 my-2 mr-2">
      <BiQuestionMark />
    </div>
  );
};

const ActivityHeader = ({ date }) => {
  return (
    <header className="text-xs uppercase text-gray-400 bg-gray-50 rounded-sm font-semibold p-2">
      {date}
    </header>
  );
};

const ActivityItem = ({ activity }) => {
  return (
    <li className="flex px-2 border-t border-gray-300">
      <div className="text-gray-700 bg-gray-50 text-xs flex items-center px-3">
        {moment(activity.created_at).format('LT')}
      </div>
      <ActivityIcon activity={activity} />
      <div className="flex-grow flex items-center border-b border-gray-100 text-sm py-2">
        <div className="flex-grow flex justify-between w-96 items-center">
          <div className="self-center">
            <a
              className="font-medium text-gray-800 hover:text-gray-900"
              href="#0"
            >
              {activity.owner.name}
            </a>{' '}
            {activity.text}{' '}
            <a
              className="font-medium text-gray-800"
              target="_blank"
              href={'#'}
              onClick={() => goToActivity(activity)} rel="noreferrer"
            >
              {activity.parameters.description}
            </a>{' '}
          </div>
          {activity.parameters.link && (
            <div className="flex-shrink-0 self-end ml-2">
              <a
                className="font-medium text-white bg-secondary rounded-md px-2 py-1 hover:text-gray-200"
                target="_blank"
                href={'#'}
                onClick={() => goToActivity(activity)} rel="noreferrer"
              >
                View <span className="hidden sm:inline"> -&gt;</span>
              </a>
            </div>
          )}
        </div>
      </div>
    </li>
  );
};
export default function UserActivityModal({ user, open, onClose }) {
  const {
    data: { records, pagy },
    loading,
    request: getUserActivity,
  } = useApi(activityApi.getUserActivity, {
    records: [],
    pagy: {},
  });

  const [activity, setActivity] = useState([]);
  const [searchParams, setSearchParams] = useState({
    q: '',
    page: 1,
    items: 20,
  });

  const loadMore = () =>
    setSearchParams({ ...searchParams, page: searchParams.page + 1 });
  const hasNextPage = () => !!pagy.next;

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: hasNextPage(),
    onLoadMore: loadMore,
    rootMargin: '0px 0px 400px 0px',
  });

  useEffect(() => {
    if (!open) return;
    getUserActivity(user.objectId, searchParams);
  }, [open, searchParams]);

  useEffect(() => {
    if (open) return;
    setSearchParams({
      q: '',
      page: 1,
      items: 20,
      order: 'created_at desc',
    });
  }, [open]);

  useEffect(() => {
    const existingActivityIds = activity.map((a) => a.id);
    setActivity((oldActivity) => [
      ...oldActivity,
      ...records.filter(({ id }) => !existingActivityIds.includes(id)),
    ]);
  }, [records]);

  const formatted = formattedActivity(activity);

  return (
    <>
      <Modal
        isOpen={open}
        onClose={onClose}
        maxHeight="h-full"
        dialogPadding="px-0"
        modalClass="rounded-0"
        side
      >
        <ModalHeader
          closeBtnClass="hidden"
          extraClass="sticky top-0"
          title={
            <div className="text-sm text-center flex justify-between min-w-96">
              <span className="font-normal">{user.name}</span>
              <span className="font-medium">Activity</span>
            </div>
          }
          onClose={onClose}
        />
        <div className="flex flex-col h-full p-2">
          {!activity.length && !loading && (
            <p className="italic text-center w-96">No Activity</p>
          )}
          {activity.length > 0 && (
            <>
              {Object.keys(formatted).map((date) => {
                const activities = formatted[date];
                return (
                  <>
                    <ActivityHeader date={date} />
                    <ul className="my-1">
                      {activities.map((activity) => (
                        <ActivityItem activity={activity} />
                      ))}
                    </ul>
                  </>
                );
              })}
            </>
          )}

          {(loading || hasNextPage()) && (
            <div className="flex justify-center py-10" ref={sentryRef}>
              <Loader color="black" />
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}
