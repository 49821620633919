export default class PinTypesViewModel {
  _filterableFields = [];

  constructor(object) {
    Object.assign(this, object);
    this.json = object;
  }

  filterableFields = () => {
    if (!!this._filterableFields.length) return this._filterableFields;
    this.json.forEach((type) => {
      type.fieldsets
        .filter(({ name }) => name !== 'Status')
        .forEach((set) => {
          set.fields.forEach((field) => {
            if (field.filters_pin) {
              field.field_options.forEach(({ value, name }) => {
                let existingFieldSetIndex = this._filterableFields.findIndex(
                  ({ name }) => name === set.name
                );
                if (existingFieldSetIndex !== -1)
                  this._filterableFields[existingFieldSetIndex].fields.filter(
                    ({ value: v }) => v === value
                  ).length === 0 &&
                    this._filterableFields[existingFieldSetIndex].fields.push({
                      name,
                      value,
                    });
                else
                  this._filterableFields.push({
                    name: set.name,
                    fields: [{ name, value }],
                  });
              });
            }
          });
        });
    });

    return this._filterableFields;
  };
}
