import { useEffect, useRef, useState } from 'react';
import Modal, { ModalHeader, ModalSuccessView } from '../utility/Modal';
import useApi from '../../hooks/useApi';
import workspaceMembersApi from '../../api/workspace_members';
import Loader from '../utility/Loader';
import DelayedSearchInput from '../utility/DelayedSearchInput';
import { AddExtraProps } from '../../utility/addExtraProps';
import { FiPlus, FiX } from 'react-icons/fi';
import AddMemberModal from '../team/AddMemberModal';
import { useParams } from 'react-router-dom';
import AddUserItem from './AddUserItem';
import { validateEmail } from '../../utility/validateEmail';
import ShareOptions from './ShareOptions';
import useWorkspaceContext from '../../hooks/useWorkspaceContext';
import useAuth from '../../auth/useAuth';

export default function ShareModal({
  button,
  hideButton,
  data,
  open,
  onClose,
  shareType = 'Report',
  shareableApi,
  folderId,
}) {
  const { workspace_id, project_id } = useParams();
  const { user } = useAuth();
  const { workspace } = useWorkspaceContext();
  const {
    data: { records, pagy },
    error,
    loading,
    request: loadWorkspaceMembers,
  } = useApi(
    project_id
      ? workspaceMembersApi.getProjectWorkspaceMembers
      : workspaceMembersApi.getWorkspaceMembers,
    { records: [], pagy: {} },
    true
  );

  const {
    data: updatedShareable,
    error: shareableError,
    loading: sharing,
    request: updateShareable,
  } = useApi(shareableApi, null);

  const [modalOpen, setModalOpen] = useState(false);
  const [searchParams, setSearchParams] = useState({
    page: 1,
    q: '',
    items: 10,
  });
  const [members, setMembers] = useState([]);
  const [sharedWith, setSharedWith] = useState([]);
  const [shared, setShared] = useState(false);
  const [shareTypes, setShareTypes] = useState(['pdf', 'public_link']);
  const sharedTimer = useRef();

  const searchIsEmail = (text) => validateEmail(text);

  const sendShares = () => {
    const shares_attributes = sharedWith.map(({ id }) => {
      return {
        shared_with_id: id,
        share_types: shareTypes,
        shared_by_id: user.id,
      };
    });
    switch (shareType) {
      case 'Document':
        updateShareable(project_id, folderId, data.objectId, {
          document: {
            shares_attributes,
          },
        });
        break;
      case 'Folder':
        updateShareable(project_id, data.objectId, {
          shares_attributes,
        });
        break;
      case 'ConstructionDrawing':
        updateShareable(data.objectId, {
          shares_attributes,
        });
        break;
      default:
        updateShareable(data.objectId, { shares_attributes });
        break;
    }
  };

  const membersNotCurrentlyShared = () => {
    return members.filter(
      ({ member: { objectId } }) =>
        !sharedWith.map(({ objectId }) => objectId).includes(objectId)
    );
  };

  const closeModal = () => {
    if (open !== undefined && onClose) return onClose?.();
    setModalOpen(false);
  };

  const addToSharedWith = (member) =>
    setSharedWith((isSharedWith) => [...isSharedWith, member]);
  const removeSharedWith = (index) =>
    setSharedWith([...sharedWith.filter((m, i) => i !== index)]);

  const toggleShareType = (type) => {
    let found = shareTypes.includes(type);
    if (found) setShareTypes([...shareTypes.filter((st) => st !== type)]);
    else setShareTypes([...shareTypes, type]);
  };

  const resetModal = () => {
    if (sharedTimer.current) clearTimeout(sharedTimer.current);
    setMembers([]);
    setSearchParams({ ...searchParams, page: 1, q: '' });
    setShared(false);
    setSharedWith([]);
  };

  useEffect(() => {
    if (open === undefined) return;
    setModalOpen(open);
  }, [open]);

  useEffect(() => {
    if (modalOpen) return;
    resetModal();
  }, [modalOpen]);

  useEffect(() => {
    if (!modalOpen) return;
    loadWorkspaceMembers(project_id || workspace_id, searchParams);
  }, [modalOpen, searchParams]);

  useEffect(() => {
    if (!records.length) return;
    setMembers([...members, ...records]);
  }, [records]);

  useEffect(() => {
    if (!updatedShareable) return;
    setShared(true);
  }, [updatedShareable]);

  useEffect(() => {
    if (!shared) return;
    sharedTimer.current = setTimeout(closeModal, 3000);
  }, [shared]);

  return (
    <>
      {button ? (
        AddExtraProps(button, {
          onClick: () => setModalOpen(!modalOpen),
          'data-testid': 'shareReportButton',
        })
      ) : (
        <>
          {hideButton ? null : (
            <a
              className={`cursor-pointer ${open !== undefined && 'hidden'}`}
              data-testid="shareReportButton"
              onClick={() => setModalOpen(!modalOpen)}
            >
              <div className="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3">
                Share
              </div>
            </a>
          )}
        </>
      )}
      <Modal isOpen={modalOpen} onClose={closeModal}>
        <ModalHeader
          title={
            <span>
              Share <span className="font-normal">{data?.name}</span>
            </span>
          }
          onClose={closeModal}
        />
        {shared ? (
          <ModalSuccessView text={`${shareType} successfully shared`} />
        ) : (
          <div className="px-2 pt-2">
            {sharedWith.length > 0 && (
              <div className="flex px-2 py-1 flex-wrap">
                {sharedWith.map(({ email, name }, index) => (
                  <div
                    key={`share_report_modal_${data?.objectId}_member_${email}`}
                    className="bg-blue-50 text-xs font-bold py-1 px-2 text-secondary border border-blue-300 rounded-md flex items-center mr-1 mb-1 shadow-sm"
                  >
                    {email || name}{' '}
                    <button
                      className="focus:outline-none"
                      onClick={() => removeSharedWith(index)}
                    >
                      <FiX
                        size={16}
                        className="ml-1 text-secondary hover:opacity-60 cursor-pointer"
                      />
                    </button>
                  </div>
                ))}
              </div>
            )}
            {(data?.permissions?.can?.('create_public_link') ||
              workspace?.permissions?.can?.('create_public_link')) && modalOpen && (
                <ShareOptions shareable={data} shareableType={shareType} />
              )}
            <div className="bg-white border-b border-gray-200 overflow-hidden p-2 flex">
              <DelayedSearchInput
                key={`searching_for_member_open_${modalOpen}`}
                placeholder="Share by name, email or position..."
                defaultValue={searchParams.q}
                onSearched={(text) => {
                  setSearchParams({ ...searchParams, page: 1, q: text });
                  setMembers([]);
                }}
              />
              <AddMemberModal
                key={`search_field_add_member_${project_id}`}
                user={{ name: searchParams.q }}
                button={
                  <button
                    className="border-l px-4 flex items-center bg-gray-100 focus:outline-none"
                    title="Add New Member"
                  >
                    <FiPlus
                      size={16}
                      className="hover:opacity-60 cursor-pointer"
                    />
                  </button>
                }
                afterCreate={addToSharedWith}
              />
            </div>
            {(!loading || members.length > 0) && (
              <>
                {members.length === 0 && searchParams.q.trim() === '' && (
                  <>
                    <p className="font-bold text-lg text-gray-600 py-10 pb-5 text-center">
                      All workspace members have been added to the project
                    </p>
                    <div className="flex justify-center">
                      <AddMemberModal
                        user={{ name: searchParams.q }}
                        key={`all_members_added_add_member_${project_id}`}
                        button={
                          <button className="focus:outline-none border border-secondary hover:opacity-80 text-sm font-semibold bg-blue-50 text-secondary px-3 py-1 shadow cursor-pointer rounded-full mb-5">
                            + Add new member
                          </button>
                        }
                        afterCreate={addToSharedWith}
                      />
                    </div>
                  </>
                )}
                {members.length === 0 && searchParams.q.trim() !== '' && (
                  <>
                    <p className="font-bold text-lg text-gray-600 py-10 pb-5 text-center">
                      No member matching{' '}
                      <span className="italic mr-1 font-medium">
                        '{searchParams.q}'
                      </span>{' '}
                      found
                    </p>
                    <div className="flex justify-center">
                      <AddMemberModal
                        user={{
                          email: searchIsEmail(searchParams.q)
                            ? searchParams.q
                            : '',
                        }}
                        key={`empty_result_add_member_${project_id}`}
                        button={
                          <button className="focus:outline-none border border-secondary hover:opacity-80 text-sm font-semibold bg-blue-50 text-secondary px-3 py-1 shadow cursor-pointer rounded-full mb-5">
                            +{' '}
                            {searchIsEmail(searchParams.q)
                              ? `Invite ${searchParams.q}`
                              : 'Add new member'}
                          </button>
                        }
                        afterCreate={addToSharedWith}
                      />
                    </div>
                  </>
                )}
                <div className="max-h-72 overflow-y-scroll">
                  {membersNotCurrentlyShared().map((workspace_member) => {
                    return (
                      <AddUserItem
                        key={`report_${data?.objectId}_workspace_member_${workspace_member.objectId}`}
                        workspace_member={workspace_member}
                        onAdd={addToSharedWith}
                      />
                    );
                  })}
                  {!loading &&
                    pagy.count !== undefined &&
                    pagy.count !== members.length && (
                      <div
                        className="flex justify-center p-3 border-b border-gray-200"
                        onClick={() =>
                          setSearchParams({
                            ...searchParams,
                            page: searchParams.page + 1,
                          })
                        }
                      >
                        <a className="text-black hover:opacity-80 text-sm font-semibold bg-gray-100 px-4 py-2 shadow cursor-pointer">
                          Load More
                        </a>
                      </div>
                    )}
                </div>
              </>
            )}
            {loading && (
              <div className="flex justify-center py-5">
                <Loader color="black" />
              </div>
            )}
            <div className="flex items-center px-6 py-6 border-t justify-between border-gray-100 relative">
              {shareTypes.length === 0 && (
                <p className="text-xs absolute bottom-2 text-red-600 font-semibold ml-10">
                  Must share either pdf or public link
                </p>
              )}
              <div className="flex">
                {shareType == 'Report' && (
                  <>
                    <p className={`mr-2 text-gray-500 font-medium text-sm`}>
                      Send PDF
                    </p>
                    <div
                      className={`form-switch focus-within:shadow-outline mr-3`}
                      onClick={() => !sharing && toggleShareType('pdf')}
                    >
                      <input
                        type="checkbox"
                        id={`sendPDF_${data?.objectId}`}
                        className="sr-only"
                        checked={shareTypes.includes('pdf')}
                        onChange={() => { }}
                      />
                      <label
                        className="bg-gray-300"
                        htmlFor={`sendPDF_${data?.objectId}`}
                      >
                        <span
                          className="bg-white shadow-sm"
                          aria-hidden="true"
                        ></span>
                        <span className="sr-only">Send Include PDF</span>
                      </label>
                    </div>
                    <p className={`mr-2 text-gray-500 font-medium text-sm`}>
                      Send Public Link
                    </p>
                    <div
                      className={`form-switch focus-within:shadow-outline`}
                      onClick={() =>
                        false && !sharing && toggleShareType('public_link')
                      }
                    >
                      <input
                        type="checkbox"
                        id={`sendMagicLink_${data?.objectId}`}
                        className="sr-only"
                        checked={shareTypes.includes('public_link')}
                        onChange={() => { }}
                      />
                      <label
                        className="bg-gray-300"
                        htmlFor={`sendMagicLink_${data?.objectId}`}
                      >
                        <span
                          className="bg-white shadow-sm"
                          aria-hidden="true"
                        ></span>
                        <span className="sr-only">Send Public Link</span>
                      </label>
                    </div>{' '}
                  </>
                )}
              </div>
              <div className="flex">
                <button
                  className="modal-save-btn"
                  type="button"
                  disabled={sharedWith.length === 0 || shareTypes.length === 0}
                  onClick={sendShares}
                >
                  Share {sharing && <Loader />}
                </button>
                <button
                  className="modal-close-btn"
                  type="button"
                  onClick={() => setModalOpen(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
}
