import React from 'react';
import { FiX } from 'react-icons/fi';
export default function Header({ title, onClose }) {
    return (
        <div className="w-100 h-14 top-0 z-50 bg-secondary flex items-center px-2 sticky">
            <button
                className="focus:outline-none z-10 cursor-pointer bg-transparent hover:opacity-60 flex items-center h-12 w-12 rounded-full flex justify-center text-white"
                title="Close modal"
                onClick={onClose}
            >
                <FiX size={34} />
            </button>
            <p className="uppercase text-xl text-white text-center mx-auto w-full -mx-2 absolute z-0">
                {title}
            </p>
            <p></p>
        </div>
    );
}