import { useState, useEffect, useRef } from 'react';
import { FiEdit } from 'react-icons/fi';
import Loader from '../utility/Loader';
import { BiCheckCircle } from 'react-icons/bi';

const EditButton = ({ dataTestId = "editPinNoteButton", onClick }) => <button data-testId={dataTestId} className="absolute right-2 top-3 hover:opacity-70" onClick={onClick}>
    <FiEdit />
</button>

const NoteSaving = ({ saving, saved }) => <>
    {saving && (
        <div className="absolute top-3 right-2 flex items-center">
            <Loader color="text-gray-700" />
        </div>
    )}
    {saved && (
        <div className="absolute top-3 right-2 flex items-center">
            <BiCheckCircle
                size={16}
                className="bg-green-500 text-white rounded-full overflow-hidden"
            />
        </div>
    )}
</>

export default function EditableNote({
    dataTestId = "inputPinSiteNote",
    editable,
    onEditButtonClicked,
    onEdit,
    saving,
    saved,
    text
}) {

    const inputRef = useRef(null);
    const [ownerEditing, setOwnerEditing] = useState(false)

    const onBlur = (e) => {
        setOwnerEditing(false)
        onEdit?.(e)
    }

    useEffect(() => {
        if (!inputRef.current || inputRef.current.textContent) return;
        inputRef.current.textContent = text;
    }, [inputRef]);

    useEffect(() => {
        if (!ownerEditing) return;
        inputRef.current.focus();
        let sel = window.getSelection();
        sel.selectAllChildren(inputRef.current);
        sel.collapseToEnd();
    }, [inputRef, ownerEditing])

    return <div className="relative py-2 mt-1 px-3 bg-white rounded shadow-sm border border-gray-100">
        {editable && !(saving || saved) && <EditButton onClick={onEditButtonClicked ? onEditButtonClicked : () => setOwnerEditing(true)} />}
        {saving || saved && <NoteSaving saving={saving} saved={saved} />}
        <p
            className="text-gray-500 mr-4 whitespace-pre-line"
            contentEditable={editable}
            data-testId={dataTestId}
            ref={inputRef}
            onInput={onEdit}
            onBlur={onBlur}
        />
    </div>
}