import React, { useState } from 'react';
import Button from '../shared/Button';
import grid50BgImg from '../../images/bgs/square50x50-1px.png';
import grid30BgImg from '../../images/bgs/square30x30-1px.png';
import grid20BgImg from '../../images/bgs/square20x20-1px.png';
import grid10BgImg from '../../images/bgs/square10x10-1px.png';
import { MdGridOff, MdGridOn, MdGridView } from 'react-icons/md';

const gridBgImgs = {
  lg: grid50BgImg,
  md: grid30BgImg,
  sm: grid20BgImg,
  xs: grid10BgImg,
  off: '',
};

export default function PinDropOverlay({ on, onCancel, type = 'drop' }) {
  const [gridSize, setGridSize] = useState('md');

  return (
    <div
      className={`inset-px transition-opacity duration-200 ${
        on ? `opacity-100` : 'opacity-0'
      } absolute z-10 bg-secondary pointer-events-none bg-opacity-20 p-1 flex justify-between flex-col items-center shadow`}
      style={{
        backgroundImage: `url(${gridBgImgs[gridSize]})`,
      }}
      data-testid="pinDropOverlay"
    >
      {type === 'drop' && (
        <p className="text-white font-black mt-3 text-sm bg-black px-3 py-1 rounded-lg shadow">
          Click to Drop Pin
        </p>
      )}

      {on && type === 'drop' && (
        <div className="pointer-events-auto flex absolute top-4 right-4">
          <Button
            text=""
            color="light"
            className={`pointer-events-auto ${
              gridSize === 'lg' ? 'bg-opacity-100' : 'bg-opacity-70'
            }`}
            rounded="rounded-l"
            onClick={() => setGridSize('lg')}
            hover="hover:bg-opacity-100"
            icon={<MdGridView />}
          />
          <Button
            text=""
            color="light"
            className={`pointer-events-auto border-l ${
              gridSize === 'md' ? 'bg-opacity-100' : 'bg-opacity-70'
            }`}
            onClick={() => setGridSize('md')}
            hover="hover:bg-opacity-100"
            rounded="rounded-none"
            icon={<MdGridOn />}
          />
          <Button
            text=""
            color="light"
            spacing="mr-6"
            className={`pointer-events-auto ${
              gridSize === 'off' ? 'bg-opacity-100' : 'bg-opacity-70'
            }`}
            rounded="rounded-r"
            onClick={() => setGridSize('off')}
            hover="hover:bg-opacity-100"
            icon={<MdGridOff />}
          />

          <Button text="Cancel Drop" color="light" onClick={onCancel} />
        </div>
      )}
    </div>
  );
}
