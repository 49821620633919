import React, { useState, useEffect, useRef } from 'react';
import { BiCheckCircle } from 'react-icons/bi';
import entriesApi from '../../api/entries';
import useApi from '../../hooks/useApi';
import PinViewModel from '../../models/PinViewModel';
import collapseSequences from '../../utility/collapseSequences';
import Loader from '../utility/Loader';

export default function EntryViolation({
  entry: passedEntry,
  editing = true,
  pins,
}) {
  const {
    data: savedEntry,
    error,
    loading: saving,
    request: saveEntry,
  } = useApi(entriesApi.updateEntry, null);
  const [entry, setEntry] = useState(passedEntry);
  const [saved, setSaved] = useState(false);
  const savedTimer = useRef(null);

  const submitEntry = (value) => {
    entry.value = value;
    setEntry({ ...entry });
    saveEntry(entry.objectId, { value });
  };

  const includedPinNumbers = () =>
    pins
      .map((pin, index) => {
        let p = new PinViewModel(pin);
        let found = p
          .violationFields()
          .find(({ value }) => value === entry.name.toLowerCase());
        return { found, index };
      })
      .filter(({ found, index }) => found)
      .map(({ found, index }) => index + 1);

  useEffect(() => {
    savedEntry && setEntry({ ...savedEntry });
  }, [savedEntry]);

  useEffect(() => {
    savedEntry && setSaved(true);
  }, [savedEntry]);

  useEffect(() => {
    if (saved) {
      savedTimer.current = setTimeout(() => {
        setSaved(false);
      }, 2000);
    }
  }, [saved]);

  return (
    <>
      <div
        className={`border border-t-0 grid grid-cols-12 text-center ${
          editing && entry.requires_confirmation && 'bg-yellow-50'
        }`}
      >
        <div className="p-2 border-r">{entry.name}</div>
        <div className="p-2 border-r col-span-7 font-bold text-left relative">
          {entry.description}
          {saving && (
            <div className="absolute top-0 right-2 h-full flex items-center">
              <Loader color="text-gray-700" />
            </div>
          )}
          {saved && (
            <div className="absolute top-0 right-2 h-full flex items-center">
              <BiCheckCircle
                size={16}
                className="bg-green-500 text-white rounded-full overflow-hidden"
              />
            </div>
          )}
        </div>
        <div
          className={`p-2 border-r ${
            editing && 'hover:bg-blue-50 focus:outline-none cursor-pointer'
          }`}
          onClick={() => editing && submitEntry('yes')}
        >
          {entry.value === 'yes' && 'X'}
        </div>
        <div
          className={`p-2 border-r ${
            editing && 'hover:bg-blue-50 focus:outline-none cursor-pointer'
          }`}
          onClick={() => editing && submitEntry('no')}
        >
          {entry.value === 'no' && 'X'}
        </div>
        <div
          className={`p-2 border-r ${
            editing && 'hover:bg-blue-50 focus:outline-none cursor-pointer'
          }`}
          onClick={() => editing && submitEntry('na')}
        >
          {entry.value === 'na' && 'X'}
        </div>
        <div className="p-2 focus:outline-none">
          {collapseSequences(includedPinNumbers() || [])}
        </div>
      </div>
    </>
  );
}
