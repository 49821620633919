import { useState } from "react";

const DragPositionIndicator = () => <div className='h-1 bg-blue-500' />

export {
    DragPositionIndicator
}

export default function useHandleDraggableList({
    itemHeight,
    items,
    onItemsReordered,
}: {
    itemHeight: number,
    items: any[]
    onItemsReordered?: (items: any[]) => void
}): {
    onItemDrag: (index: number) => (e: any, data: any) => void,
    onItemDragStart: (index: number) => () => void,
    onItemDragStop: () => void,
    potentialNewIndex: number,
    draggedIndex: number,
} {

    const [draggedIndex, setDraggedIndex] = useState<number>(-1);
    const [potentialNewIndex, setPotentialNewIndex] = useState<number>(-1);

    const onItemDrag = (_: number) => (_: any, data: any) => {
        const newIndex = Math.floor((data.y + ((draggedIndex + 1) * itemHeight)) / itemHeight);
        if (newIndex > -1 && newIndex < items.length) setPotentialNewIndex(newIndex);
        else setPotentialNewIndex(draggedIndex);
    };

    const onItemDragStart = (index: number) => () => {
        setDraggedIndex(index);
        setPotentialNewIndex(index);
    };

    const onItemDragStop = () => {
        if (potentialNewIndex !== draggedIndex) {
            const tempOrderItems = [...items];
            const draggedItem = tempOrderItems[draggedIndex];
            tempOrderItems.splice(draggedIndex, 1);
            tempOrderItems.splice(potentialNewIndex, 0, draggedItem);
            onItemsReordered?.(tempOrderItems);
        }
        setDraggedIndex(-1);
        setPotentialNewIndex(-1);
    }

    return {
        onItemDrag,
        onItemDragStart,
        onItemDragStop,
        potentialNewIndex,
        draggedIndex,
    }

}
