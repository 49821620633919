import { useMemo } from 'react';
import { PinFilterWhereParams } from '../data/models';

export const useFilterCount = (currentFilter: PinFilterWhereParams | undefined) => {
    const defaultFilter: PinFilterWhereParams = useMemo(() => ({ private: [true, false] }), []);

    const isDefaultFilter = useMemo(() => {
        return JSON.stringify(currentFilter) === JSON.stringify(defaultFilter);
    }, [currentFilter, defaultFilter]);

    const nonDefaultFiltersCount = useMemo(() => {
        if (!currentFilter) return 0;

        const nonDefaultKeys = Object.keys(currentFilter).filter(key => !(key in defaultFilter));
        return nonDefaultKeys.length;
    }, [currentFilter, defaultFilter]);

    return {
        isDefaultFilter,
        nonDefaultFiltersCount,
        defaultFilter,
    };
};