import React, { useEffect } from 'react';
import appConfigApi from '../api/app_config';
import useAuth from '../auth/useAuth';
import useApi from '../hooks/useApi';

const FeatureFlagContext = React.createContext();
export default FeatureFlagContext;
function FeatureFlagProvider({ children }) {
  const { data: features, request: getAppConfig } = useApi(
    appConfigApi.getAppConfig,
    {},
    true
  );
  const { user } = useAuth();

  useEffect(() => {
    getAppConfig();
  }, [user?.updated_at]);

  const value = {
    features,
  };

  return (
    <FeatureFlagContext.Provider value={value}>
      {children}
    </FeatureFlagContext.Provider>
  );
}

export { FeatureFlagProvider };
