import { useState, useEffect } from 'react';
import Modal, { ModalHeader } from '../utility/Modal';
import { useParams } from 'react-router-dom';
import Loader from '../utility/Loader';
import projectMembersApi from '../../api/project_members';
import DelayedSearchInput, { SearchContainer } from '../utility/DelayedSearchInput';
import useApi from '../../hooks/useApi';
import AddPinDropperItem from './AddPinDropperItem';
import ErrorViewModel from '../../models/ErrorViewModel';
import useModal from '../../hooks/useModal';

const AvatarSizes = {
  "7": "w-7 h-7",
  "5": "w-5 h-5",
  "3": "w-3 h-3",
}
export default function PinDroppersButton({
  buttonClassName = "border border-gray-200 focus:outline-none flex flex-shrink-0 items-center -space-x-3 -ml-px bg-white py-2 px-2 rounded-lg shadow hover:opacity-80 cursor-pointer",
  emptyState = <span className="mr-5 font-medium">N/A</span>,
  chosenIds = [],
  avatarSize = "7",
  onClick,
  onChosen,
  showName = true,
}) {
  const {
    data: { records, pagy },
    error: errorMembers,
    loading,
    request: loadProjectMembers,
  } = useApi(
    projectMembersApi.getProjectMembers,
    { records: [], pagy: {} },
    true
  );
  const [error, setError] = useState(null);
  const [droppedBy, setDroppedBy] = useState([]);
  const {
    open: modalOpen,
    setOpen: setModalOpen,
    toggle: toggleModal,
  } = useModal();
  const [searchParams, setSearchParams] = useState({
    page: 1,
    q: '',
    items: 10,
    has_updated: true,
  });
  const [members, setMembers] = useState([]);
  const { project_id } = useParams();

  const updateDroppedBy = (member, chosen) => {
    if (chosen) {
      let newDroppedBy = droppedBy.filter(({ id }) => id !== member.id);
      if (newDroppedBy.length === 0)
        return setError(
          new ErrorViewModel({ pin_dropper: 'at least one required' })
        );
      setDroppedBy([...droppedBy.filter(({ id }) => id !== member.id)]);
      onChosen?.(member.id, chosen);
    } else {
      setDroppedBy([...droppedBy, member]);
      onChosen?.(member.id, chosen);
    }
  };

  useEffect(() => {
    loadProjectMembers(project_id, searchParams);
  }, [searchParams]);

  useEffect(() => {
    records.length && setMembers([...members, ...records]);
  }, [records]);

  useEffect(() => {
    members.length &&
      chosenIds.length &&
      setDroppedBy([
        ...members
          .filter(({ member: { id } }) => chosenIds.includes(id))
          .map(({ member }) => member),
      ]);
  }, [members, chosenIds]);

  return (
    <>
      <button
        onClick={onClick || toggleModal}
        className={buttonClassName}
        title={`${droppedBy.map(({ name }) => name).join(', ')}`}
      >
        {loading && <Loader margin={''} color={'text-gray-800'} />}
        {(chosenIds.length === 0 || droppedBy.length === 0) && !loading && (
          emptyState
        )}
        {!!chosenIds.length && droppedBy.length === 1 && showName && (
          <span className="mr-5 font-medium">{droppedBy[0].name}</span>
        )}
        {!!chosenIds.length && droppedBy.map((member) => {
          return (
            <img
              key={`pin_dropper_member_${member.objectId}`}
              className={`rounded-full border-2 border-white box-content object-cover ${AvatarSizes[avatarSize]}`}
              src={member.profile_photos.thumbnail}
              alt={member.name}
            />
          );
        })}
        {chosenIds.length > 3 && (
          <div className={`rounded-full border-2 border-white box-content bg-primary text-white text-xs flex items-center justify-center ${AvatarSizes[avatarSize]}`}>
            <p>+2</p>
          </div>
        )}
      </button>

      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
        <ModalHeader title={'Update Pin Droppers'} onClose={toggleModal} />
        {error && (
          <div className="">
            <div className="bg-red-100 text-red-600 px-3 py-2 rounded">
              <span className="text-sm">{error.text()}</span>
            </div>
          </div>
        )}
        <SearchContainer>
          <DelayedSearchInput
            key={`searching_for_pin_droppers_${project_id}_open_${modalOpen}`}
            placeholder="Search Team Members..."
            defaultValue={searchParams.q}
            onSearched={(text) => {
              setSearchParams({ ...searchParams, page: 1, q: text });
              setMembers([]);
            }}
          />
        </SearchContainer>
        {(!loading || members.length > 0) && (
          <>
            {members.length === 0 && searchParams.q.trim() === '' && (
              <p className="empty-data-txt text-center">
                All projects members being used for report
              </p>
            )}
            {members.length === 0 && searchParams.q.trim() !== '' && (
              <p className="empty-data-txt text-center">
                No member matching{' '}
                <span className="italic mr-1 font-medium">
                  '{searchParams.q}'
                </span>{' '}
                found <br /> that can be added as pin dropper
              </p>
            )}
            <div className="max-h-72 overflow-y-scroll">
              {members.map((project_member) => {
                let chosen = droppedBy.find(
                  ({ id }) => id === project_member.member.id
                );
                return (
                  <AddPinDropperItem
                    key={`project_${project_id}_project_member_${project_member.objectId}_pin_dropper`}
                    project_member={project_member}
                    chosen={chosen}
                    onChosen={updateDroppedBy}
                  />
                );
              })}
              {!loading &&
                pagy.count !== undefined &&
                pagy.count !== members.length && (
                  <div
                    className="flex justify-center p-3 border-b border-gray-200"
                    onClick={() =>
                      setSearchParams({
                        ...searchParams,
                        page: searchParams.page + 1,
                      })
                    }
                  >
                    <a className="text-black hover:opacity-80 text-sm font-semibold bg-gray-100 px-4 py-2 shadow cursor-pointer">
                      Load More
                    </a>
                  </div>
                )}
            </div>
          </>
        )}

        {loading && (
          <div className="flex justify-center py-5">
            <Loader color="black" />
          </div>
        )}
        <div className="modal-footer">
          <button
            className="modal-close-btn"
            type="button"
            onClick={toggleModal}
          >
            done
          </button>
        </div>
      </Modal>
    </>
  );
}
