import React, { memo } from 'react';
import EntrySignature from '../entries/EntrySignature';

function EntryGroupSignatures({ report, editing, entries }) {
  return (
    <div className="mx-5 py-5 text-xs relative h-54">
      {entries.map((entry, index) => (
        <EntrySignature
          key={`report_${report.objectId}_entry_signature_${entry.objectId}`}
          entry={entry}
          editing={editing}
        />
      ))}
      {entries.length === 0 && 'N/A'}
    </div>
  );
}

export default memo(EntryGroupSignatures);
