import { memo, useEffect, useState } from 'react';
import projectsApi from '../../api/projects';
import documentsApi from '../../api/documents';
import foldersApi from '../../api/folders';
import useApi from '../../hooks/useApi';
import useModal from '../../hooks/useModal';
import useProjectContext from '../../hooks/useProjectContext';
import Modal, { ModalHeader } from '../utility/Modal';
import MoveDocumentTree from './MoveDocumentTree';

const MoveDocumentModal = ({ row, currentFolderId, onMove }) => {
  const { project } = useProjectContext();

  const {
    open: modalOpen,
    toggle: toggleModal,
    setOpen: setModalOpen,
  } = useModal();

  const {
    data: projectTreeData,
    error: projectTreeError,
    request: projectTreeRequest,
    loading: projectTreeLoading,
  } = useApi(projectsApi.getProjectFolderTree, null);
  const {
    data: documentData,
    error: documentError,
    request: documentRequest,
    loading: documentLoading,
  } = useApi(documentsApi.updateDocument, null);
  const {
    data: folderData,
    error: folderError,
    request: folderRequest,
    loading: folderLoading,
  } = useApi(foldersApi.updateFolder, null);

  const [folderSections, setFolderSections] = useState([]);
  const [selectedFolderObjectId, setSelectedFolderObjectId] = useState(null);

  useEffect(() => {
    if (!modalOpen) return;
    projectTreeRequest(project.objectId, project.root_folder.objectId);
  }, [modalOpen]);

  useEffect(() => {
    if (!projectTreeData) return;
    const results = projectTreeData.map((x) => ({
      ...x,
      parent: x.parent ?? 0,
      hasChildren: projectTreeData.filter((y) => y.parent === x.id)?.length > 0,
    }));
    setFolderSections(results);
  }, [projectTreeData]);

  useEffect(() => {
    if (!documentData && !folderData) return;
    onMove();
    setSelectedFolderObjectId(null);
    toggleModal();
  }, [documentData, folderData]);

  const submitMove = () => {
    if (!selectedFolderObjectId) return;

    if (row.type === 'folder') {
      folderRequest(project.objectId, row.objectId, {
        name: row.name,
        description: row.description,
        folder_id: selectedFolderObjectId,
      });
    } else {
      documentRequest(project.objectId, currentFolderId, row.objectId, {
        name: row.name,
        description: row.description,
        folder_id: selectedFolderObjectId,
      });
    }
  };

  return (
    <>
      <a
        className="focus:outline-none font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3"
        rel="noreferrer"
        onClick={(e) => toggleModal()}
      >
        Move
      </a>
      <Modal
        isOpen={modalOpen}
        onClose={toggleModal}
        modalClass={'rounded-lg cursor-default'}
      >
        <ModalHeader title={`Move ${row.name}`} onClose={toggleModal} />

        <div className="flex flex-col text-left text-base px-5 py-3 bg-white">
          <MoveDocumentTree
            folderSections={folderSections}
            parentId={0}
            level={0}
            selectedFolderObjectId={selectedFolderObjectId}
            onSelectFolder={(objectId) => setSelectedFolderObjectId(objectId)}
          />
        </div>

        <div className="modal-footer">
          <button
            className="modal-save-btn"
            type="button"
            onClick={submitMove}
            disabled={projectTreeLoading || !selectedFolderObjectId}
            data-testid="test-move-btn"
          >
            Move
          </button>
          <button
            className="modal-close-btn"
            type="button"
            onClick={() => {
              toggleModal();
            }}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};

export default memo(MoveDocumentModal);
