import React, { memo, useEffect, useState } from 'react';
import Modal from '../utility/Modal';
import useApi from '../../hooks/useApi';
import rolesApi from '../../api/roles';
import Loader from '../utility/Loader';
import { FiX, FiInfo } from 'react-icons/fi';

function PermissionsInfoModal({ button }) {
  const [modalOpen, setModalOpen] = useState(false);
  const {
    data: roles,
    error,
    loading,
    request: getRoles,
  } = useApi(rolesApi.getRoles, []);

  useEffect(() => {
    modalOpen && getRoles();
  }, [modalOpen]);

  return (
    <div>
      {/* Button */}
      <a className="cursor-pointer" onClick={() => setModalOpen(!modalOpen)}>
        {button || (
          <div className="font-medium text-sm text-secondary hover:text-gray-800 flex py-1 px-3">
            <FiInfo size={18} />
          </div>
        )}
      </a>
      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
        <div className="flex items-center justify-between px-5 py-3 bg-primary">
          <p className="text-lg font-bold text-white">Permission Levels</p>
          <button
            className="focus:outline-none"
            onClick={() => setModalOpen(false)}
          >
            <FiX
              size={28}
              className="text-white hover:opacity-60 cursor-pointer"
            />
          </button>
        </div>
        <div className="px-2 pt-2">
          <div className="p-5">
            {loading && (
              <div className="flex justify-center items-center h-36">
                <Loader margin={''} color="gray-800" />
              </div>
            )}
            {!loading &&
              roles
                .filter((r) => r.name.indexOf('workspace') > -1)
                .map(({ id, name, description }) => (
                  <div
                    className="p-2 mt-1 rounded-md text-sm"
                    key={`edit_permissions_role_${id}`}
                  >
                    <div className="group">
                      <div className="flex items-center">
                        <div className="font-semibold uppercase font-semibold">
                          {name.replace('workspace_', '')}
                        </div>
                      </div>
                      <div className="leading-snug text-gray-600 font-normal">
                        {description}
                      </div>
                    </div>
                  </div>
                ))}
          </div>

          <div className="modal-footer">
            <button
              className="modal-close-btn"
              type="button"
              onClick={() => setModalOpen(false)}
            >
              Got it
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default memo(PermissionsInfoModal);
