import { useCallback, useState, useRef, useEffect, useMemo } from 'react';
import Transition from '../../utils/Transition';
import { BsFilter } from 'react-icons/bs';
import useApi from '../../hooks/useApi';
import pinTypesApi from '../../api/pin_types';
import { useParams } from 'react-router-dom';
import Loader from '../utility/Loader';
import Tags from '../shared/Tags';
import { FiFilter, FiUser, FiX } from 'react-icons/fi';
import useFeatures from '../../hooks/useFeatures';
import { PinFilterWhereParams, PinType } from '../../data/models';
import PinDroppersButton from './PinDroppersButton';
import PinDroppersModal from './PinDroppersModal';
import { useFilterCount } from '../../hooks/useFilterCount';

function PinsFilterDropdown({ currentFilter, onFilter }: {
  currentFilter: undefined | PinFilterWhereParams
  onFilter: (newFilter: PinFilterWhereParams) => void
}) {
  let { workspace_id, project_id } = useParams<{ workspace_id: string, project_id: string }>();
  const {
    data: pin_types,
    loading,
    request: getPinTypes,
  } = useApi(pinTypesApi.getPinTypes, []);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [choosingPinDroppers, setChoosingPinDroppers] = useState(false);
  const [creatorIds, setCreatorIds] = useState(currentFilter?.notes?.notetaker_id || []);
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>(currentFilter?.status || []);
  const [selectedPinType, setSelectedPinType] = useState<null | number>(null);
  const [includePrivate, setIncludePrivate] = useState<boolean | boolean[]>(true);
  const [fieldValues, setFieldValues] = useState<any[]>([]);
  const [filterableFields, setFilterableFields] = useState<any[]>([]);
  const [days, setDays] = useState<number | null>(null);
  const [tags, setTags] = useState<Array<{ name: string }>>([]);
  const { features: { rollout: { pin_tags = false } = {} } = {} } = useFeatures();
  const trigger = useRef<HTMLButtonElement | null>(null);

  const { isDefaultFilter, nonDefaultFiltersCount } = useFilterCount(currentFilter);

  const newFilter = () => {
    let tempNewFilter: Partial<PinFilterWhereParams> = {};
    if (selectedStatuses.length) tempNewFilter.status = selectedStatuses;
    if (selectedPinType) tempNewFilter.pin_type_id = selectedPinType;
    if (!!creatorIds.length) tempNewFilter.notes = {
      notetaker_id: creatorIds
    }
    tempNewFilter.private = includePrivate ? [true, false] : [false];
    if (!!tags.length) tempNewFilter.tags = tags.map(({ name }) => name);
    if (fieldValues.length > 0)
      tempNewFilter.field_values = {
        value: fieldValues.map(({ value }) => value),
      };
    if (days) tempNewFilter.days = days;
    return tempNewFilter;
  };

  const submitNewFilter = () => onFilter?.(newFilter());

  const uniqueStatuses = useCallback(() => {
    const uniqueValues: any[] = [];

    const unique = pin_types
      .map(({ statuses }: {
        statuses: {
          value: string
        }[]
      }) => statuses)
      .flat()
      .filter((element: {
        value: string
      }) => {
        const isDuplicate = uniqueValues.includes(element.value);

        if (!isDuplicate) {
          uniqueValues.push(element.value);

          return true;
        }

        return false;
      });

    return unique;
  }, [pin_types]);

  const updateFilterableFields = () => {
    const tempFilterableFields: any[] = [];
    pin_types.forEach((type: PinType) => {
      type.fieldsets
        .filter(({ name }) => name !== 'Status')
        .forEach((set) => {
          set.fields.forEach((field) => {
            if (field.filters_pin) {
              field.field_options.forEach(({ value, name }) => {
                let existingFieldSetIndex = tempFilterableFields.findIndex(
                  ({ name }) => name === set.name
                );
                if (existingFieldSetIndex !== -1)
                  tempFilterableFields[existingFieldSetIndex].fields.filter(
                    ({ value: v }: {
                      value: any
                    }) => v === value
                  ).length === 0 &&
                    tempFilterableFields[existingFieldSetIndex].fields.push({
                      name,
                      value,
                    });
                else
                  tempFilterableFields.push({
                    name: set.name,
                    fields: [{ name, value }],
                  });
              });
            }
          });
        });
    });

    setFilterableFields(tempFilterableFields);
  };

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: {
      keyCode: number
    }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {
    getPinTypes(workspace_id);
  }, []);

  useEffect(() => {
    updateFilterableFields();
  }, [pin_types]);

  const pinDroppersButton = useMemo(
    () => (
      <PinDroppersButton
        chosenIds={creatorIds}
        avatarSize='5'
        onClick={() => setChoosingPinDroppers(true)}
        buttonClassName="text-xs inline-flex font-medium rounded-full text-center p-1 border border-dashed border-gray-300 bg-white mr-1"
        emptyState={<FiUser />}
        showName={false}
        onChosen={(chosenId: any, chosen: any) => {
          chosen
            ? setCreatorIds((existing: any) => ({
              ...existing.filter((id: number) => id !== chosenId)
            }))
            : setCreatorIds((existing: any) => ({
              ...existing, chosenId
            }))
        }}
      />
    ),
    [JSON.stringify(creatorIds)]
  );

  return (
    <>
      <Transition
        className="absolute top-0 left-0 w-full h-full bg-white z-10 overflow-y-scroll overflow-x-visible"
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-x-2"
        enterEnd="opacity-100 translate-x-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        appear={undefined}
      >
        <div className="sticky z-10 top-0 left-0 bg-white py-2 px-3 mb-1 border-b border-gray-200 flex justify-between items-center">
          <div className="font-semibold text-gray-800">
            Filter pins
            <button
              disabled={currentFilter === null && !tags.length}
              onClick={() => {
                setSelectedPinType(null);
                setDays(null);
                setFieldValues([]);
                setCreatorIds([]);
                setSelectedStatuses([]);
                setTags([]);
              }}
              className="ml-2 cursor-pointer text-secondary text-xs hover:underline disabled:opacity-50"
            >
              Reset
            </button>
          </div>

          <button
            className="focus:outline-none"
            data-testid="pinsFilterDropdownClose"
            onClick={() => setDropdownOpen(false)}
          >
            <FiX size={28} className="hover:opacity-60 cursor-pointer" />
          </button>
        </div>
        {loading ? (
          <div className="py-5 flex justify-center w-full h-full items-center">
            <Loader margin={''} color="text-gray-600" />
          </div>
        ) : (
          <div className="bg-gray-50">
            <div className="pt-0.5 pb-3 px-3 mb-1 border-b border-gray-200">
              <div className="text-xs text-gray-500 italic mt-1">
                Time Frame
              </div>
              <div className="pt-2 text-xs">
                <div className="flex items-center">
                  <div>Last</div>
                  <div className="mx-2 border rounded-md overflow-hidden text-secondary">
                    <input
                      type="number"
                      key={`days_filter`}
                      placeholder="1"
                      min={1}
                      value={days || ''}
                      onChange={({ target: { value } }) => setDays(parseInt(value))}
                      className="text-center py-1 px-2 placeholder-gray-400 relative text-xs border-0 w-14 border-0 outline-none focus:outline-none focus:ring-0"
                    />
                  </div>
                  <div>day(s)</div>
                </div>
              </div>
            </div>
            <div className="pt-0.5 pb-2 px-3 mb-1 border-b border-gray-200">
              <div className="text-xs text-gray-500 italic mt-1">Dropped By</div>

              <div className="pt-2 text-xs">
                {pinDroppersButton}
              </div>
            </div>
            {pin_tags && (
              <div className="pt-0.5 pb-2 px-3 mb-1 border-b border-gray-200">
                <div className="text-xs text-gray-500 italic mt-1">Tags</div>
                <Tags
                  tags={tags}
                  taggableType="Pin"
                  maxListHeight="max-h-40"
                  keyPrefix={`${project_id}tagPinFilter`}
                  containerClassName="normal-case font-normal font-inter items-center flex my-2 flex-wrap"
                  tagClassName="mr-1 mb-1"
                  canAdd
                  allowCreate={false}
                  onAdd={(newTag: {
                    name: string,
                    color: string,
                    id: number
                  }) => {
                    setTags([...tags.filter(({ name }) => name !== newTag.name), newTag])
                  }
                  }
                  onRemove={(oldTag: { name: string; }) => setTags([
                    ...tags.filter(({ name }) => name !== oldTag.name),
                  ])
                  }
                  onClearTags={() => setTags([])}
                  maxTags={0}
                  triggerTestId="pinFilterTagTrigger"
                  onDestroy={undefined}
                  onUpdate={undefined}
                />
              </div>
            )}

            <div className="pt-0.5 pb-2 px-3 mb-1 border-b border-gray-200">
              <div className="text-xs text-gray-500 italic mt-1">Private Pins</div>
              <div className="pb-2 text-xs text-secondary">
                <a
                  className="cursor-pointer flex items-center mt-2 group"
                  onClick={() => setIncludePrivate(true)}
                >
                  <div
                    className={`w-3 h-3 border rounded-full mr-2 border-secondary ${includePrivate
                      ? 'bg-secondary'
                      : 'group-hover:bg-blue-200'
                      }`}
                  ></div>
                  <div>Include</div>
                </a>
                <a
                  className="cursor-pointer flex items-center mt-2 group"
                  onClick={() => setIncludePrivate(false)}
                >
                  <div
                    className={`w-3 h-3 border rounded-full mr-2 border-secondary ${!includePrivate
                      ? 'bg-secondary'
                      : 'group-hover:bg-blue-200'
                      }`}
                  ></div>
                  <div>Exclude</div>
                </a>
              </div>
            </div>

            <div className="pt-0.5 pb-2 px-3 mb-1 border-b border-gray-200">
              <div className="text-xs text-gray-500 italic mt-1">Pin Type</div>
              <div className="pb-2 text-xs text-secondary">
                <a
                  className="cursor-pointer flex items-center mt-2 group"
                  onClick={() => setSelectedPinType(null)}
                >
                  <div
                    className={`w-3 h-3 border rounded-full mr-2 border-secondary ${selectedPinType === null
                      ? 'bg-secondary'
                      : 'group-hover:bg-blue-200'
                      }`}
                  ></div>
                  <div>All</div>
                </a>
                {pin_types.map(({ id, name }: {
                  id: number,
                  name: string
                }) => (
                  <a
                    key={`pin_filter_dropdown_pin_status_${id}`}
                    className="cursor-pointer flex items-center mt-2 group"
                    onClick={() => setSelectedPinType(id)}
                  >
                    <div
                      className={`w-3 h-3 border rounded-full mr-2 border-secondary ${selectedPinType === id
                        ? 'bg-secondary'
                        : 'group-hover:bg-blue-200'
                        }`}
                    ></div>
                    <div>{name}</div>
                  </a>
                ))}
              </div>
            </div>

            <div className="pt-0.5 pb-2 px-3 mb-1 border-b border-gray-200">
              <div className="text-xs text-gray-500 italic mt-1">
                Pin Status
              </div>
              <div className="pb-2 text-xs text-secondary">
                <a
                  className="cursor-pointer flex items-center mt-2 group"
                  data-testid="pinsFilterDropdownStatusAll"
                  onClick={() => setSelectedStatuses([])}
                >
                  <div
                    className={`w-3 h-3 border rounded-full mr-2 border-secondary ${selectedStatuses.length === 0
                      ? 'bg-secondary'
                      : 'group-hover:bg-blue-200'
                      }`}
                  ></div>
                  <div>All</div>
                </a>
                {uniqueStatuses().map(({ id, name, value }: {
                  id: number,
                  name: string,
                  value: string
                }) => (
                  <a
                    key={`pin_filter_dropdown_pin_type_${id}`}
                    className="cursor-pointer flex items-center mt-2 group"
                    onClick={() =>
                      selectedStatuses.includes(value)
                        ? setSelectedStatuses([
                          ...selectedStatuses.filter((v) => v !== value),
                        ])
                        : setSelectedStatuses([...selectedStatuses, value])
                    }
                  >
                    <div
                      className={`w-3 h-3 border rounded-full mr-2 border-secondary ${selectedStatuses.includes(value)
                        ? 'bg-secondary'
                        : 'group-hover:bg-blue-200'
                        }`}
                    ></div>
                    <div>{name}</div>
                  </a>
                ))}
              </div>
            </div>

            {filterableFields.map(({ name: setName, fields }: {
              name: string,
              fields: any[],
            }, index) => {
              let allSelected =
                fieldValues.filter(({ name: n }) => n === setName).length === 0;

              return (
                <div
                  key={`pin_filter_dropdown_filter_fields_${index}`}
                  className={`pt-0.5 px-3 mt-1 border-b border-gray-200`}
                >
                  <div
                    className="text-xs text-gray-500 italic mt-1"
                    data-testid={`pinFilterFilterableField${setName.replace(
                      ' ',
                      ''
                    )}`}
                  >
                    {setName}
                  </div>
                  <div className="pb-2 text-xs text-secondary">
                    <a
                      key={`pin_filter_dropdown_filter_fields_field_${setName}_all`}
                      className="cursor-pointer flex items-center mt-2 group"
                      onClick={() => {
                        if (allSelected) return;

                        setFieldValues([
                          ...fieldValues.filter(
                            ({ name: n, value: v }) => n !== setName
                          ),
                        ]);
                      }}
                    >
                      <div
                        className={`w-3 h-3 border rounded-full mr-2 border-secondary ${allSelected
                          ? 'bg-secondary'
                          : 'group-hover:bg-blue-200'
                          }`}
                      ></div>
                      <div>All</div>
                    </a>

                    {fields.map(({ name, value }: {
                      name: string,
                      value: any
                    }) => {
                      let fieldAdded =
                        fieldValues.filter(
                          ({ name: n, value: v }) =>
                            value === v && n === setName
                        ).length > 0;

                      return (
                        <a
                          key={`pin_filter_dropdown_filter_fields_field_${value}`}
                          data-testid={`pinFilterDropdownFilterFieldsField${value}`}
                          className="cursor-pointer flex items-center mt-2 group"
                          onClick={() =>
                            fieldAdded
                              ? setFieldValues([
                                ...fieldValues.filter(
                                  ({ name: n, value: v }) => {
                                    return !(n === setName && v === value);
                                  }
                                ),
                              ])
                              : setFieldValues([
                                ...fieldValues,
                                { name: setName, value },
                              ])
                          }
                        >
                          <div
                            className={`w-3 h-3 border rounded-full mr-2 border-secondary ${fieldAdded
                              ? 'bg-secondary'
                              : 'group-hover:bg-blue-200'
                              }`}
                          ></div>
                          <div>{name}</div>
                        </a>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <div className="py-2 px-3 bottom-0 right-0 sticky bg-gray-200">
          <button
            className="w-full cursor-pointer disabled:opacity-50 text-center text-xs font-semibold bg-secondary text-white py-1 px-2 rounded-md"
            disabled={
              JSON.stringify(currentFilter) === JSON.stringify(newFilter())
            }
            onClick={submitNewFilter}
            data-testid="pinFilterDropdownUpdateButton"
          >
            Update Results
          </button>
        </div>
      </Transition >
      <button
        ref={trigger}
        aria-haspopup="true"
        data-testid="pinFilterDropdownButton"
        className={`${!isDefaultFilter ? 'shadow-md border-gray-300' : 'bg-white'
          } w-full h-full focus:outline-none flex items-center justify-center text-secondary relative border rounded-md`}
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        {!isDefaultFilter && nonDefaultFiltersCount > 0 && <span className="absolute text-xxs -top-2 font-semibold -right-2 w-4 h-4 bg-secondary rounded-full text-white">
          {nonDefaultFiltersCount}
        </span>}
        <FiFilter size={20} />
      </button>
      <PinDroppersModal
        chosenIds={creatorIds}
        open={choosingPinDroppers}
        onClose={() => setChoosingPinDroppers(false)}
        onChosen={(member: { id: number }, chosen: any) => {
          chosen
            ? setCreatorIds((existing: any) => ([
              ...existing.filter((id: number) => id !== member.id)
            ]))
            : setCreatorIds((existing: any) => ([
              ...existing, member.id
            ]))
        }}
      />
    </>
  );
}

export default PinsFilterDropdown;