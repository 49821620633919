import { invited, login } from './endpoints/auth';
import { loadProject, loadProjects } from './endpoints/projects';
import { loadPinTypes } from './endpoints/pin_types';

const requests = {
  '/invited': invited,
  '/login': login,
  '/projects': loadProjects,
  '/pin_types': loadPinTypes,
};

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const dummyRequest = async (url, params, axiosConfig) => {
  await delay(100);

  console.log('requests[url]', requests[url]);

  if (requests[url]) {
    return requests[url](params);
  }

  if (url.indexOf('/projects/') > -1) {
    let objectId = url.replace('/projects/', '');
    return loadProject(objectId);
  }

  return false;
};

export default dummyRequest;
