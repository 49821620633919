const collapseSequences = (arr) => {
  var i,
    idx = 0,
    val,
    len = arr.length;
  const res = [];
  while (idx < len) {
    i = 0;
    val = arr[idx++];
    while (idx + i < len && arr[idx + i] === val + i + 1) {
      i++;
    }
    res.push(val + (i > 1 ? '-' + arr[(idx += i) - 1] : ''));
  }
  return res.join(', ');
};
export default collapseSequences;
