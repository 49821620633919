import React from 'react';
import ErrorView from '../utility/ErrorView';
import ErrorViewModel from '../../models/ErrorViewModel';
import Form from '../../utils/Form';
interface AddProjectContactFormProps {
  formData: {
    id?: number;
    role: string;
    company?: string;
    contact_name: string;
    address_line_1: string;
    address_line_2?: string;
    phone?: string;
    email?: string;
    created_at?: string;
    updated_at?: string;
  };
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error: ErrorViewModel | null | unknown;
}

const AddProjectContactForm = ({ formData, handleChange, error }: AddProjectContactFormProps) => {
  return (
    <form className="text-base text-gray-800 space-y-4 bg-gray-50 max-w-3xl w-full max-h-auto shadow-lg rounded-lg overflow-hidden p-4">
      {error ? <ErrorView error={error} extraClass={'mb-4'} /> : null}
      <Form.Group className="border-gray-200 border-l-0 border-t-0 border-b border-r-0">
        <Form.Label>
          Contact Name <span className="ml-2 text-red-500">*</span>
        </Form.Label>
        <Form.Input
          type="text"
          placeholder="Ex: Landon Messal"
          name="contact_name"
          value={formData.contact_name}
          onChange={handleChange}
          required
          data-testid="contactName"
          className="px-2 bg-gray-50 placeholder-gray-500 relative text-sm border-0 w-full border-0 outline-none focus:outline-none"
        />
      </Form.Group>
      <div className="flex items-center">
        <Form.Group className="flex-1 border-l-0 border-t-0 border-b border-r border-gray-200">
          <Form.Label>Role</Form.Label>
          <Form.Input
            type="text"
            placeholder="Head Engineer"
            name="role"
            value={formData.role}
            onChange={handleChange}
            required
            data-testid="contactRole"
            className="px-2 bg-gray-50 placeholder-gray-500 relative text-sm border-0 w-full border-0 outline-none focus:outline-none"
          />
        </Form.Group>
        <Form.Group className="flex-1 px-2 border-l-0 border-t-0 border-b border-r-0 border-gray-200">
          <Form.Label>Company</Form.Label>
          <Form.Input
            type="text"
            placeholder="Site Marker Inc"
            name="company"
            value={formData.company}
            onChange={handleChange}
            required
            data-testid="contactCompany"
            className="px-2 bg-gray-50 placeholder-gray-500 relative text-sm border-0 w-full border-0 outline-none focus:outline-none"
          />
        </Form.Group>
      </div>
      <div className="flex items-center">
        <Form.Group className="flex-1 border-l-0 border-t-0 border-b border-r border-gray-200">
          <Form.Label>Address</Form.Label>
          <Form.Input
            type="text"
            placeholder="501 Wando Park Blvd."
            name="address_line_1"
            value={formData.address_line_1}
            onChange={handleChange}
            required
            data-testid="contactAddressLine1"
            className="px-2 bg-gray-50 placeholder-gray-500 relative text-sm border-0 w-full border-0 outline-none focus:outline-none"
          />
        </Form.Group>
        <Form.Group className="flex-1 px-2 border-l-0 border-t-0 border-b border-r-0 border-gray-200">
          <Form.Label>City, State, Zip</Form.Label>
          <Form.Input
            type="text"
            placeholder="Charleston, SC, 29464"
            name="address_line_2"
            value={formData.address_line_2}
            onChange={handleChange}
            data-testid="contactAddressLine2"
            className="px-2 bg-gray-50 placeholder-gray-500 relative text-sm border-0 w-full border-0 outline-none focus:outline-none"
          />
        </Form.Group>
      </div>
      <div className="flex items-center">
        <Form.Group className="flex-1 border-l-0 border-t-0 border-b border-r border-gray-200">
          <Form.Label>
            Phone <span className="ml-2 text-red-500">*</span>
          </Form.Label>
          <Form.Input
            type="text"
            placeholder="555-555-5555"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
            data-testid="contactPhone"
            className="px-2 bg-gray-50 placeholder-gray-500 relative text-sm border-0 w-full border-0 outline-none focus:outline-none"
          />
        </Form.Group>
        <Form.Group className="flex-1 px-2 border-l-0 border-t-0 border-b border-r-0 border-gray-200">
          <Form.Label>
            Email <span className="ml-2 text-red-500">*</span>
          </Form.Label>
          <Form.Input
            type="text"
            placeholder="name@gmail.com"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            data-testid="contactEmail"
            className="px-2 bg-gray-50 placeholder-gray-500 relative text-sm border-0 w-full border-0 outline-none focus:outline-none"
          />
        </Form.Group>
      </div>
    </form>
  );
};

export default AddProjectContactForm;
