import { memo, useState } from 'react';
import Modal, { ModalHeader } from '../utility/Modal';
import { FaExternalLinkSquareAlt } from 'react-icons/fa';
import { Step } from './EditProjectModal';
import formatCurrency from '../../utility/formatCurrency';
import formatDate from '../../utility/formatDate';
import humanize from '../../utility/humanize';
import Progress from '../../utils/Progress';
import { Permissions } from '../../data/models';
import { checkPermission } from '../shared/utils';

const MoreProjectDetailsModal = (
  {
    length,
    amount,
    start_date,
    end_date,
    completion_percentage,
    npdes_permit_no,
    ms4_operator,
    inspection_frequency,
    inspection_frequency_other,
    hasContractDetails,
    permissions,
    setStepAndShowModal,
    noReportingFieldsFilled
  }: {
    length?: number,
    amount?: number,
    start_date?: string,
    end_date?: string,
    completion_percentage?: number,
    npdes_permit_no?: string,
    ms4_operator?: string,
    inspection_frequency?: string,
    inspection_frequency_other?: string,
    hasContractDetails?: boolean,
    permissions: Permissions,
    setStepAndShowModal: (step: Step) => void,
    noReportingFieldsFilled: () => boolean
  }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const completion_percentage_formatted = (typeof completion_percentage === 'number' && completion_percentage >= 0) ? completion_percentage : 0;

  return (
    <div>
      <a className="flex justify-start items-center mt-5 cursor-pointer" onClick={() => setModalOpen(true)}>
        <span className="font-bold mr-2">
          See More Details
        </span>
        <FaExternalLinkSquareAlt />
      </a>
      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
        <ModalHeader
          title={'Project Details'}
          onClose={() => setModalOpen(false)}
        />
        <div className="px-2 pt-2">
          <div className="pr-5 pl-5">
            <div className={hasContractDetails ? 'group cursor-pointer relative flex items-center justify-between' : ''}>
              <p className="font-bold mt-5 mb-2 text-lg">Contract Details:</p>
              {checkPermission({ permissions: permissions, permission: 'update' }) ? <button
                className="transition-opacity duration-300 text-xs focus:outline-none bg-white border-2 border-sm-lightblue shadow-sm text-secondary rounded-md font-semibold hover:opacity-80 cursor-pointer px-5 py-2"
                onClick={() => setStepAndShowModal('advanced')}
              >
                {hasContractDetails ? 'Edit' : '+ Add Contract Details'}
              </button> : 'N/A'}
            </div>
            {start_date && end_date && length && (
              <p className="text-base">
                <span className="font-semibold">Length:</span> {length} days
              </p>
            )}
            {checkPermission({ permissions: permissions, permission: 'read_contract_amount' }) && amount && (
              <p className="text-base">
                <span className="font-semibold">Amount:</span> ${formatCurrency(amount.toString(), true)}
              </p>
            )}
            {start_date && (
              <p className="text-base">
                <span className="font-semibold">Start date:</span> {formatDate(start_date)}
              </p>
            )}
            {end_date && (
              <p className="text-base">
                <span className="font-semibold">End date:</span> {formatDate(end_date)}
              </p>
            )}
            {start_date && end_date && completion_percentage && (
              <div>
                <p className="text-base mb-2">
                  <span className="font-semibold">Percentage of Completion:</span> {completion_percentage_formatted || 0}%
                </p>
                <Progress current={completion_percentage_formatted || 0} total={100} />
              </div>
            )}
          </div>
          <div className="group cursor-pointer relative pt-2 pb-5 pr-5 pl-5">
            <div className={!noReportingFieldsFilled() ? 'flex justify-between' : ''}>
              <p className="font-bold mt-2 mb-2 text-lg">Reporting:</p>
              {checkPermission({ permissions: permissions, permission: 'update' }) ? <button
                className="transition-opacity duration-300 text-xs focus:outline-none bg-white border-2 border-sm-lightblue shadow-sm text-secondary rounded-md font-semibold hover:opacity-80 cursor-pointer px-5 py-2"
                onClick={() => setStepAndShowModal('reporting')}
              >
                {!noReportingFieldsFilled() ? 'Edit' : '+ Add Permits'}
              </button> : 'N/A'}
            </div>
            {npdes_permit_no && (
              <p className="text-base">
                <span className="font-semibold">
                  NPDES Permit No:
                </span>{' '}
                {npdes_permit_no}
              </p>
            )}
            {ms4_operator && (
              <p className="text-base">
                <span className="font-semibold">
                  MS4 Operator:
                </span>{' '}
                {ms4_operator}
              </p>
            )}
            {inspection_frequency && (
              <p className="text-base">
                <span className="font-semibold">
                  Inspection Frequency:
                </span>{' '}
                {inspection_frequency === 'other'
                  ? inspection_frequency_other
                  : humanize(inspection_frequency)}
              </p>
            )}
          </div>

          <div className="modal-footer">
            <button
              className="modal-close-btn"
              type="button"
              onClick={() => setModalOpen(false)}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default memo(MoreProjectDetailsModal);
