const colors = require('tailwindcss/colors');

module.exports = {
  mode: 'jit',
  purge: ['./src/**/*.{js,jsx,ts,tsx}', './public/index.html'],
  theme: {
    extend: {
      boxShadow: {
        DEFAULT:
          '0 1px 3px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.02)',
        md: '0 4px 6px -1px rgba(0, 0, 0, 0.08), 0 2px 4px -1px rgba(0, 0, 0, 0.02)',
        lg: '0 10px 15px -3px rgba(0, 0, 0, 0.08), 0 4px 6px -2px rgba(0, 0, 0, 0.01)',
        xl: '0 20px 25px -5px rgba(0, 0, 0, 0.08), 0 10px 10px -5px rgba(0, 0, 0, 0.01)',
      },
      colors: {
        gray: colors.slate,
        blue: colors.sky,
        'light-blue': colors.lightBlue,
        red: colors.rose,
        'sm-blue': '#10678b',
        secondary: '#276093',
        'secondary-light': '#276093',
        'secondary-lighter': '#4283B2',
        'secondary-lightest': '#5C9EC6',
        slate: '#172F44',
        'slate-light': '#37556D',
        'slate-lighter': '#49677f',
        'slate-lightest': '#537a92',
        tertiary: '#16697A',
        'tertiary-light': '#268C99',
        'tertiary-lighter': '#47b2ba',
        'tertiary-lightest': '#74d8db',
        'sm-orange': '#d57a35',
        primary: '#F49F15',
        'primary-light': '#f9b24b',
        'primary-lighter': '#ffc67b',
        'primary-lightest': '#ffd6a4',
        'sm-orange-lighten': '#F9B24B',
        'primary-lighten': '#cf854c',
        'sm-lightblue': '#dfe9ed',
        'sm-success': '#9FD53D',
      },
      cursor: {
        'none': 'none',
        'grab': 'grab',
        'grabbing': 'grabbing',
        'zoom-in': 'zoom-in',
        'zoom-out': 'zoom-out',
        crosshair: 'crosshair',
        'n-resize': 'n-resize',
        'e-resize': 'e-resize',
        's-resize': 's-resize',
        'w-resize': 'w-resize',
        'ne-resize': 'ne-resize',
        'nw-resize': 'nw-resize',
        'se-resize': 'se-resize',
        'sw-resize': 'sw-resize',
      },
      opacity: {
        1: '0.01',
        2: '0.02',
        3: '0.03',
        4: '0.04',
      },
      outline: {
        blue: '2px solid rgba(0, 112, 244, 0.5)',
      },
      fontFamily: {
        inter: ['Inter', 'sans-serif'],
        oswald: ['Oswald', 'sans-serif'],
        poppins: ['Poppins', 'sans-serif'],
      },
      fontSize: {
        xs: ['0.75rem', { lineHeight: '1.5' }],
        sm: ['0.875rem', { lineHeight: '1.5715' }],
        base: ['1rem', { lineHeight: '1.5', letterSpacing: '-0.01em' }],
        lg: ['1.125rem', { lineHeight: '1.5', letterSpacing: '-0.01em' }],
        xl: ['1.25rem', { lineHeight: '1.5', letterSpacing: '-0.01em' }],
        '2xl': ['1.5rem', { lineHeight: '1.33', letterSpacing: '-0.01em' }],
        '3xl': ['1.88rem', { lineHeight: '1.33', letterSpacing: '-0.01em' }],
        '4xl': ['2.25rem', { lineHeight: '1.25', letterSpacing: '-0.02em' }],
        '5xl': ['3rem', { lineHeight: '1.25', letterSpacing: '-0.02em' }],
        '6xl': ['3.75rem', { lineHeight: '1.2', letterSpacing: '-0.02em' }],
      },
      spacing: {
        '42': '10.65rem',
      },
      screens: {
        xs: '480px',
        print: { raw: 'print' },
      },
      borderWidth: {
        3: '3px',
      },
      minWidth: {
        36: '9rem',
        44: '11rem',
        56: '14rem',
        60: '15rem',
        80: '20rem',
      },
      maxWidth: {
        '8xl': '88rem',
        '9xl': '96rem',
      },
      zIndex: {
        60: '60',
        70: '70',
      },
      transitionDelay: {
        0: '0ms',
        2000: '2000ms',
        3000: '3000ms',
        4000: '4000ms',
        5000: '5000ms',
      },
    },
  },
  variants: {
    extend: {
      margin: ['first', 'last'],
      borderWidth: ['hover', 'first', 'last'],
      padding: ['hover', 'first', 'last'],
      opacity: ['disabled'],
      backgroundColor: ['checked', 'disabled'],
      borderColor: ['hover', 'checked'],
      borderRadius: ['hover', 'focus'],
      display: ['hover', 'focus', 'group-hover'],
      width: ['hover', 'focus', 'group-hover'],
      height: ['hover', 'focus', 'group-hover'],
      justifyContent: ['responsive', 'hover', 'focus', 'group-hover'],
      cursor: ['hover', 'focus', 'disabled'],
      textOpacity: ['hover', 'focus', 'disabled'],
    },
  },
  plugins: [
    // eslint-disable-next-line global-require
    require('@tailwindcss/forms'),
    require('@tailwindcss/line-clamp'),
  ],
};
