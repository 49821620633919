import { useEffect } from 'react';
import useApi from '../../../hooks/useApi';
import workspaceMembersApi from '../../../api/workspace_members';
export default function useWorkspaceMembers(workspaceObjectId: string) {
    const {
        data: { records: members, pagy },
        error,
        loading,
        request: loadWorkspaceMembers,
    } = useApi(
        workspaceMembersApi.getWorkspaceMembers,
        { records: [], pagy: {} },
        true
    );

    useEffect(() => {
        if (!workspaceObjectId) return;
        loadWorkspaceMembers(workspaceObjectId, {
            items: 100,
        });
    }, [workspaceObjectId])

    return {
        members,
        pagy,
        error,
        loading,
        loadWorkspaceMembers
    }
}