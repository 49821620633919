import client from './client';
const endpoint = '/shares';

const getShares = (params = {}) => client.get(endpoint, params);
const getShare = (id, params = {}) => client.get(`${endpoint}/${id}`, params);
const addShare = (share) => client.post(endpoint, { share });
const archiveShare = (shareId) => client.put(`${endpoint}/${shareId}/archive`);
const unarchiveShare = (shareId) =>
  client.put(`${endpoint}/${shareId}/unarchive`);
const updateShare = (shareId, share) =>
  client.put(`${endpoint}/${shareId}`, { share });

export default {
  addShare,
  archiveShare,
  getShares,
  getShare,
  unarchiveShare,
  updateShare,
};
