import { memo } from 'react';
import Tag from '../shared/Tag';

function EntryPinTags({ entry }) {
  return (
    <div className="text-xs pb-2">
      {entry.info.tags.map((tag) => (
        <Tag tag={tag} size="sm" className="mr-1" />
      ))}
    </div>
  );
}

export default memo(EntryPinTags);
