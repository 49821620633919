import Badge from '../shared/Badge';
import Modal, { ModalHeader, ModalBody, ModalFooter } from '../utility/Modal';
export default function PreviewFeatureAnnouncementModal({
    open,
    featureAnnouncement,
    onClose,
}) {

    const openFaqLink = () => {
        window.open(featureAnnouncement?.faq_link, '_blank');
    }

    return (
        <Modal
            isOpen={open}
            dialogScrolls={true}
            modalScrolls={false}
            maxHeight={'max-h-auto'}
            dialogClass={'py-10'}
            modalClass={'overflow-hidden rounded-lg'}
            aligned={'items-start'}
            onClose={onClose}
        >
            <ModalHeader
                title={<div className="flex">
                    <span className="mr-1">New Feature Announcement!</span>
                    {featureAnnouncement?.status == 'beta' && <Badge label="Beta" animated style={'green'} title="This feature is currently in Beta - don't expect perfection!" />}
                    {featureAnnouncement?.status == 'new' && <Badge label="New" animated style={'warning'} title="This feature is brand new! Enjoy!" />}
                </div>}
                onClose={onClose}
            />
            <ModalBody>
                <div className="flex flex-col justify-center items-center">
                    <h2 className="text-xl font-light mb-1">Introducing...</h2>
                    <h2 className="text-2xl font-black text-black mb-1 flex" data-testid="featureAnnouncementPreviewTitle">{featureAnnouncement?.title}</h2>
                    <h2 className="text-md font-medium text-gray-800 mb-7" data-testid="featureAnnouncementPreviewSubtitle">{featureAnnouncement?.subtitle}</h2>
                    <div className="w-full h-72 shadow-md bg-gray-800" data-testid="featureAnnouncementPreviewLoom" ><iframe src={featureAnnouncement?.loom_link?.replace?.("share", "embed")} frameborder="0" className="w-full h-full" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe></div>
                    <p className="text-sm font-medium text-secondary mt-7 mb-3" data-testid="featureAnnouncementPreviewFaq" >Watch video above to learn more or <a href={featureAnnouncement?.faq_link} onClick={openFaqLink} className="font-semibold cursor-pointer underline">see the FAQ</a></p>
                    <p className="text-sm font-light text-center text-gray-700" data-testid="featureAnnouncementPreviewDescription">{featureAnnouncement?.description}</p>
                </div>
            </ModalBody>
            <ModalFooter>
                <button
                    className="modal-save-btn mr-2"
                    type="button"
                    data-testid="featureAnnouncementPreviewCloseButton"
                    onClick={onClose}
                >
                    Great!
                </button>
            </ModalFooter>

        </Modal >
    );
}
