import React, { useEffect, useState, useCallback } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import invitationsApi from '../api/invitations';
import useApi from '../hooks/useApi';
import useQuery from '../hooks/useQuery';
import useAuth from '../auth/useAuth';
import Loader from '../partials/utility/Loader';

import AuthImage from '../images/bgs/login-bg.png';
import ErrorView from '../partials/utility/ErrorView';
import formatPhoneNumber from '../utility/formatPhoneNumber';
import ErrorViewModel from '../models/ErrorViewModel';
import toBase64 from '../utility/toBase64';
import { useDropzone } from 'react-dropzone';

function Invitation() {
  const { user, logIn } = useAuth();
  const {
    data: invitedUser,
    error,
    loading,
    request: getInvitation,
  } = useApi(invitationsApi.getInvitation, true);

  const query = useQuery();
  const invitationToken = query.get('token');
  const [newUser, setNewUser] = useState({
    invitation_token: invitationToken,
    password: '',
    password_confirmation: '',
  });

  const [saving, setSaving] = useState(false);
  const [confirmation_error, setConfirmationError] = useState(false);
  const [file, setFile] = useState(null);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFile(acceptedFiles[0]);
    },
    [file]
  );

  const { getRootProps, getInputProps, isDragActive, rootRef } = useDropzone({
    onDrop,
    accept: 'image/*',
    maxFiles: 1,
  });

  const confirmInvitation = async (values) => {
    setSaving(true);

    if (file !== null) {
      let base64File = await toBase64(file);
      values['profile_photo'] = { filename: file.name, data: base64File };
    }

    let response = await invitationsApi.invitation(values);

    setSaving(false);

    if (!response.ok)
      return setConfirmationError(
        new ErrorViewModel(response.data.error || response.data)
      );

    await logIn(response);
  };

  const formik = useFormik({
    initialValues: newUser,
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Required'),
      name: Yup.string().required('Required'),
      password: Yup.string()
        .min(6, 'Must be 6 characters or more')
        .max(128, 'Must be 128 characters or less')
        .required('Required'),
      password_confirmation: Yup.string()
        .min(6, 'Must be 6 characters or more')
        .max(128, 'Must be 128 characters or less')
        .required('Required'),
    }),
    onSubmit: confirmInvitation,
  });

  useEffect(() => {
    getInvitation(invitationToken);
  }, []);

  useEffect(() => {
    invitedUser && setNewUser({ ...newUser, ...invitedUser });
  }, [invitedUser]);

  useEffect(() => {
    newUser &&
      Object.keys(formik.initialValues).length < 4 &&
      formik.setValues(newUser);
  }, [newUser]);

  if (user)
    return <Redirect to={`/${user.default_workspace?.objectId}/projects`} />;
  if (error)
    return (
      <Redirect
        to={{
          pathname: '/signin',
          state: { error },
        }}
      />
    );

  // Add put method to accept invite

  return (
    <main className="bg-white">
      <div className="relative md:flex">
        {/* Content */}
        <div className="md:w-1/2">
          <div className="w-full bg-green-400 text-white text-center text-lg font-semibold p-3">
            You've been invited to join Site Marker
          </div>
          <div className="max-w-sm mx-auto min-h-screen flex flex-col justify-center px-4 py-8">
            <div className="w-full">
              {loading && (
                <div className="flex justify-center py-10">
                  <Loader color="black" />
                </div>
              )}
              {!loading && (
                <>
                  {(error || confirmation_error) && (
                    <ErrorView
                      error={error || confirmation_error}
                      extraClass={'mb-4'}
                    />
                  )}
                  <h1 className="text-3xl text-gray-800 font-bold mb-6">
                    Complete Sign Up
                  </h1>
                  {/* Form */}
                  <form onSubmit={formik.handleSubmit}>
                    <div className="space-y-4">
                      <div className="flex flex-col items-center">
                        <div className="flex items-center focus:outline-none">
                          <div
                            className={`mr-4 w-16 h-16 rounded-full overflow-hidden cursor-pointer hover:opacity-80 focus:outline-none ${
                              !isDragActive
                                ? ''
                                : 'border-3 border-secondary bg-blue-100 opacity-50 bg-tertiary'
                            }`}
                            {...getRootProps()}
                            title="Click or Drag and Drop to change photo"
                          >
                            <img
                              className="w-full h-full object-cover mr-4"
                              src={
                                file
                                  ? URL.createObjectURL(file)
                                  : invitedUser?.profile_photos?.medium
                              }
                              alt="Profile Photo"
                            />
                            <input {...getInputProps()} />
                          </div>
                          {file ? (
                            <>
                              <button
                                className="btn rounded bg-gray-100 shadow-sm text-gray-900 focus:outline-none hover:opacity-80 ml-2"
                                disabled={saving}
                                onClick={() => setFile(null)}
                                type="button"
                              >
                                Revert
                              </button>
                            </>
                          ) : (
                            <button
                              className="btn rounded border-gray-200 text-secondary shadow-sm focus:outline-none hover:opacity-80 ml-4"
                              onClick={() => rootRef.current.click()}
                              type="button"
                            >
                              Change
                            </button>
                          )}
                        </div>
                      </div>
                      <div>
                        <label
                          className={`block text-sm font-medium mb-1`}
                          htmlFor="email"
                        >
                          Email Address{' '}
                          <span className="text-red-500">
                            *{' '}
                            {formik.touched.email &&
                              formik.errors.email &&
                              formik.errors.email}
                          </span>
                        </label>
                        <input
                          id="email"
                          className={`form-input w-full ${
                            formik.touched.email &&
                            formik.errors.email &&
                            'border-red-500'
                          }`}
                          type="email"
                          {...formik.getFieldProps('email')}
                        />
                      </div>
                      <div>
                        <label
                          className={`block text-sm font-medium mb-1`}
                          htmlFor="name"
                        >
                          Full Name{' '}
                          <span className="text-red-500">
                            *{' '}
                            {formik.touched.name &&
                              formik.errors.name &&
                              formik.errors.name}
                          </span>
                        </label>
                        <input
                          id="name"
                          className={`form-input w-full ${
                            formik.touched.name &&
                            formik.errors.name &&
                            'border-red-500'
                          }`}
                          {...formik.getFieldProps('name')}
                        />
                      </div>
                      <div>
                        <label
                          className={`block text-sm font-medium mb-1`}
                          htmlFor="position"
                        >
                          Position{' '}
                          <span className="text-red-500">
                            {formik.touched.title &&
                              formik.errors.title &&
                              formik.errors.title}
                          </span>
                        </label>
                        <input
                          id="position"
                          className={`form-input w-full ${
                            formik.touched.title &&
                            formik.errors.title &&
                            'border-red-500'
                          }`}
                          {...formik.getFieldProps('title')}
                        />
                      </div>
                      <div>
                        <label
                          className={`block text-sm font-medium mb-1`}
                          htmlFor="phone"
                        >
                          Phone Number{' '}
                          <span className="text-red-500">
                            {formik.touched.phone &&
                              formik.errors.phone &&
                              formik.errors.phone}
                          </span>
                        </label>
                        <input
                          id="phone"
                          className={`form-input w-full ${
                            formik.touched.phone &&
                            formik.errors.phone &&
                            'border-red-500'
                          }`}
                          onChange={(e) => {
                            e.target.value = formatPhoneNumber(e.target.value);
                            formik.handleChange(e);
                          }}
                          value={formik.values.phone}
                        />
                      </div>
                      <div className="hidden">
                        <label
                          className="block text-sm font-medium mb-1"
                          htmlFor="role"
                        >
                          Your Role <span className="text-red-500">*</span>
                        </label>
                        <select id="role" className="form-select w-full">
                          <option>Designer</option>
                          <option>Developer</option>
                          <option>Accountant</option>
                        </select>
                      </div>
                      <div>
                        <label
                          className={`block text-sm font-medium mb-1`}
                          htmlFor="password"
                        >
                          Password{' '}
                          <span className="text-red-500">
                            *{' '}
                            {formik.touched.password &&
                              formik.errors.password &&
                              formik.errors.password}{' '}
                            {formik.values.password &&
                              formik.values.password_confirmation &&
                              !formik.errors.password &&
                              !formik.errors.password_confirmation &&
                              formik.values.password !==
                                formik.values.password_confirmation &&
                              'Do not match'}{' '}
                          </span>
                        </label>
                        <input
                          id="password"
                          className={`form-input w-full ${
                            formik.touched.password &&
                            formik.errors.password &&
                            'border-red-500'
                          }`}
                          type="password"
                          {...formik.getFieldProps('password')}
                        />
                      </div>
                      <div>
                        <label
                          className={`block text-sm font-medium mb-1`}
                          htmlFor="password_confirmation"
                        >
                          Confirm Password{' '}
                          <span className="text-red-500">
                            *{' '}
                            {formik.touched.password_confirmation &&
                              formik.errors.password_confirmation &&
                              formik.errors.password_confirmation}
                          </span>
                        </label>
                        <input
                          id="password_confirmation"
                          className={`form-input w-full ${
                            formik.touched.password_confirmation &&
                            formik.errors.password_confirmation &&
                            'border-red-500'
                          }`}
                          type="password"
                          {...formik.getFieldProps('password_confirmation')}
                        />
                      </div>
                    </div>
                    <div className="flex items-center justify-between mt-6">
                      <div className="mr-1 hidden">
                        <label className="flex items-center">
                          <input type="checkbox" className="form-checkbox" />
                          <span className="text-sm ml-2">
                            Email me about product news.
                          </span>
                        </label>
                      </div>
                      <button
                        className="btn rounded bg-secondary hover:opacity-90 text-white w-full whitespace-nowrap"
                        type="submit"
                      >
                        Sign Up {saving && <Loader />}
                      </button>
                    </div>
                  </form>
                  {/* Footer */}
                  <div className="pt-5 mt-6 border-t border-gray-200">
                    <div className="text-sm">
                      Have an account?{' '}
                      <Link
                        className="font-medium text-secondary hover:opacity-90"
                        to="/signin"
                      >
                        Sign In
                      </Link>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        {/* Image */}
        <div
          className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
          aria-hidden="true"
        >
          <img
            className="object-cover object-center w-full h-full"
            src={AuthImage}
            width="760"
            height="1024"
            alt="Authentication"
          />
        </div>
      </div>
    </main>
  );
}

export default Invitation;
