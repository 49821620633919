import client from './client';
const endpoint = '/roles';

const getRoles = () => client.get(`${endpoint}`);
const getWorkspaceRoles = (workspaceId, params) =>
  client.get(`/workspaces/${workspaceId}${endpoint}`, params);
const getRole = (id, params = {}) => client.get(`${endpoint}/${id}`, params);
const addRole = (projectId, asset) =>
  client.post(`/projects/${projectId}${endpoint}`, { asset });
const updateRole = (assetId, asset) =>
  client.put(`${endpoint}/${assetId}`, { asset });

export default {
  getRoles,
  getWorkspaceRoles,
  getRole,
  addRole,
  updateRole,
};
