import React, { useEffect, useRef, useState } from 'react';
import { BiCheckCircle } from 'react-icons/bi';
import { GrCheckbox, GrCheckboxSelected } from 'react-icons/gr';
import entriesApi from '../../api/entries';
import useApi from '../../hooks/useApi';
import useReportContext from '../reports/useReportContext';
import Loader from '../utility/Loader';

export default function EntryWeatherRainExisted({ entry: passedEntry }) {
  const {
    data: savedEntry,
    error,
    loading: saving,
    request: saveEntry,
  } = useApi(entriesApi.updateEntry, null);
  const [entry, setEntry] = useState(passedEntry);
  const [saved, setSaved] = useState(false);
  const { editing } = useReportContext();

  const savedTimer = useRef(null);

  const submitEntry = (value) => {
    entry.value = value;
    setEntry({ ...entry });
    saveEntry(entry.objectId, { value });
  };

  useEffect(() => {
    savedEntry && setEntry({ ...savedEntry });
  }, [savedEntry]);

  useEffect(() => {
    savedEntry && setSaved(true);
  }, [savedEntry]);

  useEffect(() => {
    if (saved) {
      savedTimer.current = setTimeout(() => {
        setSaved(false);
      }, 2000);
    }
  }, [saved]);

  return (
    <div className="p-2 flex border-b">
      <div className="font-bold relative">
        Has there been a rain event since the last inspection?
        {saving && (
          <div className="absolute top-0 right-2 h-full flex items-center">
            <Loader color="text-gray-700" />
          </div>
        )}
        {saved && (
          <div className="absolute top-0 right-2 h-full flex items-center">
            <BiCheckCircle
              size={16}
              className="bg-green-500 text-white rounded-full overflow-hidden"
            />
          </div>
        )}
      </div>
      <div className="pl-4 pr-1">
        {entry.value === 'yes' ? (
          <GrCheckboxSelected
            size={14}
            onClick={() => editing && submitEntry(null)}
            className={editing && `hover:bg-blue-100 cursor-pointer`}
          />
        ) : (
          <GrCheckbox
            size={14}
            onClick={() => editing && submitEntry('yes')}
            className={editing && 'hover:bg-blue-100 cursor-pointer'}
          />
        )}
      </div>
      <div>Yes</div>
      <div className="pl-4 pr-1">
        {entry.value === 'no' ? (
          <GrCheckboxSelected
            size={14}
            onClick={() => editing && submitEntry(null)}
            className={editing && `hover:bg-blue-100 cursor-pointer`}
          />
        ) : (
          <GrCheckbox
            size={14}
            onClick={() => editing && submitEntry('no')}
            className={editing && 'hover:bg-blue-100 cursor-pointer'}
          />
        )}
      </div>
      <div>No</div>
      <div className="pl-4 pr-1">
        {entry.value === 'na' ? (
          <GrCheckboxSelected
            size={14}
            onClick={() => editing && submitEntry(null)}
            className={editing && `hover:bg-blue-100 cursor-pointer`}
          />
        ) : (
          <GrCheckbox
            size={14}
            onClick={() => editing && submitEntry('na')}
            className={editing && 'hover:bg-blue-100 cursor-pointer'}
          />
        )}
      </div>
      <div>N/A</div>
      <div className="pl-3 font-bold"> If yes, data provided below:</div>
    </div>
  );
}
