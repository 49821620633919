import { memo, useEffect, useState } from 'react';
import workspaceMembersApi from '../../api/workspace_members';
import useApi from '../../hooks/useApi';
import Loader from '../utility/Loader';
import Modal, { ModalHeader, ModalSuccessView } from '../utility/Modal';

function DeleteMemberModal({
  workspaceId,
  workspaceMemberId,
  member,
  afterRemove,
}) {
  const {
    data: removedMember,
    error,
    loading: removing,
    request: removeWorkspaceMember,
  } = useApi(workspaceMembersApi.removeWorkspaceMember, null);
  const [modalOpen, setModalOpen] = useState(false);

  const removeMember = () =>
    removeWorkspaceMember(workspaceId, workspaceMemberId);

  useEffect(() => {
    removedMember && afterRemove && afterRemove();
  }, [removedMember]);

  return (
    <div>
      {/* Button */}
      <a className="cursor-pointer" onClick={() => setModalOpen(!modalOpen)}>
        <div className="font-medium text-sm text-red-500 hover:text-red-600 flex py-1 px-3">
          Remove
        </div>
      </a>
      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
        <ModalHeader
          title={'Remove Team Member from Organization'}
          onClose={() => setModalOpen(false)}
        />
        <div className="px-2 pt-2">
          <div className="p-5">
            {!removedMember && (
              <>
                <div className="mb-4">
                  <div className="flex items-center">
                    <img
                      className="rounded-full border-2 object-cover border-white float-left mr-2 w-16 h-16"
                      src={
                        member.profile_photos?.medium ||
                        'https://bucketeer-d9937a0d-80e4-469a-91e2-6c86bd37d08f.s3.amazonaws.com/public/user_empty.png'
                      }
                    />
                    <div className="leading-snug">
                      <div className="font-semibold">
                        {member.name || member.email}
                      </div>
                      <div>{member.title}</div>
                    </div>
                  </div>
                </div>
                <div>
                  This will completely remove{' '}
                  <span className="font-semibold">{member.name}</span> from all
                  teams and projects.
                </div>
              </>
            )}
            {removedMember && (
              <ModalSuccessView
                text={'Organization member successfully deleted'}
              />
            )}
          </div>

          {!removedMember && (
            <div className="modal-footer">
              <button
                className="modal-remove-btn"
                type="button"
                onClick={removeMember}
              >
                Remove Member {removing && <Loader />}
              </button>
              <button
                className="modal-close-btn"
                type="button"
                onClick={() => setModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default memo(DeleteMemberModal);
