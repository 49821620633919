import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { CancelToken } from 'apisauce';
import useAuth from '../auth/useAuth';
import ErrorViewModel from '../models/ErrorViewModel';

const SIGNATURE_ERRORS = [
  'You need to sign in or sign up before continuing.',
  'Signature verification raised',
  'Signature verification failed',
  'Signature has expired',
  'nil user',
  'wrong scope',
  'Invalid segment encoding',
];

export default function useApi(
  apiFunc: (...args: any) => any,
  defaultData: any = [],
  defaultLoading: boolean = false
) {
  const [[data, error, _latestInboundNonce], setAllData] = useState([
    defaultData,
    null as false | null | { unknown: string[] } | ErrorViewModel,
    0,
  ]);
  const [loading, setLoading] = useState(defaultLoading);
  const [, setLatestOutboundNonce] = useState(0);
  const { logOut, logIn } = useAuth();
  const source = CancelToken.source();
  const request = async (...args: any[]) => {
    setLoading(true);
    let thisRequestNonce: number;
    await new Promise<void>((resolve) => {
      setLatestOutboundNonce((n) => {
        thisRequestNonce = n + 1;
        resolve();
        return thisRequestNonce;
      });
    });

    try {
      const response = await apiFunc(...args);

      if (!response.ok) {

        if (
          SIGNATURE_ERRORS.includes(response?.data?.error) ||
          response?.data?.error?.includes?.('Signature') ||
          response?.data?.error?.includes?.('verification')
        ) {
          logOut();
          return <Redirect to="/signin" />;
        }

        setAllData(([oldData, oldError, oldNonce]) => {
          const shouldDiscard = thisRequestNonce <= oldNonce;
          if (shouldDiscard) return [oldData, oldError, oldNonce];

          const newError = response?.data
            ? new ErrorViewModel(response.data)
            : { unknown: ['Unknown Error Occured'] };

          // NOTE: this is a unique behavior here where the old data is not
          // cleared when a new error is set. I'm preserving this behavior for
          // now for compatibility, but it might make sense in the future to
          // aggressively clear current data when there's an error retrieving
          // updated data.
          return [oldData, newError, thisRequestNonce];
        });
      } else {
        if (response.headers.authorization) {
          //TO-DO (is this necessary?)
          logIn(response);
        }

        setAllData(([oldData, oldError, oldNonce]) => {
          const shouldDiscard = thisRequestNonce <= oldNonce;
          if (shouldDiscard) return [oldData, oldError, oldNonce];
          return [response?.data, null, thisRequestNonce];
        });
      }
    } catch (err) {
      console.log({ err });
    }

    setLoading(false);
  };

  return { data, error, loading, source, request };
}
