import { useEffect, useRef, useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import { FiX } from 'react-icons/fi';
import colors from '../../styles/colors';
const SearchContainer = ({ children }) => <div className="bg-white border-b border-gray-200 p-4 flex relative" >
  {children}
</div>

const SearchContainerFull = ({ children }) => <div className="bg-white border-b border-gray-200 flex" >
  {children}
</div>

const SearchEmptyContainer = ({ children }) => <div className="px-3 py-2 text-center font-light" >
  {children}
</div>

export {
  SearchContainer,
  SearchContainerFull,
  SearchEmptyContainer
}
export default function DelayedSearchInput({
  placeholder,
  defaultValue = '',
  searchAfter = 1000,
  onSearched,
  ...rest
}) {
  const [text, setText] = useState(defaultValue);
  const searchTimer = useRef(null);

  const startSearching = ({ target: { value } }) => {
    setText(value);

    if (searchTimer.current) clearTimeout(searchTimer.current);

    searchTimer.current = setTimeout(() => {
      onSearched?.(value);
    }, searchAfter);
  };

  useEffect(() => {
    setText(defaultValue);
  }, [defaultValue]);

  return (
    <div className="flex items-center px-2 flex-1 bg-gray-100" {...rest}>
      <BiSearch size={16} color={colors.darkGray} />
      <input
        type="text"
        placeholder={placeholder}
        value={text}
        onChange={startSearching}
        className="bg-gray-100 focus:ring-0 px-2 placeholder-gray-500 relative text-xs border-0 w-full border-0 outline-none focus:outline-none"
      />
      <div>
        {text.length > 0 && (
          <button
            className="mt-1 bg-gray-100 focus:outline-none mr-1 text-sm"
            title="Clear text"
            onClick={() => {
              setText('')
              onSearched?.('')
            }}
          >
            <FiX className="hover:opacity-60 text-gray-100 bg-gray-600 rounded-full p-0.5" />
          </button>
        )}
      </div>
    </div>
  );
}
