import moment from 'moment-timezone';
import qs from 'querystring';
import { useEffect, useState, useCallback, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { FiCheckCircle, FiMoreHorizontal } from 'react-icons/fi';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import documentsApi from '../../api/documents';
import foldersApi from '../../api/folders';
import useProjectContext from '../../hooks/useProjectContext';
import ErrorView from '../../partials/utility/ErrorView';
import EditMenu from '../EditMenu';
import ShareModal from '../shares/ShareModal';
import PhotosModal from '../utility/PhotosModal';
import AddFolderModal from './AddFolderModal';
import ArchiveModal from './ArchiveModal';
import Breadcrumb from './Breadcrumb';
import ConfirmDeleteDocumentModal from './ConfirmDeleteDocumentModal';
import DocumentName from './DocumentName';
import EditDocumentModal from './EditDocumentModal';
import MoveDocumentModal from './MoveDocumentModal';
import EmptyState from '../utility/EmptyState';
import AddDocumentVersionModal from './AddDocumentVersionModal';
import VersionsDocumentsModal from './VersionsDocumentsModal';
import useFormattedFolderData from '../../hooks/useFormattedFolderData';
import Button from '../shared/Button';
import DownloadFolderButton from './DownloadFolderButton';
import FolderDropzone from './FolderDropzone';
import Progress from '../../utils/Progress';
import Loader from '../utility/Loader';
import { uploadAttachment } from '../../hooks/useS3';
import useSaved from '../../hooks/useSaved';
import { FormPrompt } from '../map_layers/MapboxMapLayerNewRefactor';
import { MultiSelectBar } from '../construction_drawings/ConstructionDrawings';
import BulkDeleteDocumentsModal from './BulkDeleteDocumentsModal';
import BulkDownloadDocumentsModal from './BulkDownloadDocumentsModal';
import useFolders from './hooks/useFolders';
import useDocuments from './hooks/useDocument';
import { checkPermission } from '../shared/utils';

export default function DocumentTable({
  folderData,
  updateFolderId,
  projectObjectId,
  onUpdate,
  searchTerm = '',
}) {
  const history = useHistory();
  const location = useLocation();
  const { project } = useProjectContext();
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [mostRecentError, setMostRecentError] = useState(null);
  const [previewDocument, setPreviewDocument] = useState(null);
  const [downloadedFileName, setDownloadedFileName] = useState(null);
  const [uploadingProgress, setUploadingProgress] = useState([]);
  const { project_id } = useParams();

  const {
    documentFile,
    getDocumentFile,
    archivedDocumentData,
    archiveDocumentError,
    archiveDocument,
    unarchivedDocumentData,
    unarchiveDocument,
  } = useDocuments();


  const {
    archivedFolderData,
    archiveFolderError,
    archiveFolder,
    unarchivedFolderData,
    unarchiveFolderError,
    unarchiveFolder,
  } = useFolders();


  const { data } = useFormattedFolderData(folderData);

  function route(newFolderId) {
    history.push({
      pathname: location.pathname,
      search: qs.stringify({
        folder_id: newFolderId,
      }),
    });
    updateFolderId(newFolderId);
  }

  const rootFolder = folderData;

  const handleFolderDrop = useCallback(
    async (folderTree, _) => {

      const flattenItems = (items) => {
        return items.reduce((acc, item) => {
          if (item.document === 'folder') {
            return acc.concat({ name: item.name, progress: 0, document: item.document }).concat(flattenItems(item.items));
          }
          return acc.concat({ name: item.name, progress: 0, document: item.document });
        }, []);
      };

      setUploadingProgress(flattenItems(folderTree));

      const processFolder = async (items, parentFolder) => {

        for (const [index, item] of items.entries()) {

          if (item.document === 'folder') {

            const folderName = () => {
              const rootFolderIncludesName = rootFolder.folders.map(f => f.name).includes(item.name);
              if (!rootFolderIncludesName) return item.name;
              let itemIndex = 1;
              while (rootFolder.folders.map(f => f.name).includes(`${item.name} (${itemIndex})`)) {
                itemIndex++;
              }
              return `${item.name} (${itemIndex})`;
            }

            const folderData = {
              name: folderName(),
              folder_id: parentFolder.id,
            };

            const response = await foldersApi.addFolder(project_id, folderData);
            const currentFolder = response.data;

            setUploadingProgress(prev => {
              return prev.map(p => {
                if (p.name === item.name && p.document === 'folder') {
                  return { ...p, progress: 100 };
                }
                return p;
              })
            });

            await processFolder(item.items, currentFolder);

          } else if (item.document === 'file') {


            const signedId = await uploadAttachment(item.file);

            const fileData = {
              name: item.name,
              description: '',
              version: '1',
              assets_attributes: [{ file: signedId }]
            };

            await documentsApi.addDocument(project_id, parentFolder.objectId, fileData);

            setUploadingProgress(prev => {
              return prev.map(p => {
                if (p.name === item.name && p.document === 'file') {
                  return { ...p, progress: 100 };
                }
                return p;
              })
            });
          }
        }
      };

      await processFolder(folderTree, rootFolder);
    },
    [project_id, rootFolder]
  );

  useEffect(() => {
    if (!documentFile) return;
    const url = URL.createObjectURL(documentFile);
    const link = document.createElement('a');
    link.href = url;
    link.download = downloadedFileName ?? '';
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }, [documentFile]);

  useEffect(() => {
    if (
      !archivedFolderData &&
      !archivedDocumentData &&
      !unarchivedFolderData &&
      !unarchivedDocumentData
    )
      return;
    onUpdate();
  }, [
    archivedFolderData,
    archivedDocumentData,
    unarchivedFolderData,
    unarchivedDocumentData,
  ]);

  useEffect(() => {
    if (!archiveFolderError) return;
    // TODO: Generically add error message from api response.
    setMostRecentError('Error archiving folder, please try again.');
    clearMostRecentError();
  }, [archiveFolderError]);

  useEffect(() => {
    if (!unarchiveFolderError) return;
    // TODO: Generically add error message from api response.
    setMostRecentError('Error unarchiving folder, please try again.');
    clearMostRecentError();
  }, [unarchiveFolderError]);

  const clearMostRecentError = () => {
    setTimeout(() => {
      setMostRecentError(null);
    }, 3000);
  };

  const handleClick = (row, evt) => {
    evt ?? evt.preventDefault();
    if (row.type === 'folder' || row.type === 'archived_folder') {
      route(row.objectId);
    } else {
      setPreviewDocument(row);
    }
  };

  const handleDownload = async (e, documentObjectId, fileName) => {
    e.preventDefault();
    setDownloadedFileName(fileName);
    await getDocumentFile(
      projectObjectId,
      folderData.objectId,
      documentObjectId
    );
  };

  const archiveEntity = async ({ row }) => {
    if (row.type === 'folder') {
      await archiveFolder(projectObjectId, row.objectId);
    } else {
      await archiveDocument(projectObjectId, folderData.objectId, row.objectId);
    }
  };

  const unarchiveEntity = async ({ row, e }) => {
    e.preventDefault();
    if (row.type === 'archived_folder') {
      await unarchiveFolder(projectObjectId, row.objectId);
    } else {
      await unarchiveDocument(
        projectObjectId,
        folderData.objectId,
        row.objectId
      );
    }
  };

  const DocumentEditMenu = ({ row, project, folder }) => {
    const [versionModalOpen, setVersionModalOpen] = useState(false);
    const [versionsModalOpen, setVersionsModalOpen] = useState(false);
    const [shareModalOpen, setShareModalOpen] = useState(false);

    const shareModal = useMemo(() => <ShareModal
      open={shareModalOpen}
      hideButton
      onClose={() => setShareModalOpen(false)}
      data={row}
      shareType={row.type === 'folder' ? 'Folder' : 'Document'}
      folderId={row.objectId}
      shareableApi={
        row.type === 'folder'
          ? foldersApi.updateFolder
          : documentsApi.updateDocument
      }
    />, [row.objectId, shareModalOpen]);

    return (
      <>
        <div className="text-xs text-center p-2 h-11 group flex justify-between items-center">
          <div className="hidden h-6 w-10 text-xl flex rounded justify-center items-center hover:bg-gray-100">
            <FiMoreHorizontal />
          </div>
          <div className="relative">
            <EditMenu action="click" dataTestId={'documentsEditButton'}>
              {checkPermission({ permissions: project.permissions, permission: 'create_public_link' }) && (
                <a
                  className={`cursor-pointer`}
                  data-testid="shareReportButton"
                  onClick={() => setShareModalOpen(true)}
                >
                  <div className="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3">
                    Share
                  </div>
                </a>
              )}

              {row.type !== 'folder' && (
                <li>
                  <a
                    className="focus:outline-none cursor-pointer font-medium text-sm text-blue-900 hover:text-blue-800 flex py-1 px-3"
                    type="button"
                    onClick={() => setVersionModalOpen(true)}
                  >
                    Upload New Version
                  </a>
                </li>
              )}

              {row.type !== 'folder' && row.versions && (
                <li>
                  <a
                    className="focus:outline-none cursor-pointer font-medium text-sm flex py-1 px-3"
                    type="button"
                    onClick={() => setVersionsModalOpen(true)}
                  >
                    Version History
                  </a>
                </li>
              )}
              {checkPermission({ permissions: project.permissions, permission: 'view_documents' }) &&
                row.type !== 'folder' &&
                !row.type.includes('archived') && (
                  <li>
                    <a
                      className="focus:outline-none font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3"
                      onClick={(e) => handleDownload(e, row.objectId, row.name)}
                    >
                      Download
                    </a>
                  </li>
                )}
              {checkPermission({ permissions: project.permissions, permission: 'view_documents' }) &&
                row.type === 'folder' &&
                !row.type.includes('archived') && (
                  <li>
                    <DownloadFolderButton
                      folder={row}
                      color={'link'}
                      className="my-0 bg-transparent shadow-none focus:outline-none font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3 hover:none"
                    />
                  </li>
                )}
              {checkPermission({ permissions: project.permissions, permission: 'view_documents' }) && (
                <li>
                  <a
                    className="focus:outline-none font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3"
                    rel="noreferrer"
                    onClick={(e) => handleClick(row, e)}
                  >
                    View
                  </a>
                </li>
              )}
              <li>
                {checkPermission({ permissions: project.permissions, permission: 'update_documents' }) &&
                  (row.type === 'folder' ? (
                    <AddFolderModal
                      folder={row}
                      onCreate={() => updateFolderId()}
                    />
                  ) : (
                    <EditDocumentModal
                      folderObjectId={folder.objectId}
                      document={row}
                      onUpdate={() => onUpdate()}
                    />
                  ))}
              </li>
              {checkPermission({ permissions: project.permissions, permission: 'view_documents' }) &&
                !row.type.includes('archived') && (
                  <li>
                    <MoveDocumentModal
                      row={row}
                      currentFolderId={folder.objectId}
                      onMove={onUpdate}
                    />
                  </li>
                )}
              {checkPermission({ permissions: project.permissions, permission: 'delete_documents' }) &&
                (!row.type.includes('archived') ? (
                  <li>
                    <ArchiveModal
                      onConfirmArchive={() => archiveEntity({ row })}
                      row={row}
                      errorMessage={
                        archiveFolderError
                          ? archiveFolderError
                          : archiveDocumentError
                      }
                    />
                  </li>
                ) : (
                  <li>
                    <a
                      className="focus:outline-none font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3"
                      rel="noreferrer"
                      onClick={(e) => unarchiveEntity({ row, e })}
                    >
                      Unarchive
                    </a>
                  </li>
                ))}
              {checkPermission({ permissions: project.permissions, permission: 'delete_documents' }) && (
                <li>
                  <ConfirmDeleteDocumentModal
                    title={'Confirm Delete'}
                    rowData={row}
                    folderObjectId={folder.objectId}
                    projectObjectId={project.objectId}
                    onDelete={onUpdate}
                  />
                </li>
              )}
            </EditMenu>
          </div>
        </div>
        {row.type !== 'folder' && (
          <>
            <AddDocumentVersionModal
              open={versionModalOpen}
              document={row}
              currentFolder={folder}
              onClose={() => setVersionModalOpen(false)}
              onCreate={onUpdate}
            />
            {row.versions && (
              <VersionsDocumentsModal
                open={versionsModalOpen}
                document={row}
                onClose={() => setVersionsModalOpen(false)}
              />
            )}
          </>
        )}
        {checkPermission({ permissions: project.permissions, permission: 'create_public_link' }) && shareModal}
      </>
    );
  };

  const handleRowSelected = useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);


  const shareModal = useMemo(() => <ShareModal
    data={folderData}
    shareType={'Folder'}
    folderId={folderData.objectId}
    shareableApi={foldersApi.updateFolder}
    button={<Button color="light" text="Share" />}
  />, [folderData?.objectId]);


  const isProcessingDragAndDropUpload = !!uploadingProgress.length;

  return (
    <>
      <div className="flex items-center justify-between">
        <Breadcrumb
          ancestors={folderData?.ancestors}
          current={folderData?.name}
          updateFolderId={updateFolderId}
        />
        <div>
          {!!data.length && (
            <>
              <DownloadFolderButton folder={folderData} />
              {checkPermission({ permissions: project.permissions, permission: 'create_public_link' }) && shareModal}
            </>
          )}
        </div>
      </div>
      <FolderDropzone onFolderDrop={handleFolderDrop}>
        <div className="relative">
          <UploadProgress uploadingProgress={uploadingProgress} afterSaved={() => {
            setUploadingProgress([]);
            onUpdate();
          }} />
          <DataTable
            disabled={isProcessingDragAndDropUpload}
            className={`!overflow-visible h-full`}
            customStyles={styles}
            selectableRows={selectableRows}
            selectableRowsComponentProps={
              {
                ['data-testid']: 'selectableRows',
              }
            }
            selectableRowsHighlight
            onSelectedRowsChange={handleRowSelected}
            clearSelectedRows={toggleCleared}
            noDataComponent={
              !!searchTerm ?
                <div className="py-5 italic font-medium">No documents found in this folder matching `{searchTerm}`</div>
                : <div onClick={() => {
                  const nearestInput = document.querySelector('input[type="file"]');
                  if (!nearestInput) return;
                  nearestInput.click();
                }} className="cursor-pointer flex justify-center w-full p-10 border-2 border-gray-300 border-dashed hover:bg-gray-50">
                  <EmptyState
                    heading={'Upload documents'}
                    subHeading={'Click or drag and drop folders/files here'}
                    hasButton={false}
                  />
                </div>
            }
            columns={defaultColumns.concat(
              [{
                name: 'Actions',
                cell: (row) => (
                  <DocumentEditMenu row={row} project={project} folder={folderData} />
                ),
                selector: (row) => row.id,
                sortable: false,
                width: '100px',
              }])
            }
            data={data}
            defaultSortFieldId={1}
            sortFunction={handleSort}
            keyField={'objectId'}
            onRowDoubleClicked={handleClick}
            conditionalRowStyles={conditionalRowStyles}
            pagination
            pointerOnHover
            highlightOnHover
          />
        </div>
      </FolderDropzone>

      <PhotosModal
        isOpen={!!previewDocument}
        assets={[previewDocument?.asset]}
        isPDF={previewDocument?.type === 'pdf'}
        isVideo={videoMimeTypes.includes(previewDocument?.type)}
        activeIndex={0}
        hideBackdrop={true}
        maxHeight={'max-h-screen'}
        onClose={() => setPreviewDocument(null)}
        defaultFullScreen={true}
        allowDelete={false}
      />

      {mostRecentError && (
        <ErrorView
          extraClass="mt-24"
          dismissable={true}
          error={mostRecentError}
        />
      )}
      {!!selectedRows.length && <MultiSelectBar.Container title={`${selectedRows.length} file${selectedRows.length > 1 ? 's' : ''} selected`}>
        <MultiSelectBar.ButtonContainer>
          <BulkDeleteDocumentsModal
            folderIds={selectedRows.filter(row => row.type === 'folder').map(row => row.objectId)}
            documentsIds={selectedRows.filter(row => !['folder', 'archived_folder'].includes(row.type)).map(row => row.objectId)}
            afterDelete={() => {
              onUpdate();
              setToggleCleared(true);
              setSelectedRows([]);
            }}
          />
          <BulkDownloadDocumentsModal
            documents={selectedRows}
          />
          <MultiSelectBar.Divider />
          <MultiSelectBar.CloseButton onClick={() => {
            setSelectedRows([]);
            setToggleCleared(!toggleCleared);
          }} />
        </MultiSelectBar.ButtonContainer>
      </MultiSelectBar.Container>}
      <FormPrompt
        hasUnsavedChanges={isProcessingDragAndDropUpload}
        disclaimer='Your files are still uploading, are you sure you want to leave?'
      />
    </>
  );
}


const selectableRows = true;
export const styles = {
  rows: {
    style: {
      minHeight: '36px',
    },
  },
  headCells: {
    style: {
      fontFamily: 'Inter, sans-serif',
      fontSize: '15px',
    },
  },
  cells: {
    style: {
      fontFamily: 'Inter, sans-serif',
      fontSize: '14px',
    },
  },
};

export const conditionalRowStyles = [
  {
    when: (row) => row.type.includes('archived'),
    style: {
      color: '#575959',
    },
  },
];

export const getFormattedStringForTableIcon = ({ type }) => {
  if (type.includes('archived')) {
    return type.replace('archived_', '');
  }

  return type;
};

export const videoMimeTypes = ['mp4', 'avi', 'mov', 'qt', 'wmv', 'm3u8', 'flv'];

const defaultColumns = [
  {
    name: 'Name',
    wrap: true,
    conditionalCellStyles: [
      {
        when: (row) => row.name,
      },
    ],
    cell: (row) => (
      <div
        data-tag="allowRowEvents"
        className="flex items-center cursor-pointer select-none"
        title={row.name}
      >
        <DocumentName
          name={
            row.type.includes('archived')
              ? `${row.name} [Archived]`
              : row.name
          }
          type={getFormattedStringForTableIcon({ type: row.type })}
        />
      </div>
    ),
    selector: (row) => row.name,
    sortable: true,
    sortField: 'name',
  },
  {
    name: 'Creator',
    selector: (row) => row.creator.name,
    sortable: true,
    conditionalCellStyles: [
      {
        when: (row) => row.creator.name,
        style: {
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        },
      },
    ],
  },
  {
    name: 'Last updated',
    selector: (row) => moment(row.updated_at).format('MMM DD, yyyy h:mm A'),
    sortable: true,
    sortFunction: (a, b) => {
      return new Date(a.updated_at) - new Date(b.updated_at);
    }
  },
];


const handleSort = (rows, selector, direction) => {
  const folders = rows.filter(
    (row) => row.type === 'folder' || row.type === 'archived_folder'
  );
  const documents = rows.filter(
    (row) => row.type !== 'folder' && row.type !== 'archived_folder'
  );

  // folders will always be sorted asc/desc before documents
  let data = folders.sort((rowA, rowB) => {
    const aField = selector(rowA);
    const bField = selector(rowB);
    let comparison = 0;

    if (aField > bField) {
      comparison = 1;
    } else if (aField < bField) {
      comparison = -1;
    }
    return direction === 'desc' ? comparison * -1 : comparison;
  });
  return data.concat(
    documents.sort((rowA, rowB) => {
      const aField = selector(rowA);
      const bField = selector(rowB);
      let comparison = 0;

      if (aField > bField) {
        comparison = 1;
      } else if (aField < bField) {
        comparison = -1;
      }
      return direction === 'desc' ? comparison * -1 : comparison;
    })
  );
}

const UploadProgress = ({ uploadingProgress, afterSaved }) => {

  const isProcessingDragAndDropUpload = !!uploadingProgress.length;
  const totalItemsCount = uploadingProgress.length;
  const totalProgress = totalItemsCount * 100;
  const currentProgress = uploadingProgress.map(({ progress }) => progress).reduce((acc, curr) => acc + curr, 0);
  const currentPercentageProgress = Math.round((parseFloat(currentProgress) / parseFloat(totalProgress)) * 100);
  const uploadCompleted = currentPercentageProgress === 100;


  const { saved } = useSaved(isProcessingDragAndDropUpload && uploadCompleted, {
    afterSaved,
  });

  if (!isProcessingDragAndDropUpload) return null;

  return <div className="w-full bg-white border-b py-2 px-3 z-40">
    <div className="flex items-center justify-between mb-1">
      <div className="flex items-center">{saved ? <span className="text-sm text-gray-600">Uploaded</span> : <span className="text-sm text-gray-600">Uploading</span>}  {saved ? <FiCheckCircle className="text-green-500 ml-2" /> : <Loader color="black" size="4" />}</div>
      <div className="flex"><span className="text-xs text-gray-500 mr-1 font-semibold">{currentPercentageProgress} /</span> <span className="text-xs text-gray-500 mr-1">100%</span>
      </div>
    </div>
    <Progress current={currentProgress} total={totalProgress} />
  </div>
}
