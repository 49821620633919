import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import pinTypesApi from '../../api/pin_types';
import useApi from '../../hooks/useApi';
import Loader from '../utility/Loader';

export default function PinTypeRadioButtons({
  pinTypes = [],
  loading,
  chosen,
  onChosen,
}) {
  return (
    <div className="flex flex-wrap text-xs items-center">
      {loading && <Loader margin={'mx-2'} color="text-gray-800" />}
      {pinTypes.map((pin_type) => {
        let chosenType = chosen.find((id) => id === pin_type.id);
        return (
          <a
            key={`pin_type_radio_button_${pin_type.id}`}
            onClick={() =>
              onChosen && onChosen(pin_type.id, chosenType !== undefined)
            }
            className={`cursor-pointer mr-2 px-2 py-1 border rounded-md ${
              chosenType
                ? 'bg-blue-50 text-secondary border-secondary shadow'
                : 'bg-white'
            } hover:shadow`}
          >
            {pin_type.name}
          </a>
        );
      })}
    </div>
  );
}
