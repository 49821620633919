import { memo, useEffect, useRef, useState } from 'react';
import { FiTrash2 } from 'react-icons/fi';
import assetsApi from '../../api/assets';
import useApi from '../../hooks/useApi';
import Modal, { ModalHeader, ModalSuccessView } from './Modal';
import Loader from './Loader';

function DeleteButton({ asset, onDelete }) {
  const {
    data: deletedAsset,
    error,
    loading: deleting,
    request: deleteAsset,
  } = useApi(assetsApi.deleteAsset, null);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const deletedTimer = useRef();

  const removeAsset = () => deleteAsset(asset.objectId);

  const showModal = () => setModalOpen(true);

  const closeModal = () => {
    setModalOpen(false);
    if (!deletedTimer.current) return;
    clearTimeout(deletedTimer.current);
    onDelete?.();
  };

  useEffect(() => {
    if (!deletedAsset) return;
    setDeleted(true);
  }, [deletedAsset]);

  useEffect(() => {
    if (!deleted) return;
    deletedTimer.current = setTimeout(() => onDelete?.(), 3000);
  }, [deleted]);

  return (
    <>
      <div
        key={`delete_button`}
        className={`${error && 'opacity-50 cursor-not-allowed'
          } px-2 bg-black flex items-center h-12 w-12 rounded-full flex justify-center focus:outline-none active:bg-gray-900 disabled:opacity-50 mr-2 hover:bg-gray-900 cursor-pointer text-white`}
        onClick={showModal}
      >
        <FiTrash2 size={24} />
      </div>
      <Modal isOpen={modalOpen} onClose={closeModal}>
        <ModalHeader title={'Confirm Image Removal'} onClose={closeModal} />
        <div className="px-2 pt-2 text-gray-900">
          <div className="p-5">
            {!deleted && (
              <>
                <div className="mb-4">
                  <div className="font-semibold">Are you sure?</div>
                </div>
                <div>
                  This will completely delete the image. It cannot be undone.
                </div>
              </>
            )}
            {deleted && (
              <ModalSuccessView text={'Image successfully deleted'} />
            )}
          </div>

          {!deleted && (
            <div className="modal-footer">
              <button
                className="modal-remove-btn"
                type="button"
                onClick={removeAsset}
                disabled={deleting}
              >
                Delete Image {deleting && <Loader />}
              </button>
              <button
                className="modal-close-btn"
                type="button"
                onClick={closeModal}
                disabled={deleting}
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}

export default memo(DeleteButton);
