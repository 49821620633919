import client from './client';
const endpoint = '/project_members';

const getProjectMembers = (projectId, params = {}) =>
  client.get(`/projects/${projectId}${endpoint}`, params);
const getProjectMember = (id, params = {}) =>
  client.get(`${endpoint}/${id}`, params);
const addProjectMember = (projectId, project_member) =>
  client.post(`/projects/${projectId}${endpoint}`, { project_member });
const updateProjectMember = (projectMemberId, project_member) =>
  client.put(`${endpoint}/${projectMemberId}`, { project_member });
const destroyProjectMember = (projectMemberId) =>
  client.delete(`${endpoint}/${projectMemberId}`);

export default {
  getProjectMembers,
  getProjectMember,
  addProjectMember,
  updateProjectMember,
  destroyProjectMember,
};
