import { useCallback, useEffect, useRef, useState } from 'react';
import Modal, { ModalHeader, ModalSuccessView } from '../utility/Modal';
import { AiOutlineCaretDown, AiOutlineFilePdf } from 'react-icons/ai';
import { useDropzone } from 'react-dropzone';
import Loader from '../utility/Loader';
import AddPDFItem from './AddPDFItem';
import useModal from '../../hooks/useModal';
import { useParams } from 'react-router-dom';
import timings from '../../styles/timings';

const ReadyToUploadBody = ({ isDragActive }) => (
  <>
    <AiOutlineFilePdf
      size={60}
      className={`mb-1 mx-auto ${!isDragActive ? 'text-tertiary-light' : 'text-secondary'
        }`}
    />
    <div
      className={`font-semibold ${!isDragActive ? 'text-tertiary' : 'text-secondary'
        }`}
    >
      Click to upload file
      <br />
      or drag and drop
    </div>
  </>
);

const VersionUploadBody = ({ oldName, newName }) => (
  <>
    <div className={`border-2 border-gray-900 bg-gray-50 pb-1 rounded-lg`}>
      <div
        className={`font-semibold text-xs text-gray-800 py-1 bg-gray-200 rounded-t-lg`}
      >
        Old
      </div>
      <div className={`py-2 px-3`}>
        <AiOutlineFilePdf size={30} className={`mb-1 mx-auto text-gray-800`} />
        <div className={`font-semibold text-sm text-gray-800`}>{oldName}</div>
      </div>
    </div>
    <div className={`py-2`}>
      <AiOutlineCaretDown size={20} className={`mb-1 mx-auto text-secondary`} />
    </div>
    <div className={`border-2 border-blue-900 pb-1 rounded-lg`}>
      <div
        className={`font-semibold text-xs text-blue-800 py-1 bg-blue-200 rounded-t-lg`}
      >
        New
      </div>
      <div className={`py-2 px-3`}>
        <AiOutlineFilePdf size={30} className={`mb-1 mx-auto text-secondary`} />
        <div className={`font-semibold text-sm text-secondary`}>{newName}</div>
      </div>
    </div>
  </>
);
export default function UploadVersionConstructionDrawingModal({
  constructionDrawing: { name, objectId },
  open = false,
  afterAdd,
  onClose,
}) {
  const { project_id } = useParams();
  const {
    open: modalOpen,
    setOpen: setModalOpen,
    toggle: toggleOpen,
  } = useModal();
  const [savedPDF, setSavedPDF] = useState(null);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const savedTimer = useRef(null);
  const [file, setFile] = useState(null);

  const _onClose = () => {
    toggleOpen();
    onClose?.();
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFile(acceptedFiles[0]);
    },
    [file]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'application/pdf',
    onDrop,
  });

  const removeImageAtIndex = (index) => {
    setFile(null);
  };

  const resetModal = () => {
    setFile(null);
    setSaving(false);
  };

  const containerStyle = () => {
    if (isDragActive) return 'border-3 border-secondary bg-blue-100';
    return 'border-2 border-gray-300';
  };

  useEffect(() => {
    if (!savedPDF) return;
    savedTimer.current = setTimeout(() => setSaved(true), timings.normal);
  }, [savedPDF]);

  useEffect(() => {
    if (saved) {
      resetModal();
      savedTimer.current = setTimeout(() => {
        setModalOpen(false);
        onClose?.();
        afterAdd?.();
        setTimeout(() => {
          setSaved(false);
        }, 500);
      }, timings.normal);
    }
  }, [saved]);

  useEffect(() => {
    setModalOpen(open);
  }, [open]);

  return (
    <div>
      <Modal isOpen={modalOpen} onClose={_onClose}>
        <ModalHeader
          title={
            <>
              Upload New Version <span className="mx-1 font-extralight">|</span>{' '}
              <span className="font-light">{name}</span>{' '}
            </>
          }
          onClose={_onClose}
        />
        {saved ? (
          <div className="p-5">
            <ModalSuccessView
              text={'New Version of PDF successfully uploaded'}
            />
          </div>
        ) : (
          <div className="px-2 pt-2">
            <div className="p-5">
              {file && (
                <>
                  <div className="pb-2 flex flex-wrap hidden">
                    <AddPDFItem
                      key={`project_${project_id}_temp_pdf_${file.path}`}
                      file={file}
                      adding={false}
                      constructionDrawing={{ objectId }}
                      startSaving={saving}
                      index={0}
                      onSaved={setSavedPDF}
                      onRemove={removeImageAtIndex}
                    />
                  </div>
                </>
              )}
              <div
                className="cursor-pointer ring-0 text-base flex-1"
                {...getRootProps()}
              >
                <div
                  disabled={saving !== false}
                  className={`disabled:opacity-50 p-5 h-72 flex flex-col active:ring-0 items-center justify-center ${containerStyle()} border-dashed hover:opacity-80 rounded-md text-center`}
                >
                  {file && saving && (
                    <div className="absolute w-full flex h-full justify-center items-center">
                      <Loader color={'gray-800'} margin={''} />
                    </div>
                  )}
                  <input {...getInputProps()} />
                  {file ? (
                    <VersionUploadBody oldName={name} newName={file.name} />
                  ) : (
                    <ReadyToUploadBody isDragActive={isDragActive} />
                  )}
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                className="modal-save-btn"
                type="button"
                disabled={!file || saving !== false}
                onClick={() => setSaving(true)}
              >
                Upload
                {saving !== false && <Loader className="ml-3" />}
              </button>
              <button
                className="modal-close-btn"
                type="button"
                disabled={saving !== false}
                onClick={() => {
                  _onClose();
                  resetModal();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}
