import client from './client';
const endpoint = '/pin_types';

const getPinTypes = (workspaceId, params = {}) =>
  client.get(`/workspaces/${workspaceId}${endpoint}`, params);
const getPinType = (pinTypeId) => client.get(`${endpoint}/${pinTypeId}`);
const updatePinType = (pinTypeId, pinType) => client.put(`${endpoint}/${pinTypeId}`, { pin_type: pinType });
const archivePinType = (pinTypeId) => client.put(`${endpoint}/${pinTypeId}/archive`);
const unarchivePinType = (pinTypeId) => client.put(`${endpoint}/${pinTypeId}/unarchive`);
const getProjectPinTypes = (projectId, params = {}) =>
  client.get(`/projects/${projectId}${endpoint}`, params);

export default {
  archivePinType,
  updatePinType,
  unarchivePinType,
  getPinTypes,
  getPinType,
  getProjectPinTypes,
};
