import client from './client';

const invited = (email) => client.post('/invited', { email });
const getInvitation = (invitation_token) =>
  client.get('/invitation/accept', { invitation_token });
const invitation = (user) => client.put('/invitation', { user });
const invite = (user) => client.post('/invitation', { user });

export default {
  invited,
  invite,
  invitation,
  getInvitation,
};
