import { useEffect } from 'react';
import { Datum, SearchParams } from '../../../data/models';
import useApi from '../../../hooks/useApi';
import datumsApi from '../../../api/datums';
type DatumConfg = {
    datums: Datum[],
    error: any,
    loading: boolean,
}

function modifyStrings(str1: string, str2: string | undefined) {
    if (!str2) return str1;
    // Helper function to extract text within parentheses
    function extractParentheses(str: string) {
        const match = str.match(/\(([^)]+)\)/);
        return match ? match[1] : '';
    }

    // Helper function to remove text within parentheses
    function removeParentheses(str: string) {
        return str.replace(/\([^)]*\)/g, '');
    }

    // Extract text within parentheses from str2
    const parenthesesContent = extractParentheses(str2);

    // Remove text within parentheses from str1
    const modifiedStr1 = removeParentheses(str1).trim();

    // Add extracted text from str2 to str1
    const result = parenthesesContent ? `${modifiedStr1} (${parenthesesContent})` : modifiedStr1;

    return result;
}


const datumName = (datum: Datum | null): string => {
    if (!datum) return 'None';
    return modifyStrings(datum.name, datum.data.json.conversion?.name)
}

export {
    datumName
}
export default function useDatums(params: SearchParams, {
    enabled = true
}: {
    enabled?: boolean
} = {
    }): DatumConfg {
    const {
        data: { records: datums },
        error,
        loading,
        request: getDatums,
    } = useApi(datumsApi.getDatams, { records: [], pagy: {} }, true);

    useEffect(() => {
        if (!enabled) return;
        getDatums(params);
    }, [enabled, JSON.stringify(params)])

    return {
        datums,
        error,
        loading,
    }
}