import { useState, useCallback, useEffect } from 'react';
import { FeatureFlag, PinsSetting, Product, Workspace } from '../../data/models';
import Form from '../../utils/Form';
import useProducts from '../products/hooks/useProducts';
import capitalize from '../../utility/capitalize';
import PermissionsViewModel from '../../models/PermissionsViewModel';
import useFeatureFlags from '../feature_flags/hooks/useFeatureFlags';
import Loader from '../utility/Loader';
import AddPhotoItem from '../project/AddPhotoItem';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { ReportHeaderComponent } from '../reports/ReportHeader';
import { BiInfoCircle, BiXCircle } from 'react-icons/bi';
import { Flex } from '../../utils/Layout';
import Tooltip from '../shared/Tooltip';
export default function WorkspaceForm({
    workspace,
    onUpdate,
    permissions
}: {
    workspace: Workspace | null
    onUpdate: (field: string, value: any) => void,
    permissions?: PermissionsViewModel
}) {

    const { featureFlags, loading } = useFeatureFlags(permissions?.can?.('admin'));

    if (!workspace) return null;

    const testIdPrefix = workspace?.id ? 'edit' : 'create';
    const testIdSuffix = workspace?.id ? workspace.name : '';

    return <><Form.Label>Name</Form.Label>
        <Form.InputGroup>
            <Form.Input
                type="text"
                placeholder="Enter Company Name"
                data-testId={`${testIdPrefix}WorkspaceName${testIdSuffix}`}
                value={workspace.name}
                onChange={({ target: { value } }) =>
                    onUpdate('name', value)
                }
            />
        </Form.InputGroup>
        {permissions?.can?.('admin') && (
            <>
                <Form.Label>Subscription</Form.Label>
                <SubscriptionSelect
                    onSelect={({ target: { value: product_id } }) => {
                        onUpdate('active_subscription_attributes', { id: workspace.active_subscription?.id, product_id, status: 'active' })
                    }}
                    data-testId={`${testIdPrefix}WorkspaceSubscription${testIdSuffix}`}
                    selected={workspace.active_subscription?.product?.id}
                />
                <Form.Label>Custom Domain</Form.Label>
                <Form.InputGroup>
                    <Form.Input
                        type="text"
                        placeholder="company.com"
                        data-testId={`${testIdPrefix}WorkspaceDomain${testIdSuffix}`}
                        value={workspace.custom_domain}
                        onChange={({ target: { value } }) =>
                            onUpdate('custom_domain', value)
                        }
                    />
                </Form.InputGroup>

                <Form.Group className='flex' mb={4}>
                    <Form.Switch checked={!!workspace.allow_matching_custom_domain_signups}
                        data-testId={`${testIdPrefix}WorkspaceAllowCustomDomain${testIdSuffix}`}
                        htmlFor={'workspaceCustomDomain'}
                        onClick={() => {
                            onUpdate(
                                'allow_matching_custom_domain_signups',
                                !workspace.allow_matching_custom_domain_signups
                            )
                        }}
                    />
                    <Form.SwitchLabel>
                        Allow{' '}
                        <span className="font-semibold">
                            {workspace.custom_domain ? '@' + workspace.custom_domain : 'domain'}{' '}
                        </span>
                        users to join{' '}
                        <span className="font-semibold">
                            {workspace.name || 'workspace'}
                        </span>
                    </Form.SwitchLabel>
                </Form.Group>
                <Form.Group className='flex'>
                    <Form.Switch checked={!!workspace.conference}
                        data-testId={`${testIdPrefix}WorkspaceConference${testIdSuffix}`}
                        htmlFor={'workspaceConference'}
                        onClick={() => {
                            onUpdate(
                                'conference',
                                !workspace.conference
                            )
                        }}
                    />
                    <Form.SwitchLabel>
                        Is this workspace for a conference?
                    </Form.SwitchLabel>
                </Form.Group>

                <div className="bg-white p-5 mb-9 rounded-md shadow-sm">
                    <Form.Label mb={3} size='lg'>Project Settings</Form.Label>
                    <hr className="border-gray-300 mb-6" />
                    <Form.Group mb={4}>
                        <Form.Label mb={3}>Max Active Projects</Form.Label>
                        <Form.InputGroup>
                            <Form.Input type="number"
                                data-testId={`${testIdPrefix}WorkspaceMaxProjectLimit${testIdSuffix}`}
                                value={workspace.max_active_projects || ''}
                                onChange={({ target: { value } }) =>
                                    onUpdate(
                                        'max_active_projects',
                                        value
                                    )
                                } />
                        </Form.InputGroup>
                    </Form.Group>
                    <Form.Group className='flex' mb={4}>
                        <Form.Switch checked={!!workspace.settings?.projects?.identifiers?.required}
                            data-testId={`${testIdPrefix}WorkspaceSettingsIdentifiersRequired${testIdSuffix}`}
                            htmlFor={'workspaceSettingsIdentifiersRequired'}
                            onClick={() => {
                                onUpdate(
                                    'settings',
                                    {
                                        ...workspace.settings,
                                        projects: {
                                            ...workspace.settings?.projects,
                                            identifiers: {
                                                ...workspace.settings?.projects?.identifiers,
                                                required: !workspace.settings?.projects?.identifiers?.required
                                            }
                                        }
                                    }
                                )
                            }}
                        />
                        <Form.SwitchLabel>
                            Require project identifiers for new projects.
                        </Form.SwitchLabel>
                    </Form.Group>

                    <Form.Group className='flex' mb={4}>
                        <Form.Switch checked={!!workspace.settings?.projects?.identifiers?.scoped}
                            data-testId={`${testIdPrefix}WorkspaceSettingsScopedIdentifiers${testIdSuffix}`}
                            htmlFor={'workspaceSettingsScopedIdentifiers'}
                            onClick={() => {
                                onUpdate(
                                    'settings',
                                    {
                                        ...workspace.settings,
                                        projects: {
                                            ...workspace.settings?.projects,
                                            identifiers: {
                                                ...workspace.settings?.projects?.identifiers,
                                                scoped: !workspace.settings?.projects?.identifiers?.scoped
                                            }
                                        }
                                    }
                                )
                            }}
                        />
                        <Form.SwitchLabel>
                            Scope pin identifiers to project.
                        </Form.SwitchLabel>
                    </Form.Group>

                    <PinNoteSettings
                        pinsSetting={workspace.settings?.projects?.pins || {}}
                        onUpdate={(newPinsSetting) => {
                            onUpdate(
                                'settings',
                                {
                                    ...workspace.settings,
                                    projects: {
                                        ...workspace.settings?.projects,
                                        pins: newPinsSetting
                                    }
                                }
                            )
                        }}
                        testIdPrefix={testIdPrefix}
                        testIdSuffix={testIdSuffix}
                    />
                </div>

                <div className="bg-white p-5 mb-9 rounded-md shadow-sm">
                    <Form.Label mb={3} size='lg'>Report Settings</Form.Label>
                    <hr className="border-gray-300 mb-6" />
                    <Form.Label mb={3}>Logo</Form.Label>
                    <ReportLogo
                        key={`workspaceReportLogoPage${workspace?.updated_at}`}
                        workspace={workspace}
                        onUpdate={onUpdate}
                    />
                    <Form.Label mb={3}>Cover page</Form.Label>
                    <ReportCoverPage
                        key={`workspaceReportCoverPage${workspace?.updated_at}`}
                        workspace={workspace}
                        onUpdate={onUpdate}
                    />
                </div>
                <Form.Group className="bg-white border-b border-gray-200 rounded-lg shadow-sm p-3">
                    <Form.Label>
                        Feature Flags{' '}
                        {loading && <Loader color="text-gray-800" />}
                    </Form.Label>
                    {featureFlags
                        .filter(({ percentage }) => percentage !== 100)
                        .map((featureFlag) => {
                            const flagOn = workspace.feature_flags?.includes?.(featureFlag.key);
                            return (
                                <FeatureFlagCheckbox
                                    featureFlag={featureFlag}
                                    checked={flagOn}
                                    onClick={() => {
                                        const workspaceFeatureFlags = workspace.feature_flags || [];
                                        if (flagOn) {
                                            const index = workspaceFeatureFlags.indexOf(featureFlag.key);
                                            workspaceFeatureFlags.splice(index, 1);
                                        } else {
                                            workspaceFeatureFlags.push(featureFlag.key);
                                        }
                                        onUpdate('feature_flags', workspaceFeatureFlags);
                                    }}
                                />
                            );
                        })}
                </Form.Group>
            </>)}
    </>;
}

export const PinNoteSettings = ({
    pinsSetting,
    testIdPrefix,
    testIdSuffix,
    onUpdate
}: {
    testIdPrefix: string,
    testIdSuffix: string,
    pinsSetting: PinsSetting
    onUpdate: (newPinsSetting: PinsSetting) => void
}) => {

    const pinNotesSettings = pinsSetting?.notes;
    const pinNotesRequired = !!pinNotesSettings?.required?.length || !!pinNotesSettings?.assets?.required?.length;

    return <Form.Group mb={4} overflow='visible'>
        <Flex>
            <Form.Switch checked={pinNotesRequired}
                data-testId={`${testIdPrefix}SettingsNoteRequired${testIdSuffix}`}
                htmlFor={'settingsNoteRequired'}
                onClick={() => {
                    onUpdate({
                        ...pinNotesSettings,
                        notes: {
                            required: pinNotesRequired ? [] : ['create', 'update'],
                            assets: {
                                required: pinNotesRequired ? [] : ['create', 'update'],
                            }
                        },
                    })
                }}
            />
            <Form.SwitchLabel>
                Require note and/or photo on pins.
            </Form.SwitchLabel>
            <Tooltip
                size="lg"
                position="top"
                className="text-sm font-normal"
                icon={
                    <BiInfoCircle className="ml-1 text-gray-50 bg-blue-800 rounded-full" />
                }
            >
                Requiring notes and photos on pins and worklogs can ensure quality - toggle to <strong>require</strong> pins to have notes and photos when created and updated.
            </Tooltip>
        </Flex>
        {pinNotesRequired &&
            <div className="ml-2 mt-4 bg-gray-50 p-3 border rounded">
                <Form.Label size='sm'>Pin Creation
                    <Tooltip
                        size="lg"
                        position="top"
                        className="text-sm font-normal"
                        icon={
                            <BiInfoCircle className="ml-1.5 text-gray-50 bg-blue-800 rounded-full" />
                        }
                    >
                        Requiring notes and photos during pin creation can ensure quality - updates in this section will only <strong>affect new pins</strong>.
                    </Tooltip>
                </Form.Label>
                <Form.Group className='flex' mb={2}>
                    <Form.Switch checked={pinNotesSettings.required?.includes?.('create')}
                        data-testId={`${testIdPrefix}SettingsNoteRequiredCreate${testIdSuffix}`}
                        htmlFor={'settingsNoteRequiredCreate'}
                        onClick={() => {
                            onUpdate({
                                ...pinNotesSettings,
                                notes: {
                                    ...pinNotesSettings,
                                    required: pinNotesSettings.required?.includes?.('create') ? pinNotesSettings?.required.filter?.((s) => s !== 'create') : [...pinNotesSettings.required, 'create'],
                                },
                            })
                        }}
                    />
                    <Form.SwitchLabel>
                        Require <span className="font-semibold">note.</span>
                    </Form.SwitchLabel>
                </Form.Group>
                <Form.Group className='flex border-b pb-4' mb={4}>
                    <Form.Switch checked={pinNotesSettings.assets?.required?.includes?.('create')}
                        data-testId={`${testIdPrefix}SettingsNoteAssetRequiredCreate${testIdSuffix}`}
                        htmlFor={'SettingsNoteAssetRequiredCreate'}
                        onClick={() => {
                            onUpdate({
                                ...pinNotesSettings,
                                notes: {
                                    ...pinNotesSettings,
                                    assets: {
                                        ...pinNotesSettings.assets,
                                        required: pinNotesSettings.assets?.required?.includes?.('create') ? pinNotesSettings?.assets.required.filter?.((s) => s !== 'create') : [...pinNotesSettings.assets.required, 'create'],
                                    }
                                },
                            })
                        }}
                    />
                    <Form.SwitchLabel>
                        Require <span className="font-semibold">photo.</span>
                    </Form.SwitchLabel>
                </Form.Group>
                <Form.Label size='sm'>Work logs <Tooltip
                    size="lg"
                    className='text-sm font-normal'
                    position="top"
                    icon={
                        <BiInfoCircle className="ml-1.5 text-gray-50 bg-blue-800 rounded-full" />
                    }
                >
                    Requiring notes and photos on worklogs can ensure quality - updates in this section will only <strong>only affect new worklogs</strong>.
                </Tooltip></Form.Label>

                <Form.Group className='flex' mb={3}>
                    <Form.Switch checked={pinNotesSettings.required?.includes?.('update')}
                        data-testId={`${testIdPrefix}WorkspaceSettingsNoteRequiredUpdate${testIdSuffix}`}
                        htmlFor={'WorkspaceSettingsNoteRequiredUpdate'}
                        onClick={() => {
                            onUpdate({
                                ...pinNotesSettings,
                                notes: {
                                    ...pinNotesSettings,
                                    required: pinNotesSettings.required?.includes?.('update') ? pinNotesSettings?.required.filter?.((s) => s !== 'update') : [...pinNotesSettings.required, 'update'],
                                },
                            })
                        }}
                    />
                    <Form.SwitchLabel>
                        Require <span className="font-semibold">note.</span>
                    </Form.SwitchLabel>
                </Form.Group>
                <Form.Group className='flex' mb={2}>
                    <Form.Switch checked={pinNotesSettings.assets?.required?.includes?.('update')}
                        data-testId={`${testIdPrefix}SettingsNoteAssetRequiredUpdate${testIdSuffix}`}
                        htmlFor={'SettingsNoteAssetRequiredUpdate'}
                        onClick={() => {

                            onUpdate({
                                ...pinNotesSettings,
                                notes: {
                                    ...pinNotesSettings,
                                    assets: {
                                        ...pinNotesSettings.assets,
                                        required: pinNotesSettings.assets?.required?.includes?.('update') ? pinNotesSettings?.assets.required.filter?.((s) => s !== 'update') : [...pinNotesSettings.assets.required, 'update'],
                                    }
                                },
                            })
                        }}
                    />
                    <Form.SwitchLabel>
                        Require <span className="font-semibold">photo.</span>
                    </Form.SwitchLabel>
                </Form.Group>
            </div>}
    </Form.Group>
}

const ReportCoverPage = ({ workspace, onUpdate }: {
    workspace: Workspace,
    onUpdate: (field: string, value: any) => void
}) => {

    const [files, setFiles] = useState<any[]>([]);
    const [forceCoverPageUpdate, setForceCoverPageUpdate] = useState<boolean>(false);
    const [savedPhotos, setSavedPhotos] = useState<string[]>([]);
    const [saving, setSaving] = useState<boolean>(false);
    const savePhotos = (signedId: string) => {
        setSavedPhotos([...savedPhotos, signedId]);
    };

    const onDrop = useCallback(
        (acceptedFiles: any) => {
            setFiles([...files, ...acceptedFiles]);
        },
        [files]
    );

    const removeImageAtIndex = (index: number) => {
        if (files.length === 0) {
            onUpdate('cover_page_attributes', {
                id: workspace.cover_page?.id,
                _destroy: true
            });
            return setForceCoverPageUpdate(true);
        }
        setFiles([...files.filter((f, i) => i !== index)]);
    };

    useEffect(() => {
        if (files.length === 0) return;
        setSaving(true);
    }, [files])

    useEffect(() => {
        if (savedPhotos.length === 0) return;
        onUpdate('cover_page_attributes', {
            name: "Cover Page",
            asset_attributes: { file: savedPhotos[0] },
        });
    }, [savedPhotos])

    return <div className="mb-4">
        <div className="pb-2 flex flex-wrap">
            {files.map((file, index) => {
                return (
                    <AddPhotoItem
                        key={`workspace_${workspace.objectId}_coverPage_${file.path}`}
                        file={file}
                        startSaving={saving}
                        index={index}
                        onSaved={savePhotos}
                        passback={true}
                        description={''}
                        onSelect={undefined}
                        onRemove={removeImageAtIndex}
                        locationRequired={undefined}
                        onLocated={undefined}
                    />
                );
            })}
            {workspace.cover_page && !forceCoverPageUpdate && <AddPhotoItem
                key={`workspace_${workspace.objectId}_coverPage_${workspace.cover_page.asset.name}`}
                src={workspace.cover_page.asset.files.medium}
                file={undefined}
                index={0}
                passback={true}
                description={''}
                onSelect={undefined}
                onRemove={removeImageAtIndex}
                locationRequired={undefined}
                onLocated={undefined}
            />}
        </div>
        {(!files.length && (!workspace.cover_page || forceCoverPageUpdate)) && <Form.FileDropzone heightClass='h-44' accept="application/pdf" onDrop={onDrop}>
            <AiOutlineFilePdf
                size={60}
                className={`mb-1 mx-auto text-tertiary-light`}
            />
            <div
                className={`font-semibold text-tertiary
                                }`}
            >
                Click to upload pdf
                <br />
                or drag and drop
            </div>
        </Form.FileDropzone>}
    </div>
}

const DEFAULT_CLASS_SIZE = 12;
const ReportLogo = ({ workspace, onUpdate }: {
    workspace: Workspace,
    onUpdate: (field: string, value: any) => void
}) => {

    const [files, setFiles] = useState<any[]>([]);
    const [forceCoverPageUpdate, setForceCoverPageUpdate] = useState<boolean>(false);
    const [savedPhotos, setSavedPhotos] = useState<string[]>([]);
    const [saving, setSaving] = useState<boolean>(false);
    const [currentClassSize, setCurrentClassSize] = useState<number | null>(null);
    const savePhotos = (signedId: string) => {
        setSavedPhotos([...savedPhotos, signedId]);
    };

    const onDrop = useCallback(
        (acceptedFiles: any) => {
            setFiles([...files, ...acceptedFiles]);
        },
        [files]
    );

    const removeImageAtIndex = (index: number) => {
        if (files.length === 0) {
            onUpdate('report_logo_attributes', {
                id: workspace.report_logo?.id,
                _destroy: true
            });
            return setForceCoverPageUpdate(true);
        }
        setFiles([...files.filter((f, i) => i !== index)]);
    };

    useEffect(() => {
        if (files.length === 0) return;
        setSaving(true);
    }, [files])

    useEffect(() => {
        if (savedPhotos.length === 0) return;
        onUpdate('report_logo_attributes', {
            id: workspace.report_logo?.id,
            file: savedPhotos[0],
            metadata: {
                class_name: `h-${currentClassSize}`
            }
        });
    }, [savedPhotos])

    useEffect(() => {
        if (currentClassSize === null) return;
        onUpdate('report_logo_attributes', {
            id: workspace.report_logo?.id,
            file: savedPhotos[0],
            metadata: {
                class_name: `h-${currentClassSize}`
            }
        });
    }, [currentClassSize])

    return <div className="mb-8">
        <div className="pb-2 flex flex-wrap">
            {files.map((file, index) => {
                return (
                    <AddPhotoItem
                        key={`workspace_${workspace.objectId}_coverPage_${file.path}`}
                        file={file}
                        startSaving={saving}
                        index={index}
                        onSaved={savePhotos}
                        passback={true}
                        description={''}
                        onSelect={undefined}
                        onRemove={removeImageAtIndex}
                        locationRequired={undefined}
                        onLocated={undefined}
                    />
                );
            })}
            {workspace.report_logo && !forceCoverPageUpdate && <div className="w-full py-4 px-2 bg-gray-50 group relative">
                <ReportHeaderComponent
                    imgEditable
                    onImageEdit={(delta: number) => setCurrentClassSize((currentClassSize || DEFAULT_CLASS_SIZE) + delta)}
                    projectIdentifier={'123'}
                    projectName={'Test Project'}
                    reportName={'Report #1'}
                    imgClassName={currentClassSize !== null ? `h-${currentClassSize}` : (workspace.report_logo.metadata.class_name || 'h-12')}
                    imgSrc={workspace.report_logo.files.medium}
                    editing={false}
                    printable
                    onImageLoad={() => { }}
                    onBlur={() => { }}
                    onInput={() => { }}
                    saving={false}
                    saved={false}
                />
                <BiXCircle
                    size={20}
                    data-testid="reportLogoRemove"
                    className="hover:opacity-80 cursor-pointer rounded-full overflow-hidden absolute -top-1 -right-1 bg-black text-white opacity-0 group-hover:opacity-100"
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        removeImageAtIndex?.(0)
                        return false;
                    }}
                />
            </div>}
        </div>
        {(!files.length && (!workspace.report_logo || forceCoverPageUpdate)) && <Form.FileDropzone heightClass='h-44' accept="image/png,image/jpeg,image/webp" onDrop={onDrop}>
            <AiOutlineFilePdf
                size={60}
                className={`mb-1 mx-auto text-tertiary-light`}
            />
            <div
                className={`font-semibold text-tertiary
                                }`}
            >
                Click to upload image
                <br />
                or drag and drop
            </div>
        </Form.FileDropzone>}
    </div>
}

const FeatureFlagCheckbox = ({ featureFlag, onClick, checked }: {
    featureFlag: FeatureFlag,
    onClick?: () => void,
    checked: boolean
}) => (<Form.Group mb={2} className='flex items-center h-12 border p-2 rounded-lg shadow-xs'>
    <Form.Switch checked={checked}
        htmlFor={`featureFlag${featureFlag.key}`}
        onClick={onClick}
    />
    <Form.SwitchLabel>
        <p className="text-gray-800 text-sm mb-0 font-medium">{featureFlag.name}</p>
        <p className="text-gray-600 italic text-sm">
            {featureFlag.data.description}{' '}
        </p>
    </Form.SwitchLabel>
</Form.Group>);

const SubscriptionSelect = ({ onSelect, selected, ...rest }: {
    onSelect: (e: React.ChangeEvent<HTMLSelectElement>) => void,
    selected?: number,
    [key: string]: any
}) => {
    const { products } = useProducts();
    return (
        <Form.SelectGroup>
            <Form.Select
                onChange={onSelect}
                {...rest}
            >
                <option value="">No Active Subscription</option>
                {products.map(({ id, name }: Product) =>
                    <option selected={selected === id} value={id}>{capitalize(name)}</option>
                )}
            </Form.Select>
        </Form.SelectGroup>
    );

}