import { useEffect, useRef, useState } from 'react';
import Modal, { ModalHeader, ModalSuccessView } from '../utility/Modal';
import useApi from '../../hooks/useApi';
import constructionDrawingsApi from '../../api/construction_drawings';
import Loader from '../utility/Loader';

export default function DeleteConstructionDrawingModal({
  constructionDrawing,
  afterDelete,
}) {
  const {
    data: deleted_construction_drawing,
    error,
    loading: deleting,
    request: deleteConstructionDrawing,
  } = useApi(constructionDrawingsApi.deleteConstructionDrawing, null);
  const [deleted, setDeleted] = useState(false);
  const deletedTimer = useRef();
  const [modalOpen, setModalOpen] = useState(false);

  const removeConstructionDrawing = () =>
    deleteConstructionDrawing(constructionDrawing.objectId);

  const closeModal = () => {
    setModalOpen(false);

    if (deletedTimer.current) {
      clearTimeout(deletedTimer.current);
      afterDelete && afterDelete();
    }
  };

  useEffect(() => {
    deleted_construction_drawing && setDeleted(true);
  }, [deleted_construction_drawing]);

  useEffect(() => {
    if (deleted)
      deletedTimer.current = setTimeout(
        () => afterDelete && afterDelete(),
        3000
      );
  }, [deleted]);

  return (
    <div>
      {/* Button */}
      <a
        className="focus:outline-none font-medium text-sm text-red-500 hover:text-red-600 flex py-1 px-3"
        type="button"
        onClick={() => setModalOpen(!modalOpen)}
      >
        Delete
      </a>
      <Modal isOpen={modalOpen} onClose={closeModal}>
        <ModalHeader
          title={
            <>
              Delete <span className="mx-1 font-extralight">|</span>{' '}
              <span className="font-light">{constructionDrawing.name}</span>{' '}
            </>
          }
          onClose={closeModal}
        />
        <div className="px-2 pt-2 text-base text-left">
          <div className="p-5">
            {!deleted && (
              <>
                <div className="mb-4">
                  <div className="font-semibold">Are you sure?</div>
                </div>
                <div>
                  This will completely remove the construction drawing. It
                  cannot be undone.
                </div>
              </>
            )}
            {deleted && (
              <ModalSuccessView text={'Drawing successfully deleted'} />
            )}
          </div>

          {!deleted && (
            <div className="modal-footer">
              <button
                className="modal-remove-btn"
                type="button"
                onClick={removeConstructionDrawing}
                disabled={deleting}
              >
                Delete Drawing {deleting && <Loader />}
              </button>
              <button
                className="modal-close-btn"
                type="button"
                onClick={closeModal}
                disabled={deleting}
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}
