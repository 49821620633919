import { useEffect } from 'react';
import useApi from '../../../hooks/useApi';
import projectsApi from '../../../api/projects';
import useSaved from '../../../hooks/useSaved';
export default function useProject(projectId: string, {
    load = true
}: {
    load?: boolean;
} = {}) {
    const {
        data: project,
        error,
        loading,
        request: getProject
    } = useApi(projectsApi.getProject, null);

    const {
        data: updatedProject,
        error: updatingError,
        loading: updating,
        request: updateProject
    } = useApi(projectsApi.updateProject, null);

    const _loadProject = () => {
        getProject(projectId);
    }

    const _updateProject = (project: any) => {
        updateProject(projectId, project);
    }

    const { saved: updated } = useSaved(updatedProject?.updated_at);

    useEffect(() => {
        if (!load) return;
        _loadProject();
    }, [projectId, load]);

    return {
        latestProject: updatedProject || project,
        loadProject: _loadProject,
        project,
        error,
        loading,
        updatedProject,
        updating,
        updated,
        updatingError,
        updateProject: _updateProject
    };
}