import { useEffect, useState } from 'react';
import { FaFilePdf } from 'react-icons/fa';
import SearchForm from '../actions/SearchForm';
import { Link, useParams } from 'react-router-dom';
import EditMenu from '../EditMenu';
import CreateReportButton from './CreateReportButton';
import useApi from '../../hooks/useApi';
import useAuth from '../../auth/useAuth';
import reportsApi from '../../api/reports';
import Loader from '../utility/Loader';
import DeleteReportModal from './DeleteReportModal';
import ShareModal from '../shares/ShareModal';
import useWorkspaceContext from '../../hooks/useWorkspaceContext';
import ReportViewModel from '../../models/ReportViewModel';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import useActionCable, { REPORTS_CHANNEL } from '../../hooks/useActionCable';
import ReportStatus, { userIsCreator } from './ReportStatus';
import formattedDateString from '../../utility/formattedDateString';
export default function Reports() {
  const {
    data: { records, pagy },
    error,
    loading,
    request: getReports,
  } = useApi(reportsApi.getReports, { records: [], pagy: {} });
  const { workspace_id, project_id } = useParams();
  const {
    workspace: { permissions = {} },
  } = useWorkspaceContext();
  const { user } = useAuth();
  const [searchParams, setSearchParams] = useState({
    q: '',
    page: 1,
    items: 20,
  });
  const [reports, setReports] = useState([]);
  const [sharingReport, setSharingReport] = useState(null);

  const loadReports = () => getReports(project_id, searchParams);

  const loadMore = () =>
    setSearchParams({ ...searchParams, page: searchParams.page + 1 });
  const hasNextPage = () => !!pagy.next;

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: hasNextPage(),
    onLoadMore: loadMore,
    disabled: !!error,
    rootMargin: '0px 0px 400px 0px',
  });

  const { receivedMessages } = useActionCable(REPORTS_CHANNEL, {
    parent_objectId: project_id,
    parent: 'project',
  });

  useEffect(() => {
    if (loading) return;
    loadReports();
  }, [searchParams, receivedMessages.length]);

  useEffect(() => {
    if (records.length == 0) return;
    const recordsObjectIds = records.map((r) => r.objectId);
    setReports([
      ...reports.filter((r) => !recordsObjectIds.includes(r.objectId)),
      ...records.map((r) => new ReportViewModel(r)),
    ]);
  }, [records]);

  return (
    <div className="p-5 w-full max-w-9xl self-start">
      <div className="sm:flex sm:justify-between sm:items-center mb-4">
        <div></div>
        <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
          <SearchForm
            placeholder="Search Reports..."
            onSearched={(text) => {
              setReports([]);
              setSearchParams({ q: text, page: 1, items: 20 });
            }}
          />
          <CreateReportButton
            afterAdd={() => {
              setReports([]);
              setSearchParams({ q: '', page: 1, items: 20 });
            }}
          />
        </div>
      </div>

      <div className="bg-white border-b border-gray-200 p-5">
        <div className="flex text-secondary font-semibold">
          <div className="flex-1">File</div>
          <div className="flex-1">Created by</div>
          <div className="flex-1">Status</div>
          <div className="flex-shrink"></div>
        </div>
      </div>
      {reports.map((report) => {
        let { objectId, name, creator, created_at, asset } = report;
        return (
          <div
            className="bg-white border-b border-gray-200 p-5"
            key={`report_list_item_${objectId}`}
          >
            <div className="flex items-center">
              <Link
                className="flex-1 flex items-center hover:text-secondary"
                to={`/${workspace_id}/projects/${project_id}/reports/${objectId}`}
              >
                <FaFilePdf size={30} className="mr-2 text-primary" /> {name}
              </Link>
              <div className="flex-1">
                <div className="text-sm font-semibold">{creator.name}</div>
                <div className="text-xs text-gray-400">
                  Created on {formattedDateString(created_at)}
                </div>
              </div>
              <div className="flex-1">
                <ReportStatus report={report} />
              </div>
              <div className="relative flex-shrink flex justify-end">
                <EditMenu className="" action="click">
                  <li>
                    <Link
                      className="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3"
                      to={`/${workspace_id}/projects/${project_id}/reports/${objectId}`}
                    >
                      View
                    </Link>
                  </li>
                  {asset && (
                    <>
                      <li>
                        <a
                          className="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3"
                          download={`${name.replace(' ', '_')}.pdf`}
                          href={asset.files.original}
                        >
                          Download
                        </a>
                      </li>
                      <li>
                        <a
                          className={`cursor-pointer`}
                          data-testid="shareReportButton"
                          onClick={() => setSharingReport(report)}
                        >
                          <div className="font-medium text-sm text-gray-600 hover:text-gray-800 flex py-1 px-3">
                            Share
                          </div>
                        </a>
                      </li>
                    </>
                  )}
                  {(permissions?.can?.('create_reports') ||
                    userIsCreator(user, report)) && (
                      <li>
                        <DeleteReportModal
                          report={report}
                          onDeleted={() => {
                            setReports([]);
                            setSearchParams({ page: 1, items: 20, q: '' });
                          }}
                        />
                      </li>
                    )}
                </EditMenu>
              </div>
            </div>
          </div>
        );
      })}
      {(loading || hasNextPage()) && (
        <div className="flex justify-center py-10" ref={sentryRef}>
          <Loader color="black" />
        </div>
      )}
      {!loading && searchParams.q.length > 0 && reports.length === 0 && (
        <div className="flex justify-center">
          <p className="empty-data-txt">
            No reports found matching '{searchParams.q}'
          </p>
        </div>
      )}
      {!loading && searchParams.q === '' && pagy.count === 0 && (
        <div className="flex justify-center">
          <p className="empty-data-txt">
            No reports have been generated on project
          </p>
        </div>
      )}

      <ShareModal
        key={`share_report_${sharingReport?.objectId}`}
        data={sharingReport}
        open={!!sharingReport}
        hideButton
        shareableApi={reportsApi.updateReport}
        onClose={() => setSharingReport(null)}
      />
    </div>
  );
}
