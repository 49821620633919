import client from './client';
const endpoint = '/entries';

const getReportEntries = (reportId, params = {}) =>
  client.get(`/reports/${reportId}${endpoint}`, params);
const getEntry = (entryId, params = {}) =>
  client.get(`${endpoint}/${entryId}`, params);
const updateEntry = (entryId, entry) =>
  client.put(`${endpoint}/${entryId}`, { entry });
const removeEntry = (entryId) => client.delete(`${endpoint}/${entryId}`);
const restoreEntry = (entryId) => client.put(`${endpoint}/${entryId}/restore`);
const getEntryGroupEntries = (entryGroupId, params = {}) =>
  client.get(`/entry_groups/${entryGroupId}${endpoint}`, params);
const addEntryToEntryGroup = (entryGroupId, entry) =>
  client.post(`/entry_groups/${entryGroupId}${endpoint}`, { entry });
const getReportPageEntryGroupEntries = (reportPageEntryGroupId, params = {}) =>
  client.get(
    `/report_page_entry_groups/${reportPageEntryGroupId}${endpoint}`,
    params
  );

export default {
  getReportEntries,
  getEntry,
  getEntryGroupEntries,
  getReportPageEntryGroupEntries,
  updateEntry,
  removeEntry,
  restoreEntry,
  addEntryToEntryGroup,
};
