import { useState, useRef, useEffect, useCallback } from 'react';
import Transition from '../../utils/Transition.js';
import { AiFillTags } from 'react-icons/ai';
import Tags from './Tags';
import tagsApi from '../../api/tags';
import { useParams } from 'react-router-dom';
import useApi from '../../hooks/useApi';
import Fuse from 'fuse.js';
import ClickBackdrop, { clickBackdropZindex } from '../utility/ClickBackdrop';

const fuseOptions = {
  includeScore: true,
  keys: ['name'],
};

const TagListItem = ({ tag, onClick }) => (
  <li
    className="py-1 px-3 border-b cursor-pointer hover:bg-gray-50"
    key={tag.id}
    data-tag-id={tag.id}
    onClick={() => {
      onClick(tag);
    }}
  >
    <label className="flex items-center" data-tag-id={tag.id}>
      <span
        className="text-sm font-medium"
        data-tag-id={tag.id}
        style={{ color: tag.color }}
      >
        {tag.name}
      </span>
    </label>
  </li>
);

function TagsFilter({ align, onChange, taggableType = 'Pin' }) {
  const [tags, setTags] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);
  const input = useRef(null);
  const { workspace_id } = useParams();
  const {
    data: { records: workspaceTags },
    request: getTags,
  } = useApi(tagsApi.getTags, {
    records: [],
    pagy: {},
  });

  const tagIds = useCallback(() => {
    return tags.map((tag) => tag.id);
  }, [tags]);

  const fuse = useCallback(
    () => new Fuse(workspaceTags, fuseOptions),
    [workspaceTags]
  );

  const filteredWorkspaceTags = filteredResults?.filter(
    ({ id }) => !tagIds().includes(id)
  );

  const addTag = async (tag) => {
    setTags((existingTags) => [...existingTags, tag]);
  };

  const removeTag = async (tag) => {
    setTags((existingTags) => existingTags.filter(({ id }) => id !== tag.id));
  };

  const onSearch = ({ target: { value } }) => {
    setFilteredResults(
      fuse()
        .search(value)
        .map(({ item }) => item)
    );
  };

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {
    onChange?.(tags.map(({ name }) => name));
  }, [tags]);

  useEffect(() => {
    setFilteredResults(workspaceTags);
  }, [workspaceTags]);

  useEffect(() => {
    if (!dropdownOpen) return;
    getTags(workspace_id, { taggable_type: taggableType });
  }, [dropdownOpen]);

  return (
    <>
      {dropdownOpen && (
        <ClickBackdrop
          onClick={() => setDropdownOpen(false)}
          testId={'tagsFilter'}
        />
      )}
      <div className="relative inline-flex">
        <button
          ref={trigger}
          className={`inline-flex items-center cursor-pointer hover:opacity-70 mr-3 ${tags.length ? 'bg-secondary text-white' : 'bg-white'
            } rounded-full shadow-sm px-5 h-8`}
          title="Filter by Tags"
          aria-haspopup="true"
          onClick={() => setDropdownOpen(!dropdownOpen)}
          aria-expanded={dropdownOpen}
        >
          <AiFillTags />
        </button>
        <Transition
          show={dropdownOpen}
          tag="div"
          className={`origin-top-right ${clickBackdropZindex} absolute top-full w-64 bg-white border border-gray-200 rounded shadow-lg overflow-hidden ${align === 'right' ? 'right-0' : 'left-0'
            }`}
          enter="transition ease-out duration-200 transform"
          enterStart="opacity-0 -translate-y-2"
          enterEnd="opacity-100 translate-y-0"
          leave="transition ease-out duration-200"
          leaveStart="opacity-100"
          leaveEnd="opacity-0"
        >
          <div ref={dropdown}>
            {!!tags.length && (
              <Tags
                tags={tags}
                tagSize="sm"
                containerClassName="bg-blue-50 py-2 border-b px-2 mt-0 flex flex-wrap items-center max-w-64 h-auto"
                tagClassName="mr-1 mb-1"
                onRemove={removeTag}
              />
            )}
            <input
              type="text"
              className="form-input bg-gray-50 w-full border-0 rounded-0 outline-none focus:ring-0 focus:border-gray-300"
              placeholder="Search tags"
              onChange={onSearch}
              ref={input}
            />
            {!!filteredWorkspaceTags.length && (
              <ul className="max-h-60 overflow-y-auto">
                {filteredWorkspaceTags.map((workspaceTag) => {
                  return <TagListItem tag={workspaceTag} onClick={addTag} />;
                })}
              </ul>
            )}

            <div className="py-2 px-3 border-t border-gray-200 bg-gray-50">
              <ul className="flex items-center justify-between">
                <li>
                  <button
                    className="btn-xs bg-white border-gray-200 hover:border-gray-300 text-gray-500 hover:text-gray-600"
                    onClick={() => setTags([])}
                  >
                    Clear
                  </button>
                </li>
                <li>
                  <button
                    className="btn-xs bg-secondary hover:bg-tertiary text-white"
                    onClick={() => setDropdownOpen(false)}
                    onBlur={() => setDropdownOpen(false)}
                  >
                    Done
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </Transition>
      </div>
    </>
  );
}

export default TagsFilter;
