import { useEffect, useRef, useState } from 'react';
import { FiTrash2 } from 'react-icons/fi';
import Modal, { ModalSuccessView, ModalHeader } from '../utility/Modal';
import useApi from '../../hooks/useApi';
import Loader from '../utility/Loader';
import documentsApi from '../../api/documents';
import foldersApi from '../../api/folders';
import useSaved from '../../hooks/useSaved';
import capitalize from '../../utility/capitalize';

export default function BulkDeleteDocumentsModal({
    open,
    documentsIds = [],
    folderIds = [],
    afterDelete,
}: {
    open: boolean,
    documentsIds: string[],
    folderIds: string[],
    afterDelete?: () => void
}) {
    const {
        data: deletedDocuments,
        error: deleteDocumentsError,
        loading: deletingDocuments,
        request: bulkDeleteDocuments,
    } = useApi(documentsApi.bulkDeleteDocuments, null);

    const {
        data: deletedFolders,
        error: deleteFoldersError,
        loading: deletingFolders,
        request: bulkDeleteFolders,
    } = useApi(foldersApi.bulkDeleteFolders, null);

    const [modalOpen, setModalOpen] = useState(open || false);

    const deleteDocumentsAndFolders = () => {
        if (!!folderIds.length) deleteFolders();
        if (!!documentsIds.length) deleteDocuments();
    }

    const deleteDocuments = () =>
        bulkDeleteDocuments(documentsIds);

    const deleteFolders = () => bulkDeleteFolders(folderIds);

    const { saved: deleted } = useSaved((modalOpen && (deletedDocuments || !documentsIds.length) && (deletedFolders || !folderIds.length)), {
        afterSaved: () => {
            setModalOpen(false);
            afterDelete?.();
        },
    });

    const closeModal = () => {
        deleted && afterDelete?.();
        setModalOpen(false);
    };

    useEffect(() => {
        setModalOpen(open);
    }, [open]);

    const fileTypes = () => {
        if (!!folderIds.length && !!documentsIds.length) return 'folders and files';
        if (!!folderIds.length) return 'folders';
        return 'files';
    }

    return (
        <div className="text-gray-600 text-center font-normal">
            {/* Button */}
            <button
                className="cursor-pointer hover:bg-gray-800 rounded-full p-3"
                data-testid="bulkDeleteDocumentsButton"
                onClick={() => setModalOpen(!modalOpen)}
            >
                <FiTrash2 className="text-white" />
            </button>
            <Modal isOpen={modalOpen} onClose={closeModal}>
                <ModalHeader
                    title={
                        <>
                            Delete <span className="mx-1 font-light">|</span>{' '}
                            <span className="font-light">
                                {folderIds.length + documentsIds.length} {fileTypes()}
                            </span>{' '}
                        </>
                    }
                    onClose={closeModal}
                />
                <div className="px-2 pt-2 text-base">
                    <div className="p-5 text-left">
                        {!deleted && (
                            <>
                                <div className="mb-4">
                                    <div className="font-semibold">Are you sure?</div>
                                </div>
                                <div>
                                    This will completely all selected {fileTypes()}. It
                                    cannot be undone.
                                </div>
                            </>
                        )}
                        {deleted && (
                            <ModalSuccessView text={`${capitalize(fileTypes())} successfully deleted`} />
                        )}
                    </div>

                    {!deleted && (
                        <div className="modal-footer">
                            <button
                                className="modal-remove-btn"
                                type="button"
                                onClick={deleteDocumentsAndFolders}
                                disabled={deletingDocuments || deletingFolders}
                            >
                                Delete {fileTypes()} {deletingDocuments || deletingFolders && <Loader />}
                            </button>
                            <button
                                className="modal-close-btn"
                                type="button"
                                onClick={closeModal}
                                disabled={deletingDocuments || deletingFolders}
                            >
                                Cancel
                            </button>
                        </div>
                    )}
                </div>
            </Modal>
        </div>
    );
}
