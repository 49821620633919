import { useEffect } from 'react';
import useApi from '../../../hooks/useApi';
import workspacesApi from '../../../api/workspaces';
export default function useWorkspaces({
    load = true
}: {
    load?: boolean
} = {
    }) {
    const {
        data: { records: workspaces, pagy },
        error,
        loading,
        request: getWorkspaces,
    } = useApi(workspacesApi.getWorkspaces, { records: [], pagy: {} });

    useEffect(() => {
        if (!load) return;
        getWorkspaces();
    }, [load]);

    return {
        workspaces,
        error,
        loading,
        getWorkspaces,
        pagy,
    }
}