import { useEffect, useState, useRef } from 'react';
import Loader from '../utility/Loader';
import Modal, { ModalHeader, ModalFooter, ModalBody, ModalSuccessView } from '../utility/Modal';
import ErrorView from '../utility/ErrorView';
import PreviewFeatureAnnouncementModal from './PreviewFeatureAnnouncementModal';
import addDays from '../../utility/addDates';
import useFeatureAnnouncementCreationServices from './hooks/useFeatureAnnouncementCreationServices';
import useFeatureAnnouncementForm from './hooks/useFeatureAnnouncementForm';
const DEFAULT_EXPIRATION_DAYS = 30;

export default function CreateFeatureAnnouncementModal({
    open,
    afterCreate,
    onClose,
}) {
    const [featureAnnouncement, setFeatureAnnouncement] = useState({
        status: 'beta',
        feature_flag: null,
        expiration_date: addDays(new Date(), DEFAULT_EXPIRATION_DAYS),
    });
    const [previewing, setPreviewing] = useState(false);
    const [saved, setSaved] = useState(false);
    const savedTimer = useRef(null);

    const {
        createError,
        creating,
        createdFeatureAnnouncement,
        createFeatureAnnouncement: create,
    } = useFeatureAnnouncementCreationServices();


    const createFeatureAnnouncement = () => {
        create(featureAnnouncement);
    };

    const updateFeatureAnnouncement = (key, value) => {
        setFeatureAnnouncement({ ...featureAnnouncement, [key]: value });
    };

    const form = useFeatureAnnouncementForm(featureAnnouncement, {
        onUpdate: updateFeatureAnnouncement,
    })

    const announcementsFilled = () => featureAnnouncement && ['title', 'subtitle', 'description', 'expiration_date', 'loom_link', 'faq_link', 'feature_flag'].map((key) => !!featureAnnouncement[key]).every(Boolean);

    useEffect(() => {
        if (!createdFeatureAnnouncement) return;
        setSaved(true);
    }, [createdFeatureAnnouncement]);

    useEffect(() => {
        if (saved) {
            setFeatureAnnouncement({});
            savedTimer.current = setTimeout(() => {
                onClose?.();
                afterCreate?.(createdFeatureAnnouncement);
                setTimeout(() => {
                    setSaved(false);
                }, 500);
            }, 2500);
        }
    }, [saved]);

    return <>
        <Modal
            isOpen={open}
            dialogScrolls={true}
            modalScrolls={false}
            maxHeight={'max-h-auto'}
            dialogClass={'py-10'}
            modalClass={'overflow-hidden rounded-lg'}
            aligned={'items-start'}
            onClose={onClose}
        >
            <ModalHeader
                title={'Create Feature Announcement'}
                onClose={onClose}
            />
            {saved ? (
                <ModalSuccessView text={'New feature announcement successfully created'} />
            ) : (<>
                <ModalBody>
                    {createError && (
                        <ErrorView error={createError} />
                    )}
                    {form}
                </ModalBody>


                <ModalFooter>
                    <button
                        className="modal-success-btn"
                        data-testid="createFeatureAnnouncementPreviewButton"
                        type="button"
                        disabled={creating || !announcementsFilled(featureAnnouncement)}
                        onClick={() => setPreviewing(true)}
                    >
                        Preview
                    </button>
                    <button
                        className="modal-save-btn mr-2"
                        data-testid="createFeatureAnnouncementButton"
                        type="button"
                        disabled={creating || !announcementsFilled(featureAnnouncement)}
                        onClick={createFeatureAnnouncement}
                    >
                        Create {creating && <Loader />}
                    </button>
                    <button
                        className="modal-close-btn mr-2"
                        type="button"
                        disabled={creating}
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                </ModalFooter></>
            )
            }
        </Modal >

        <PreviewFeatureAnnouncementModal
            featureAnnouncement={featureAnnouncement}
            open={previewing}
            onClose={() => setPreviewing(false)}
        />
    </>
        ;
}
