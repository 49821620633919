import DelayedSearchInput, { SearchContainerFull } from '../utility/DelayedSearchInput';

export default function DatumSearch({
    onSearch,
}: {
    onSearch?: (q: string) => void,
}) {
    return <SearchContainerFull>
        <DelayedSearchInput
            placeholder={'Search for a datums...'}
            onSearched={onSearch}
            autoFocus
        />
    </SearchContainerFull>
}