import Modal, { ModalBody, ModalFooter, ModalHeader } from '../utility/Modal';
import { ReportType } from '../../data/models';
import { List } from '../../utils/Layout';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useWorkspace from '../workspaces/hooks/useWorkspace';
import Button from '../shared/Button';
import useReportType from './hooks/useReportType';
export default function ReportTypeDuplicateModal({
    open,
    afterDuplication,
    onClose,
    reportTypes,
}: {
    open: boolean
    onClose: () => void
    afterDuplication: () => void
    reportTypes: ReportType[]
}) {

    const { workspaceId } = useParams<{ workspaceId: string }>();
    const { workspace } = useWorkspace(workspaceId);

    const [selectedReportType, setSelectedReportType] = useState<ReportType | null>(null);
    const { duplicateToWorkspace, duplicating, duplicated } = useReportType(selectedReportType?.id || null, { load: false });

    useEffect(() => {
        if (!duplicated) return;
        afterDuplication?.();
    }, [duplicated])

    return <Modal isOpen={open} >
        <ModalHeader title="Add Report Type" onClose={onClose} />
        <ModalBody>
            {!selectedReportType ? <>
                <p className="mb-4 font-semibold">Select report type to add</p>
                <List.List>
                    {reportTypes.map((reportType) => (
                        <List.Item key={reportType.id}>
                            <div onClick={() => setSelectedReportType(reportType)} className="cursor-pointer font-semibold hover:bg-gray-50 bg-white w-full py-2 shadow-sm rounded-lg px-2">{reportType.name}</div>
                        </List.Item>
                    ))}
                </List.List></> : <>
                <p className="mb-4 font-semibold">Are you sure you want to add report type?</p>
                <p className="mb-1 mt-4 mb-4">This will make <span className="font-semibold">{selectedReportType.name}</span> report type immediately available to <span className="font-semibold">{workspace?.name}</span> workspace</p>
                <div className="flex flex-wrap justify-end space-x-2">
                    <Button color='gray' data-testid="buttonCancelReportTypeDuplication" text='Cancel' onClick={() => setSelectedReportType(null)} />
                    <Button color='tertiary' data-testid="buttonConfirmReportTypeDuplication" saving={duplicating} saved={duplicated} savedText='Confirmed!' text={"I am sure"} onClick={() => duplicateToWorkspace(workspaceId)} />
                </div>
            </>}
        </ModalBody>
        <ModalFooter>
            <button
                className="modal-close-btn mr-2"
                type="button"
                onClick={onClose}
                disabled={duplicating}
            >
                Cancel
            </button>
        </ModalFooter>
    </Modal>
}