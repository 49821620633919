import { useState, memo } from 'react';
import { useParams } from 'react-router-dom';
import { FaUndo } from 'react-icons/fa';
import { BiHide } from 'react-icons/bi';
import Modal, { ModalHeader } from '../utility/Modal';
import reportPagesApi from '../../api/report_pages';
import { toast } from 'react-toastify';
import useReportContext from '../reports/useReportContext';

function ReportPageHideAllButton({ reportPageId }) {
  const { report_id } = useParams();
  const [hidden, setHidden] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const { hiddenPages, setHiddenPages } = useReportContext();

  const unHideReportPages = async () => {
    if (document.getElementById(`report_page_${reportPageId}`) === null) {
      let { data } = await reportPagesApi.bulkUpdateReportPage(
        report_id,
        { hidden: false },
        { where_pins: true }
      );
      if (data) {
        setHiddenPages([...hiddenPages.filter((id) => !data.includes(id))]);
        setHidden([]);
      }
    }
  };

  const hideReportPages = async () => {
    let { data } = await reportPagesApi.bulkUpdateReportPage(
      report_id,
      { hidden: true },
      { where_pins: true }
    );
    if (data) {
      setHidden(data);
      setHiddenPages([...hiddenPages, ...data]);
    }
  };

  const showToast = async () => {
    setModalOpen(false);
    toast.info(
      <div>
        <div className="font-bold text-secondary flex items-center justify-between">
          <span>
            <strong>Pin Page(s)</strong> Hidden
          </span>
          <a
            className="cursor-pointer ml-4 px-2 py-1 border rounded-md bg-white hover:shadow flex text-secondary items-center"
            onClick={unHideReportPages}
          >
            Undo <FaUndo className="ml-3" />
          </a>
        </div>
      </div>,
      {
        position: 'bottom-left',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );

    await hideReportPages();
  };

  const closeModal = () => setModalOpen(false);

  return (
    <>
      <a
        onClick={() => setModalOpen(!modalOpen)}
        className="transition ease-in duration-200 py-2 cursor-pointer bg-transparent border-l border-gray-500 pl-3 md:opacity-0 group-hover:opacity-100 font-medium text-xs text-gray-200 hover:text-white flex px-2 border-b-0 border-r-0"
      >
        <BiHide size={16} className="mr-0.5 text-red-400" /> Hide All Pin Pages
      </a>

      <Modal isOpen={modalOpen} onClose={closeModal}>
        <ModalHeader title={'Confirm Page(s) Hiding'} onClose={closeModal} />
        <div className="px-2 pt-2">
          <div className="p-5">
            {hidden.length === 0 && (
              <>
                <div className="mb-4">
                  <div className="font-semibold">Are you sure?</div>
                </div>
                <div>
                  This will completely hide all pin pages from the report.
                </div>
              </>
            )}
          </div>

          {hidden.length === 0 && (
            <div className="modal-footer">
              <button
                className="modal-save-btn"
                type="button"
                onClick={showToast}
              >
                Hide All Pages
              </button>
              <button
                className="modal-close-btn"
                type="button"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}

export default memo(ReportPageHideAllButton);
