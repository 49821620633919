import * as React from 'react';
import { Entry } from '../../data/models';
export default function EntryFreeText({ entry, disabled, useBottomBorder, onChange }: {
    entry: Entry
    disabled?: boolean
    useBottomBorder?: boolean
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}) {

    return <p
        data-testid={`entryTextInput_${entry.name
            .replace(' ', '_')
            .toLowerCase()}`}
        style={{
            minHeight: entry.styles?.height || 'auto',
        }}
        contentEditable={!disabled}
        className={`whitespace-pre-line text-xs w-full leading-4 border-gray-200 p-2 border-t-0 border-r-0 border-l-0 ${entry.class_name
            } ${useBottomBorder ? 'border-b' : 'border-b-0'} ${!disabled && 'hover:bg-blue-50'
            } focus:border-gray-200 focus:outline-none focus:ring-0`}
    >
        {entry.value}
    </p>
}