import uuidv4 from '../utility/uuidv4';

export default class PinDropService {
  constructor({
    projectObjectId,
    data,
    type,
    status,
    imageUris,
    offline = false,
    noteRequiredOnCreate = false,
    noteAssetRequiredOnCreate = false,
  }) {
    this.projectObjectId = projectObjectId;
    this.data = { ...data, objectId: uuidv4() };
    this.type = type;
    this.status = status;
    this.imageUris = imageUris;
    this.offline = offline;
    this.noteRequiredOnCreate = noteRequiredOnCreate;
    this.noteAssetRequiredOnCreate = noteAssetRequiredOnCreate;
  }

  isComplete() {
    if (!this.type) return false;
    return (
      this.noteComplete() &&
      this.photoComplete() &&
      this.fieldsComplete() &&
      this.statusComplete()
    );
  }

  noteComplete() {
    let complete = !this.type.note_required_on_create && !this.noteRequiredOnCreate;
    if (this.type.note_required_on_create || this.noteRequiredOnCreate)
      complete = this.data.notes_attributes?.[0]?.text?.length > 0;
    return complete;
  }

  photoComplete() {
    let complete = !this.type.note_photo_required_on_create && !this.noteAssetRequiredOnCreate;
    if (this.type.note_photo_required_on_create || this.noteAssetRequiredOnCreate)
      complete = this.imageUris.length > 0;
    return complete;
  }

  statusComplete() {
    let complete = this.type?.statuses?.length === 0;

    if (this.type?.statuses?.length > 0) {
      complete = this.type?.statuses
        ?.map(({ objectId }) => objectId)
        .includes(this.status?.objectId);
    }

    return complete;
  }

  setFields = () =>
    this.data.field_values_attributes?.map(
      (field_value) => field_value.field_id
    ) || [];

  activeFields = () =>
    this.type?.fieldsets
      ?.map((fieldset) =>
        fieldset.fields
          .filter(({ archived_at }) => !archived_at)
          .map((field) => field)
      )
      .reduce((prev, curr) => prev.concat(curr), []) || [];

  requiredFields = () =>
    this.activeFields()
      .filter(({ required }) => required)
      .map((field) => field);
  requiredFieldIds = () => this.requiredFields().map(({ id }) => id);

  fieldsComplete = () =>
    this.requiredFieldIds().filter(
      (requiredId) => this.setFields().indexOf(requiredId) === -1
    ).length === 0;
}
