import React, { useEffect, useState } from 'react';
import { BiX } from 'react-icons/bi';
import useApi from '../../hooks/useApi';
import projectMembersApi from '../../api/project_members';
import Loader from '../utility/Loader';
import { useParams } from 'react-router-dom';

export default function RemoveMemberItem({ project_member, afterUpdate }) {
  const { project_id } = useParams();
  const [added, setAdded] = useState(true);

  const {
    data: new_member,
    error,
    loading,
    request: addProjectMember,
  } = useApi(projectMembersApi.addProjectMember, false);
  const {
    data: removed_member,
    error: removeError,
    loading: removeLoading,
    request: removeProjectMember,
  } = useApi(projectMembersApi.destroyProjectMember, false);

  const createNewProjectMember = () => {
    addProjectMember(project_id, { member_id: project_member.member.id });
  };

  const deleteProjectMember = () => {
    removeProjectMember(
      new_member ? new_member.objectId : project_member.objectId
    );
  };

  useEffect(() => {
    new_member && setAdded(true);
  }, [new_member]);

  useEffect(() => {
    removed_member && setAdded(false);
  }, [removed_member]);

  useEffect(() => {
    if ((added || removed_member) && afterUpdate) afterUpdate();
  }, [added, removed_member]);

  let invitationPending =
    (project_member.member.invited_by &&
      !project_member.member.invitation_accepted_at) ||
    !project_member.workspace_accepted;

  return (
    <div className="flex items-center justify-between p-3 border-b border-gray-200">
      <div className="flex items-center">
        <img
          className="rounded-full w-12 h-12 object-cover mr-4"
          src={
            project_member.member.profile_photos?.medium ||
            'https://bucketeer-d9937a0d-80e4-469a-91e2-6c86bd37d08f.s3.amazonaws.com/public/user_empty.png'
          }
        />
        <div className="text-left">
          <p className="truncate">
            {project_member.member.name || project_member.member.email}
          </p>
          {invitationPending && (
            <p className="italic text-tertiary text-xs truncate">
              Invitation pending
            </p>
          )}
          <p className="font-bold text-xs truncate">
            {project_member.member.title}
          </p>
        </div>
      </div>
      {added ? (
        <a
          onClick={deleteProjectMember}
          className="flex text-white hover:opacity-80 text-sm font-semibold bg-red-500 px-3 py-1 rounded-md cursor-pointer"
        >
          Remove from project{' '}
          {removeLoading ? (
            <div className="ml-3">
              <Loader />
            </div>
          ) : (
            <BiX size={20} className="ml-2 text-white" />
          )}
        </a>
      ) : (
        <a
          onClick={createNewProjectMember}
          className="flex text-white hover:opacity-80 text-sm font-semibold bg-tertiary-light px-3 py-1 rounded-md cursor-pointer"
        >
          Add to project{' '}
          {loading && (
            <div className="ml-3">
              <Loader />
            </div>
          )}
        </a>
      )}
    </div>
  );
}
